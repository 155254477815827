import React from "react";
import ReactDOM from "react-dom";
import DocumentContainerByTypeAndName from "components/FormIOForm/react-components/DocumentContainerByTypeAndName";
import ReactComponentFormio from "components/FormIOForm/react-components/ReactComponentFormio";
import { DocumentContainerType } from "models/DocumentContainer.model";
import get from "lodash/get";
import AppContextProvider from "components/FormIOForm/react-components/AppContextProvider";
import DocumentDataProvider from "components/FormIOForm/react-components/DocumentDataProvider";

export default class DocumentContainerFormio extends ReactComponentFormio {
  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param element
   * @param ref
   * #returns ReactInstance
   */
  attachReact(element: any, ref?: any) {
    const { applicationId } = window.__OBJECTIVE_BUILD_FORMIO_CUSTOM_DATA__;
    const component = get(this, "component");
    const props = get(component, "properties");
    const documentContainerType = get(props, "documentContainerType") as DocumentContainerType;
    const documentContainerName = get(props, "documentContainerName") as string;
    const hasNoHeading = Boolean(get(props, "hasNoHeading") || "true");

    return ReactDOM.render(
      <AppContextProvider>
        <DocumentDataProvider applicationId={applicationId}>
          <DocumentContainerByTypeAndName
            applicationId={applicationId}
            documentContainerType={documentContainerType}
            documentContainerName={documentContainerName}
            hasNoHeading={hasNoHeading}
          />
        </DocumentDataProvider>
      </AppContextProvider>,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  // istanbul ignore next
  detachReact(element: any) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
