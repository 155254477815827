import { createAsyncThunk, createReducer, createSelector } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { ApplicationRecordType } from "models/Application.model";
import { ApplicationStatus } from "models/ApplicationStatus.model";

// Type
export type ApplicationBadgeCountState = {
  recentApplicationsBadgeCount: number;
  allApplicationsBadgeCount: number;
  submittedApplicationsBadgeCount: number;
  allArchivedApplicationsBadgeCount: number;
  archivedApplicationsBadgeCount: number;
  recentConsentsBadgeCount: number;
  currentConsentsBadgeCount: number;
  archivedConsentsBadgeCount: number;
};

// Actions & Thunks
export const fetchRecentApplicationsBadgeCount = createAsyncThunk(
  "appState/recentApplicationsBadgeCount/fetchRecentApplicationsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      recordType: ApplicationRecordType.Draft,
      status: [
        ApplicationStatus.Draft,
        ApplicationStatus.Submitted,
        ApplicationStatus.Submitting,
        ApplicationStatus.VRFISuspended,
        ApplicationStatus.InProgress,
        ApplicationStatus.Refused,
      ],
    });
  }
);

export const fetchAllApplicationsBadgeCount = createAsyncThunk(
  "appState/applicationsBadgeCount/fetchAllApplicationsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      isHidden: false,
    });
  }
);

export const fetchSubmittedApplicationsBadgeCount = createAsyncThunk(
  "appState/applicationsBadgeCount/fetchSubmittedApplicationsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      recordType: ApplicationRecordType.Draft,
      isHidden: false,
      status: [
        ApplicationStatus.Submitted,
        ApplicationStatus.Submitting,
        ApplicationStatus.VRFISuspended,
        ApplicationStatus.InProgress,
        ApplicationStatus.Refused,
      ],
    });
  }
);

export const fetchArchivedApplicationsBadgeCount = createAsyncThunk(
  "appState/applicationsBadgeCount/fetchArchivedApplicationsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      recordType: ApplicationRecordType.Draft,
      isHidden: true,
      status: [
        ApplicationStatus.Draft,
        ApplicationStatus.Submitted,
        ApplicationStatus.Submitting,
        ApplicationStatus.VRFISuspended,
        ApplicationStatus.InProgress,
        ApplicationStatus.Refused,
      ],
    });
  }
);

export const fetchAllArchivedApplicationsBadgeCount = createAsyncThunk(
  "appState/applicationsBadgeCount/fetchAllArchivedApplicationsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      isHidden: true,
    });
  }
);

export const fetchRecentConsentsBadgeCount = createAsyncThunk(
  "appState/consentsBadgeCount/fetchRecentConsentsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      recordType: ApplicationRecordType.Consent,
      status: [ApplicationStatus.InProgress, ApplicationStatus.Refused, ApplicationStatus.Submitted],
    });
  }
);

export const fetchCurrentConsentsBadgeCount = createAsyncThunk(
  "appState/consentsBadgeCount/fetchCurrentConsentsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      recordType: ApplicationRecordType.Consent,
      isHidden: false,
      status: [ApplicationStatus.InProgress, ApplicationStatus.Refused, ApplicationStatus.Submitted],
    });
  }
);

export const fetchArchivedConsentsBadgeCount = createAsyncThunk(
  "appState/consentsBadgeCount/fetchArchivedConsentsBadgeCount",
  () => {
    const applicationService = serviceContainer.cradle.applicationService;
    return applicationService.fetchApplicationsBadgeCount({
      recordType: ApplicationRecordType.Consent,
      isHidden: true,
      status: [ApplicationStatus.InProgress, ApplicationStatus.Refused, ApplicationStatus.Submitted],
    });
  }
);

// Reducer

export const defaultApplicationsBadgeCountState: ApplicationBadgeCountState = {
  recentApplicationsBadgeCount: 0,
  allApplicationsBadgeCount: 0,
  submittedApplicationsBadgeCount: 0,
  archivedApplicationsBadgeCount: 0,
  allArchivedApplicationsBadgeCount: 0,
  recentConsentsBadgeCount: 0,
  currentConsentsBadgeCount: 0,
  archivedConsentsBadgeCount: 0,
};

export const applicationsBadgeCountReducer = createReducer<ApplicationBadgeCountState>(
  defaultApplicationsBadgeCountState,
  (builder) =>
    builder
      .addCase(fetchRecentApplicationsBadgeCount.fulfilled, (state, action) => {
        state.recentApplicationsBadgeCount = action.payload;
      })
      .addCase(fetchSubmittedApplicationsBadgeCount.fulfilled, (state, action) => {
        state.submittedApplicationsBadgeCount = action.payload;
      })
      .addCase(fetchAllApplicationsBadgeCount.fulfilled, (state, action) => {
        state.allApplicationsBadgeCount = action.payload;
      })
      .addCase(fetchAllArchivedApplicationsBadgeCount.fulfilled, (state, action) => {
        state.allArchivedApplicationsBadgeCount = action.payload;
      })
      .addCase(fetchArchivedApplicationsBadgeCount.fulfilled, (state, action) => {
        state.archivedApplicationsBadgeCount = action.payload;
      })
      .addCase(fetchRecentConsentsBadgeCount.fulfilled, (state, action) => {
        state.recentConsentsBadgeCount = action.payload;
      })
      .addCase(fetchCurrentConsentsBadgeCount.fulfilled, (state, action) => {
        state.currentConsentsBadgeCount = action.payload;
      })
      .addCase(fetchArchivedConsentsBadgeCount.fulfilled, (state, action) => {
        state.archivedConsentsBadgeCount = action.payload;
      })
);

// Selectors

export const selectApplicationBadgeCountState = (state: RootState) => state.appState.applicationsBadgeCount;

export const selectAllApplicationsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.allApplicationsBadgeCount
);

export const selectAllArchivedApplicationsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.allArchivedApplicationsBadgeCount
);

export const selectRecentApplicationsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.recentApplicationsBadgeCount
);

export const selectSubmittedApplicationsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.submittedApplicationsBadgeCount
);

export const selectArchivedApplicationsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.archivedApplicationsBadgeCount
);

export const selectRecentConsentsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.recentConsentsBadgeCount
);

export const selectCurrentConsentsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.currentConsentsBadgeCount
);

export const selectArchivedConsentsBadgeCount = createSelector(
  [selectApplicationBadgeCountState],
  (state) => state.archivedConsentsBadgeCount
);
