import { UserStatus } from "models/UserInfo.model";

export type IOrganisationMemberEntity = {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  organisationId: number;
  role: OrganisationRole;
  status: UserStatus;
};

export enum OrganisationRole {
  Admin = "Admin",
  Normal = "Normal",
}

export const OrganisationRoleUtil = {
  parse: (str: string): OrganisationRole => {
    for (const value of Object.values(OrganisationRole)) {
      if (str === value.toString()) {
        return value;
      }
    }
    return OrganisationRole.Normal;
  },
};

export const OrganisationMemberUtil = {
  getDisplayName: (member?: IOrganisationMemberEntity): string => {
    if (!member) {
      return "";
    }
    const displayName = `${member.firstName} ${member.lastName}`.trim();
    return displayName;
  },
  getInitials: (member?: IOrganisationMemberEntity) => {
    if (!member) {
      return "";
    }

    return [member.firstName, member.lastName]
      .filter((str) => str && str.length > 0)
      .map((str) => str[0].toUpperCase())
      .join("");
  },
};
