import { ApplicationDocumentRelationUtil } from "models/ApplicationDocumentRelation.model";
import { createAction, createAsyncThunk, createReducer, unwrapResult } from "@reduxjs/toolkit";
import {
  selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments,
  selectDocumentEntityByName,
  uploadFile,
  upsertDocument,
} from "store/domain-data/document/document";
import { serviceContainer } from "services/serviceContainer";
import { batch } from "react-redux";
import {
  selectApplicationDocumentRelationByKey,
  selectApplicationDocumentRelationsInApplicationContainer,
  updateApplicationDocumentStatus,
  upsertApplicationDocumentRelation,
} from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { DocumentStatus } from "models/DocumentStatus.model";
import { getFileNameComponents } from "utils/getFileNameComponents";
import get from "lodash/get";
import { IDocumentEntity } from "models/Document.model";
import { convertImageToPdf } from "utils/convertImageToPdf";
import { MIME_TYPES } from "constants/configs";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { DocumentContainerUtil } from "models/DocumentContainer.model";
import { isBefore, subMonths } from "date-fns";

const logger = serviceContainer.cradle.logger;
const LOG_PREFIX = "applicationDocumentUploadQueue::";

// Type

type IQueuedItemKey = {
  documentName: string;
  applicationId: number;
};

type IQueuedItem = IQueuedItemKey & {
  isProcessing: boolean;
  isProcessed: boolean;
  file: File;
};

type IDocumentContainerItem = {
  applicationId: number;
  documentContainerId: number;
  documentCategoryId: number;
  file: File;
};

type IApplicationDocumentUploadQueueState = {
  queuedItems: IQueuedItem[];
  duplicatedItems: IDocumentContainerItem[];
  expiredItems: IDocumentContainerItem[];
};

// Actions & Thunks

const addQueuedItem = createAction<IQueuedItemKey & { file: File }>(
  "appState/applicationDocumentUploadQueue/addQueuedItem"
);

const addDuplicatedItems = createAction<IDocumentContainerItem[]>(
  "appState/applicationDocumentUploadQueue/addDuplicatedItems"
);

export const removeDuplicatedItems = createAction<{ applicationId: number; documentContainerId: number }>(
  "appState/applicationDocumentUploadQueue/removeDuplicatedItems"
);

export const clearDuplicatedItems = createAction("appState/applicationDocumentUploadQueue/clearDuplicatedItems");

const addExpiredItems = createAction<IDocumentContainerItem[]>(
  "appState/applicationDocumentUploadQueue/addExpiredItems"
);

export const removeExpiredItems = createAction<{ applicationId: number; documentContainerId: number }>(
  "appState/applicationDocumentUploadQueue/removeExpiredItems"
);

export const clearExpiredItems = createAction("appState/applicationDocumentUploadQueue/clearExpiredItems");

const startProcessQueuedItem = createAction<IQueuedItemKey>(
  "appState/applicationDocumentUploadQueue/startProcessQueuedItem"
);

const endProcessQueuedItem = createAction<IQueuedItemKey>(
  "appState/applicationDocumentUploadQueue/endProcessQueuedItem"
);

const clearQueue = createAction("appState/applicationDocumentUploadQueue/clearQueue");

// Promises pool to store upload document promises for cancellation
const processQueuedItemPromisesPool: Record<string, Promise<any> & { abort: Function }> = {};

/**
 * Create application document and add them to queue
 */
export const addFilesToQueue = createAsyncThunk<
  { newDocuments: IDocumentEntity[]; duplicatedDocuments: IDocumentEntity[] },
  {
    files: File[];
    applicationId: number;
    documentContainerId: number;
    documentCategoryId: number;
  },
  { state: RootState }
>("appState/applicationDocumentUploadQueue/addFilesToQueue", async (arg, thunkAPI) => {
  const { files, applicationId, documentContainerId, documentCategoryId } = arg;
  const duplicatedItems: IDocumentContainerItem[] = [];
  const expiredItems: IDocumentContainerItem[] = [];

  const newDocuments: IDocumentEntity[] = [];
  const duplicatedDocuments: IDocumentEntity[] = [];

  for (const _file of files) {
    logger.debug(LOG_PREFIX, "Add file to queue: ", _file);

    let file: File;

    // if Image file is not enabled, we do not convert the images
    if (_file.type === MIME_TYPES.IMAGE_PNG || _file.type === MIME_TYPES.IMAGE_JPEG) {
      file = await convertImageToPdf(_file);
    } else {
      file = _file;
    }

    const isExpiredFile = selectIsDocumentExpired(thunkAPI.getState(), { documentContainerId, file: _file });
    if (isExpiredFile) {
      expiredItems.push({ applicationId, documentCategoryId, documentContainerId, file });
      logger.debug(LOG_PREFIX, "Expired file detected: ", file.name);
      continue;
    }

    const duplicateDocumentProps = { applicationId, documentContainerId, file };
    const duplicateDocument = selectDuplicateFileForContainer(thunkAPI.getState(), duplicateDocumentProps);
    if (duplicateDocument) {
      const duplicatedItem: IDocumentContainerItem = { applicationId, documentCategoryId, documentContainerId, file };
      duplicatedItems.push(duplicatedItem);
      duplicatedDocuments.push(duplicateDocument);
      logger.debug(LOG_PREFIX, "Duplicated item detected: ", duplicatedItem);
      continue;
    }

    // Create document record
    const applicationDocumentService = serviceContainer.cradle.applicationDocumentService;
    const createDocumentArgs: Parameters<typeof applicationDocumentService.createApplicationDocument>[0] = {
      applicationId,
      file,
      documentContainerId,
      documentCategoryId,
    };
    logger.debug(LOG_PREFIX, "Creating document: ", createDocumentArgs);
    const {
      document,
      applicationDocumentRelation: relation,
    } = await applicationDocumentService.createApplicationDocument(createDocumentArgs);
    logger.debug(LOG_PREFIX, "Created document: ", { document, relation });
    newDocuments.push(document);
    batch(() => {
      thunkAPI.dispatch(upsertDocument(document));
      thunkAPI.dispatch(upsertApplicationDocumentRelation(relation));
      thunkAPI.dispatch(addQueuedItem({ documentName: document.name, applicationId, file }));
    });
  }

  thunkAPI.dispatch(addExpiredItems(expiredItems));
  thunkAPI.dispatch(addDuplicatedItems(duplicatedItems));
  return { newDocuments, duplicatedDocuments };
});

/**
 * Find and process the next queued item
 */
export const processNextQueuedItem = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
  }
>("appState/applicationDocumentUploadQueue/processNextQueuedItem", async (_, thunkAPI) => {
  const { t } = serviceContainer.cradle.i18n;
  const items = selectApplicationDocumentUploadQueuedItems(thunkAPI.getState());
  const item = items.find((item) => !item.isProcessing && !item.isProcessed);

  logger.debug(LOG_PREFIX, "processNextQueuedItem: ", item);

  if (!item) {
    logger.debug(LOG_PREFIX, "No pending item, clear the queue");
    thunkAPI.dispatch(clearQueue());
    return;
  }

  const { applicationId, documentName } = item;

  thunkAPI.dispatch(startProcessQueuedItem({ applicationId, documentName }));

  const document = selectDocumentEntityByName(thunkAPI.getState(), documentName);
  const file = item.file;
  const presignedUrl = document?.presignedUrl;
  const relation = selectApplicationDocumentRelationByKey(
    thunkAPI.getState(),
    ApplicationDocumentRelationUtil.generateEntityKey({ applicationId, documentName })
  );

  try {
    logger.debug(LOG_PREFIX, "Validate queued item: ", { file, presignedUrl, relation });
    if (!file || !presignedUrl || !relation) {
      throw new ServiceError(ServiceErrorCode.ClientError, t(`Upload failed`));
    }

    await thunkAPI.dispatch(
      updateApplicationDocumentStatus({
        applicationId,
        documentName,
        status: DocumentStatus.Uploading,
      })
    );

    // Register promise for cancellation
    const uploadDocumentPromise = thunkAPI.dispatch(uploadFile({ file, presignedUrl }));
    processQueuedItemPromisesPool[documentName] = uploadDocumentPromise;
    await uploadDocumentPromise.then(unwrapResult).finally(() => {
      delete processQueuedItemPromisesPool[documentName];
    });
  } catch (e) {
    logger.debug(LOG_PREFIX, "processNextQueuedItem::Error", e);

    await thunkAPI.dispatch(
      updateApplicationDocumentStatus({
        applicationId,
        documentName,
        status: DocumentStatus.Error,
      })
    );

    throw e;
  } finally {
    thunkAPI.dispatch(endProcessQueuedItem({ applicationId, documentName }));
  }
});

export const addDuplicatedItemsToQueueWithRename = createAsyncThunk<
  IDocumentEntity[],
  { applicationId: number; documentContainerId: number },
  { state: RootState }
>(
  "appState/applicationDocumentUploadQueue/addDuplicatedItemsToQueueWithRename",
  async ({ applicationId, documentContainerId }, thunkAPI) => {
    const duplicatedItems = selectApplicationDocumentUploadDuplicatedItemsByApplicationAndContainer(
      thunkAPI.getState(),
      { applicationId, documentContainerId }
    );
    const newDocuments: IDocumentEntity[] = [];
    for (const duplicatedItem of duplicatedItems) {
      logger.debug(LOG_PREFIX, "Add duplicated item to queue: ", duplicatedItem);

      const { applicationId, documentContainerId, documentCategoryId, file } = duplicatedItem;
      const { fileNameWithoutExt, fileExt } = getFileNameComponents(duplicatedItem.file.name);
      let newSuffix = 1;
      let newFileName = `${fileNameWithoutExt}_${newSuffix}.${fileExt}`;

      const documentsInContainer = selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments(
        thunkAPI.getState(),
        { applicationId, documentContainerId }
      );
      // eslint-disable-next-line no-loop-func
      while (documentsInContainer.find((doc) => doc.fileName === newFileName)) {
        newSuffix++;
        newFileName = `${fileNameWithoutExt}_${newSuffix}.${fileExt}`;
      }

      // Create document record
      const applicationDocumentService = serviceContainer.cradle.applicationDocumentService;
      const createDocumentArgs: Parameters<typeof applicationDocumentService.createApplicationDocument>[0] = {
        applicationId,
        file,
        documentContainerId,
        documentCategoryId,
        fileName: newFileName,
      };
      logger.debug(LOG_PREFIX, "Creating document: ", createDocumentArgs);
      const {
        document,
        applicationDocumentRelation: relation,
      } = await applicationDocumentService.createApplicationDocument(createDocumentArgs);
      newDocuments.push(document);
      logger.debug(LOG_PREFIX, "Created document: ", { document, relation });

      batch(() => {
        thunkAPI.dispatch(upsertDocument(document));
        thunkAPI.dispatch(upsertApplicationDocumentRelation(relation));
        thunkAPI.dispatch(addQueuedItem({ documentName: document.name, applicationId, file }));
      });
    }

    thunkAPI.dispatch(removeDuplicatedItems({ applicationId, documentContainerId }));
    return newDocuments;
  }
);

export const addExpiredItemsToQueue = createAsyncThunk<
  IDocumentEntity[],
  { applicationId: number; documentContainerId: number },
  { state: RootState }
>(
  "appState/applicationDocumentUploadQueue/addExpiredItemsToQueue",
  async ({ applicationId, documentContainerId }, thunkAPI) => {
    // Get expired documents
    const expiredItems = selectApplicationDocumentUploadExpiredItemsByApplicationAndContainer(thunkAPI.getState(), {
      applicationId,
      documentContainerId,
    });
    const duplicatedItems: IDocumentContainerItem[] = [];

    const newDocuments: IDocumentEntity[] = [];
    for (const expiredItem of expiredItems) {
      logger.debug(LOG_PREFIX, "Add expired item to queue: ", expiredItem);

      // Create document record
      const { file, documentCategoryId } = expiredItem;

      // In case expired items and duplicate
      const duplicateDocumentProps = { applicationId, documentContainerId, file };
      const duplicateDocument = selectDuplicateFileForContainer(thunkAPI.getState(), duplicateDocumentProps);
      if (duplicateDocument) {
        const duplicatedItem: IDocumentContainerItem = { applicationId, documentCategoryId, documentContainerId, file };
        duplicatedItems.push(duplicatedItem);
        logger.debug(LOG_PREFIX, "Expired and Duplicated item detected: ", duplicatedItem);
        continue;
      }

      const applicationDocumentService = serviceContainer.cradle.applicationDocumentService;
      const createDocumentArgs: Parameters<typeof applicationDocumentService.createApplicationDocument>[0] = {
        applicationId,
        file,
        documentContainerId,
        documentCategoryId,
      };
      logger.debug(LOG_PREFIX, "Creating expired document: ", createDocumentArgs);
      const {
        document,
        applicationDocumentRelation: relation,
      } = await applicationDocumentService.createApplicationDocument(createDocumentArgs);
      newDocuments.push(document);
      logger.debug(LOG_PREFIX, "Created expired document: ", { document, relation });

      batch(() => {
        thunkAPI.dispatch(addQueuedItem({ documentName: document.name, applicationId, file }));
        thunkAPI.dispatch(upsertDocument(document));
        thunkAPI.dispatch(upsertApplicationDocumentRelation(relation));
      });
    }

    thunkAPI.dispatch(addDuplicatedItems(duplicatedItems));
    thunkAPI.dispatch(removeExpiredItems({ applicationId, documentContainerId }));
    return newDocuments;
  }
);

export const cancelUploadDocumentInQueue = createAsyncThunk<void, string, { state: RootState }>(
  "appState/applicationDocumentUploadQueue/cancelUploadDocumentInQueue",
  async (documentName, thunkAPI) => {
    const promise = get(processQueuedItemPromisesPool, documentName);

    if (!promise) {
      // Not uploading, just upload status
      logger.debug(LOG_PREFIX, `cancelUploadDocumentInQueue: ${documentName} is not uploading`);
      const queuedItems = selectApplicationDocumentUploadQueuedItems(thunkAPI.getState()).filter(
        (item) => item.documentName === documentName
      );
      batch(() => {
        for (const queuedItem of queuedItems) {
          thunkAPI.dispatch(endProcessQueuedItem(queuedItem));
        }
      });

      return;
    }

    // Uploading, abort
    logger.debug(LOG_PREFIX, `cancelUploadDocumentInQueue: ${documentName} is uploading`);
    promise.abort();
    delete processQueuedItemPromisesPool[documentName];
  }
);

// Reducer

const initialState: IApplicationDocumentUploadQueueState = {
  queuedItems: [],
  duplicatedItems: [],
  expiredItems: [],
};

export const applicationDocumentUploadQueueReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addQueuedItem, (draft, action) => {
      draft.queuedItems.push({
        ...action.payload,
        isProcessing: false,
        isProcessed: false,
      });
    })
    .addCase(startProcessQueuedItem, (draft, action) => {
      const { applicationId, documentName } = action.payload;
      const item = draft.queuedItems.find((item) => {
        return item.documentName === documentName && item.applicationId === applicationId;
      });
      if (item) {
        item.isProcessing = true;
        item.isProcessed = false;
      }
    })
    .addCase(endProcessQueuedItem, (draft, action) => {
      const { applicationId, documentName } = action.payload;
      const item = draft.queuedItems.find((item) => {
        return item.documentName === documentName && item.applicationId === applicationId;
      });
      if (item) {
        item.isProcessing = false;
        item.isProcessed = true;
      }
    })
    .addCase(clearQueue, (draft) => {
      draft.queuedItems = [];
    })
    .addCase(addDuplicatedItems, (draft, action) => {
      draft.duplicatedItems = draft.duplicatedItems.concat(action.payload);
    })
    .addCase(clearDuplicatedItems, (draft) => {
      draft.duplicatedItems = [];
    })
    .addCase(removeDuplicatedItems, (draft, action) => {
      draft.duplicatedItems = draft.duplicatedItems.filter((item) => {
        const shouldDuplicatedItemsBeRemoved =
          item.applicationId === action.payload.applicationId &&
          item.documentContainerId === action.payload.documentContainerId;
        return !shouldDuplicatedItemsBeRemoved;
      });
    })
    .addCase(addExpiredItems, (draft, action) => {
      draft.expiredItems = draft.expiredItems.concat(action.payload);
    })
    .addCase(removeExpiredItems, (draft, action) => {
      draft.expiredItems = draft.expiredItems.filter((item) => {
        const shouldExpiredItemsBeRemoved =
          item.applicationId === action.payload.applicationId &&
          item.documentContainerId === action.payload.documentContainerId;
        return !shouldExpiredItemsBeRemoved;
      });
    })
    .addCase(clearExpiredItems, (draft) => {
      draft.expiredItems = [];
    });
});

// Selector
export const selectApplicationDocumentUploadQueueState = (state: RootState) => {
  return state.appState.applicationDocumentUploadQueue;
};

export const selectApplicationDocumentUploadQueuedItems = createDeepEqualSelector(
  [selectApplicationDocumentUploadQueueState],
  (state) => {
    return state.queuedItems;
  }
);

export const selectApplicationDocumentUploadDuplicatedItems = createDeepEqualSelector(
  [selectApplicationDocumentUploadQueueState],
  (state) => {
    return state.duplicatedItems;
  }
);

export const selectApplicationDocumentUploadDuplicatedItemsByApplicationAndContainer = createDeepEqualSelector(
  [
    selectApplicationDocumentUploadQueueState,
    (_: RootState, props: { applicationId: number; documentContainerId: number }) => props,
  ],
  (state, { applicationId, documentContainerId }) => {
    return state.duplicatedItems.filter(
      (item) => item.applicationId === applicationId && item.documentContainerId === documentContainerId
    );
  }
);

export const selectApplicationDocumentUploadExpiredItemsByApplicationAndContainer = createDeepEqualSelector(
  [
    selectApplicationDocumentUploadQueueState,
    (_: RootState, props: { applicationId: number; documentContainerId: number }) => props,
  ],
  (state, { applicationId, documentContainerId }) => {
    return state.expiredItems.filter(
      (item) => item.applicationId === applicationId && item.documentContainerId === documentContainerId
    );
  }
);

export const selectIsDocumentExpired = createDeepEqualSelector(
  [
    (state: RootState, args: { documentContainerId: number }) =>
      selectDocumentContainersEntityById(state, args.documentContainerId),
    (state: RootState, args: { file: File }) => args.file,
  ],
  (documentContainer, file) => {
    if (!documentContainer) {
      return false;
    }
    if (DocumentContainerUtil.isProofOfOwnership(documentContainer) || DocumentContainerUtil.isPIM(documentContainer)) {
      const lastModifiedDate = new Date(file.lastModified);
      const currentDate = new Date();
      const cutoffDate = subMonths(currentDate, 3);
      return isBefore(lastModifiedDate, cutoffDate);
    }

    return false;
  }
);

export const selectDuplicateFileForContainer = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number; documentContainerId: number }) => {
      const existingApplicationDocumentRelations = selectApplicationDocumentRelationsInApplicationContainer(state, {
        applicationId: args.applicationId,
        documentContainerId: args.documentContainerId,
      });
      const existingDocuments = existingApplicationDocumentRelations
        .map((relation) => {
          const document = selectDocumentEntityByName(state, relation.documentName);
          return document;
        })
        .filter((item) => Boolean(item));
      return existingDocuments;
    },
    (state: RootState, args: { file: File }) => args.file,
  ],
  (existingDocuments, file) => {
    const duplicateDocument = existingDocuments.find((document) => document?.fileName === file.name);
    return duplicateDocument;
  }
);
