import { IQualificationTypeEntity } from "models/Qualification.model";
import { createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import sortBy from "lodash/sortBy";

// Entity Adapter

const qualificationTypeAdapter = createEntityAdapter<IQualificationTypeEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => a.id - b.id,
});

//Action & Thunks
export const fetchQualificationTypes = createAsyncThunk(
  "domainData/qualificationTypes/fetchQualificationTypeByApplicationId",
  async () => {
    return await serviceContainer.cradle.qualificationTypeService.fetchQualificationTypes();
  }
);

// Reducer

export const defaultQualificationTypeState = qualificationTypeAdapter.getInitialState();

export const qualificationTypeReducer = createReducer<typeof defaultQualificationTypeState>(
  defaultQualificationTypeState,
  (builder) => builder.addCase(fetchQualificationTypes.fulfilled, qualificationTypeAdapter.upsertMany)
);

// Selectors

export const { selectAll: selectAllQualificationTypeEntities } = qualificationTypeAdapter.getSelectors(
  (state: RootState) => state.domainData.qualificationType
);

export const selectLicensingClassesByQualificationName = createDeepEqualSelector(
  [
    (state: RootState) => selectAllQualificationTypeEntities(state),
    (state: RootState, qualificationName: string) => qualificationName,
  ],
  (qualificationTypes, qualificationName) => {
    const qualificationType = qualificationTypes.find((item) => item.name === qualificationName);
    if (!qualificationType) {
      return [];
    }
    const licensingClasses = sortBy(qualificationType.classes, (item) => item.displayOrder);
    return licensingClasses;
  }
);
