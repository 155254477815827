import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { IDocumentTypeEntity } from "models/DocumentType.model";
import { createDeepEqualSelector } from "store/utils";
import {
  selectJurisdictionFormDocumentTypeRelationsByJurisdictionFormId,
  upsertFormDocumentTypeRelations,
} from "store/domain-data/jurisdiction-form-document-type-relation/jurisdictionFormDocumentTypeRelation";
import {
  selectJurisdictionDocumentTypeRelationsByJurisdictionId,
  upsertJurisdictionDocumentTypeRelation,
} from "store/domain-data/jurisdiction-document-type-relation/jurisdictionDocumentTypeRelation";
import { batch } from "react-redux";
import { IDocumentTypeService } from "services/document-type/DocumentTypeService.types";
import {
  removeDocumentTypeRelations,
  selectDocumentTypeRelationsByDocumentName,
  upsertDocumentTypeRelations,
} from "store/domain-data/document-type-relation/documentTypeRelation";

// Entity Adapter

const documentTypeAdapter = createEntityAdapter<IDocumentTypeEntity>({
  selectId: (documentTypes) => documentTypes.id,
  sortComparer: (a, b) => a.displayOrder - b.displayOrder,
});

// Action & Thunks

export const fetchDocumentTypesByJurisdiction = createAsyncThunk(
  "domainData/documentType/fetchDocumentTypeByJurisdiction",
  async (jurisdictionId: number, thunkAPI) => {
    const documentTypes = await serviceContainer.cradle.documentTypeService.fetchDocumentTypesByJurisdiction(
      jurisdictionId
    );

    const documentTypeIds = documentTypes.map((documentType) => documentType.id);
    const relations = documentTypeIds.map((documentTypeId) => {
      return {
        documentTypeId,
        jurisdictionId,
      };
    });
    thunkAPI.dispatch(upsertJurisdictionDocumentTypeRelation(relations));

    return documentTypes;
  }
);

export const fetchDocumentTypesByJurisdictionForm = createAsyncThunk(
  "domainData/documentType/fetchDocumentTypeByJurisdictionForm",
  async ({ jurisdictionId, formId }: { jurisdictionId: number; formId: number }, thunkAPI) => {
    const documentTypes = await serviceContainer.cradle.documentTypeService.fetchDocumentTypesByJurisdictionForm({
      jurisdictionId,
      formId,
    });

    const documentTypeIds = documentTypes.map((documentType) => documentType.id);
    const relations = documentTypeIds.map((documentTypeId) => {
      return {
        documentTypeId: documentTypeId,
        jurisdictionFormId: formId,
      };
    });
    thunkAPI.dispatch(upsertFormDocumentTypeRelations(relations));

    return documentTypes;
  }
);

export const upsertDocumentTypes = createAction<IDocumentTypeEntity[]>("domainData/documentType/upsertDocumentTypes");

export const fetchDocumentTypesByDocumentName = createAsyncThunk(
  "domainData/documentType/fetchDocumentTypesByDocument",
  async ({ applicationId, documentName }: { applicationId: number; documentName: string }, _) => {
    const documentTypes = await serviceContainer.cradle.documentTypeService.fetchDocumentTypesByApplicationDocument({
      applicationId: applicationId,
      documentName,
    });
    return documentTypes;
  }
);

export const setDocumentTypesForApplicationDocument = createAsyncThunk(
  "domainData/documentType/setDocumentTypesForApplicationDocument",
  async (args: Parameters<IDocumentTypeService["updateDocumentTypesForApplicationDocument"]>[0], thunkAPI) => {
    const documentTypeRelation = await serviceContainer.cradle.documentTypeService.updateDocumentTypesForApplicationDocument(
      args
    );

    const legacyDocumentTypeRelations = selectDocumentTypeRelationsByDocumentName(
      thunkAPI.getState() as RootState,
      args.documentName
    );
    batch(() => {
      thunkAPI.dispatch(removeDocumentTypeRelations(legacyDocumentTypeRelations));
      thunkAPI.dispatch(upsertDocumentTypeRelations(documentTypeRelation));
    });
  }
);

// Reducer

export const documentTypeReducer = createReducer(documentTypeAdapter.getInitialState(), (builder) =>
  builder
    .addCase(fetchDocumentTypesByJurisdiction.fulfilled, (draft, action) => {
      documentTypeAdapter.upsertMany(draft, action.payload);
    })
    .addCase(fetchDocumentTypesByJurisdictionForm.fulfilled, (draft, action) => {
      documentTypeAdapter.upsertMany(draft, action.payload);
    })
    .addCase(fetchDocumentTypesByDocumentName.fulfilled, (draft, action) => {
      documentTypeAdapter.upsertMany(draft, action.payload);
    })
    .addCase(upsertDocumentTypes, (draft, action) => {
      documentTypeAdapter.upsertMany(draft, action.payload);
    })
);

// Selectors

export const { selectAll: selectAllDocumentTypeEntities } = documentTypeAdapter.getSelectors(
  (state: RootState) => state.domainData.documentType
);

export const selectDocumentTypeEntitiesByJurisdictionId = createDeepEqualSelector(
  [selectAllDocumentTypeEntities, selectJurisdictionDocumentTypeRelationsByJurisdictionId],
  (allDocumentTypes, relations) => {
    const documentTypeIds = relations.map((rel) => rel.documentTypeId);
    return allDocumentTypes.filter((documentType) => documentTypeIds.includes(documentType.id));
  }
);
export const selectDocumentTypeEntitiesByJurisdictionFormId = createDeepEqualSelector(
  [selectAllDocumentTypeEntities, selectJurisdictionFormDocumentTypeRelationsByJurisdictionFormId],
  (allDocumentTypes, relations) => {
    const documentTypeIds = relations.map((rel) => rel.documentTypeId);
    return allDocumentTypes.filter((documentType) => documentTypeIds.includes(documentType.id));
  }
);
export const selectDocumentTypesByDocumentName = createDeepEqualSelector(
  [selectAllDocumentTypeEntities, selectDocumentTypeRelationsByDocumentName],
  (allDocumentTypes, documentTypeRelations) => {
    const documentTypeIds = documentTypeRelations.map((rel) => rel.documentTypeId);
    return allDocumentTypes.filter((type) => documentTypeIds.includes(type.id));
  }
);
