import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import {
  IParticipantTypeApplicationEntity,
  IParticipantTypeEntity,
  ParticipantTypeExtraNumber,
  ParticipantTypeOccurrences,
} from "models/Participant.model";
import { createDeepEqualSelector } from "store/utils";
import { serviceContainer } from "services/serviceContainer";
import {
  selectParticipantEntitiesByApplicationId,
  selectParticipantUserByApplicationId,
} from "store/domain-data/participant/participant";
import { IParticipantBufferEntity } from "store/app-state/participant-buffer/participantBuffer";

// Entity Adapter

const participantTypeAdapter = createEntityAdapter<IParticipantTypeApplicationEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Action & Thunks

export const fetchParticipantTypes = createAsyncThunk("/fetchParticipantTypes", async (applicationId: number) => {
  const results = await serviceContainer.cradle.participantTypeService.fetchParticipantTypesByApplicationId(
    applicationId
  );
  return results;
});

export const loadParticipantTypes = createAction<IParticipantTypeApplicationEntity[]>(
  "domainData/participantType/loadParticipantTypes"
);

// Reducer

export const defaultParticipantTypeState = participantTypeAdapter.getInitialState();

export const participantTypeReducer = createReducer<typeof defaultParticipantTypeState>(
  defaultParticipantTypeState,
  (builder) =>
    builder
      .addCase(fetchParticipantTypes.fulfilled, participantTypeAdapter.upsertMany)
      .addCase(loadParticipantTypes, participantTypeAdapter.upsertMany)
);

// Selectors

export const {
  selectById: selectParticipantTypeEntityById,
  selectAll: selectAllParticipantTypeEntities,
  selectTotal: selectTotalParticipantTypeEntities,
} = participantTypeAdapter.getSelectors((state: RootState) => state.domainData.participantType);

export const selectAllParticipantTypes = selectAllParticipantTypeEntities;

export const selectRequiredParticipantTypeEntities = createDeepEqualSelector(
  [selectAllParticipantTypeEntities],
  (allEntities) => {
    return Object.values(allEntities).filter((participantType) => !!participantType.required);
  }
);

export const selectOptionalParticipantTypeEntities = createDeepEqualSelector(
  [selectAllParticipantTypeEntities],
  (allEntities) => {
    return Object.values(allEntities).filter((participantType) => !participantType.required);
  }
);

type SelectParticipantTypeByNameProps = {
  participantTypeName: string;
};

export const selectParticipantTypeByName = createDeepEqualSelector(
  [
    selectAllParticipantTypeEntities,
    (state: RootState, props: SelectParticipantTypeByNameProps) => props.participantTypeName,
  ],
  (allEntities, participantTypeName) => {
    return Object.values(allEntities).find((participantType) => participantType.name === participantTypeName);
  }
);

type SelectParticipantTypeOccurrencesProps = {
  applicationId: number;
  participantTypeName: string;
};

export const selectParticipantTypeOccurrences = createDeepEqualSelector(
  [
    (state: RootState, props: SelectParticipantTypeOccurrencesProps) =>
      selectParticipantEntitiesByApplicationId(state, props.applicationId),
    (state: RootState, props: SelectParticipantTypeOccurrencesProps) => props.participantTypeName,
  ],
  (allParticipants, participantTypeName): ParticipantTypeOccurrences => {
    const participantsWithMatchingType = allParticipants.filter((participant) =>
      participant.participantTypes.some((type) => type.name === participantTypeName)
    );

    return {
      participantTypeName,
      participants: participantsWithMatchingType,
    };
  }
);

export const selectNumberOfParticipantTypeOccurrences = createDeepEqualSelector(
  [selectParticipantTypeOccurrences],
  (participantTypeOccurrences) => {
    return participantTypeOccurrences.participants.length;
  }
);

export const selectMatchingApplicationParticipantTypes = createDeepEqualSelector(
  [
    selectAllParticipantTypeEntities,
    (state: RootState, selectedParticipantTypes: IParticipantTypeEntity[]) => selectedParticipantTypes,
  ],
  (applicationParticipantTypes, selectedParticipantTypes) => {
    // Map participant types to application participant types
    return applicationParticipantTypes.filter((applicationType) => {
      return selectedParticipantTypes.some((selectedType) => selectedType.name === applicationType.name);
    });
  }
);

// Remove masked by for selected participant types
export const selectVisibleSavedParticipantTypes = createDeepEqualSelector(
  [
    (state: RootState, selectedParticipantTypes: IParticipantTypeEntity[]) =>
      selectMatchingApplicationParticipantTypes(state, selectedParticipantTypes),
  ],
  (selectedParticipantTypes: IParticipantTypeApplicationEntity[]) => {
    return selectedParticipantTypes.filter((participantType) => {
      const isMasked =
        participantType.maskedBy && selectedParticipantTypes.some((type) => type.name === participantType.maskedBy);
      return !isMasked;
    });
  }
);

// Remove masked by for all participant types
export const selectVisibleParticipantTypes = createDeepEqualSelector(
  [
    selectAllParticipantTypeEntities,
    (state: RootState, selectedParticipantTypes: IParticipantTypeEntity[]) =>
      selectedParticipantTypes.map((participantType) => participantType.name),
  ],
  (participantTypes: IParticipantTypeApplicationEntity[], selectedParticipantTypesNames: string[]) => {
    return participantTypes.filter((participantType) => {
      if (!participantType.maskedBy) {
        return true;
      }
      // eg. If agent masks owner contact but agent is not selected than show agent
      if (!selectedParticipantTypesNames.includes(participantType.name)) {
        return true;
      }

      const isMasked = selectedParticipantTypesNames.includes(participantType.maskedBy);
      return !isMasked;
    });
  }
);

export const selectMustInviteSelectedParticipantTypes = createDeepEqualSelector(
  [
    selectAllParticipantTypeEntities,
    (state: RootState, props: { selectedParticipantTypes: IParticipantTypeEntity[] }) =>
      props.selectedParticipantTypes.map((participantType) => participantType.name),
  ],
  (participantTypes: IParticipantTypeApplicationEntity[], selectedParticipantTypes: string[]) =>
    participantTypes.filter(
      (participantType) => participantType.mustInvite && selectedParticipantTypes.includes(participantType.name)
    )
);

/**
 * @deprecated
 *
 * This selector relies on Participant Buffer to work, because it requires participant.participantTypes to have full information
 */
export const selectNonDeletableParticipantTypes = createDeepEqualSelector(
  [
    (state: RootState, selectedParticipant: IParticipantBufferEntity) =>
      selectParticipantEntitiesByApplicationId(state, selectedParticipant.applicationId),
    (state: RootState, selectedParticipant: IParticipantBufferEntity) =>
      selectMatchingApplicationParticipantTypes(state, selectedParticipant.participantTypes),
    (state: RootState, selectedParticipant: IParticipantBufferEntity) => selectedParticipant.id,
  ],
  (allParticipants, selectedApplicationParticipantTypes, selectedParticipantId) => {
    // Get non selected participant types
    const nonSelectedParticipants = allParticipants.filter((participant) => participant.id !== selectedParticipantId);
    const nonSelectedTypes = nonSelectedParticipants.map((participant) => participant.participantTypes).flat();

    const selectedRequiredTypes = selectedApplicationParticipantTypes.filter((appParticipantType) => {
      if (appParticipantType.number === ParticipantTypeExtraNumber.ExactlyOne) {
        return true;
      }
      if (appParticipantType.number === ParticipantTypeExtraNumber.MinimumOne) {
        const found = nonSelectedTypes.some((nonSelectedType) => nonSelectedType.name === appParticipantType.name);
        return !found;
      }
      return false;
    });

    // For a required type that is maskedBy another type that is also selected, replace with it's masking type
    const visibleRequiredTypes = selectedRequiredTypes.map((requiredType) => {
      const maskingType = selectedApplicationParticipantTypes.find((selectedType) => {
        return requiredType.maskedBy === selectedType.name;
      });

      return maskingType || requiredType;
    });

    // Remove duplicates when a type that is now masking (i.e displayed in place of) another required type, is itself
    // a required type, so was already in the selectedRequiredTypes list. Edge case.
    const uniqueVisibleRequiredTypes = visibleRequiredTypes.filter(
      (item: IParticipantTypeApplicationEntity, idx: number) => visibleRequiredTypes.indexOf(item) === idx
    );

    return uniqueVisibleRequiredTypes;
  }
);

export const selectParticipantTypesBeingManagedByCurrentUser = createDeepEqualSelector(
  [
    (state: RootState, applicationId: number) => selectParticipantUserByApplicationId(state, applicationId),
    selectAllParticipantTypes,
  ],
  (usersParticipant, participantTypes) => {
    return participantTypes.filter((type) => {
      return usersParticipant?.participantTypes.some(
        (usersParticipantType) => usersParticipantType.name === type.managedBy
      );
    });
  }
);

type SelectUnassignedParticipantTypesBeingManagedByCurrentUserProps = {
  applicationId: number;
  currentParticipantTypes: IParticipantTypeEntity[];
};

export const selectUnassignedParticipantTypesBeingManagedByCurrentUser = createDeepEqualSelector(
  [
    (state: RootState, props: SelectUnassignedParticipantTypesBeingManagedByCurrentUserProps) =>
      selectParticipantTypesBeingManagedByCurrentUser(state, props.applicationId),
    (state: RootState, props: SelectUnassignedParticipantTypesBeingManagedByCurrentUserProps) =>
      props.currentParticipantTypes,
  ],
  (participantTypesToAssign, currentParticipantTypes) => {
    return participantTypesToAssign.filter((typeToAssign) => {
      return !currentParticipantTypes.some(
        (currentParticipantType) => currentParticipantType.name === typeToAssign.name
      );
    });
  }
);

export const selectUnassignedRequiredParticipantTypes = createDeepEqualSelector(
  [
    (state: RootState, applicationId: number) => selectParticipantEntitiesByApplicationId(state, applicationId),
    selectAllParticipantTypes,
  ],
  (participants, participantTypes) => {
    return participantTypes.filter((type) => {
      return (
        type.required &&
        !participants.some((participant) => {
          return participant.participantTypes.some(
            (currentParticipantType) => currentParticipantType.name === type.name
          );
        })
      );
    });
  }
);
