import React from "react";
import styled, { css } from "styled-components/macro";
import FontIcon from "components/FontIcon/FontIcon";
import IconButton from "@material-ui/core/IconButton";
import { ODL_ICONS } from "components/FontIcon/FontIcon.constant";

type Props = {
  onClick: () => any;
};

const RemoveIconButton = ({ onClick }: Props) => {
  return (
    <React.Fragment>
      <StyledDeleteButton data-testid="RemoveIconButton" onClick={onClick}>
        <StyledRemoveIcon name={ODL_ICONS.CLOSE_CIRCLE} />
      </StyledDeleteButton>
    </React.Fragment>
  );
};

const StyledDeleteButton = styled(IconButton)(
  ({ theme }) => css`
    width: 44px;
    height: 44px;
  `
);

const StyledRemoveIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};
  `
);

export default RemoveIconButton;
