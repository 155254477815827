import React from "react";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import { serviceContainer } from "services/serviceContainer";

type FetchDataForPageResult<T> = {
  data: T[];
  hasNextPage: boolean;
};

export type FetchDataForPageFunc<T> = (pageIndex: number) => Promise<FetchDataForPageResult<T>>;

export const emptyFetchDataForPage: FetchDataForPageFunc<any> = async () => {
  return {
    data: [],
    hasNextPage: false,
  };
};

export const usePaginatedData = <T extends unknown>(fetchDataForPage: FetchDataForPageFunc<T>) => {
  const pageIndexRef = React.useRef(0);

  const [data, setData] = React.useState<T[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasNextPage, setHasNextPage] = React.useState(false);

  const loadNextPage = React.useCallback(async () => {
    try {
      setIsLoading(true);
      pageIndexRef.current += 1;
      const newData = await fetchDataForPage(pageIndexRef.current);
      setData((existingData) => {
        const isFirstPage = pageIndexRef.current === 1;
        if (isFirstPage) {
          return newData.data;
        }
        return uniqWith([...existingData, ...newData.data], isEqual);
      });
      setHasNextPage(newData.hasNextPage);
    } catch (e) {
      serviceContainer.cradle.logger.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [fetchDataForPage]);

  React.useEffect(() => {
    pageIndexRef.current = 0;
    setData([]);
    loadNextPage();
  }, [loadNextPage]);

  return {
    data,
    hasNextPage,
    loadNextPage,
    isLoading,
  };
};
