import React from "react";
import styled, { css } from "styled-components/macro";

type Props = {
  "data-testid"?: string;
};

const Cards = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(({ children, ...otherProps }, ref) => {
  return (
    <StyledCardContainer {...otherProps} ref={ref}>
      {children}
    </StyledCardContainer>
  );
});

const StyledCardContainer = styled.div(
  ({ theme }) => css`
    & > *:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.objective.light.day};
    }
  `
);

export default Cards;
