import React from "react";
import { useParticipants } from "hooks/useParticipants";
import { fetchParticipantTypes } from "store/domain-data/participant-type/participantType";
import { useStoreDispatch } from "store/hooks";
import ParticipantCard from "components/ParticipantCard/ParticipantCard";
import Cards from "components/odl-v2/Card/Cards";
import styled, { css } from "styled-components/macro";

type Props = {
  applicationId: number;
};

const ParticipantStepContent: React.FC<Props> = ({ applicationId }) => {
  const dispatch = useStoreDispatch();
  const { participants } = useParticipants(applicationId);
  const [isParticipantTypesReady, setParticipantTypesReady] = React.useState<boolean>(false);

  // Lifecycle
  React.useEffect(() => {
    const initData = async () => {
      await dispatch(fetchParticipantTypes(applicationId));
      setParticipantTypesReady(true);
    };
    initData();
  }, [applicationId, dispatch, isParticipantTypesReady]);

  if (!isParticipantTypesReady) {
    return null;
  }

  return (
    <Cards>
      {participants.map((participant) => (
        <StyledCardContainer key={participant.id}>
          <ParticipantCard participantId={participant.id} readOnly={true} />
        </StyledCardContainer>
      ))}
    </Cards>
  );
};

const StyledCardContainer = styled.div(
  ({ theme }) => css`
    // Flexbox does not support "break-inside", but we need to avoid page break when print
    display: block;
    break-inside: avoid;
  `
);

export default ParticipantStepContent;
