import { createAction, createAsyncThunk, createReducer, createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { selectJurisdictionEntityByName } from "store/domain-data/jurisdiction/jurisdiction";

// Types

export type ApplicationNavigationState = {
  jurisdictionId: number;
  applicationId: number;
  breadcrumbId: number;
  breadcrumbStepId: number;
  paymentId: number;
};

// Actions & Thunks

export const setCurrentJurisdictionId = createAction<number>("appState/applicationNavigation/setCurrentJurisdictionId");
export const updateCurrentJurisdictionIdByName = createAsyncThunk(
  "appState/applicationNavigation/updateCurrentJurisdictionIdByName",
  (name: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const jurisdiction = selectJurisdictionEntityByName(state, name);
    if (jurisdiction) {
      thunkAPI.dispatch(setCurrentJurisdictionId(jurisdiction.id));
    }
  }
);

export const setCurrentApplicationId = createAction<number>("appState/applicationNavigation/setCurrentApplicationId");
export const setCurrentBreadcrumbId = createAction<number>("appState/applicationNavigation/setCurrentBreadcrumbId");
export const setCurrentBreadcrumbStepId = createAction<number>(
  "appState/applicationNavigation/setCurrentBreadcrumbStepId"
);
export const setCurrentPaymentId = createAction<number>("appState/applicationNavigation/setCurrentPaymentId");

// Reducer

export const initialApplicationNavigationState: ApplicationNavigationState = {
  jurisdictionId: 0,
  applicationId: 0,
  breadcrumbId: 0,
  breadcrumbStepId: 0,
  paymentId: 0,
};

export const applicationNavigationReducer = createReducer(initialApplicationNavigationState, (builder) => {
  builder
    .addCase(setCurrentJurisdictionId, (draft, action) => {
      draft.jurisdictionId = action.payload;
    })
    .addCase(setCurrentApplicationId, (draft, action) => {
      draft.applicationId = action.payload;
      draft.breadcrumbId = 0;
      draft.breadcrumbStepId = 0;
      draft.paymentId = 0;
    })
    .addCase(setCurrentBreadcrumbId, (draft, action) => {
      draft.breadcrumbId = action.payload;
      draft.breadcrumbStepId = 0;
    })
    .addCase(setCurrentBreadcrumbStepId, (draft, action) => {
      draft.breadcrumbStepId = action.payload;
    })
    .addCase(setCurrentPaymentId, (draft, action) => {
      draft.paymentId = action.payload;
    });
});

// Selectors

const selectApplicationNavigationState = (state: RootState) => state.appState.applicationNavigation;

export const selectCurrentJurisdictionId = createSelector([selectApplicationNavigationState], (state) => {
  return state.jurisdictionId;
});

export const selectCurrentApplicationId = createSelector([selectApplicationNavigationState], (state) => {
  return state.applicationId;
});

export const selectCurrentBreadcrumbId = createSelector([selectApplicationNavigationState], (state) => {
  return state.breadcrumbId;
});

export const selectCurrentBreadcrumbStepId = createSelector([selectApplicationNavigationState], (state) => {
  return state.breadcrumbStepId;
});

export const selectCurrentPaymentId = createSelector([selectApplicationNavigationState], (state) => {
  return state.paymentId;
});
