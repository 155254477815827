import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import ParticipantCardActions from "components/ParticipantCard/ParticipantCardActions";
import {
  fetchParticipants,
  removeParticipantFromApplication,
  selectParticipantEntityById,
  selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
  sendParticipantInvite,
  updateParticipant,
} from "store/domain-data/participant/participant";
import ParticipantEditDialog from "components/ParticipantEditDialog/ParticipantEditDialog";
import { Trans, useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import {
  getParticipantName,
  InvitationStatus,
  IParticipantTypeApplicationEntity,
  ParticipantTypeExtraNumber,
} from "models/Participant.model";
import Spacer from "components/Spacer/Spacer";
import FlexBox from "components/FlexBox/FlexBox";
import Guard from "components/Guard/Guard";
import { unwrapResult } from "@reduxjs/toolkit";
import ParticipantChangeTypeConfirmationDialog from "components/ParticipantChangeTypeConfirmationDialog/ParticipantChangeTypeConfirmationDialog";
import {
  selectParticipantTypeOccurrences,
  selectUnassignedParticipantTypesBeingManagedByCurrentUser,
} from "store/domain-data/participant-type/participantType";
import { useStore } from "react-redux";
import ParticipantCardDetail from "components/ParticipantCard/ParticipantCardDetail";
import Card from "components/odl-v2/Card/Card";
import isEmpty from "lodash/isEmpty";
import styled, { css } from "styled-components/macro";
import { Box } from "@material-ui/core";
import { selectCanUserManageApplicationById } from "store/domain-data/application/application";
import { usePostHog } from "posthog-js/react";

type Props = {
  participantId: number;
  readOnly?: boolean;
};

const ParticipantCard: React.FC<Props> = ({ participantId, readOnly }) => {
  // Common
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastSuccess, toastError } = useToast();
  const store = useStore();
  const posthog = usePostHog();

  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isChangeParticipantTypeDialogOpen, setChangeParticipantTypeDialogOpen] = React.useState(false);
  const [
    participantTypeToChange,
    setParticipantTypeToChange,
  ] = React.useState<IParticipantTypeApplicationEntity | null>(null);

  const participant = useStoreSelector((state) => selectParticipantEntityById(state, participantId));
  const applicationId = participant?.applicationId || 0;

  const participantTypesOfCurrentUserOnApplicationBasedOnApplicationId = useStoreSelector((state) =>
    selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId(state, applicationId)
  );

  const canUserManageApplication = useStoreSelector((state) =>
    selectCanUserManageApplicationById(state, applicationId)
  );

  const unassignedUserManagedParticipantTypes = useStoreSelector((state) =>
    selectUnassignedParticipantTypesBeingManagedByCurrentUser(state, {
      applicationId,
      currentParticipantTypes: participant?.participantTypes || [],
    })
  );

  const actionsMenuEnabled = React.useMemo(() => {
    return !readOnly && (canUserManageApplication || !isEmpty(unassignedUserManagedParticipantTypes));
  }, [readOnly, canUserManageApplication, unassignedUserManagedParticipantTypes]);

  const handleClickEditParticipantInfo = React.useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);

  const handleCloseEditParticipantDialog = React.useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const displayInviteSuccessToast = React.useCallback(
    (email: string) => {
      toastSuccess(t(`Invitation has been resent to {{email}}`, { email }));
    },
    [toastSuccess, t]
  );

  const displayInviteErrorToast = React.useCallback(
    (email: string) => {
      toastError(t(`Failed to resend invitation to {{email}}`, { email }));
    },
    [toastError, t]
  );

  const handleClickReinviteParticipant = React.useCallback(async () => {
    if (!participant?.email) {
      return;
    }

    try {
      const savedParticipant = await dispatch(sendParticipantInvite({ applicationId, participantId })).then(
        unwrapResult
      );

      if (savedParticipant.invitation === InvitationStatus.Error) {
        displayInviteErrorToast(participant.email);
      } else {
        displayInviteSuccessToast(participant.email);

        posthog?.capture(`user resent-participant-invitation`, {
          participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
        });
      }
    } catch (e) {
      displayInviteErrorToast(participant.email);
    }
  }, [
    participant?.email,
    dispatch,
    applicationId,
    participantId,
    displayInviteErrorToast,
    displayInviteSuccessToast,
    posthog,
    participantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
  ]);

  const handleClickDeleteParticipant = React.useCallback(() => {
    setIsDeleteDialogOpen(true);
  }, []);

  const handleCloseDeleteParticipantDialog = React.useCallback(
    async (confirmed: boolean) => {
      if (confirmed) {
        try {
          await dispatch(removeParticipantFromApplication({ applicationId, participantId })).then(unwrapResult);

          posthog?.capture(`user deleted-participant`, {
            participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
          });
        } catch (e) {
          if (e && e.message) {
            toastError(e);
          } else {
            toastError(t(`Failed to remove participant from application`));
          }
        }
      }

      setIsDeleteDialogOpen(false);
    },
    [
      dispatch,
      applicationId,
      participantId,
      posthog,
      participantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
      toastError,
      t,
    ]
  );

  const handleSaveParticipant = React.useCallback(
    async (participantType?: IParticipantTypeApplicationEntity) => {
      const currentParticipantType = participantType || participantTypeToChange;

      if (!currentParticipantType) {
        return;
      }

      const updatedParticipantTypes = [
        ...(participant?.participantTypes.map((type) => type.name) || []),
        currentParticipantType.name,
      ];

      try {
        const payload = {
          firstName: participant?.firstName,
          lastName: participant?.lastName,
          organisation: participant?.organisation,
          phone: participant?.phone,
          email: participant?.email,
          address: participant?.address,
          participantTypes: updatedParticipantTypes,
        };

        await dispatch(updateParticipant({ applicationId, participantId, payload: payload })).then(unwrapResult);

        posthog?.capture(`user updated-participant`, {
          participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
        });

        // Load updated participants (BE handles migrating participant types between participants)
        await dispatch(fetchParticipants({ applicationId })).then(unwrapResult);
      } catch (e) {
        toastError(e);
      }
    },
    [
      participantTypeToChange,
      participant?.participantTypes,
      participant?.firstName,
      participant?.lastName,
      participant?.organisation,
      participant?.phone,
      participant?.email,
      participant?.address,
      dispatch,
      applicationId,
      participantId,
      posthog,
      participantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
      toastError,
    ]
  );

  const handleClickSetToParticipantType = React.useCallback(
    async (participantType: IParticipantTypeApplicationEntity) => {
      const participantTypeOccurrences = selectParticipantTypeOccurrences(store.getState(), {
        applicationId,
        participantTypeName: participantType.name,
      });

      setParticipantTypeToChange(participantType);

      if (
        (participantType.number === ParticipantTypeExtraNumber.ExactlyOne ||
          participantType.number === ParticipantTypeExtraNumber.MaximumOne) &&
        participantTypeOccurrences.participants.length === 1
      ) {
        setChangeParticipantTypeDialogOpen(true);
      } else {
        handleSaveParticipant(participantType);
      }
    },
    [store, applicationId, handleSaveParticipant]
  );

  const handleCloseChangeParticipantTypeDialog = React.useCallback(
    async (confirmed: boolean) => {
      if (confirmed && participantTypeToChange) {
        handleSaveParticipant();
        toastSuccess(t(`Participant has been set to ${participantTypeToChange.displayName}`));
      }

      setChangeParticipantTypeDialogOpen(false);
    },
    [participantTypeToChange, t, toastSuccess, handleSaveParticipant]
  );

  if (!participant) {
    return null;
  }

  return (
    <React.Fragment>
      <Card data-testid={`ParticipantCard-${participant.id}`} isCardClickable={false}>
        <ParticipantCardDetail participant={participant} readOnly={readOnly} />

        <StyledMenuContainer $menuDisabled={!actionsMenuEnabled}>
          <Guard condition={actionsMenuEnabled}>
            <ParticipantCardActions
              participant={participant}
              onClickEditParticipantInfo={handleClickEditParticipantInfo}
              onClickReinviteParticipant={handleClickReinviteParticipant}
              onClickDeleteParticipant={handleClickDeleteParticipant}
              onClickSetToParticipantType={handleClickSetToParticipantType}
            />
          </Guard>
        </StyledMenuContainer>
      </Card>

      <Guard condition={isEditDialogOpen}>
        <ParticipantEditDialog
          participantId={participantId}
          applicationId={applicationId}
          onClose={handleCloseEditParticipantDialog}
        />
      </Guard>

      <ConfirmationDialog
        title={t(`Remove participant from application`)}
        data-testid={"DeleteParticipantDialog"}
        content={
          <div>
            <Trans
              i18nKey="Removing <strong>{{name}}</strong> from application, are you sure?"
              defaults="Removing <strong>{{name}}</strong> from application, are you sure?"
              components={{
                strong: <strong />,
              }}
              tOptions={{
                name: getParticipantName(participant),
              }}
            />

            <Spacer y={6} />

            <FlexBox direction={"column"} spacing={2}>
              <div>
                <strong>{t(`Mobile:`)}</strong> {participant.phone || "--"}
              </div>
              <div>
                <strong>{t(`Email:`)}</strong> {participant.email || "--"}
              </div>
              <div>
                <strong>{t(`Address:`)}</strong> {participant.address.fullAddress || "--"}
              </div>
              <div>
                <strong>{t(`Participant types:`)}</strong>{" "}
                {participant.participantTypes.map((item) => item.displayName).join(", ") || "--"}
              </div>
            </FlexBox>
          </div>
        }
        isOpen={isDeleteDialogOpen}
        onClose={handleCloseDeleteParticipantDialog}
      />

      {participantTypeToChange && (
        <ParticipantChangeTypeConfirmationDialog
          applicationId={applicationId}
          participantFirstName={participant.firstName}
          participantLastName={participant.lastName}
          businessName={participant.organisation}
          participantTypeName={participantTypeToChange.name}
          participantTypeDisplayName={participantTypeToChange.displayName}
          isOpen={isChangeParticipantTypeDialogOpen}
          onClose={handleCloseChangeParticipantTypeDialog}
        />
      )}
    </React.Fragment>
  );
};

export default ParticipantCard;

const StyledMenuContainer = styled(Box)<{ $menuDisabled: boolean }>(
  ({ theme, $menuDisabled }) => css`
    align-self: flex-start;
    ${$menuDisabled &&
    css`
      margin-right: 40px;
    `}
  `
);
