import head from "lodash/head";

export const initialsFromNames = (firstName: string, lastName: string): string => {
  const initialPart1 = head(firstName) || "";
  const initialPart2 = head(lastName) || "";
  const initial = [initialPart1, initialPart2].join("");
  return initial.toUpperCase();
};

export const initialsFromName = (name: string): string => {
  const parts = name.split(" ");
  const len = parts.length;
  const firstPart = parts[0];
  if (len === 1) {
    return initialsFromNames(firstPart, "");
  }
  const lastPart = parts[len - 1];
  return initialsFromNames(firstPart, lastPart);
};
