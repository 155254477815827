import React from "react";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import FlexBox from "components/FlexBox/FlexBox";
import ApplicationInfoBasic from "components/ApplicationCard/ApplicationInfoBasic";
import ApplicationInfoDetails from "components/ApplicationCard/ApplicationInfoDetails";
import { useTranslation } from "react-i18next";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { fetchApplicationById, selectApplicationEntityById } from "store/domain-data/application/application";
import { ApplicationRecordType } from "models/Application.model";

type Props = {
  applicationId: number;
  onClose: (confirmed: boolean) => void;
};

const DeleteApplicationDialog: React.FC<Props> = ({ applicationId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  React.useEffect(() => {
    const initData = async () => {
      if (!applicationId) {
        return;
      }
      await dispatch(fetchApplicationById(applicationId));
    };
    initData();
  }, [dispatch, applicationId]);

  const title = React.useMemo(() => {
    if (application?.recordType === ApplicationRecordType.Template) {
      return t(`Delete Template`);
    }

    return t(`Delete Application`);
  }, [t, application]);

  const dataTestId = React.useMemo(() => {
    if (application?.recordType === ApplicationRecordType.Template) {
      return "DeleteApplicationTemplateDialog";
    }

    return "DeleteApplicationDialog";
  }, [application]);

  return (
    <ConfirmationDialog
      title={title}
      content={
        <FlexBox direction={"column"} spacing={2}>
          <ApplicationInfoBasic applicationId={applicationId} />
          <ApplicationInfoDetails applicationId={applicationId} />
        </FlexBox>
      }
      isOpen={true}
      onClose={onClose}
      confirmTitle={t(`Delete`)}
      data-testid={dataTestId}
    />
  );
};

export default DeleteApplicationDialog;
