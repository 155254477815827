import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { ApplicationStepDataUtil, IApplicationStepDataEntity } from "models/ApplicationStepData.model";

export type IApplicationStepDataBuffer = Pick<
  IApplicationStepDataEntity,
  "applicationId" | "breadcrumbStepId" | "data"
>;

// Store a key-value mapping, storing editing buffer of application step data
export type ApplicationStepDataBufferState = Record<string, IApplicationStepDataBuffer>;

// Actions & Thunks

export const updateApplicationStepDataBuffer = createAction<IApplicationStepDataBuffer>(
  "appState/applicationStepDataBuffer/updateApplicationStepDataBuffer"
);

// Reducer

export const applicationStepDataBufferState: ApplicationStepDataBufferState = {};

export const applicationStepDataBufferReducer = createReducer<ApplicationStepDataBufferState>(
  applicationStepDataBufferState,
  (builder) =>
    builder.addCase(updateApplicationStepDataBuffer, (draft, action) => {
      const entity = action.payload;
      const key = ApplicationStepDataUtil.generateEntityKey(entity);
      draft[key] = entity;
    })
);

// Selectors

export const selectApplicationStepDataBufferState = (state: RootState) => {
  return state.appState.applicationStepDataBuffer;
};

export const selectApplicationStepDataBuffer = createSelector(
  [
    selectApplicationStepDataBufferState,
    (_: RootState, props: Pick<IApplicationStepDataBuffer, "applicationId" | "breadcrumbStepId">) =>
      ApplicationStepDataUtil.generateEntityKey(props),
  ],
  (entities, key) => entities[key] || ""
);
