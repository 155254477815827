import HCaptchaRaw from "@hcaptcha/react-hcaptcha";
import React from "react";
import { H_CAPTCHA_SITE_KEY } from "constants/configs";

type Props = Omit<React.ComponentProps<typeof HCaptchaRaw>, "sitekey">;

/**
 * This is a wrapped version of "@hcaptcha/react-hcaptcha".
 * Reason to wrap:
 *   - provide default fallback for "onVerify"
 *   - bind "sitekey"
 *
 * @see https://github.com/hCaptcha/react-hcaptcha#references
 */
const HCaptcha = React.forwardRef<HCaptchaRaw, Props>((props, ref) => {
  return (
    <HCaptchaRaw {...props} sitekey={H_CAPTCHA_SITE_KEY} ref={ref} onVerify={props.onVerify || handleVerifyFallback} />
  );
});

const handleVerifyFallback = () => {
  /**
   *  HCaptcha runtime requires the definition of "onVerify" method when using a production sitekey.
   *  However, this is not typed or documented correctly, so we have to provide an empty function as the default fallback.
   *  When using "invisible checkbox" mode, this callback is intentionally left blank.
   */
};

export default HCaptcha;
export type HCaptchaClass = HCaptchaRaw;
