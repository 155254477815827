import { IOrganisationMembersService } from "services/organisation-members/OrganisationMembersService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { IOrganisationMemberEntity, OrganisationRole, OrganisationRoleUtil } from "models/OrganisationMember.model";
import { ServiceError } from "services/ServiceError";
import { UserStatus, UserStatusUtil } from "models/UserInfo.model";
import { IOrganisationEntity } from "models/Organisation.model";

export class OrganisationMembersService implements IOrganisationMembersService {
  private readonly apiClient: Cradle["apiClient"];

  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async fetchOrganisationMembersForCurrentUser(): Promise<IOrganisationMemberEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/members`);
    const json = response.data;

    const membersJSON = get(json, "users");
    const organisationId = toInteger(get(json, "organisationId"));

    if (!Array.isArray(membersJSON)) {
      throw new ServiceError();
    }
    const members = membersJSON.map((item) => {
      return this.parseMemberFromJSON(item, organisationId);
    });
    return members;
  }

  public async updateOrganisationMemberRole(args: {
    memberId: number;
    role: OrganisationRole;
  }): Promise<IOrganisationMemberEntity> {
    const { memberId, role } = args;
    const response = await this.apiClient.protectedApi.patch(`/user/organisation/members/${memberId}/roles`, {
      isAdministrator: role === OrganisationRole.Admin,
    });
    const json = response.data;
    const organisationId = toInteger(get(json, "organisationId"));
    const member = this.parseMemberFromJSON(json, organisationId);
    return member;
  }

  public async updateOrganisationMemberStatus(args: {
    memberId: number;
    status: UserStatus;
  }): Promise<IOrganisationMemberEntity> {
    try {
      const response = await this.apiClient.protectedApi.put(`user/organisation/members/${args.memberId}/status`, {
        status: args.status,
      });
      const json = response.data;
      const organisationId = toInteger(get(json, "organisationId"));
      const member = this.parseMemberFromJSON(json, organisationId);
      return member;
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public parseMemberFromJSON(json: any, organisationId: number): IOrganisationMemberEntity {
    // TODO: A member is a User, so no need for extra id
    const member: IOrganisationMemberEntity = {
      id: toInteger(get(json, "id")),
      userId: toInteger(get(json, "id")),
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      email: toString(get(json, "email")),
      organisationId,
      role: OrganisationRoleUtil.parse(toString(get(json, "organisationRole"))),
      status: UserStatusUtil.parse(toString(get(json, "status"))),
    };
    return member;
  }

  private parseOrganisationEntityJSON(json: string): IOrganisationEntity {
    return {
      id: toInteger(get(json, "id")),
      displayName: toString(get(json, "displayName")),
    };
  }

  public async releaseOrganisationMember(existingUserId: number): Promise<IOrganisationEntity> {
    const response = await this.apiClient.protectedApi.post(`/user/organisation`, { existingUserId });
    const json = response.data;
    const organisation = this.parseOrganisationEntityJSON(json);
    return organisation;
  }
}
