import { IAuthenticationService } from "services/authentication/AuthenticationService.types";
import { Cradle } from "services/serviceContainer.types";
import { ServiceError } from "services/ServiceError";
import { RegisterArgs } from "services/cognito/CognitoService.types";
import get from "lodash/get";
import toString from "lodash/toString";

export class AuthenticationService implements IAuthenticationService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];
  private readonly cognitoService: Cradle["cognitoService"];

  constructor(args: {
    apiClient: Cradle["apiClient"];
    i18n: Cradle["i18n"];
    cognitoService: Cradle["cognitoService"];
  }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
    this.cognitoService = args.cognitoService;
  }

  public async register(args: RegisterArgs): Promise<{ username: string }> {
    try {
      const response = await this.apiClient.publicApi.post("/public/user/registration", args);
      const json = response.data;
      const username = toString(get(json, "username"));
      return { username };
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async confirmRegistration(email: string, verificationCode: string): Promise<void> {
    try {
      await this.apiClient.publicApi.post(`/public/user/registration/confirm`, {
        email,
        code: verificationCode,
      });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async registerViaOrganisationInvitation(args: { invitationCode: string; password: string }): Promise<void> {
    try {
      await this.apiClient.publicApi.post(`/public/user/registration/invited`, {
        uuid: args.invitationCode,
        password: args.password,
      });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async submitChangePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      const accessToken = await this.cognitoService.getAccessToken();

      await this.apiClient.protectedApi.put(`/user/password`, {
        accessToken,
        previousPassword: oldPassword,
        proposedPassword: newPassword,
      });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async resendVerificationEmail(email: string): Promise<void> {
    try {
      await this.apiClient.publicApi.post("/public/user/registration/resend", { email });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }
}
