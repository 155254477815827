import React from "react";
import { useStoreSelector } from "store/hooks";
import { selectDocumentEntitiesByApplicationIdForContainer } from "store/domain-data/document/document";
import styled, { css } from "styled-components/macro";
import { Collapse } from "@material-ui/core";
import Guard from "components/Guard/Guard";
import DocumentContainerDocumentCard from "components/DocumentContainer/DocumentContainerDocumentCard";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { useRemoveApplicationDocument } from "components/DocumentContainer/functions/useRemoveApplicationDocument";
import { useCollapse } from "hooks/useCollapse";
import MergedDocumentCard from "components/DocumentContainer/MergedDocumentCard";
import FileUploadDropzone from "components/Dropzone/FileUploadDropzone";
import Spacer from "components/Spacer/Spacer";
import { usePreviewApplicationDocument } from "components/DocumentPreviewDialog/usePreviewApplicationDocument";
import { useDownloadApplicationDocument } from "hooks/useDownloadApplicationDocument";
import DocumentContainerHeader from "components/DocumentContainer/DocumentContainerHeader";
import { useDuplicatedDocumentsHandler } from "components/DocumentContainer/functions/useDuplicatedDocumentsHandler";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDragDropReorder } from "components/DocumentContainer/functions/useDragDropReorder";
import { DocumentCategoryIdentifier } from "models/DocumentCategory.model";
import { DOCUMENT_CONTAINER_UPLOAD_CONFIG } from "constants/configs";
import { selectDocumentCategoryForApplicationByIdentifier } from "store/domain-data/document-categories/documentCategories";
import Cards from "components/odl-v2/Card/Cards";
import { DocumentContainerUtil } from "models/DocumentContainer.model";
import { useHandleDropFiles } from "components/DocumentContainer/functions/useHandleDropFiles";
import SupportedFileInformation from "components/SupportedFileInformation/SupportedFileInformation";
import { useApplicationDocumentExpiredDialog } from "components/DocumentContainer/functions/useApplicationDocumentExpiredDialog";

type Props = {
  documentContainerId: number;
  applicationId: number;
  wrapperComponent?: React.ElementType;
  documentCategoryIdentifier?: DocumentCategoryIdentifier;
  hasNoHeading?: boolean;
};

const DocumentContainerEditMode: React.FC<Props> = ({
  documentContainerId,
  applicationId,
  wrapperComponent: WrapperComponent = React.Fragment,
  documentCategoryIdentifier = DocumentCategoryIdentifier.Lodgement,
  hasNoHeading = false,
}) => {
  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));
  const documentCategory = useStoreSelector((state) =>
    selectDocumentCategoryForApplicationByIdentifier(state, {
      applicationId,
      identifier: documentCategoryIdentifier,
    })
  );
  const documentCategoryId = documentCategory?.id || 0;
  const documents = useStoreSelector((state) =>
    selectDocumentEntitiesByApplicationIdForContainer(state, { applicationId, documentContainerId })
  );

  const { isCollapsed, toggleCollapse } = useCollapse(false);

  const {
    handleRemoveApplicationDocument,
    removeApplicationDocumentConfirmationDialog,
  } = useRemoveApplicationDocument({ applicationId, documentContainerId });

  const { downloadApplicationDocument } = useDownloadApplicationDocument(applicationId);

  const { previewApplicationDocument, applicationDocumentPreviewDialog } = usePreviewApplicationDocument(applicationId);

  const { duplicatedDocumentsDialog } = useDuplicatedDocumentsHandler(applicationId, documentContainerId);

  const { expiredDocumentsDialog } = useApplicationDocumentExpiredDialog(applicationId, documentContainerId);

  const { handleOnDragEnd, droppableId } = useDragDropReorder(applicationId, documentContainerId);

  const handleDropFiles = useHandleDropFiles({
    applicationId,
    documentContainerId,
    documentCategoryId,
  });

  if (!documentContainer) {
    return null;
  }

  return (
    <WrapperComponent>
      <StyledSection data-testid={`DocumentContainer-${documentContainer.id}`}>
        <Guard condition={!hasNoHeading}>
          <DocumentContainerHeader documentContainerId={documentContainerId} />
        </Guard>

        <StyledDocumentContainerContent
          $hasDocuments={documents.length > 0}
          data-testid={documentContainer.displayName}
        >
          <Guard condition={documents.length > 0 && DocumentContainerUtil.isMergeDocumentsSupported(documentContainer)}>
            <MergedDocumentCard
              applicationId={applicationId}
              documentContainerId={documentContainerId}
              isCollapsed={isCollapsed}
              onToggleCollapse={toggleCollapse}
            />
          </Guard>

          <Collapse in={!isCollapsed}>
            <FileUploadDropzone
              multiple={true}
              noKeyboard={true}
              noClick={true}
              onDrop={handleDropFiles}
              accept={DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MIME_TYPES}
              maxSize={DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MAX_FILE_SIZE}
              minSize={DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MIN_FILE_SIZE}
            >
              {() => (
                <Guard condition={documents.length > 0}>
                  <Spacer y={6} />
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId={droppableId}>
                      {(providedDroppable, snapshotDroppable) => (
                        <StyledDocumentCardList ref={providedDroppable.innerRef} {...providedDroppable.droppableProps}>
                          {/* uploaded Files */}
                          {documents.map((document, idx) => (
                            <Draggable draggableId={document.name} index={idx} key={document.name}>
                              {(providedDraggable, snapshotDraggable) => (
                                <div
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.draggableProps}
                                  {...providedDraggable.dragHandleProps}
                                >
                                  <DocumentContainerDocumentCard
                                    document={document}
                                    onPreviewApplicationDocument={previewApplicationDocument}
                                    onDownloadApplicationDocument={downloadApplicationDocument}
                                    onDeleteApplicationDocument={handleRemoveApplicationDocument}
                                    isDraggable={true}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {providedDroppable.placeholder}
                        </StyledDocumentCardList>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Guard>
              )}
            </FileUploadDropzone>
            <SupportedFileInformation />
          </Collapse>
        </StyledDocumentContainerContent>
      </StyledSection>

      {removeApplicationDocumentConfirmationDialog}
      {duplicatedDocumentsDialog}
      {expiredDocumentsDialog}
      {applicationDocumentPreviewDialog}
    </WrapperComponent>
  );
};

const StyledSection = styled.section(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("20px")}
  `
);

const StyledDocumentContainerContent = styled.div<{ $hasDocuments: boolean }>(
  ({ theme, $hasDocuments }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("12px")}

    ${$hasDocuments &&
    css`
      padding: 24px;
      border: 1px solid ${theme.palette.divider};
      border-radius: 8px;
    `};
  `
);

const StyledDocumentCardList = styled(Cards)(
  ({ theme }) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  `
);

export default DocumentContainerEditMode;
