import React from "react";
import { MenuItem as MuiMenuItem, MenuItemProps, Tooltip } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import FontIcon from "components/FontIcon/FontIcon";
import Guard from "components/Guard/Guard";
import Spacer from "components/Spacer/Spacer";
import omit from "lodash/omit";
import toString from "lodash/toString";

type Props = MenuItemProps & {
  /**
   * Name of icon:
   *   undefined - no icon
   *   empty string or invalid icon name - empty placeholder
   */
  icon?: string | JSX.Element;

  /**
   * Text of tooltip
   */
  tooltip?: string;
};

const MenuItem: React.FC<Props> = React.forwardRef(
  (
    {
      children,
      disabled = false,
      icon,
      tooltip = "",
      selected = false,
      button,
      onClick,
      onKeyPress,
      "aria-label": _ariaLabel,
      ...props
    },
    ref
  ) => {
    const handleClick = React.useCallback<React.MouseEventHandler<HTMLLIElement>>(
      (event) => {
        event.stopPropagation();

        if (disabled) {
          return;
        }

        if (onClick) {
          onClick(event);
        }
      },
      [disabled, onClick]
    );

    const handleKeyPress = React.useCallback<React.KeyboardEventHandler<HTMLLIElement>>(
      (event) => {
        event.stopPropagation();

        if (event.key === "Enter" || event.key === " ") {
          const el = event.currentTarget;
          if (el && typeof el.click === "function") {
            el.click();
          }
        }

        if (onKeyPress) {
          onKeyPress(event);
        }
      },
      [onKeyPress]
    );

    const ariaLabel = React.useMemo(() => {
      // If aria-label specified, use it
      if (_ariaLabel) {
        return _ariaLabel;
      }

      // If aria-label not specified, stringify children
      return toString(children);
    }, [_ariaLabel, children]);

    return (
      <StyledMuiMenuItem
        ref={ref}
        disableRipple={true}
        disableGutters={true}
        selected={selected}
        button={button ? true : undefined}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        {...omit(props, ["icon", "tooltip", "disabled", "onClick"])}
      >
        <Tooltip title={tooltip}>
          <StyledItemContent $disabled={disabled} $selected={selected} data-testid={"MenuItemContent"}>
            <Guard condition={typeof icon !== "undefined"}>
              <StyledIconContainer>
                {typeof icon === "string" && <FontIcon name={icon} fontSize={14} />}
                {typeof icon === "object" && icon}
              </StyledIconContainer>
              <Spacer x={2} />
            </Guard>

            <div>{children}</div>
          </StyledItemContent>
        </Tooltip>
      </StyledMuiMenuItem>
    );
  }
);

const StyledMuiMenuItem = styled(MuiMenuItem)(
  ({ theme, selected }) => css`
    min-width: 200px;
    min-height: 36px;
    padding: 0;
    display: flex;
    cursor: pointer;

    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }

    &:focus-visible {
      outline: none;

      ${StyledItemContent} {
        background-color: ${theme.palette.objective.light.day};
      }
    }

    ${selected &&
    css`
      background-color: transparent !important;
    `}
  `
);

const StyledItemContent = styled.div<{ $disabled?: boolean; $selected?: boolean }>(
  ({ theme, $disabled, $selected }) => css`
    // Normal
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.objective.dark.night};
    border-left: 4px solid transparent;
    &:hover {
      background-color: ${theme.palette.objective.light.day};
    }
    &:active {
      background-color: ${theme.palette.objective.blue.light};
    }

    // Disabled
    ${
      $disabled &&
      !$selected &&
      css`
        color: ${theme.palette.objective.dark.neutral};
        &:hover {
          background-color: transparent;
        }
        &:active {
          background-color: transparent;
        }
      `
    }

    // Selected
    ${
      $selected &&
      !$disabled &&
      css`
        color: ${theme.palette.objective.blue.main};
        border-left-color: ${theme.palette.objective.blue.main};
        background-color: ${theme.palette.objective.blue.light};
        &:hover {
          background-color: ${theme.palette.objective.blue.light};
        }
        &:active {
          background-color: ${theme.palette.objective.blue.light};
        }
      `
    }

      // Selected & disabled
    ${
      $selected &&
      $disabled &&
      css`
        color: ${theme.palette.objective.dark.neutral};
        border-left-color: ${theme.palette.objective.light.allspice};
        background-color: ${theme.palette.objective.light.day};
        &:hover {
          background-color: ${theme.palette.objective.light.day};
        }
        &:active {
          background-color: ${theme.palette.objective.light.day};
        }
      `
    }
  `
);

const StyledIconContainer = styled.div(
  ({ theme }) => css`
    width: 20px;
    height: 20px;
    text-align: center;
  `
);

export default MenuItem;
