import React from "react";
import ApplicationDocumentDuplicationDialog from "components/DocumentContainer/ApplicationDocumentDuplicationDialog";
import isEmpty from "lodash/isEmpty";
import { unwrapResult } from "@reduxjs/toolkit";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { useToast } from "hooks/useToast";
import {
  addDuplicatedItemsToQueueWithRename,
  removeDuplicatedItems,
  selectApplicationDocumentUploadDuplicatedItemsByApplicationAndContainer,
} from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { IDocumentEntity } from "models/Document.model";

export const useDuplicatedDocumentsHandler = (
  applicationId: number,
  documentContainerId: number,
  onDocumentsAdded?: (documents: IDocumentEntity[]) => void
) => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  const duplicatedItems = useStoreSelector((state) =>
    selectApplicationDocumentUploadDuplicatedItemsByApplicationAndContainer(state, {
      applicationId,
      documentContainerId,
    })
  );

  const duplicatedFileNames = React.useMemo(() => {
    const fileNames = duplicatedItems.map((item) => item.file.name);
    return fileNames;
  }, [duplicatedItems]);

  const handleClickRename = React.useCallback(async () => {
    try {
      const documents = await dispatch(
        addDuplicatedItemsToQueueWithRename({
          applicationId,
          documentContainerId,
        })
      ).then(unwrapResult);

      if (onDocumentsAdded) {
        onDocumentsAdded(documents);
      }
    } catch (e) {
      toastError(e);
    }
  }, [dispatch, toastError, applicationId, documentContainerId, onDocumentsAdded]);

  const handleClickCancel = React.useCallback(() => {
    dispatch(removeDuplicatedItems({ applicationId, documentContainerId }));
  }, [dispatch, applicationId, documentContainerId]);

  const duplicatedDocumentsDialog = React.useMemo(() => {
    if (isEmpty(duplicatedFileNames)) {
      return null;
    }

    return (
      <ApplicationDocumentDuplicationDialog
        onClickCancel={handleClickCancel}
        onClickRename={handleClickRename}
        duplicatedFileNames={duplicatedFileNames}
      />
    );
  }, [duplicatedFileNames, handleClickRename, handleClickCancel]);

  return {
    duplicatedDocumentsDialog,
  };
};
