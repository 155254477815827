import get from "lodash/get";
import toString from "lodash/toString";
import { serviceContainer } from "services/serviceContainer";
import has from "lodash/has";
import toInteger from "lodash/toInteger";

export const ServiceErrorCode = {
  ClientError: "ClientError",
  ServerError: "ServerError",
  CognitoUsernameExists: "CognitoUsernameExists",
  NetworkError: "NetworkError",
  ResourceNotFound: "ResourceNotFound",
  UserNotActive: "UserNotActive",
};

const SERVICE_ERROR_NAME = "ServiceError";

export class ServiceError extends Error {
  public code: string;

  constructor(code: string = ServiceErrorCode.ServerError, message?: string) {
    super(message ?? code);

    this.name = SERVICE_ERROR_NAME;
    this.code = code;
  }

  static createFromResponseError(e: Error): ServiceError {
    // The error is already a ServiceError
    if (ServiceError.isServiceError(e)) {
      return e;
    }
    const i18n = serviceContainer.cradle.i18n;

    // Check by status code
    const status = toInteger(get(e, "response.status"));
    if (status === 404) {
      return new ServiceError(ServiceErrorCode.ResourceNotFound, i18n.t(`Resource not found`));
    }

    // If error is not caused by API backend, throw general NetworkError
    const json = get(e, "response.data");
    if (!json) {
      return new ServiceError(ServiceErrorCode.NetworkError, e.message);
    }

    // If error comes from API backend and with special code we need to handle
    // TODO: Extract this part to adapt all special server error code cases
    //   We may or may not want replicate all backend error codes.
    //   Special cases only for now, and all others are normal ServerError.
    const serverErrorCode = toString(get(json, "code"));
    if (serverErrorCode === "USER.EXISTS.COGNITO.400") {
      return new ServiceError(
        ServiceErrorCode.CognitoUsernameExists,
        i18n.t(`An account already exists with this email`)
      );
    }
    if (serverErrorCode === "USER.INACTIVE.100") {
      return new ServiceError(ServiceErrorCode.UserNotActive, i18n.t(`User is not active`));
    }

    // Treat all other server errors as normal ServerError with custom error message
    const serverErrorMessage = toString(get(json, "description")) || "Error";
    return new ServiceError(ServiceErrorCode.ServerError, serverErrorMessage);
  }

  static isServiceError(error: any): error is ServiceError {
    if (typeof error !== "object") {
      return false;
    }

    if (!has(error, "name") || !has(error, "message") || !has(error, "code")) {
      return false;
    }

    return error.name === SERVICE_ERROR_NAME;
  }
}
