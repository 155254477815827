import { IApplicationValidationResultService } from "services/application-validation/ApplicationValidationResultService.types";
import { Cradle } from "services/serviceContainer.types";
import { IApplicationValidationResultEntity } from "models/ApplicationValidation.model";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { ApplicationValidationResultSeverityUtil } from "models/ApplicationValidationSeverity.model";
import { ApplicationValidationResultRuleTypeUtil } from "models/ApplicationValidationResultRuleType";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class ApplicationValidationResultService implements IApplicationValidationResultService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchApplicationValidationResults({
    applicationId,
    isReviewStep,
  }: {
    applicationId: number;
    isReviewStep?: boolean;
  }): Promise<IApplicationValidationResultEntity[]> {
    const options = isReviewStep ? { params: { type: "FULL" } } : undefined;
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/ruleresults`, options);
    const jsonArr = get(response.data, "ruleResultDTOs");

    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t("Failed to fetch application validation"));
    }

    const applicationValidationEntities = jsonArr.map((json) =>
      ApplicationValidationResultService.parseApplicationValidationResult(json, applicationId)
    );
    return applicationValidationEntities;
  }

  static parseApplicationValidationResult(json: any, applicationId: number) {
    const applicationValidation: IApplicationValidationResultEntity = {
      message: toString(get(json, "message")),
      messageParams: toString(get(json, "messageParams")),
      severity: ApplicationValidationResultSeverityUtil.parse(toString(get(json, "severity"))),
      ruleId: toInteger(get(json, "ruleId")),
      ruleType: ApplicationValidationResultRuleTypeUtil.parse(toString(get(json, "ruleType"))),
      applicationId: applicationId,
      stepId: toInteger(get(json, "stepId")),
      applicationFieldName: toString(get(json, "applicationFieldName")),
    };
    return applicationValidation;
  }
}
