import React from "react";
import { ActionItemVariant, IActionItem } from "components/ActionSection/ActionItem";
import { useHistory } from "react-router";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";

type Props = {
  items: IActionItem[];
};

const ActionSectionAlt: React.FC<Props> = ({ items }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const screenWidthMatch = useScreenWidthMatch();

  const { Menu, openMenu, closeMenu } = useMenu();

  const handleClickItem = React.useCallback(
    (item: IActionItem) => {
      if (item.variant === ActionItemVariant.Button && item.onClick) {
        closeMenu();
        item.onClick();
        return;
      }

      if (item.variant === ActionItemVariant.Link && item.to) {
        history.push(item.to);
        return;
      }
    },
    [history, closeMenu]
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledButton
        data-testid={"PopupMenuTriggerActions"}
        color="primary"
        onClick={openMenu}
        startIcon={<StyledStartIcon name="flash-auto" />}
        endIcon={screenWidthMatch.md ? <FontIcon name={"menu-down"} /> : null}
      >
        {screenWidthMatch.md ? t(`Actions`) : ""}
      </StyledButton>

      {items.map((item, idx) => item.dialog)}

      <Menu data-testid={"PopupMenu"}>
        {items.map((item) => (
          <StyledMenuItem
            icon={item.icon}
            onClick={() => handleClickItem(item)}
            key={item.title}
            data-testid={item.testId}
          >
            {item.title}
          </StyledMenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    text-transform: none;
    padding: 0;
  `
);

const StyledStartIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.neutral};
  `
);
const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    & .icon::before {
      color: ${theme.palette.objective.blue.main};
    }
  `
);

export default ActionSectionAlt;
