import { useHistory } from "react-router";
import React from "react";
import toString from "lodash/toString";
import get from "lodash/get";

/**
 * Hook to provide the `navigateBack` function
 *
 * The ideal logic to navigate back is:
 *   - if there is a specific backURL, back to there
 *   - if no backURL, back to previous page
 *   - if previous page is not reachable, back to home page ("/")
 *
 * @param args
 */
export const useNavigateBack = (args: { enabled?: boolean; backURL?: string } = {}) => {
  const history = useHistory();

  const { enabled = true, backURL } = args;

  const navigateBack = React.useCallback(() => {
    if (!enabled) {
      return;
    }

    // Explicit backURL from argument
    if (backURL) {
      history.replace(backURL);
      return;
    }

    // Explicit backURL from location state
    const backURLFromLocationState = toString(get(history.location.state, "backURL"));
    if (backURLFromLocationState) {
      history.replace(backURLFromLocationState);
      return;
    }

    // No explicit backURL, then back to previous page when possible
    const goBack = () => {
      if (typeof history.goBack === "function") {
        history.goBack();
        return;
      }

      // Note: this is a workaround when `createMemoryHistory` does not give us `goBack`
      //   it should be solved after force resolving `history` package to 4.10.1
      if (typeof history.go === "function") {
        history.go(-1);
        return;
      }
    };

    // No referrer, means the app is opened directly
    if (!document.referrer) {
      goBack();
      return;
    }

    // Has referrer, means it is redirected from a previous site
    if (document.referrer) {
      const referrerURL = new URL(document.referrer);
      const hostname = referrerURL.hostname;
      if (hostname === "localhost" || hostname.includes("objective")) {
        goBack();
        return;
      }
    }

    // Previous page not reachable, back to home page
    history.replace("/");
  }, [history, enabled, backURL]);

  return navigateBack;
};
