import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { IOrganisationMemberEntity, OrganisationRole } from "models/OrganisationMember.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import {
  fetchUserInfoForAuthenticatedUser,
  selectUserInfoEntityForAuthenticatedUser,
} from "store/domain-data/user-info/userInfo";
import { UserStatus } from "models/UserInfo.model";

//Entity Adapter

const organisationMemberAdapter = createEntityAdapter<IOrganisationMemberEntity>({
  selectId: (member) => member.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Action & thunks

export const loadOrganisationMembers = createAction<IOrganisationMemberEntity[]>(
  "domainData/organisationMember/loadMembers"
);

export const fetchOrganisationMembersForCurrentUser = createAsyncThunk(
  "domainData/organisationMember/fetchOrganisationMembersForCurrentUser",
  async (_, thunkAPI) => {
    const members = await serviceContainer.cradle.organisationMembersService.fetchOrganisationMembersForCurrentUser();
    thunkAPI.dispatch(loadOrganisationMembers(members));
    return members;
  }
);

export const updateOrganisationMemberRole = createAsyncThunk(
  "domainData/organisationMember/updateOrganisationMemberRole",
  async (args: { role: OrganisationRole; memberId: number }, thunkAPI) => {
    const member = await serviceContainer.cradle.organisationMembersService.updateOrganisationMemberRole(args);

    // After updating a member's role, re-fetch the userInfo of authenticated user
    const user = selectUserInfoEntityForAuthenticatedUser(thunkAPI.getState() as RootState);
    if (member.id === user?.id) {
      await thunkAPI.dispatch(fetchUserInfoForAuthenticatedUser());
    }

    return member;
  }
);

export const updateOrganisationMemberStatus = createAsyncThunk(
  "domainData/organisationMember/updateOrganisationMemberStatus",
  async (args: { memberId: number; status: UserStatus }) => {
    const member = await serviceContainer.cradle.organisationMembersService.updateOrganisationMemberStatus({
      memberId: args.memberId,
      status: args.status,
    });
    return member;
  }
);

export const releaseOrganisationMember = createAsyncThunk(
  "domainData/organisation/releaseMemberFromOrganisation",
  async ({ userId }: { userId: number }) => {
    await serviceContainer.cradle.organisationMembersService.releaseOrganisationMember(userId);
    return userId;
  }
);

// Reducer

export const defaultOrganisationMemberState = organisationMemberAdapter.getInitialState();

export const organisationMemberReducer = createReducer<typeof defaultOrganisationMemberState>(
  defaultOrganisationMemberState,
  (builder) =>
    builder
      .addCase(loadOrganisationMembers, organisationMemberAdapter.upsertMany)
      .addCase(updateOrganisationMemberRole.fulfilled, organisationMemberAdapter.upsertOne)
      .addCase(updateOrganisationMemberStatus.fulfilled, organisationMemberAdapter.upsertOne)
      .addCase(releaseOrganisationMember.fulfilled, organisationMemberAdapter.removeOne)
);

// Selectors

export const {
  selectById: selectOrganisationMemberEntityById,
  selectAll: selectAllOrganisationMemberEntities,
} = organisationMemberAdapter.getSelectors((state: RootState) => state.domainData.organisationMember);

export const selectOrganisationMemberByUserId = createDeepEqualSelector(
  [selectAllOrganisationMemberEntities, (state: RootState, userId: number) => userId],
  (entities, userId) => {
    return entities.find((entity) => entity.userId === userId);
  }
);

export const selectOrganisationMembersByOrganisationId = createDeepEqualSelector(
  [selectAllOrganisationMemberEntities, (state: RootState, organisationId: number) => organisationId],
  (entities, organisationId) => {
    return entities.filter(
      (entity) => entity.organisationId === organisationId && entity.status !== UserStatus.Disabled
    );
  }
);

export const selectOrganisationAdminCountByOrganisationId = createDeepEqualSelector(
  [selectOrganisationMembersByOrganisationId],
  (members) => {
    return members.filter((member) => member.role === OrganisationRole.Admin && member.status !== UserStatus.Disabled)
      .length;
  }
);

export const selectDisabledOrganisationMembersByOrganisationId = createDeepEqualSelector(
  [selectAllOrganisationMemberEntities, (state: RootState, organisationId: number) => organisationId],
  (members, organisationId) => {
    return members.filter(
      (member) => member.organisationId === organisationId && member.status === UserStatus.Disabled
    );
  }
);
