import React, { forwardRef, useCallback } from "react";
import { SnackbarContent, useSnackbar } from "notistack";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import styled, { css } from "styled-components/macro";
import FontIcon from "components/FontIcon/FontIcon";
import { ToastDataType, ToastVariant } from "components/ToastMessage/ToastMessage.types";

type ToastMessageProps = {
  id: string | number;
  data: ToastDataType;
};

// Note: only Success and Error are approved for production for now, so please use Info and Warning sparingly (if needs to go production, ask PO / Designer first)
const ToastMessage = forwardRef<HTMLDivElement, ToastMessageProps>(({ id, data }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <StyledSnackbarContent ref={ref} onClick={handleDismiss} data-testid="ToastMessage">
      <StyledCard $type={data.variant}>
        <StyledCardActions>
          <StyledMessage $variant={data.variant}>{data.message}</StyledMessage>
          <div className="icons">
            <IconButton>
              <StyledFontIcon name="close" $variant={data.variant} />
            </IconButton>
          </div>
        </StyledCardActions>
      </StyledCard>
    </StyledSnackbarContent>
  );
});

const StyledSnackbarContent = styled(SnackbarContent)(
  ({ theme }) => css`
    ${theme.breakpoints.up("sm")} {
      min-width: 344px !important;
    }
  `
);

const StyledMessage = styled.div<{ $variant: ToastVariant }>(
  ({ theme, $variant }) => css`
    font-size: 14px;
    font-weight: 500;

    ${($variant === ToastVariant.Error || $variant === ToastVariant.Success || $variant === ToastVariant.Info) &&
    css`
      color: ${theme.palette.objective.light.white};
    `}

    ${$variant === ToastVariant.Warning &&
    css`
      color: ${theme.palette.objective.dark.night};
    `}
  `
);

const StyledFontIcon = styled(FontIcon)<{ $variant: ToastVariant }>(
  ({ theme, $variant }) => css`
    font-size: 14px;
    font-weight: 500;

    ${($variant === ToastVariant.Error || $variant === ToastVariant.Success || $variant === ToastVariant.Info) &&
    css`
      color: ${theme.palette.objective.light.white};
    `}

    ${$variant === ToastVariant.Warning &&
    css`
      color: ${theme.palette.objective.dark.night};
    `}
  `
);

const StyledCard = styled(Card)<{ $type: string }>(
  ({ theme, $type }) => css`
    
    ${
      $type === ToastVariant.Error &&
      css`
        background-color: ${theme.palette.objective.orange.freshCarrot};
      `
    }

    ${
      $type === ToastVariant.Success &&
      css`
        background-color: ${theme.palette.objective.green.main};
      `
    }

    ${
      $type === ToastVariant.Warning &&
      css`
        background-color: ${theme.palette.objective.yellow.main};
      `
    }

    ${
      $type === ToastVariant.Info &&
      css`
        background-color: ${theme.palette.objective.blue.main};
      `
    }

    
    width: 100%;
    border-radius: 75px;
    padding: 11px 10px 10px 10px;
  `
);

const StyledCardActions = styled(CardActions)`
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
`;

export default ToastMessage;
