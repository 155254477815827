import { DocumentStatus } from "models/DocumentStatus.model";
import { DocumentErrorCode } from "models/DocumentErrorCode.model";

export type IDocumentEntity = {
  name: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileVersion: number;
  fileModifiedDate: string;
  referenceUrl: string;
  presignedUrl: string;
  uploadStatus: DocumentStatus;
  errorCode: DocumentErrorCode | null;
  createdDate: string;
  isMergedFile?: boolean;
};

export const DocumentUtil = {
  isSettled: (document: IDocumentEntity) => {
    return document.uploadStatus === DocumentStatus.Completed || document.uploadStatus === DocumentStatus.Error;
  },
  isUnsettled: (document: IDocumentEntity) => {
    return !DocumentUtil.isSettled(document);
  },
};
