// language=ejs
const address = `<!-- Address -->
{% if (ctx.mode.autocomplete) { %}
  <div class="address-autocomplete-container">
    <label class="form-check-label">Address:</label>
    <input
      ref="{{ ctx.ref.searchInput }}"
      {% for (var attr in ctx.inputAttributes) { %}
        {{attr}}="{{ctx.inputAttributes[attr]}}"
      {% } %}
      value="{{ ctx.displayValue }}"
      autocomplete="off"
    >
    <span></span>
    {% if (!ctx.component.disableClearIcon) { %}
      <i
        class="address-autocomplete-remove-value-icon fa fa-times"
        tabindex="{{ ctx.inputAttributes.tabindex }}"
        ref="{{ ctx.ref.removeValueIcon }}"
      ></i>
    {% } %}
  </div>
{% } %}
{% if (ctx.self.manualModeEnabled) { %}
  <div class="form-check checkbox">
    <label class="form-check-label">
      <input
        ref="{{ ctx.ref.modeSwitcher }}"
        type="checkbox"
        class="form-check-input"
        tabindex="{{ ctx.inputAttributes.tabindex }}"
        {% if (ctx.mode.manual) { %}checked=true{% } %}
        {% if (ctx.disabled) { %}disabled=true{% } %}
      >
      <span>{{ ctx.component.switchToManualModeLabel }}</span>
    </label>
  </div>
{% } %}
{% if (ctx.self.manualMode) { %}
  <div ref="{{ ctx.nestedKey }}">
    {{ ctx.children }}
  </div>
{% } %}
`;
export default address;
