import React from "react";
import { DropResult, ResponderProvided } from "react-beautiful-dnd";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import { selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments } from "store/domain-data/document/document";
import { updateApplicationDocumentOrderInContainer } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { useStoreDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { useTranslation } from "react-i18next";

const getDroppableId = (applicationId: number, documentContainerId: number) => {
  return `Application-${applicationId}|DocumentContainer-${documentContainerId}`;
};

// a little function to help us with reordering the result
const getReorderedDocumentNames = (documentNames: string[], srcIdx: number, dstIdx: number) => {
  const result = [...documentNames];
  const [removed] = result.splice(srcIdx, 1);
  result.splice(dstIdx, 0, removed);
  return result;
};

export const useDragDropReorder = (applicationId: number, documentContainerId: number) => {
  const store = useStore<RootState>();
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();
  const { t } = useTranslation();

  const handleOnDragEnd = React.useCallback(
    async (result: DropResult, provided: ResponderProvided) => {
      console.debug("handleOnDragEnd: ", result);

      // No-op if drop outside
      if (!result.destination) {
        return;
      }

      // No-op if drop in the same place
      if (
        result.source.droppableId === result.destination.droppableId &&
        result.source.index === result.destination.index
      ) {
        return;
      }

      const documents = selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments(store.getState(), {
        applicationId,
        documentContainerId,
      });
      const documentNames = documents.map((document) => document.name);
      const newDocumentNames = getReorderedDocumentNames(documentNames, result.source.index, result.destination.index);

      try {
        await dispatch(
          updateApplicationDocumentOrderInContainer({
            applicationId,
            documentContainerId,
            documentNames: newDocumentNames,
          })
        ).then(unwrapResult);
      } catch (e) {
        toastError(t(`Fail to reorder documents`));
      }
    },
    [store, applicationId, documentContainerId, dispatch, toastError, t]
  );

  const droppableId = React.useMemo(() => getDroppableId(applicationId, documentContainerId), [
    applicationId,
    documentContainerId,
  ]);

  return {
    handleOnDragEnd,
    droppableId,
  };
};
