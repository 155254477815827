export type IJurisdictionFormEntity = {
  id: number;
  name: string;
  description: string;
  displayName: string;
  displayOrder: number;
  imageId: number;
  jurisdictionId: number;
  breadcrumbIds: number[];
  category: JurisdictionFormCategory;
  isHidden: boolean;
  declarationText: string;
  series: string;
};

export enum JurisdictionFormCategory {
  Standalone = "Standalone",
  Amendment = "Amendment",
  Supporting = "Supporting",
}

export const JurisdictionFormCategoryUtil = {
  parse: (str: string): JurisdictionFormCategory => {
    for (const value of Object.values(JurisdictionFormCategory)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        return value;
      }
    }
    return JurisdictionFormCategory.Standalone;
  },
};
