import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { LoginArgs, RegisterArgs, ResetPasswordArgs } from "services/cognito/CognitoService.types";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";

// Type

export type AuthenticationState = {
  authenticating: boolean;
  authenticated: boolean;
  username: string;
  email: string;
  emailVerified: boolean;
};

// Actions & Thunks

export const loginWithCredentials = createAsyncThunk(
  "appState/authentication/loginWithCredentials",
  (loginArgs: LoginArgs) => {
    return serviceContainer.cradle.cognitoService.login(loginArgs);
  }
);

export const loginWithCachedState = createAsyncThunk("appState/authentication/loginWithCachedState", () => {
  return serviceContainer.cradle.cognitoService.loginWithCachedState();
});

export const logout = createAsyncThunk("appState/authentication/logout", async () => {
  await serviceContainer.cradle.cognitoService.logout();
  localStorage.clear();
  window.location.href = "/";
});

export const register = createAsyncThunk("appState/authentication/register", (args: RegisterArgs) => {
  return serviceContainer.cradle.authenticationService.register(args);
});

export const resetPassword = createAsyncThunk("appState/authentication/resetPassword", (args: ResetPasswordArgs) => {
  return serviceContainer.cradle.cognitoService.resetPassword(args);
});

export const resendVerificationEmail = createAsyncThunk(
  "appState/authentication/resendVerificationEmail",
  (email: string) => {
    return serviceContainer.cradle.authenticationService.resendVerificationEmail(email);
  }
);

// Reducer

export const defaultAuthenticationState: AuthenticationState = {
  authenticating: false,
  authenticated: false,
  username: "",
  email: "",
  emailVerified: false,
};

export const authenticationReducer = createReducer<AuthenticationState>(defaultAuthenticationState, (builder) =>
  builder
    .addCase(loginWithCredentials.pending, (draft, action) => {
      draft.authenticating = true;
    })
    .addCase(loginWithCredentials.fulfilled, (draft, action) => {
      draft.authenticated = true;
      draft.username = action.payload.username;
      draft.email = action.payload.email;
      draft.emailVerified = action.payload.emailVerified;
      draft.authenticating = false;
    })
    .addCase(loginWithCredentials.rejected, (draft, action) => {
      draft.authenticating = false;
    })
    .addCase(loginWithCachedState.pending, (draft, action) => {
      draft.authenticating = true;
    })
    .addCase(loginWithCachedState.fulfilled, (draft, action) => {
      draft.authenticated = true;
      draft.username = action.payload.username;
      draft.email = action.payload.email;
      draft.emailVerified = action.payload.emailVerified;
      draft.authenticating = false;
    })
    .addCase(loginWithCachedState.rejected, (draft, action) => {
      draft.authenticating = false;
    })
);

// Selectors

export const selectAuthenticationState = (state: RootState) => {
  return state.appState.authentication;
};

export const selectIsAuthenticating = createDeepEqualSelector(
  [selectAuthenticationState],
  (state) => state.authenticating
);
