import React from "react";
import MuiAccordionSummary, { AccordionSummaryProps } from "@material-ui/core/AccordionSummary";
import styled, { css } from "styled-components/macro";
import FontIcon from "components/FontIcon/FontIcon";
import Guard from "components/Guard/Guard";
import Spacer from "components/Spacer/Spacer";
import { IconButton } from "@material-ui/core";

type Props = AccordionSummaryProps & {
  icon?: React.ReactNode;
};

const AccordionSummary: React.FC<Props> = React.forwardRef(
  ({ children, icon, expandIcon: _expandIcon, ...props }, ref) => {
    const expandIcon = React.useMemo(() => {
      // Default expend icon
      if (typeof _expandIcon === "undefined") {
        return (
          <StyledIconButton>
            <StyledFontIcon name={"chevron-down"} />
          </StyledIconButton>
        );
      }

      return _expandIcon;
    }, [_expandIcon]);

    return (
      <StyledMuiAccordionSummary ref={ref} expandIcon={expandIcon} {...props}>
        <Guard condition={icon}>
          {icon}
          <Spacer x={2} />
        </Guard>
        {children}
      </StyledMuiAccordionSummary>
    );
  }
);

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)(
  ({ theme }) => css`
    min-height: 68px !important;
    padding: 0 24px;

    &.Mui-expanded {
      border-bottom: 1px solid ${theme.palette.objective.light.day};
    }

    & > .MuiAccordionSummary-content {
      min-height: 68px !important;
      margin: 0 !important;
      height: 100%;
      display: flex;
      align-items: center;
    }

    & > .MuiAccordionSummary-expandIcon {
      margin: 0;
    }
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    height: 44px;
    width: 44px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.text.primary};
  `
);

export default AccordionSummary;
