import { format } from "date-fns";

type FormatDateFunction = (
  dateStr: string,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
) => string;

// This is the replacement of `format` of `date-fns` to make it safer (not throw)
export const formatDateStr: FormatDateFunction = (dateStr, ...args) => {
  try {
    return format(Date.parse(dateStr), ...args);
  } catch (e) {
    return "Invalid date";
  }
};
