import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from "constants/configs";
import * as Sentry from "@sentry/react";
import omit from "lodash/omit";
import * as crypto from "crypto";

/*
 * @see https://wiki.objective.com/pages/viewpage.action?pageId=652448896 on how to implement and test scrubbing
 */
export const initializeSentry = () => {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay(), new Sentry.Integrations.Breadcrumbs()],
    // Performance Monitoring
    tracesSampleRate: 0,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.3,
    release: SENTRY_RELEASE,
    beforeSend(event, hint) {
      if (event.user?.email) {
        event.user = omit(event.user, "email");
      }

      if (event.request?.url) {
        const emails = event.request?.url?.match(/([+'a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
        if (emails) {
          for (const email of emails) {
            event.request.url = event.request.url.replace(email, hashString(email));
          }
        }

        // We hash the verification code in side the URL
        const value = new URLSearchParams(event.request.url).get("verification_code");
        if (value) {
          event.request.url = event.request.url?.replace(value, hashString(value));
        }
      }
      return event;
    },
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.message?.startsWith("[BUILD LOG]")) {
        return breadcrumb;
      }
      return null;
    },
  });

  const hashString = (str: string): string => {
    return crypto.createHash("md5").update(str).digest("hex");
  };
};
