import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { IBreadcrumbStepEntity } from "models/BreadcrumbStep.model";
import { selectAllBreadcrumbEntities } from "store/domain-data/breadcrumb/breadcrumb";
import { rankAccessLevel } from "models/Participant.model";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { BreadcrumbStepType } from "models/BreadcrumbStepType";
import { selectCurrentUserManagesOtherParticipants } from "../participant/participant";

// Entity Adapter

const breadcrumbStepAdapter = createEntityAdapter<IBreadcrumbStepEntity>({
  selectId: (breadcrumbStep) => breadcrumbStep.id,
  sortComparer: (a, b) => {
    if (a.breadcrumbId !== b.breadcrumbId) {
      return a.breadcrumbId - b.breadcrumbId;
    }

    return a.displayOrder - b.displayOrder;
  },
});

// Action & Thunks

export const loadBreadcrumbSteps = createAction<IBreadcrumbStepEntity[]>(
  "domainData/breadcrumbStep/loadBreadcrumbSteps"
);

// Reducer

export const defaultBreadcrumbStepState = breadcrumbStepAdapter.getInitialState();

export const breadcrumbStepReducer = createReducer<typeof defaultBreadcrumbStepState>(
  defaultBreadcrumbStepState,
  (builder) =>
    builder.addCase(loadBreadcrumbSteps, (draft, action) => {
      breadcrumbStepAdapter.upsertMany(draft, action.payload);
    })
);

// Selectors

export const {
  selectById: selectBreadcrumbStepEntityById,
  selectIds: selectBreadcrumbStepEntityIds,
  selectEntities: selectBreadcrumbStepEntities,
  selectAll: selectAllBreadcrumbStepEntities,
  selectTotal: selectTotalBreadcrumbStepEntities,
} = breadcrumbStepAdapter.getSelectors((state: RootState) => state.domainData.breadcrumbStep);

export const selectBreadcrumbStepEntitiesByBreadcrumbId = createDeepEqualSelector(
  [selectAllBreadcrumbStepEntities, (state: RootState, breadcrumbId: number) => breadcrumbId],
  (entities, breadcrumbId) => {
    return entities.filter((entity) => entity.breadcrumbId === breadcrumbId);
  }
);

export const selectBreadcrumbStepEntitiesByJurisdictionFormId = createDeepEqualSelector(
  [
    selectAllBreadcrumbStepEntities,
    selectAllBreadcrumbEntities,
    (state: RootState, jurisdictionFormId: number) => jurisdictionFormId,
  ],
  (allSteps, allBreadcrumbs, jurisdictionFormId) => {
    const breadcrumbs = allBreadcrumbs.filter((breadcrumb) => breadcrumb.jurisdictionFormId === jurisdictionFormId);
    const breadcrumbIds = breadcrumbs.map((breadcrumb) => breadcrumb.id);
    const steps = allSteps.filter((step) => breadcrumbIds.includes(step.breadcrumbId));
    return steps;
  }
);

export const selectAccessibleBreadcrumbStepIds = createDeepEqualSelector(
  [
    selectAllBreadcrumbStepEntities,
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
    (state: RootState, applicationId: number) => selectCurrentUserManagesOtherParticipants(state, applicationId),
  ],
  (allSteps, application, userManagesOtherParticipants) => {
    if (!application) {
      return [];
    }

    return allSteps
      .filter((step) => {
        return (
          rankAccessLevel(application.accessLevel) >= rankAccessLevel(step.minimumAccessLevel) ||
          (step.type === BreadcrumbStepType.Contact && userManagesOtherParticipants)
        );
      })
      .map((step) => step.id);
  }
);

type SelectCanEditBreadcrumbStepProps = {
  applicationId: number;
  breadcrumbStepId: number;
};

export const selectCanEditBreadcrumbStep = createDeepEqualSelector(
  [
    (state: RootState, props: SelectCanEditBreadcrumbStepProps) =>
      selectAccessibleBreadcrumbStepIds(state, props.applicationId),
    (state: RootState, props: SelectCanEditBreadcrumbStepProps) =>
      selectBreadcrumbStepEntityById(state, props.breadcrumbStepId),
  ],
  (allAccessibleStepIds, currentStep) => {
    return currentStep?.id && allAccessibleStepIds.includes(currentStep?.id);
  }
);

export const selectCurrentStepByBreadcrumbStepId = createDeepEqualSelector(
  [selectAllBreadcrumbStepEntities, (state: RootState, breadcrumbStepId: number) => breadcrumbStepId],
  (entities, breadcrumbStepId) => {
    return entities.find((entity) => entity.id === breadcrumbStepId);
  }
);
