export type IApplicationDocumentRelation = {
  applicationId: number;
  documentName: string;
  documentContainerId: number;
  order: number;
  documentCategoryId: number;
};

export const ApplicationDocumentRelationUtil = {
  generateEntityKey: (relation: Pick<IApplicationDocumentRelation, "applicationId" | "documentName">) => {
    return `applicationId:${relation.applicationId}|documentName:${relation.documentName}}`;
  },
};
