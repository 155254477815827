export const formatCommaSeparatedString = (csv: string): string => {
  if (!csv) {
    return "";
  }
  const cleanSpacesCsv = csv
    .trim()
    .replace(/\./g, " ") // "John Fuller." => "John Fuller"
    .replace(/ +(?= )/g, ""); // " a,    b,c " => "a, b,c"
  const csvList = cleanSpacesCsv.split(",").map((item) => item.trim()); // a, b,c => [a,b,c]
  return csvList.filter((item) => !!item).join(", "); //[a,b,,,c] => a, b, c
};
