import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { IJurisdictionFormEntity, JurisdictionFormCategory } from "models/JurisdictionForm.model";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { selectApplicationStepDataEntitiesForApplicationId } from "store/domain-data/application-step-data/applicationStepData";
import get from "lodash/get";
import toString from "lodash/toString";
import uniq from "lodash/uniq";

// Entity Adapter

const jurisdictionFormAdapter = createEntityAdapter<IJurisdictionFormEntity>({
  selectId: (jurisdictionForm) => jurisdictionForm.id,
  sortComparer: (a, b) => {
    if (a.jurisdictionId !== b.jurisdictionId) {
      return a.jurisdictionId - b.jurisdictionId;
    }

    return a.displayOrder - b.displayOrder;
  },
});

// Action & Thunks

export const loadJurisdictionForms = createAction<IJurisdictionFormEntity[]>(
  "domainData/jurisdictionForm/loadJurisdictionForms"
);

// Reducer

export const defaultJurisdictionFormState = jurisdictionFormAdapter.getInitialState();

export const jurisdictionFormReducer = createReducer<typeof defaultJurisdictionFormState>(
  defaultJurisdictionFormState,
  (builder) =>
    builder.addCase(loadJurisdictionForms, (draft, action) => {
      jurisdictionFormAdapter.upsertMany(draft, action.payload);
    })
);

// Selectors

export const {
  selectById: selectJurisdictionFormEntityById,
  selectIds: selectJurisdictionFormEntityIds,
  selectEntities: selectJurisdictionFormEntities,
  selectAll: selectAllJurisdictionFormEntities,
  selectTotal: selectTotalJurisdictionFormEntities,
} = jurisdictionFormAdapter.getSelectors((state: RootState) => state.domainData.jurisdictionForm);

export const selectJurisdictionFormEntitiesByJurisdictionId = createDeepEqualSelector(
  [selectAllJurisdictionFormEntities, (_: RootState, jurisdictionId: number) => jurisdictionId],
  (entities, jurisdictionId) => {
    return entities.filter((entity) => entity.jurisdictionId === jurisdictionId);
  }
);

export const selectJurisdictionFormNameByFormId = createDeepEqualSelector(
  [selectAllJurisdictionFormEntities, (_: RootState, formId: number) => formId],
  (entities, formId) => {
    const form = entities.find((entity) => entity.id === formId);
    if (!form) {
      return "";
    }
    return form.displayName;
  }
);

export const selectJurisdictionFormByApplicationId = createDeepEqualSelector(
  [
    (state: RootState) => selectAllJurisdictionFormEntities(state),
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
  ],
  (entities, application) => {
    return entities.find((entity) => entity.id === application?.formId);
  }
);

export const selectDeclarationTextByApplicationId = createDeepEqualSelector(
  [
    (state: RootState, applicationId: number) => selectJurisdictionFormByApplicationId(state, applicationId),
    (state: RootState, applicationId: number) =>
      selectApplicationStepDataEntitiesForApplicationId(state, applicationId),
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
  ],
  (jurisdictionForm, applicationStepData, application) => {
    if (!applicationStepData || !application || !jurisdictionForm) {
      return "";
    }
    if (!jurisdictionForm.name.startsWith("bc-v")) {
      return jurisdictionForm.declarationText;
    }
    for (const stepDataEntity of applicationStepData) {
      const pimData = toString(get(stepDataEntity.data, "requestPIM"));
      const positiveValues = ["yes", "true"];
      if (pimData && positiveValues.includes(pimData.toLowerCase())) {
        return "I request that you issue a project information memorandum and building consent for the building work described in this application.";
      }
    }
    return jurisdictionForm.declarationText;
  }
);

export const selectStandaloneFormSeries = createDeepEqualSelector([selectAllJurisdictionFormEntities], (entities) => {
  const formSeries = uniq(
    entities
      .filter((entity) => entity.category === JurisdictionFormCategory.Standalone)
      .filter((entity) => !entity.isHidden) // TODO: BLD-4663
      .map((entity) => entity.series)
      .filter((series) => Boolean(series))
  );
  return formSeries;
});

export const selectAmendmentJurisdictionFormByParentFormId = createDeepEqualSelector(
  [
    selectAllJurisdictionFormEntities,
    (state: RootState, formId: number) => selectJurisdictionFormEntityById(state, formId),
  ],
  (entities, jurisdictionForm) => {
    let amendmentFormSeries = "";
    if (jurisdictionForm?.series === "bc-sfh") {
      amendmentFormSeries = "bca-sfh";
    } else if (jurisdictionForm?.series === "bc") {
      amendmentFormSeries = "bca";
    } else {
      return null;
    }
    return entities.filter((entity) => !entity.isHidden).find((entity) => entity.series === amendmentFormSeries);
  }
);

export const selectActiveCCCForm = createDeepEqualSelector([selectAllJurisdictionFormEntities], (entities) => {
  return entities.filter((entity) => !entity.isHidden).find((entity) => entity.series === "ccc");
});

export const selectActiveEOTForm = createDeepEqualSelector([selectAllJurisdictionFormEntities], (entities) => {
  return entities.filter((entity) => !entity.isHidden).find((entity) => entity.series === "eot");
});

export const selectJurisdictionFormsByFormIds = createDeepEqualSelector(
  [selectAllJurisdictionFormEntities, (state: RootState, formIds: string[]) => formIds],
  (entities, jurisdictionForms) => {
    const forms = entities.filter((form) => jurisdictionForms.includes(form.series) && !form.isHidden);
    return forms;
  }
);
