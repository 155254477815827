import React from "react";
import { Theme } from "@material-ui/core";
import { objectivePalette } from "styles/objectivePalette";
import styled, { css } from "styled-components/macro";
import { UserAvatarType } from "components/UserAvatar/UserAvatar.types";
import FontIcon from "components/FontIcon/FontIcon";

type Props = {
  rectSize: number;
  fontSize: number;
  type?: UserAvatarType;
};

const UserGroupAvatar: React.FC<Props> = ({ rectSize, fontSize, type = UserAvatarType.Solid }) => {
  return (
    <StyledUserGroupAvatar $rectSize={rectSize} $fontSize={fontSize} $type={type}>
      <FontIcon name={"account-group"} fontSize={fontSize} paddingLeft={"6px"} paddingTop={"9px"} />
    </StyledUserGroupAvatar>
  );
};

const StyledUserGroupAvatar = styled.div(
  ({
    theme,
    $rectSize,
    $fontSize,
    $type,
  }: {
    theme: Theme;
    $rectSize: number;
    $fontSize: number;
    $type?: UserAvatarType;
  }) => css`
    width: ${$rectSize}px;
    height: ${$rectSize}px;
    border-radius: ${$rectSize}px;
    font-size: ${$fontSize}px;
    display: flex;
    background-color: ${objectivePalette.background.freshMango};
    ${$type === UserAvatarType.Solid &&
    css`
      border: none;
      background-color: ${objectivePalette.background.freshMango};
    `}
    ${$type === UserAvatarType.Outlined &&
    css`
      border: 2px solid ${objectivePalette.background.freshMango};
      background-color: transparent;
    `}
  `
);
export default UserGroupAvatar;
