import React from "react";
import DocumentContainerReadonlyMode from "components/DocumentContainer/DocumentContainerReadonlyMode";
import DocumentContainerEditMode from "components/DocumentContainer/DocumentContainerEditMode";

type Props = {
  documentContainerId: number;
  applicationId: number;
  isReadonly?: boolean;
  hasNoHeading?: boolean;
  wrapperComponent?: React.ElementType;
};

const DocumentContainer: React.FC<Props> = ({ isReadonly = false, hasNoHeading = false, ...otherProps }) => {
  if (isReadonly) {
    return <DocumentContainerReadonlyMode {...otherProps} />;
  }

  return <DocumentContainerEditMode hasNoHeading={hasNoHeading} {...otherProps} />;
};

export default DocumentContainer;
