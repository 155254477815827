import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appStateReducer } from "store/app-state/appStateReducer";
import { domainDataReducer } from "store/domain-data/domainDataReducer";

const combinedReducer = combineReducers({
  appState: appStateReducer,
  domainData: domainDataReducer,
});
type RootReducer = typeof combinedReducer;

export const rootReducer: RootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
    return middlewares;
  },
});
