import React from "react";
import { useStoreSelector } from "store/hooks";
import { selectDocumentEntityByName } from "store/domain-data/document/document";
import { DocumentPreviewDialogProps } from "components/DocumentPreviewDialog/DocumentPreviewDialog";
import RawDocumentPreviewDialog from "components/DocumentPreviewDialog/DocumentPreviewDialog";

export const useDocumentPreviewDialog = () => {
  const [documentName, setDocumentName] = React.useState("");
  const document = useStoreSelector((state) => selectDocumentEntityByName(state, documentName));

  const openDocumentPreviewDialog = React.useCallback((documentName: string) => {
    setDocumentName(documentName);
  }, []);

  const closeDocumentPreviewDialog = React.useCallback(() => {
    setDocumentName("");
  }, []);

  // Component with props partially bound
  //    TODO: This is an experimental pattern
  const DocumentPreviewDialog = React.useCallback(
    (props: Partial<DocumentPreviewDialogProps>) => {
      if (!document) {
        return null;
      }

      return (
        <RawDocumentPreviewDialog
          data-testid={"DocumentPreviewDialog"}
          title={document.fileName}
          getDownloadLink={() => Promise.resolve("")}
          onClose={closeDocumentPreviewDialog}
          {...props}
        />
      );
    },
    [document, closeDocumentPreviewDialog]
  );

  return {
    document,
    openDocumentPreviewDialog,
    closeDocumentPreviewDialog,
    DocumentPreviewDialog,
  };
};
