import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { serviceContainer } from "services/serviceContainer";
import Box from "components/Box/Box";
import Guard from "components/Guard/Guard";
import MessageSection from "pages/authentication/MessageSection";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import Spacer from "components/Spacer/Spacer";
import FlexBox from "components/FlexBox/FlexBox";
import { Form, Formik } from "formik";
import FormikPasswordInput from "components/FormikPasswordInput/FormikPasswordInput";
import { InvitationRegistrationFormValues } from "pages/authentication/AuthenticationPage.types";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import Alert from "components/odl-v2/Alert/Alert";
import { unwrapResult } from "@reduxjs/toolkit";
import { loginWithCredentials, selectAuthenticationState } from "store/app-state/authentication/authentication";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import LinkAction from "pages/authentication/LinkAction";
import AcknowledgementCheckbox from "pages/authentication/AcknowledgementCheckbox";
import FormikSubmitButton from "components/FormikSubmitButton/FormikSubmitButton";

type Props = {
  invitationCode: string;
};

const invitationRegistrationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(formValidationUtils.getRegexForPassword(), formValidationUtils.getErrorMessageForPassword())
    .required(formValidationUtils.getErrorMessageForRequiredField()),
});

const InvitationRegistrationSection: React.FC<Props> = ({ invitationCode }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useStoreDispatch();

  const [isCheckingCode, setIsCheckingCode] = React.useState(true);
  const [isRegistering, setIsRegistering] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false);
  const [isAcknowledged, setIsAcknowledged] = React.useState(false);

  const { authenticating } = useStoreSelector(selectAuthenticationState);

  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");

  const initialValues: InvitationRegistrationFormValues = React.useMemo(() => {
    return { email: email, password: "" };
  }, [email]);

  const checkInvitationCode = React.useCallback(async () => {
    setIsCheckingCode(true);
    try {
      const service = serviceContainer.cradle.organisationMemberInvitationService;
      const { email } = await service.validateOrganisationInvitation(invitationCode);
      setEmail(email);
    } catch (e) {
      setErrorMessage(t(`Invitation code is not valid`));
    } finally {
      setIsCheckingCode(false);
    }
  }, [t, invitationCode]);

  const register = React.useCallback(
    async (invitationCode: string, password: string) => {
      try {
        setIsRegistering(true);
        await serviceContainer.cradle.authenticationService.registerViaOrganisationInvitation({
          invitationCode: invitationCode,
          password: password,
        });
        // Used to clean up guard conditions once registration part is completed
        setIsRegistered(true);
      } catch (e) {
        setErrorMessage(t(`Unable to register with invitation code`));
        throw e;
      } finally {
        setIsRegistering(false);
      }
    },
    [t]
  );

  const login = React.useCallback(
    async (email: string, password: string) => {
      try {
        await dispatch(
          loginWithCredentials({
            username: email,
            password: password,
          })
        ).then(unwrapResult);
      } catch (e) {
        setErrorMessage(e.message);
        throw e;
      }
    },
    [dispatch]
  );

  const handleToggleAcknowledged = React.useCallback(() => {
    setIsAcknowledged((prev) => !prev);
  }, []);

  const handleSubmit = React.useCallback(
    async ({ email, password }: InvitationRegistrationFormValues) => {
      try {
        await register(invitationCode, password);
        await login(email, password);
        history.replace("/");
      } catch {
        // Fail silently, the error has been displayed
      }
    },
    [login, register, invitationCode, history]
  );

  React.useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    checkInvitationCode();
  }, [checkInvitationCode]);

  const isSubmitDisabled = isRegistering || !isAcknowledged;

  return (
    <Box data-testid="InvitationRegistrationSection" paddingTop={4}>
      {/* Pre registration - checking code and get email */}
      <Guard condition={!email}>
        <Guard condition={isCheckingCode}>
          <MessageSection title={t(`Validating Invitation Code`)}>
            {t(`Your invitation code is currently being validated.`)}
          </MessageSection>
        </Guard>

        <Guard condition={!isCheckingCode}>
          <MessageSection title={t(`Invalid code`)}>{t(`Your invitation code is invalid.`)}</MessageSection>
        </Guard>
      </Guard>

      {/* Code is valid */}
      <Guard condition={email}>
        {/* Register */}
        <Guard condition={!isRegistered}>
          <Guard condition={isRegistering}>
            <MessageSection title={t(`Registering your account`)}>
              {t(`Your registration is currently being processed.`)}
            </MessageSection>
          </Guard>

          <Guard condition={!isRegistering}>
            <MessageSection title={t(`Password required`)}>
              {t(`Please enter the password you want to use for your Build account`)}
            </MessageSection>
            <Spacer y={6} />
            <Formik<InvitationRegistrationFormValues>
              initialValues={initialValues}
              validationSchema={invitationRegistrationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {() => (
                <Form>
                  <FlexBox spacing={6} direction={"column"}>
                    <FormikTextInput label={t(`Email`)} fieldName={"email"} disabled={true} />
                    <FormikPasswordInput label={t(`Password`)} fieldName={"password"} />
                    {/* Error message */}
                    <Guard condition={errorMessage}>
                      <Alert severity={"error"}>{errorMessage}</Alert>
                    </Guard>

                    <AcknowledgementCheckbox isAcknowledged={isAcknowledged} onToggle={handleToggleAcknowledged} />

                    <FlexBox justifyContent={"flex-end"}>
                      <FormikSubmitButton title={t("Submit")} disabled={isSubmitDisabled} />
                    </FlexBox>
                  </FlexBox>
                </Form>
              )}
            </Formik>
          </Guard>
        </Guard>

        {/* Post registration */}
        <Guard condition={isRegistered}>
          <Guard condition={authenticating}>
            <MessageSection title={t(`Signing In`)}>
              {t(`Signing you into Build with your new account.`)}
            </MessageSection>
          </Guard>

          <Guard condition={!authenticating && errorMessage}>
            <MessageSection title={t(`Signing In failed`)}>
              <div>{t(`We were unable to automatically log you in.`)}</div>
              <div>{t(`Please try to log in in the login page.`)}</div>
              <Spacer y={4} />
              <LinkAction action={t(`Sign In here`)} to="/" />
            </MessageSection>
          </Guard>
        </Guard>
      </Guard>
    </Box>
  );
};

export default InvitationRegistrationSection;
