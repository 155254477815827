import { ApplicationStatus } from "models/ApplicationStatus.model";
import { ApplicationRecordType } from "models/Application.model";
import { IWorkflowStage } from "models/ApplicationWorkflow.model";
import { ParticipantAccessLevel } from "models/Participant.model";
import isEmpty from "lodash/isEmpty";

export type IApplicationV3Entity = {
  id: number;
  formId: number;
  formDisplayName: string;
  authorityId: number;
  consentNumber: string;
  complexity: string;
  currentStage: string;
  currentStatusLabel: string;
  applicationNumber: string;
  modifiedDate: string;
  modifiedBy: string;
  hasChildApplications: boolean;
  hasOutstandingRFIs: boolean;
  hasOutstandingPayments: boolean;
  address: string;
  recordType: ApplicationRecordType;
  status: ApplicationStatus;
  isArchived: boolean;
  isEditable: boolean;
  isMigrated: boolean;
  isDeletable: boolean;
  displayStages: IWorkflowStage[];
  accessLevel: ParticipantAccessLevel;
  applicationFormPdf: string;
  parentApplicationId: number;
};

export const ApplicationV3Util = {
  getRecordType: (consentNumber: string) => {
    if (!isEmpty(consentNumber)) {
      return ApplicationRecordType.Consent;
    }
    return ApplicationRecordType.Draft;
  },
  getDisplayId: (application?: IApplicationV3Entity): string => {
    if (!application) {
      return "";
    }
    return application.recordType === ApplicationRecordType.Consent
      ? application.consentNumber
      : application.applicationNumber;
  },
};
