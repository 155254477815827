import React from "react";
import { useStoreSelector } from "store/hooks";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { UserInfoUtil } from "models/UserInfo.model";
import ProfileIncompleteAlert from "pages/dashboard/ProfileIncompleteAlert";
import { useNavigate } from "components/RouteController/functions/useNavigate";
import { urlHelper } from "components/RouteController/functions/urlHelper";

const STORAGE_KEY_PREFIX_ALERT_MUTED = "build.dashboard.alerts.profileIncompleteAlert.isMuted";

const generateStorageKey = (username: string) => {
  return [STORAGE_KEY_PREFIX_ALERT_MUTED, username].join(".");
};

export const useProfileIncompleteAlert = () => {
  const [isProfileIncompleteAlertVisible, setIsProfileIncompleteAlertVisible] = React.useState(false);

  const userInfo = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);
  const { navigateTo } = useNavigate();

  React.useEffect(() => {
    if (!userInfo) {
      return;
    }

    const isProfileComplete = UserInfoUtil.isProfileComplete(userInfo);

    let isMuted = false;
    const storageKey = generateStorageKey(userInfo.username);
    if (localStorage.getItem(storageKey) === "true") {
      isMuted = true;
    }

    if (isProfileComplete || isMuted) {
      setIsProfileIncompleteAlertVisible(false);
    } else {
      setIsProfileIncompleteAlertVisible(true);
    }
  }, [userInfo]);

  const handleClickMute = React.useCallback(() => {
    if (!userInfo) {
      return;
    }

    setIsProfileIncompleteAlertVisible(false);
    const storageKey = generateStorageKey(userInfo.username);
    localStorage.setItem(storageKey, "true");
  }, [userInfo]);

  const handleClickGotoProfile = React.useCallback(() => {
    navigateTo(urlHelper.getUrlForUserProfilePage());
  }, [navigateTo]);

  const profileIncompleteAlert = React.useMemo(() => {
    if (!isProfileIncompleteAlertVisible) {
      return null;
    }

    return <ProfileIncompleteAlert onClickMute={handleClickMute} onClickGotoProfile={handleClickGotoProfile} />;
  }, [isProfileIncompleteAlertVisible, handleClickMute, handleClickGotoProfile]);

  return profileIncompleteAlert;
};
