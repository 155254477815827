import React from "react";

type ChildrenFunc = (args: { mainScrollElement: HTMLElement }) => React.ReactNode;

export const useMainContentScroll = (
  children?: React.ReactNode | ChildrenFunc,
  outerRef?: React.MutableRefObject<HTMLElement | undefined>
) => {
  const [isScrollElementReady, setIsScrollElementReady] = React.useState(false);

  const ref = React.useRef<HTMLElement>();
  const handleRef = React.useCallback(
    (el: HTMLDivElement) => {
      ref.current = el;

      if (outerRef) {
        outerRef.current = el;
      }

      setIsScrollElementReady(true);
    },
    [outerRef]
  );

  const scrollElement = ref.current;

  const childrenNode = React.useMemo(() => {
    if (typeof children !== "function") {
      return children;
    }

    if (typeof children === "function" && isScrollElementReady && scrollElement) {
      const childrenFunc = children as ChildrenFunc;
      return childrenFunc({ mainScrollElement: scrollElement });
    }

    return null;
  }, [children, isScrollElementReady, scrollElement]);

  return {
    isScrollElementReady,
    scrollElement,
    handleScrollElementRef: handleRef,
    childrenNode,
  };
};
