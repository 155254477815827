// language=ejs
const radio = `<!-- Radio -->
{% const isReadOnly = ctx.options.readOnly %}
{% const isImageRadio = ctx.component.properties && (typeof ctx.component.properties['display_type'] !== undefined) && ctx.component.properties['display_type'] === 'image' %}

<!--  Begin: Readonly  -->
{% if (isReadOnly) { %}
<div class="form-radio radio">
    <label class="form-check-label">
        {% ctx.values.forEach(function(item) { %}
        <div class="form-check radio-option" ref="wrapper">
            <{{ctx.input.type}} ref="input" {% for (var attr in ctx.input.attr) { %} {{attr}}="{{ctx.input.attr[attr]}}" {%
            } %} id="{{ctx.id}}{{ctx.row}}-{{item.value}}" value="{{item.value}}"/>
            <span class="value">{{ctx.t(item.label)}}</span>
        </div>
        {% }) %}
    </label>
</div>
{% } %}
<!--  End: Readonly  -->

<!--  Begin: Image Radio  -->
{% if (!isReadOnly && isImageRadio) { %}
{% ctx.input.attr['class'] += ' m-0 ob-image-radio' %}
<div class="form-radio radio d-flex justify-content-between align-items-baseline">
    {% ctx.values.forEach(function(item) { %}

    <div class="form-check{{ctx.inline ? '-inline' : ''}} radio-option" ref="wrapper">
        <label class="form-check-label label-position-{{ ctx.component.optionsLabelPosition }} d-flex flex-column"
               for="{{ctx.id}}{{ctx.row}}-{{item.value}}"
               style="cursor: pointer">


            <img alt="{{item.label}}"
                 src="{{ctx.component.getImageUrl(item.value, ctx.value === item.value)}}"
                 style="opacity: {{ctx.component.getImageOpacity(ctx.value === item.value)}};"
            />

            <div class="pt-6"></div>

            <div>
                <{{ctx.input.type}}
                ref="input"
                {% for (var attr in ctx.input.attr) { %}
                {{attr}}="{{ctx.input.attr[attr]}}"
                {% } %}
                value="{{item.value}}"
                {% if (ctx.value && (ctx.value === item.value || (typeof ctx.value === 'object' &&
                ctx.value.hasOwnProperty(item.value) && ctx.value[item.value]))) { %}
                checked=true
                {% } %}
                {% if (item.disabled) { %}
                disabled=true
                {% } %}
                id="{{ctx.id}}{{ctx.row}}-{{item.value}}"
                >
                <span>{{ctx.t(item.label)}}</span>
            </div>
        </label>
    </div>
    {% }) %}
</div>
{% } %}
<!--  End: Image Radio  -->

<!-- Begin: Normal Radio  -->
{% if (!isReadOnly && !isImageRadio) { %}
<div class="form-radio radio">
    {% ctx.values.forEach(function(item) { %}
    <div class="form-check{{ctx.inline ? '-inline' : ''}} radio-option" ref="wrapper">
        <label class="form-check-label label-position-{{ ctx.component.optionsLabelPosition }}"
               for="{{ctx.id}}{{ctx.row}}-{{item.value}}">
            <{{ctx.input.type}}
            ref="input"
            {% for (var attr in ctx.input.attr) { %}
            {{attr}}="{{ctx.input.attr[attr]}}"
            {% } %}
            value="{{item.value}}"
            {% if (ctx.value && (ctx.value === item.value || (typeof ctx.value === 'object' &&
            ctx.value.hasOwnProperty(item.value) && ctx.value[item.value]))) { %}
            checked=true
            {% } %}
            {% if (item.disabled) { %}
            disabled=true
            {% } %}
            id="{{ctx.id}}{{ctx.row}}-{{item.value}}"
            >
            <span>{{ctx.t(item.label)}}</span>
        </label>
    </div>
    {% }) %}
</div>
{% } %}
<!-- End: Normal Radio  -->
`;
export default radio;
