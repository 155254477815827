import React from "react";
import MuiDialogContent, { DialogContentProps } from "@material-ui/core/DialogContent";
import styled, { css } from "styled-components/macro";

type Props = DialogContentProps;

const DialogContent: React.FC<Props> = React.forwardRef((props, ref) => {
  return <StyledMuiDialogContent ref={ref} {...props} />;
});

const StyledMuiDialogContent = styled(MuiDialogContent)(
  ({ theme }) => css`
    font-size: 14px;
    line-height: 150%;
    color: ${theme.palette.objective.dark.fiordland};
    min-width: 600px;

    ${theme.breakpoints.down("xs")} {
      min-width: unset;
    }
  `
);

export default DialogContent;
