export enum SortDirection {
  Ascending = "asc",
  Descending = "desc",
}

export const SortDirectionUtil = {
  parse: (criteria: string): SortDirection => {
    switch (criteria) {
      case "asc":
        return SortDirection.Ascending;
      case "desc":
        return SortDirection.Descending;
      default:
        return SortDirection.Descending;
    }
  },
};
