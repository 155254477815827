import { Color } from "@material-ui/lab/Alert/Alert";

export type IAnnouncementEntity = {
  id: string;
  location: AnnouncementLocation;
  severity: Color;
  startTime: string;
  endTime: string;
  persistent: boolean;
  header: string;
  subTitle: string;
  description: string;
};

export enum AnnouncementLocation {
  Authentication = "authentication",
  Dashboard = "dashboard",
}

export const AnnouncementLocationUtil = {
  parse: (str: string) => {
    const locations = Object.values(AnnouncementLocation);
    const location = locations.find((item) => str.toLowerCase() === item);
    return location || AnnouncementLocation.Dashboard;
  },
};

export const AnnouncementSeverityUtil = {
  parse: (str: string): Color => {
    const severities: Color[] = ["info", "success", "warning", "error"];
    const severity = severities.find((item) => item === str.toLowerCase());
    return severity || "info";
  },
};
