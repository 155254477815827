import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import {
  IJurisdictionDocumentTypeRelation,
  JurisdictionDocumentTypeDataUtil,
} from "models/IJurisdictionDocumentTypeRelation";

// Entity Adapter

const jurisdictionDocumentTypeRelationAdapter = createEntityAdapter<IJurisdictionDocumentTypeRelation>({
  selectId: (documentFormTypes) => JurisdictionDocumentTypeDataUtil.generateEntityKey(documentFormTypes),
  sortComparer: (a, b) => {
    if (a.jurisdictionId !== b.jurisdictionId) {
      return a.jurisdictionId - b.jurisdictionId;
    }

    return a.documentTypeId - b.documentTypeId;
  },
});

// Action & Thunks

export const upsertJurisdictionDocumentTypeRelation = createAction<IJurisdictionDocumentTypeRelation[]>(
  "domainData/jurisdictionDocumentTypes/updateJurisdictionDocumentTypeRelation"
);

// Reducer

export const jurisdictionDocumentTypeRelationReducer = createReducer(
  jurisdictionDocumentTypeRelationAdapter.getInitialState(),
  (builder) =>
    builder.addCase(upsertJurisdictionDocumentTypeRelation, (draft, action) => {
      jurisdictionDocumentTypeRelationAdapter.upsertMany(draft, action.payload);
    })
);

// Selectors

export const {
  selectAll: selectAllJurisdictionDocumentTypeRelations,
} = jurisdictionDocumentTypeRelationAdapter.getSelectors(
  (state: RootState) => state.domainData.jurisdictionDocumentTypeRelation
);

export const selectJurisdictionDocumentTypeRelationsByJurisdictionId = createDeepEqualSelector(
  [selectAllJurisdictionDocumentTypeRelations, (state: RootState, jurisdictionId: number) => jurisdictionId],
  (allRelations, jurisdictionId) => {
    return allRelations.filter((rel) => rel.jurisdictionId === jurisdictionId);
  }
);
