import styled, { css } from "styled-components/macro";
import FlexBox from "components/FlexBox/FlexBox";

const StyledAccordionTitle = styled(FlexBox)(
  ({ theme }) => css`
    flex-grow: 1;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: ${theme.palette.text.primary};
  `
);

export default StyledAccordionTitle;
