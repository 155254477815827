import React, { useRef } from "react";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import FontIcon from "components/FontIcon/FontIcon";
import HelpCentreDialog from "components/AppHeader/HelpCentreDialog";
import { useTranslation } from "react-i18next";
import { IconButton, Link } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import { WEB_HELP_LINK } from "constants/configs";
import { useDialog } from "hooks/useDialog";
import { ODL_ICONS } from "components/FontIcon/FontIcon.constant";

type Props = {};

const HelpNavItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { Menu, openMenu, closeMenu } = useMenu();
  const webHelpLinkRef = useRef<HTMLAnchorElement>(null);
  const {
    isDialogOpen: isHelpCentreDialogOpen,
    openDialog: openHelpCentreDialog,
    closeDialog: closeHelpCentreDialog,
  } = useDialog();

  const handleClickWebHelpLink = React.useCallback(() => {
    webHelpLinkRef.current?.click();
  }, []);

  return (
    <div data-testid={"HelpNavItem"}>
      <HoverTooltip title={t(`Help centre`)}>
        <StyledIconButton aria-label={t(`Help centre`)} onClick={openMenu}>
          <StyledFontIcon name={ODL_ICONS.HELP_CIRCLE} />
        </StyledIconButton>
      </HoverTooltip>

      <Menu data-testid="HelpNavItemPopup">
        <MenuItem
          icon={ODL_ICONS.HELP_CIRCLE_OUTLINE}
          autoFocus={true}
          onClick={() => {
            closeMenu();
            handleClickWebHelpLink();
          }}
        >
          {t(`Online help`)}
        </MenuItem>
        <MenuItem
          icon={ODL_ICONS.HEADSET}
          onClick={() => {
            closeMenu();
            openHelpCentreDialog();
          }}
        >
          {t(`Support information`)}
        </MenuItem>
      </Menu>
      <StyledHiddenLink ref={webHelpLinkRef} href={WEB_HELP_LINK} target={"_blank"} rel="noopener" />
      <HelpCentreDialog isOpen={isHelpCentreDialogOpen} onClose={closeHelpCentreDialog} />
    </div>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    text-transform: none;
    color: ${theme.palette.text.secondary};
    width: 44px;
    height: 44px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 18px;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledHiddenLink = styled(Link)(
  ({ theme }) => css`
    display: none;
  `
);

export default HelpNavItem;
