import { Cradle } from "services/serviceContainer.types";
import { IDocumentTypeService } from "services/document-type/DocumentTypeService.types";
import { IDocumentTypeEntity } from "models/DocumentType.model";
import get from "lodash/get";
import toString from "lodash/toString";
import toInteger from "lodash/toInteger";
import { IDocumentTypeRelation } from "models/DocumentTypeRelation.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class DocumentTypeService implements IDocumentTypeService {
  private apiClient: Cradle["apiClient"];

  constructor(args: { apiClient: Cradle["apiClient"] }) {
    this.apiClient = args.apiClient;
  }

  public async fetchDocumentTypesByJurisdiction(jurisdictionId: number): Promise<IDocumentTypeEntity[]> {
    const response = await this.apiClient.publicApi.get(`/public/jurisdictions/${jurisdictionId}/document-types`);
    const jsonArr = get(response.data, "documentTypes");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      // TODO: BLD-562 Should throw error
      return [];
    }
    const documentTypes = jsonArr.map((json) => {
      return DocumentTypeService.parseDocumentType(json);
    });
    return documentTypes;
  }

  public async fetchDocumentTypesByJurisdictionForm(args: {
    jurisdictionId: number;
    formId: number;
  }): Promise<IDocumentTypeEntity[]> {
    const response = await this.apiClient.publicApi.get(
      `/public/jurisdictions/${args.jurisdictionId}/forms/${args.formId}/document-types`
    );
    const jsonArr = get(response.data, "documentTypes");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      // TODO: BLD-562 Should throw error
      return [];
    }
    const documentTypes = jsonArr.map((json) => {
      return DocumentTypeService.parseDocumentType(json);
    });
    return documentTypes;
  }

  public async fetchDocumentTypesByApplicationDocument(args: {
    applicationId: number;
    documentName: string;
  }): Promise<IDocumentTypeEntity[]> {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${args.applicationId}/documents/${args.documentName}/types`
    );
    const jsonArr = get(response.data, "documentTypes");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Failed to fetch document types by document name");
    }
    const documentTypes = jsonArr.map((json) => {
      return DocumentTypeService.parseDocumentType(json);
    });
    return documentTypes;
  }

  public async updateDocumentTypesForApplicationDocument(args: {
    applicationId: number;
    documentName: string;
    documentTypeNames: string[];
  }): Promise<IDocumentTypeRelation[]> {
    const response = await this.apiClient.protectedApi.put(
      `/user/applications/${args.applicationId}/documents/${args.documentName}/types`,
      {
        types: args.documentTypeNames,
      }
    );
    const jsonArr = get(response.data, "documentTypes");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Failed to fetch document types");
    }
    const documentTypeEntities = jsonArr.map((json) => DocumentTypeService.parseDocumentType(json));
    const documentTypeRelations = documentTypeEntities.map(
      (documentType): IDocumentTypeRelation => {
        return {
          documentTypeId: documentType.id,
          documentName: args.documentName,
        };
      }
    );
    return documentTypeRelations;
  }

  public static parseDocumentType(json: any): IDocumentTypeEntity {
    const documentType: IDocumentTypeEntity = {
      id: toInteger(get(json, "id", 0)),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder", 0)),
      description: toString(get(json, "description")),
    };
    return documentType;
  }
}
