import { differenceInDays, differenceInMinutes, format, formatDistanceStrict, subDays } from "date-fns";

type FormatDateFunction = (
  dateStr: string,
  format: string,
  options?: {
    locale: Locale;
  }
) => string;

export const formatNotificationDateStr: FormatDateFunction = (dateStr, ...args) => {
  try {
    const diffInDays = differenceInDays(new Date(), new Date(dateStr));

    // Check if it is future date
    if (diffInDays < 0) {
      return "just now";
    }

    // Check for notification in last 24 hours
    if (diffInDays >= 0 && diffInDays <= 1) {
      const diffInMinutes = differenceInMinutes(new Date(), new Date(dateStr));

      if (diffInMinutes <= 0) {
        return "just now";
      }

      if (diffInMinutes > 0 && diffInMinutes < 60) {
        return formatDistanceStrict(subDays(new Date(dateStr), 0), new Date(), { addSuffix: true, unit: "minute" });
      }
      return formatDistanceStrict(subDays(new Date(dateStr), 0), new Date(), { addSuffix: true, unit: "hour" });
    }
    return format(Date.parse(dateStr), ...args);
  } catch (e) {
    return "Invalid date";
  }
};
