import { ICountryService } from "services/country/CountryService.types";
import { Cradle } from "services/serviceContainer.types";
import { ICountryEntity } from "models/Country.model";
import get from "lodash/get";
import toString from "lodash/toString";
import toInteger from "lodash/toInteger";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class CountryService implements ICountryService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchCountries() {
    const response = await this.apiClient.protectedApi.get(`/user/countries`);
    const jsonArr = get(response.data, "countries");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch countries`));
    }
    const countries: ICountryEntity[] = jsonArr.map((json) => this.parseCountry(json));
    return countries;
  }

  private parseCountry(json: any): ICountryEntity {
    return {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      abbreviatedName: toString(get(json, "abbreviatedName")),
      phoneCountryCode: toString(get(json, "phoneCountryCode")),
    };
  }
}
