export type INotificationsResult = {
  notifications: INotificationEntity[];
  lastKey: string;
};

export type INotificationEntity = {
  id: string;
  groupId: string;
  recipientId: string;
  recipientType: NotificationRecipientType;

  createdDate: string;
  updatedDate: string;

  message: {
    subtype: NotificationMessageType;
    shortText: string;
    fullText?: string;
    htmlText?: string;
  };

  recipients: {
    users: Array<{
      id: string;
      email: string;
      fullName: string;
    }>;
    organisation: {
      id: string;
      email: string;
    };
    application: {
      id: string;
    };
    authority: {
      id: string;
    };
  };
  apiVersion: string;
};

export enum NotificationSortOrder {
  Ascending = "Asc",
  Descending = "Desc",
}

export enum NotificationRecipientType {
  User = "User",
  Application = "Application",
  Organisation = "Organisation",
}

export enum NotificationMessageType {
  Payment = "Payment",
  RFI = "RFI",
  Application = "Application",
  ApplicationSubmit = "ApplicationSubmit",
  Participant = "Participant",
  Organisation = "Organisation",
}

export const NotificationMessageTypeUtil = {
  parse: (notificationType: string): NotificationMessageType => {
    switch (notificationType) {
      case NotificationMessageType.RFI:
        return NotificationMessageType.RFI;
      case NotificationMessageType.Payment:
        return NotificationMessageType.Payment;
      case NotificationMessageType.Application:
        return NotificationMessageType.Application;
      case NotificationMessageType.ApplicationSubmit:
        return NotificationMessageType.ApplicationSubmit;
      case NotificationMessageType.Participant:
        return NotificationMessageType.Participant;
      case NotificationMessageType.Organisation:
        return NotificationMessageType.Organisation;
      default:
        return NotificationMessageType.Application;
    }
  },
};

export const NotificationRecipientTypeUtil = {
  parse: (recipientType: string): NotificationRecipientType => {
    switch (recipientType) {
      case NotificationRecipientType.User:
        return NotificationRecipientType.User;
      case NotificationRecipientType.Application:
        return NotificationRecipientType.Application;
      case NotificationRecipientType.Organisation:
        return NotificationRecipientType.Organisation;
      default:
        return NotificationRecipientType.User;
    }
  },
};
