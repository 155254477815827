import React from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import FontIcon from "components/FontIcon/FontIcon";
import Guard from "components/Guard/Guard";
import FlexBox from "components/FlexBox/FlexBox";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";

type Props = {
  title: React.ReactNode;
  subtitle?: string;
  isBackButtonVisible?: boolean;
  topAccessory?: React.ReactNode;
  toolbar?: React.ReactNode;
  backURL?: string;
  headerIcon?: React.ReactNode;
};

const PageHeader: React.FC<Props> = ({
  title,
  subtitle,
  topAccessory,
  isBackButtonVisible = true,
  toolbar,
  backURL,
  headerIcon,
}) => {
  const { t } = useTranslation();
  const screenWidthMatch = useScreenWidthMatch();

  const handleClickBack = useNavigateBack({
    backURL,
    enabled: isBackButtonVisible,
  });

  return (
    <StyledContainer data-testid={"PageHeader"}>
      <Guard condition={isBackButtonVisible}>
        <StyledIconButton aria-label={t(`Back`)} onClick={handleClickBack}>
          <StyledFontIcon width={44} name="arrow-left" />
        </StyledIconButton>
      </Guard>
      <StyledTitleSection>
        <Guard condition={topAccessory}>{topAccessory}</Guard>

        <FlexBox spacing={4} alignItems={"stretch"} overflow={"hidden"}>
          <Guard condition={headerIcon && screenWidthMatch.sm}>
            <StyledHeaderIcon>{headerIcon}</StyledHeaderIcon>
          </Guard>
          <StyledTitleContainer>
            <StyledMainTitle>{title}</StyledMainTitle>
            <Guard condition={subtitle && screenWidthMatch.sm}>
              <StyledSubtitle>{subtitle}</StyledSubtitle>
            </Guard>
          </StyledTitleContainer>
        </FlexBox>
      </StyledTitleSection>
      <StyledToolbar>{toolbar}</StyledToolbar>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $centered?: boolean }>(
  ({ theme, $centered }) => css`
    max-width: 100%;
    display: flex;
    ${theme.mixins.flexGap("16px")};
    align-items: center;
    z-index: ${theme.zIndex.appBar - 1};
    min-height: 44px;
  `
);

const StyledTitleSection = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("4px")};
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  `
);

const StyledTitleContainer = styled.div(
  ({ theme }) => css`
    overflow: hidden;
    max-width: 100%;
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    min-height: 44px;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: ${theme.zIndex.appBar};
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledMainTitle = styled.h2(
  ({ theme }) => css`
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 16pt;
    line-height: 20pt;
    font-weight: 700;
    color: ${theme.palette.objective.dark.night};
    z-index: ${theme.zIndex.appBar};
  `
);

const StyledSubtitle = styled.h3(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.neutral};
    z-index: ${theme.zIndex.appBar};
  `
);

const StyledToolbar = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: ${theme.zIndex.appBar};
  `
);

const StyledHeaderIcon = styled(FlexBox).attrs({ flexGrow: 0, flexShrink: 0 })(
  ({ theme }) => css`
    width: 44px;
    height: 44px;
    z-index: ${theme.zIndex.appBar};
  `
);

export default PageHeader;
