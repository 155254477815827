import React from "react";
import { serviceContainer } from "services/serviceContainer";
import { useDocumentPreviewDialog } from "components/DocumentPreviewDialog/useDocumentPreviewDialog";
import { usePostHog } from "posthog-js/react";
import { useStoreSelector } from "store/hooks";
import { selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId } from "store/domain-data/participant/participant";

export const usePreviewApplicationDocument = (applicationId: number) => {
  const { document, openDocumentPreviewDialog, DocumentPreviewDialog } = useDocumentPreviewDialog();
  const posthog = usePostHog();

  const participantTypesOfCurrentUserOnApplicationBasedOnApplicationId = useStoreSelector((state) =>
    selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId(state, applicationId)
  );

  const applicationDocumentPreviewDialog = React.useMemo(() => {
    if (!document || !document.name) {
      return null;
    }

    const getDownloadLink = () =>
      serviceContainer.cradle.applicationDocumentService
        .fetchApplicationDocumentDownloadInfo({
          applicationId,
          name: document.name,
        })
        .then((downloadInfo) => downloadInfo.presignedUrl);

    posthog?.capture(`user previewed-document`, {
      participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
    });

    return <DocumentPreviewDialog data-testid={"ApplicationDocumentPreviewDialog"} getDownloadLink={getDownloadLink} />;
  }, [
    document,
    posthog,
    participantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
    DocumentPreviewDialog,
    applicationId,
  ]);

  return {
    previewApplicationDocument: openDocumentPreviewDialog,
    applicationDocumentPreviewDialog,
  };
};
