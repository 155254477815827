import React, { useRef } from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import {
  addQualification,
  clearQualifications,
  selectCurrentParticipant,
  selectPersonalInfoFormFields,
  setLibraryContactId,
  clearLibraryContactId,
  setPersonalInfoFormFields,
} from "store/app-state/participant-buffer/participantBuffer";
import {
  IParticipantLookupResponse,
  IParticipantPersonalInfoFormData,
  ParticipantAccessLevel,
  rankAccessLevel,
} from "models/Participant.model";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import { selectMustInviteSelectedParticipantTypes } from "store/domain-data/participant-type/participantType";
import { selectEmailExistsOnOtherApplicationParticipant } from "store/domain-data/participant/participant";
import isEmpty from "lodash/isEmpty";
import { useStore } from "react-redux";
import Guard from "components/Guard/Guard";
import FormikChangeDetection from "components/FormikChangeDetection/FormikChangeDetection";

import styled, { css } from "styled-components/macro";
import { FULL_CONTACT_PHONE_ENABLED } from "constants/configs";
import FormikAddressSearch from "../FormikAddressInput/FormikAddressSearch";
import FormikManualAddressInput from "../FormikAddressInput/FormikManualAddressInput";
import PersonSearch from "./PersonSearch";
import PersonalDetails from "./PersonalDetails";
import PersonalInfoViewModeButton from "./PersonalInfoViewModeButton";
import { serviceContainer } from "services/serviceContainer";
import { PersonSearchLibraries } from "services/person-search/PersonSearchService.types";
import first from "lodash/first";
import { IQualificationEntity } from "models/Qualification.model";

export enum ParticipantInfoFormViewMode {
  PersonalInfo = "PersonalInfo",
  ManualAddress = "ManualAddress",
}

type Props = {
  onFormValidated: (invalid: boolean) => void;
};

const PersonalInfoForm: React.FC<Props> = ({ onFormValidated }) => {
  // Hooks
  const dispatch = useStoreDispatch();
  const store = useStore();
  const formRef = useRef<FormikProps<IParticipantPersonalInfoFormData>>(null);

  const [participantInfoView, setParticipantInfoView] = React.useState<ParticipantInfoFormViewMode>(
    ParticipantInfoFormViewMode.PersonalInfo
  );

  const currentFormFields = useStoreSelector((state) => selectPersonalInfoFormFields(state));
  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));
  const mustInviteSelectedParticipantsTypes = useStoreSelector((state) =>
    selectMustInviteSelectedParticipantTypes(state, { selectedParticipantTypes: currentParticipant.participantTypes })
  );
  const isEmailRequired = React.useMemo(() => {
    const hasUserAccess =
      rankAccessLevel(currentParticipant.accessLevel) >= rankAccessLevel(ParticipantAccessLevel.ReadOnly);
    return hasUserAccess || !isEmpty(mustInviteSelectedParticipantsTypes);
  }, [mustInviteSelectedParticipantsTypes, currentParticipant]);

  const isPhoneNumberRequired = React.useMemo(() => {
    return currentParticipant.participantTypes.some((participantType) => {
      return ["OwnerContact", "Agent", "InvoicePayer"].includes(participantType.name);
    });
  }, [currentParticipant]);

  const isAddressRequired = React.useMemo(() => {
    return currentParticipant.participantTypes.some((participantType) => {
      return ["OwnerContact", "Agent", "InvoicePayer"].includes(participantType.name);
    });
  }, [currentParticipant]);

  const participantFormSchema = React.useMemo(() => {
    const testForEmailRequired = (value: string | undefined) => {
      return !isEmailRequired || !!value;
    };

    const testForPhoneRequired = (value: string | undefined) => {
      return !isPhoneNumberRequired || !!value;
    };

    const testForEmailUnique = (value: string | undefined) => {
      const emailAlreadyExists = selectEmailExistsOnOtherApplicationParticipant(store.getState(), {
        applicationId: currentParticipant.applicationId,
        participantId: currentParticipant.id,
        participantEmail: value || "",
      });

      return !emailAlreadyExists || !value;
    };

    let schema = yup.object().shape({
      firstName: yup
        .string()
        .required(formValidationUtils.getErrorMessageForRequiredField())
        .matches(formValidationUtils.getRegexForTrimmedName(), formValidationUtils.getErrorMessageForName()),
      lastName: yup
        .string()
        .required(formValidationUtils.getErrorMessageForRequiredField())
        .matches(formValidationUtils.getRegexForTrimmedName(), formValidationUtils.getErrorMessageForName()),
      email: yup
        .string()
        .matches(formValidationUtils.getRegexForEmail(), formValidationUtils.getErrorMessageForEmail())
        .test("email-required", formValidationUtils.getErrorMessageForRequiredField(), testForEmailRequired)
        .test("email-unique", formValidationUtils.getErrorMessageForDuplicateEmail(), testForEmailUnique),
    });

    if (FULL_CONTACT_PHONE_ENABLED) {
      schema = schema.shape({
        phoneCountryCode: yup.string(),
        phone: yup.string().when("phoneCountryCode", (phoneCountryCode: string) => {
          return yup
            .string()
            .test("phone-required", formValidationUtils.getErrorMessageForRequiredField(), testForPhoneRequired)
            .matches(
              formValidationUtils.getRegexForContactNumber(phoneCountryCode),
              formValidationUtils.getErrorMessageForContactNumber()
            );
        }),
      });
    } else {
      schema = schema.shape({
        phone: yup
          .string()
          .test("phone-required", formValidationUtils.getErrorMessageForRequiredField(), testForPhoneRequired),
      });
    }

    if (isAddressRequired) {
      schema = schema.shape({
        address1: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        city: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        country: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        zipCode: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
        fullAddress: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
      });
    }

    return schema;
  }, [
    currentParticipant.applicationId,
    currentParticipant.id,
    isEmailRequired,
    isPhoneNumberRequired,
    store,
    isAddressRequired,
  ]);

  const handleSubmit = React.useCallback(
    async (
      formValues: IParticipantPersonalInfoFormData,
      formikHelpers: FormikHelpers<IParticipantPersonalInfoFormData>
    ) => {
      dispatch(setPersonalInfoFormFields(formValues));
    },
    [dispatch]
  );

  // Required to dynamically detect participant type unselections and revalidate with updated schema
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [currentParticipant.participantTypes, currentParticipant.accessLevel]);

  const handleChangeView = React.useCallback((view: ParticipantInfoFormViewMode) => {
    setParticipantInfoView(view);
  }, []);

  const handlePersonSearchSelected = React.useCallback(
    async (args: {
      identifier: any;
      personDetails: IParticipantLookupResponse;
      searchLibrary: PersonSearchLibraries;
    }) => {
      const { identifier, personDetails, searchLibrary } = args;
      if (!formRef.current) {
        return;
      }
      for (const key of Object.keys(formRef.current.values)) {
        formRef.current.setFieldValue(key, "");
      }
      for (const [key, value] of Object.entries(personDetails)) {
        formRef.current.setFieldValue(key, value);
      }

      if (searchLibrary === PersonSearchLibraries.ContactLibrary) {
        dispatch(setLibraryContactId(identifier));
      } else {
        dispatch(clearLibraryContactId());
      }

      if (isEmpty(personDetails.qualifications)) {
        return;
      }

      dispatch(clearQualifications());
      for (const qualification of personDetails.qualifications) {
        // If qualification is "other", then no special processing
        if (qualification.qualificationName.toLowerCase() === "other") {
          dispatch(addQualification(qualification));
          continue;
        }

        // If data coming from LBP library search, then no special processing
        if (searchLibrary === PersonSearchLibraries.LBP) {
          dispatch(addQualification(qualification));
          continue;
        }

        // Special handling for LBP qualifications coming from Contact Library
        // Note:
        //   There was a scenario identified that when we save a contact with qualifications from LBP lookup, the details of that LBP may have changed since the original lookup.
        //   So now when user adds from contact library, we want to look up any qualifications to see if they have changed status.
        if (qualification.qualificationName === "LBP") {
          const updatedQualifications = await serviceContainer.cradle.personSearchService.updateQualification({
            library: PersonSearchLibraries.LBP,
            identifier: qualification.qualificationNumber,
          });
          const updatedQualification = first(updatedQualifications);
          if (updatedQualification) {
            dispatch(addQualification(updatedQualification));
          } else {
            // If the qualification can not be updated, keep the original with warning
            const warnedQualification: IQualificationEntity = {
              ...qualification,
              warnings: [
                {
                  label: qualification.qualificationNumber,
                  warning: `Could not verify ${qualification.qualificationNumber} in LBP Register`,
                },
              ],
            };
            dispatch(addQualification(warnedQualification));
          }
        }
      }
    },
    [dispatch]
  );

  if (!currentFormFields) {
    return null;
  }

  return (
    <StyledParticipantPersonalInfoForm data-testid="EditParticipantStepPersonalInfo">
      <Formik<IParticipantPersonalInfoFormData>
        initialValues={currentFormFields}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={participantFormSchema}
        validateOnChange={true}
        innerRef={formRef}
      >
        {() => (
          <Form>
            <Guard condition={participantInfoView === ParticipantInfoFormViewMode.PersonalInfo}>
              <StyledParticipantPersonalFormWrapper>
                <Guard condition={!currentParticipant.id}>
                  <PersonSearch
                    availableSearchLibraries={[PersonSearchLibraries.LBP, PersonSearchLibraries.ContactLibrary]}
                    initialSearchLibrary={PersonSearchLibraries.LBP}
                    onSearchCompleted={handlePersonSearchSelected}
                  />
                </Guard>
                <PersonalDetails />
                <FormikAddressSearch label={"Address"} required={isAddressRequired} />
                <PersonalInfoViewModeButton
                  currentViewMode={ParticipantInfoFormViewMode.PersonalInfo}
                  onChangeViewMode={handleChangeView}
                />
              </StyledParticipantPersonalFormWrapper>
            </Guard>

            <Guard condition={participantInfoView === ParticipantInfoFormViewMode.ManualAddress}>
              <FormikManualAddressInput isRequired={isAddressRequired} />
              <PersonalInfoViewModeButton
                currentViewMode={ParticipantInfoFormViewMode.ManualAddress}
                onChangeViewMode={() => handleChangeView(ParticipantInfoFormViewMode.PersonalInfo)}
              />
            </Guard>

            <FormikChangeDetection onFormValidated={onFormValidated} />
          </Form>
        )}
      </Formik>
    </StyledParticipantPersonalInfoForm>
  );
};

const StyledParticipantPersonalInfoForm = styled.div(
  ({ theme }) => css`
    padding: 24px 48px;
  `
);

const StyledParticipantPersonalFormWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("12px")}
  `
);

export default PersonalInfoForm;
