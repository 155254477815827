import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { selectAuthorityEntityById } from "store/domain-data/authority/authority";
import ApplicationInfoField from "components/ApplicationCard/ApplicationInfoField";
import { selectPrimaryContactParticipantByApplicationId } from "store/domain-data/participant/participant";
import { getParticipantName } from "models/Participant.model";

type Props = {
  applicationId: number;
};

const ApplicationInfoDetails: React.FC<Props> = ({ applicationId }) => {
  // Common
  const { t } = useTranslation();

  // From Redux
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const authority = useStoreSelector((state) => selectAuthorityEntityById(state, application?.authorityId || 0));
  const primaryContactParticipant = useStoreSelector((state) =>
    selectPrimaryContactParticipantByApplicationId(state, applicationId)
  );

  // Guard
  if (!application) {
    return null;
  }

  return (
    <FlexBox direction="column" spacing={3} paddingTop={1}>
      <ApplicationInfoField label={t(`Contact`)} value={getParticipantName(primaryContactParticipant)} />
      <ApplicationInfoField label={t(`Type`)} value={application.complexityDescription} />
      <ApplicationInfoField label={t(`Authority`)} value={authority?.displayName} />
    </FlexBox>
  );
};

export default ApplicationInfoDetails;
