import React from "react";
import FlexBox from "../FlexBox/FlexBox";
import ParticipantQualificationTypeSelect from "./ParticipantQualificationTypeSelect";
import Guard from "../Guard/Guard";
import styled, { css } from "styled-components/macro";
import { Form, useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import OtherQualificationForm from "components/ParticipantEditDialog/qualification-forms/OtherQualificationForm";
import LbpQualificationForm from "components/ParticipantEditDialog/qualification-forms/LbpQualificationForm";
import LicenseFormButtons from "components/ParticipantEditDialog/qualification-forms/LicenseFormButtons";

type AddQualificationProps = {};

const QualificationFormContent: React.FC<AddQualificationProps> = () => {
  const { values } = useFormikContext<IQualificationEntity>();

  return (
    <StyledForm>
      <FlexBox direction="column" spacing={8} flexGrow={1}>
        <ParticipantQualificationTypeSelect />
        <Guard condition={values.qualificationName.toLowerCase() === "other"}>
          <OtherQualificationForm />
        </Guard>
        <Guard condition={values.qualificationName.toLowerCase() === "lbp"}>
          <LbpQualificationForm />
        </Guard>
        <LicenseFormButtons />
      </FlexBox>
    </StyledForm>
  );
};

const StyledForm = styled(Form)(
  () => css`
    position: relative;
    padding: 16px 0;
    width: 100%;
  `
);

export default QualificationFormContent;
