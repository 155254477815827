import React, { SyntheticEvent, useRef } from "react";
import { MenuItem } from "@material-ui/core";
import Box from "components/Box/Box";
import FontIcon from "components/FontIcon/FontIcon";
import Spacer from "components/Spacer/Spacer";
import styled, { css } from "styled-components/macro";
import toString from "lodash/toString";
import Guard from "components/Guard/Guard";

type Props = {
  title: React.ReactNode;
  value: string;
  icon?: string;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
};

const SelectOption: React.FC<Props> = ({ title, value, icon, disabled, onClick }) => {
  const itemRef = useRef<any | null>(null);

  const handleClick = React.useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (!disabled && onClick) {
        onClick(event);
      }
    },
    [disabled, onClick]
  );

  return (
    <StyledMenuItem
      data-testid="PopupMenuItem"
      tabIndex="0"
      ref={itemRef}
      onClick={handleClick}
      aria-label={toString(title)}
      value={value}
      disabled={disabled}
    >
      <Guard condition={!!icon}>
        <FontIcon name={icon!} />
        <Spacer x={4} />
      </Guard>
      <Box fontSize={14} fontWeight={500}>
        {title}
      </Box>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    border-left: 4px solid transparent;
    color: ${theme.palette.primary.main};

    &:hover {
      border-left: 4px solid ${theme.palette.primary.main};
      background: ${theme.palette.objective.blue.light};
    }
  `
);

export default SelectOption;
