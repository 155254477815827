import React from "react";
import MuiAccordion, { AccordionProps } from "@material-ui/core/Accordion";
import styled, { css } from "styled-components/macro";
import { TransitionProps } from "@material-ui/core/transitions";

type Props = AccordionProps;

const defaultTransitionProps: TransitionProps = {
  mountOnEnter: true,
  unmountOnExit: true,
};

const Accordion: React.FC<Props> = React.forwardRef(({ children, TransitionProps, ...props }, ref) => {
  return (
    <StyledMuiAccordion ref={ref} TransitionProps={TransitionProps || defaultTransitionProps} {...props}>
      {children}
    </StyledMuiAccordion>
  );
});

const StyledMuiAccordion = styled(MuiAccordion)(
  ({ theme }) => css`
    border: 1px solid transparent;
    box-shadow: none;

    &::before {
      background-color: ${theme.palette.objective.light.day};
    }

    &.Mui-expanded {
      border: 1px solid ${theme.palette.objective.light.allspice};
      box-sizing: border-box;
      border-radius: 4px;
      margin: 4px 0;
    }
  `
);

export default Accordion;
