import { IDBImage, IDBImageService } from "services/db-image/DBImageService.types";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import get from "lodash/get";
import { Cradle } from "services/serviceContainer.types";

export class DBImageService implements IDBImageService {
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async fetchDBImageById(id: number): Promise<IDBImage> {
    const response = await this.apiClient.publicApi.get(`/public/images/${id}`);
    const json = response.data;
    const dbImage: IDBImage = {
      id: toInteger(get(json, "id")),
      type: toString(get(json, "type")),
      name: toString(get(json, "name")),
      base64Image: toString(get(json, "base64Image")),
    };
    return dbImage;
  }
}
