import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/types";

// Type

export enum AvailableFlagKey {
  NewApplicationPageViewMode = "NewApplicationPageViewMode",
  GlobalToastMessage = "GlobalToastMessage",
}

// Store a key-value mapping
export type FlagState = Record<string, string>;

// Actions & Thunks

export const setFlag = createAction<{ usedFor: AvailableFlagKey; flag: string }>("appState/flag/setFlag");

// Reducer

export const defaultFlagState: FlagState = {};

export const flagReducer = createReducer<FlagState>(defaultFlagState, (builder) =>
  builder.addCase(setFlag, (draft, action) => {
    const { usedFor, flag } = action.payload;
    draft[usedFor] = flag;
  })
);

// Selectors

export const selectFlagState = (state: RootState) => {
  return state.appState.flag;
};

export const selectFlagFor = createSelector(
  [selectFlagState, (_: RootState, props: { usedFor: AvailableFlagKey }) => props.usedFor],
  (state, usedFor) => state[usedFor] || ""
);
