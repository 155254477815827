import React from "react";

export const useCollapse = (isCollapsedInitially: boolean) => {
  const [isCollapsed, setIsCollapsed] = React.useState(isCollapsedInitially);

  const collapse = React.useCallback(() => {
    setIsCollapsed(true);
  }, []);

  const expand = React.useCallback(() => {
    setIsCollapsed(false);
  }, []);

  const toggleCollapse = React.useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, [setIsCollapsed]);

  const chevronIcon = React.useMemo(() => {
    if (isCollapsed) {
      return "chevron-up";
    }
    return "chevron-down";
  }, [isCollapsed]);

  return {
    isCollapsed,
    toggleCollapse,
    expand,
    collapse,
    chevronIcon,
  };
};
