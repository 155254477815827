import { css } from "styled-components/macro";

/**
 * CSS mixin for disabled styles
 *
 * @param disabled
 */
export const disabledMixin = (disabled: boolean = false) => {
  if (!disabled) {
    return css``;
  }

  return css`
    border: none !important;
    color: ${(props) => props.theme.palette.objective.light.allspice} !important;
    background-color: ${(props) => props.theme.palette.objective.light.day} !important;
  `;
};
