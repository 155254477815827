import React from "react";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectAllApplicationEntities } from "store/domain-data/application/application";
import { uniqBy } from "lodash";
import { fetchJurisdictionById, selectJurisdictionEntityIds } from "store/domain-data/jurisdiction/jurisdiction";

type Props = {};

const MissingJurisdictionListener: React.FC<Props> = () => {
  const dispatch = useStoreDispatch();
  const store = useStore<RootState>();
  const applications = useStoreSelector(selectAllApplicationEntities);

  // When application changes, fetch jurisdiction info if it is missing to provide basic multi-jurisdictions support
  React.useEffect(() => {
    const jurisdictionIds = uniqBy(applications, (item) => item.jurisdictionId).map((item) => item.jurisdictionId);
    const existingJurisdictionIds = selectJurisdictionEntityIds(store.getState());
    for (const jurisdictionId of jurisdictionIds) {
      if (existingJurisdictionIds.includes(jurisdictionId)) {
        continue;
      }
      dispatch(fetchJurisdictionById(jurisdictionId));
    }
  }, [applications, dispatch, store]);

  return null;
};

export default MissingJurisdictionListener;
