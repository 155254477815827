import React from "react";
import isEmpty from "lodash/isEmpty";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  addExpiredItemsToQueue,
  removeExpiredItems,
  selectApplicationDocumentUploadExpiredItemsByApplicationAndContainer,
} from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import ApplicationDocumentExpiredDialog from "components/DocumentContainer/ApplicationDocumentExpiredDialog";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { usePostHog } from "posthog-js/react";

export const useApplicationDocumentExpiredDialog = (applicationId: number, documentContainerId: number) => {
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();

  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));
  const documentContainerName = documentContainer?.displayName || "";
  const documentContainerPosthogId = documentContainerName.toLowerCase().replace(/\s+/g, "-");

  const expiredFileItems = useStoreSelector((state) =>
    selectApplicationDocumentUploadExpiredItemsByApplicationAndContainer(state, {
      applicationId,
      documentContainerId,
    })
  );
  const expiredFileNames = React.useMemo(() => {
    return expiredFileItems.map((item) => item.file.name);
  }, [expiredFileItems]);

  const handleClickKeep = React.useCallback(async () => {
    dispatch(addExpiredItemsToQueue({ applicationId, documentContainerId }));
    posthog?.capture(`user uploaded-${documentContainerPosthogId}`);
  }, [applicationId, dispatch, documentContainerId, posthog, documentContainerPosthogId]);

  const handleClickCancel = React.useCallback(() => {
    dispatch(removeExpiredItems({ applicationId, documentContainerId }));
    posthog?.capture(`user deleted-${documentContainerPosthogId}`);
  }, [applicationId, dispatch, documentContainerId, posthog, documentContainerPosthogId]);

  const expiredDocumentsDialog = React.useMemo(() => {
    if (isEmpty(expiredFileNames)) {
      return null;
    }

    return (
      <ApplicationDocumentExpiredDialog
        onClickCancel={handleClickCancel}
        onClickKeep={handleClickKeep}
        expiredFileNames={expiredFileNames}
        documentContainerName={documentContainerName}
        fileExpiryInMonths={3}
      />
    );
  }, [documentContainerName, expiredFileNames, handleClickCancel, handleClickKeep]);

  return {
    expiredDocumentsDialog,
  };
};
