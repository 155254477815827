import React from "react";
import { useStoreDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { SortDirection } from "models/SortDirection.model";
import { ApplicationSortCriteria } from "models/ApplicationSortCriteria.model";
import { fetchPaginatedOutstandingApplicationsV3ForCurrentUser } from "store/domain-data/application-v3/applicationV3";
import { FetchDataForPageFunc } from "hooks/usePaginatedData";
import { IApplicationV3Entity } from "models/ApplicationV3.model";
import { DEFAULT_PAGE_SIZE } from "constants/configs";

export const useFetchOutstandingApplicationsForCurrentUser = () => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  const runFetchPaginatedApplications = React.useCallback(
    async (args: { pageIndex: number }) => {
      try {
        const data = await dispatch(
          fetchPaginatedOutstandingApplicationsV3ForCurrentUser({
            pageIndex: args.pageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            sortDirection: SortDirection.Descending,
            sortBy: ApplicationSortCriteria.ModifiedDate,
          })
        ).then(unwrapResult);
        const hasNextPage = data.length === DEFAULT_PAGE_SIZE;
        return { data, hasNextPage };
      } catch (e) {
        toastError(e);
        throw e;
      }
    },
    [dispatch, toastError]
  );

  const fetchPaginatedOutstandingApplicationsForPage: FetchDataForPageFunc<IApplicationV3Entity> = React.useCallback(
    (pageIndex) => {
      return runFetchPaginatedApplications({
        pageIndex: pageIndex,
      });
    },
    [runFetchPaginatedApplications]
  );

  return fetchPaginatedOutstandingApplicationsForPage;
};
