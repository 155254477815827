// TODO: BLD-1987 typing issue with this function
export function enumFromValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: number | string | null | undefined,
  defaultValue: any
): T {
  if (enumValue === null) {
    return defaultValue;
  }
  const value = typeof enumValue === "string" ? enumValue.trim() : enumValue;
  const key = Object.keys(myEnum).find((x) => myEnum[x] === value);
  return key ? myEnum[key] : defaultValue;
}
