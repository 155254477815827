import React from "react";
import { IParticipantBufferEntity } from "store/app-state/participant-buffer/participantBuffer";
import ParticipantTypeTag from "components/ParticipantTypeTag/ParticipantTypeTag";
import { useStoreSelector } from "store/hooks";
import { selectParticipantTypeEntityById } from "store/domain-data/participant-type/participantType";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import { useDialog } from "hooks/useDialog";
import ParticipantCannotRemoveInformationDialog from "components/ParticipantCannotRemoveInformationDialog/ParticipantCannotRemoveInformationDialog";
import { selectParticipantEntityById } from "store/domain-data/participant/participant";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { useTranslation } from "react-i18next";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import { PARTICIPANT_TYPE_NAMES } from "models/Participant.model";

type Props = {
  participantBuffer: IParticipantBufferEntity;
  participantTypeId: number;
  onToggle: () => void;
};

const ParticipantFormParticipantTypeTag: React.FC<Props> = ({ participantBuffer, participantTypeId, onToggle }) => {
  const { t } = useTranslation();
  const store = useStore<RootState>();

  const {
    isDialogOpen: isLockInfoDialogOpen,
    closeDialog: closeLockInfoDialog,
    openDialog: openLockInfoDialog,
  } = useDialog();

  const participantType = useStoreSelector((state) => selectParticipantTypeEntityById(state, participantTypeId));
  const applicationId = participantBuffer?.applicationId || 0;
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const isSelected = React.useMemo(() => {
    if (!participantBuffer || !participantType) {
      return false;
    }
    return participantBuffer.participantTypes.some((selectedType) => selectedType.name === participantType.name);
  }, [participantBuffer, participantType]);

  const isDisabled = React.useMemo(() => {
    if (!participantBuffer || !participantType) {
      return false;
    }

    if (participantType.name !== PARTICIPANT_TYPE_NAMES.ADDITIONAL_OWNER) {
      return false;
    }
    // If Registered Owner is already assigned then Additional Owner is disabled
    const hasRegisteredOwner = participantBuffer.participantTypes.some(
      (selectedType) => selectedType.name === PARTICIPANT_TYPE_NAMES.REGISTERED_OWNER
    );
    return hasRegisteredOwner;
  }, [participantBuffer, participantType]);

  const tagTooltip = React.useMemo(() => {
    if (!participantBuffer || !participantType) {
      return false;
    }

    if (!isDisabled) {
      return "";
    }

    if (participantType.name === PARTICIPANT_TYPE_NAMES.ADDITIONAL_OWNER) {
      return t(`Registered Owner role is assigned already`);
    }
    return "";
  }, [participantBuffer, participantType, isDisabled, t]);

  const isLocked = React.useMemo(() => {
    if (!participantBuffer || !participantType) {
      return false;
    }

    // For existing participant, don't lock the newly selected participant type (aka, allow toggle selection for editing buffer)
    if (participantBuffer.id) {
      const participantBeforeEditing = selectParticipantEntityById(store.getState(), participantBuffer.id);
      const participantTypeNamesBeforeEditing =
        participantBeforeEditing?.participantTypes.map(
          (participantTypeBeforeEditing) => participantTypeBeforeEditing.name
        ) || [];
      if (!participantTypeNamesBeforeEditing.includes(participantType.name)) {
        return false;
      }
    }

    // Don't lock roles that are not selected to allow transfer
    if (!isSelected) {
      return false;
    }

    // Mandatory roles are locked
    if (participantType.mandatory) {
      return true;
    }

    // Required roles are locked after submission
    if (participantType.required && application?.status !== ApplicationStatus.Draft) {
      return true;
    }

    // Otherwise not locked
    return false;
  }, [application?.status, isSelected, participantBuffer, participantType, store]);

  const handleClickTag = React.useCallback(() => {
    if (isLocked) {
      openLockInfoDialog();
      return;
    }

    onToggle();
  }, [onToggle, isLocked, openLockInfoDialog]);

  if (!participantType) {
    return null;
  }

  return (
    <React.Fragment>
      <HoverTooltip title={tagTooltip}>
        <ParticipantTypeTag
          displayName={participantType.displayName}
          isClickable={true}
          isLocked={isLocked}
          isRequired={participantType.required}
          isSelected={isSelected}
          disabled={isDisabled}
          onClick={handleClickTag}
          data-participant-type-name={participantType.name}
        />
      </HoverTooltip>

      <ParticipantCannotRemoveInformationDialog
        participantTypeDisplayNames={participantType.displayName}
        isOpen={isLockInfoDialogOpen}
        onClose={closeLockInfoDialog}
      />
    </React.Fragment>
  );
};

export default ParticipantFormParticipantTypeTag;
