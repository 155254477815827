import React from "react";
import MuiDialogTitle, { DialogTitleProps } from "@material-ui/core/DialogTitle";
import styled, { css } from "styled-components/macro";

type Props = DialogTitleProps & { closeButton?: React.ReactNode };

const DialogTitle: React.FC<Props> = React.forwardRef(({ children, closeButton, ...props }, ref) => {
  return (
    <StyledMuiDialogTitle ref={ref} disableTypography={true} {...props}>
      <StyledHeader>{children}</StyledHeader>
      <StyledCloseButtonContainer>{closeButton}</StyledCloseButtonContainer>
    </StyledMuiDialogTitle>
  );
});

const StyledMuiDialogTitle = styled(MuiDialogTitle)(
  ({ theme }) => css`
    padding: 24px 64px 24px 24px;
    position: relative;
    display: flex;
    align-items: center;
  `
);

const StyledHeader = styled.h2(
  ({ theme }) => css`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledCloseButtonContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

export default DialogTitle;
