import React from "react";
import { BoxProps } from "@material-ui/core";
import Box from "components/Box/Box";
import Spacer from "components/Spacer/Spacer";
import { isChildTruthy } from "components/FlexBox/functions/IsChildTruthy";
import styled, { css } from "styled-components/macro";

type Props = Omit<BoxProps, "justifyContent" | "alignItems" | "flexWrap" | "flexDirection"> & {
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  flexShrink?: number;
  flexGrow?: number;
  flexBasis?: number | string;
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around";
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
  spacing?: number;

  /**
   * TODO: Remove usage of <Spacer /> in this component
   * @deprecated
   */
  useSpacer?: boolean;
};

const FlexBox: React.FC<Props> = ({
  direction: _direction = "row",
  flexDirection: _flexDirection,
  flexShrink = 0,
  flexGrow = 0,
  flexBasis = "auto",
  spacing = 0,
  justifyContent = "flex-start",
  alignItems = "stretch",
  flexWrap = "nowrap",
  useSpacer = false,
  children,
  ...boxProps
}) => {
  const direction = _flexDirection ?? _direction;

  const styledBoxProps = React.useMemo(() => {
    let props: Omit<Props, "spacing"> & { $spacing?: number } = {
      display: "flex",
      flexDirection: direction,
      flexShrink: flexShrink,
      flexGrow: flexGrow,
      flexBasis: flexBasis,
      flexWrap: flexWrap,
      justifyContent: justifyContent,
      alignItems: alignItems,
      ...boxProps,
    };

    if (!useSpacer) {
      props.$spacing = spacing;
    }

    return props;
  }, [direction, flexShrink, flexGrow, flexBasis, flexWrap, justifyContent, alignItems, boxProps, useSpacer, spacing]);

  const childrenToRender = React.useMemo(() => {
    // If not use Spacer, render the children directly
    if (!useSpacer) {
      return children;
    }

    // If use Spacer, rendered filtered children
    const childrenArr = React.Children.toArray(children).filter(isChildTruthy);
    return childrenArr.map((child, idx, arr) => {
      return (
        <React.Fragment key={idx}>
          {child}
          {useSpacer && spacing > 0 && idx < arr.length - 1 && (
            <Spacer
              x={["row", "row-reverse"].includes(direction) ? spacing : undefined}
              y={["column", "column-reverse"].includes(direction) ? spacing : undefined}
            />
          )}
        </React.Fragment>
      );
    });
  }, [children, useSpacer, spacing, direction]);

  return <StyledBox {...styledBoxProps}>{childrenToRender}</StyledBox>;
};

const StyledBox = styled(Box)<{ $spacing?: number }>(
  ({ theme, $spacing }) => css`
    ${$spacing && theme.mixins.flexGap(`${4 * $spacing}px`)}
  `
);

export default FlexBox;
export type FlexBoxProps = Props;
