import React from "react";
import styled, { css } from "styled-components/macro";
import { OutlinedInputProps, OutlinedInput as MUIOutlinedInput } from "@material-ui/core";

// This is the complete replacement of OutlinedInput from MUI with ODLv2 styles
const OutlinedInput: React.FC<OutlinedInputProps> = React.forwardRef((props, ref) => {
  return <StyledMUIOutlinedInput ref={ref} {...props} />;
});

const StyledMUIOutlinedInput = styled(MUIOutlinedInput)(
  ({ theme, error, disabled }) => css`
    // Common
    input, textarea {
      caret-color: ${theme.palette.objective.blue.main};
      color: ${theme.palette.objective.dark.night};
      font-weight: 600;
      font-size: 16px;

      &::placeholder {
        color: ${theme.palette.objective.dark.neutral};
        opacity: 1;
        font-weight: 400;
      }

      // No default review icon on IE
      &::-ms-reveal {
        display: none !important;
      }
    }

    fieldset {
      border: 1px solid ${theme.palette.objective.light.allspice};
      border-radius: 4px;
    }
    input:focus ~ fieldset {
      border: 1px solid ${theme.palette.objective.blue.main};
    }

    // Error
    ${
      error &&
      css`
        input::placeholder {
          color: ${theme.palette.objective.dark.fiordland};
          opacity: 1;
          font-weight: 400;
        }
        fieldset {
          border: 2px dashed ${theme.palette.objective.red.main};
        }
        input:focus ~ fieldset {
          border: 2px dashed ${theme.palette.objective.red.main};
        }
      `
    }
    
    // Disabled
    ${
      disabled &&
      css`
        input {
          color: ${theme.palette.objective.dark.fiordland};
          cursor: not-allowed;
          z-index: 1;
        }
        fieldset {
          background-color: ${theme.palette.objective.light.day};
        }
      `
    }
  `
);

export default OutlinedInput;
