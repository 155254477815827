import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import {
  ApplicationValidationResultUtil,
  IApplicationValidationResultEntity,
} from "models/ApplicationValidation.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { ApplicationValidationResultSeverity } from "models/ApplicationValidationSeverity.model";
import { ApplicationValidationResultRuleType } from "models/ApplicationValidationResultRuleType";

// Entity Adapter

const applicationValidationResultAdapter = createEntityAdapter<IApplicationValidationResultEntity>({
  selectId: (applicationValidation) => ApplicationValidationResultUtil.generateKey(applicationValidation),
  sortComparer: (a, b) => a.applicationId - b.applicationId,
});

// Action & Thunks

export const fetchApplicationValidationResults = createAsyncThunk(
  "domainData/applicationValidationResult/fetchApplicationValidationResults",
  async ({ applicationId, isReviewStep }: { applicationId: number; isReviewStep?: boolean }, thunkAPI) => {
    const validationResults = await serviceContainer.cradle.applicationValidationService.fetchApplicationValidationResults(
      {
        applicationId,
        isReviewStep,
      }
    );
    return validationResults;
  }
);

export const removeApplicationValidationResultsByApplicationId = createAction<number>(
  "domainData/applicationStepData/removeApplicationValidationResultsByApplicationId"
);

// Reducer

export const applicationValidationResultReducer = createReducer(
  applicationValidationResultAdapter.getInitialState(),
  (builder) =>
    builder
      .addCase(fetchApplicationValidationResults.fulfilled, (draft, action) => {
        // Clear all existing validation errors
        applicationValidationResultAdapter.removeAll(draft);
        applicationValidationResultAdapter.upsertMany(draft, action.payload);
      })
      .addCase(removeApplicationValidationResultsByApplicationId, (draft, action) => {
        const applicationId = action.payload;
        const keys = Object.keys(draft.entities);
        for (const key of keys) {
          const relation = draft.entities[key];
          if (relation?.applicationId === applicationId) {
            applicationValidationResultAdapter.removeOne(draft, key);
          }
        }
      })
);

// Selectors

export const {
  selectAll: selectAllApplicationValidationResultEntities,
} = applicationValidationResultAdapter.getSelectors((state: RootState) => state.domainData.applicationValidationResult);

export const selectApplicationValidationErrors = createDeepEqualSelector(
  [selectAllApplicationValidationResultEntities, (_: RootState, applicationId: number) => applicationId],
  (entities, applicationId) => {
    return entities.filter(
      (entity) =>
        entity.applicationId === applicationId && entity.severity === ApplicationValidationResultSeverity.Error
    );
  }
);
export const selectApplicationValidationErrorsByStep = createDeepEqualSelector(
  [
    selectAllApplicationValidationResultEntities,
    (_: RootState, args: { stepId: number; applicationId: number }) => args,
  ],
  (entities, args) => {
    return entities.filter(
      (entity) =>
        entity.applicationId === args.applicationId &&
        entity.stepId === args.stepId &&
        entity.severity === ApplicationValidationResultSeverity.Error
    );
  }
);

export const selectApplicationDocumentValidationErrors = createDeepEqualSelector(
  [selectAllApplicationValidationResultEntities, (_: RootState, args: { applicationId: number }) => args],
  (entities, args) => {
    return entities.filter(
      (entity) =>
        entity.applicationId === args.applicationId &&
        entity.ruleType === ApplicationValidationResultRuleType.DocumentContainer &&
        entity.severity === ApplicationValidationResultSeverity.Error
    );
  }
);

export const selectApplicationValidationErrorsGlobal = createDeepEqualSelector(
  [selectAllApplicationValidationResultEntities, (_: RootState, applicationId: number) => applicationId],
  (entities, applicationId) => {
    return entities.filter(
      (entity) =>
        entity.applicationId === applicationId &&
        entity.stepId === 0 &&
        entity.severity === ApplicationValidationResultSeverity.Error
    );
  }
);
