import get from "lodash/get";
import toInteger from "lodash/toInteger";
import qs from "qs";
import { IAuthorityFilterPayload, IAuthorityService } from "services/authority/AuthorityService.types";
import { Cradle } from "services/serviceContainer.types";

export class AuthorityService implements IAuthorityService {
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async detectAuthorityId(payload: IAuthorityFilterPayload): Promise<number> {
    const response = await this.apiClient.publicApi.get(`/public/authorities/detection?${qs.stringify(payload)}`);
    const json = response.data;
    const id = toInteger(get(json, "authorityEmbedDTOs.0.id"));
    return id;
  }
}
