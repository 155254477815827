import React from "react";
import { useSnackbar } from "notistack";
import { ToastVariant } from "components/ToastMessage/ToastMessage.types";

export const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueToast = React.useCallback(
    (message: string, variant: ToastVariant) => {
      enqueueSnackbar({ message, variant });
    },
    [enqueueSnackbar]
  );

  const toastError = React.useCallback(
    (e: Error | string) => {
      if (typeof e === "string") {
        enqueueToast(e, ToastVariant.Error);
        return;
      }

      if (e && e.message) {
        enqueueToast(e.message, ToastVariant.Error);
        return;
      }
    },
    [enqueueToast]
  );

  const toastSuccess = React.useCallback(
    (message: string) => {
      enqueueToast(message, ToastVariant.Success);
    },
    [enqueueToast]
  );

  const toastWarning = React.useCallback(
    (message: string) => {
      enqueueToast(message, ToastVariant.Warning);
    },
    [enqueueToast]
  );

  const toastInfo = React.useCallback(
    (message: string) => {
      enqueueToast(message, ToastVariant.Info);
    },
    [enqueueToast]
  );

  return { enqueueToast, toastError, toastSuccess, toastWarning, toastInfo };
};
