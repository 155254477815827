import * as xssSanitize from "xss";

export const sanitize = <T>(obj: T): T => {
  // Case: null or undefined value, boolean, number or function, it should return as it is
  if (!obj || ["boolean", "number", "function"].includes(typeof obj)) {
    return obj;
  }

  // Case: String is received
  if (typeof obj === "string") {
    return (xssSanitize.filterXSS(obj) as any) as T; // Sanitize the string using XSS module
  }

  if (Array.isArray(obj)) {
    return (obj.map((item) => sanitize(item)) as any) as T;
  }

  const sanitizedObj = {} as T;
  Object.keys(obj).forEach((key) => {
    sanitizedObj[key as keyof T] = sanitize(obj[key as keyof T]);
  });
  return sanitizedObj;
};
