import * as React from "react";
import BaseMenu from "components/odl-v2/Menu/Menu";
import { SyntheticEvent } from "react";

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const openMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  // Partial application - return a new functional Menu component with partially applied arguments / props
  const Menu = React.useCallback<React.FC<Partial<React.ComponentProps<typeof BaseMenu>>>>(
    ({ children, ...props }) => {
      const handleClose = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        closeMenu();
      };

      return (
        <BaseMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} {...props}>
          {children}
        </BaseMenu>
      );
    },
    [anchorEl, closeMenu]
  );

  return {
    Menu,
    openMenu,
    closeMenu,
  };
};
