import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { IUserAuthorityRelation, UserAuthorityRelationUtil } from "models/UserAuthorityRelation.model";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import { createDeepEqualSelector } from "store/utils";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import isEmpty from "lodash/isEmpty";

// Entity Adapter
const userAuthorityRelationAdapter = createEntityAdapter<IUserAuthorityRelation>({
  selectId: (entity) => UserAuthorityRelationUtil.generateEntityKey(entity),
  sortComparer: (a, b) => {
    if (a.authorityId !== b.authorityId) {
      return a.authorityId - b.authorityId;
    }
    return a.userId - b.userId;
  },
});

// Action & Thunks
export const loadUserAuthoritiesRelations = createAction<IUserAuthorityRelation[]>(
  "domainData/userAuthorityRelation/loadUserAuthoritiesRelations"
);

export const fetchUserAuthoritiesRelationForAuthenticatedUser = createAsyncThunk(
  "domainData/userAuthorityRelation/fetchUserAuthoritiesRelationForAuthenticatedUser",
  async (_: void, thunkAPI) => {
    const {
      userAuthorityRelations,
    } = await serviceContainer.cradle.userInfoService.fetchUserAuthoritiesRelationForAuthenticatedUser();
    thunkAPI.dispatch(loadUserAuthoritiesRelations(userAuthorityRelations));
  }
);

// Reducer

export const userAuthorityRelationReducer = createReducer(userAuthorityRelationAdapter.getInitialState(), (builder) =>
  builder.addCase(loadUserAuthoritiesRelations, (draft, action) => {
    userAuthorityRelationAdapter.upsertMany(draft, action.payload);
  })
);

// Selectors

export const {
  selectById: selectUserAuthorityRelationByKey,
  selectAll: selectAllUserAuthorityRelations,
} = userAuthorityRelationAdapter.getSelectors((state: RootState) => state.domainData.userAuthorityRelation);

export const selectAllUserAuthorityRelationsForCurrentUser = createDeepEqualSelector(
  [selectAllUserAuthorityRelations, selectUserInfoEntityForAuthenticatedUser],
  (allUserAuthorityRelations, userInfo) => {
    if (!userInfo) {
      return;
    }
    const currentUserAuthorityRelations = allUserAuthorityRelations.filter((rel) => rel.userId === userInfo.id);
    return currentUserAuthorityRelations;
  }
);

export const selectDefaultIndependentAuthorityIdForCurrentUser = createDeepEqualSelector(
  [selectAllUserAuthorityRelationsForCurrentUser],
  (allUserAuthorityRelationsForCurrentUser) => {
    if (!allUserAuthorityRelationsForCurrentUser || isEmpty(allUserAuthorityRelationsForCurrentUser)) {
      return 0;
    }
    const defaultIndependentRelation = allUserAuthorityRelationsForCurrentUser.find((rel) => rel.defaultIndependent);
    if (!defaultIndependentRelation) {
      return 0;
    }
    return defaultIndependentRelation.authorityId;
  }
);
