// language=ejs
const checkbox = `<!-- Checkbox -->
{% const isReadOnly = ctx.options.readOnly %}

<!-- Begin: Readonly Checkbox -->
{% if (isReadOnly) { %}
<div class="form-check checkbox">
    <label class="{{ctx.input.labelClass}} form-check-label">
        <span>{% if (!ctx.self.labelIsHidden()) { %}{{ctx.input.label}}:{% } %}</span>
        <{{ctx.input.type}}
        ref="input"
        {% for (var attr in ctx.input.attr) { %}
        {{attr}}="{{ctx.input.attr[attr]}}"
        {% } %}
        {% if (ctx.checked) { %}checked=true{% } %}
        />
        <span />
    </label>
</div>
{% } %}
<!-- End: Readonly Checkbox -->

<!-- Begin: Checkbox -->
{% if (!isReadOnly) { %}
<div class="form-check checkbox">
    <label class="{{ctx.input.labelClass}} form-check-label">
        <{{ctx.input.type}}
        ref="input"
        {% for (var attr in ctx.input.attr) { %}
        {{attr}}="{{ctx.input.attr[attr]}}"
        {% } %}
        {% if (ctx.checked) { %}checked=true{% } %}
        >
        <span>
          <span>
          {% if (!ctx.self.labelIsHidden()) { %}{{ctx.input.label}}{% } %}{% if (ctx.component.tooltip) { %}<i ref="tooltip" class="{{ctx.iconClass('question-sign')}} text-muted ml-1" data-tooltip="{{ctx.component.tooltip}}"></i>{% } %}
          </span>
        </span>
        {{ctx.input.content}}
    </{{ctx.input.type}}>
    </label>
</div>
{% } %}
<!-- Begin: Checkbox -->
`;
export default checkbox;
