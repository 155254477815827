import React from "react";
import styled, { css } from "styled-components/macro";
import Guard from "components/Guard/Guard";

type Props = {
  icon?: React.ReactNode;
  "data-testid"?: string;
  onClick?: React.MouseEventHandler;
  isCardClickable?: boolean;
  isCardExpanded?: boolean;
  header?: React.ReactNode;
  details?: React.ReactNode;
};

const Card = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      "data-testid": dataTestId,
      isCardClickable = true,
      isCardExpanded = false,
      onClick,
      icon,
      header,
      children,
      details,
      ...otherProps
    },
    ref
  ) => {
    return (
      <StyledCardContainer
        $isCardClickable={isCardClickable}
        $isCardExpanded={isCardExpanded}
        data-testid={dataTestId || "Card"}
        onClick={onClick}
        ref={ref}
        {...otherProps}
      >
        <Guard condition={header}>
          <StyledHeaderContainer>{header}</StyledHeaderContainer>
        </Guard>
        <StyledCardContent>
          <Guard condition={icon}>
            <StyledIconContainer>{icon}</StyledIconContainer>
          </Guard>
          {children}
        </StyledCardContent>
        {details}
      </StyledCardContainer>
    );
  }
);

const StyledCardContainer = styled.div<{ $isCardClickable: boolean; $isCardExpanded: boolean }>(
  ({ theme, $isCardClickable, $isCardExpanded }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${theme.mixins.flexGap("12px")}
    padding: 12px 24px;

    ${
      $isCardClickable &&
      css`
        cursor: pointer;
      `
    }

    &:hover {
      background: ${theme.palette.objective.light.day};
    }

    &:active {
      background: ${theme.palette.objective.light.day};

      ${
        $isCardClickable &&
        css`
          background: ${theme.palette.objective.blue.light};
        `
      }
    }
      ${
        $isCardExpanded &&
        css`
          background: ${theme.palette.objective.blue.light};
        `
      }}
  `
);

const StyledCardContent = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }
  `
);

const StyledIconContainer = styled.div(
  ({ theme }) => css`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  `
);

const StyledHeaderContainer = styled.div(({ theme }) => css``);

export default Card;
