import { combineReducers } from "@reduxjs/toolkit";
import { authenticationReducer } from "store/app-state/authentication/authentication";
import { flagReducer } from "store/app-state/flag/flag";
import { applicationStepDataBufferReducer } from "store/app-state/application-step-data-buffer/applicationStepDataBuffer";
import { loadingReducer } from "store/app-state/loading/loading";
import { applicationNavigationReducer } from "store/app-state/application-navigation/applicationNavigation";
import { uiReducer } from "store/app-state/ui/ui";
import { participantBufferReducer } from "store/app-state/participant-buffer/participantBuffer";
import { applicationDocumentUploadQueueReducer } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { applicationsBadgeCountReducer } from "store/app-state/application-badge-count/applicationBadgeCount";
import { applicationFormioStepBufferReducer } from "store/app-state/application-formio-step-buffer/applicationFormioStepBuffer";

export const appStateReducer = combineReducers({
  authentication: authenticationReducer,
  flag: flagReducer,
  applicationStepDataBuffer: applicationStepDataBufferReducer,
  loading: loadingReducer,
  applicationNavigation: applicationNavigationReducer,
  ui: uiReducer,
  participantBuffer: participantBufferReducer,
  applicationDocumentUploadQueue: applicationDocumentUploadQueueReducer,
  applicationsBadgeCount: applicationsBadgeCountReducer,
  applicationFormioStepBuffer: applicationFormioStepBufferReducer,
});
