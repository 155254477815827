import React from "react";
import { Chip } from "@material-ui/core";
import LogoImage from "assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import UserNavItem from "components/AppHeader/UserNavItem";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import styled, { css } from "styled-components/macro";
import { useHistory } from "react-router";
import FlexBox from "components/FlexBox/FlexBox";
import { BUILD_API_ENDPOINT, NOTIFICATION_CENTRE_ENABLED, USE_MOCK_BACKEND } from "constants/configs";
import newApplicationIcon from "assets/images/note-plus-outline-icon.svg";
import NotificationNavItem from "components/AppHeader/NotificationNavItem";
import TextButton from "components/odl-v2/Button/TextButton";
import Guard from "components/Guard/Guard";
import HelpNavItem from "components/AppHeader/HelpNavItem";
import { hideInPrintMixin } from "components/odl-v2/Button/mixins/hideInPrintMixin";

type Props = {};

const AppHeader: React.FC<Props> = () => {
  const { t } = useTranslation();
  const screenWidthMatch = useScreenWidthMatch();
  const history = useHistory();

  const handleClickNewApplication = React.useCallback(() => {
    history.push("/new-application");
  }, [history]);

  const handleClickDashboard = React.useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <StyledAppHeaderContainer>
      <FlexBox spacing={4} alignItems={"center"}>
        <StyledH1>Objective Build</StyledH1>
        <StyledLogoImage src={LogoImage} alt={"Objective Build"} onClick={handleClickDashboard} />
        {USE_MOCK_BACKEND && <Chip color="secondary" label="Mock" />}
        {BUILD_API_ENDPOINT.includes("localhost") && <Chip color="primary" label="Local" />}
      </FlexBox>

      <StyledToolBar>
        <Guard condition={screenWidthMatch.md}>
          <TextButton
            size={"large"}
            startIcon={<StyledNewApplicationIcon src={newApplicationIcon} alt={"New Application app bar icon"} />}
            onClick={handleClickNewApplication}
            data-testid="NewApplicationButton"
          >
            {t(`New application`)}
          </TextButton>

          <StyledVDivider />
        </Guard>

        <HelpNavItem />

        <Guard condition={NOTIFICATION_CENTRE_ENABLED}>
          <NotificationNavItem />
        </Guard>

        <StyledVDivider />

        <UserNavItem compact={!screenWidthMatch.md} />
      </StyledToolBar>
    </StyledAppHeaderContainer>
  );
};

const StyledAppHeaderContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 73px;
    border-top: 5px solid ${theme.palette.objective.brandColors.build.primary};
    border-bottom: 1px solid ${theme.palette.objective.light.day};
    background-color: ${theme.palette.objective.light.white};

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    z-index: ${theme.zIndex.appBar};

    ${theme.breakpoints.down("xs")} {
      padding: 0 8px;
    }
  `
);

const StyledLogoImage = styled.img(
  ({ theme }) => css`
    width: 160px;
    height: 24px;
    cursor: pointer;
  `
);

const StyledToolBar = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("12px")};

    ${theme.breakpoints.down("xs")} {
      ${theme.mixins.flexGap("4px")};
    }

    ${hideInPrintMixin()}
  `
);

const StyledVDivider = styled.div(
  ({ theme }) => css`
    width: 1px;
    height: 48px;
    background-color: ${theme.palette.objective.light.allspice};
  `
);

const StyledH1 = styled.h1(
  ({ theme }) => css`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  `
);

const StyledNewApplicationIcon = styled.img(
  ({ theme }) => css`
    width: 20px;
  `
);

export default AppHeader;
