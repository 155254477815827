import React from "react";
import { useStoreDispatch } from "store/hooks";
import { fetchPaginatedApplicationsForCurrentUser } from "store/domain-data/application/application";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { ApplicationRecordType, IApplicationEntity } from "models/Application.model";
import { SortDirection } from "models/SortDirection.model";

export const useRecentApplicationsForCurrentUser = () => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  const [recentApplications, setRecentApplications] = React.useState<IApplicationEntity[]>([]);

  React.useEffect(() => {
    dispatch(
      fetchPaginatedApplicationsForCurrentUser({
        pageIndex: 1,
        pageSize: 10,
        recordType: ApplicationRecordType.Draft,
        sortDirection: SortDirection.Descending,
        isHidden: false,
      })
    )
      .then(unwrapResult)
      .then(setRecentApplications)
      .catch(toastError);
  }, [dispatch, toastError]);

  return recentApplications;
};
