import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import toInteger from "lodash/toInteger";
import { IPaymentService } from "services/payment/PaymentService.types";
import {
  IPaymentEntity,
  IPaymentTransaction,
  PaymentMethod,
  PaymentMethodUtil,
  PaymentStatusUtil,
  TransactionResultUtil,
} from "models/Payment.model";
import toNumber from "lodash/toNumber";
import { IPaymentOptionEntity, PaymentOptionType, PaymentTypeUtil } from "models/PaymentOption.model";
import { DIRECT_TRANSFER_PROOF_OF_PAYMENT_ENABLED } from "constants/configs";

export class PaymentService implements IPaymentService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchPaymentsByApplicationId(applicationId: number) {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/payments/`);
    const jsonArr = get(response.data, "payments");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch Payments`));
    }
    const payments = jsonArr.map((paymentJson) => this.parsePayment(paymentJson));
    return payments;
  }

  public async fetchPayment(args: { applicationId: number; paymentId: number }) {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${args.applicationId}/payments/${args.paymentId}`
    );
    const json = response.data;
    if (!json) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch Payment`));
    }
    const { payment, paymentOptions } = this.parsePaymentDetails(json);

    return {
      payment,
      paymentOptions,
    };
  }

  public async claimPaymentBeingMade(args: {
    applicationId: number;
    paymentId: number;
    paymentMethod: PaymentMethod;
    transactionDate: string;
    proofDocumentName?: string;
  }) {
    const { applicationId, paymentId, paymentMethod, transactionDate, proofDocumentName } = args;
    let payload;
    if (DIRECT_TRANSFER_PROOF_OF_PAYMENT_ENABLED && proofDocumentName) {
      payload = { paymentMethod, transactionDate, proofDocumentName };
    } else {
      payload = { paymentMethod, transactionDate };
    }
    const response = await this.apiClient.protectedApi.patch(
      `/user/applications/${applicationId}/payments/${paymentId}`,
      payload
    );
    const json = response.data;
    if (!json) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch Payment`));
    }
    const { payment, paymentOptions } = this.parsePaymentDetails(json);

    return {
      payment,
      paymentOptions,
    };
  }

  public async claimOnlinePaymentBeingMade(args: { applicationId: number; paymentId: number; token: string }) {
    const response = await this.apiClient.protectedApi.post(
      `/user/applications/${args.applicationId}/payments/${args.paymentId}/sessions`,
      {
        paymentMethod: PaymentMethod.Gateway,
        token: args.token,
      }
    );
    const json = response.data;
    if (!json) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to claim online Payment`));
    }
    const result = {
      transactionResult: TransactionResultUtil.parse(get(json, "result")),
      message: toString(get(json, "message")),
    };
    return result;
  }

  private parsePayment(json: any): IPaymentEntity {
    const payment: IPaymentEntity = {
      id: toInteger(get(json, "id")),
      applicationId: toInteger(get(json, "applicationId")),
      description: toString(get(json, "description")),
      displayOrder: toInteger(get(json, "displayOrder")),
      documentContainerId: toInteger(get(json, "documentContainerId")),
      guid: toString(get(json, "guid")),
      paymentDate: toString(get(json, "paymentDate")),
      reference: toString(get(json, "reference")),
      status: PaymentStatusUtil.parse(toString(get(json, "status"))),
      baseAmount: toNumber(get(json, "baseAmount")),
      surchargeAmount: toNumber(get(json, "surchargeAmount")),
      currency: toString(get(json, "currency")),
      invoiceDocumentName: toString(get(json, "invoiceDocumentName")) || "",
      issuedDate: toString(get(json, "issuedDate")) || "",
      transactions: this.parsePaymentTransactions(get(json, "transactions")) || [],
      paymentMethod: PaymentMethodUtil.parseWithNull(get(json, "paymentMethod")),
    };
    return payment;
  }

  private parsePaymentDetails(json: any): { payment: IPaymentEntity; paymentOptions: IPaymentOptionEntity[] } {
    const paymentId = toInteger(get(json, "id"));
    const payment = this.parsePayment(json);
    const paymentOptions = this.parsePaymentOptions(get(json, "paymentOptions"), paymentId);
    return { payment, paymentOptions };
  }

  private parsePaymentOptions(paymentOptionsJson: any, paymentId: number): IPaymentOptionEntity[] {
    const paymentOptions: IPaymentOptionEntity[] = [];
    for (const json of paymentOptionsJson) {
      let paymentOption: IPaymentOptionEntity;
      const paymentType = PaymentTypeUtil.parse(toString(get(json, "type")));
      switch (paymentType) {
        case PaymentOptionType.Gateway: {
          paymentOption = {
            paymentId: paymentId,
            type: paymentType,
            displayName: toString(get(json, "displayName")),
            surchargeFlag: Boolean(get(json, "surchargeFlag")),
            spreedlyEnvironmentKey: toString(get(json, "spreedlyEnvironmentKey")),
          };
          break;
        }
        case PaymentOptionType.Deposit: {
          paymentOption = {
            paymentId: paymentId,
            type: paymentType,
            displayName: toString(get(json, "displayName")),
            bankName: toString(get(json, "bankName")),
            accountName: toString(get(json, "accountName")),
            routingNumber: toString(get(json, "routingNumber")),
            accountNumber: toString(get(json, "accountNumber")),
            paymentInstruction: toString(get(json, "paymentInstruction")),
          };
          break;
        }
        case PaymentOptionType.Council: {
          paymentOption = {
            paymentId: paymentId,
            type: paymentType,
            displayName: toString(get(json, "displayName")),
            councilPaymentPortal: toString(get(json, "linkToCouncilPaymentPortal")),
          };
          break;
        }
      }

      if (paymentOption) {
        paymentOptions.push(paymentOption);
      }
    }
    return paymentOptions;
  }

  private parsePaymentTransactions(transactionJson: any): IPaymentTransaction[] {
    if (!Array.isArray(transactionJson)) {
      return [];
    }
    const transactions: IPaymentTransaction[] = [];
    for (const json of transactionJson) {
      const transaction: IPaymentTransaction = {
        transactionId: toInteger(get(json, "transactionId")),
        transactionReference: toString(get(json, "transactionReference")),
        transactionDate: toString(get(json, "transactionDate")),
        paymentMethod: PaymentMethodUtil.parse(toString(get(json, "paymentMethod"))),
        surchargePaid: toNumber(get(json, "surchargePaid")),
        proofDocumentName: toString(get(json, "proofDocumentName")),
      };
      transactions.push(transaction);
    }
    return transactions;
  }
}
