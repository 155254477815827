import React from "react";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import Dialog from "components/odl-v2/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { WEB_SUPPORT_EMAIL, WEB_SUPPORT_LINK, WEB_SUPPORT_PHONE } from "constants/configs";
import FlexBox from "components/FlexBox/FlexBox";
import InfoLink from "components/InfoLink/InfoLink";
import { ODL_ICONS } from "components/FontIcon/FontIcon.constant";
import { getHostnameFromUrl } from "utils/getHostnameFromUrl";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const HelpCentreDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const webSupportLinkHostname = getHostnameFromUrl(WEB_SUPPORT_LINK);

  return (
    <Dialog open={isOpen} title={t(`Support information`)} onClose={onClose} data-testid={"HelpCentreDialog"}>
      <StyledDialogContent>
        <FlexBox flexDirection={"column"} spacing={2}>
          <StyledInfoHeading>{t("Need help? Contact our support team")}</StyledInfoHeading>
          <StyledInfoField>
            <StyledInfoValue>
              <InfoLink displayValue={webSupportLinkHostname} iconName={ODL_ICONS.EARTH} linkUrl={WEB_SUPPORT_LINK} />
            </StyledInfoValue>
          </StyledInfoField>
          <StyledInfoField>
            <StyledInfoValue>
              <InfoLink
                displayValue={WEB_SUPPORT_EMAIL}
                iconName={ODL_ICONS.EMAIL}
                linkUrl={`mailto:${WEB_SUPPORT_EMAIL}`}
              />
            </StyledInfoValue>
          </StyledInfoField>
          <StyledInfoField>
            <StyledInfoValue>
              <InfoLink
                displayValue={WEB_SUPPORT_PHONE}
                iconName={ODL_ICONS.PHONE}
                linkUrl={`tel:${WEB_SUPPORT_PHONE}`}
              />
            </StyledInfoValue>
          </StyledInfoField>
          <StyledInfoField>
            <StyledInfoLine>
              {t(`Objective Build applicant support is available Monday - Friday 8am - 6pm excl. Public Holidays`)}
            </StyledInfoLine>
          </StyledInfoField>
          <StyledInfoField>
            <StyledInfoLine>
              {t(`Please have your application or consent number to hand before contacting the support team`)}
            </StyledInfoLine>
          </StyledInfoField>
        </FlexBox>
      </StyledDialogContent>
      <DialogActions>
        <DialogActionCancel onClick={onClose}>{t(`Cancel`)}</DialogActionCancel>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    padding-top: 0px;
    padding-bottom: 0px;
    min-width: 650px;

    ${theme.breakpoints.down("sm")} {
      min-width: unset;
    }
  `
);

const StyledInfoHeading = styled.div(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.text.primary};
  `
);

const StyledInfoField = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledInfoLine = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.text.primary};
    padding-top: 4px;

    ${theme.breakpoints.down("xs")} {
      max-width: 100%;
      text-align: center;
    }
  `
);

const StyledInfoValue = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.text.primary};
  `
);

export default HelpCentreDialog;
