// language=ejs
const selectBoxes = `<!-- Selectboxes -->
<div class="form-radio radio">
    {% ctx.values.forEach(function(item) { %}
    <div class="form-check{{ctx.inline ? '-inline' : ''}} radio-option" ref="wrapper">
        <label class="form-check-label label-position-{{ ctx.component.optionsLabelPosition }}"
               for="{{ctx.id}}{{ctx.row}}-{{item.value}}">
            <{{ctx.input.type}}
            ref="input"
            {% for (var attr in ctx.input.attr) { %}
            {{attr}}="{{ctx.input.attr[attr]}}"
            {% } %}
            value="{{item.value}}"
            {% if (ctx.value && (ctx.value === item.value || (typeof ctx.value === 'object' &&
            ctx.value.hasOwnProperty(item.value) && ctx.value[item.value]))) { %}
            checked=true
            {% } %}
            {% if (item.disabled) { %}
            disabled=true
            {% } %}
            id="{{ctx.id}}{{ctx.row}}-{{item.value}}"
            >
            <span>{{ctx.t(item.label)}}</span>
        </label>
    </div>
    {% }) %}
</div>
`;
export default selectBoxes;
