import { useStoreDispatch, useStoreSelector } from "store/hooks";
import React from "react";
import { fetchFormSchemaByName, selectFormSchemaEntityByName } from "store/domain-data/form-schema/formSchema";
import cloneDeep from "lodash/cloneDeep";

export const useFormSchemaByName = (name: string, options: { reload: boolean } = { reload: false }) => {
  const dispatch = useStoreDispatch();
  const reload = options.reload;

  // Note: form.io component wants to modify the schema object on runtime so we need to make a clone
  const _formSchema = useStoreSelector((state) => selectFormSchemaEntityByName(state, name));
  const formSchema = React.useMemo(() => cloneDeep(_formSchema), [_formSchema]);

  React.useEffect(() => {
    if (!name) {
      return;
    }

    if (!reload && formSchema) {
      return;
    }

    dispatch(fetchFormSchemaByName(name));
  }, [dispatch, name, reload, formSchema]);

  return formSchema;
};
