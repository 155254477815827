import React from "react";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import FlexBox from "components/FlexBox/FlexBox";
import StatusIcon from "components/ApplicationWorkflow/ItemStatusIcon";
import { ApplicationWorkflowUtil, IWorkflowStage } from "models/ApplicationWorkflow.model";

type Props = {
  workflowStage: IWorkflowStage;
};

const ApplicationWorkflowBadge: React.FC<Props> = ({ workflowStage }) => {
  const { icon, colour, iconColour } = ApplicationWorkflowUtil.getWorkflowStageMetaData(workflowStage.stageStatus);

  return (
    <div
      key={workflowStage.stageName}
      data-testid={"ApplicationWorkflowBadge"}
      data-stage-name={workflowStage.stageName}
      data-stage-status={workflowStage.stageStatus}
    >
      <HoverTooltip
        title={
          <FlexBox direction="column" alignItems="center">
            <div>{workflowStage.stageDisplayName}</div>
            <div>{workflowStage.stageStatus}</div>
          </FlexBox>
        }
        placement="top"
      >
        <div>
          <StatusIcon icon={icon} colour={colour} iconColour={iconColour} size={9} />
        </div>
      </HoverTooltip>
    </div>
  );
};

export default ApplicationWorkflowBadge;
