import React from "react";
import AuthenticationPage from "pages/authentication/AuthenticationPage";

type Props = {};

const MaintenancePage: React.FC<Props> = () => {
  return <AuthenticationPage />;
};

export default MaintenancePage;
