import { css } from "styled-components/macro";
import { ButtonProps } from "components/odl-v2/Button/Button";

/**
 * CSS mixin for focus style
 *
 * Refer: https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
 * @param variant
 * @param size
 */
export const focusMixin = (variant: ButtonProps["variant"], size: ButtonProps["size"] = "regular") => {
  if (variant === "text") {
    return css`
      &:focus-visible {
        background-color: ${(props) => props.theme.palette.objective.blue.light};
        box-shadow: inset 0 0 0 1px ${(props) => props.theme.palette.objective.blue.main};
      }
      &:focus-visible:hover {
        background-color: ${(props) => props.theme.palette.objective.blue.light};
        box-shadow: inset 0 0 0 1px ${(props) => props.theme.palette.objective.blue.main};
      }
    `;
  }

  return css`
    position: relative;

    &:focus-visible {
      color: ${(props) => props.theme.palette.objective.light.white};
      background-color: ${(props) => props.theme.palette.objective.blue.main};

      &:after {
        content: "";
        position: absolute;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        border: solid 1px ${(props) => props.theme.palette.objective.light.white};

        ${size === "regular" &&
        css`
          border-radius: 4px;
        `}
        ${size === "large" &&
        css`
          border-radius: 8px;
        `}
      }
    }
  `;
};
