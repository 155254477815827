// Filter out "null" children when using Guard
import Guard from "components/Guard/Guard";

export const isChildTruthy = (child: any): boolean => {
  // Filter out "null" children
  if (!child) {
    return false;
  }

  // Handle Guard
  if (typeof child === "object" && child.type === Guard) {
    if (Boolean(child.props.condition)) {
      // Recursive check
      return isChildTruthy(child.props.children);
    }

    if (child.props.fallback) {
      // Recursive check
      return isChildTruthy(child.props.fallback);
    }

    return false;
  }

  return true;
};
