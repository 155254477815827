import React from "react";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import FontIcon from "components/FontIcon/FontIcon";
import { useTheme } from "@material-ui/core";
import { useStoreSelector } from "store/hooks";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { NOTIFICATION_SETTINGS_ENABLED } from "constants/configs";

type Props = { applicationId: number };

const ApplicationNotificationLevelIcon: React.FC<Props> = ({ applicationId }) => {
  const theme = useTheme();

  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  if (!NOTIFICATION_SETTINGS_ENABLED) {
    return null;
  }

  switch (application?.notificationLevel) {
    case NotificationLevel.Watching:
      return (
        <FontIcon
          name={"alarm"}
          color={theme.palette.objective.brandColors.teams.primary}
          fontSize={16}
          data-testid={"NotificationIconWatching"}
        />
      );
    case NotificationLevel.Normal:
      return (
        <FontIcon name={"alarm-completed"} color={"#27AE60"} fontSize={16} data-testid={"NotificationIconNormal"} />
      );
    case NotificationLevel.Muted:
      return (
        <FontIcon
          name={"alarm-suspended"}
          color={theme.palette.objective.dark.neutral}
          fontSize={18}
          data-testid={"NotificationIconMuted"}
        />
      );
    default:
      return null;
  }
};

export default ApplicationNotificationLevelIcon;
