import { IParticipantLookupResponse, IParticipantSearchData } from "models/Participant.model";
import { IQualificationEntity } from "models/Qualification.model";

export enum PersonSearchLibraries {
  ContactLibrary = "ContactLibrary",
  LBP = "LBP",
}

export interface IPersonSearchService {
  search(args: { library: PersonSearchLibraries; keywords: string }): Promise<IParticipantSearchData[]>;

  find(args: { library: PersonSearchLibraries; identifier: string }): Promise<IParticipantLookupResponse>;

  updateQualification(args: { library: PersonSearchLibraries; identifier: string }): Promise<IQualificationEntity[]>;
}
