import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  fetchRegisteredApplicantPendingInvitations,
  selectAllPendingRegisteredApplicationInvitations,
} from "store/domain-data/registered-applicant-invitation/registeredApplicantInvitationReducer";
import RespondToRegisterApplicantInvitationAlert from "pages/dashboard/RespondToRegisterApplicantInvitationAlert";

export const useRespondToRegisterApplicantInvitationAlerts = () => {
  const dispatch = useStoreDispatch();
  React.useEffect(() => {
    dispatch(fetchRegisteredApplicantPendingInvitations());
  }, [dispatch]);

  const registeredApplicantInvitations = useStoreSelector(selectAllPendingRegisteredApplicationInvitations);

  return (
    <React.Fragment>
      {registeredApplicantInvitations.map((invitation) => (
        <RespondToRegisterApplicantInvitationAlert
          authorityAbbr={invitation.bcaCode}
          invitatoinId={invitation.id}
          key={invitation.id}
        />
      ))}
    </React.Fragment>
  );
};
