import { IMaintenanceAlertService } from "services/maintenance/MaintenanceAlertService.types";
import { Cradle } from "services/serviceContainer.types";
import axios from "axios";
import get from "lodash/get";

export class MaintenanceAlertService implements IMaintenanceAlertService {
  private readonly i18n: Cradle["i18n"];

  constructor(args: { i18n: Cradle["i18n"] }) {
    this.i18n = args.i18n;
  }

  public async fetchIsInMaintenance() {
    const response = await axios.get("/maintenance/maintenance.json");
    const isInMaintenance = Boolean(get(response.data, "isInMaintenance"));
    return isInMaintenance;
  }
}
