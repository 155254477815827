import { css } from "styled-components/macro";

/**
 * CSS mixin for common styles
 */
export const commonMixin = (size: "regular" | "large") => {
  return css`
    text-transform: none;
    font-weight: 500;
    min-width: 0;
    border: none;

    ${size === "regular" &&
    css`
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
    `}

    ${size === "large" &&
    css`
      padding: 10px 16px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 24px;
    `}
  `;
};
