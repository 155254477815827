export enum ApplicationDetailsSections {
  Overview = "Overview",
  Applications = "Applications",
  Forms = "Forms",
  RFIs = "RFIs",
  Inspections = "Inspections",
  Documents = "Documents",
  People = "People",
  Payments = "Payments",
  Notifications = "Notifications",
}

export const ApplicationDetailsSectionsUtil = {
  parse: (str: string): ApplicationDetailsSections => {
    for (const value of Object.values(ApplicationDetailsSections)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        return value;
      }
    }
    return ApplicationDetailsSections.Overview;
  },
};
