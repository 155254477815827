export type IApplicationStepDataEntity = {
  applicationId: number;
  breadcrumbStepId: number;
  data: any;
  exists: boolean;
  isValid: boolean;
  validationErrors: Record<string, string>;
};

export type IApplicationStepRelation = { applicationId: number; breadcrumbStepId: number };

export const ApplicationStepDataUtil = {
  generateEntityKey: (args: Pick<IApplicationStepDataEntity, "applicationId" | "breadcrumbStepId">) =>
    `application:${args.applicationId}|step:${args.breadcrumbStepId}`,
};
