import React from "react";
import SectionHeader from "components/Layout/SectionHeader";
import Section from "components/Layout/Section";
import { useTranslation } from "react-i18next";
import Alert from "components/odl-v2/Alert/Alert";
import Panel from "components/odl-v2/Layout/Panel";
import Cards from "components/odl-v2/Card/Cards";
import OutstandingApplicationCard from "pages/dashboard/OutstandingApplicationCard";
import { useFetchOutstandingApplicationsForCurrentUser } from "pages/dashboard/functions/useFetchOutstandingApplicationsForCurrentUser";
import isEmpty from "lodash/isEmpty";
import { usePaginatedData } from "hooks/usePaginatedData";
import { useStoreSelector } from "store/hooks";
import { selectIsLoadingThunk } from "store/app-state/loading/loading";
import { fetchPaginatedOutstandingApplicationsV3ForCurrentUser } from "store/domain-data/application-v3/applicationV3";
import LoadMoreButton from "components/LoadMoreButton/LoadMoreButton";
import NoApplicationCard from "components/ApplicationCard/NoApplicationCard";

type Props = {};
const OutstandingApplicationsSection: React.FC<Props> = () => {
  const { t } = useTranslation();

  const isLoadingApplications = useStoreSelector((state) =>
    selectIsLoadingThunk(state, fetchPaginatedOutstandingApplicationsV3ForCurrentUser)
  );

  const fetchApplicationsForPage = useFetchOutstandingApplicationsForCurrentUser();
  const { data: outstandingApplications, hasNextPage, loadNextPage } = usePaginatedData(fetchApplicationsForPage);

  const hasOutstandingApplications = !isEmpty(outstandingApplications);

  // TODO: BLD-5319 Display empty state for Dashboard
  if (!isLoadingApplications && !hasOutstandingApplications) {
    return null;
  }

  return (
    <Section data-testid={"OutStandingApplicationsSection"} header={<SectionHeader title={t(`Actions to be taken`)} />}>
      <Alert header={t(`Some applications are suspended`)} severity={"info"}>
        {t(`One or more applications or consents are suspended because of outstanding RFIs or payments.`)}
      </Alert>

      <Panel thickness={"normal"}>
        {isLoadingApplications && !hasOutstandingApplications && (
          <NoApplicationCard message={t("Loading outstanding applications")} />
        )}

        {hasOutstandingApplications && (
          <Cards>
            {outstandingApplications.map((outstandingApplication) => (
              <OutstandingApplicationCard applicationId={outstandingApplication.id} key={outstandingApplication.id} />
            ))}
          </Cards>
        )}
      </Panel>

      {hasNextPage && (
        <div>
          <LoadMoreButton isLoading={isLoadingApplications} onClick={loadNextPage} />
        </div>
      )}
    </Section>
  );
};

export default OutstandingApplicationsSection;
