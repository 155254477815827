import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createReducer,
  EntityState,
  Reducer,
} from "@reduxjs/toolkit";
import { ApplicationStepDataUtil, IApplicationStepDataEntity } from "models/ApplicationStepData.model";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { serviceContainer } from "services/serviceContainer";
import { selectApplicationStepDataBuffer } from "store/app-state/application-step-data-buffer/applicationStepDataBuffer";
import { selectBreadcrumbStepEntityById } from "store/domain-data/breadcrumb-step/breadcrumbStep";
import {
  selectCurrentApplicationId,
  selectCurrentBreadcrumbStepId,
} from "store/app-state/application-navigation/applicationNavigation";
import { BreadcrumbStepType } from "models/BreadcrumbStepType";

const applicationStepDataEntityAdapter = createEntityAdapter<IApplicationStepDataEntity>({
  selectId: (entity) => ApplicationStepDataUtil.generateEntityKey(entity),
  sortComparer: (a, b) => {
    const keyA = ApplicationStepDataUtil.generateEntityKey(a);
    const keyB = ApplicationStepDataUtil.generateEntityKey(b);
    return keyA.localeCompare(keyB);
  },
});

// Action & Thunks

export const fetchApplicationStepData = createAsyncThunk(
  "domainData/applicationStepData/fetchApplicationStepData",
  async (args: Pick<IApplicationStepDataEntity, "applicationId" | "breadcrumbStepId">, thunkAPI) => {
    // Fetch saved data from API
    const savedData = await serviceContainer.cradle.applicationService.fetchStepData(args);
    return savedData;
  }
);

// Mainly use to detect loading state
export const prepareApplicationStepData = createAsyncThunk(
  "domainData/applicationStepData/prepareApplicationStepData",
  async (args: { prepareCommand: () => Promise<void> }) => {
    const { prepareCommand } = args;
    return await prepareCommand();
  }
);

export const saveApplicationStepData = createAsyncThunk(
  "domainData/applicationStepData/saveApplicationStepData",
  async (args: Pick<IApplicationStepDataEntity, "applicationId" | "breadcrumbStepId">, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const breadcrumbStep = selectBreadcrumbStepEntityById(state, args.breadcrumbStepId);
    if (!breadcrumbStep) {
      throw new Error("Step not found");
    }

    // Non-formio step does not have step data
    if (breadcrumbStep.type !== BreadcrumbStepType.Formio) {
      return {
        applicationId: args.applicationId,
        breadcrumbStepId: args.breadcrumbStepId,
        data: {},
        isValid: true,
      } as IApplicationStepDataEntity;
    }

    // Get entity from fetched data
    const fetchedStepData = selectApplicationStepDataEntity(state, args);

    // Get entity from editing buffer
    const buffer = selectApplicationStepDataBuffer(state, args);

    // If fetched step data or buffered step data is missing, we should not run saving process
    if (!buffer || !fetchedStepData) {
      const message = serviceContainer.cradle.i18n.t(`Cannot save application step data`);
      serviceContainer.cradle.logger.error(message, args);
      throw new Error(message);
    }

    await serviceContainer.cradle.applicationService.saveStepData({
      applicationId: args.applicationId,
      breadcrumbStepId: args.breadcrumbStepId,
      data: buffer.data,
    });

    return buffer;
  }
);

export const saveApplicationStepDataForCurrentStep = createAsyncThunk(
  "domainData/applicationStepData/saveApplicationStepDataForCurrentStep",
  async (_: void, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const applicationId = selectCurrentApplicationId(state);
    const breadcrumbStepId = selectCurrentBreadcrumbStepId(state);

    if (!applicationId || !breadcrumbStepId) {
      return;
    }

    return await thunkAPI.dispatch(
      saveApplicationStepData({
        applicationId,
        breadcrumbStepId,
      })
    );
  }
);

export const removeApplicationStepDataByApplicationId = createAction<number>(
  "domainData/applicationStepData/removeApplicationStepDataByApplicationId"
);

// Reducer

export const defaultApplicationStepDataState = applicationStepDataEntityAdapter.getInitialState();

export const applicationStepDataReducer: Reducer<EntityState<IApplicationStepDataEntity>> = createReducer(
  defaultApplicationStepDataState,
  (builder) =>
    builder
      .addCase(fetchApplicationStepData.fulfilled, applicationStepDataEntityAdapter.upsertOne)
      .addCase(saveApplicationStepData.fulfilled, (draft, action) => {
        applicationStepDataEntityAdapter.updateOne(draft, {
          id: ApplicationStepDataUtil.generateEntityKey(action.payload),
          changes: {
            data: action.payload.data,
          },
        });
      })
      .addCase(removeApplicationStepDataByApplicationId, (draft, action) => {
        const applicationId = action.payload;
        const keys = Object.keys(draft.entities);
        for (const key of keys) {
          const relation = draft.entities[key];
          if (relation?.applicationId === applicationId) {
            applicationStepDataEntityAdapter.removeOne(draft, key);
          }
        }
      })
);

// Selectors

export const {
  selectEntities: selectApplicationStepDataEntities,
  selectAll: selectAllApplicationStepDataEntities,
  selectTotal: selectTotalApplicationStepDataEntities,
} = applicationStepDataEntityAdapter.getSelectors((state: RootState) => state.domainData.applicationStepData);

export const selectApplicationStepDataEntity = createDeepEqualSelector(
  [
    selectAllApplicationStepDataEntities,
    (state: RootState, props: Pick<IApplicationStepDataEntity, "applicationId" | "breadcrumbStepId">) =>
      ApplicationStepDataUtil.generateEntityKey(props),
  ],
  (entities, key) => {
    return entities.find((entity) => ApplicationStepDataUtil.generateEntityKey(entity) === key);
  }
);

export const selectApplicationStepDataEntitiesForApplicationId = createDeepEqualSelector(
  [selectAllApplicationStepDataEntities, (state: RootState, applicationId: number) => applicationId],
  (entities, applicationId) => {
    return entities.filter((entity) => entity.applicationId === applicationId);
  }
);
