import { IApplicationDocumentService } from "services/application-document/ApplicationDocumentService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { DocumentStatus, UploadStatusUtil } from "models/DocumentStatus.model";
import toInteger from "lodash/toInteger";
import { IDocumentEntity } from "models/Document.model";
import { IApplicationDocumentRelation } from "models/ApplicationDocumentRelation.model";
import { IDocumentTagEntity } from "models/DocumentTag.model";
import { IDocumentTagRelation } from "models/DocumentTagRelation.model";
import { DocumentTagService } from "services/document-tag/DocumentTagService";
import { IDocumentTypeRelation } from "models/DocumentTypeRelation.model";
import { IDocumentTypeEntity } from "models/DocumentType.model";
import { DocumentTypeService } from "services/document-type/DocumentTypeService";
import { DocumentContainerTypeUtil, IDocumentContainerEntity } from "models/DocumentContainer.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { DocumentErrorCodeUtil } from "models/DocumentErrorCode.model";
import { IApplicationDocumentDownloadInfo } from "models/ApplicationDocumentDownloadInfo.model";
import { RequiredDocumentStatus } from "models/RequiredDocument.model";

export class ApplicationDocumentService implements IApplicationDocumentService {
  private apiClient: Cradle["apiClient"];

  constructor(args: { apiClient: Cradle["apiClient"] }) {
    this.apiClient = args.apiClient;
  }

  public async createApplicationDocument(args: {
    applicationId: number;
    documentContainerId?: number;
    file: File;
    fileName?: string;
    documentCategoryId: number;
  }): Promise<{
    document: IDocumentEntity;
    applicationDocumentRelation: IApplicationDocumentRelation;
  }> {
    const { applicationId, documentContainerId, file, fileName: _fileName, documentCategoryId } = args;

    const fileName = _fileName || file.name;
    const fileType = file.type;
    const fileSize = file.size;

    const { name, presignedUrl, fileVersion, fileModifiedDate, order } = await this.prepareFileForUpload({
      applicationId,
      fileName,
      fileType,
      fileSize,
      documentContainerId,
      documentCategoryId,
    });

    const document: IDocumentEntity = {
      name,
      fileName,
      fileType,
      fileSize,
      fileVersion,
      fileModifiedDate,
      referenceUrl: "",
      presignedUrl,
      uploadStatus: presignedUrl ? DocumentStatus.Pending : DocumentStatus.Error,
      createdDate: new Date().toISOString(),
      errorCode: null,
    };
    const applicationDocumentRelation: IApplicationDocumentRelation = {
      documentName: name,
      applicationId,
      documentContainerId: documentContainerId || 0,
      order,
      documentCategoryId,
    };

    return {
      document,
      applicationDocumentRelation,
    };
  }

  public async fetchDocumentsByApplication(
    applicationId: number
  ): Promise<{
    documentEntities: IDocumentEntity[];
    applicationDocumentRelations: IApplicationDocumentRelation[];
    documentTagEntities: IDocumentTagEntity[];
    documentTagRelations: IDocumentTagRelation[];
    documentTypeEntities: IDocumentTypeEntity[];
    documentTypeRelations: IDocumentTypeRelation[];
  }> {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/documents`);
    const jsonArr = get(response.data, "applicationDocuments");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Fail to fetch application documents");
    }

    const documentEntities: IDocumentEntity[] = [];
    const applicationDocumentRelations: IApplicationDocumentRelation[] = [];
    const documentTagEntities: IDocumentTagEntity[] = [];
    const documentTagRelations: IDocumentTagRelation[] = [];
    const documentTypeEntities: IDocumentTypeEntity[] = [];
    const documentTypeRelations: IDocumentTypeRelation[] = [];

    jsonArr.forEach((json) => {
      const {
        documentEntity,
        applicationDocumentRelation,
        documentTagEntities: _documentTagEntities,
        documentTagRelations: _documentTagRelations,
        documentTypeEntities: _documentTypeEntities,
        documentTypeRelations: _documentTypeRelations,
      } = ApplicationDocumentService.parseApplicationDocumentJSON(applicationId, json);

      // TODO: remove duplication
      applicationDocumentRelations.push(applicationDocumentRelation);

      if (!documentEntities.find((document) => document.name === documentEntity.name)) {
        documentEntities.push(documentEntity);
        documentTagEntities.push(..._documentTagEntities);
        documentTagRelations.push(..._documentTagRelations);
        documentTypeEntities.push(..._documentTypeEntities);
        documentTypeRelations.push(..._documentTypeRelations);
      }
    });

    return {
      documentEntities,
      applicationDocumentRelations,
      documentTagEntities,
      documentTagRelations,
      documentTypeEntities,
      documentTypeRelations,
    };
  }

  public async fetchDocumentByApplication(args: {
    applicationId: number;
    name: string;
  }): Promise<{
    documentEntity: IDocumentEntity;
    applicationDocumentRelation: IApplicationDocumentRelation;
    documentTagEntities: IDocumentTagEntity[];
    documentTagRelations: IDocumentTagRelation[];
    documentTypeEntities: IDocumentTypeEntity[];
    documentTypeRelations: IDocumentTypeRelation[];
  }> {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${args.applicationId}/documents/${args.name}`
    );
    return ApplicationDocumentService.parseApplicationDocumentJSON(args.applicationId, response.data);
  }

  public async removeApplicationDocument({
    applicationId,
    name,
  }: {
    applicationId: number;
    name: string;
  }): Promise<void> {
    return await this.apiClient.protectedApi.delete(`/user/applications/${applicationId}/documents/${name}`);
  }

  public async removeApplicationDocumentsFromContainer({
    applicationId,
    documentContainerId,
  }: {
    applicationId: number;
    documentContainerId: number;
  }): Promise<void> {
    return await this.apiClient.protectedApi.delete(
      `user/applications/${applicationId}/document-containers/${documentContainerId}/documents`
    );
  }

  public async fetchApplicationDocumentDownloadInfo({
    applicationId,
    name,
  }: {
    applicationId: number;
    name: string;
  }): Promise<IApplicationDocumentDownloadInfo> {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${applicationId}/documents/${name}/download-link`
    );
    const applicationDocumentDownloadInfo = ApplicationDocumentService.parseApplicationDocumentDownloadInfoJSON(
      response.data
    );
    return applicationDocumentDownloadInfo;
  }

  public async fetchDocumentContainersByApplication(applicationId: number): Promise<IDocumentContainerEntity[]> {
    const response = await this.apiClient.protectedApi.get(`user/applications/${applicationId}/document-containers`);
    const jsonArr = get(response.data, "applicationDocumentContainers");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError();
    }
    const documentContainers = jsonArr.map((json) => this.parseDocumentContainerJSON(json));
    return documentContainers;
  }

  public async updateApplicationDocumentStatus(args: {
    applicationId: number;
    documentName: string;
    status: DocumentStatus.Uploading | DocumentStatus.Error;
  }): Promise<IDocumentEntity> {
    const urlPath = `/user/applications/${args.applicationId}/documents/${args.documentName}`;
    const requestPayload = {
      status: args.status.toUpperCase(),
    };
    const response = await this.apiClient.protectedApi.patch(urlPath, requestPayload);
    const { documentEntity } = ApplicationDocumentService.parseApplicationDocumentJSON(
      args.applicationId,
      response.data
    );
    return documentEntity;
  }

  public async withdrawApplicationDocument(args: {
    applicationId: number;
    documentName: string;
  }): Promise<IDocumentEntity> {
    const urlPath = `/user/applications/${args.applicationId}/documents/${args.documentName}`;
    const requestPayload = {
      status: RequiredDocumentStatus.Withdrawn.toUpperCase(),
    };
    const response = await this.apiClient.protectedApi.patch(urlPath, requestPayload);
    const { documentEntity } = ApplicationDocumentService.parseApplicationDocumentJSON(
      args.applicationId,
      response.data
    );
    return documentEntity;
  }

  public async updateApplicationDocumentsOrder(args: {
    applicationId: number;
    documentContainerId: number;
    documentNames: string[];
  }): Promise<void> {
    const urlPath = `/user/applications/${args.applicationId}/document-containers/${args.documentContainerId}/documents/order`;
    const requestPayload = {
      documents: args.documentNames,
    };
    try {
      await this.apiClient.protectedApi.patch(urlPath, requestPayload);
    } catch (e) {
      throw new ServiceError(e);
    }
    return;
  }

  private async prepareFileForUpload({
    applicationId,
    fileName,
    fileType,
    fileSize,
    documentContainerId,
    documentCategoryId,
  }: {
    applicationId: number;
    fileName: string;
    fileType: string;
    fileSize: number;
    documentContainerId?: number;
    documentCategoryId: number;
  }) {
    if (!applicationId) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Not supported yet...");
    }

    const response = await this.apiClient.protectedApi.post(`/user/applications/${applicationId}/documents`, {
      name: fileName,
      mimeType: fileType,
      size: fileSize,
      containerId: documentContainerId,
      categoryId: documentCategoryId,
    });
    const json = response.data;
    const presignedUrl = toString(get(json, "presignedUrl"));
    const name = toString(get(json, "name"));
    const fileVersion = toInteger(get(json, "version"));
    const fileModifiedDate = toString(get(json, "modifiedDate"));
    const order = toInteger(get(json, "order"));
    return { name, presignedUrl, fileVersion, fileModifiedDate, order };
  }

  private async readFile(file: File) {
    const reader = new FileReader();
    const promise = new Promise<string>((resolve) => {
      reader.onloadend = () => {
        const result = reader.result as string;
        resolve(result);
      };
      reader.readAsDataURL(file);
    });
    return promise;
  }

  // Refer: https://stackoverflow.com/questions/12168909/blob-from-dataurl
  private dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  private parseDocumentContainerJSON(json: any) {
    const container: IDocumentContainerEntity = {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      description: toString(get(json, "description")),
      mergeFileName: toString(get(json, "mergedFileName")),
      displayOrder: toInteger(get(json, "displayOrder")),
      containerType: DocumentContainerTypeUtil.parse(get(json, "containerType")),
    };
    return container;
  }

  static parseApplicationDocumentDownloadInfoJSON(json: any) {
    const downloadInfo: IApplicationDocumentDownloadInfo = {
      name: toString(get(json, "name")),
      applicationId: toInteger(get(json, "applicationId")),
      presignedUrl: toString(get(json, "downloadLink")),
      fileType: toString(get(json, "mimeType")),
      fileName: toString(get(json, "originalName")),
      fileSize: toInteger(get(json, "size")),
    };
    return downloadInfo;
  }

  static parseApplicationDocumentJSON(applicationId: number, json: any) {
    // Document entities and relations
    const documentEntity: IDocumentEntity = {
      name: toString(get(json, "name")),
      fileName: toString(get(json, "originalName")),
      fileType: toString(get(json, "mimeType")),
      fileSize: toInteger(get(json, "size")),
      fileVersion: toInteger(get(json, "version")),
      fileModifiedDate: toString(get(json, "modifiedDate")),
      presignedUrl: toString(get(json, "presignedUrl")),
      referenceUrl: toString(get(json, "reference")),
      createdDate: toString(get(json, "createdDate")),
      uploadStatus: UploadStatusUtil.parse(toString(get(json, "status"))),
      errorCode: DocumentErrorCodeUtil.parse(toString(get(json, "errorCode"))),
      isMergedFile: Boolean(get(json, "isMergedFile")),
    };

    const applicationDocumentRelation: IApplicationDocumentRelation = {
      applicationId,
      documentName: documentEntity.name,
      documentContainerId: toInteger(get(json, "documentContainerId")),
      order: toInteger(get(json, "order")),
      documentCategoryId: toInteger(get(json, "documentCategoryId")),
    };

    let documentTagEntities: IDocumentTagEntity[] = [];
    let documentTagRelations: IDocumentTagRelation[] = [];

    // Document Tag entities and relations
    const documentTagsArr = get(json, "documentTags");
    if (Array.isArray(documentTagsArr)) {
      documentTagEntities = documentTagsArr.map(DocumentTagService.parseDocumentTag);
      documentTagRelations = documentTagEntities.map((documentTag) => ({
        documentTagId: documentTag.id,
        documentName: documentEntity.name,
      }));
    }
    // Document Type entities and relations
    let documentTypeEntities: IDocumentTypeEntity[] = [];
    let documentTypeRelations: IDocumentTypeRelation[] = [];
    const documentTypesArr = get(json, "documentTypes");
    if (Array.isArray(documentTypesArr)) {
      documentTypeEntities = documentTypesArr.map(DocumentTypeService.parseDocumentType);
      documentTypeRelations = documentTypeEntities.map((documentType) => ({
        documentTypeId: documentType.id,
        documentName: documentEntity.name,
      }));
    }

    return {
      documentEntity,
      applicationDocumentRelation,
      documentTagEntities,
      documentTagRelations,
      documentTypeEntities,
      documentTypeRelations,
    };
  }
}
