import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import toInteger from "lodash/toInteger";
import { IPropertyEntity } from "models/Property.model";
import { IPropertyService } from "services/property/PropertyService.types";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class PropertyService implements IPropertyService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchPropertiesForCurrentUser() {
    const response = await this.apiClient.protectedApi.get(`/user/properties`);

    const jsonArr = get(response.data, "properties");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch properties`));
    }

    const contacts: IPropertyEntity[] = jsonArr.map((json) => this.parseProperty(json));
    return contacts;
  }

  private parseProperty(json: any): IPropertyEntity {
    return {
      id: toInteger(get(json, "id")),
      address: toString(get(json, "address")),
      postcode: toString(get(json, "postcode")),
      description: toString(get(json, "legal_description")),
      longitude: toString(get(json, "longitude")),
      latitude: toString(get(json, "latitude")),
      parcelId: toString(get(json, "parcel_id")),
    };
  }
}
