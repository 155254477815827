import { createAction, createAsyncThunk, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { IUserInfoEntity } from "models/UserInfo.model";
import { createDeepEqualSelector } from "store/utils";
import { serviceContainer } from "services/serviceContainer";
import { loadOrganisation, selectAllOrganisationEntities } from "store/domain-data/organisation/organisation";
import { OrganisationRole } from "models/OrganisationMember.model";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import { IUserInfoServiceUpdateProfilePayload } from "services/user-info/UserInfoService.types";
import * as Sentry from "@sentry/react";
import toString from "lodash/toString";

// Entity Adapter

const userInfoAdapter = createEntityAdapter<IUserInfoEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    return a.id - b.id;
  },
});

// Action & Thunks

export const loadUserInfo = createAction<IUserInfoEntity>("domainData/userInfo/loadUserInfo");

export const fetchUserInfoForAuthenticatedUser = createAsyncThunk(
  "domainData/userInfo/fetchUserInfoForAuthenticatedUser",
  async (_: void, thunkAPI) => {
    const {
      userInfo,
      organisation,
    } = await serviceContainer.cradle.userInfoService.fetchUserInfoForAuthenticatedUser();
    thunkAPI.dispatch(loadOrganisation(organisation));
    thunkAPI.dispatch(loadUserInfo(userInfo));
    Sentry.setUser({ id: toString(userInfo.id) });
  }
);

export const updateUserProfileForAuthenticatedUser = createAsyncThunk(
  "domainData/userInfo/updateUserProfile",
  async (payload: IUserInfoServiceUpdateProfilePayload, thunkAPI) => {
    const { userInfo } = await serviceContainer.cradle.userInfoService.updateUserProfileForAuthenticatedUser(payload);
    thunkAPI.dispatch(loadUserInfo(userInfo));
  }
);

export const updateDefaultApplicationNotificationSubscriptionLevel = createAsyncThunk(
  "domainData/userInfo/updateDefaultApplicationNotificationSubscriptionLevel",
  async (level: NotificationLevel, thunkAPI) => {
    const {
      userInfo,
    } = await serviceContainer.cradle.userInfoService.updateDefaultApplicationNotificationSubscriptionLevelForAuthenticatedUser(
      {
        level,
      }
    );
    thunkAPI.dispatch(loadUserInfo(userInfo));
  }
);

export const updateNotificationMethod = createAsyncThunk(
  "domainData/userInfo/updateNotificationMethod",
  async (
    args: Parameters<typeof serviceContainer.cradle.userInfoService.updateNotificationMethodForAuthenticatedUser>[0],
    thunkAPI
  ) => {
    const { userInfo } = await serviceContainer.cradle.userInfoService.updateNotificationMethodForAuthenticatedUser(
      args
    );
    thunkAPI.dispatch(loadUserInfo(userInfo));
  }
);

// Reducer

export const defaultUserInfoState = userInfoAdapter.getInitialState();

export const userInfoReducer = createReducer<EntityState<IUserInfoEntity>>(defaultUserInfoState, (builder) =>
  builder.addCase(loadUserInfo, userInfoAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectUserInfoEntityById,
  selectIds: selectUserInfoEntityIds,
  selectEntities: selectUserInfoEntities,
  selectAll: selectAllUserInfoEntities,
  selectTotal: selectTotalUserInfoEntities,
} = userInfoAdapter.getSelectors((state: RootState) => state.domainData.userInfo);

export const selectUserInfoEntityByUsername = createDeepEqualSelector(
  [selectAllUserInfoEntities, (state: RootState, username: string) => username],
  (entities, username) => entities.find((entity) => entity.username === username)
);

export const selectUserInfoEntityForAuthenticatedUser = createDeepEqualSelector(
  [selectAllUserInfoEntities, (state: RootState) => state.appState.authentication.username],
  (entities, username) => entities.find((entity) => entity.username === username)
);

export const selectOrganisationForAuthenticatedUser = createDeepEqualSelector(
  [(state: RootState) => selectAllOrganisationEntities(state), selectUserInfoEntityForAuthenticatedUser],
  (organisations, userInfo) => {
    return organisations.find((organisation) => organisation.id === userInfo?.organisationId);
  }
);

export const selectIsCurrentUserOrganisationAdmin = createDeepEqualSelector(
  [selectUserInfoEntityForAuthenticatedUser],
  (user) => {
    return user?.organisationRole === OrganisationRole.Admin;
  }
);

// Note: The organisation name to display is calculated based on organisation.displayName and user.firstName, user.lastName
export const selectOrganisationNameForAuthenticatedUser = createDeepEqualSelector(
  [selectUserInfoEntityForAuthenticatedUser, selectOrganisationForAuthenticatedUser],
  (user, organisation) => {
    if (organisation?.displayName) {
      return organisation.displayName;
    }

    return [user?.firstName, user?.lastName].join(" ");
  }
);
