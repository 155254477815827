import React from "react";
import DocumentContainer from "components/DocumentContainer/DocumentContainer";
import { useStoreSelector } from "store/hooks";
import { DocumentContainerType } from "models/DocumentContainer.model";
import { selectApplicationDocumentContainerRelationsByTypeAndName } from "store/domain-data/application-document-containers-relation/applicationDocumentContainersRelation";

type Props = {
  applicationId: number;
  documentContainerType: DocumentContainerType;
  documentContainerName: string;
  hasNoHeading: boolean;
};

const DocumentContainerByTypeAndName: React.FC<Props> = ({
  applicationId,
  documentContainerType,
  documentContainerName,
  hasNoHeading = true,
}) => {
  const relation = useStoreSelector((state) =>
    selectApplicationDocumentContainerRelationsByTypeAndName(state, {
      applicationId,
      documentContainerType,
      documentContainerName,
    })
  );

  if (!relation) {
    return null;
  }

  return (
    <DocumentContainer
      applicationId={applicationId}
      documentContainerId={relation.documentContainerId}
      hasNoHeading={hasNoHeading}
    />
  );
};

export default DocumentContainerByTypeAndName;
