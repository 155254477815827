import React from "react";
import Dropzone, { DropzoneProps, DropzoneRef } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import FontIcon from "components/FontIcon/FontIcon";
import Guard from "components/Guard/Guard";
import styled, { css } from "styled-components/macro";
import FlexBox from "components/FlexBox/FlexBox";

type Props = DropzoneProps & React.RefAttributes<DropzoneRef>;

const FileUploadDropzone: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Dropzone noKeyboard={true} noClick={true} noDragEventsBubbling={false} {...props}>
      {(childrenProps) => {
        const { getRootProps, getInputProps, isDragActive, open: openFileBrowser, isDragReject } = childrenProps;
        const hasError = isDragActive && isDragReject;

        return (
          <StyledDropzone
            data-testid="FileUploadDropzone"
            {...(getRootProps() as any)}
            $isDragActive={isDragActive}
            $isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            <FlexBox direction={"column"} alignItems={"center"} spacing={2}>
              <StyledUploadIcon $isDragReject={isDragReject} />

              {/* TODO: May need to provide renderDropMessage as a customise point in the future */}
              <StyledDropMessage $isDragActive={isDragActive} $isDragReject={isDragReject}>
                <Guard condition={hasError}>{t(`Unable to upload file due to file constraints`)}</Guard>
                <Guard condition={!hasError && !isDragActive}>
                  <Guard condition={!props.multiple}>
                    <Trans
                      defaults={`Drop your file here or <button>click to browse</button>`}
                      components={{
                        button: <StyledLinkButton onClick={openFileBrowser} type={"button"} />,
                      }}
                    />
                  </Guard>
                  <Guard condition={props.multiple}>
                    <Trans
                      defaults={`Drop your files here or <button>click to browse</button>`}
                      components={{
                        button: <StyledLinkButton onClick={openFileBrowser} type={"button"} />,
                      }}
                    />
                  </Guard>
                </Guard>
                <Guard condition={!hasError && isDragActive}>{t(`Drop your file here`)}</Guard>
              </StyledDropMessage>
            </FlexBox>

            {props.children && props.children(childrenProps)}
          </StyledDropzone>
        );
      }}
    </Dropzone>
  );
};

const StyledDropzone = styled.div<{ $isDragActive: boolean; $isDragReject: boolean }>(
  ({ theme, $isDragActive, $isDragReject }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;
    background: ${theme.palette.objective.light.day};
    border: 1px dashed ${theme.palette.objective.dark.fiordland};
    border-radius: 4px;

    ${$isDragActive &&
    !$isDragReject &&
    css`
      background: ${theme.palette.objective.blue.light};
      border: 1px dashed ${theme.palette.objective.blue.main};
    `}

    ${$isDragActive &&
    $isDragReject &&
    css`
      background: ${theme.palette.objective.background.freshRaspberry};
      border: 1px dashed ${theme.palette.objective.red.main};
    `}
  `
);

const StyledUploadIcon = styled(FontIcon).attrs({
  name: "cloud-upload",
})<{ $isDragReject: boolean }>(
  ({ theme, $isDragReject }) => css`
    font-size: 16px;

    ${$isDragReject &&
    css`
      color: ${theme.palette.objective.red.main};
    `}
  `
);

const StyledDropMessage = styled.div<{ $isDragActive: boolean; $isDragReject: boolean }>(
  ({ theme, $isDragActive, $isDragReject }) => css`
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.objective.dark.fiordland};

    ${$isDragActive &&
    !$isDragReject &&
    css`
      color: ${theme.palette.objective.dark.night};
    `}

    ${$isDragActive &&
    $isDragReject &&
    css`
      color: ${theme.palette.objective.red.main};
    `}
  `
);

const StyledLinkButton = styled.button(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    border: none !important;
    background: none !important;
    text-transform: none !important;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.objective.blue.main};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `
);

export default FileUploadDropzone;
