import React from "react";
import styled, { css } from "styled-components/macro";
import { Box } from "@material-ui/core";
import FontIcon from "components/FontIcon/FontIcon";

type Props = {
  icon: string;
  colour: string;
  iconColour: string;
  size?: number;
};

const ItemStatusIcon: React.FC<Props> = ({ icon, colour, iconColour, size = 24 }) => {
  return (
    <StyledStatusIconBackground $colour={colour} $size={size}>
      <FontIcon name={icon} color={iconColour} fontSize={size} data-testid="FontIcon" data-icon={icon} />
    </StyledStatusIconBackground>
  );
};

const StyledStatusIconBackground = styled(Box)<{ $colour: string; $size: number }>(
  ({ theme, $colour, $size }) => css`
    width: calc(${$size}px * 2);
    height: calc(${$size}px * 2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${$colour};
    border-radius: 50%;
  `
);

export default ItemStatusIcon;
