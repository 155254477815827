import React from "react";
import { useStoreDispatch } from "store/hooks";
import { useToast } from "hooks/useToast";
import RemoveAllApplicationDocumentsInContainerConfirmationDialog from "components/DocumentContainer/RemoveAllApplicationDocumentsInContainerConfirmationDialog";
import { removeApplicationDocumentsInContainer } from "store/domain-data/application-document-relation/applicationDocumentRelation";

export const useRemoveAllApplicationDocumentsInContainer = (applicationId: number, onDocumentsRemoved?: () => void) => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  const [removingDocumentContainerId, setRemovingDocumentContainerId] = React.useState(0);

  const handleCloseConfirmationDialog = React.useCallback(
    async (confirmed: boolean) => {
      setRemovingDocumentContainerId(0);

      if (confirmed) {
        try {
          await dispatch(
            removeApplicationDocumentsInContainer({
              applicationId,
              documentContainerId: removingDocumentContainerId,
            })
          );

          if (onDocumentsRemoved) {
            onDocumentsRemoved();
          }
        } catch (e) {
          toastError(e);
        }
      }
    },
    [applicationId, removingDocumentContainerId, dispatch, onDocumentsRemoved, toastError]
  );

  const handleRemoveAllApplicationDocumentsInContainer = React.useCallback((containerId: number) => {
    setRemovingDocumentContainerId(containerId);
  }, []);

  const removeAllApplicationDocumentsInContainerConfirmationDialog = React.useMemo(() => {
    if (!removingDocumentContainerId) {
      return null;
    }

    return (
      <RemoveAllApplicationDocumentsInContainerConfirmationDialog
        documentContainerId={removingDocumentContainerId}
        onClose={handleCloseConfirmationDialog}
      />
    );
  }, [removingDocumentContainerId, handleCloseConfirmationDialog]);

  return {
    handleRemoveAllApplicationDocumentsInContainer,
    removeAllApplicationDocumentsInContainerConfirmationDialog,
    onDocumentsRemoved,
  };
};
