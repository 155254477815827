import React from "react";
import MuiTabs, { TabsProps } from "@material-ui/core/Tabs";
import styled, { css } from "styled-components/macro";
import { useMediaQuery, useTheme } from "@material-ui/core";

type Props = Omit<TabsProps, "onChange"> & {
  // Workaround on typing issue of onChange
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
};

const Tabs: React.FC<Props> = React.forwardRef(({ ...tabsProps }, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return <StyledMuiTabs ref={ref} variant="scrollable" scrollButtons={isMobile ? "on" : "off"} {...tabsProps} />;
});

const StyledMuiTabs = styled(MuiTabs)(
  ({ theme }) => css`
    .MuiTabs-indicator {
      background-color: transparent !important;
    }
  `
);

export default Tabs;
