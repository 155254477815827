import React from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core";
import FontIcon from "components/FontIcon/FontIcon";
import { ODL_ICONS } from "components/FontIcon/FontIcon.constant";
import { hideInPrintMixin } from "components/odl-v2/Button/mixins/hideInPrintMixin";

type Props = {
  scrollElement?: HTMLElement;
  scrollPosition: number;
};

const ScrollToTop: React.FC<Props> = ({ scrollElement, scrollPosition }) => {
  // Common
  const { t } = useTranslation();
  const theme = useTheme();

  // Callbacks
  const handleScrollToTop = React.useCallback(() => {
    if (!scrollElement) {
      return;
    }

    scrollElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [scrollElement]);

  // Guard
  if (!scrollElement || scrollPosition <= 400) {
    return null;
  }

  return (
    <StyledScrollToTop data-testid={"ScrollToTop"}>
      <StyledScrollToTopButton data-testid={"ScrollToTopButton"} onClick={handleScrollToTop}>
        <StyledActionTitle>{t("Back to Top")}</StyledActionTitle>
        <StyledFontIcon
          name={ODL_ICONS.ARROW_UP_BOLD_CIRCLE_OUTLINE}
          data-testid={"ScrollToTopIcon"}
          fontSize={14}
          color={theme.palette.objective.blue.main}
        />
      </StyledScrollToTopButton>
    </StyledScrollToTop>
  );
};

const StyledScrollToTop = styled.div(
  ({ theme }) => css`
    position: fixed;
    bottom: 2px;
    right: 16px;
    z-index: ${theme.zIndex.modal};

    ${hideInPrintMixin()}
  `
);

const StyledScrollToTopButton = styled.button(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    opacity: 100%;
    padding: 8px 0;
    cursor: pointer;
    color: ${theme.palette.primary.main};
    ${theme.mixins.flexGap("6px")}

    &:hover {
      opacity: 100%;
    }
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    fill: ${theme.palette.objective.background.freshCabbage};
  `
);

const StyledActionTitle = styled.span(
  ({ theme }) => css`
    font-size: 13px;
    font-weight: 500;
  `
);

export default ScrollToTop;
