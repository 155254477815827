export type IDocumentCategoryEntity = {
  id: number;
  name: string;
  displayName: string;
  jurisdictionId: number;
  displayOrder: number;
  identifier: DocumentCategoryIdentifier;
};

export enum DocumentCategoryIdentifier {
  Lodgement = "Lodgement",
  Assessment = "Assessment",
  Approved = "Approved",
  Inspections = "Inspections",
  Certificates = "Certificates",
  Required = "Required",
  Payment = "Payment",
}

export const DocumentCategoryIdentifierUtil = {
  parse: (category: string): DocumentCategoryIdentifier => {
    for (const value of Object.values(DocumentCategoryIdentifier)) {
      if (category === value.toString()) {
        return value;
      }
    }
    // Legacy support
    switch (category) {
      case "Submission":
        return DocumentCategoryIdentifier.Lodgement;
      case "Consent":
        return DocumentCategoryIdentifier.Assessment;
      default:
        return DocumentCategoryIdentifier.Lodgement;
    }
  },
};
