import React from "react";
import { PrimarySideNavigationItemType } from "components/PrimarySideNavigation/PrimarySideNavigation.types";
import { useAppNavigationItemsProps } from "hooks/useAppNavigationItemsProps";
import PrimarySideNavigationItems from "components/PrimarySideNavigation/PrimarySideNavigationItems";
import { usePostHog } from "posthog-js/react";

type Props = {
  activeNavigationItemType?: PrimarySideNavigationItemType;
};

const PrimarySideNavigation: React.FC<Props> = ({ activeNavigationItemType }) => {
  const posthog = usePostHog();
  const { isCollapsed, onClickToggleCollapse } = useAppNavigationItemsProps();

  const onClickToggle = React.useCallback(() => {
    onClickToggleCollapse();
    posthog?.capture(`user toggled-side-navigation`);
  }, [onClickToggleCollapse, posthog]);

  return (
    <PrimarySideNavigationItems
      activeNavigationItemType={activeNavigationItemType}
      data-testid={"NavigationItems"}
      isCollapsed={isCollapsed}
      onClickToggleCollapse={onClickToggle}
    />
  );
};

export default PrimarySideNavigation;
