import React from "react";

export const useDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const openDialog = React.useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return {
    isDialogOpen,
    openDialog,
    closeDialog,
  };
};
