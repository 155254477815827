import { Formio, Templates } from "@objective_build/formiojs";
import checkboxFormTemplate from "components/FormIOForm/templates/checkbox";
import radioFormTemplate from "components/FormIOForm/templates/radio";
import inputFormTemplate from "components/FormIOForm/templates/input";
import labelFormTemplate from "components/FormIOForm/templates/label";
import addressFormTemplate from "components/FormIOForm/templates/address";
import selectboxesFormTemplate from "components/FormIOForm/templates/selectboxes";
import "./FormIOForm.styles.scss";
import merge from "lodash/merge";

// @ts-ignore
import AllComponents from "@objective_build/formiojs/components";
// @ts-ignore
import Components from "@objective_build/formiojs/components/Components";
import RadioComponent from "./formio-components/Radio";
import CheckboxComponent from "./formio-components/Checkbox";
import EditGridComponent from "components/FormIOForm/formio-components/EditGrid";
import DocumentContainerComponent from "components/FormIOForm/react-components/DocumentContainerFormio";
import { OBAPIRequestPlugin } from "components/FormIOForm/plugins/OBAPIRequestPlugin";

AllComponents.radio = RadioComponent;
AllComponents.checkbox = CheckboxComponent;
AllComponents.editgrid = EditGridComponent;
AllComponents.documentContainer = DocumentContainerComponent;
Components.setComponents(AllComponents);

Templates.current = merge({}, Templates.defaultTemplates, {
  checkbox: {
    form: checkboxFormTemplate,
  },
  radio: {
    form: radioFormTemplate,
  },
  input: {
    form: inputFormTemplate,
  },
  label: {
    form: labelFormTemplate,
  },
  address: {
    form: addressFormTemplate,
  },
  "radio-selectboxes": {
    form: selectboxesFormTemplate,
  },
});

Formio.registerPlugin(OBAPIRequestPlugin, "obapi-request");
