import { DOCUMENT_MERGING_ENABLED } from "constants/configs";

export type IDocumentContainerEntity = {
  id: number;
  name: string;
  displayName: string;
  description: string;
  mergeFileName: string;
  displayOrder: number;
  containerType: DocumentContainerType;
};

export enum DocumentContainerType {
  DocumentUploadStep = "DocumentUploadStep",
  RequiredDocument = "RequiredDocument",
  RFIDocument = "RFIDocument",
  PaymentProofOfDocument = "PaymentProofOfDocument",
  CouncilUploadDocument = "CouncilUploadDocument",
  FormPdfDocument = "FormPdfDocument",
  OtherUploadDocument = "OtherUploadDocument",
}

export const DocumentContainerTypeUtil = {
  parse: (containerType: string): DocumentContainerType => {
    switch (containerType) {
      case "DOCUMENT_UPLOAD_STEP":
      case "DocumentUploadStep":
        return DocumentContainerType.DocumentUploadStep;
      case "REQUIRED_DOCUMENT":
      case "RequiredDocument":
        return DocumentContainerType.RequiredDocument;
      case "RFI_DOCUMENT":
      case "RFIDocument":
        return DocumentContainerType.RFIDocument;
      case "PAYMENT_PROOF_UPLOAD":
      case "PaymentProofUploadDocument":
        return DocumentContainerType.PaymentProofOfDocument;
      case "COUNCIL_UPLOAD":
      case "CouncilUpload":
        return DocumentContainerType.CouncilUploadDocument;
      case "FORM_PDF":
      case "FormPdf":
        return DocumentContainerType.FormPdfDocument;
      case "DOC_UPLOAD_OTHER":
      case "DocUploadOther":
        return DocumentContainerType.OtherUploadDocument;
      default:
        return DocumentContainerType.OtherUploadDocument;
    }
  },
};

export const DocumentContainerUtil = {
  isMergeDocumentsSupported: (documentContainer: IDocumentContainerEntity) => {
    if (!DOCUMENT_MERGING_ENABLED) {
      return false;
    }
    return documentContainer.name.toLowerCase() !== "other";
  },
  isProofOfOwnership: (documentContainer: IDocumentContainerEntity) => {
    switch (documentContainer.name) {
      case "Proof of Ownership":
      case "proof_of_ownership":
        return true;
      default:
        return false;
    }
  },
  isPIM: (documentContainer: IDocumentContainerEntity) => {
    switch (documentContainer.name) {
      case "Project Information Memorandum":
      case "project_information_memorandum":
        return true;
      default:
        return false;
    }
  },
};
