import { INotificationUserProfileService } from "services/notifiation-user-profile/NotificatinUserProfileService.types";
import { Cradle } from "services/serviceContainer.types";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { INotificationUserProfileEntity } from "models/NotificationUserProfile.model";
import get from "lodash/get";
import toString from "lodash/toString";
import { BNS_FETCH_PROFILE_ENABLED, BNS_MARK_SEEN_ENABLED } from "constants/configs";

export class NotificationUserProfileService implements INotificationUserProfileService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchNotificationUserProfile() {
    if (!BNS_FETCH_PROFILE_ENABLED) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Not implemented");
    }

    try {
      const response = await this.apiClient.protectedApi.get(`/v1/service/notifications/user/profile`);
      return this.parseNotificationUserProfile(response.data);
    } catch (error) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch notification user profile`));
    }
  }

  public async markAllNotificationsAsSeen() {
    if (!BNS_MARK_SEEN_ENABLED) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Not implemented");
    }

    try {
      const response = await this.apiClient.protectedApi.patch("v1/service/notifications/user/seen");
      return this.parseNotificationUserProfile(response.data);
    } catch (error) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to update notification user profile`));
    }
  }

  private parseNotificationUserProfile(json: any): INotificationUserProfileEntity {
    return {
      id: toString(get(json, "id")),
      username: toString(get(json, "username")),
      createdTime: toString(get(json, "createdTime")),
      updatedTime: toString(get(json, "updatedTime")),
      hasNewNotifications: Boolean(get(json, "hasNewNotifications")),
      apiVersion: toString(get(json, "apiVersion")),
    };
  }
}
