import React from "react";
import { WebForm } from "@objective_build/formiojs";
import { useStoreSelector } from "store/hooks";
import { selectApplicationStepDataEntity } from "store/domain-data/application-step-data/applicationStepData";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { selectApplicationValidationErrorsByStep } from "store/domain-data/application-validation-result/applicationValidationResult";

type Props = {
  applicationId: number;
  breadcrumbStepId: number;
  form: WebForm;
};

const FormIOReviewDataHandler: React.FC<Props> = ({ applicationId, breadcrumbStepId, form }) => {
  // Retrieve application step data
  const applicationStepData = useStoreSelector((state) =>
    selectApplicationStepDataEntity(state, { applicationId, breadcrumbStepId })
  );

  const serverValidationErrors = useStoreSelector((state) =>
    selectApplicationValidationErrorsByStep(state, { stepId: breadcrumbStepId, applicationId: applicationId })
  );

  React.useEffect(() => {
    if (!form || !applicationStepData) {
      return;
    }

    // Note: formio read-only mode can't show client-side validation errors

    if (!isEmpty(applicationStepData.data)) {
      // Pre-fill submission, it will dispatch 2 "onChange" events, and the "data" carried in callback will be based on application step data
      //    Thus, we guarantee saved data won't be overwritten by default empty values
      form.submission = {
        data: cloneDeep(applicationStepData.data),
      };
    }
  }, [form, applicationStepData, serverValidationErrors]);

  return null;
};

export default FormIOReviewDataHandler;
