import {
  IEmbeddedOrganisationAdministrator,
  IOrganisationInfoWithEmbeddedAdministrators,
  IOrganisationService,
  IOrganisationUpdateRequestPayload,
} from "services/organisation/OrganisationService.types";
import { IOrganisationEntity, parseOrganisationImage } from "models/Organisation.model";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { ServiceError } from "services/ServiceError";
import toArray from "lodash/toArray";

export class OrganisationService implements IOrganisationService {
  private readonly apiClient: Cradle["apiClient"];

  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  async updateOrganisationInfo(args: IOrganisationUpdateRequestPayload): Promise<IOrganisationEntity> {
    try {
      const response = await this.apiClient.protectedApi.patch(`/user/organisation`, args);
      const json = response.data;
      const organisation = this.parseOrganisationEntityJSON(json);
      return organisation;
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  async lookupOrganisationByAdminEmail(email: string): Promise<IOrganisationInfoWithEmbeddedAdministrators> {
    try {
      const response = await this.apiClient.protectedApi.get(`/user/organisation/lookup`, {
        params: {
          email,
        },
      });
      const json = response.data;
      const organisation = this.parseOrganisationAdminEntityJSON(json);
      return organisation;
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  private parseOrganisationEntityJSON(json: string): IOrganisationEntity {
    const jsonImage = get(json, "image");

    return {
      id: toInteger(get(json, "id")),
      displayName: toString(get(json, "displayName")),
      colour: toString(get(json, "colour")),
      image: jsonImage ? parseOrganisationImage(jsonImage) : undefined,
    };
  }

  private parseOrganisationAdminEntityJSON(json: string): IOrganisationInfoWithEmbeddedAdministrators {
    const jsonAdmins = toArray(get(json, "administrators"));
    const admins = jsonAdmins.map((jsonAdmin) => {
      return this.parseOrganisationAdmin(jsonAdmin);
    });
    return {
      id: toInteger(get(json, "id")),
      displayName: toString(get(json, "displayName")),
      administrators: admins,
    };
  }
  private parseOrganisationAdmin(json: any): IEmbeddedOrganisationAdministrator {
    return {
      displayName: toString(get(json, "name")),
      email: toString(get(json, "email")),
    };
  }
}
