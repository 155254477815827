import { serviceContainer } from "services/serviceContainer";
import { PDFDocument } from "pdf-lib";
import { MIME_TYPES } from "constants/configs";

// Util function to convert image to pdf
export const convertImageToPdf = async (imageFile: File): Promise<File> => {
  const t = serviceContainer.cradle.i18n.t;

  // We are maintaining the original document name including extension if present
  const fileName = `${imageFile.name}.pdf`;

  const arrayBuffer = await imageFile.arrayBuffer();

  // Create a pdf document
  const pdfDoc = await PDFDocument.create();
  pdfDoc.setCreationDate(new Date());

  // Add a blank page to the document
  const page = pdfDoc.addPage();

  // Embed the arrayBuffer of the image to the pdf document based on its type
  let image;
  switch (imageFile.type) {
    case MIME_TYPES.IMAGE_JPEG:
      image = await pdfDoc.embedJpg(arrayBuffer || "");
      break;
    case MIME_TYPES.IMAGE_PNG:
      image = await pdfDoc.embedPng(arrayBuffer || "");
      break;
    default:
      throw new Error(t("Type not supported"));
  }

  // Get the dimensions of the image
  const imgWidth = image.width;
  const imgHeight = image.height;

  // Update the page size to match the image size, so we don't have to scale the image and we maintain the quality of the images
  page.setSize(imgWidth, imgHeight);

  // Draw the image in pdf document
  page.drawImage(image, {
    x: 0,
    y: 0,
    width: imgWidth,
    height: imgHeight,
  });

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const result = await pdfDoc.save();
  const blob = new Blob([result], { type: MIME_TYPES.APPLICATION_PDF });
  const file = new File([blob], fileName, { type: MIME_TYPES.APPLICATION_PDF });
  return file;
};
