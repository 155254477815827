import React from "react";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";
import { ODL_ICONS } from "../FontIcon/FontIcon.constant";

type Props = {
  displayName: string;
  isSelected: boolean;
  isLocked: boolean;
  isClickable: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
};

const ParticipantTypeTag: React.FC<Props> = ({
  displayName,
  isSelected,
  isLocked,
  isClickable,
  isRequired = false,
  disabled = false,
  onClick,
  ...otherProps
}) => {
  return (
    <StyledParticipantTypeTag
      onClick={onClick}
      $isSelected={isSelected}
      $isClickable={isClickable}
      disabled={disabled}
      isRequired={isRequired}
      data-testid={"ParticipantTypeTag"}
      aria-pressed={isSelected}
      {...otherProps}
    >
      <StyledIconContainer $isSelected={isSelected}>
        {!isSelected && <FontIcon name={ODL_ICONS.PLUS} />}
        {isSelected && <FontIcon name={ODL_ICONS.CHECK} />}
      </StyledIconContainer>
      {displayName}
    </StyledParticipantTypeTag>
  );
};

const StyledParticipantTypeTag = styled.button<{
  $isClickable: boolean;
  $isSelected: boolean;
  isRequired: boolean;
  disabled: boolean;
}>(
  ({ theme, $isClickable, $isSelected, disabled, isRequired }) => css`
    padding: 6px 8px 6px 8px;
    background: ${theme.palette.objective.light.day};
    border-radius: 4px;
    border: 1px solid ${theme.palette.objective.light.allspice};
    font-size: 14px;
    text-align: center;
    color: ${theme.palette.objective.dark.neutral};
    display: flex;
    align-items: center;
    cursor: ${$isClickable && !disabled ? "pointer" : "default"};
      ${
        isRequired &&
        css`
          background: ${theme.palette.objective.light.white};
          border: 1px solid ${theme.palette.objective.blue.main};
        `
      }
    ${
      $isSelected &&
      css`
        background: ${theme.palette.objective.green.dark};
        color: ${theme.palette.objective.light.white};
        border: 1px solid ${theme.palette.objective.green.dark};
      `
    }
    ${
      disabled &&
      css`
        background: ${theme.palette.objective.light.day};
        color: ${theme.palette.objective.light.allspice};
      `
    }
    ${theme.mixins.flexGap("10px")};
  `
);

const StyledIconContainer = styled.div<{ $isSelected: boolean }>(
  ({ theme, $isSelected }) => css`
    border: 2px solid ${theme.palette.objective.blue.main};
    border-radius: 50%;
    height: 24px;
    width: 24px;
    color: ${theme.palette.objective.blue.main};
    ${$isSelected &&
    css`
      border: 2px solid ${theme.palette.objective.light.white};
      color: ${theme.palette.objective.green.main};
      background-color: ${theme.palette.objective.light.white};
    `}
  `
);

export default ParticipantTypeTag;
