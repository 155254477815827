export const getAddressWithoutCountry = (address: string): string => {
  if (!address) {
    return "";
  }
  const DEFINED_COUNTRIES = ["new zealand"];
  const addressLines = address.split(", ");
  if (addressLines.length === 0) {
    return "";
  }
  const lastAddressLine = addressLines.pop() ?? "";
  if (DEFINED_COUNTRIES.includes(lastAddressLine.trim().toLowerCase())) {
    return addressLines.join(", ");
  }
  return address.trim();
};
