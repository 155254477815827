import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { fetchParticipants, selectParticipantEntitiesByApplicationId } from "store/domain-data/participant/participant";
import { selectIsLoadingThunk } from "store/app-state/loading/loading";
import React from "react";

export const useParticipants = (applicationId: number) => {
  const dispatch = useStoreDispatch();
  const participants = useStoreSelector((state) => selectParticipantEntitiesByApplicationId(state, applicationId));
  const isLoading = useStoreSelector((state) => selectIsLoadingThunk(state, fetchParticipants));

  React.useEffect(() => {
    dispatch(fetchParticipants({ applicationId }));
  }, [dispatch, applicationId]);

  return { participants, isLoading };
};
