import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import { ApplicationStepDataUtil, IApplicationStepRelation } from "models/ApplicationStepData.model";
import { createDeepEqualSelector } from "store/utils";
import { batch } from "react-redux";

// Entity Adapter

const applicationStepRelationAdapter = createEntityAdapter<IApplicationStepRelation>({
  selectId: (relation) => ApplicationStepDataUtil.generateEntityKey(relation),
  sortComparer: (a, b) => {
    if (a.applicationId !== b.applicationId) {
      return a.applicationId - b.applicationId;
    }

    return a.breadcrumbStepId - b.breadcrumbStepId;
  },
});

// Action & Thunks

export const upsertApplicationStepRelations = createAction<IApplicationStepRelation[]>(
  "domainData/applicationStepRelation/upsertApplicationStepRelations"
);

export const removeApplicationStepRelationsByApplicationId = createAction<number>(
  "domainData/applicationStepRelation/removeApplicationStepRelationsByApplicationId"
);

export const fetchApplicationStepRelations = createAsyncThunk(
  "domainData/applicationStepRelation/fetchApplicationSteps",
  async (applicationId: number, thunkAPI) => {
    const enabledSteps = await serviceContainer.cradle.applicationService.fetchEnabledSteps(applicationId);
    const relations = enabledSteps.map((step) => {
      return {
        applicationId,
        breadcrumbStepId: step.id,
      } as IApplicationStepRelation;
    });
    batch(() => {
      thunkAPI.dispatch(removeApplicationStepRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(upsertApplicationStepRelations(relations));
    });
  }
);

// Reducer

export const applicationStepRelationReducer = createReducer(
  applicationStepRelationAdapter.getInitialState(),
  (builder) =>
    builder
      .addCase(upsertApplicationStepRelations, (draft, action) => {
        applicationStepRelationAdapter.upsertMany(draft, action.payload);
      })
      .addCase(removeApplicationStepRelationsByApplicationId, (draft, action) => {
        const relationIdsToBeRemoved = draft.ids.filter((id) => {
          const rel = draft.entities[id];
          return rel?.applicationId === action.payload;
        });
        applicationStepRelationAdapter.removeMany(draft, relationIdsToBeRemoved);
      })
);

// Selectors

export const { selectAll: selectAllApplicationStepRelations } = applicationStepRelationAdapter.getSelectors(
  (state: RootState) => state.domainData.applicationStepRelation
);

export const selectApplicationStepRelationsByApplicationId = createDeepEqualSelector(
  [selectAllApplicationStepRelations, (_: RootState, applicationId: number) => applicationId],
  (relations, applicationId) => {
    return relations.filter((rel) => rel.applicationId === applicationId);
  }
);

export const selectEnabledStepIdsByApplicationId = createDeepEqualSelector(
  [selectApplicationStepRelationsByApplicationId],
  (relations) => {
    return relations.map((rel) => rel.breadcrumbStepId);
  }
);
