import React from "react";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";

type Props = IconButtonProps & {
  icon?: string;
  "data-testid"?: string;
};

const MenuTriggerIconButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <StyledIconButton
      onClick={props.onClick}
      aria-label={props["aria-label"] || t(`Actions`)}
      data-testid={props["data-testid"] || "MenuTriggerIconButton"}
      disableRipple={true}
      {...omit(props, ["icon", "data-testid", "aria-label", "onClick"])}
    >
      <StyledFontIcon name={props.icon || "dots-horizontal"} />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    height: 44px;
    width: 44px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-size: 18px;
  `
);

export default MenuTriggerIconButton;
