import React from "react";
import { Divider, MenuItem as MuiMenuItem } from "@material-ui/core";
import styled, { css } from "styled-components/macro";

type Props = {};

const MenuItemDivider: React.FC<Props> = () => {
  return (
    <StyledDividerMenuItem disableRipple={true} disableTouchRipple={true} disabled={true} disableGutters={true}>
      <StyledDivider />
    </StyledDividerMenuItem>
  );
};

const StyledDividerMenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    min-width: 200px;
    min-height: 0;
    padding: 4px 0;
  `
);

const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    width: 100%;
  `
);

export default MenuItemDivider;
