import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  acceptOrganisationMergeInvitation,
  rejectOrganisationMergeInvitation,
  selectOrganisationMergeInvitationById,
} from "store/domain-data/organisation-merge-invitation/organisationMergeInvitation";
import styled, { css } from "styled-components/macro";
import { unwrapResult } from "@reduxjs/toolkit";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionSecondary from "components/odl-v2/Dialog/DialogActionSecondary";
import Alert from "components/odl-v2/Alert/Alert";
import AlertAction from "components/odl-v2/Alert/AlertAction";
import { usePostHog } from "posthog-js/react";

type Props = {
  mergeInvitationId: number;
};

const OrganisationMergeInvitationAlertItem: React.FC<Props> = ({ mergeInvitationId }) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();

  const organisationMergeInvitation = useStoreSelector((state) =>
    selectOrganisationMergeInvitationById(state, mergeInvitationId)
  );

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleClickOrganisationMergeInvitation = React.useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleClickAcceptInvitation = React.useCallback(async () => {
    try {
      await dispatch(acceptOrganisationMergeInvitation(mergeInvitationId)).then(unwrapResult);
      toastSuccess(t(`Organisation merge invitation accepted`));
      posthog?.capture(`user organisation-merge-invitation-accepted`);
      handleCloseDialog();

      // TODO: Reload user information in a better manner
      setTimeout(() => {
        window.location.reload();
      }, 1);
    } catch (e) {
      toastError(e);
    }
  }, [dispatch, handleCloseDialog, mergeInvitationId, posthog, t, toastError, toastSuccess]);

  const handleClickDeclineInvitation = React.useCallback(async () => {
    try {
      await dispatch(rejectOrganisationMergeInvitation(mergeInvitationId)).then(unwrapResult);
      toastSuccess(t(`Organisation merge invitation declined`));
      posthog?.capture(`user organisation-merge-invitation-declined`);
      handleCloseDialog();
    } catch (e) {
      toastError(e);
    }
  }, [dispatch, handleCloseDialog, mergeInvitationId, posthog, t, toastError, toastSuccess]);

  if (!organisationMergeInvitation) {
    return null;
  }
  const organisationName = organisationMergeInvitation.organisationName;

  return (
    <React.Fragment>
      <Alert
        severity={"info"}
        data-testid={"AlertOrganisationInvitation"}
        action={
          <AlertAction
            children={t(`Respond`)}
            severity={"info"}
            onClick={handleClickOrganisationMergeInvitation}
            data-testid={"AlertLinkOrganisationInvitation"}
          />
        }
      >
        {t(`"{{organisationName}}" has invited your organisation to merge with theirs`, {
          organisationName: organisationMergeInvitation.organisationName,
        })}
      </Alert>

      <Dialog
        title={t(`Respond to organisation merge invitation`)}
        data-testid={"RespondToOrganisationMergeInvitationDialog"}
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth={false}
        scroll={"body"}
      >
        <DialogContent>
          <StyledDialogContent>
            <StyledSubTitle>
              <Trans>
                <span>
                  Your organisation has been invited to merge with <StyledNoWrap>"{{ organisationName }}"</StyledNoWrap>
                </span>
              </Trans>
            </StyledSubTitle>
          </StyledDialogContent>
          <StyledSubHeading>{t("Important")}</StyledSubHeading>
          <StyledContent>
            <StyledParagraph>
              {t(
                `If you accept, all members of your organisation and all applications, contacts and templates move to {{organisationName}}.`,
                { organisationName }
              )}
            </StyledParagraph>
            <StyledParagraph>
              {t(
                `As a member of {{organisationName}}, you will have access to all that organisation's applications, contacts and templates.`,
                { organisationName }
              )}
            </StyledParagraph>
            <StyledParagraph>
              {t(
                `If you or any member of your organisation are then released from {{organisationName}}, all other members, consents, contacts and templates stay with that organisation. You will be able to view only those applications on which you are a participant.`,
                { organisationName }
              )}
            </StyledParagraph>
            <StyledParagraph>
              {t(
                `If an administrator of {{organisationName}} deactivates your user profile, you cannot log in to Objective Build using the email address connected to that profile. You cannot then view any applications on which you are a participant with that email.`,
                { organisationName }
              )}
            </StyledParagraph>
            <StyledParagraph>
              {t(
                `Consider requesting to be added as a participant on applications instead, if you need to collaborate with {{organisationName}} but do not want to be become a member of that organisation.`,
                { organisationName }
              )}
            </StyledParagraph>
          </StyledContent>
        </DialogContent>
        <StyledDialogActions>
          <DialogActionGroup>
            <DialogActionSecondary data-testid={"DeclineButton"} onClick={handleClickDeclineInvitation}>
              {t(`Decline`)}
            </DialogActionSecondary>
            <DialogActionPrimary data-testid={"AcceptButton"} onClick={handleClickAcceptInvitation}>
              {t(`Accept`)}
            </DialogActionPrimary>
          </DialogActionGroup>
        </StyledDialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const StyledDialogContent = styled.div(
  ({ theme }) => css`
    font-size: 16px;
  `
);

const StyledSubTitle = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 16px;
    color: ${theme.palette.objective.dark.neutral};
  `
);
const StyledSubHeading = styled.h3(
  ({ theme }) => css`
    padding: 16px 0;
    font-size: 16px;
    font-weight: 700;
  `
);

const StyledContent = styled.div(
  ({ theme }) => css`
    max-width: 600px;
  `
);

const StyledParagraph = styled.p(
  ({ theme }) => css`
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  `
);

const StyledNoWrap = styled.span(
  ({ theme }) => css`
    white-space: nowrap;
  `
);

const StyledDialogActions = styled(DialogActions)(
  ({ theme }) => css`
    flex-direction: row-reverse;
  `
);

export default OrganisationMergeInvitationAlertItem;
