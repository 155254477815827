import { IParticipantTypeService } from "./ParticipantTypeService.types";
import {
  IParticipantTypeEntity,
  IParticipantTypeApplicationEntity,
  parseParticipantAccessLevelString,
  parseParticipantTypeExtraNumber,
} from "models/Participant.model";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class ParticipantTypeService implements IParticipantTypeService {
  private readonly i18n: Cradle["i18n"];
  private readonly apiClient: Cradle["apiClient"];
  private readonly contactService: Cradle["contactService"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "i18n" | "apiClient" | "contactService">) {
    this.i18n = args.i18n;
    this.apiClient = args.apiClient;
    this.contactService = args.contactService;
  }

  public async fetchParticipantTypesByApplicationId(
    applicationId: number
  ): Promise<IParticipantTypeApplicationEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/participant-types`);
    const json = get(response.data, "availableParticipantTypes");
    if (!Array.isArray(json)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t("Fail to fetch participant types"));
    }

    let participantTypes: IParticipantTypeEntity[] = [];
    for (const item of json) {
      const participantType = this.parseParticipantTypeFromJSON(item);
      participantTypes.push(participantType);
    }

    return participantTypes;
  }

  public parseParticipantTypeFromJSON(json: any): IParticipantTypeApplicationEntity {
    const participantType: IParticipantTypeApplicationEntity = {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder")),
      required: Boolean(get(json, "required")),
      mandatory: Boolean(get(json, "mandatory")),
      mustInvite: Boolean(get(json, "mustInvite")),
      validationRequired: Boolean(get(json, "validationRequired")),
      number: parseParticipantTypeExtraNumber(toString(get(json, "number"))),
      minimumAccessLevel: parseParticipantAccessLevelString(toString(get(json, "minimumAccessLevel"))),
      enableGroups: Boolean(get(json, "enableGroups")),
    };
    const maskedBy = get(json, "maskedBy");
    if (maskedBy) {
      participantType.maskedBy = toString(maskedBy);
    }
    const managedBy = get(json, "managedBy");
    if (managedBy) {
      participantType.managedBy = toString(managedBy);
    }
    return participantType;
  }
}
