import React from "react";
import styled, { css } from "styled-components/macro";
import { Link, LinkProps } from "react-router-dom";
import FontIcon from "components/FontIcon/FontIcon";
import { NavigationItemsContext } from "components/odl-v2/Navigation/NavigationItemsContext";

type NavigationItemProps = Omit<LinkProps, "to" | "onClick"> & {
  to: string;
  icon: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isCollapsed?: boolean;
  "data-testid"?: string;
};

const NavigationItem: React.FC<NavigationItemProps> = React.forwardRef(
  (
    {
      icon,
      title,
      to,
      isActive = false,
      replace = false,
      isDisabled = false,
      isCollapsed: isCollapsedFromProps,
      "data-testid": dataTestId = "NavigationItem",
      ...otherProps
    },
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const { isCollapsed: isCollapsedFromContext } = React.useContext(NavigationItemsContext);

    const isCollapsed = React.useMemo(() => {
      // If specify `isCollapsed` from props, use that
      if (typeof isCollapsedFromProps !== "undefined") {
        return isCollapsedFromProps;
      }

      // Otherwise use value from context
      return isCollapsedFromContext;
    }, [isCollapsedFromProps, isCollapsedFromContext]);

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLAnchorElement>) => {
        // Should not perform click event when active or disabled, so no redirection could be triggered
        if (isActive || isDisabled) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }
      },
      [isActive, isDisabled]
    );

    return (
      <StyledNavigationItem
        to={to}
        replace={Boolean(replace)}
        $isActive={isActive}
        $isDisabled={isDisabled}
        $isCollapsed={isCollapsed}
        ref={ref}
        data-testid={dataTestId}
        data-is-active={isActive}
        data-is-collapsed={isCollapsed}
        aria-label={title}
        onClick={handleClick}
        {...otherProps}
      >
        <StyledIconContainer $isActive={isActive}>
          <StyledFontIcon
            name={icon}
            $isActive={isActive}
            $isDisabled={isDisabled}
            data-testid={"NavigationItemIcon"}
            data-title={title}
          />
        </StyledIconContainer>
        <StyledNavigationItemTitle $isCollapsed={isCollapsed} $isActive={isActive} $isDisabled={isDisabled}>
          {title}
        </StyledNavigationItemTitle>
      </StyledNavigationItem>
    );
  }
);

const StyledNavigationItem = styled(Link)<{
  $isActive: boolean;
  $isDisabled: boolean;
  $isCollapsed: boolean;
}>(
  ({ theme, $isActive, $isDisabled, $isCollapsed }) => css`
    // Default
    display: flex;
    padding: 10px;
    align-items: center;
    color: ${theme.palette.objective.dark.night};
    ${theme.mixins.flexGap("6px")}
    cursor: pointer;
    text-decoration: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &:hover {
      background: ${theme.palette.objective.light.day};
    }
    
    // Collapsed
    ${
      $isCollapsed &&
      css`
        display: inline-flex;
      `
    }

    // Active
    ${
      $isActive &&
      css`
        background-color: ${theme.palette.objective.blue.light};
        color: ${theme.palette.objective.light.white};
        &:hover {
          background-color: ${theme.palette.objective.blue.light};
        }
      `
    }

    // Disabled
    ${
      $isDisabled &&
      css`
        cursor: not-allowed;
        pointer-events: none;
      `
    }
  `
);

const StyledIconContainer = styled.div<{ $isActive: boolean }>(
  ({ theme, $isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    ${$isActive &&
    css`
      position: relative;
      &::before {
        position: absolute;
        content: "";
        left: -4px;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: ${theme.palette.objective.blue.main};
        border-radius: 4px;
      }
    `}
  `
);

const StyledFontIcon = styled(FontIcon)<{ $isActive: boolean; $isDisabled: boolean }>(
  ({ theme, $isActive, $isDisabled }) => css`
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};

    ${$isActive &&
    css`
      color: ${theme.palette.objective.blue.main};
    `}

    ${$isDisabled &&
    css`
      color: ${theme.palette.objective.dark.neutral};
    `}
  `
);

const StyledNavigationItemTitle = styled.span<{ $isCollapsed: boolean; $isActive: boolean; $isDisabled: boolean }>(
  ({ theme, $isCollapsed, $isActive, $isDisabled }) => css`
    text-wrap: none;
    white-space: nowrap;
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};
    padding: 0 10px;
    transition: ${theme.mixins.layoutTransition("left")};

    ${$isCollapsed &&
    css`
      display: none;
    `};

    ${$isActive &&
    css`
      color: ${theme.palette.objective.blue.main};
    `}

    ${$isDisabled &&
    css`
      color: ${theme.palette.objective.dark.neutral};
    `}
  `
);

export default NavigationItem;
