import React from "react";
import { serviceContainer } from "services/serviceContainer";
import { pageRefreshUtil } from "components/RouteController/functions/pageRefreshUtil";

export const lazyLoadComponent = (dynamicImport: any) => {
  return React.lazy(async () => {
    try {
      const importedModule = await dynamicImport();
      return importedModule;
    } catch (error) {
      logger.error(error);

      if (pageRefreshUtil.getIsPageRefreshed()) {
        return { default: FallbackComponent };
      }

      pageRefreshUtil.markPageRefreshed();
      window.location.reload();
      return { default: FallbackComponent };
    }
  });
};

const logger = serviceContainer.cradle.logger;

const FallbackComponent: React.FC = () => {
  return null;
};
