export const getHostnameFromUrl = (urlAsStr?: string): string => {
  if (!urlAsStr) {
    return "";
  }

  try {
    const url = new URL(urlAsStr);
    return url.hostname;
  } catch (e) {
    return "";
  }
};
