import React from "react";
import { FormControl, FormHelperText, InputAdornment, InputLabel, capitalize } from "@material-ui/core";
import { useFormikContext } from "formik";
import FontIcon from "components/FontIcon/FontIcon";
import Guard from "components/Guard/Guard";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "components/OutlinedInput/OutlinedInput";
import styled, { css } from "styled-components/macro";

const nanoid = require("nanoid");

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};

const FormikPasswordInput: React.FC<Props> = ({ label, fieldName, disabled = false, required = false }) => {
  const { touched, errors, handleChange, handleBlur, values } = useFormikContext<any>();

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const inputId = fieldName + "-" + nanoid(5);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);

  const handleTogglePasswordVisibilityClick = React.useCallback(() => {
    setIsPasswordVisible((value) => !value);
  }, []);

  const handleMouseDownPassword = React.useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <StyledInputLabel htmlFor={inputId} shrink={false}>
        {label}
      </StyledInputLabel>

      <Guard
        condition={!disabled}
        fallback={
          <OutlinedInput
            id={inputId}
            name={fieldName}
            type={"password"}
            value={values[fieldName]}
            disabled={disabled}
          />
        }
      >
        <OutlinedInput
          id={inputId}
          name={fieldName}
          type={isPasswordVisible ? "text" : "password"}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError}
          value={values[fieldName]}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePasswordVisibilityClick}
                onMouseDown={handleMouseDownPassword}
              >
                {/* For some reason the font icons are different sizes */}
                {isPasswordVisible ? <FontIcon name="eye" fontSize={20} /> : <FontIcon name="eye-off" fontSize={16} />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Guard>

      {hasError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.night};
  `
);

export default FormikPasswordInput;
