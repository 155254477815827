import { IOrganisationEntity } from "models/Organisation.model";
import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import { IOrganisationUpdateRequestPayload } from "services/organisation/OrganisationService.types";

// Entity Adapter

const organisationAdapter = createEntityAdapter<IOrganisationEntity>({
  selectId: (organisation) => organisation.id,
  sortComparer: (a, b) => b.id - a.id,
});

// Action & thunks
export const loadOrganisations = createAction<IOrganisationEntity[]>("domainData/organisation/loadOrganisations");
export const loadOrganisation = createAction<IOrganisationEntity>("domainData/organisation/loadOrganisation");
export const updateOrganisation = createAsyncThunk(
  "domainData/organisation/updateOrganisation",
  async (args: IOrganisationUpdateRequestPayload) => {
    return await serviceContainer.cradle.organisationService.updateOrganisationInfo(args);
  }
);

// Reducer
export const defaultOrganisationState = organisationAdapter.getInitialState();

export const organisationReducer = createReducer<typeof defaultOrganisationState>(
  defaultOrganisationState,
  (builder) => {
    builder
      .addCase(loadOrganisations, (draft, action) => {
        organisationAdapter.upsertMany(draft, action.payload);
      })
      .addCase(loadOrganisation, (draft, action) => {
        organisationAdapter.upsertOne(draft, action.payload);
      })
      .addCase(updateOrganisation.fulfilled, (draft, action) => {
        organisationAdapter.upsertOne(draft, action.payload);
      });
  }
);
// Selectors

export const {
  selectById: selectOrganisationEntityById,
  selectIds: selectOrganisationEntityIds,
  selectAll: selectAllOrganisationEntities,
} = organisationAdapter.getSelectors((state: RootState) => state.domainData.organisation);
