export enum DocumentErrorCode {
  FailToUpload = "FAIL_TO_UPLOAD",
  AvScanFailed = "AV_SCAN_FAILED",
  AvScanInfected = "AV_SCAN_INFECTED",
  UploadLinkExpired = "UPLOAD_LINK_EXPIRED",
  FileIsCorrupt = "FILE_IS_CORRUPT",
  WrongFileType = "WRONG_FILE_TYPE",
  InvalidPdfPasswordProtected = "INVALID_PDF_PASSWORD_PROTECTED",
  InvalidPdfInteractive = "INVALID_PDF_INTERACTIVE",
  InvalidPdfFormat = "INVALID_PDF_FORMAT",
  PdfValidationFailed = "PDF_VALIDATION_FAILED",
}

export const DocumentErrorCodeUtil = {
  parse: (str?: string): DocumentErrorCode | null => {
    const values = Object.values(DocumentErrorCode);
    for (const value of values) {
      if (str?.toUpperCase() === value) {
        return value;
      }
    }

    return null;
  },
  getErrorMessage: (errorCode?: DocumentErrorCode | null | undefined) => {
    switch (errorCode) {
      case DocumentErrorCode.AvScanInfected:
        return `File is infected. You must replace the file.`;
      case DocumentErrorCode.FileIsCorrupt:
        return `File is corrupt. You must replace the file.`;
      case DocumentErrorCode.InvalidPdfPasswordProtected:
        return `Password-protected files are not allowed.`;
      case DocumentErrorCode.InvalidPdfInteractive:
        return `Files with interactive content are not allowed.`;
      case DocumentErrorCode.InvalidPdfFormat:
        return `File's format does not match the file's type.`;
      default:
        return (
          `File failed to upload. Please remove the file and upload it again.` +
          (errorCode ? ` (Code: ${errorCode})` : ``)
        );
    }
  },
};
