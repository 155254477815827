import Resizer from "react-image-file-resizer";
import { IEncodedImageFile } from "models/EncodedImageFile.model";

/**
 * Resize image to fit the width & height constraint, generally use for thumbnail and logo.
 * Note: we always compress it to jpeg so that mime type is always correct.
 *   Interestingly, even the mime type is wrong, browser still can display it...
 *   That is, some thing like this still can display: src="data:image/png;base64,/9j/4AAQSkZJ..."
 *
 * Note: this function is hard to test because react-image-file-resizer seems not working well with the File object created by us
 *
 * @param file
 * @param options
 */
export const scaleImageToFit = async (
  file: File,
  options: {
    maxWidth: number;
    maxHeight: number;
  }
): Promise<IEncodedImageFile> => {
  const name = file.name;

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      options.maxWidth,
      options.maxHeight,
      "JPEG",
      90,
      0,
      (uri) => {
        const [, data] = (uri as string).split(",");
        resolve({
          // Note: when compressing, we changed the mime type of the file,
          //   but it could be better to leave the file name unchanged because user don't need to know it
          name,
          type: "image/jpeg",
          base64Image: data,
          size: data.length,
          modifiedDate: new Date().toISOString(),
        });
      },
      "base64"
    );
  });
};
