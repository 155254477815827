import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  processNextQueuedItem,
  selectApplicationDocumentUploadQueuedItems,
} from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import isEmpty from "lodash/isEmpty";
import { serviceContainer } from "services/serviceContainer";

const logger = serviceContainer.cradle.logger;
const LOG_PREFIX = "ApplicationDocumentUploadQueueListener::";

const ApplicationDocumentUploadQueueListener: React.FC = () => {
  const dispatch = useStoreDispatch();
  const queuedItems = useStoreSelector(selectApplicationDocumentUploadQueuedItems);

  // Effect: Check status of items and process next item
  React.useEffect(() => {
    if (isEmpty(queuedItems)) {
      return;
    }

    const isProcessing = queuedItems.some((item) => item.isProcessing);

    logger.debug(LOG_PREFIX, { queuedItems, isProcessing });
    if (isProcessing) {
      return;
    }

    logger.debug(LOG_PREFIX, "processNextQueuedItem");
    dispatch(processNextQueuedItem());
  }, [queuedItems, dispatch]);

  return null;
};

export default ApplicationDocumentUploadQueueListener;
