export enum ApplicationValidationResultSeverity {
  Suggestion = "Suggestion",
  Control = "Control",
  Error = "Error",
}

export const ApplicationValidationResultSeverityUtil = {
  parse: (str: string): ApplicationValidationResultSeverity => {
    for (const value of Object.values(ApplicationValidationResultSeverity)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        return value;
      }
    }
    return ApplicationValidationResultSeverity.Suggestion;
  },
};
