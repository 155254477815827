import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";

// Types

export type UIState = {
  isSideNavigationCollapsed: boolean;
};

// Actions & Thunks

export const setIsSideNavigationCollapsed = createAction<boolean>("appState/ui/setIsSideNavigationCollapsed");

export const toggleIsSideNavigationCollapsed = createAction<void>("appState/ui/toggleIsSideNavigationCollapsed");

// Reducer

export const initialState: UIState = {
  isSideNavigationCollapsed: false,
};

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIsSideNavigationCollapsed, (draft, action) => {
      draft.isSideNavigationCollapsed = action.payload;
    })
    .addCase(toggleIsSideNavigationCollapsed, (draft, action) => {
      draft.isSideNavigationCollapsed = !draft.isSideNavigationCollapsed;
    });
});

// Selectors

const selectUIState = (state: RootState) => state.appState.ui;

export const selectIsSideNavigationCollapsed = createDeepEqualSelector([selectUIState], (state: UIState) => {
  return state.isSideNavigationCollapsed;
});
