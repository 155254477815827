import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useTheme } from "@material-ui/core";
import { Formik } from "formik";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import Spacer from "components/Spacer/Spacer";
import Guard from "components/Guard/Guard";
import Box from "components/Box/Box";
import FlexBox from "components/FlexBox/FlexBox";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import AuthenticationNavigationTabs from "pages/authentication/AuthenticationNavigationTabs";
import { AuthenticationType, RegistrationFormValues } from "pages/authentication/AuthenticationPage.types";
import { selectIsLoadingThunk } from "store/app-state/loading/loading";
import { unwrapResult } from "@reduxjs/toolkit";
import { register } from "store/app-state/authentication/authentication";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { Link as RouterLink } from "react-router-dom";
import Alert from "components/odl-v2/Alert/Alert";
import { formValidationUtils } from "utils/formValidationUtils";
import FormikEmailInput from "components/FormikEmailInput/FormikEmailInput";
import FormikPasswordInput from "components/FormikPasswordInput/FormikPasswordInput";
import * as yup from "yup";
import FormikSubmitButton from "components/FormikSubmitButton/FormikSubmitButton";
import AcknowledgementCheckbox from "pages/authentication/AcknowledgementCheckbox";
import { serviceContainer } from "services/serviceContainer";
import HCaptcha, { HCaptchaClass } from "components/HCaptcha/HCaptcha";

type Props = {};

const defaultRegistrationValues: RegistrationFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
};

const registrationFormSchema = yup.object().shape({
  email: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForEmail(), formValidationUtils.getErrorMessageForEmail()),
  firstName: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForName(), formValidationUtils.getErrorMessageForName()),
  lastName: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForName(), formValidationUtils.getErrorMessageForName()),
  password: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForPassword(), formValidationUtils.getErrorMessageForPassword()),
});

const RegistrationSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useStoreDispatch();

  const [isAcknowledged, setIsAcknowledged] = React.useState(false);
  const isRegistering = useStoreSelector((state) => selectIsLoadingThunk(state, register));
  const [registerServiceError, setRegisterServiceError] = React.useState<ServiceError | null>(null);
  const [registered, setRegistered] = React.useState(false);
  const hCaptchaRef = React.useRef<HCaptchaClass | null>(null);

  const handleToggleAcknowledged = React.useCallback(() => {
    setIsAcknowledged((prev) => !prev);
  }, []);

  const handleFormChange = React.useCallback(() => {
    // Clear error after value changed
    setRegisterServiceError(null);
  }, []);

  const handleSubmit = React.useCallback(
    async (formValues: RegistrationFormValues) => {
      const hCaptcha = hCaptchaRef.current;
      if (!hCaptcha) {
        return;
      }
      let captchaToken;
      try {
        const result = await hCaptcha.execute({ async: true });
        captchaToken = result.response;
      } catch (e) {
        serviceContainer.cradle.logger.error(e);
        setRegisterServiceError(
          new ServiceError(ServiceErrorCode.ClientError, t(`Please complete the CAPTCHA challenge`))
        );
        return;
      }
      try {
        await dispatch(
          register({
            email: formValues.email,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            password: formValues.password,
            captchaToken: captchaToken,
          })
        ).then(unwrapResult);
        setRegistered(true);
      } catch (e) {
        setRegisterServiceError(e);
      }
    },
    [dispatch, t]
  );
  const isSubmitDisabled = isRegistering || !isAcknowledged;

  return (
    <Box data-testid="RegistrationSection">
      <AuthenticationNavigationTabs activeAuthenticationType={AuthenticationType.Registration} />

      <Spacer y={4} />

      {/* Registration form for new user */}
      <Guard condition={!registered}>
        <Formik<RegistrationFormValues>
          initialValues={defaultRegistrationValues}
          validationSchema={registrationFormSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, values, isValid }) => (
            <form onSubmit={handleSubmit} onChange={handleFormChange}>
              <HCaptcha size="invisible" ref={hCaptchaRef} />

              <FlexBox spacing={6} direction={"column"}>
                <FormikEmailInput label={t(`Email`)} fieldName="email" />
                <FormikTextInput label={t(`First name`)} fieldName="firstName" />
                <FormikTextInput label={t(`Last name`)} fieldName="lastName" />
                <FormikPasswordInput label={t(`Password`)} fieldName="password" />

                {/* Error message */}
                <Guard condition={registerServiceError}>
                  <Alert severity={"error"}>
                    <Guard
                      condition={registerServiceError?.code === ServiceErrorCode.CognitoUsernameExists}
                      fallback={registerServiceError?.message}
                    >
                      <FlexBox direction="column" spacing={2}>
                        <div>{t(`An account already exists with this email`)}</div>

                        <div>
                          <Trans
                            defaults="If you have forgotten your password, you can <0>reset password here</0>"
                            components={[<Link component={RouterLink} to="/forgot-password" />]}
                          />
                        </div>
                      </FlexBox>
                    </Guard>
                  </Alert>
                </Guard>
              </FlexBox>

              <Spacer y={6} />

              <AcknowledgementCheckbox isAcknowledged={isAcknowledged} onToggle={handleToggleAcknowledged} />

              <Spacer y={6} />

              <FlexBox justifyContent={"flex-end"}>
                <FormikSubmitButton title={t("Register")} disabled={isSubmitDisabled} />
              </FlexBox>

              <Spacer y={6} />

              <FlexBox spacing={1}>
                <Box fontWeight={500} color={theme.palette.text.secondary}>
                  {t(`Already have an account?`)}
                </Box>
                <Link component={RouterLink} to="/">
                  {t(`Sign In here`)}
                </Link>
              </FlexBox>
            </form>
          )}
        </Formik>
      </Guard>

      {/* Message after register */}
      <Guard condition={registered}>
        <FlexBox direction={"column"} alignItems="center" spacing={4}>
          <Box fontWeight={500} fontSize={20} lineHeight={1.3}>
            {t(`Thank you for registering`)}
          </Box>

          <Box fontSize={16} textAlign={"center"} lineHeight={1.3}>
            {t(`You will receive an email to validate your registration before you can sign in`)}
          </Box>
        </FlexBox>
      </Guard>
    </Box>
  );
};

export default RegistrationSection;
