import trim from "lodash/trim";
import toString from "lodash/toString";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
import { base64Encode } from "utils/base64Encode";
import { base64Decode } from "utils/base64Decode";

export type IAddressEntity = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  isManualAddress: boolean;
  fullAddress: string;
};

export type IExtendedAddressEntity = IAddressEntity & { latitude: number; longitude: number };

export type IAddressSuggestionEntity = {
  identifier: string;
  fullAddress: string;
};

export const AddressUtil = {
  getFullAddress: (address?: IAddressEntity): string => {
    if (!address) {
      return "";
    }

    // If there is a saved full address, just use it
    if (trim(address.fullAddress)) {
      return trim(address.fullAddress);
    }

    return AddressUtil.generateFullAddressForManualAddress(address);
  },
  generateFullAddressForManualAddress: (address?: IAddressEntity): string => {
    if (!address) {
      return "";
    }

    // Concat address into 1 line based on user's input
    const part1 = [address.address1, address.address2, address.city, address.state]
      .filter((str) => trim(str))
      .join(", ");
    const part2 = address.zipCode;
    const addr = [part1, part2].filter((str) => trim(str)).join(" ");
    return addr;
  },
  isEmpty: (address?: IAddressEntity): boolean => {
    if (!address) {
      return true;
    }

    const hasMeaningfulContent = [
      address.address1,
      address.address2,
      address.country,
      address.state,
      address.zipCode,
    ].some((str) => !trim(str));

    return !hasMeaningfulContent;
  },

  base64Encode: (address: IExtendedAddressEntity): string => {
    const jsonString = JSON.stringify(address);
    const encodedString = base64Encode(encodeURIComponent(jsonString));
    return encodedString;
  },

  base64Decode: (encodedString: string): IExtendedAddressEntity => {
    const decodedString = decodeURIComponent(base64Decode(encodedString));
    const json = JSON.parse(decodedString);
    const address = {
      address1: toString(get(json, "address1")),
      address2: toString(get(json, "address2")),
      city: toString(get(json, "city")),
      country: toString(get(json, "country")),
      state: toString(get(json, "state")),
      zipCode: toString(get(json, "zipCode")),
      isManualAddress: Boolean(get(json, "isManualAddress")),
      fullAddress: toString(get(json, "fullAddress")),
      latitude: toNumber(get(json, "latitude")),
      longitude: toNumber(get(json, "longitude")),
    };

    return address;
  },
};
