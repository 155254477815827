import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import {
  ApplicationDocumentRelationUtil,
  IApplicationDocumentRelation,
} from "models/ApplicationDocumentRelation.model";
import { createDeepEqualSelector } from "store/utils";
import { serviceContainer } from "services/serviceContainer";
import cloneDeep from "lodash/cloneDeep";
import { batch } from "react-redux";
import {
  removeDocument,
  selectDocumentEntitiesByApplicationIdForContainerWithoutMasking,
  upsertDocument,
  upsertDocuments,
  withdrawDocument,
} from "store/domain-data/document/document";
import { DocumentStatus } from "models/DocumentStatus.model";
import { upsertDocumentTags } from "store/domain-data/document-tag/documentTag";
import { upsertDocumentTagRelations } from "store/domain-data/document-tag-relation/documentTagRelation";
import { upsertDocumentTypes } from "store/domain-data/document-type/documentType";
import { upsertDocumentTypeRelations } from "store/domain-data/document-type-relation/documentTypeRelation";

// Entity Adapter

// This is a join table between Application and Document
const applicationDocumentRelationAdapter = createEntityAdapter<IApplicationDocumentRelation>({
  selectId: (entity) => ApplicationDocumentRelationUtil.generateEntityKey(entity),
  sortComparer: (a, b) => {
    if (a.applicationId !== b.applicationId) {
      return a.applicationId - b.applicationId;
    }

    return a.documentName.localeCompare(b.documentName);
  },
});

// Action & Thunks

export const upsertApplicationDocumentRelation = createAction<IApplicationDocumentRelation>(
  "domainData/applicationDocumentRelation/upsertApplicationDocumentRelation"
);

export const upsertApplicationDocumentRelations = createAction<IApplicationDocumentRelation[]>(
  "domainData/applicationDocumentRelation/upsertApplicationDocumentRelations"
);

export const removeApplicationDocumentRelationsByApplicationIdAndDocumentName = createAction<
  Pick<IApplicationDocumentRelation, "applicationId" | "documentName">
>("domainData/applicationDocumentRelation/removeApplicationDocumentRelationsByApplicationIdAndDocumentName");

export const removeApplicationDocumentRelationsByApplicationId = createAction<number>(
  "domainData/applicationDocumentRelation/removeApplicationDocumentRelationsByApplicationId"
);

export const removeAllApplicationDocumentRelationsByApplicationIdAndContainerId = createAction<{
  applicationId: number;
  documentContainerId: number;
}>("domainData/applicationDocumentRelation/removeAllApplicationDocumentRelationsByApplicationIdAndContainerId");

export const addApplicationDocumentToUpload = createAsyncThunk(
  "domainData/applicationDocumentRelation/addApplicationDocumentToUpload",
  async (
    {
      applicationId,
      documentContainerId,
      file,
      fileName,
      documentCategoryId,
    }: {
      applicationId: number;
      documentContainerId?: number;
      file: File;
      fileName?: string;
      documentCategoryId: number;
    },
    thunkAPI
  ) => {
    const {
      document,
      applicationDocumentRelation,
    } = await serviceContainer.cradle.applicationDocumentService.createApplicationDocument({
      applicationId,
      file,
      fileName,
      documentContainerId,
      documentCategoryId,
    });

    batch(() => {
      thunkAPI.dispatch(upsertDocument(document));
      thunkAPI.dispatch(upsertApplicationDocumentRelation(applicationDocumentRelation));
    });

    return document;
  }
);

export const updateApplicationDocumentOrderInContainer = createAsyncThunk(
  "domainData/applicationDocumentRelation/updateApplicationDocumentOrderInContainer",
  async (args: { applicationId: number; documentContainerId: number; documentNames: string[] }, thunkAPI) => {
    // Update orders first local
    const baseDocumentRelations: IApplicationDocumentRelation[] = selectApplicationDocumentRelationsInApplicationContainer(
      thunkAPI.getState() as RootState,
      { applicationId: args.applicationId, documentContainerId: args.documentContainerId }
    );

    const documentRelations = cloneDeep(baseDocumentRelations);

    for (const documentName of args.documentNames) {
      const documentRelation = documentRelations.find(
        (relation) => relation.documentName === documentName && relation.applicationId === args.applicationId
      );
      if (!documentRelation) {
        continue;
      }
      documentRelation.order = args.documentNames.indexOf(documentName);
    }

    thunkAPI.dispatch(upsertApplicationDocumentRelations(documentRelations));

    // API call
    try {
      await serviceContainer.cradle.applicationDocumentService.updateApplicationDocumentsOrder({
        applicationId: args.applicationId,
        documentContainerId: args.documentContainerId,
        documentNames: args.documentNames,
      });
    } catch (e) {
      // Revert it back when fail
      thunkAPI.dispatch(upsertApplicationDocumentRelations(baseDocumentRelations));
      throw e;
    }

    return documentRelations;
  }
);

export const updateApplicationDocumentStatus = createAsyncThunk(
  "domainData/applicationDocumentRelation/updateApplicationDocumentStatus",
  async (
    args: {
      applicationId: number;
      documentName: string;
      status: DocumentStatus.Uploading | DocumentStatus.Error;
    },
    thunkAPI
  ) => {
    const document = await serviceContainer.cradle.applicationDocumentService.updateApplicationDocumentStatus(args);
    thunkAPI.dispatch(upsertDocument(document));
  }
);

export const removeApplicationDocumentByApplicationIdAndDocumentName = createAsyncThunk(
  "domainData/applicationDocumentRelation/removeApplicationDocumentByApplicationIdAndDocumentName",
  async ({ applicationId, documentName }: { applicationId: number; documentName: string }, thunkAPI) => {
    await serviceContainer.cradle.applicationDocumentService.removeApplicationDocument({
      applicationId,
      name: documentName,
    });

    batch(() => {
      thunkAPI.dispatch(removeDocument({ documentName }));
      thunkAPI.dispatch(
        removeApplicationDocumentRelationsByApplicationIdAndDocumentName({ applicationId, documentName })
      );
    });
  }
);

export const withdrawApplicationDocument = createAsyncThunk(
  "domainData/applicationDocumentRelation/withdrawApplicationDocumentByApplicationIdAndDocumentName",
  async ({ applicationId, documentName }: { applicationId: number; documentName: string }, thunkAPI) => {
    await serviceContainer.cradle.applicationDocumentService.withdrawApplicationDocument({
      applicationId,
      documentName: documentName,
    });
    thunkAPI.dispatch(withdrawDocument({ documentName }));
  }
);

export const removeApplicationDocumentsInContainer = createAsyncThunk(
  "domainData/applicationDocumentRelation/removeApplicationDocumentsInContainer",
  async ({ applicationId, documentContainerId }: { applicationId: number; documentContainerId: number }, thunkAPI) => {
    // Send the API call to remove the documents on the server.
    await serviceContainer.cradle.applicationDocumentService.removeApplicationDocumentsFromContainer({
      applicationId,
      documentContainerId,
    });

    // Remove the documents from the store if service run successfully.
    const state = thunkAPI.getState() as RootState;
    const documents = selectDocumentEntitiesByApplicationIdForContainerWithoutMasking(state, {
      applicationId,
      documentContainerId,
    });
    batch(() => {
      const documentNames = documents.map((document) => document.name);
      for (const documentName of documentNames) {
        thunkAPI.dispatch(
          removeAllApplicationDocumentRelationsByApplicationIdAndContainerId({ applicationId, documentContainerId })
        );
        thunkAPI.dispatch(removeDocument({ documentName }));
      }
    });
  }
);

export const fetchApplicationDocuments = createAsyncThunk(
  "domainData/applicationDocumentRelation/fetchApplicationDocuments",
  async (applicationId: number, thunkAPI) => {
    const {
      documentEntities,
      applicationDocumentRelations,
      documentTagEntities,
      documentTagRelations,
      documentTypeEntities,
      documentTypeRelations,
    } = await serviceContainer.cradle.applicationDocumentService.fetchDocumentsByApplication(applicationId);

    batch(() => {
      thunkAPI.dispatch(upsertDocuments(documentEntities));
      thunkAPI.dispatch(upsertApplicationDocumentRelations(applicationDocumentRelations));
      thunkAPI.dispatch(upsertDocumentTags(documentTagEntities));
      thunkAPI.dispatch(upsertDocumentTagRelations(documentTagRelations));
      thunkAPI.dispatch(upsertDocumentTypes(documentTypeEntities));
      thunkAPI.dispatch(upsertDocumentTypeRelations(documentTypeRelations));
    });
  }
);

export const fetchApplicationDocument = createAsyncThunk(
  "domainData/applicationDocumentRelation/fetchApplicationDocument",
  async (args: { applicationId: number; documentName: string }, thunkAPI) => {
    const {
      documentEntity,
      applicationDocumentRelation,
      documentTagEntities,
      documentTagRelations,
    } = await serviceContainer.cradle.applicationDocumentService.fetchDocumentByApplication({
      applicationId: args.applicationId,
      name: args.documentName,
    });

    batch(() => {
      thunkAPI.dispatch(upsertDocument(documentEntity));
      thunkAPI.dispatch(upsertApplicationDocumentRelation(applicationDocumentRelation));
      // TODO: tags are not used any more
      thunkAPI.dispatch(upsertDocumentTags(documentTagEntities));
      thunkAPI.dispatch(upsertDocumentTagRelations(documentTagRelations));
    });
  }
);

// Reducer

export const applicationDocumentRelationReducer = createReducer(
  applicationDocumentRelationAdapter.getInitialState(),
  (builder) =>
    builder
      .addCase(upsertApplicationDocumentRelation, (draft, action) => {
        applicationDocumentRelationAdapter.upsertOne(draft, action.payload);
      })
      .addCase(upsertApplicationDocumentRelations, (draft, action) => {
        applicationDocumentRelationAdapter.upsertMany(draft, action.payload);
      })
      .addCase(removeApplicationDocumentRelationsByApplicationIdAndDocumentName, (draft, action) => {
        const { applicationId, documentName } = action.payload;
        const keys = Object.keys(draft.entities);
        for (const key of keys) {
          const relation = draft.entities[key];
          if (relation?.applicationId === applicationId && relation?.documentName === documentName) {
            applicationDocumentRelationAdapter.removeOne(draft, key);
          }
        }
      })
      .addCase(removeApplicationDocumentRelationsByApplicationId, (draft, action) => {
        const applicationId = action.payload;
        const keys = Object.keys(draft.entities);
        for (const key of keys) {
          const relation = draft.entities[key];
          if (relation?.applicationId === applicationId) {
            applicationDocumentRelationAdapter.removeOne(draft, key);
          }
        }
      })
);

// Selectors

export const {
  selectById: selectApplicationDocumentRelationByKey,
  selectAll: selectAllApplicationDocumentRelations,
} = applicationDocumentRelationAdapter.getSelectors((state: RootState) => state.domainData.applicationDocumentRelation);

export const selectApplicationDocumentRelationsByApplicationId = createDeepEqualSelector(
  [selectAllApplicationDocumentRelations, (state: RootState, applicationId: number) => applicationId],
  (relations, applicationId) => relations.filter((relation) => relation.applicationId === applicationId)
);

export const selectApplicationDocumentRelationsInApplicationContainer = createDeepEqualSelector(
  [
    selectAllApplicationDocumentRelations,
    (state: RootState, { applicationId }: { applicationId: number }) => applicationId,
    (state: RootState, { documentContainerId }: { documentContainerId: number }) => documentContainerId,
  ],
  (relations, applicationId, documentContainerId) =>
    relations.filter(
      (relation) => relation.applicationId === applicationId && relation.documentContainerId === documentContainerId
    )
);

export const selectApplicationDocumentRelationsByDocumentName = createDeepEqualSelector(
  [selectAllApplicationDocumentRelations, (state: RootState, documentName: string) => documentName],
  (relations, documentName) => relations.filter((relation) => relation.documentName === documentName)
);
