import React from "react";
import FormikTextInput from "../FormikTextInput/FormikTextInput";
import FormikEmailInput from "../FormikEmailInput/FormikEmailInput";
import { FULL_CONTACT_PHONE_ENABLED } from "constants/configs";
import FormikContactNumberInput from "../FormikContactNumberInput/FormikContactNumberInput";
import { useTranslation } from "react-i18next";
import { ParticipantAccessLevel, rankAccessLevel } from "models/Participant.model";
import isEmpty from "lodash/isEmpty";
import { useStoreSelector } from "store/hooks";
import {
  selectCurrentParticipant,
  selectIsParticipantLinkedToUser,
} from "store/app-state/participant-buffer/participantBuffer";
import { selectMustInviteSelectedParticipantTypes } from "store/domain-data/participant-type/participantType";
import FlexBox from "components/FlexBox/FlexBox";

type PersonalDetailsProps = {};

const PersonalDetails: React.FC<PersonalDetailsProps> = () => {
  const { t } = useTranslation();
  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));
  const mustInviteSelectedParticipantsTypes = useStoreSelector((state) =>
    selectMustInviteSelectedParticipantTypes(state, { selectedParticipantTypes: currentParticipant.participantTypes })
  );
  const isParticipantLinkedToUser = useStoreSelector((state) => selectIsParticipantLinkedToUser(state));

  const isPhoneNumberRequired = React.useMemo(() => {
    return currentParticipant.participantTypes.some((participantType) => {
      return ["OwnerContact", "Agent", "InvoicePayer"].includes(participantType.name);
    });
  }, [currentParticipant]);

  const isEmailRequired = React.useMemo(() => {
    const hasUserAccess =
      rankAccessLevel(currentParticipant.accessLevel) >= rankAccessLevel(ParticipantAccessLevel.ReadOnly);
    return hasUserAccess || !isEmpty(mustInviteSelectedParticipantsTypes);
  }, [mustInviteSelectedParticipantsTypes, currentParticipant]);

  return (
    <React.Fragment>
      <FlexBox spacing={4}>
        <FormikTextInput label="First name" required={true} fieldName={"firstName"} />
        <FormikTextInput label="Last name" required={true} fieldName={"lastName"} />
      </FlexBox>

      <FlexBox spacing={4}>
        <FormikEmailInput
          label={t(`Email address`)}
          fieldName="email"
          required={isEmailRequired}
          disabled={isParticipantLinkedToUser}
        />
        {FULL_CONTACT_PHONE_ENABLED && (
          <FormikContactNumberInput
            label={t(`Contact number`)}
            contactNumberFieldName="phone"
            countryCodeFieldName={"phoneCountryCode"}
            required={isPhoneNumberRequired}
          />
        )}
        {!FULL_CONTACT_PHONE_ENABLED && (
          <FormikTextInput label={t(`Contact number`)} fieldName="phone" required={isPhoneNumberRequired} />
        )}
      </FlexBox>

      <FormikTextInput label={t(`Business name`)} fieldName="organisation" />
    </React.Fragment>
  );
};

export default PersonalDetails;
