export enum ApplicationListTabType {
  All = "All",
  Submitted = "Submitted",
  Draft = "Draft",
  Current = "Current",
  Archived = "Archived",
}

export const ApplicationListTabTypeUtil = {
  parseForProjectsList: (str: string) => {
    switch (str.toLowerCase()) {
      case ApplicationListTabType.All.toLowerCase():
        return ApplicationListTabType.All;
      case ApplicationListTabType.Archived.toLowerCase():
        return ApplicationListTabType.Archived;
      default:
        return null;
    }
  },
  parseForApplicationList: (str: string) => {
    switch (str.toLowerCase()) {
      case ApplicationListTabType.Draft.toLowerCase():
        return ApplicationListTabType.Draft;
      case ApplicationListTabType.Submitted.toLowerCase():
        return ApplicationListTabType.Submitted;
      case ApplicationListTabType.Archived.toLowerCase():
        return ApplicationListTabType.Archived;
      default:
        return null;
    }
  },
  parseForConsentList: (str: string) => {
    switch (str.toLowerCase()) {
      case ApplicationListTabType.Current.toLowerCase():
        return ApplicationListTabType.Current;
      case ApplicationListTabType.Archived.toLowerCase():
        return ApplicationListTabType.Archived;
      default:
        return null;
    }
  },
};
