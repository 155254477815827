import { IDocumentTagEntity } from "models/DocumentTag.model";
import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { IDocumentTagService } from "services/document-tag/DocumentTagService.types";
import {
  removeDocumentTagRelation,
  removeDocumentTagRelations,
  selectDocumentTagRelationByKey,
  selectDocumentTagRelationsByDocumentName,
  upsertDocumentTagRelation,
  upsertDocumentTagRelations,
} from "../document-tag-relation/documentTagRelation";
import { batch } from "react-redux";
import { DocumentTagRelationUtil } from "models/DocumentTagRelation.model";

// Entity Adapter

const documentTagAdapter = createEntityAdapter<IDocumentTagEntity>({
  selectId: (documentTags) => documentTags.id,
  sortComparer: (a, b) => a.displayOrder - b.displayOrder,
});

// Action and Thunks

export const fetchDocumentTagsForAuthenticatedUser = createAsyncThunk(
  "domainData/documentTag/fetchDocumentTagsForAuthenticatedUser",
  async (_, thunkAPI) => {
    const documentTags = await serviceContainer.cradle.documentTagService.fetchUserDocumentTagsForAuthenticatedUser();
    thunkAPI.dispatch(upsertDocumentTags(documentTags));
    return documentTags;
  }
);

export const fetchDocumentTagsByApplicationDocument = createAsyncThunk(
  "domainData/documentTag/fetchDocumentTagsByApplicationDocument",
  async (args: Parameters<IDocumentTagService["fetchDocumentTagsByApplicationDocument"]>[0], thunkAPI) => {
    const {
      documentTagEntities,
      documentTagRelations,
    } = await serviceContainer.cradle.documentTagService.fetchDocumentTagsByApplicationDocument(args);

    const legacyDocumentTagRelations = selectDocumentTagRelationsByDocumentName(
      thunkAPI.getState() as RootState,
      args.documentName
    );
    batch(() => {
      thunkAPI.dispatch(upsertDocumentTags(documentTagEntities));
      thunkAPI.dispatch(removeDocumentTagRelations(legacyDocumentTagRelations));
      thunkAPI.dispatch(upsertDocumentTagRelations(documentTagRelations));
    });
    return documentTagEntities;
  }
);

export const setDocumentTagsForApplicationDocument = createAsyncThunk(
  "domainData/documentTag/setDocumentTagsForApplicationDocument",
  async (args: Parameters<IDocumentTagService["setDocumentTagForApplicationDocument"]>[0], thunkAPI) => {
    const {
      documentTagEntities,
      documentTagRelations,
    } = await serviceContainer.cradle.documentTagService.setDocumentTagForApplicationDocument(args);

    const legacyDocumentTagRelations = selectDocumentTagRelationsByDocumentName(
      thunkAPI.getState() as RootState,
      args.documentName
    );
    batch(() => {
      thunkAPI.dispatch(upsertDocumentTags(documentTagEntities));
      thunkAPI.dispatch(removeDocumentTagRelations(legacyDocumentTagRelations));
      thunkAPI.dispatch(upsertDocumentTagRelations(documentTagRelations));
    });
    return documentTagEntities;
  }
);

export const removeDocumentTagFromApplicationDocument = createAsyncThunk(
  "domainData/documentTag/removeDocumentTagFromApplicationDocument",
  async (args: Parameters<IDocumentTagService["removeDocumentTagFromApplicationDocument"]>[0], thunkAPI) => {
    const relation = selectDocumentTagRelationByKey(
      thunkAPI.getState() as RootState,
      DocumentTagRelationUtil.generateKey({
        documentTagId: args.documentTagId,
        documentName: args.documentName,
      })
    );

    if (relation) {
      await serviceContainer.cradle.documentTagService.removeDocumentTagFromApplicationDocument(args);
      thunkAPI.dispatch(removeDocumentTagRelation(relation));
    }
  }
);

export const addDocumentTagToApplicationDocument = createAsyncThunk(
  "domainData/documentTag/addDocumentTagToApplicationDocument",
  async (args: Parameters<IDocumentTagService["addDocumentTagToApplicationDocument"]>[0], thunkAPI) => {
    const {
      documentTagEntity,
      documentTagRelation,
    } = await serviceContainer.cradle.documentTagService.addDocumentTagToApplicationDocument(args);

    batch(() => {
      thunkAPI.dispatch(upsertDocumentTag(documentTagEntity));
      thunkAPI.dispatch(upsertDocumentTagRelation(documentTagRelation));
    });
  }
);

export const upsertDocumentTags = createAction<IDocumentTagEntity[]>("domainData/documentTag/upsertDocumentTags");
export const upsertDocumentTag = createAction<IDocumentTagEntity>("domainData/documentTag/upsertDocumentTag");

// Reducer

export const documentTagReducer = createReducer(documentTagAdapter.getInitialState(), (builder) =>
  builder
    .addCase(upsertDocumentTags, (draft, action) => {
      documentTagAdapter.upsertMany(draft, action.payload);
    })
    .addCase(upsertDocumentTag, (draft, action) => {
      documentTagAdapter.upsertOne(draft, action.payload);
    })
);

// Selectors

export const { selectAll: selectAllDocumentTagEntities } = documentTagAdapter.getSelectors(
  (state: RootState) => state.domainData.documentTag
);
export const selectDocumentTagsForAuthenticatedUser = createDeepEqualSelector(
  [selectAllDocumentTagEntities, selectUserInfoEntityForAuthenticatedUser],
  (allDocumentTags, authenticatedUser) => {
    // TODO: We assume all tags belong to the authenticated user for now (while userId === 0)
    //   We either need to beg backend devs to include userId in the payload so we can parse the data without extra help,
    //   or we need to grab the userId from auth state and pass it to service layer
    return allDocumentTags.filter((tag) => tag.userId === 0 || tag.userId === authenticatedUser?.id);
  }
);
export const selectDocumentTagsByDocumentName = createDeepEqualSelector(
  [selectAllDocumentTagEntities, selectDocumentTagRelationsByDocumentName],
  (allDocumentTags, documentTagRelations) => {
    const documentTagIds = documentTagRelations.map((rel) => rel.documentTagId);
    return allDocumentTags.filter((tag) => documentTagIds.includes(tag.id));
  }
);
