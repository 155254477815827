export const generateApplicationFormPageURL = ({
  applicationId,
  breadcrumbId,
  breadcrumbStepId,
}: {
  applicationId: number;
  breadcrumbId?: number;
  breadcrumbStepId?: number;
}) => {
  if (!breadcrumbId) {
    return `/application-form/${applicationId}`;
  }

  if (!breadcrumbStepId) {
    return `/application-form/${applicationId}/breadcrumb/${breadcrumbId}`;
  }

  return `/application-form/${applicationId}/breadcrumb/${breadcrumbId}/step/${breadcrumbStepId}`;
};
