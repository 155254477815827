import React from "react";
import { Alert as MuiAlert, AlertProps, AlertTitle } from "@material-ui/lab";
import styled, { css } from "styled-components/macro";
import { iconColor } from "components/odl-v2/Alert/mixins/iconColor";
import { hideInPrintMixin } from "components/odl-v2/Button/mixins/hideInPrintMixin";

type Props = Pick<AlertProps, "action" | "severity"> & { header?: string };

const Alert: React.FC<Props> = React.forwardRef(({ header, severity, children, action, ...otherProps }, ref) => {
  return (
    <StyledAlert
      $hasHeader={Boolean(header)}
      action={action}
      severity={severity}
      ref={ref}
      data-testid={"Alert"}
      data-severity={severity}
      {...otherProps}
    >
      {header && <StyledAlertHeader data-testid={"AlertHeader"}>{header}</StyledAlertHeader>}
      <StyledChildContainer>{children}</StyledChildContainer>
    </StyledAlert>
  );
});

const StyledAlert = styled(MuiAlert)<{ $hasHeader: boolean }>(
  ({ theme, $hasHeader, severity }) => css`
    display: flex;
    align-items: flex-start;
    padding: 8px 12px;
    min-height: 48px;
    ${theme.mixins.flexGap("10px")};

    .MuiAlert-icon {
      margin: 0;
      padding: 0;
      height: 32px;
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 20px;
      ${iconColor(severity)};
    }

    .MuiAlert-message {
      flex-grow: 1;

      margin: 0;
      padding: 6px 0 0 0;
      min-height: 32px;
    }

    .MuiAlert-action {
      margin-right: 0;
      padding-left: 0;
      display: flex;
      align-items: flex-start;
    }

    ${$hasHeader &&
    css`
      .MuiAlert-message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        ${theme.mixins.flexGap("4px")};
      }
    `}

    ${hideInPrintMixin()}
  `
);

const StyledAlertHeader = styled(AlertTitle)(
  ({ theme }) => css`
    margin-bottom: 0;
    color: ${theme.palette.objective.dark.night};
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  `
);

const StyledChildContainer = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.objective.dark.night};
    line-height: 150%;
  `
);

export default Alert;
