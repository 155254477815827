import { Color } from "@material-ui/lab/Alert";
import { css } from "styled-components/macro";

/**
 * Mixin: get icon color based on severity
 *
 * @param severity
 */
export const iconColor = (severity: Color = "info") => {
  switch (severity) {
    case "info":
      return css`
        color: ${({ theme }) => theme.palette.objective.blue.main} !important;
      `;
    case "warning":
      return css`
        color: ${({ theme }) => theme.palette.objective.yellow.main} !important;
      `;
    case "error":
      return css`
        color: ${({ theme }) => theme.palette.objective.red.main} !important;
      `;
    case "success":
      return css`
        color: ${({ theme }) => theme.palette.objective.green.main} !important;
      `;
    default:
      return css`
        color: ${({ theme }) => theme.palette.objective.blue.main} !important;
      `;
  }
};
