import React from "react";
import { selectDocumentEntityByName } from "store/domain-data/document/document";
import { unwrapResult } from "@reduxjs/toolkit";
import { useStoreDispatch } from "store/hooks";
import { useToast } from "hooks/useToast";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import RemoveApplicationDocumentConfirmationDialog from "components/DocumentContainer/RemoveApplicationDocumentConfirmationDialog";
import { cancelUploadDocumentInQueue } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { removeApplicationDocumentByApplicationIdAndDocumentName } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { DocumentUtil } from "models/Document.model";

type Props = {
  applicationId: number;
  documentContainerId: number;
  onDeleteConfirmed?: () => void;
};

export const useRemoveApplicationDocument = ({ applicationId, documentContainerId, onDeleteConfirmed }: Props) => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();
  const store = useStore<RootState>();

  const [removingDocumentName, setRemovingDocumentName] = React.useState("");

  const handleCloseConfirmationDialog = React.useCallback(
    async (confirmed: boolean) => {
      if (confirmed) {
        try {
          const documentName = removingDocumentName;
          await dispatch(removeApplicationDocumentByApplicationIdAndDocumentName({ applicationId, documentName })).then(
            unwrapResult
          );
          if (onDeleteConfirmed) {
            onDeleteConfirmed();
          }
        } catch (e) {
          toastError(e);
        }
      }
      setRemovingDocumentName("");
    },
    [applicationId, removingDocumentName, dispatch, toastError, onDeleteConfirmed]
  );

  const handleRemoveApplicationDocument = React.useCallback(
    async (documentName: string) => {
      const document = selectDocumentEntityByName(store.getState(), documentName);
      if (!document) {
        return;
      }

      const isDocumentSettled = DocumentUtil.isSettled(document);
      if (!isDocumentSettled) {
        try {
          await dispatch(cancelUploadDocumentInQueue(documentName));
          await dispatch(removeApplicationDocumentByApplicationIdAndDocumentName({ applicationId, documentName })).then(
            unwrapResult
          );
          if (onDeleteConfirmed) {
            onDeleteConfirmed();
          }
        } catch (e) {
          toastError(e);
        }

        return;
      }

      setRemovingDocumentName(documentName);
    },
    [store, dispatch, applicationId, onDeleteConfirmed, toastError]
  );

  const removeApplicationDocumentConfirmationDialog = React.useMemo(() => {
    if (!removingDocumentName) {
      return null;
    }

    return (
      <RemoveApplicationDocumentConfirmationDialog
        documentContainerId={documentContainerId}
        documentName={removingDocumentName}
        onClose={handleCloseConfirmationDialog}
      />
    );
  }, [removingDocumentName, handleCloseConfirmationDialog, documentContainerId]);

  return {
    handleRemoveApplicationDocument,
    removeApplicationDocumentConfirmationDialog,
  };
};
