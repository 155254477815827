import React from "react";
import Guard from "components/Guard/Guard";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";

type Props = {
  displayName: string;
  isLocked: boolean;
  isClickable: boolean;
  onClick?: React.MouseEventHandler;
};

const ParticipantTypeTagStatic: React.FC<Props> = ({ displayName, isLocked, isClickable, onClick, ...otherProps }) => {
  return (
    <StyledParticipantTypeTagStatic
      onClick={onClick}
      $isClickable={isClickable}
      data-testid={"ParticipantTypeTag"}
      {...otherProps}
    >
      <div>{displayName}</div>
      <Guard condition={isLocked}>
        <FontIcon name="icon-lock" />
      </Guard>
    </StyledParticipantTypeTagStatic>
  );
};

const StyledParticipantTypeTagStatic = styled.button<{
  $isClickable: boolean;
}>(
  ({ theme, $isClickable }) => css`
    padding: 10px 20px;
    background: ${theme.palette.objective.green.dark};
    color: ${theme.palette.objective.light.white};
    border-radius: 39px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: ${$isClickable ? "pointer" : "default"};

    ${theme.mixins.flexGap("8px")};
  `
);

export default ParticipantTypeTagStatic;
