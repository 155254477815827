export enum ApplicationActionItemType {
  SaveAsTemplate = "SaveAsTemplate",
  CreateChildApplication = "CreateChildApplication",
  PrintRFIs = "PrintRFIs",
  BookInspection = "BookInspection",
  Unknown = "Unknown",
}

export type IBasicApplicationActionItem = {
  displayName: string;
  itemType: ApplicationActionItemType;
  icon?: React.ReactNode;
  displayOrder: number;
};

export type ICreateChildActionItem = Omit<IBasicApplicationActionItem, "itemType"> & {
  itemType: ApplicationActionItemType.CreateChildApplication;
  formId: number;
};

export type IBookInspectionActionItem = Omit<IBasicApplicationActionItem, "itemType"> & {
  itemType: ApplicationActionItemType.BookInspection;
  to: string;
  target: string;
};

export type IApplicationActionItemData =
  | IBasicApplicationActionItem
  | ICreateChildActionItem
  | IBookInspectionActionItem;

export const ApplicationActionItemTypeUtil = {
  parse: (itemType: string): ApplicationActionItemType => {
    switch (itemType) {
      case "SAVE_TEMPLATE":
        return ApplicationActionItemType.SaveAsTemplate;
      case "CREATE_CHILD_APPLICATION":
        return ApplicationActionItemType.CreateChildApplication;
      case "PRINT_RFIS":
        return ApplicationActionItemType.PrintRFIs;
      case "BOOK_INSPECTION":
        return ApplicationActionItemType.BookInspection;
      default:
        return ApplicationActionItemType.Unknown;
    }
  },
};
