import React from "react";
import { PUBLIC_POSTHOG_HOST, PUBLIC_POSTHOG_KEY } from "constants/configs";
import { PostHogProvider as OriginalPostHogProvider } from "posthog-js/react";

type Props = {
  children: React.ReactNode;
};

const postHogOptions = {
  api_host: PUBLIC_POSTHOG_HOST,
  autocapture: false,
};

const PostHogProvider: React.FC<Props> = ({ children }) => {
  return (
    <OriginalPostHogProvider apiKey={PUBLIC_POSTHOG_KEY} options={postHogOptions}>
      {children}
    </OriginalPostHogProvider>
  );
};

export default PostHogProvider;
