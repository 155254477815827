export const ODL_ICONS: Record<string, string> = {
  ACCESS_POINT_NETWORK: "icon-access-point-network",
  ACCOUNT: "icon-account",
  ACCOUNT_ALERT: "icon-account-alert",
  ACCOUNT_BOX: "icon-account-box",
  ACCOUNT_BOX_OUTLINE: "icon-account-box-outline",
  ACCOUNT_CARD_DETAILS: "icon-account-card-details",
  ACCOUNT_CHECK: "icon-account-check",
  ACCOUNT_CIRCLE: "icon-account-circle",
  ACCOUNT_CONVERT: "icon-account-convert",
  ACCOUNT_EDIT: "icon-account-edit",
  ACCOUNT_GROUP: "icon-account-group",
  ACCOUNT_KEY: "icon-account-key",
  ACCOUNT_LOCATION: "icon-account-location",
  ACCOUNT_MINUS: "icon-account-minus",
  ACCOUNT_MULTIPLE: "icon-account-multiple",
  ACCOUNT_MULTIPLE_CHECK: "icon-account-multiple-check",
  ACCOUNT_MULTIPLE_MINUS: "icon-account-multiple-minus",
  ACCOUNT_MULTIPLE_OUTLINE: "icon-account-multiple-outline",
  ACCOUNT_MULTIPLE_PLUS: "icon-account-multiple-plus",
  ACCOUNT_MULTIPLE_REMOVE: "icon-account-multiple-remove",
  ACCOUNT_OFF: "icon-account-off",
  ACCOUNT_OUTLINE: "icon-account-outline",
  ACCOUNT_PLUS: "icon-account-plus",
  ACCOUNT_REMOVE: "icon-account-remove",
  ACCOUNT_SEARCH: "icon-account-search",
  ACCOUNT_SETTINGS: "icon-account-settings",
  ACCOUNT_SETTINGS_VARIANT: "icon-account-settings-variant",
  ACCOUNT_STAR: "icon-account-star",
  ACCOUNT_STAR_VARIANT: "icon-account-star-variant",
  ACCOUNT_SWITCH: "icon-account-switch",
  ACCOUNT_TRANSFER: "icon-account-transfer",
  ACCOUNT_TRANSFER_TO: "icon-account-transfer-to",
  ADD_CORPORATEFLAG_OUTLINE: "icon-add-corporateflag-outline",
  ADD_CORPORATEFLAG_SOILD: "icon-add-corporateflag-soild",
  ADJUST: "icon-adjust",
  ALARM: "icon-alarm",
  ALARM_COMPLETED: "icon-alarm-completed",
  ALARM_MULTIPLE: "icon-alarm-multiple",
  ALARM_OFF: "icon-alarm-off",
  ALARM_PLUS: "icon-alarm-plus",
  ALARM_SNOOZE: "icon-alarm-snooze",
  ALARM_SUSPENDED: "icon-alarm-suspended",
  ALBUM: "icon-album",
  ALERT: "icon-alert",
  ALERT_BOX: "icon-alert-box",
  ALERT_CIRCLE: "icon-alert-circle",
  ALERT_CIRCLE_OUTLINE: "icon-alert-circle-outline",
  ALERT_OCTAGON: "icon-alert-octagon",
  ALERT_OUTLINE: "icon-alert-outline",
  ALIAS: "icon-alias",
  ALPHABETICAL: "icon-alphabetical",
  ALTIMETER: "icon-altimeter",
  AMAZON_CLOUDDRIVE: "icon-amazon-clouddrive",
  ANDROID_STUDIO: "icon-android-studio",
  ANGULAR: "icon-angular",
  ANIMATION: "icon-animation",
  APPLE_KEYBOARD_SHIFT: "icon-apple-keyboard-shift",
  APPLE_MOBILEME: "icon-apple-mobileme",
  APPLE_SAFARI: "icon-apple-safari",
  APPLICATION: "icon-application",
  APPS: "icon-apps",
  ARCHIVE: "icon-archive",
  ARRANGE_BRING_FORWARD: "icon-arrange-bring-forward",
  ARRANGE_BRING_TO_FRONT: "icon-arrange-bring-to-front",
  ARRANGE_SEND_BACKWARD: "icon-arrange-send-backward",
  ARRANGE_SEND_TO_BACK: "icon-arrange-send-to-back",
  ARROW_ALL: "icon-arrow-all",
  ARROW_BOTTOM_LEFT: "icon-arrow-bottom-left",
  ARROW_BOTTOM_RIGHT: "icon-arrow-bottom-right",
  ARROW_COMPRESS: "icon-arrow-compress",
  ARROW_COMPRESS_ALL: "icon-arrow-compress-all",
  ARROW_DOWN: "icon-arrow-down",
  ARROW_DOWN_BOLD: "icon-arrow-down-bold",
  ARROW_DOWN_BOLD_CIRCLE: "icon-arrow-down-bold-circle",
  ARROW_DOWN_BOLD_CIRCLE_OUTLINE: "icon-arrow-down-bold-circle-outline",
  ARROW_DOWN_DROP_CIRCLE: "icon-arrow-down-drop-circle",
  ARROW_DOWN_DROP_CIRCLE_OUTLINE: "icon-arrow-down-drop-circle-outline",
  ARROW_EXPAND: "icon-arrow-expand",
  ARROW_EXPAND_ALL: "icon-arrow-expand-all",
  ARROW_LEFT: "icon-arrow-left",
  ARROW_LEFT_BOLD: "icon-arrow-left-bold",
  ARROW_LEFT_BOLD_CIRCLE: "icon-arrow-left-bold-circle",
  ARROW_LEFT_BOLD_CIRCLE_OUTLINE: "icon-arrow-left-bold-circle-outline",
  ARROW_LEFT_DROP_CIRCLE: "icon-arrow-left-drop-circle",
  ARROW_LEFT_DROP_CIRCLE_OUTLINE: "icon-arrow-left-drop-circle-outline",
  ARROW_RIGHT: "icon-arrow-right",
  ARROW_RIGHT_BOLD: "icon-arrow-right-bold",
  ARROW_RIGHT_BOLD_CIRCLE: "icon-arrow-right-bold-circle",
  ARROW_RIGHT_BOLD_CIRCLE_OUTLINE: "icon-arrow-right-bold-circle-outline",
  ARROW_RIGHT_DROP_CIRCLE: "icon-arrow-right-drop-circle",
  ARROW_RIGHT_DROP_CIRCLE_OUTLINE: "icon-arrow-right-drop-circle-outline",
  ARROW_TOP_LEFT: "icon-arrow-top-left",
  ARROW_TOP_RIGHT: "icon-arrow-top-right",
  ARROW_UP: "icon-arrow-up",
  ARROW_UP_BOLD: "icon-arrow-up-bold",
  ARROW_UP_BOLD_CIRCLE: "icon-arrow-up-bold-circle",
  ARROW_UP_BOLD_CIRCLE_OUTLINE: "icon-arrow-up-bold-circle-outline",
  ARROW_UP_DROP_CIRCLE: "icon-arrow-up-drop-circle",
  ARROW_UP_DROP_CIRCLE_OUTLINE: "icon-arrow-up-drop-circle-outline",
  ASSIGNTO: "icon-assignto",
  ASSISTANT: "icon-assistant",
  ASTERISK: "icon-asterisk",
  AT: "icon-at",
  ATTACHMENT: "icon-attachment",
  AUDIOBOOK: "icon-audiobook",
  AUTORENEW: "icon-autorenew",
  AUTO_FIX: "icon-auto-fix",
  AUTO_UPLOAD: "icon-auto-upload",
  AV_TIMER: "icon-av-timer",
  AWAITING_APPROVAL: "icon-awaiting-approval",
  AWAITING_PUBLICATION: "icon-awaiting-publication",
  AWAITING_SUBMITTED: "icon-awaiting-submitted",
  AZ_ASCENDING: "icon-az-ascending",
  AZ_DESCENDING: "icon-az-descending",
  BACK: "icon-back",
  BACKBURGER: "icon-backburger",
  BACKSPACE: "icon-backspace",
  BACKUP_RESTORE: "icon-backup-restore",
  BAN: "icon-ban",
  BANDCAMP: "icon-bandcamp",
  BANK: "icon-bank",
  BARCODE: "icon-barcode",
  BARCODE_SCAN: "icon-barcode-scan",
  BELL: "icon-bell",
  BELL_OFF: "icon-bell-off",
  BELL_OUTLINE: "icon-bell-outline",
  BELL_PLUS: "icon-bell-plus",
  BELL_RING: "icon-bell-ring",
  BELL_RING_OUTLINE: "icon-bell-ring-outline",
  BELL_SLEEP: "icon-bell-sleep",
  BIBLE: "icon-bible",
  BINOCULARS: "icon-binoculars",
  BLADES_IN: "icon-blades-in",
  BLADES_OUT: "icon-blades-out",
  BLINDS: "icon-blinds",
  BOOK: "icon-book",
  BOOKMARK: "icon-bookmark",
  BOOKMARK_CHECK: "icon-bookmark-check",
  BOOKMARK_MUSIC: "icon-bookmark-music",
  BOOKMARK_OUTLINE: "icon-bookmark-outline",
  BOOKMARK_PLUS: "icon-bookmark-plus",
  BOOKMARK_PLUS_OUTLINE: "icon-bookmark-plus-outline",
  BOOKMARK_REMOVE: "icon-bookmark-remove",
  BOOK_MINUS: "icon-book-minus",
  BOOK_MULTIPLE: "icon-book-multiple",
  BOOK_MULTIPLE_VARIANT: "icon-book-multiple-variant",
  BOOK_OPEN: "icon-book-open",
  BOOK_OPEN_VARIANT: "icon-book-open-variant",
  BOOK_PLUS: "icon-book-plus",
  BOOK_VARIANT: "icon-book-variant",
  BORDER_ALL: "icon-border-all",
  BORDER_BOTTOM: "icon-border-bottom",
  BORDER_COLOR: "icon-border-color",
  BORDER_HORIZONTAL: "icon-border-horizontal",
  BORDER_INSIDE: "icon-border-inside",
  BORDER_LEFT: "icon-border-left",
  BORDER_NONE: "icon-border-none",
  BORDER_OUTSIDE: "icon-border-outside",
  BORDER_RIGHT: "icon-border-right",
  BORDER_STYLE: "icon-border-style",
  BORDER_TOP: "icon-border-top",
  BORDER_VERTICAL: "icon-border-vertical",
  BOX_SHADOW: "icon-box-shadow",
  BRIEFCASE: "icon-briefcase",
  BRIEFCASE_CHECK: "icon-briefcase-check",
  BRIEFCASE_DOWNLOAD: "icon-briefcase-download",
  BRIEFCASE_UPLOAD: "icon-briefcase-upload",
  BRIGHTNESS_AUTO: "icon-brightness-auto",
  BUFFER: "icon-buffer",
  BULLETIN_BOARD: "icon-bulletin-board",
  BULLHORN: "icon-bullhorn",
  BULLSEYE: "icon-bullseye",
  BURST_MODE: "icon-burst-mode",
  CACHED: "icon-cached",
  CALCULATOR: "icon-calculator",
  CALENDAR: "icon-calendar",
  CALENDAR_BLANK: "icon-calendar-blank",
  CALENDAR_CHECK: "icon-calendar-check",
  CALENDAR_CLOCK: "icon-calendar-clock",
  CALENDAR_MULTIPLE: "icon-calendar-multiple",
  CALENDAR_MULTIPLE_CHECK: "icon-calendar-multiple-check",
  CALENDAR_PLUS: "icon-calendar-plus",
  CALENDAR_QUESTION: "icon-calendar-question",
  CALENDAR_RANGE: "icon-calendar-range",
  CALENDAR_REMOVE: "icon-calendar-remove",
  CALENDAR_TEXT: "icon-calendar-text",
  CALENDAR_TODAY: "icon-calendar-today",
  CALL_MADE: "icon-call-made",
  CALL_MISSED: "icon-call-missed",
  CALL_RECEIVED: "icon-call-received",
  CAMCORDER: "icon-camcorder",
  CAMCORDER_BOX: "icon-camcorder-box",
  CAMCORDER_BOX_OFF: "icon-camcorder-box-off",
  CAMCORDER_OFF: "icon-camcorder-off",
  CAMERA: "icon-camera",
  CAMERA_BURST: "icon-camera-burst",
  CAMERA_FRONT: "icon-camera-front",
  CAMERA_FRONT_VARIANT: "icon-camera-front-variant",
  CAMERA_OFF: "icon-camera-off",
  CAMERA_PARTY_MODE: "icon-camera-party-mode",
  CAMERA_SWITCH: "icon-camera-switch",
  CANCELLED: "icon-cancelled",
  CARDS: "icon-cards",
  CARDS_OUTLINE: "icon-cards-outline",
  CASH: "icon-cash",
  CASH_100: "icon-cash-100",
  CASH_MULTIPLE: "icon-cash-multiple",
  CASH_USD: "icon-cash-usd",
  CAST: "icon-cast",
  CAST_CONNECTED: "icon-cast-connected",
  CELLPHONE: "icon-cellphone",
  CELLPHONE_ANDROID: "icon-cellphone-android",
  CELLPHONE_IPHONE: "icon-cellphone-iphone",
  CELLPHONE_LINK: "icon-cellphone-link",
  CELLPHONE_LINK_OFF: "icon-cellphone-link-off",
  CELLPHONE_SETTINGS: "icon-cellphone-settings",
  CERTIFICATE: "icon-certificate",
  CHART_ARC: "icon-chart-arc",
  CHART_AREASPLINE: "icon-chart-areaspline",
  CHART_BAR: "icon-chart-bar",
  CHART_BUBBLE: "icon-chart-bubble",
  CHART_GANTT: "icon-chart-gantt",
  CHART_HISTOGRAM: "icon-chart-histogram",
  CHART_LINE: "icon-chart-line",
  CHART_PIE: "icon-chart-pie",
  CHART_SCATTERPLOT_HEXBIN: "icon-chart-scatterplot-hexbin",
  CHART_TIMELINE: "icon-chart-timeline",
  CHECK: "icon-check",
  CHECKBOX_BLANK: "icon-checkbox-blank",
  CHECKBOX_BLANK_CIRCLE: "icon-checkbox-blank-circle",
  CHECKBOX_BLANK_CIRCLE_OUTLINE: "icon-checkbox-blank-circle-outline",
  CHECKBOX_BLANK_OUTLINE: "icon-checkbox-blank-outline",
  CHECKBOX_MARKED: "icon-checkbox-marked",
  CHECKBOX_MARKED_CIRCLE: "icon-checkbox-marked-circle",
  CHECKBOX_MARKED_CIRCLE_OUTLINE: "icon-checkbox-marked-circle-outline",
  CHECKBOX_MARKED_OUTLINE: "icon-checkbox-marked-outline",
  CHECKBOX_MULTIPLE_BLANK: "icon-checkbox-multiple-blank",
  CHECKBOX_MULTIPLE_BLANK_CIRCLE: "icon-checkbox-multiple-blank-circle",
  CHECKBOX_MULTIPLE_BLANK_CIRCLE_OUTLINE: "icon-checkbox-multiple-blank-circle-outline",
  CHECKBOX_MULTIPLE_BLANK_OUTLINE: "icon-checkbox-multiple-blank-outline",
  CHECKBOX_MULTIPLE_MARKED: "icon-checkbox-multiple-marked",
  CHECKBOX_MULTIPLE_MARKED_CIRCLE: "icon-checkbox-multiple-marked-circle",
  CHECKBOX_MULTIPLE_MARKED_CIRCLE_OUTLINE: "icon-checkbox-multiple-marked-circle-outline",
  CHECKBOX_MULTIPLE_MARKED_OUTLINE: "icon-checkbox-multiple-marked-outline",
  CHECKBOX_OFF: "icon-checkbox-off",
  CHECKERBOARD: "icon-checkerboard",
  CHECK_ALL: "icon-check-all",
  CHECK_CIRCLE: "icon-check-circle",
  CHECK_CIRCLE_OUTLINE: "icon-check-circle-outline",
  CHEVRON_DOUBLE_DOWN: "icon-chevron-double-down",
  CHEVRON_DOUBLE_LEFT: "icon-chevron-double-left",
  CHEVRON_DOUBLE_RIGHT: "icon-chevron-double-right",
  CHEVRON_DOUBLE_UP: "icon-chevron-double-up",
  CHEVRON_DOWN: "icon-chevron-down",
  CHEVRON_LEFT: "icon-chevron-left",
  CHEVRON_RIGHT: "icon-chevron-right",
  CHEVRON_UP: "icon-chevron-up",
  CHIP: "icon-chip",
  CIRCLE_DOTS: "icon-circle-dots",
  CISCO_WEBEX: "icon-cisco-webex",
  CLIPBOARD: "icon-clipboard",
  CLIPBOARD_ACCOUNT: "icon-clipboard-account",
  CLIPBOARD_ALERT: "icon-clipboard-alert",
  CLIPBOARD_ARROW_DOWN: "icon-clipboard-arrow-down",
  CLIPBOARD_ARROW_LEFT: "icon-clipboard-arrow-left",
  CLIPBOARD_CHECK: "icon-clipboard-check",
  CLIPBOARD_OUTLINE: "icon-clipboard-outline",
  CLIPBOARD_TEXT: "icon-clipboard-text",
  CLOCK: "icon-clock",
  CLOCK_ALERT: "icon-clock-alert",
  CLOCK_END: "icon-clock-end",
  CLOCK_FAST: "icon-clock-fast",
  CLOCK_IN: "icon-clock-in",
  CLOCK_OUT: "icon-clock-out",
  CLOCK_START: "icon-clock-start",
  CLOSE: "icon-close",
  CLOSED_CAPTION: "icon-closed-caption",
  CLOSE_BOX: "icon-close-box",
  CLOSE_BOX_OUTLINE: "icon-close-box-outline",
  CLOSE_CIRCLE: "icon-close-circle",
  CLOSE_CIRCLE_OUTLINE: "icon-close-circle-outline",
  CLOSE_NETWORK: "icon-close-network",
  CLOSE_OCTAGON: "icon-close-octagon",
  CLOSE_OCTAGON_OUTLINE: "icon-close-octagon-outline",
  CLOUD: "icon-cloud",
  CLOUD_CHECK: "icon-cloud-check",
  CLOUD_CIRCLE: "icon-cloud-circle",
  CLOUD_DOWNLOAD: "icon-cloud-download",
  CLOUD_OUTLINE: "icon-cloud-outline",
  CLOUD_OUTLINE_OFF: "icon-cloud-outline-off",
  CLOUD_PRINT: "icon-cloud-print",
  CLOUD_PRINT_OUTLINE: "icon-cloud-print-outline",
  CLOUD_SYNC: "icon-cloud-sync",
  CLOUD_UPLOAD: "icon-cloud-upload",
  CODE_ARRAY: "icon-code-array",
  CODE_BRACES: "icon-code-braces",
  CODE_BRACKETS: "icon-code-brackets",
  CODE_EQUAL: "icon-code-equal",
  CODE_GREATER_THAN: "icon-code-greater-than",
  CODE_GREATER_THAN_OR_EQUAL: "icon-code-greater-than-or-equal",
  CODE_LESS_THAN: "icon-code-less-than",
  CODE_LESS_THAN_OR_EQUAL: "icon-code-less-than-or-equal",
  CODE_NOT_EQUAL: "icon-code-not-equal",
  CODE_NOT_EQUAL_VARIANT: "icon-code-not-equal-variant",
  CODE_PARENTHESES: "icon-code-parentheses",
  CODE_STRING: "icon-code-string",
  CODE_TAGS: "icon-code-tags",
  CODE_TAGS_CHECK: "icon-code-tags-check",
  COIN: "icon-coin",
  COINS: "icon-coins",
  COLUMN_DRAG: "icon-column-drag",
  COMMENT: "icon-comment",
  COMMENT_ACCOUNT: "icon-comment-account",
  COMMENT_ACCOUNT_OUTLINE: "icon-comment-account-outline",
  COMMENT_ALERT: "icon-comment-alert",
  COMMENT_ALERT_OUTLINE: "icon-comment-alert-outline",
  COMMENT_CHECK: "icon-comment-check",
  COMMENT_CHECK_OUTLINE: "icon-comment-check-outline",
  COMMENT_MULTIPLE_OUTLINE: "icon-comment-multiple-outline",
  COMMENT_OUTLINE: "icon-comment-outline",
  COMMENT_PLUS_OUTLINE: "icon-comment-plus-outline",
  COMMENT_PROCESSING: "icon-comment-processing",
  COMMENT_PROCESSING_OUTLINE: "icon-comment-processing-outline",
  COMMENT_QUESTION_OUTLINE: "icon-comment-question-outline",
  COMMENT_REMOVE_OUTLINE: "icon-comment-remove-outline",
  COMMENT_TEXT: "icon-comment-text",
  COMMENT_TEXT_OUTLINE: "icon-comment-text-outline",
  COMPASS: "icon-compass",
  COMPASS_OUTLINE: "icon-compass-outline",
  CONSOLE: "icon-console",
  CONTACT_MAIL: "icon-contact-mail",
  CONTENT_COPY: "icon-content-copy",
  CONTENT_CUT: "icon-content-cut",
  CONTENT_DUPLICATE: "icon-content-duplicate",
  CONTENT_PASTE: "icon-content-paste",
  CONTENT_SAVE: "icon-content-save",
  CONTENT_SAVE_ALL: "icon-content-save-all",
  CONTENT_SAVE_SETTINGS: "icon-content-save-settings",
  CONTRAST_BOX: "icon-contrast-box",
  CONTRAST_CIRCLE: "icon-contrast-circle",
  COOKIE: "icon-cookie",
  COPYRIGHT: "icon-copyright",
  COUNTER: "icon-counter",
  CREDIT_CARD: "icon-credit-card",
  CREDIT_CARD_MULTIPLE: "icon-credit-card-multiple",
  CREDIT_CARD_OFF: "icon-credit-card-off",
  CREDIT_CARD_PLUS: "icon-credit-card-plus",
  CREDIT_CARD_SCAN: "icon-credit-card-scan",
  CROP: "icon-crop",
  CROP_FREE: "icon-crop-free",
  CROP_LANDSCAPE: "icon-crop-landscape",
  CROP_PORTRAIT: "icon-crop-portrait",
  CROP_SQUARE: "icon-crop-square",
  CROSSHAIRS: "icon-crosshairs",
  CROSSHAIRS_GPS: "icon-crosshairs-gps",
  CUBE: "icon-cube",
  CUBE_OUTLINE: "icon-cube-outline",
  CUBE_SEND: "icon-cube-send",
  CUBE_UNFOLDED: "icon-cube-unfolded",
  CURRENCY_USD: "icon-currency-usd",
  CURRENCY_USD_OFF: "icon-currency-usd-off",
  CURSOR_DEFAULT: "icon-cursor-default",
  CURSOR_DEFAULT_OUTLINE: "icon-cursor-default-outline",
  CURSOR_MOVE: "icon-cursor-move",
  CURSOR_POINTER: "icon-cursor-pointer",
  CURSOR_TEXT: "icon-cursor-text",
  DATABASE: "icon-database",
  DATABASE_MINUS: "icon-database-minus",
  DATABASE_PLUS: "icon-database-plus",
  DEBUG_STEP_INTO: "icon-debug-step-into",
  DEBUG_STEP_OUT: "icon-debug-step-out",
  DEBUG_STEP_OVER: "icon-debug-step-over",
  DECIMAL_DECREASE: "icon-decimal-decrease",
  DECIMAL_INCREASE: "icon-decimal-increase",
  DELEGATE: "icon-delegate",
  DELETE: "icon-delete",
  DELETE_CIRCLE: "icon-delete-circle",
  DELETE_FOREVER: "icon-delete-forever",
  DELETE_OFF: "icon-delete-off",
  DELETE_OUTLINE: "icon-delete-outline",
  DELETE_SWEEP: "icon-delete-sweep",
  DESKTOP_MAC: "icon-desktop-mac",
  DESKTOP_TOWER: "icon-desktop-tower",
  DETAILS: "icon-details",
  DEVELOPER_BOARD: "icon-developer-board",
  DIALPAD: "icon-dialpad",
  DICTIONARY: "icon-dictionary",
  DIRECTIONS: "icon-directions",
  DIRECTIONS_FORK: "icon-directions-fork",
  DNS: "icon-dns",
  DOCUMENT_CODE: "icon-document-code",
  DOTS_HORIZONTAL: "icon-dots-horizontal",
  DOTS_VERTICAL: "icon-dots-vertical",
  DOUBAN: "icon-douban",
  DOWNLOAD: "icon-download",
  DOWNLOAD_UNAVAILABLE: "icon-download-unavailable",
  DO_NOT_DISTURB: "icon-do-not-disturb",
  DO_NOT_DISTURB_OFF: "icon-do-not-disturb-off",
  DRAG: "icon-drag",
  DRAG_HORIZONTAL: "icon-drag-horizontal",
  DRAG_VERTICAL: "icon-drag-vertical",
  DRAWING: "icon-drawing",
  DRAWING_BOX: "icon-drawing-box",
  EARTH: "icon-earth",
  EARTH_OFF: "icon-earth-off",
  ELECTRONIC_OBJECT: "icon-electronic-object",
  ELEVATION_DECLINE: "icon-elevation-decline",
  ELEVATION_RISE: "icon-elevation-rise",
  ELEVATOR: "icon-elevator",
  EMAIL: "icon-email",
  EMAIL_OPEN: "icon-email-open",
  EMAIL_OPEN_OUTLINE: "icon-email-open-outline",
  EMAIL_OUTLINE: "icon-email-outline",
  EMAIL_OUTLINE_SENT: "icon-email-outline-sent",
  EMAIL_SECURE: "icon-email-secure",
  EMAIL_VARIANT: "icon-email-variant",
  EMBARGO: "icon-embargo",
  EMBARGO_VARIANT: "icon-embargo-variant",
  ENGAGEMENT_PORTAL: "icon-engagement-portal",
  ENGINE: "icon-engine",
  ENGINE_OUTLINE: "icon-engine-outline",
  ENTERPRISE: "icon-enterprise",
  EQUAL: "icon-equal",
  EQUAL_BOX: "icon-equal-box",
  ERASER: "icon-eraser",
  ERASER_VARIANT: "icon-eraser-variant",
  EXIT_TO_APP: "icon-exit-to-app",
  EXPORT: "icon-export",
  EXPORT_ARROW: "icon-export-arrow",
  EXPORT_DELETE: "icon-export-delete",
  EYE: "icon-eye",
  EYEDROPPER: "icon-eyedropper",
  EYEDROPPER_VARIANT: "icon-eyedropper-variant",
  EYE_OFF: "icon-eye-off",
  FAX: "icon-fax",
  FILE: "icon-file",
  FILE_ADD: "icon-file-add",
  FILE_CHART: "icon-file-chart",
  FILE_CHECK: "icon-file-check",
  FILE_CLOUD: "icon-file-cloud",
  FILE_DELIMITED: "icon-file-delimited",
  FILE_DOCUMENT: "icon-file-document",
  FILE_DOCUMENT_BOX: "icon-file-document-box",
  FILE_EXCEL: "icon-file-excel",
  FILE_EXCEL_BOX: "icon-file-excel-box",
  FILE_EXPORT: "icon-file-export",
  FILE_FIND: "icon-file-find",
  FILE_HIDDEN: "icon-file-hidden",
  FILE_IMAGE: "icon-file-image",
  FILE_IMPORT: "icon-file-import",
  FILE_LOCK: "icon-file-lock",
  FILE_MULTIPLE: "icon-file-multiple",
  FILE_MUSIC: "icon-file-music",
  FILE_OUTLINE: "icon-file-outline",
  FILE_PART: "icon-file-part",
  FILE_PDF: "icon-file-pdf",
  FILE_PDF_BOX: "icon-file-pdf-box",
  FILE_POWERPOINT: "icon-file-powerpoint",
  FILE_POWERPOINT_BOX: "icon-file-powerpoint-box",
  FILE_PRESENTATION_BOX: "icon-file-presentation-box",
  FILE_REQUEST: "icon-file-request",
  FILE_REQUEST_FILE: "icon-file-request-file",
  FILE_REQUEST_FILE2: "icon-file-request-file2",
  FILE_RESTORE: "icon-file-restore",
  FILE_SEND: "icon-file-send",
  FILE_STOWED: "icon-file-stowed",
  FILE_STOWED_DETAILS: "icon-file-stowed-details",
  FILE_STOWED_SUITCASE: "icon-file-stowed-suitcase",
  FILE_TRANSFER_FILE: "icon-file-transfer-file",
  FILE_TREE: "icon-file-tree",
  FILE_VIDEO: "icon-file-video",
  FILE_WORD: "icon-file-word",
  FILE_XML: "icon-file-xml",
  FILM: "icon-film",
  FILMSTRIP: "icon-filmstrip",
  FILMSTRIP_OFF: "icon-filmstrip-off",
  FILTER: "icon-filter",
  FILTER_OUTLINE: "icon-filter-outline",
  FILTER_REMOVE: "icon-filter-remove",
  FILTER_REMOVE_OUTLINE: "icon-filter-remove-outline",
  FILTER_VARIANT: "icon-filter-variant",
  FINGERPRINT: "icon-fingerprint",
  FIRE: "icon-fire",
  FLAG: "icon-flag",
  FLAG_CHECKERED: "icon-flag-checkered",
  FLAG_OFF: "icon-flag-off",
  FLAG_OUTLINE: "icon-flag-outline",
  FLAG_OUTLINE_VARIANT: "icon-flag-outline-variant",
  FLAG_TRIANGLE: "icon-flag-triangle",
  FLAG_VARIANT: "icon-flag-variant",
  FLASH: "icon-flash",
  FLASHLIGHT: "icon-flashlight",
  FLASHLIGHT_OFF: "icon-flashlight-off",
  FLASH_AUTO: "icon-flash-auto",
  FLASH_OFF: "icon-flash-off",
  FLASH_RED_EYE: "icon-flash-red-eye",
  FLIP_TO_BACK: "icon-flip-to-back",
  FLIP_TO_FRONT: "icon-flip-to-front",
  FLOPPY: "icon-floppy",
  FOLDER: "icon-folder",
  FOLDER_ACCOUNT: "icon-folder-account",
  FOLDER_DOWNLOAD: "icon-folder-download",
  FOLDER_GOOGLE_DRIVE: "icon-folder-google-drive",
  FOLDER_IMAGE: "icon-folder-image",
  FOLDER_LOCK: "icon-folder-lock",
  FOLDER_LOCK_OPEN: "icon-folder-lock-open",
  FOLDER_MOVE: "icon-folder-move",
  FOLDER_MULTIPLE: "icon-folder-multiple",
  FOLDER_MULTIPLE_IMAGE: "icon-folder-multiple-image",
  FOLDER_MULTIPLE_OUTLINE: "icon-folder-multiple-outline",
  FOLDER_OPEN: "icon-folder-open",
  FOLDER_OUTLINE: "icon-folder-outline",
  FOLDER_PLUS: "icon-folder-plus",
  FOLDER_REMOVE: "icon-folder-remove",
  FOLDER_STAR: "icon-folder-star",
  FOLDER_TRANSFER: "icon-folder-transfer",
  FOLDER_UPLOAD: "icon-folder-upload",
  FORM: "icon-form",
  FORMAT_ALIGN_CENTER: "icon-format-align-center",
  FORMAT_ALIGN_JUSTIFY: "icon-format-align-justify",
  FORMAT_ALIGN_LEFT: "icon-format-align-left",
  FORMAT_ALIGN_RIGHT: "icon-format-align-right",
  FORMAT_ANNOTATION_PLUS: "icon-format-annotation-plus",
  FORMAT_BOLD: "icon-format-bold",
  FORMAT_CLEAR: "icon-format-clear",
  FORMAT_COLOR_FILL: "icon-format-color-fill",
  FORMAT_COLOR_TEXT: "icon-format-color-text",
  FORMAT_FLOAT_CENTER: "icon-format-float-center",
  FORMAT_FLOAT_LEFT: "icon-format-float-left",
  FORMAT_FLOAT_NONE: "icon-format-float-none",
  FORMAT_FLOAT_RIGHT: "icon-format-float-right",
  FORMAT_HEADER_1: "icon-format-header-1",
  FORMAT_HEADER_2: "icon-format-header-2",
  FORMAT_HEADER_3: "icon-format-header-3",
  FORMAT_HEADER_4: "icon-format-header-4",
  FORMAT_HEADER_5: "icon-format-header-5",
  FORMAT_HEADER_6: "icon-format-header-6",
  FORMAT_HEADER_DECREASE: "icon-format-header-decrease",
  FORMAT_HEADER_EQUAL: "icon-format-header-equal",
  FORMAT_HEADER_INCREASE: "icon-format-header-increase",
  FORMAT_HEADER_POUND: "icon-format-header-pound",
  FORMAT_HORIZONTAL_ALIGN_CENTER: "icon-format-horizontal-align-center",
  FORMAT_HORIZONTAL_ALIGN_LEFT: "icon-format-horizontal-align-left",
  FORMAT_HORIZONTAL_ALIGN_RIGHT: "icon-format-horizontal-align-right",
  FORMAT_INDENT_DECREASE: "icon-format-indent-decrease",
  FORMAT_INDENT_INCREASE: "icon-format-indent-increase",
  FORMAT_ITALIC: "icon-format-italic",
  FORMAT_LINE_SPACING: "icon-format-line-spacing",
  FORMAT_LINE_STYLE: "icon-format-line-style",
  FORMAT_LINE_WEIGHT: "icon-format-line-weight",
  FORMAT_LIST_BULLETED: "icon-format-list-bulleted",
  FORMAT_LIST_BULLETED_TYPE: "icon-format-list-bulleted-type",
  FORMAT_LIST_NUMBERS: "icon-format-list-numbers",
  FORMAT_PAINT: "icon-format-paint",
  FORMAT_PARAGRAPH: "icon-format-paragraph",
  FORMAT_QUOTE: "icon-format-quote",
  FORMAT_SECTION: "icon-format-section",
  FORMAT_SIZE: "icon-format-size",
  FORMAT_STRIKETHROUGH: "icon-format-strikethrough",
  FORMAT_STRIKETHROUGH_VARIANT: "icon-format-strikethrough-variant",
  FORMAT_SUBSCRIPT: "icon-format-subscript",
  FORMAT_SUPERSCRIPT: "icon-format-superscript",
  FORMAT_TEXT: "icon-format-text",
  FORMAT_TEXTDIRECTION_L_TO_R: "icon-format-textdirection-l-to-r",
  FORMAT_TEXTDIRECTION_R_TO_L: "icon-format-textdirection-r-to-l",
  FORMAT_TITLE: "icon-format-title",
  FORMAT_UNDERLINE: "icon-format-underline",
  FORMAT_VERTICAL_ALIGN_BOTTOM: "icon-format-vertical-align-bottom",
  FORMAT_VERTICAL_ALIGN_CENTER: "icon-format-vertical-align-center",
  FORMAT_VERTICAL_ALIGN_TOP: "icon-format-vertical-align-top",
  FORMAT_WRAP_INLINE: "icon-format-wrap-inline",
  FORMAT_WRAP_SQUARE: "icon-format-wrap-square",
  FORMAT_WRAP_TIGHT: "icon-format-wrap-tight",
  FORMAT_WRAP_TOP_BOTTOM: "icon-format-wrap-top-bottom",
  FORUM: "icon-forum",
  FORWARD: "icon-forward",
  FULLSCREEN: "icon-fullscreen",
  FULLSCREEN_EXIT: "icon-fullscreen-exit",
  GATE: "icon-gate",
  GAUGE: "icon-gauge",
  GAVEL: "icon-gavel",
  GOOGLE_CIRCLES_COMMUNITIES: "icon-google-circles-communities",
  GOOGLE_CIRCLES_EXTENDED: "icon-google-circles-extended",
  GOOGLE_CIRCLES_GROUP: "icon-google-circles-group",
  GOVERNMENT_BUILDING: "icon-government-building",
  GRADIENT: "icon-gradient",
  GRID: "icon-grid",
  GRID_OFF: "icon-grid-off",
  GROUP: "icon-group",
  HAND_POINTING_RIGHT: "icon-hand-pointing-right",
  HANGOUTS: "icon-hangouts",
  HEADPHONES_SETTINGS: "icon-headphones-settings",
  HEADSET: "icon-headset",
  HEADSET_OFF: "icon-headset-off",
  HEART: "icon-heart",
  HEART_BOX: "icon-heart-box",
  HEART_BOX_OUTLINE: "icon-heart-box-outline",
  HEART_OUTLINE: "icon-heart-outline",
  HELP_CIRCLE: "icon-help-circle",
  HELP_CIRCLE_OUTLINE: "icon-help-circle-outline",
  HIGHLIGHTER: "icon-highlighter",
  HISTORY: "icon-history",
  HOME: "icon-home",
  HOME_MAP_MARKER: "icon-home-map-marker",
  HOME_OUTLINE: "icon-home-outline",
  HOME_VARIANT: "icon-home-variant",
  HOSPITAL: "icon-hospital",
  HUMAN_FEMALE: "icon-human-female",
  HUMAN_MALE: "icon-human-male",
  HUMAN_MALE_FEMALE: "icon-human-male-female",
  IMAGE: "icon-image",
  IMAGE_ALBUM: "icon-image-album",
  IMAGE_AREA: "icon-image-area",
  IMAGE_AREA_CLOSE: "icon-image-area-close",
  IMAGE_BROKEN: "icon-image-broken",
  IMAGE_BROKEN_VARIANT: "icon-image-broken-variant",
  IMAGE_FILTER: "icon-image-filter",
  IMAGE_FILTER_BLACK_WHITE: "icon-image-filter-black-white",
  IMAGE_FILTER_CENTER_FOCUS: "icon-image-filter-center-focus",
  IMAGE_FILTER_CENTER_FOCUS_WEAK: "icon-image-filter-center-focus-weak",
  IMAGE_FILTER_DRAMA: "icon-image-filter-drama",
  IMAGE_FILTER_FRAMES: "icon-image-filter-frames",
  IMAGE_FILTER_HDR: "icon-image-filter-hdr",
  IMAGE_FILTER_NONE: "icon-image-filter-none",
  IMAGE_FILTER_TILT_SHIFT: "icon-image-filter-tilt-shift",
  IMAGE_MULTIPLE: "icon-image-multiple",
  IMPORT: "icon-import",
  INACTIVE_USER: "icon-inactive-user",
  INBOX: "icon-inbox",
  INBOX_ARROW_DOWN: "icon-inbox-arrow-down",
  INBOX_ARROW_UP: "icon-inbox-arrow-up",
  INFORMATION: "icon-information",
  INFORMATION_OUTLINE: "icon-information-outline",
  INFORMATION_VARIANT: "icon-information-variant",
  INVERT_COLORS: "icon-invert-colors",
  JSON: "icon-json",
  KEY: "icon-key",
  KEYBOARD: "icon-keyboard",
  KEYBOARD_BACKSPACE: "icon-keyboard-backspace",
  KEYBOARD_CAPS: "icon-keyboard-caps",
  KEYBOARD_CLOSE: "icon-keyboard-close",
  KEYBOARD_OFF: "icon-keyboard-off",
  KEYBOARD_RETURN: "icon-keyboard-return",
  KEYBOARD_TAB: "icon-keyboard-tab",
  KEYBOARD_VARIANT: "icon-keyboard-variant",
  KEY_CHANGE: "icon-key-change",
  KEY_MINUS: "icon-key-minus",
  KEY_PLUS: "icon-key-plus",
  KEY_REMOVE: "icon-key-remove",
  KEY_VARIANT: "icon-key-variant",
  LABEL: "icon-label",
  LABEL_OUTLINE: "icon-label-outline",
  LAMP: "icon-lamp",
  LAN: "icon-lan",
  LAN_CONNECT: "icon-lan-connect",
  LAN_DISCONNECT: "icon-lan-disconnect",
  LAN_PENDING: "icon-lan-pending",
  LAPTOP: "icon-laptop",
  LAPTOP_CHROMEBOOK: "icon-laptop-chromebook",
  LAPTOP_MAC: "icon-laptop-mac",
  LAPTOP_WINDOWS: "icon-laptop-windows",
  LAUNCH: "icon-launch",
  LAYERS: "icon-layers",
  LAYERS_OFF: "icon-layers-off",
  LEAD_PENCIL: "icon-lead-pencil",
  LEVEL_DOWN: "icon-level-down",
  LEVEL_UP: "icon-level-up",
  LIBRARY_BOOKS: "icon-library-books",
  LIBRARY_MUSIC: "icon-library-music",
  LIBRARY_PLUS: "icon-library-plus",
  LIGHTBULB: "icon-lightbulb",
  LIGHTBULB_OUTLINE: "icon-lightbulb-outline",
  LINK: "icon-link",
  LINK_OFF: "icon-link-off",
  LINK_VARIANT: "icon-link-variant",
  LINK_VARIANT_OFF: "icon-link-variant-off",
  LOCK: "icon-lock",
  LOCK_OPEN: "icon-lock-open",
  LOCK_OPEN_OUTLINE: "icon-lock-open-outline",
  LOCK_OUTLINE: "icon-lock-outline",
  LOCK_PLUS: "icon-lock-plus",
  LOGIN: "icon-login",
  LOGIN_VARIANT: "icon-login-variant",
  LOGOUT: "icon-logout",
  LOGOUT_VARIANT: "icon-logout-variant",
  LOGO_INFORM: "icon-logo-inform",
  LOUPE: "icon-loupe",
  MAGNIFY: "icon-magnify",
  MAGNIFY_MINUS: "icon-magnify-minus",
  MAGNIFY_PLUS: "icon-magnify-plus",
  MAP: "icon-map",
  MAP_MARKER: "icon-map-marker",
  MAP_MARKER_CIRCLE: "icon-map-marker-circle",
  MAP_MARKER_MINUS: "icon-map-marker-minus",
  MAP_MARKER_MULTIPLE: "icon-map-marker-multiple",
  MAP_MARKER_OFF: "icon-map-marker-off",
  MAP_MARKER_PLUS: "icon-map-marker-plus",
  MAP_MARKER_RADIUS: "icon-map-marker-radius",
  MARGIN: "icon-margin",
  MARKER_CHECK: "icon-marker-check",
  MATH_COMPASS: "icon-math-compass",
  MEMORY: "icon-memory",
  MENU: "icon-menu",
  MENU_DOWN: "icon-menu-down",
  MENU_LEFT: "icon-menu-left",
  MENU_RIGHT: "icon-menu-right",
  MENU_UP: "icon-menu-up",
  MESSAGE: "icon-message",
  MESSAGE_ALERT: "icon-message-alert",
  MESSAGE_BULLETED: "icon-message-bulleted",
  MESSAGE_BULLETED_OFF: "icon-message-bulleted-off",
  MESSAGE_DRAW: "icon-message-draw",
  MESSAGE_IMAGE: "icon-message-image",
  MESSAGE_OUTLINE: "icon-message-outline",
  MESSAGE_PLUS: "icon-message-plus",
  MESSAGE_PROCESSING: "icon-message-processing",
  MESSAGE_REPLY: "icon-message-reply",
  MESSAGE_REPLY_TEXT: "icon-message-reply-text",
  MESSAGE_TEXT: "icon-message-text",
  MESSAGE_TEXT_OUTLINE: "icon-message-text-outline",
  MESSAGE_VIDEO: "icon-message-video",
  MICROPHONE: "icon-microphone",
  MICROPHONE_OFF: "icon-microphone-off",
  MICROPHONE_OUTLINE: "icon-microphone-outline",
  MICROPHONE_SETTINGS: "icon-microphone-settings",
  MICROSOFT_OFFICE: "icon-microsoft-office",
  MICROSOFT_TEAMS_LOGO: "icon-microsoft-teams-logo",
  MINISTERIAL: "icon-ministerial",
  MINUS: "icon-minus",
  MINUS_BOX: "icon-minus-box",
  MINUS_CIRCLE: "icon-minus-circle",
  MINUS_CIRCLE_OUTLINE: "icon-minus-circle-outline",
  MINUS_NETWORK: "icon-minus-network",
  MIXED_FILE: "icon-mixed-file",
  MONITOR: "icon-monitor",
  MONITOR_MULTIPLE: "icon-monitor-multiple",
  MORE: "icon-more",
  MOVE_RESIZE_VARIANT: "icon-move-resize-variant",
  MOVIE: "icon-movie",
  MULTIPLE_COMMENTS: "icon-multiple-comments",
  MULTIPLICATION: "icon-multiplication",
  MULTIPLICATION_BOX: "icon-multiplication-box",
  MUSIC_BOX: "icon-music-box",
  MUSIC_BOX_OUTLINE: "icon-music-box-outline",
  MUSIC_CIRCLE: "icon-music-circle",
  MUSIC_NOTE: "icon-music-note",
  NAVIGATION: "icon-navigation",
  NEAR_ME: "icon-near-me",
  NEST_PROTECT: "icon-nest-protect",
  NEWSPAPER: "icon-newspaper",
  NEW_BOX: "icon-new-box",
  NFC_TAP: "icon-nfc-tap",
  NFC_VARIANT: "icon-nfc-variant",
  NOTE: "icon-note",
  NOTE_DELETE_OUTLINE: "icon-note-delete-outline",
  NOTE_DELETE_OUTLINE_VARIANT: "icon-note-delete-outline-variant",
  NOTE_MULTIPLE: "icon-note-multiple",
  NOTE_MULTIPLE_OUTLINE: "icon-note-multiple-outline",
  NOTE_OUTLINE: "icon-note-outline",
  NOTE_PLUS: "icon-note-plus",
  NOTE_PLUS_OUTLINE: "icon-note-plus-outline",
  NOTE_TEXT: "icon-note-text",
  NOTIFICATION_CLEAR_ALL: "icon-notification-clear-all",
  NUMERIC: "icon-numeric",
  NUMERIC_0_BOX: "icon-numeric-0-box",
  NUMERIC_0_BOX_MULTIPLE_OUTLINE: "icon-numeric-0-box-multiple-outline",
  NUMERIC_0_BOX_OUTLINE: "icon-numeric-0-box-outline",
  NUMERIC_1_BOX: "icon-numeric-1-box",
  NUMERIC_1_BOX_MULTIPLE_OUTLINE: "icon-numeric-1-box-multiple-outline",
  NUMERIC_1_BOX_OUTLINE: "icon-numeric-1-box-outline",
  NUMERIC_2_BOX: "icon-numeric-2-box",
  NUMERIC_2_BOX_MULTIPLE_OUTLINE: "icon-numeric-2-box-multiple-outline",
  NUMERIC_2_BOX_OUTLINE: "icon-numeric-2-box-outline",
  NUMERIC_3_BOX: "icon-numeric-3-box",
  NUMERIC_3_BOX_MULTIPLE_OUTLINE: "icon-numeric-3-box-multiple-outline",
  NUMERIC_3_BOX_OUTLINE: "icon-numeric-3-box-outline",
  NUMERIC_4_BOX: "icon-numeric-4-box",
  NUMERIC_4_BOX_MULTIPLE_OUTLINE: "icon-numeric-4-box-multiple-outline",
  NUMERIC_4_BOX_OUTLINE: "icon-numeric-4-box-outline",
  NUMERIC_5_BOX: "icon-numeric-5-box",
  NUMERIC_5_BOX_MULTIPLE_OUTLINE: "icon-numeric-5-box-multiple-outline",
  NUMERIC_5_BOX_OUTLINE: "icon-numeric-5-box-outline",
  NUMERIC_6_BOX: "icon-numeric-6-box",
  NUMERIC_6_BOX_MULTIPLE_OUTLINE: "icon-numeric-6-box-multiple-outline",
  NUMERIC_6_BOX_OUTLINE: "icon-numeric-6-box-outline",
  NUMERIC_7_BOX: "icon-numeric-7-box",
  NUMERIC_7_BOX_MULTIPLE_OUTLINE: "icon-numeric-7-box-multiple-outline",
  NUMERIC_7_BOX_OUTLINE: "icon-numeric-7-box-outline",
  NUMERIC_8_BOX: "icon-numeric-8-box",
  NUMERIC_8_BOX_MULTIPLE_OUTLINE: "icon-numeric-8-box-multiple-outline",
  NUMERIC_8_BOX_OUTLINE: "icon-numeric-8-box-outline",
  NUMERIC_9_BOX: "icon-numeric-9-box",
  NUMERIC_9_BOX_MULTIPLE_OUTLINE: "icon-numeric-9-box-multiple-outline",
  NUMERIC_9_BOX_OUTLINE: "icon-numeric-9-box-outline",
  NUMERIC_9_PLUS_BOX: "icon-numeric-9-plus-box",
  NUMERIC_9_PLUS_BOX_MULTIPLE_OUTLINE: "icon-numeric-9-plus-box-multiple-outline",
  NUMERIC_9_PLUS_BOX_OUTLINE: "icon-numeric-9-plus-box-outline",
  NUTRITION: "icon-nutrition",
  OBJECTIVE_LOGO_CONNECT: "icon-objective-logo-connect",
  OBJECTIVE_LOGO_ECM: "icon-objective-logo-ecm",
  OBJECTIVE_LOGO_PERFORM: "icon-objective-logo-perform",
  OBJECTIVE_LOGO_REDACT: "icon-objective-logo-redact",
  OBJECTIVE_LOGO_TRAPEZE: "icon-objective-logo-trapeze",
  OCTAGON: "icon-octagon",
  OCTAGON_OUTLINE: "icon-octagon-outline",
  OPEN_IN_APP: "icon-open-in-app",
  OPEN_IN_NEW: "icon-open-in-new",
  OZ_BUILDING: "icon-oz-building",
  PACKAGE: "icon-package",
  PACKAGE_DOWN: "icon-package-down",
  PACKAGE_UP: "icon-package-up",
  PACKAGE_VARIANT: "icon-package-variant",
  PACKAGE_VARIANT_CLOSED: "icon-package-variant-closed",
  PAGE_FIRST: "icon-page-first",
  PAGE_LAST: "icon-page-last",
  PALETTE: "icon-palette",
  PALETTE_ADVANCED: "icon-palette-advanced",
  PANORAMA: "icon-panorama",
  PANORAMA_FISHEYE: "icon-panorama-fisheye",
  PANORAMA_HORIZONTAL: "icon-panorama-horizontal",
  PANORAMA_VERTICAL: "icon-panorama-vertical",
  PANORAMA_WIDE_ANGLE: "icon-panorama-wide-angle",
  PAPERCLIP: "icon-paperclip",
  PAPER_CUT_VERTICAL: "icon-paper-cut-vertical",
  PAUSE: "icon-pause",
  PAUSE_CIRCLE: "icon-pause-circle",
  PAUSE_CIRCLE_OUTLINE: "icon-pause-circle-outline",
  PAUSE_OCTAGON: "icon-pause-octagon",
  PAUSE_OCTAGON_OUTLINE: "icon-pause-octagon-outline",
  PENCIL: "icon-pencil",
  PENCIL_BOX: "icon-pencil-box",
  PENCIL_BOX_OUTLINE: "icon-pencil-box-outline",
  PENCIL_LOCK: "icon-pencil-lock",
  PENCIL_OFF: "icon-pencil-off",
  PHONE: "icon-phone",
  PHONE_CLASSIC: "icon-phone-classic",
  PHYSICAL_FILE: "icon-physical-file",
  PIN: "icon-pin",
  PIN_OFF: "icon-pin-off",
  PLAY: "icon-play",
  PLAYLIST_ADD: "icon-playlist-add",
  PLAYLIST_CHECK: "icon-playlist-check",
  PLAYLIST_MINUS: "icon-playlist-minus",
  PLAYLIST_PLAY: "icon-playlist-play",
  PLAYLIST_PLUS: "icon-playlist-plus",
  PLAYLIST_REMOVE: "icon-playlist-remove",
  PLAY_BOX_OUTLINE: "icon-play-box-outline",
  PLAY_CIRCLE: "icon-play-circle",
  PLAY_CIRCLE_OUTLINE: "icon-play-circle-outline",
  PLAY_PAUSE: "icon-play-pause",
  PLAY_PROTECTED_CONTENT: "icon-play-protected-content",
  PLEX: "icon-plex",
  PLUS: "icon-plus",
  PLUS_BOX: "icon-plus-box",
  PLUS_CIRCLE: "icon-plus-circle",
  PLUS_CIRCLE_MULTIPLE_OUTLINE: "icon-plus-circle-multiple-outline",
  PLUS_CIRCLE_OUTLINE: "icon-plus-circle-outline",
  PLUS_NETWORK: "icon-plus-network",
  PLUS_ONE: "icon-plus-one",
  POLAROID: "icon-polaroid",
  POLL: "icon-poll",
  POLL_BOX: "icon-poll-box",
  POUND: "icon-pound",
  POUND_BOX: "icon-pound-box",
  POWER: "icon-power",
  POWER_PLUG: "icon-power-plug",
  POWER_PLUG_OFF: "icon-power-plug-off",
  PRESENTATION: "icon-presentation",
  PRESENTATION_PLAY: "icon-presentation-play",
  PREVIEW_ONLY: "icon-preview-only",
  PRINTER: "icon-printer",
  PRINTER_3D: "icon-printer-3d",
  PRINTER_ALERT: "icon-printer-alert",
  PRIORITY_HIGH: "icon-priority-high",
  PRIORITY_LOW: "icon-priority-low",
  PROFESSIONAL_HEXAGON: "icon-professional-hexagon",
  PROJECTOR_SCREEN: "icon-projector-screen",
  PUBLISH: "icon-publish",
  PUBLISH_OUTLINE: "icon-publish-outline",
  PUBLISH_VARIENT: "icon-publish-varient",
  PULSE: "icon-pulse",
  QRCODE: "icon-qrcode",
  QRCODE_SCAN: "icon-qrcode-scan",
  QUALITY_HIGH: "icon-quality-high",
  QUERY: "icon-query",
  QUESTION_MARK: "icon-question-mark",
  RADAR: "icon-radar",
  RADIATOR: "icon-radiator",
  RADIO: "icon-radio",
  RADIOBOX_BLANK: "icon-radiobox-blank",
  RADIOBOX_MARKED: "icon-radiobox-marked",
  RADIO_HANDHELD: "icon-radio-handheld",
  RAY_END: "icon-ray-end",
  RAY_END_ARROW: "icon-ray-end-arrow",
  RAY_START: "icon-ray-start",
  RAY_START_ARROW: "icon-ray-start-arrow",
  RAY_START_END: "icon-ray-start-end",
  RAY_VERTEX: "icon-ray-vertex",
  REACH: "icon-reach",
  RECEIPT: "icon-receipt",
  RECORD: "icon-record",
  REDO: "icon-redo",
  REDO_VARIANT: "icon-redo-variant",
  REFRESH: "icon-refresh",
  REGEX: "icon-regex",
  RELATIVE_SCALE: "icon-relative-scale",
  RELOAD: "icon-reload",
  REMOTE: "icon-remote",
  REMOVE_CORPORATEFLAG_OUTLINE: "icon-remove-corporateflag-outline",
  REMOVE_CORPORATEFLAG_SOLID: "icon-remove-corporateflag-solid",
  RENAME: "icon-rename",
  RENAME_BOX: "icon-rename-box",
  REORDER_HORIZONTAL: "icon-reorder-horizontal",
  REORDER_VERTICAL: "icon-reorder-vertical",
  REPEAT: "icon-repeat",
  REPEAT_OFF: "icon-repeat-off",
  REPEAT_ONCE: "icon-repeat-once",
  REPLAY: "icon-replay",
  REPLY: "icon-reply",
  REPLY_ALL: "icon-reply-all",
  RESIZE_BOTTOM_RIGHT: "icon-resize-bottom-right",
  RESPONSIVE: "icon-responsive",
  RESTORE: "icon-restore",
  REWIND: "icon-rewind",
  ROAD: "icon-road",
  ROAD_VARIANT: "icon-road-variant",
  ROCKET: "icon-rocket",
  ROTATE_3D: "icon-rotate-3d",
  ROTATE_90: "icon-rotate-90",
  ROTATE_LEFT: "icon-rotate-left",
  ROTATE_LEFT_VARIANT: "icon-rotate-left-variant",
  ROTATE_RIGHT: "icon-rotate-right",
  ROTATE_RIGHT_VARIANT: "icon-rotate-right-variant",
  ROUNDED_CORNER: "icon-rounded-corner",
  ROUTER_WIRELESS: "icon-router-wireless",
  ROUTES: "icon-routes",
  RSS: "icon-rss",
  RSS_BOX: "icon-rss-box",
  RULER: "icon-ruler",
  RUN: "icon-run",
  SALE: "icon-sale",
  SATELLITE: "icon-satellite",
  SCALE_BALANCE: "icon-scale-balance",
  SCALE_BATHROOM: "icon-scale-bathroom",
  SCANNER: "icon-scanner",
  SCHOOL: "icon-school",
  SCRIBBLE: "icon-scribble",
  SCRIPT: "icon-script",
  SD: "icon-sd",
  SEAL: "icon-seal",
  SECURITY: "icon-security",
  SECURITY_HOME: "icon-security-home",
  SECURITY_NETWORK: "icon-security-network",
  SELECT: "icon-select",
  SELECTION: "icon-selection",
  SELECT_ALL: "icon-select-all",
  SELECT_INVERSE: "icon-select-inverse",
  SELECT_OFF: "icon-select-off",
  SELECT_RECTANGLE: "icon-select-rectangle",
  SEND: "icon-send",
  SERVER_MINUS: "icon-server-minus",
  SERVER_NETWORK: "icon-server-network",
  SERVER_NETWORK_OFF: "icon-server-network-off",
  SERVER_OFF: "icon-server-off",
  SERVER_PLUS: "icon-server-plus",
  SERVER_REMOVE: "icon-server-remove",
  SERVER_SECURITY: "icon-server-security",
  SETTINGS: "icon-settings",
  SETTINGS_BOX: "icon-settings-box",
  SETTINGS_COG: "icon-settings-cog",
  SHAPE_CIRCLE_PLUS: "icon-shape-circle-plus",
  SHAPE_PLUS: "icon-shape-plus",
  SHAPE_POLYGON_PLUS: "icon-shape-polygon-plus",
  SHAPE_RECTANGLE_PLUS: "icon-shape-rectangle-plus",
  SHAPE_SQUARE_PLUS: "icon-shape-square-plus",
  SHARE: "icon-share",
  SHARE_BOX: "icon-share-box",
  SHARE_VARIANT: "icon-share-variant",
  SHIELD: "icon-shield",
  SHIELD_OUTLINE: "icon-shield-outline",
  SHIELD_VARIANT: "icon-shield-variant",
  SHOPPING: "icon-shopping",
  SHOPPING_MUSIC: "icon-shopping-music",
  SHOW_THUMBNAIL: "icon-show-thumbnail",
  SHREDDER: "icon-shredder",
  SHUFFLE: "icon-shuffle",
  SHUFFLE_DISABLED: "icon-shuffle-disabled",
  SHUFFLE_VARIANT: "icon-shuffle-variant",
  SIGNAL: "icon-signal",
  SIGN_CAUTION: "icon-sign-caution",
  SIM: "icon-sim",
  SIM_ALERT: "icon-sim-alert",
  SIM_OFF: "icon-sim-off",
  SITEMAP: "icon-sitemap",
  SKIP_BACKWARD: "icon-skip-backward",
  SKIP_FORWARD: "icon-skip-forward",
  SKIP_NEXT: "icon-skip-next",
  SKIP_NEXT_CIRCLE: "icon-skip-next-circle",
  SKIP_NEXT_CIRCLE_OUTLINE: "icon-skip-next-circle-outline",
  SKIP_PREVIOUS: "icon-skip-previous",
  SKIP_PREVIOUS_CIRCLE: "icon-skip-previous-circle",
  SKIP_PREVIOUS_CIRCLE_OUTLINE: "icon-skip-previous-circle-outline",
  SOLID: "icon-solid",
  SORT: "icon-sort",
  SORT_ALPHABETICAL: "icon-sort-alphabetical",
  SORT_ASCENDING: "icon-sort-ascending",
  SORT_DESCENDING: "icon-sort-descending",
  SORT_NUMERIC: "icon-sort-numeric",
  SORT_VARIANT: "icon-sort-variant",
  SOURCE_BRANCH: "icon-source-branch",
  SOURCE_FORK: "icon-source-fork",
  SOURCE_MERGE: "icon-source-merge",
  SOURCE_PULL: "icon-source-pull",
  SPEEDOMETER: "icon-speedometer",
  SPELLCHECK: "icon-spellcheck",
  SPINNER: "icon-spinner",
  SQUARE_INC: "icon-square-inc",
  SQUARE_INC_CASH: "icon-square-inc-cash",
  STAIRS: "icon-stairs",
  STAR: "icon-star",
  STAR_CIRCLE: "icon-star-circle",
  STAR_HALF: "icon-star-half",
  STAR_OFF: "icon-star-off",
  STAR_OUTLINE: "icon-star-outline",
  STATUS_ADD: "icon-status-add",
  STEP_BACKWARD: "icon-step-backward",
  STEP_BACKWARD_VARIANT: "icon-step-backward-variant",
  STEP_FORWARD: "icon-step-forward",
  STEP_FORWARD_VARIANT: "icon-step-forward-variant",
  STOP: "icon-stop",
  STOP_CIRCLE: "icon-stop-circle",
  STOP_CIRCLE_OUTLINE: "icon-stop-circle-outline",
  STORE: "icon-store",
  SUBDIRECTORY_ARROW_LEFT: "icon-subdirectory-arrow-left",
  SUBDIRECTORY_ARROW_RIGHT: "icon-subdirectory-arrow-right",
  SUBDIRECTORY_ARROW_RIGHT_VARIANT: "icon-subdirectory-arrow-right-variant",
  SUBMISSION_STATUS: "icon-submission-status",
  SURROUND_SOUND: "icon-surround-sound",
  SWAP_VERTICAL: "icon-swap-vertical",
  SWITCH: "icon-switch",
  SYNC: "icon-sync",
  SYNC_ALERT: "icon-sync-alert",
  SYNC_OFF: "icon-sync-off",
  TAB: "icon-tab",
  TABLE: "icon-table",
  TABLET: "icon-tablet",
  TABLET_ANDROID: "icon-tablet-android",
  TABLET_IPAD: "icon-tablet-ipad",
  TABLE_COLUMN_PLUS_AFTER: "icon-table-column-plus-after",
  TABLE_COLUMN_PLUS_BEFORE: "icon-table-column-plus-before",
  TABLE_COLUMN_REMOVE: "icon-table-column-remove",
  TABLE_COLUMN_WIDTH: "icon-table-column-width",
  TABLE_EDIT: "icon-table-edit",
  TABLE_INPROGRESS: "icon-table-inprogress",
  TABLE_LARGE: "icon-table-large",
  TABLE_ROW_HEIGHT: "icon-table-row-height",
  TABLE_ROW_PLUS_AFTER: "icon-table-row-plus-after",
  TABLE_ROW_PLUS_BEFORE: "icon-table-row-plus-before",
  TABLE_ROW_REMOVE: "icon-table-row-remove",
  TAB_UNSELECTED: "icon-tab-unselected",
  TAG: "icon-tag",
  TAG_HEART: "icon-tag-heart",
  TAG_MULTIPLE: "icon-tag-multiple",
  TAG_OUTLINE: "icon-tag-outline",
  TAG_TEXT_OUTLINE: "icon-tag-text-outline",
  TARGET: "icon-target",
  TASKS: "icon-tasks",
  TASK_CLIPBOARD: "icon-task-clipboard",
  TASK_DECISION: "icon-task-decision",
  TASK_JOIN: "icon-task-join",
  TASK_SPLIT: "icon-task-split",
  TEAMVIEWER: "icon-teamviewer",
  TELEGRAM: "icon-telegram",
  TELEVISION: "icon-television",
  TELEVISION_GUIDE: "icon-television-guide",
  TEMPLATE: "icon-template",
  TEMPLATE_OUTLINE: "icon-template-outline",
  TEXTBOX: "icon-textbox",
  TEXTURE: "icon-texture",
  TEXT_SHADOW: "icon-text-shadow",
  THEATER: "icon-theater",
  TICKET: "icon-ticket",
  TICKET_ACCOUNT: "icon-ticket-account",
  TICKET_CONFIRMATION: "icon-ticket-confirmation",
  TIMELAPSE: "icon-timelapse",
  TIMER: "icon-timer",
  TIMER_OFF: "icon-timer-off",
  TIMER_SAND: "icon-timer-sand",
  TIMER_SAND_EMPTY: "icon-timer-sand-empty",
  TIMETABLE: "icon-timetable",
  TOGGLE_SWITCH: "icon-toggle-switch",
  TOGGLE_SWITCH_OFF: "icon-toggle-switch-off",
  TOOLTIP: "icon-tooltip",
  TOOLTIP_EDIT: "icon-tooltip-edit",
  TOOLTIP_IMAGE: "icon-tooltip-image",
  TOOLTIP_OUTLINE: "icon-tooltip-outline",
  TOOLTIP_OUTLINE_PLUS: "icon-tooltip-outline-plus",
  TOOLTIP_TEXT: "icon-tooltip-text",
  TOWNHALL_MINISTERIAL: "icon-townhall-ministerial",
  TRAFFIC_LIGHT: "icon-traffic-light",
  TRANSCRIBE: "icon-transcribe",
  TRANSCRIBE_CLOSE: "icon-transcribe-close",
  TRANSFER: "icon-transfer",
  TRANSLATE: "icon-translate",
  TRENDING_DOWN: "icon-trending-down",
  TRENDING_NEUTRAL: "icon-trending-neutral",
  TRENDING_UP: "icon-trending-up",
  TRIANGLE: "icon-triangle",
  TRIANGLE_OUTLINE: "icon-triangle-outline",
  TROPHY_AWARD: "icon-trophy-award",
  TUMBLR_REBLOG: "icon-tumblr-reblog",
  TUNE: "icon-tune",
  TUNE_VERTICAL: "icon-tune-vertical",
  TWITTER_RETWEET: "icon-twitter-retweet",
  UNDO: "icon-undo",
  UNDO_VARIANT: "icon-undo-variant",
  UNFOLD_LESS: "icon-unfold-less",
  UNFOLD_MORE: "icon-unfold-more",
  UNGROUP: "icon-ungroup",
  UPDATE: "icon-update",
  UPLOAD: "icon-upload",
  URGENT_MINISTERIAL: "icon-urgent-ministerial",
  USB: "icon-usb",
  USER_CODE: "icon-user-code",
  USER_RESTRICTED: "icon-user-restricted",
  VECTOR_ARRANGE_ABOVE: "icon-vector-arrange-above",
  VECTOR_ARRANGE_BELOW: "icon-vector-arrange-below",
  VECTOR_CIRCLE: "icon-vector-circle",
  VECTOR_CIRCLE_VARIANT: "icon-vector-circle-variant",
  VECTOR_COMBINE: "icon-vector-combine",
  VECTOR_CURVE: "icon-vector-curve",
  VECTOR_DIFFERENCE: "icon-vector-difference",
  VECTOR_DIFFERENCE_AB: "icon-vector-difference-ab",
  VECTOR_DIFFERENCE_BA: "icon-vector-difference-ba",
  VECTOR_INTERSECTION: "icon-vector-intersection",
  VECTOR_LINE: "icon-vector-line",
  VECTOR_POINT: "icon-vector-point",
  VECTOR_POLYGON: "icon-vector-polygon",
  VECTOR_POLYLINE: "icon-vector-polyline",
  VECTOR_RECTANGLE: "icon-vector-rectangle",
  VECTOR_SELECTION: "icon-vector-selection",
  VECTOR_SQUARE: "icon-vector-square",
  VECTOR_TRIANGLE: "icon-vector-triangle",
  VECTOR_UNION: "icon-vector-union",
  VERIFIED: "icon-verified",
  VIBRATE: "icon-vibrate",
  VIDEO: "icon-video",
  VIDEO_OFF: "icon-video-off",
  VIDEO_SWITCH: "icon-video-switch",
  VIEW_AGENDA: "icon-view-agenda",
  VIEW_ARRAY: "icon-view-array",
  VIEW_CAROUSEL: "icon-view-carousel",
  VIEW_COLUMN: "icon-view-column",
  VIEW_COMPACT: "icon-view-compact",
  VIEW_DASHBOARD: "icon-view-dashboard",
  VIEW_DAY: "icon-view-day",
  VIEW_DEFAULT: "icon-view-default",
  VIEW_GRID: "icon-view-grid",
  VIEW_HEADLINE: "icon-view-headline",
  VIEW_LIST: "icon-view-list",
  VIEW_MODULE: "icon-view-module",
  VIEW_QUILT: "icon-view-quilt",
  VIEW_STREAM: "icon-view-stream",
  VIEW_WEEK: "icon-view-week",
  VIRTUAL_FILE: "icon-virtual-file",
  VOLUME_HIGH: "icon-volume-high",
  VOLUME_LOW: "icon-volume-low",
  VOLUME_MEDIUM: "icon-volume-medium",
  WALLET: "icon-wallet",
  WALLET_GIFTCARD: "icon-wallet-giftcard",
  WALLET_MEMBERSHIP: "icon-wallet-membership",
  WALLET_TRAVEL: "icon-wallet-travel",
  WAN: "icon-wan",
  WATERMARK: "icon-watermark",
  WECHAT: "icon-wechat",
  WHATSAPP: "icon-whatsapp",
  WHITE_BALANCE_INCANDESCENT: "icon-white-balance-incandescent",
  WHITE_BALANCE_IRIDESCENT: "icon-white-balance-iridescent",
  WHITE_BALANCE_SUNNY: "icon-white-balance-sunny",
  WIFI: "icon-wifi",
  WIFI_OFF: "icon-wifi-off",
  WINDOW_CLOSE: "icon-window-close",
  WINDOW_CLOSED: "icon-window-closed",
  WINDOW_MAXIMIZE: "icon-window-maximize",
  WINDOW_MINIMIZE: "icon-window-minimize",
  WINDOW_OPEN: "icon-window-open",
  WINDOW_RESTORE: "icon-window-restore",
  WORKFLOW: "icon-workflow",
  WORKFLOW_SLIP: "icon-workflow-slip",
  WORKGROUP: "icon-workgroup",
  WORKSPACE: "icon-workspace",
  XAML: "icon-xaml",
  XML: "icon-xml",
};
