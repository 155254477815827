import React from "react";
import { serviceContainer } from "services/serviceContainer";
import { useToast } from "hooks/useToast";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { useStoreSelector } from "store/hooks";
import { selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId } from "store/domain-data/participant/participant";

export const useDownloadApplicationDocument = (applicationId: number) => {
  const { toastError } = useToast();
  const posthog = usePostHog();

  const participantTypesOfCurrentUserOnApplicationBasedOnApplicationId = useStoreSelector((state) =>
    selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId(state, applicationId)
  );

  const downloadApplicationDocument = React.useCallback(
    async (documentName: string): Promise<boolean> => {
      try {
        const downloadInfo = await serviceContainer.cradle.applicationDocumentService.fetchApplicationDocumentDownloadInfo(
          {
            applicationId,
            name: documentName,
          }
        );

        const response = await axios.get(downloadInfo.presignedUrl, {
          responseType: "arraybuffer",
        });
        downloadFile(downloadInfo.fileName, response.data);

        posthog?.capture(`user downloaded-document`, {
          participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
        });

        return true;
      } catch (e) {
        toastError(e);
        return false;
      }
    },
    [applicationId, participantTypesOfCurrentUserOnApplicationBasedOnApplicationId, posthog, toastError]
  );

  return {
    downloadApplicationDocument,
  };
};

const downloadFile = (fileName: string, buffer: ArrayBuffer) => {
  const data = new Blob([buffer], { type: "application/pdf" });
  const url = URL.createObjectURL(data);
  const linkElement = document.createElement("a");
  linkElement.href = url;
  linkElement.download = fileName;
  linkElement.click();
};
