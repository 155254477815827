// @ts-nocheck
import FormioEditGridComponent from "@objective_build/formiojs/components/editgrid/EditGrid";
import get from "lodash/get";

export default class EditGridComponent extends FormioEditGridComponent {
  showRowErrorAlerts(editRow, valid) {
    super.showRowErrorAlerts(editRow, valid);
    this.jumpToFirstError(editRow);
  }

  jumpToFirstError(editRow) {
    if (!editRow) {
      return;
    }

    if (!editRow.errors || !Array.isArray(editRow.errors) || editRow.errors.length === 0) {
      return;
    }

    const componentId = get(editRow.errors, "0.component.id");
    if (!componentId) {
      return;
    }

    window.setTimeout(() => {
      const element = document.getElementById(componentId);
      if (!element || typeof element.scrollIntoView !== "function") {
        return;
      }

      element.scrollIntoView({ block: "center" });
    }, 0);
  }
}
