// Refer: https://redux-toolkit.js.org/api/createReducer#builderaddmatcher
import { AsyncThunk, createReducer, createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/types";

// Types

export type LoadingState = Record<string, string>;

type GenericAsyncThunk = AsyncThunk<any, any, any>;

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

const initialState: LoadingState = {};

// Reducer

export const loadingReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(
      (action): action is PendingAction => action.type.endsWith("/pending"),
      (state, action) => {
        state[getAsyncThunkTypePrefix(action.type)] = "pending";
      }
    )
    .addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected"),
      (state, action) => {
        state[getAsyncThunkTypePrefix(action.type)] = "rejected";
      }
    )
    .addMatcher<FulfilledAction>(
      (action): action is FulfilledAction => action.type.endsWith("/fulfilled"),
      (state, action) => {
        state[getAsyncThunkTypePrefix(action.type)] = "fulfilled";
      }
    );
});

const getAsyncThunkTypePrefix = (actionType: string): string => {
  const arr = actionType.split("/");
  const newArr = arr.slice(0, arr.length - 1);
  const typePrefix = newArr.join("/");
  return typePrefix;
};

// Selectors

// Select the whole loading state object
export const selectLoadingState = (state: RootState) => state.appState.loading;

export const selectIsLoadingThunk = createSelector(
  [selectLoadingState, (_: RootState, asyncThunk: GenericAsyncThunk) => asyncThunk.typePrefix],
  (state, prefix) => {
    return state[prefix] === "pending";
  }
);

export const selectIsLoadingThunks = createSelector(
  [
    selectLoadingState,
    (_: RootState, asyncThunks: GenericAsyncThunk[]) => asyncThunks.map((thunk) => thunk.typePrefix),
  ],
  (state, prefixes) => {
    return prefixes.some((prefix) => state[prefix] === "pending");
  }
);
