import React from "react";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: (confirmed: boolean) => void;
  className?: string;
  "data-testid"?: string;
};

const InformationDialog: React.FC<Props> = ({
  title,
  isOpen,
  onClose,
  children,
  className,
  "data-testid": dataTestId = "information-dialog",
}) => {
  return (
    <Dialog className={className} title={title} open={isOpen} onClose={() => onClose(false)} data-testid={dataTestId}>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default InformationDialog;
