import React from "react";
import { Fade, Tooltip, TooltipProps } from "@material-ui/core";

type Props = {
  children: any;
  title: any;
} & Omit<TooltipProps, "title">;

const HoverTooltip: React.FC<Props> = ({ children, title, ...tooltipProps }) => {
  return (
    <Tooltip aria-label={title} title={title} arrow={true} TransitionComponent={Fade} {...tooltipProps}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default HoverTooltip;
