export const getFileNameComponents = (fileName: string) => {
  const fileParts = fileName.split(".");
  const fileNameWithoutExt = fileParts.slice(0, -1).join(".");
  if (!fileNameWithoutExt) {
    return {
      fileNameWithoutExt: fileName,
      fileExt: "",
    };
  }
  const [fileExt] = fileParts.slice(-1);
  return {
    fileNameWithoutExt,
    fileExt,
  };
};
