import React from "react";
import { ScreenWidthMatchContext } from "components/ScreenWidthMatchProvider/ScreenWidthMatchContext";
import { IScreenWidthMatchContextValue } from "components/ScreenWidthMatchProvider/ScreenWidthMatchProvider.types";

export const useScreenWidthMatch = (): IScreenWidthMatchContextValue => {
  const { xs, sm, md, lg, xl, xxl, xxxl } = React.useContext(ScreenWidthMatchContext);

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
  };
};
