import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { IRequiredDocumentEntity } from "models/RequiredDocument.model";
import { serviceContainer } from "services/serviceContainer";

// Entity Adapter

const requiredDocumentAdapter = createEntityAdapter<IRequiredDocumentEntity>({
  selectId: (category) => category.id,
  sortComparer: (a, b) => a.category.localeCompare(b.category),
});

// Action & Thunks

export const loadRequiredDocuments = createAction<IRequiredDocumentEntity[]>(
  "domainData/requiredDocument/loadRequiredDocuments"
);

export const submitRequiredDocument = createAsyncThunk(
  "domainData/requiredDocument/submitRequiredDocument",
  async (args: { applicationId: number; requiredDocumentId: number }, thunkAPI) => {
    const requiredDocument = await serviceContainer.cradle.applicationService.submitRequiredDocument(
      args.applicationId,
      args.requiredDocumentId
    );

    return requiredDocument;
  }
);

// Reducer

export const defaultRequiredDocumentState = requiredDocumentAdapter.getInitialState();

export const requiredDocumentReducer = createReducer<typeof defaultRequiredDocumentState>(
  defaultRequiredDocumentState,
  (builder) =>
    builder
      .addCase(loadRequiredDocuments, requiredDocumentAdapter.upsertMany)
      .addCase(submitRequiredDocument.fulfilled, requiredDocumentAdapter.upsertOne)
);

// Selectors

export const {
  selectAll: selectAllRequiredDocumentEntities,
  selectById: selectRequiredDocumentById,
} = requiredDocumentAdapter.getSelectors((state: RootState) => state.domainData.requiredDocument);

export const selectRequiredDocumentsByApplicationId = createDeepEqualSelector(
  [
    selectAllRequiredDocumentEntities,
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
  ],
  (entities, application) => {
    return entities.filter((entity) => entity.applicationId === application?.id);
  }
);

type SelectRequiredDocumentProps = {
  applicationId: number;
  documentContainerId: number;
};

export const selectRequiredDocument = createDeepEqualSelector(
  [
    (state: RootState, props: SelectRequiredDocumentProps) =>
      selectRequiredDocumentsByApplicationId(state, props.applicationId),
    (state: RootState, props: SelectRequiredDocumentProps) => props.documentContainerId,
  ],
  (applicationRequiredDocuments, documentContainerId) => {
    return applicationRequiredDocuments.find((document) => document.documentContainerId === documentContainerId);
  }
);
