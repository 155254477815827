import React from "react";
import { SnackbarProvider } from "notistack";
import ToastMessage from "components/ToastMessage/ToastMessage";
import { ToastDataType } from "components/ToastMessage/ToastMessage.types";

type ToastProviderProps = {
  children?: React.ReactNode;
};

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  return (
    <SnackbarProvider
      autoHideDuration={7000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      content={(key: string | number, data: ToastDataType) => <ToastMessage id={key} data={data} />}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
