import { deleteApplicationById } from "store/domain-data/application/application";
import { useStoreDispatch } from "store/hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import DeleteApplicationDialog from "components/DeleteApplicationDialog/DeleteApplicationDialog";

export const useDeleteApplicationDialog = (
  applicationId: number,
  options: {
    deleteWhenDialogClose: boolean;
  } = { deleteWhenDialogClose: true }
) => {
  // Common
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  // States
  const [isPendingConfirmDelete, setIsPendingConfirmDelete] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);

  // Callbacks
  const deleteApplication = React.useCallback(async () => {
    try {
      await dispatch(deleteApplicationById(applicationId)).then(unwrapResult);
    } catch (e) {
      toastError(t(`Fail to delete application`));
      setIsDeleted(false);
    }
  }, [t, dispatch, applicationId, toastError]);

  const handleDialogClose = React.useCallback(
    async (confirmed: boolean) => {
      setIsPendingConfirmDelete(false);

      if (!confirmed) {
        setIsDeleted(false);
        return;
      }

      setIsDeleted(true);

      if (options.deleteWhenDialogClose || confirmed) {
        await deleteApplication();
      }
    },
    [options.deleteWhenDialogClose, deleteApplication]
  );

  const deleteApplicationDialog = React.useMemo(() => {
    if (!isPendingConfirmDelete) {
      return null;
    }

    return <DeleteApplicationDialog applicationId={applicationId} onClose={handleDialogClose} />;
  }, [applicationId, isPendingConfirmDelete, handleDialogClose]);

  const openDeleteApplicationDialog = React.useCallback(() => {
    setIsPendingConfirmDelete(true);
  }, []);

  return {
    isDeleted,
    deleteApplication,
    deleteApplicationDialog,
    openDeleteApplicationDialog,
  };
};
