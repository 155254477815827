import { Cradle } from "services/serviceContainer.types";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18next, { i18n as II18n } from "i18next";
import { II18nInitializer } from "services/i18n/I18nInitializer.types";

export class I18nInitializer implements II18nInitializer {
  private logger: Cradle["logger"];

  public constructor({ logger }: Pick<Cradle, "logger">) {
    this.logger = logger;
  }

  public initI18n(): II18n {
    const i18n = i18next.createInstance();

    // Expose i18n instance for debugging purpose
    if (typeof globalThis !== "undefined") {
      // @ts-ignore
      globalThis.i18n = i18n;
    }

    // Reload resources when language changed
    i18n.on("languageChanged", (lang) => {
      i18n.reloadResources([lang]);
    });

    i18n
      .use(HttpApi)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: "en",
        debug: false,
        interpolation: {
          escapeValue: false,
        },
        ns: ["common"],
        defaultNS: "common",
        nsSeparator: "|",
        pluralSeparator: "---",
        keySeparator: false,
        backend: {
          loadPath: `/translations/{{lng}}.json`,
          crossDomain: false,
          parse: (str: string) => {
            // Parse all the keys
            const jsonObj = JSON.parse(str);
            // Avoid "null" and empty value
            const result = { ...jsonObj };
            const keys = Object.keys(result);
            for (const key of keys) {
              if (!result[key]) {
                delete result[key];
              }
            }
            return result;
          },
        },
        detection: {
          order: ["localStorage"],
          caches: ["localStorage"],
          lookupLocalStorage: "i18nextLang",
        },
      });
    return i18n;
  }
}
