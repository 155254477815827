import React from "react";

export type ISaveCurrentStepDataCommand = () => Promise<void>;

export const ApplicationFormContext = React.createContext<{
  runSaveCurrentStepDataCommand: ISaveCurrentStepDataCommand;
  updateSaveCurrentStepDataCommand: (command: ISaveCurrentStepDataCommand) => void;
}>({
  runSaveCurrentStepDataCommand: async () => {},
  updateSaveCurrentStepDataCommand: () => {},
});

const ApplicationFormContextProvider: React.FC = ({ children }) => {
  const [saveCurrentStepDataCommand, setSaveCurrentStepDataCommand] = React.useState(() => async () => {});
  const updateSaveCurrentStepDataCommand = React.useCallback((command: () => Promise<void>) => {
    setSaveCurrentStepDataCommand((prevState) => {
      return command;
    });
  }, []);
  const runSaveCurrentStepDataCommand = React.useCallback(() => saveCurrentStepDataCommand(), [
    saveCurrentStepDataCommand,
  ]);

  return (
    <ApplicationFormContext.Provider value={{ runSaveCurrentStepDataCommand, updateSaveCurrentStepDataCommand }}>
      {children}
    </ApplicationFormContext.Provider>
  );
};

export default ApplicationFormContextProvider;
