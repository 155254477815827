import React from "react";
import styled, { css } from "styled-components/macro";
import IconButton from "@material-ui/core/IconButton";
import FontIcon from "components/FontIcon/FontIcon";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import { NavigationItemsContext } from "components/odl-v2/Navigation/NavigationItemsContext";

type Props = {
  header?: React.ReactNode;
  isCollapsed?: boolean;
  onClickToggleCollapse: () => void;
  "data-testid"?: string;
};

const NavigationItems: React.FC<Props> = React.forwardRef(
  (
    {
      header,
      isCollapsed: isSideBarCollapsed = false,
      onClickToggleCollapse,
      children,
      "data-testid": dataTestId = "NavigationItems",
      ...otherProps
    },
    ref: React.Ref<SimpleBar>
  ) => {
    const { t } = useTranslation();

    const isSideBarCollapsedMemo = React.useMemo(() => {
      return {
        isCollapsed: isSideBarCollapsed,
      };
    }, [isSideBarCollapsed]);

    return (
      <StyledSimpleBar ref={ref} $isCollapsed={isSideBarCollapsed} data-testid={dataTestId} {...otherProps}>
        <StyledNavigationContainer>
          {header && <StyledHeaderContainer>{header}</StyledHeaderContainer>}
          <NavigationItemsContext.Provider value={isSideBarCollapsedMemo}>
            <StyledChildrenContainer>{children}</StyledChildrenContainer>
          </NavigationItemsContext.Provider>

          <StyledToggleButtonContainer>
            <StyledIconButton
              data-testid="NavigationToggleButton"
              aria-label={t(`Toggle navigation`)}
              onClick={onClickToggleCollapse}
            >
              <StyledFontIcon name={isSideBarCollapsed ? "chevron-right" : "chevron-left"} />
            </StyledIconButton>
          </StyledToggleButtonContainer>
        </StyledNavigationContainer>
      </StyledSimpleBar>
    );
  }
);

const StyledSimpleBar = styled(SimpleBar)<{ $isCollapsed: boolean }>(
  ({ $isCollapsed, theme }) => css`
    height: 100%;
    overflow-x: visible;
    transition: ${theme.mixins.layoutTransition("width")};
    width: ${$isCollapsed ? "56px" : "auto"};
  `
);

const StyledNavigationContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledHeaderContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
  `
);

const StyledChildrenContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledToggleButtonContainer = styled.div(
  ({ theme }) => css`
    padding: 0 10px;
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 36px;
    height: 36px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};
  `
);

export default NavigationItems;
