import { createAction, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { IPaymentOptionEntity, PaymentOptionDataUtil } from "models/PaymentOption.model";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";

const paymentOptionAdapter = createEntityAdapter<IPaymentOptionEntity>({
  selectId: (entity) => PaymentOptionDataUtil.generateEntityKey(entity),
  sortComparer: (a, b) => {
    const keyA = PaymentOptionDataUtil.generateEntityKey(a);
    const keyB = PaymentOptionDataUtil.generateEntityKey(b);
    return keyA.localeCompare(keyB);
  },
});

// Actions
export const loadPaymentOptions = createAction<IPaymentOptionEntity[]>("domainData/paymentOption/loadPaymentOptions");

// Reducer
export const defaultPaymentOptionState = paymentOptionAdapter.getInitialState();
export const paymentOptionReducer = createReducer<EntityState<IPaymentOptionEntity>>(
  defaultPaymentOptionState,
  (builder) => builder.addCase(loadPaymentOptions, paymentOptionAdapter.upsertMany)
);

// Selectors
export const {
  selectById: selectPaymentOptionById,
  selectAll: selectAllPaymentOptionEntities,
} = paymentOptionAdapter.getSelectors((state: RootState) => state.domainData.paymentOption);

export const selectPaymentOptionsByPaymentId = createDeepEqualSelector(
  [selectAllPaymentOptionEntities, (state: RootState, paymentId: number) => paymentId],
  (entities, paymentId) => {
    return entities.filter((entity) => entity.paymentId === paymentId);
  }
);
