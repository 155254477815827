import React from "react";
import MuiDialog, { DialogProps } from "@material-ui/core/Dialog";
import styled, { css } from "styled-components/macro";
import DialogTitle from "components/odl-v2/Dialog/DialogTitle";
import { IconButton } from "@material-ui/core";
import FontIcon from "components/FontIcon/FontIcon";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

type Props = Omit<DialogProps, "onClose"> & {
  title: React.ReactNode;
  onClose?: (event: React.SyntheticEvent) => void;
  disableCloseButton?: boolean;
};

const Dialog: React.FC<Props> = React.forwardRef(
  ({ title, children, onClose, disableCloseButton = false, ...props }, ref) => {
    const { t } = useTranslation();

    const handleClose = React.useCallback(
      (event: React.SyntheticEvent, reason?: string) => {
        if (!onClose || reason === "escapeKeyDown" || reason === "backdropClick") {
          return;
        }

        onClose(event);
      },
      [onClose]
    );

    const titleHtmlId = React.useMemo(() => `dialog-title-${nanoid(5)}`, []);

    return (
      <StyledDialog
        ref={ref}
        maxWidth={false}
        scroll={"body"}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        aria-labelledby={titleHtmlId}
        {...props}
      >
        <DialogTitle
          id={titleHtmlId}
          closeButton={
            <StyledIconButton disabled={disableCloseButton} onClick={handleClose} aria-label={t(`Close`)}>
              <StyledCloseIcon $disabled={disableCloseButton} />
            </StyledIconButton>
          }
        >
          {title}
        </DialogTitle>
        {children}
      </StyledDialog>
    );
  }
);

const StyledDialog = styled(MuiDialog)(
  ({ theme }) => css`
    .MuiDialog-paper {
      border-radius: 8px;
    }
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 50px;
    height: 50px;
  `
);

const StyledCloseIcon = styled(FontIcon).attrs({ name: "close" })<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    font-size: 14px;
    color: ${theme.palette.objective.dark.night};
    ${$disabled &&
    css`
      color: ${theme.palette.objective.light.allspice};
    `}
  `
);

export default Dialog;
