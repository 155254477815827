import React from "react";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";
import Guard from "components/Guard/Guard";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import { cloneDeep } from "lodash";

type LbpLicenseClassTagProps = {
  displayName: string;
  name: string;
};

const LbpLicenseClassTag: React.FC<LbpLicenseClassTagProps> = ({ displayName, name }) => {
  const { values, setFieldValue } = useFormikContext<IQualificationEntity>();

  const isSelected = React.useMemo(() => {
    return values.licensingClasses.includes(name);
  }, [name, values.licensingClasses]);

  const handleLicenseTagClicked = React.useCallback(() => {
    let licenseValues = cloneDeep(values.licensingClasses);
    if (licenseValues.includes(name)) {
      licenseValues = licenseValues.filter((val) => val !== name);
    } else {
      licenseValues.push(name);
    }
    setFieldValue("licensingClasses", licenseValues);
  }, [name, setFieldValue, values.licensingClasses]);

  return (
    <StyledLicenseClassTag
      onClick={handleLicenseTagClicked}
      $isSelected={isSelected}
      data-testid={"LbpLicenseClassTag"}
      aria-pressed={isSelected}
    >
      {displayName}
      <Guard condition={isSelected}>
        <StyledIconContainer>
          <FontIcon name={"icon-close"} />
        </StyledIconContainer>
      </Guard>
    </StyledLicenseClassTag>
  );
};

const StyledLicenseClassTag = styled.button<{
  $isSelected: boolean;
}>(
  ({ theme, $isSelected }) => css`
    padding: 8px 6px;
    background: ${theme.palette.objective.light.white};
    border-radius: 4px;
    border: 1px solid ${theme.palette.objective.light.allspice};
    font-size: 14px;
    text-align: center;
    color: ${theme.palette.objective.dark.fiordland};
    display: flex;
    align-items: center;
    cursor: pointer;

    ${$isSelected &&
    css`
      background: ${theme.palette.objective.blue.light};
      color: ${theme.palette.objective.dark.night};
      border: 1px solid ${theme.palette.objective.blue.light};
    `}

    ${theme.mixins.flexGap("10px")};
  `
);

const StyledIconContainer = styled.div(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.palette.objective.dark.night};
  `
);

export default LbpLicenseClassTag;
