import { IRegisteredApplicantInvitationService } from "services/registered-applicant-invitation/RegisteredApplicantInvitationService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import {
  IRegisteredApplicantInvitationEntity,
  RegisteredApplicantInvitationStatus,
  RegisteredApplicantInvitationStatusUtil,
} from "models/RegisteredApplicantInvitation.model";
import toNumber from "lodash/toNumber";

export class RegisteredApplicantInvitationService implements IRegisteredApplicantInvitationService {
  private readonly i18n: Cradle["i18n"];
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "i18n" | "apiClient">) {
    this.i18n = args.i18n;
    this.apiClient = args.apiClient;
  }

  public async fetchRegisteredApplicantPendingInvitations(): Promise<IRegisteredApplicantInvitationEntity[]> {
    const response = await this.apiClient.protectedApi.get(
      `/user/registered-applicant-invitations?invitationStatus=${RegisteredApplicantInvitationStatus.Pending}`
    );
    const json = this.parseRegisteredApplicantInvitations(get(response, "data.invitations"));
    return json;
  }

  public async fetchRegisteredApplicantPendingInvitation(
    invitationId: number
  ): Promise<IRegisteredApplicantInvitationEntity> {
    const response = await this.apiClient.protectedApi.get(`/user/registered-applicant-invitations/${invitationId}`);
    const json = this.parseRegisteredApplicantInvitation(get(response, "data.invitations"));
    return json;
  }

  public async updateRegisteredApplicantInvitation(args: {
    invitationId: number;
    status: RegisteredApplicantInvitationStatus;
  }): Promise<IRegisteredApplicantInvitationEntity> {
    const data = {
      status: args.status,
    };
    const response = await this.apiClient.protectedApi.patch(
      `/user/registered-applicant-invitations/${args.invitationId}`,
      data
    );
    const json = this.parseRegisteredApplicantInvitation(get(response, "data.invitations"));
    return json;
  }

  private parseRegisteredApplicantInvitations = (json: any): IRegisteredApplicantInvitationEntity[] => {
    return json.map((invitation: any) => {
      return this.parseRegisteredApplicantInvitation(invitation);
    });
  };

  private parseRegisteredApplicantInvitation = (json: any): IRegisteredApplicantInvitationEntity => {
    return {
      timestamp: toString(get(json, "timestamp")),
      id: toNumber(get(json, "id")),
      email: toString(get(json, "email")),
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      status: RegisteredApplicantInvitationStatusUtil.parse(get(json, "status")),
      sendingDate: toString(get(json, "sendingDate")),
      expiryDate: toString(get(json, "sendingDate")),
      userId: toNumber(get(json, "userId")),
      registeredApplicantId: toString(get(json, "registeredApplicantId")),
      bcaCode: toString(get(json, "bcaCode")),
    };
  };
}
