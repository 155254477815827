import get from "lodash/get";
import { ServiceError } from "services/ServiceError";
import { Cradle } from "services/serviceContainer.types";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { IOrganisationMergeInvitationService } from "services/organisation-merge-invitation/OrganisationMergeInvitationService.types";
import { IOrganisationMergeInvitationEntity } from "models/OrganisationMergeInvitation.model";
import {
  OrganisationInvitationStatus,
  OrganisationInvitationStatusUtil,
} from "models/OrganisationMemberInvitation.model";

export class OrganisationMergeInvitationService implements IOrganisationMergeInvitationService {
  private readonly apiClient: Cradle["apiClient"];

  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async fetchOrganisationMergeInvitationsSentFromCurrentOrganisation(): Promise<
    IOrganisationMergeInvitationEntity[]
  > {
    try {
      const response = await this.apiClient.protectedApi.get(`/user/organisation/merge-invitations`);
      const json = response.data;
      const mergeInvitationJSON = get(json, "invitations");
      if (!Array.isArray(mergeInvitationJSON)) {
        throw new ServiceError();
      }
      const invitations = mergeInvitationJSON.map((invite) => {
        return this.parseInvitationFromJSON(invite);
      });
      return invitations;
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async fetchOrganisationMergeInvitationsSentToCurrentUser(): Promise<IOrganisationMergeInvitationEntity[]> {
    try {
      const response = await this.apiClient.protectedApi.get(`/user/merge-invitations`);
      const json = response.data;
      const invitationsJSON = get(json, "mergeInvitations");

      if (!Array.isArray(invitationsJSON)) {
        throw new ServiceError();
      }
      const invitations = invitationsJSON.map((invite) => {
        return this.parseInvitationFromJSON(invite);
      });
      return invitations;
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async sendOrganisationMergeInvitation(args: {
    organisationId: number;
    inviteeEmail: string;
  }): Promise<IOrganisationMergeInvitationEntity> {
    try {
      const response = await this.apiClient.protectedApi.post(`user/organisation/merge-invitations`, {
        inviteeOrganisationId: args.organisationId,
        inviteeAdminEmail: args.inviteeEmail,
      });
      const json = response.data;
      return this.parseInvitationFromJSON(json);
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async resendOrganisationMergeInvitation(invitationId: number): Promise<void> {
    try {
      await this.apiClient.protectedApi.post(`user/organisation/merge-invitations/${invitationId}/notifications`);
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async revokeOrganisationMergeInvitation(invitationId: number): Promise<void> {
    try {
      await this.apiClient.protectedApi.patch(`user/organisation/merge-invitations/${invitationId}`, {
        status: "Revoked",
      });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async respondToOrganisationMergeInvitation(args: {
    invitationId: number;
    status: OrganisationInvitationStatus;
  }): Promise<IOrganisationMergeInvitationEntity> {
    try {
      const data = {
        status: args.status,
      };

      const response = await this.apiClient.protectedApi.patch(`/user/merge-invitations/${args.invitationId}`, data);
      const json = response.data;
      const responseInvitation = this.parseInvitationFromJSON(json);
      return responseInvitation;
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  private parseInvitationFromJSON(json: any): IOrganisationMergeInvitationEntity {
    const invitation: IOrganisationMergeInvitationEntity = {
      id: toInteger(get(json, "id")),
      organisationId: toInteger(get(json, "organisationId")),
      organisationName: toString(get(json, "organisationDisplayName")),
      inviteeFirstName: toString(get(json, "inviteeFirstName")),
      inviteeLastName: toString(get(json, "inviteeLastName")),
      inviteeEmail: toString(get(json, "inviteeAdminEmail")),
      status: OrganisationInvitationStatusUtil.parse(toString(get(json, "status"))),
      expiryDate: toString(get(json, "expiryDate")),
      sentDate: toString(get(json, "sendingDate")),
      inviteeOrganisationId: toInteger(get(json, "inviteeOrganisationId")),
      inviteeOrganisationName: toString(get(json, "inviteeOrganisationDisplayName")),
    };
    return invitation;
  }
}
