import trim from "lodash/trim";

export type IOrganisationMemberInvitationEntity = {
  id: number;
  organisationId: number;
  organisationName: string;
  firstName: string;
  lastName: string;
  email: string;
  status: OrganisationInvitationStatus;
  expiryDate: string;
  sentDate: string;
};

export enum OrganisationInvitationStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Expired = "Expired",
  Revoked = "Revoked",
  Error = "Error",
}

export const OrganisationInvitationStatusUtil = {
  parse: (str: string) => {
    switch (trim(str)) {
      case OrganisationInvitationStatus.Accepted:
        return OrganisationInvitationStatus.Accepted;
      case OrganisationInvitationStatus.Rejected:
        return OrganisationInvitationStatus.Rejected;
      case OrganisationInvitationStatus.Expired:
        return OrganisationInvitationStatus.Expired;
      case OrganisationInvitationStatus.Revoked:
        return OrganisationInvitationStatus.Revoked;
      case OrganisationInvitationStatus.Pending:
      case "Unregistered":
        return OrganisationInvitationStatus.Pending;
      default:
        return OrganisationInvitationStatus.Error;
    }
  },
};
