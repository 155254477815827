import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IParcelService } from "services/parcels/ParcelService.types";
import { IParcelEntity } from "models/Parcel.model";
import { base64Encode } from "utils/base64Encode";
import { IExtendedAddressEntity } from "models/Address.model";

export class ParcelService implements IParcelService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchParcelsByAuthorityAbbrAndAddress({
    authorityAbbr,
    lookupAddress,
  }: {
    authorityAbbr: string;
    lookupAddress: IExtendedAddressEntity;
  }): Promise<IParcelEntity[]> {
    const queryParams = new URLSearchParams();

    queryParams.set("authority", toString(authorityAbbr));
    const addressAsBase64Encoded = base64Encode(JSON.stringify(lookupAddress));
    queryParams.set("address", addressAsBase64Encoded);

    const urlPath = `/v1/service/property-lookup/parcels?${queryParams.toString()}`;
    const response = await this.apiClient.protectedApi.get(urlPath);

    const jsonArr = get(response, "data");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch parcels`));
    }
    return this.parseParcels(jsonArr);
  }

  private parseParcels(jsonArr: any[]) {
    const parcels = jsonArr.map((json) => {
      const parcel = {
        parcelId: toString(get(json, "parcelId")),
        ratingAddress: toString(get(json, "ratingAddress")),
        legalDescription: toString(get(json, "legalDescription")),
        lotNumber: toString(get(json, "lotNumber")),
        dpNumber: toString(get(json, "dpNumber")),
        valuationNumber: toString(get(json, "valuationNumber")),
      };
      return parcel;
    });
    return parcels;
  }
}
