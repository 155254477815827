import { IFormIOService } from "services/formio/FormIOService.types";
import get from "lodash/get";
import merge from "lodash/merge";
import { Cradle } from "services/serviceContainer.types";

export class FormIOService implements IFormIOService {
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async fetchFormSchemaByName(name: string) {
    const response = await this.apiClient.publicApi.get(`/public/formio/${name}`);
    const json = response.data;

    // Assign "name" field to the form json
    const formJson = merge({}, get(json, "formJson"), {
      name,
    });
    return formJson;
  }
}
