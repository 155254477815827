export type IPaymentOptionGateway = {
  paymentId: number;
  type: PaymentOptionType;
  displayName: string;
  surchargeFlag: boolean;
  spreedlyEnvironmentKey: string;
};

export type IPaymentOptionDirectTransfer = {
  paymentId: number;
  type: PaymentOptionType;
  displayName: string;
  bankName: string;
  accountName: string;
  routingNumber: string;
  accountNumber: string;
  paymentInstruction: string;
};

export type IPaymentOptionCouncil = {
  paymentId: number;
  type: PaymentOptionType;
  displayName: string;
  councilPaymentPortal: string;
};

export enum PaymentOptionType {
  Gateway = "Gateway",
  Deposit = "Deposit",
  Council = "Council",
}

export type IPaymentOptionEntity = IPaymentOptionGateway | IPaymentOptionDirectTransfer | IPaymentOptionCouncil;

export const PaymentOptionDataUtil = {
  generateEntityKey: (args: Pick<IPaymentOptionEntity, "paymentId" | "type">) =>
    `paymentId:${args.paymentId}|type:${args.type}`,
  isDepositPaymentType: (paymentOption: IPaymentOptionEntity): paymentOption is IPaymentOptionDirectTransfer => {
    return paymentOption.type === PaymentOptionType.Deposit;
  },
  isCouncilPaymentType: (paymentOption: IPaymentOptionEntity): paymentOption is IPaymentOptionCouncil => {
    return paymentOption.type === PaymentOptionType.Council;
  },
  isGatewayPaymentType: (paymentOption: IPaymentOptionEntity): paymentOption is IPaymentOptionGateway => {
    return paymentOption.type === PaymentOptionType.Gateway;
  },
};

export const PaymentTypeUtil = {
  parse: (str: string): PaymentOptionType => {
    for (const value of Object.values(PaymentOptionType)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        return value;
      }
    }
    return PaymentOptionType.Gateway;
  },
};
