import React from "react";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import Guard from "components/Guard/Guard";

type Props = {
  action: React.ReactNode;
  to?: string;
  hint?: React.ReactNode;
  linkTestId?: string;
  disabled?: boolean;
};

const LinkAction: React.FC<Props> = React.forwardRef(
  ({ hint, action, to = "/", disabled = false, linkTestId }, ref) => {
    return (
      <StyledWrapper>
        <Guard condition={hint}>
          <StyledHintText>{hint}</StyledHintText>
        </Guard>

        <Guard
          condition={!disabled}
          fallback={
            <Link href="#" color="textSecondary" data-testid={linkTestId}>
              <StyledLinkText>{action}</StyledLinkText>
            </Link>
          }
        >
          <Link component={RouterLink} to={to} data-testid={linkTestId}>
            <StyledLinkText>{action}</StyledLinkText>
          </Link>
        </Guard>
      </StyledWrapper>
    );
  }
);

const StyledWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledHintText = styled.div(
  ({ theme }) => css`
    font-weight: 500;
    color: ${theme.palette.text.secondary};
  `
);

const StyledLinkText = styled.span(
  () => css`
    font-weight: 500;
  `
);

export default LinkAction;
