import React from "react";
import { IScreenWidthMatchContextValue } from "components/ScreenWidthMatchProvider/ScreenWidthMatchProvider.types";

export const ScreenWidthMatchContext = React.createContext<IScreenWidthMatchContextValue>({
  xs: true,
  sm: true,
  md: true,
  lg: true,
  xl: true,
  xxl: true,
  xxxl: true,
});
