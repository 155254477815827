import React from "react";
import { Checkbox, FormControlLabel, Theme } from "@material-ui/core";
import styled, { css } from "styled-components/macro";

type Props = {
  label: string;
  fieldName: string;
  selected: boolean;
  disabled?: boolean;
  onSelect: (fieldName: string) => void;
  onDeselect: (fieldName: string) => void;
  ["data-testid"]?: string;
};

const LabelledCheckbox: React.FC<Props> = ({
  label,
  fieldName,
  selected,
  disabled,
  onSelect,
  onDeselect,
  "data-testid": dataTestId,
}) => {
  const handleChange = React.useCallback(
    (evt) => {
      const shouldCheck = evt.target.checked;
      if (shouldCheck) {
        onSelect(fieldName);
        return;
      }

      onDeselect(fieldName);
    },
    [fieldName, onSelect, onDeselect]
  );

  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          data-testid={dataTestId}
          name={fieldName}
          checked={selected}
          onChange={handleChange}
          disabled={disabled}
          size="small"
        />
      }
      label={
        <StyledLabel selected={selected} disabled={disabled}>
          {label}
        </StyledLabel>
      }
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    width: fit-content;
  `
);

const StyledCheckbox = styled(Checkbox)(
  ({ theme, disabled }: { theme: Theme; disabled?: boolean }) => css`
    color: ${theme.palette.objective.light.allspice};
    margin: 0 12px 0 0;
    padding: 0;

    .MuiSvgIcon-fontSizeSmall {
      font-size: 16px;
    }

    &.MuiIconButton-colorSecondary {
      &.Mui-checked {
        color: ${disabled ? theme.palette.objective.dark.neutral2 : theme.palette.objective.blue.main};
      }

      &:hover {
        background-color: transparent;
      }
    }
  `
);
const StyledLabel = styled.span(
  ({ theme, selected, disabled }: { theme: Theme; selected: boolean; disabled?: boolean }) => css`
    color: ${disabled ? theme.palette.objective.dark.neutral : theme.palette.objective.dark.fiordland};

    ${selected &&
    css`
      font-weight: 700;
    `}
  `
);

export default LabelledCheckbox;
