import { createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { loadJurisdictionForms } from "store/domain-data/jurisdiction-form/jurisdictionForm";
import { serviceContainer } from "services/serviceContainer";
import { loadBreadcrumbSteps } from "store/domain-data/breadcrumb-step/breadcrumbStep";
import { loadBreadcrumbs } from "store/domain-data/breadcrumb/breadcrumb";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { IJurisdictionEntity } from "models/Jurisdiction.model";
import { loadAuthorities } from "store/domain-data/authority/authority";
import { loadDocumentCategories } from "store/domain-data/document-categories/documentCategories";

// Entity Adapter

const jurisdictionAdapter = createEntityAdapter<IJurisdictionEntity>({
  selectId: (jurisdiction) => jurisdiction.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

// Action & Thunks

export const fetchJurisdictionByName = createAsyncThunk(
  "domainData/jurisdiction/fetchJurisdiction",
  async (name: string, thunkAPI) => {
    const jurisdictionService = serviceContainer.cradle.jurisdictionService;
    const {
      jurisdictionEntity,
      jurisdictionFormEntities,
      breadcrumbEntities,
      breadcrumbStepEntities,
      authorityEntities,
      documentCategories,
    } = await jurisdictionService.fetchJurisdictionByName(name);

    await thunkAPI.dispatch(loadBreadcrumbSteps(breadcrumbStepEntities));
    await thunkAPI.dispatch(loadBreadcrumbs(breadcrumbEntities));
    await thunkAPI.dispatch(loadJurisdictionForms(jurisdictionFormEntities));
    await thunkAPI.dispatch(loadAuthorities(authorityEntities));
    await thunkAPI.dispatch(loadDocumentCategories(documentCategories));

    return jurisdictionEntity;
  }
);

export const fetchJurisdictionById = createAsyncThunk(
  "domainData/jurisdiction/fetchJurisdiction",
  async (id: number, thunkAPI) => {
    const jurisdictionService = serviceContainer.cradle.jurisdictionService;
    const {
      jurisdictionEntity,
      jurisdictionFormEntities,
      breadcrumbEntities,
      breadcrumbStepEntities,
      authorityEntities,
      documentCategories,
    } = await jurisdictionService.fetchJurisdictionById(id);

    await thunkAPI.dispatch(loadBreadcrumbSteps(breadcrumbStepEntities));
    await thunkAPI.dispatch(loadBreadcrumbs(breadcrumbEntities));
    await thunkAPI.dispatch(loadJurisdictionForms(jurisdictionFormEntities));
    await thunkAPI.dispatch(loadAuthorities(authorityEntities));
    await thunkAPI.dispatch(loadDocumentCategories(documentCategories));

    return jurisdictionEntity;
  }
);

// Reducer

export const defaultJurisdictionState = jurisdictionAdapter.getInitialState();

export const jurisdictionReducer = createReducer<typeof defaultJurisdictionState>(defaultJurisdictionState, (builder) =>
  builder.addCase(fetchJurisdictionByName.fulfilled, (draft, action) => {
    jurisdictionAdapter.upsertOne(draft, action.payload);
  })
);

// Selectors

// Select entities
export const {
  selectById: selectJurisdictionEntityById,
  selectIds: selectJurisdictionEntityIds,
  selectEntities: selectJurisdictionEntities,
  selectAll: selectAllJurisdictionEntities,
  selectTotal: selectTotalJurisdictionEntities,
} = jurisdictionAdapter.getSelectors((state: RootState) => state.domainData.jurisdiction);

export const selectJurisdictionEntityByName = createDeepEqualSelector(
  [selectAllJurisdictionEntities, (_: RootState, name: string) => name],
  (entities, name) => {
    return entities.find((entity) => entity.name === name);
  }
);
