import { useStoreSelector } from "store/hooks";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { selectBreadcrumbEntitiesByJurisdictionFormId } from "store/domain-data/breadcrumb/breadcrumb";
import {
  selectAccessibleBreadcrumbStepIds,
  selectBreadcrumbStepEntitiesByJurisdictionFormId,
} from "store/domain-data/breadcrumb-step/breadcrumbStep";
import { assembleApplicationFormNavigation } from "pages/application-form/functions/assembleApplicationFormNavigation";
import { ApplicationFormNavigation } from "models/ApplicationFormNavigation.model";
import { selectEnabledStepIdsByApplicationId } from "store/domain-data/application-step-relation/applicationStepRelation";

export const useApplicationFormNavigation = (applicationId: number, forEdit: boolean): ApplicationFormNavigation => {
  const applicationEntity = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const formId = applicationEntity?.formId || 0;
  const breadcrumbEntities = useStoreSelector((state) => selectBreadcrumbEntitiesByJurisdictionFormId(state, formId));
  const breadcrumbStepEntities = useStoreSelector((state) =>
    selectBreadcrumbStepEntitiesByJurisdictionFormId(state, formId)
  );
  const enabledStepIds = useStoreSelector((state) => selectEnabledStepIdsByApplicationId(state, applicationId));
  const accessibleStepIds = useStoreSelector((state) => selectAccessibleBreadcrumbStepIds(state, applicationId));

  const breadcrumbs = assembleApplicationFormNavigation({
    breadcrumbEntities,
    breadcrumbStepEntities,
    applicationId,
    enabledStepIds,
    accessibleStepIds,
    forEdit,
  });

  return {
    applicationId,
    breadcrumbs,
  };
};
