import React from "react";
import { ParticipantInfoFormViewMode } from "./PersonalInfoForm";
import { useTranslation } from "react-i18next";
import Guard from "../Guard/Guard";
import FontIcon from "../FontIcon/FontIcon";
import { ODL_ICONS } from "../FontIcon/FontIcon.constant";
import TextButton from "../odl-v2/Button/TextButton";
import styled, { css } from "styled-components/macro";

type SetViewButtonProps = {
  currentViewMode: ParticipantInfoFormViewMode;
  onChangeViewMode: (view: ParticipantInfoFormViewMode) => void;
};

const PersonalInfoViewModeButton: React.FC<SetViewButtonProps> = ({ onChangeViewMode, currentViewMode }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Guard condition={currentViewMode === ParticipantInfoFormViewMode.PersonalInfo}>
        <StyledTextButton
          data-testid={"EnterManualAddressButton"}
          onClick={() => {
            onChangeViewMode(ParticipantInfoFormViewMode.ManualAddress);
          }}
        >
          {t("Enter address manually")}
        </StyledTextButton>
      </Guard>
      <Guard condition={currentViewMode === ParticipantInfoFormViewMode.ManualAddress}>
        <StyledTextButton
          data-testid={"ManualAddressGoBackButton"}
          onClick={() => {
            onChangeViewMode(ParticipantInfoFormViewMode.PersonalInfo);
          }}
          startIcon={<FontIcon name={ODL_ICONS.CHEVRON_LEFT} />}
        >
          {t(`Back to contact`)}
        </StyledTextButton>
      </Guard>
    </React.Fragment>
  );
};

const StyledTextButton = styled(TextButton)(
  ({ theme }) => css`
    justify-content: start;
    color: ${theme.palette.objective.blue.main};
    width: 200px;
    height: 32px;
    padding: 6px 12px 6px 0;
  `
);

export default PersonalInfoViewModeButton;
