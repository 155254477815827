import React from "react";
import Accordion from "components/odl-v2/Accordion/Accordion";
import { BreadcrumbStepType } from "models/BreadcrumbStepType";
import AccordionTitle from "components/odl-v2/Accordion/AccordionTitle";
import AccordionSummary from "components/odl-v2/Accordion/AccordionSummary";
import AccordionDetails from "components/odl-v2/Accordion/AccordionDetails";
import Guard from "components/Guard/Guard";
import FormIOStepContent from "components/ApplicationFormReviewDialog/FormIOStepContent";
import { ApplicationFormNavigationBreadcrumbStep } from "models/ApplicationFormNavigation.model";
import DocumentUploadStepContent from "components/ApplicationFormReviewDialog/DocumentUploadStepContent";
import ParticipantStepContent from "components/ApplicationFormReviewDialog/ParticipantStepContent";

type Props = { applicationId: number; breadcrumbStep: ApplicationFormNavigationBreadcrumbStep };

const BreadcrumbStepAccordion: React.FC<Props> = ({ applicationId, breadcrumbStep }) => {
  return (
    <Accordion defaultExpanded={false} data-testid={`BreadcrumbStepAccordion-${breadcrumbStep.id}`}>
      <AccordionSummary>
        <AccordionTitle>{breadcrumbStep.displayName}</AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Guard condition={breadcrumbStep.type === BreadcrumbStepType.Formio}>
          <FormIOStepContent applicationId={applicationId} breadcrumbStepId={breadcrumbStep.id} />
        </Guard>

        <Guard condition={breadcrumbStep.type === BreadcrumbStepType.DocumentUpload}>
          <DocumentUploadStepContent applicationId={applicationId} />
        </Guard>

        <Guard condition={breadcrumbStep.type === BreadcrumbStepType.Contact}>
          <ParticipantStepContent applicationId={applicationId} />
        </Guard>
      </AccordionDetails>
    </Accordion>
  );
};

export default BreadcrumbStepAccordion;
