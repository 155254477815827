import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { IApplicationTemplateEntity } from "models/ApplicationTemplate.model";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import { IUpdateApplicationTemplatePayload } from "services/application/ApplicationService.types";

// Entity Adapter

const applicationTemplateAdapter = createEntityAdapter<IApplicationTemplateEntity>({
  selectId: (entity) => entity.applicationId,
  sortComparer: (a, b) => {
    return a.applicationId - b.applicationId;
  },
});

// Action & Thunks

export const loadApplicationTemplates = createAction<IApplicationTemplateEntity[]>(
  "domainData/applicationTemplates/loadApplicationTemplates"
);

export const loadApplicationTemplate = createAction<IApplicationTemplateEntity>(
  "domainData/applicationTemplates/loadApplicationTemplate"
);

export const removeApplicationTemplate = createAction<number>(
  "domainData/applicationTemplates/removeApplicationTemplate"
);

export const updateApplicationTemplate = createAsyncThunk(
  "domainData/applicationTemplates/updateApplicationTemplate",
  async (args: { applicationId: number; payload: IUpdateApplicationTemplatePayload }, thunkAPI) => {
    const applicationTemplate = await serviceContainer.cradle.applicationService.updateApplicationTemplate(args);
    thunkAPI.dispatch(loadApplicationTemplate(applicationTemplate));
  }
);

// Reducer

export const defaultApplicationTemplateState = applicationTemplateAdapter.getInitialState();

export const applicationTemplateReducer = createReducer<typeof defaultApplicationTemplateState>(
  defaultApplicationTemplateState,
  (builder) =>
    builder
      .addCase(loadApplicationTemplates, applicationTemplateAdapter.upsertMany)
      .addCase(loadApplicationTemplate, applicationTemplateAdapter.upsertOne)
      .addCase(removeApplicationTemplate, (draft, action) => {
        applicationTemplateAdapter.removeOne(draft, action.payload);
      })
);

// Selectors

export const {
  selectById: selectApplicationTemplateEntityById,
  selectIds: selectApplicationTemplateEntityIds,
  selectEntities: selectApplicationTemplateEntities,
  selectAll: selectAllApplicationTemplateEntities,
  selectTotal: selectTotalApplicationTemplateEntities,
} = applicationTemplateAdapter.getSelectors((state: RootState) => state.domainData.applicationTemplate);
