import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { IEncodedImageFile } from "models/EncodedImageFile.model";

export type IOrganisationEntity = {
  id: number;
  displayName: string;
  colour?: string;
  image?: IEncodedImageFile;
};

export const parseOrganisationImage = (json: string): IEncodedImageFile => {
  return {
    name: toString(get(json, "name")),
    type: toString(get(json, "type")),
    size: toInteger(get(json, "size")),
    base64Image: toString(get(json, "base64Image")),
    modifiedDate: toString(get(json, "dateModified")),
  };
};
