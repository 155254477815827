import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { IAnnouncementEntity } from "models/Announcement.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";

// Entity Adapter
const announcementAdapter = createEntityAdapter<IAnnouncementEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    return b.id.localeCompare(a.id);
  },
});

// Action & Thunks
export const loadAnnouncements = createAction<IAnnouncementEntity[]>("domainData/announcement/loadAnnouncements");

export const fetchAnnouncements = createAsyncThunk(
  "domainData/announcement/fetchAnnouncements",
  async (_: void, thunkAPI) => {
    const announcements = await serviceContainer.cradle.announcementService.fetchAnnouncements();
    thunkAPI.dispatch(loadAnnouncements(announcements));
  }
);

// Reducer
export const defaultAnnouncementState = announcementAdapter.getInitialState();

export const announcementReducer = createReducer<typeof defaultAnnouncementState>(defaultAnnouncementState, (builder) =>
  builder.addCase(loadAnnouncements, (state, action) => {
    announcementAdapter.removeAll(state);
    announcementAdapter.addMany(state, action.payload);
  })
);

// Selectors
export const {
  selectById: selectAnnouncementEntityById,
  selectIds: selectAnnouncementEntityIds,
  selectEntities: selectAnnouncementEntities,
  selectAll: selectAllAnnouncementEntities,
  selectTotal: selectTotalAnnouncementEntities,
} = announcementAdapter.getSelectors((state: RootState) => state.domainData.announcement);

export const selectAnnouncementBasedOnLocation = createDeepEqualSelector(
  [selectAllAnnouncementEntities, (state: RootState, location: string) => location],
  (entities, location: string) => {
    if (!location) {
      return [];
    }
    return entities.filter((entity) => entity.location.toLowerCase() === location.toLowerCase());
  }
);
