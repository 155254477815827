import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import { DocumentUtil, IDocumentEntity } from "models/Document.model";
import { selectApplicationDocumentRelationsByApplicationId } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { createDeepEqualSelector } from "store/utils";
import uniq from "lodash/uniq";
import head from "lodash/head";
import axios from "axios";
import { selectAllDocumentCategoryEntities } from "../document-categories/documentCategories";
import { DocumentCategoryIdentifier } from "models/DocumentCategory.model";
import { DocumentStatus } from "models/DocumentStatus.model";

// Entity Adapter

const documentAdapter = createEntityAdapter<IDocumentEntity>({
  selectId: (entity) => entity.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

// Action & Thunks

export const upsertDocument = createAction<IDocumentEntity>("domainData/document/upsertDocument");
export const upsertDocuments = createAction<IDocumentEntity[]>("domainData/document/upsertDocuments");
export const removeDocument = createAction<{ documentName: string }>("domainData/document/removeDocument");
export const withdrawDocument = createAction<{ documentName: string }>("domainData/document/withdrawDocument");

export const uploadFile = createAsyncThunk(
  "domainData/document/uploadFile",
  async (
    args: {
      file: File;
      presignedUrl: string;
    },
    thunkAPI
  ) => {
    const { file, presignedUrl } = args;

    // Handle cancellation
    const cancelSource = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      cancelSource.cancel();
    });

    await serviceContainer.cradle.documentService.uploadDocument(file, presignedUrl, {
      cancelToken: cancelSource.token,
    });
  }
);

export const fetchDocument = createAsyncThunk(
  "domainData/document/fetchDocument",
  async (args: { documentName: string }, thunkAPI) => {
    const documentEntity = await serviceContainer.cradle.documentService.fetchDocument(args.documentName);
    thunkAPI.dispatch(upsertDocument(documentEntity));
  }
);

// Reducer

export const initialDocumentState = documentAdapter.getInitialState();

export const documentReducer = createReducer<typeof initialDocumentState>(initialDocumentState, (builder) =>
  builder
    .addCase(upsertDocument, (draft, action) => {
      documentAdapter.upsertOne(draft, action.payload);
    })
    .addCase(upsertDocuments, (draft, action) => {
      documentAdapter.setAll(draft, action.payload);
    })
    .addCase(removeDocument, (draft, action) => {
      documentAdapter.removeOne(draft, action.payload.documentName);
    })
);

// Selectors

export const {
  selectById: selectDocumentEntityByName,
  selectAll: selectAllDocumentEntities,
} = documentAdapter.getSelectors((state: RootState) => state.domainData.document);

export const selectDocumentEntitiesByNames = createDeepEqualSelector(
  [selectAllDocumentEntities, (state: RootState, documentNames: string[]) => documentNames],
  (entities, documentNames) => {
    return entities.filter((entity) => documentNames.includes(entity.name));
  }
);

export const selectDocumentEntitiesByApplicationId = createDeepEqualSelector(
  [
    selectAllDocumentEntities,
    (state: RootState, applicationId: number) =>
      selectApplicationDocumentRelationsByApplicationId(state, applicationId),
  ],
  (entities, relations) => {
    const documentNames = relations.map((rel) => rel.documentName);
    return entities.filter((entity) => documentNames.includes(entity.name));
  }
);

export const selectHasUnsettledDocumentsByApplication = createDeepEqualSelector(
  [selectDocumentEntitiesByApplicationId],
  (documents) => {
    return documents.some(DocumentUtil.isUnsettled);
  }
);

export const selectHasUploadingDocumentsByApplication = createDeepEqualSelector(
  [selectDocumentEntitiesByApplicationId],
  (documents) => {
    return documents.some((document) =>
      [DocumentStatus.Pending, DocumentStatus.Uploading].includes(document.uploadStatus)
    );
  }
);

export const selectDownloadableDocumentEntitiesByApplicationId = createDeepEqualSelector(
  [selectDocumentEntitiesByApplicationId],
  (documents) => {
    return documents.filter((document) => {
      return document.uploadStatus === DocumentStatus.Completed || document.uploadStatus === DocumentStatus.Uploaded;
    });
  }
);

export const selectDocumentEntitiesByApplicationIdForContainer = createDeepEqualSelector(
  [
    (state: RootState) => selectAllDocumentEntities(state),
    (state: RootState, args: { applicationId: number }) =>
      selectApplicationDocumentRelationsByApplicationId(state, args.applicationId),
    (state: RootState, args: { documentContainerId: number }) => args.documentContainerId,
    (state: RootState) => selectAllDocumentCategoryEntities(state),
  ],
  (allDocuments, applicationDocumentRelations, documentContainerId, allDocumentCategories) => {
    const documentsInContainer = applicationDocumentRelations
      .filter((rel) => rel.documentContainerId === documentContainerId)
      .map((rel) => {
        const matchingDoc = allDocuments.find((doc) => doc.name === rel.documentName);

        // Merged Required Documents should not be displayed
        const docCategory = allDocumentCategories.find((category) => category.id === rel.documentCategoryId);
        if (docCategory?.identifier === DocumentCategoryIdentifier.Required && matchingDoc?.isMergedFile) {
          return undefined;
        }

        return matchingDoc;
      })
      .filter((doc): doc is IDocumentEntity => Boolean(doc));

    // We will hide documents with same fileName if a new one just been added
    const fileNames = uniq(documentsInContainer.map((document) => document.fileName));
    const fileNameToDocument: Record<string, IDocumentEntity[]> = {};
    for (const fileName of fileNames) {
      fileNameToDocument[fileName] = documentsInContainer
        .filter((document) => document.fileName === fileName)
        // Sort order: createdDate DESC
        .sort((docA, docB) => docB.createdDate.localeCompare(docA.createdDate));
    }

    const documents = fileNames
      .map((fileName) => head(fileNameToDocument[fileName]))
      .filter((item): item is IDocumentEntity => Boolean(item));

    const orderedDocuments = documents.sort((docA, docB) => {
      const orderA = applicationDocumentRelations.find((rel) => rel.documentName === docA.name)?.order || 0;
      const orderB = applicationDocumentRelations.find((rel) => rel.documentName === docB.name)?.order || 0;
      return orderA - orderB;
    });

    return orderedDocuments;
  }
);

export const selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments = createDeepEqualSelector(
  [
    (state: RootState) => selectAllDocumentEntities(state),
    (state: RootState, args: { applicationId: number }) =>
      selectApplicationDocumentRelationsByApplicationId(state, args.applicationId),
    (state: RootState, args: { documentContainerId: number }) => args.documentContainerId,
  ],
  (allDocuments, applicationDocumentRelations, documentContainerId) => {
    const documentsInContainer = applicationDocumentRelations
      .filter((rel) => rel.documentContainerId === documentContainerId)
      .map((rel) => allDocuments.find((doc) => doc.name === rel.documentName))
      .filter((doc): doc is IDocumentEntity => Boolean(doc));

    // We will hide documents with same fileName if a new one just been added
    const fileNames = uniq(documentsInContainer.map((document) => document.fileName));
    const fileNameToDocument: Record<string, IDocumentEntity[]> = {};
    for (const fileName of fileNames) {
      fileNameToDocument[fileName] = documentsInContainer
        .filter((document) => document.fileName === fileName)
        // Sort order: createdDate DESC
        .sort((docA, docB) => docB.createdDate.localeCompare(docA.createdDate));
    }

    const documents = fileNames
      .map((fileName) => head(fileNameToDocument[fileName]))
      .filter((item): item is IDocumentEntity => Boolean(item));

    const orderedDocuments = documents.sort((docA, docB) => {
      const orderA = applicationDocumentRelations.find((rel) => rel.documentName === docA.name)?.order || 0;
      const orderB = applicationDocumentRelations.find((rel) => rel.documentName === docB.name)?.order || 0;
      return orderA - orderB;
    });

    return orderedDocuments;
  }
);

export const selectDocumentEntitiesByApplicationIdForContainerWithoutMasking = createDeepEqualSelector(
  [
    selectAllDocumentEntities,
    (state: RootState, args: { applicationId: number }) =>
      selectApplicationDocumentRelationsByApplicationId(state, args.applicationId),
    (state: RootState, args: { documentContainerId: number }) => args.documentContainerId,
  ],
  (allDocuments, applicationDocumentRelations, documentContainerId) => {
    const documentsInContainer = applicationDocumentRelations
      .filter((rel) => rel.documentContainerId === documentContainerId)
      .map((rel) => allDocuments.find((doc) => doc.name === rel.documentName))
      .filter((doc): doc is IDocumentEntity => Boolean(doc));

    return documentsInContainer;
  }
);

export const selectAllDocumentsByCategoryId = createDeepEqualSelector(
  [
    selectAllDocumentEntities,
    (state: RootState, args: { applicationId: number }) =>
      selectApplicationDocumentRelationsByApplicationId(state, args.applicationId),
    (state: RootState, args: { documentCategoryId: number }) => args.documentCategoryId,
  ],
  (allDocuments, applicationDocumentRelations, documentCategoryId) => {
    const allDocumentsByCategory = applicationDocumentRelations
      .filter((rel) => rel.documentCategoryId === documentCategoryId)
      .map((rel) => allDocuments.find((doc) => doc.name === rel.documentName))
      .filter((doc): doc is IDocumentEntity => Boolean(doc));
    return allDocumentsByCategory;
  }
);
