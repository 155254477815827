import React from "react";

import styled, { css } from "styled-components/macro";

type Props = { label: React.ReactNode; value: React.ReactNode };

const NotificationInfoField: React.FC<Props> = ({ label, value }) => {
  return (
    <StyledInfoField>
      <StyledInfoLabel>{label}:</StyledInfoLabel>
      <StyledInfoValue>{value || "--"}</StyledInfoValue>
    </StyledInfoField>
  );
};

const StyledInfoField = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledInfoLabel = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.text.primary};
  `
);

const StyledInfoValue = styled.div(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.text.primary};
    font-weight: 600;
  `
);

export default NotificationInfoField;
