import styled, { css } from "styled-components/macro";

const DialogActionGroup = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("16px")}
  `
);

export default DialogActionGroup;
