export type IDocumentTypeRelation = {
  documentName: string;
  documentTypeId: number;
};

export const DocumentTypeRelationUtil = {
  generateKey: (relation: IDocumentTypeRelation) => {
    return `documentName:${relation.documentName}|documentTypeId:${relation.documentTypeId}`;
  },
};
