import React from "react";

type Props = {
  condition: any;
  fallback?: React.ReactNode;
  children?: any;
};

const Guard: React.FC<Props> = ({ condition, fallback = null, children = null }) => {
  if (!Boolean(condition)) {
    return fallback;
  }

  return children;
};

export default Guard;
