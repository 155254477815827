import React from "react";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import FlexBox from "components/FlexBox/FlexBox";
import ApplicationInfoBasic from "components/ApplicationCard/ApplicationInfoBasic";
import ApplicationInfoDetails from "components/ApplicationCard/ApplicationInfoDetails";
import { useTranslation } from "react-i18next";
import { CreateApplicationIntention } from "models/CreateApplicationIntention.model";
import { useHistory } from "react-router-dom";
import { fetchApplicationById } from "store/domain-data/application/application";
import { fetchParticipantTypes } from "store/domain-data/participant-type/participantType";
import { useStoreDispatch } from "store/hooks";

type Props = {
  applicationId: number;
  onClose: () => void;
};

const DuplicateApplicationDialog: React.FC<Props> = ({ applicationId, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    const initData = async () => {
      if (!applicationId) {
        return;
      }
      await dispatch(fetchApplicationById(applicationId));
      await dispatch(fetchParticipantTypes(applicationId));
    };
    initData();
  }, [dispatch, applicationId]);

  const handleDialogClose = React.useCallback(
    async (confirmed: boolean) => {
      if (!confirmed) {
        onClose();
        return;
      }

      const queryParams = new URLSearchParams();
      queryParams.set("intention", CreateApplicationIntention.FromExistingApplication);
      queryParams.set("refApplicationId", applicationId.toString());
      history.push({ pathname: "/new-application/address", search: queryParams.toString() });

      onClose();
    },
    [applicationId, history, onClose]
  );

  return (
    <ConfirmationDialog
      title={t(`Duplicate application`)}
      content={
        <FlexBox direction={"column"} spacing={2}>
          <ApplicationInfoBasic applicationId={applicationId} />
          <ApplicationInfoDetails applicationId={applicationId} />
        </FlexBox>
      }
      isOpen={true}
      onClose={handleDialogClose}
      confirmTitle={t(`Duplicate`)}
      data-testid={"DuplicateApplicationConfirmDialog"}
    />
  );
};

export default DuplicateApplicationDialog;
