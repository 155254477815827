import React from "react";
import styled, { css } from "styled-components/macro";
import startsWith from "lodash/startsWith";
import Guard from "components/Guard/Guard";
import { Link } from "@material-ui/core";

type Props = {
  label: string;
  value?: React.ReactElement | string;
  href?: string;
};

const InfoField: React.FC<Props> = ({ label, value, href }) => {
  const isValidProtocol = startsWith(href, "https") || startsWith(href, "mailto") || startsWith(href, "tel");
  const isLink = value && href && isValidProtocol;

  return (
    <div data-testid={`InfoField-${label}`}>
      <StyledFieldLabel role={"term"}>{label}</StyledFieldLabel>
      <StyledFieldValue role={"definition"}>
        <Guard condition={isLink} fallback={value || "--"}>
          <Link href={href} rel="noopener noreferrer" target="_blank">
            {value}
          </Link>
        </Guard>
      </StyledFieldValue>
    </div>
  );
};

export default InfoField;

const StyledFieldLabel = styled.dt(
  ({ theme }) => css`
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);
const StyledFieldValue = styled.dd(
  ({ theme }) => css`
    font-size: 16px;
    line-height: 24px;
    margin-left: 0;
    color: ${theme.palette.objective.dark.night};
  `
);
