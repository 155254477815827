import { Cradle } from "services/serviceContainer.types";
import toInteger from "lodash/toInteger";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { ApplicationSortCriteria } from "models/ApplicationSortCriteria.model";
import { SortDirection } from "models/SortDirection.model";
import { OutstandingActionType } from "models/OutstandingActionType.model";
import { ApplicationV3Util, IApplicationV3Entity } from "models/ApplicationV3.model";
import {
  FetchPaginatedApplicationsV3Args,
  IApplicationV3Service,
} from "services/application-v3/ApplicationV3Service.types";
import { ApplicationStatusUtil } from "models/ApplicationStatus.model";
import { parseWorkflowStages } from "models/ApplicationWorkflow.model";
import { parseParticipantAccessLevelString } from "models/Participant.model";
import isEmpty from "lodash/isEmpty";

export class ApplicationV3Service implements IApplicationV3Service {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchPaginatedOutstandingApplicationsV3ForCurrentUser(args: FetchPaginatedApplicationsV3Args) {
    const {
      pageIndex,
      pageSize,
      sortBy = ApplicationSortCriteria.ModifiedDate,
      sortDirection = SortDirection.Descending,
    } = args;

    const queryParams = new URLSearchParams();

    queryParams.set("pageIndex", toString(pageIndex));
    queryParams.set("pageSize", toString(pageSize));
    queryParams.set("sortBy", sortBy);
    queryParams.set("sortDirection", sortDirection);

    const outstandingActions = [OutstandingActionType.RFI, OutstandingActionType.PAYMENT];
    queryParams.set("outstandingActions", toString(outstandingActions.join(",")));

    const response = await this.apiClient.protectedApi.get(`/user/applicationsV3?${queryParams.toString()}`);

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }

    return this.parseApplicationsV3(jsonArr, 0);
  }

  public async fetchPaginatedApplicationsV3ForCurrentUser(args: FetchPaginatedApplicationsV3Args) {
    const {
      pageIndex,
      pageSize,
      sortBy = ApplicationSortCriteria.ModifiedDate,
      sortDirection = SortDirection.Descending,
      formCategory,
      isHidden,
      parentApplicationId,
      accessLevel,
      searchText,
      statuses = [],
      forms = [],
      authorities = [],
      complexities = [],
      outstandingActions = [],
    } = args;

    const queryParams = new URLSearchParams();

    queryParams.set("pageIndex", toString(pageIndex));
    queryParams.set("pageSize", toString(pageSize));
    queryParams.set("sortBy", sortBy);
    queryParams.set("sortDirection", sortDirection);

    if (!searchText) {
      queryParams.set("isArchived", toString(isHidden));
    }

    if (formCategory) {
      queryParams.set("formCategory", toString(formCategory));
    }

    if (accessLevel) {
      queryParams.set("accessLevel", accessLevel);
    }

    if (parentApplicationId) {
      queryParams.set("parentApplicationId", toString(parentApplicationId));
    }

    if (!isEmpty(authorities)) {
      queryParams.set("authority", toString(authorities.join(",")));
    }

    if (!isEmpty(forms)) {
      queryParams.set("form", toString(forms.join(",")));
    }

    if (!isEmpty(complexities)) {
      queryParams.set("complexity", toString(complexities.join(",")));
    }

    if (!isEmpty(statuses)) {
      queryParams.set("status", statuses.join(","));
    }

    if (!isEmpty(outstandingActions)) {
      queryParams.set("outstandingActions", toString(outstandingActions.join(",")));
    }

    if (searchText) {
      queryParams.set("searchText", toString(searchText));
    }

    const response = await this.apiClient.protectedApi.get(`/user/applicationsV3?${queryParams.toString()}`);

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }

    return this.parseApplicationsV3(jsonArr, 0);
  }

  public async fetchPaginatedChildApplicationsV3ForCurrentUser(args: FetchPaginatedApplicationsV3Args) {
    const {
      pageIndex,
      pageSize,
      sortBy = ApplicationSortCriteria.ModifiedDate,
      sortDirection = SortDirection.Descending,
      parentApplicationId,
    } = args;

    const queryParams = new URLSearchParams();

    queryParams.set("pageIndex", toString(pageIndex));
    queryParams.set("pageSize", toString(pageSize));
    queryParams.set("sortBy", sortBy);
    queryParams.set("sortDirection", sortDirection);

    const response = await this.apiClient.protectedApi.get(
      `/user/applicationsV3/${parentApplicationId}/child-applications?${queryParams.toString()}`
    );

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }

    return this.parseApplicationsV3(jsonArr, parentApplicationId || 0);
  }

  private parseApplicationsV3(jsonArr: any[], parentApplicationId: number) {
    const applications: IApplicationV3Entity[] = [];

    for (const json of jsonArr) {
      const application = this.parseApplicationV3(json, parentApplicationId);
      applications.push(application);
    }

    return {
      applications,
    };
  }

  private parseApplicationV3(json: any, parentApplicationId: number): IApplicationV3Entity {
    const application: IApplicationV3Entity = {
      id: toInteger(get(json, "id")),
      formId: toInteger(get(json, "formId")),
      formDisplayName: toString(get(json, "formDisplayName")),
      authorityId: toInteger(get(json, "authorityId")),
      complexity: toString(get(json, "complexity")),
      consentNumber: toString(get(json, "consentNumber")),
      currentStage: toString(get(json, "currentStage")),
      currentStatusLabel: toString(get(json, "currentStatusLabel")),
      modifiedDate: toString(get(json, "modifiedDate")),
      modifiedBy: toString(get(json, "modifiedBy")),
      applicationNumber: toString(get(json, "applicationNumber")),
      hasChildApplications: Boolean(get(json, "hasChildApplications")),
      hasOutstandingRFIs: Boolean(get(json, "hasOutstandingRFIs")),
      hasOutstandingPayments: Boolean(get(json, "hasOutstandingPayments")),
      isArchived: Boolean(get(json, "isArchived")),
      isEditable: Boolean(get(json, "isEditable")),
      isMigrated: Boolean(get(json, "isMigrated")),
      isDeletable: Boolean(get(json, "isDeletable")),
      address: toString(get(json, "address")),
      status: ApplicationStatusUtil.parse(toString(get(json, "status"))),
      recordType: ApplicationV3Util.getRecordType(toString(get(json, "consentNumber"))),
      displayStages: parseWorkflowStages(get(json, "displayStages")) || [],
      accessLevel: parseParticipantAccessLevelString(toString(get(json, "accessLevel"))),
      applicationFormPdf: toString(get(json, "applicationFormPdf")),
      parentApplicationId,
    };
    return application;
  }

  public async deleteApplicationV3ById(applicationId: number): Promise<void> {
    await this.apiClient.protectedApi.delete(`/user/applications/${applicationId}`);
  }
}
