import React from "react";
import { useHistory } from "react-router-dom";
import { LocationState } from "components/RouteController/RouteController.types";

export const useNavigate = () => {
  const history = useHistory<LocationState>();

  const navigateTo = React.useCallback(
    (url: string) => {
      history.push(url, {
        prevLocation: history.location,
      });
    },
    [history]
  );

  return {
    navigateTo,
  };
};
