// @ts-nocheck
import FormioCheckboxComponent from "@objective_build/formiojs/components/checkbox/Checkbox";

export default class CheckboxComponent extends FormioCheckboxComponent {
  /**
   * @override
   * @param value
   * @param flags
   */
  updateValue(value, flags) {
    const changed = super.updateValue(value, flags);
    if (changed && this.input) {
      if (this.input.checked) {
        this.input.setAttribute("checked", "true");
      } else {
        this.input.removeAttribute("checked");
      }
    }

    return changed;
  }
}
