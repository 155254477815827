import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { DocumentTagRelationUtil, IDocumentTagRelation } from "models/DocumentTagRelation.model";

// Entity Adapter

// This is a join table between Document and Document Tag
const documentTagRelationAdapter = createEntityAdapter<IDocumentTagRelation>({
  selectId: (relation) => DocumentTagRelationUtil.generateKey(relation),
  sortComparer: (a, b) => {
    if (a.documentName.localeCompare(b.documentName) !== 0) {
      return a.documentName.localeCompare(b.documentName);
    }

    return a.documentTagId - b.documentTagId;
  },
});

// Action & Thunks

export const upsertDocumentTagRelation = createAction<IDocumentTagRelation>(
  "domainData/documentTagRelation/upsertDocumentTagRelation"
);
export const upsertDocumentTagRelations = createAction<IDocumentTagRelation[]>(
  "domainData/documentTagRelation/upsertDocumentTagRelations"
);

export const removeDocumentTagRelation = createAction<IDocumentTagRelation>(
  "domainData/documentTagRelation/removeDocumentTagRelation"
);

export const removeDocumentTagRelations = createAction<IDocumentTagRelation[]>(
  "domainData/documentTagRelation/removeDocumentTagRelations"
);

// Reducer

export const documentTagRelationReducer = createReducer(documentTagRelationAdapter.getInitialState(), (builder) =>
  builder
    .addCase(upsertDocumentTagRelation, (draft, action) => {
      documentTagRelationAdapter.upsertOne(draft, action.payload);
    })
    .addCase(upsertDocumentTagRelations, (draft, action) => {
      documentTagRelationAdapter.upsertMany(draft, action.payload);
    })
    .addCase(removeDocumentTagRelation, (draft, action) => {
      documentTagRelationAdapter.removeOne(draft, DocumentTagRelationUtil.generateKey(action.payload));
    })
    .addCase(removeDocumentTagRelations, (draft, action) => {
      documentTagRelationAdapter.removeMany(draft, action.payload.map(DocumentTagRelationUtil.generateKey));
    })
);

// Selectors

export const {
  selectById: selectDocumentTagRelationByKey,
  selectAll: selectAllDocumentTagRelations,
} = documentTagRelationAdapter.getSelectors((state: RootState) => state.domainData.documentTagRelation);

export const selectDocumentTagRelationsByTagId = createDeepEqualSelector(
  [selectAllDocumentTagRelations, (state: RootState, documentTagId: number) => documentTagId],
  (relations, documentTagId) => relations.filter((relation) => relation.documentTagId === documentTagId)
);

export const selectDocumentTagRelationsByDocumentName = createDeepEqualSelector(
  [selectAllDocumentTagRelations, (state: RootState, documentName: string) => documentName],
  (relations, documentName) => relations.filter((relation) => relation.documentName === documentName)
);
