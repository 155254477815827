import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { IRequiredInspectionsEntity } from "models/RequiredInspections.model";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { selectApplicationEntityById } from "store/domain-data/application/application";

// Action & Thunks

export const loadRequiredInspections = createAction<IRequiredInspectionsEntity[]>(
  "domainData/requiredInspections/loadRequiredInspections"
);

// Entity Adapter

const requiredInspectionsAdapter = createEntityAdapter<IRequiredInspectionsEntity>({
  selectId: (entity) => entity.location,
});

// Reducer

export const defaultRequiredInspectionsState = requiredInspectionsAdapter.getInitialState();

export const requiredInspectionsReducer = createReducer<typeof defaultRequiredInspectionsState>(
  defaultRequiredInspectionsState,
  (builder) => builder.addCase(loadRequiredInspections, requiredInspectionsAdapter.upsertMany)
);

// Selectors

export const { selectAll: selectAllRequiredInspectionsEntities } = requiredInspectionsAdapter.getSelectors(
  (state: RootState) => state.domainData.requiredInspections
);

export const selectRequiredInspectionsByApplicationId = createDeepEqualSelector(
  [
    selectAllRequiredInspectionsEntities,
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
  ],
  (entities, application) => {
    return entities.filter((entity) => entity.applicationId === application?.id);
  }
);
