import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { ApplicationRecordType, IApplicationEntity } from "models/Application.model";
import { fetchPropertiesForCurrentUser } from "store/domain-data/property/property";
import { loadParticipants, removeParticipantsByApplicationId } from "store/domain-data/participant/participant";
import { ParticipantAccessLevel, rankAccessLevel } from "models/Participant.model";
import { removeApplicationDocumentRelationsByApplicationId } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import {
  loadApplicationTemplate,
  loadApplicationTemplates,
  removeApplicationTemplate,
} from "store/domain-data/application-template/applicationTemplate";
import { batch } from "react-redux";
import { loadRequiredDocuments } from "store/domain-data/required-document/requiredDocument";
import { loadRequiredInspections } from "store/domain-data/required-inspection/requiredInspections";
import { removeApplicationDocumentContainerRelationsByApplicationId } from "store/domain-data/application-document-containers-relation/applicationDocumentContainersRelation";
import { removeApplicationStepDataByApplicationId } from "store/domain-data/application-step-data/applicationStepData";
import { removeApplicationValidationResultsByApplicationId } from "store/domain-data/application-validation-result/applicationValidationResult";
import { removeApplicationStepRelationsByApplicationId } from "store/domain-data/application-step-relation/applicationStepRelation";
import isEmpty from "lodash/isEmpty";
import { IAddressEntity } from "models/Address.model";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import {
  FetchApplicationsBadgeCountArgs,
  FetchPaginatedApplicationsArgs,
} from "services/application/ApplicationService.types";
import {
  fetchArchivedApplicationsBadgeCount,
  fetchArchivedConsentsBadgeCount,
  fetchCurrentConsentsBadgeCount,
  fetchSubmittedApplicationsBadgeCount,
} from "store/app-state/application-badge-count/applicationBadgeCount";
import loglevel from "loglevel";
import { IParcelEntity } from "models/Parcel.model";

// Entity Adapter

const applicationAdapter = createEntityAdapter<IApplicationEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    return b.modifiedDate.localeCompare(a.modifiedDate);
  },
});

// Action & Thunks

export const loadApplications = createAction<IApplicationEntity[]>("domainData/application/loadApplications");
export const loadApplication = createAction<IApplicationEntity>("domainData/application/loadApplication");

export const fetchApplicationsForCurrentUser = createAsyncThunk(
  "domainData/application/fetchApplicationsForCurrentUser",
  async (_: void, thunkAPI) => {
    await thunkAPI.dispatch(fetchDraftApplicationsForCurrentUser());
    await thunkAPI.dispatch(fetchConsentApplicationsForCurrentUser());
  }
);

export const fetchDraftApplicationsForCurrentUser = createAsyncThunk(
  "domainData/application/fetchDraftApplicationsForCurrentUser",
  async (_: void, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    await thunkAPI.dispatch(fetchPropertiesForCurrentUser());
    const { applications, participants } = await applicationService.fetchApplicationsForCurrentUser();
    batch(() => {
      thunkAPI.dispatch(loadParticipants(participants));
      thunkAPI.dispatch(loadApplications(applications));
    });
  }
);

export const fetchPaginatedApplicationsForCurrentUser = createAsyncThunk(
  "domainData/application/fetchPaginatedApplicationsForCurrentUser",
  async (args: FetchPaginatedApplicationsArgs, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const { applications, participants } = await applicationService.fetchPaginatedApplicationsForCurrentUser(args);
    batch(() => {
      thunkAPI.dispatch(loadParticipants(participants));
      thunkAPI.dispatch(loadApplications(applications));
    });
    return applications;
  }
);

export const fetchApplicationsBadgeCountForCurrentUser = createAsyncThunk(
  "domainData/application/fetchApplicationBadgesForCurrentUser",
  async (args: FetchApplicationsBadgeCountArgs, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const badgeCount = await applicationService.fetchApplicationsBadgeCount(args);
    return badgeCount;
  }
);

export const fetchConsentApplicationsForCurrentUser = createAsyncThunk(
  "domainData/application/fetchConsentApplicationsForCurrentUser",
  async (_: void, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    await thunkAPI.dispatch(fetchPropertiesForCurrentUser());
    const {
      applications: consentApplications,
      participants: consentParticipants,
    } = await applicationService.fetchApplicationConsentsForCurrentUser();

    batch(() => {
      thunkAPI.dispatch(loadParticipants(consentParticipants));
      thunkAPI.dispatch(loadApplications(consentApplications));
    });
  }
);

export const fetchApplicationTemplatesForCurrentUser = createAsyncThunk(
  "domainData/application/fetchApplicationTemplatesForCurrentUser",
  async (_: void, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;
    const {
      templates,
      applications,
      participants,
    } = await applicationService.fetchApplicationTemplatesForCurrentUser();
    await thunkAPI.dispatch(loadParticipants(participants));
    await thunkAPI.dispatch(loadApplicationTemplates(templates));
    await thunkAPI.dispatch(loadApplications(applications));
  }
);

export const createApplicationTemplateFromApplication = createAsyncThunk(
  "domainData/application/createApplicationTemplateFromApplication",
  async (
    payload: Parameters<typeof serviceContainer.cradle.applicationService.createTemplateFromApplication>[0],
    thunkAPI
  ) => {
    const {
      template,
      application,
      participants,
    } = await serviceContainer.cradle.applicationService.createTemplateFromApplication(payload);
    batch(() => {
      thunkAPI.dispatch(loadApplication(application));
      thunkAPI.dispatch(loadParticipants(participants));
      thunkAPI.dispatch(loadApplicationTemplate(template));
    });
  }
);

export const createApplication = createAsyncThunk(
  "domainData/application/createApplication",
  async (
    args: {
      jurisdictionId: number;
      formId: number;
      authorityId: number;
      address: Omit<IAddressEntity, "fullAddress">;
      parcel?: IParcelEntity;
    },
    thunkAPI
  ) => {
    const { application } = await serviceContainer.cradle.applicationService.createApplication({
      ...args,
    });
    return application;
  }
);

export const createDuplicateApplication = createAsyncThunk(
  "domainData/application/createApplicationBasedOnApplication",
  async (
    args: {
      applicationId: number;
      authorityId: number;
      address: Omit<IAddressEntity, "fullAddress">;
      parcel?: IParcelEntity;
    },
    thunkAPI
  ) => {
    const { application, participants } = await serviceContainer.cradle.applicationService.createDuplicateApplication({
      applicationId: args.applicationId,
      address: args.address,
      authorityId: args.authorityId,
      parcel: args.parcel,
    });
    batch(() => {
      thunkAPI.dispatch(loadParticipants(participants));
      thunkAPI.dispatch(loadApplication(application));
    });
    await thunkAPI.dispatch(fetchPropertiesForCurrentUser());
    return application;
  }
);

export const createApplicationFromTemplate = createAsyncThunk(
  "domainData/application/createApplicationBasedOnApplication",
  async (
    args: {
      applicationId: number;
      authorityId: number;
      address: Omit<IAddressEntity, "fullAddress">;
      parcel?: IParcelEntity;
    },
    thunkAPI
  ) => {
    const {
      application,
      participants,
    } = await serviceContainer.cradle.applicationService.createApplicationFromTemplate({
      applicationId: args.applicationId,
      address: args.address,
      authorityId: args.authorityId,
      parcel: args.parcel,
    });
    batch(() => {
      thunkAPI.dispatch(loadParticipants(participants));
      thunkAPI.dispatch(loadApplication(application));
    });
    await thunkAPI.dispatch(fetchPropertiesForCurrentUser());
    return application;
  }
);

export const createChildApplication = createAsyncThunk(
  "domainData/application/createChildApplication",
  async (
    args: {
      formId: number;
      parentApplicationId: number;
    },
    thunkAPI
  ) => {
    const { application } = await serviceContainer.cradle.applicationService.createChildApplication({
      formId: args.formId,
      parentApplicationId: args.parentApplicationId,
    });

    thunkAPI.dispatch(loadApplication(application));
    return application;
  }
);

export const fetchApplicationById = createAsyncThunk(
  "domainData/application/fetchApplication",
  async (applicationId: number, thunkAPI) => {
    const {
      application,
      requiredDocuments,
      requiredInspections,
      participants,
      template,
    } = await serviceContainer.cradle.applicationService.fetchApplicationById(applicationId);
    batch(() => {
      thunkAPI.dispatch(loadApplication(application));
      thunkAPI.dispatch(loadParticipants(participants));
      if (!isEmpty(requiredDocuments)) {
        thunkAPI.dispatch(loadRequiredDocuments(requiredDocuments));
      }
      if (!isEmpty(requiredInspections)) {
        thunkAPI.dispatch(loadRequiredInspections(requiredInspections));
      }

      if (template) {
        thunkAPI.dispatch(loadApplicationTemplate(template));
      }
    });
  }
);

export const fetchChildApplications = createAsyncThunk(
  "domainData/application/fetchChildApplications",
  async (applicationId: number, thunkAPI) => {
    const applicationService = serviceContainer.cradle.applicationService;

    const { applications, participants } = await applicationService.fetchChildApplications(applicationId);

    batch(() => {
      thunkAPI.dispatch(loadParticipants(participants));
      thunkAPI.dispatch(loadApplications(applications));
    });
  }
);

export const deleteApplicationById = createAsyncThunk(
  "domainData/application/deleteApplication",
  async (applicationId: number, thunkAPI) => {
    await serviceContainer.cradle.applicationService.deleteApplicationById(applicationId);
    batch(() => {
      thunkAPI.dispatch(removeApplicationTemplate(applicationId));
      thunkAPI.dispatch(removeParticipantsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationDocumentContainerRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationDocumentRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationStepRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationStepDataByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationValidationResultsByApplicationId(applicationId));
    });
    return applicationId;
  }
);

export const submitApplication = createAsyncThunk(
  "domainData/application/applicationSubmission",
  async (args: { applicationId: number; signature: string }, thunkAPI) => {
    const { application } = await serviceContainer.cradle.applicationService.submitApplication({
      ...args,
    });
    thunkAPI.dispatch(removeApplicationDocumentRelationsByApplicationId(args.applicationId));
    return application;
  }
);

export const updateApplicationUserPreferences = createAsyncThunk(
  "domainData/application/updateApplicationUserPreferences",
  async (args: { applicationId: number; isHidden: boolean }, thunkAPI) => {
    const userPreferences = await serviceContainer.cradle.applicationService.updateApplicationUserPreferences({
      applicationId: args.applicationId,
      isHidden: args.isHidden,
    });

    try {
      await Promise.all([
        thunkAPI.dispatch(fetchSubmittedApplicationsBadgeCount()),
        thunkAPI.dispatch(fetchArchivedApplicationsBadgeCount()),
        thunkAPI.dispatch(fetchCurrentConsentsBadgeCount()),
        thunkAPI.dispatch(fetchArchivedConsentsBadgeCount()),
      ]);
    } catch (e) {
      loglevel.error(`Error when re-fetching badges count:`, e);
    }

    return { applicationId: args.applicationId, userPreferences };
  }
);

// Reducer

export const defaultApplicationState = applicationAdapter.getInitialState();

// TODO BLD-2060 Add case to update required document
export const applicationReducer = createReducer<typeof defaultApplicationState>(defaultApplicationState, (builder) =>
  builder
    .addCase(createApplication.fulfilled, applicationAdapter.addOne)
    .addCase(submitApplication.fulfilled, applicationAdapter.upsertOne)
    .addCase(deleteApplicationById.fulfilled, applicationAdapter.removeOne)
    .addCase(loadApplications, applicationAdapter.upsertMany)
    .addCase(loadApplication, applicationAdapter.upsertOne)
    .addCase(updateApplicationUserPreferences.fulfilled, (draft, action) => {
      const applicationId = action.payload.applicationId;
      const preferences = action.payload.userPreferences;
      const entity = draft.entities[applicationId];
      if (entity) {
        entity.isHidden = preferences.isHidden;
      }
    })
);

// Selectors

export const {
  selectById: selectApplicationEntityById,
  selectIds: selectApplicationEntityIds,
  selectEntities: selectApplicationEntities,
  selectAll: selectAllApplicationEntities,
  selectTotal: selectTotalApplicationEntities,
} = applicationAdapter.getSelectors((state: RootState) => state.domainData.application);

export const selectDraftAndConsentApplicationsForCurrentUser = createDeepEqualSelector(
  [selectAllApplicationEntities],
  (entities) => {
    return entities.filter((entity) =>
      [ApplicationRecordType.Draft, ApplicationRecordType.Consent].includes(entity.recordType)
    );
  }
);

export const selectDraftApplicationsForCurrentUser = createDeepEqualSelector(
  [selectAllApplicationEntities],
  (applications) => {
    return applications.filter((application) => application.recordType === ApplicationRecordType.Draft);
  }
);

export const selectConsentApplicationsForCurrentUser = createDeepEqualSelector(
  [selectAllApplicationEntities],
  (applications) => {
    return applications.filter((application) => application.recordType === ApplicationRecordType.Consent);
  }
);

export const selectCanUserEditApplicationById = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    if (!application) {
      return false;
    }

    return rankAccessLevel(application.accessLevel) >= rankAccessLevel(ParticipantAccessLevel.Edit);
  }
);

export const selectCanUserManageApplicationById = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    if (!application) {
      return false;
    }

    return rankAccessLevel(application.accessLevel) >= rankAccessLevel(ParticipantAccessLevel.Manage);
  }
);

export const selectIsApplicationRequiresAttention = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    if (!application) {
      return false;
    }

    return Boolean(application.hasOutstandingPayments || application.hasOutstandingRFIs);
  }
);

export const selectCanDeleteApplication = createDeepEqualSelector([selectApplicationEntityById], (application) => {
  if (!application) {
    return false;
  }

  return application.status === ApplicationStatus.Draft;
});

export const selectCanEditApplicationFormForApplication = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    const allowedStatuses = [ApplicationStatus.Draft, ApplicationStatus.VRFISuspended];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectIsApplicationInProgress = createDeepEqualSelector([selectApplicationEntityById], (application) => {
  if (!application) {
    return false;
  }

  return application.status === ApplicationStatus.InProgress || application.status === ApplicationStatus.VRFISuspended;
});

export const selectIsApplicationMigrated = createDeepEqualSelector([selectApplicationEntityById], (application) => {
  if (!application) {
    return false;
  }

  return !!application.migrationSource;
});

export const selectCanEditParticipantsForApplication = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    const allowedStatuses = [
      ApplicationStatus.Draft,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
      ApplicationStatus.Refused,
    ];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectCanActionRFIPointForApplication = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    const allowedStatuses = [
      ApplicationStatus.Submitted,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
    ];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectCanMakePaymentForApplication = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    const allowedStatuses = [
      ApplicationStatus.Submitted,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
      ApplicationStatus.Completed,
      ApplicationStatus.Refused,
      ApplicationStatus.Lapsed,
      ApplicationStatus.Withdrawn,
      ApplicationStatus.Rejected,
    ];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectCanUploadRequiredDocumentsForApplication = createDeepEqualSelector(
  [selectApplicationEntityById],
  (application) => {
    const allowedStatuses = [
      ApplicationStatus.Submitted,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
      ApplicationStatus.Refused,
    ];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectCanDuplicateApplication = createDeepEqualSelector([selectApplicationEntityById], (application) => {
  const allowedStatuses = [
    ApplicationStatus.Draft,
    ApplicationStatus.Submitting,
    ApplicationStatus.Submitted,
    ApplicationStatus.VRFISuspended,
    ApplicationStatus.InProgress,
    ApplicationStatus.Completed,
    ApplicationStatus.Refused,
    ApplicationStatus.Rejected,
    ApplicationStatus.Lapsed,
    ApplicationStatus.Withdrawn,
  ];

  if (!application) {
    return false;
  }

  return allowedStatuses.includes(application.status);
});

export const selectCanSubmitApplication = createDeepEqualSelector(
  [selectApplicationEntityById, selectCanUserManageApplicationById],
  (application, canUserManageApplication) => {
    if (!application) {
      return false;
    }

    if (!canUserManageApplication) {
      return false;
    }

    if (application.status === ApplicationStatus.VRFISuspended) {
      return false;
    }

    return application.recordType === ApplicationRecordType.Draft;
  }
);

export const selectChildApplicationsByParentId = createDeepEqualSelector(
  [selectAllApplicationEntities, (state: RootState, parentApplicationId: number) => parentApplicationId],
  (applications, parentApplicationId) => {
    return applications.filter((application) => application.parentApplicationId === parentApplicationId);
  }
);

export const selectParentApplicationByChildId = createDeepEqualSelector(
  [selectAllApplicationEntities, (state: RootState, childApplicationId: number) => childApplicationId],
  (applications, childApplicationId) => {
    const childApplication = applications.find((application) => application.id === childApplicationId);
    return applications.find((application) => application.id === childApplication?.parentApplicationId);
  }
);
