import { serviceContainer } from "services/serviceContainer";

export const formValidationUtils = {
  getRegexForEmail: (): RegExp => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  },
  getRegexForPassword: (): RegExp => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#.[\]{}()"/\\,<>':;|_~`=+-])[A-Za-z\d@$!%*?&^#.[@$!%*?&^#.[\]{}()"/\\,<>':;|_~`=+-]{8,}$/;
  },
  getRegexForName: (): RegExp => {
    return /^[\p{L}](?:[\p{L} ,.'-]*[\p{L}.'])?$/u;
  },
  getRegexForTrimmedName: (): RegExp => {
    return /^[\p{L} ](?:[\p{L} ,.'-]*[\p{L}.' ])?$/u;
  },
  getRegexForOrganisationName: (): RegExp => {
    return /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '._~?!-]{2,}$/u;
  },
  getRegexForContactNumber: (countryCode?: string): RegExp => {
    // NZ
    if (countryCode === "+64") {
      return /^0([34679]((?:\s?)\d){7}|2((?:\s?)\d){7,9})$/;
    }

    // Other
    return /^((?:\s?)\d){7,14}$/;
  },
  getRegexForQualificationNumber: (): RegExp => {
    return /^BP[0-9]{6}$/;
  },
  getErrorMessageForEmail: (): string => {
    return serviceContainer.cradle.i18n.t("Invalid email format");
  },
  getErrorMessageForPassword: (): string => {
    return serviceContainer.cradle.i18n.t("Requires at least 8, upper, lower, numerical & special characters");
  },
  getErrorMessageForName: (): string => {
    return serviceContainer.cradle.i18n.t("Please enter a name with a valid format");
  },
  getErrorMessageForOrganisationName: (): string => {
    return serviceContainer.cradle.i18n.t("Please enter a valid organisation name");
  },
  getErrorMessageForRequiredField: (fieldName?: string): string => {
    // TODO: May use interpolate for fieldName
    return serviceContainer.cradle.i18n.t("Required");
  },
  getErrorMessageForDuplicateEmail: (): string => {
    return serviceContainer.cradle.i18n.t("Email must be unique across application participants");
  },
  getErrorMessageForContactNumber: (): string => {
    return serviceContainer.cradle.i18n.t("Contact number is not valid");
  },
  getErrorMessageForRequiredContactNumber: (): string => {
    return serviceContainer.cradle.i18n.t("You must provide a valid contact number");
  },
  getErrorMessageForQualificationNumber: (): string => {
    return serviceContainer.cradle.i18n.t("Qualification number is not valid");
  },
  getErrorMessageForDuplicateLicenseIds: (): string => {
    return serviceContainer.cradle.i18n.t("This license number is already in use on this application");
  },
};
