import React from "react";
import { useTranslation } from "react-i18next";
import FlexBox from "components/FlexBox/FlexBox";
import FontIcon from "components/FontIcon/FontIcon";
import { Collapse } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import ActionItem, { IActionItem } from "components/ActionSection/ActionItem";

type Props = {
  items: IActionItem[];
};

const ActionSection: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const handleToggleCollapseClick = React.useCallback(() => {
    setIsCollapsed((v) => !v);
  }, []);

  if (items.length === 0) {
    return null;
  }

  return (
    <FlexBox data-testid={"ActionSection"} direction="column" spacing={4}>
      <FlexBox>
        <StyledActionTitle onClick={handleToggleCollapseClick}>
          {t(`Actions`)}
          <FontIcon name={isCollapsed ? "chevron-down" : "chevron-up"} />
        </StyledActionTitle>
      </FlexBox>
      <Collapse in={!isCollapsed}>
        {items.map((item) => (
          <ActionItem item={item} key={item.title} />
        ))}
      </Collapse>
    </FlexBox>
  );
};

const StyledActionTitle = styled.button(
  ({ theme }) => css`
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    font-size: 16px;
    padding: 0;
    color: ${theme.palette.objective.dark.night};
    ${theme.mixins.flexGap("8px")}
  `
);

export default ActionSection;
