export enum OutstandingActionType {
  RFI = "RFI",
  PAYMENT = "PAYMENT",
}

export const OutstandingActionTypeUtil = {
  parse: (actionType: string): OutstandingActionType | null => {
    switch (actionType) {
      case "RFI":
        return OutstandingActionType.RFI;
      case "PAYMENT":
        return OutstandingActionType.PAYMENT;
      default:
        return null;
    }
  },
};
