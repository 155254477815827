import React from "react";
import styled, { css } from "styled-components/macro";
import { Link } from "@material-ui/core";
import FontIcon from "components/FontIcon/FontIcon";

type Props = {
  displayValue: string;
  iconName: string;
  linkUrl: string;
};

const InfoLink: React.FC<Props> = ({ displayValue, iconName, linkUrl }) => {
  if (!displayValue) {
    return null;
  }

  return (
    <StyledContainer data-testid="InfoLink">
      <span>
        <FontIcon name={iconName} />
      </span>
      <StyledLink href={linkUrl} target={"_blank"} rel="noopener">
        {displayValue}
      </StyledLink>
    </StyledContainer>
  );
};

const StyledContainer = styled.div(
  ({ theme }) => css`
    line-height: 25px;
    display: flex;
    ${theme.mixins.flexGap("8px")}
    font-size: 14px;
    color: ${theme.palette.text.secondary};
  `
);
const StyledLink = styled(Link)(
  ({ theme }) => css`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  `
);
export default InfoLink;
