import { unwrapResult } from "@reduxjs/toolkit";
import React from "react";
import { fetchApplicationById, selectApplicationEntityById } from "store/domain-data/application/application";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { useToast } from "hooks/useToast";
import { useTranslation } from "react-i18next";

export const useApplicationById = (applicationId: number) => {
  // Common
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const { toastError } = useToast();

  // Effect: Load application
  React.useEffect(() => {
    if (!applicationId) {
      return;
    }

    dispatch(fetchApplicationById(applicationId))
      .then(unwrapResult)
      .catch(() => {
        toastError(t(`Fail to fetch application, id: {{id}}`, { id: applicationId }));
      });
  }, [dispatch, applicationId, t, toastError]);

  return useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
};
