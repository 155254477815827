import React from "react";
import styled, { css } from "styled-components/macro";
import FontIcon from "components/FontIcon/FontIcon";

type Props = {};

const AlertIcon: React.FC<Props> = ({ ...otherProps }) => {
  return <StyledAlertIcon role={"alert"} name={"alert"} fontSize={18} padding={2} {...otherProps} />;
};

export default AlertIcon;

const StyledAlertIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: ${theme.palette.objective.red.main};
    font-weight: 600;
  `
);
