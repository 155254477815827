import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled, { css } from "styled-components/macro";

type Props = {
  size?: "normal" | "large";
  "data-testid"?: string;
};

const ButtonLoadingIcon: React.FC<Props> = ({ size = "normal", "data-testid": dataTestId = "ButtonLoadingIcon" }) => {
  const sizeInPx = size === "normal" ? "16px" : "24px";

  return <StyledCircularProgress size={sizeInPx} data-testid={dataTestId} />;
};

const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => css`
    color: ${theme.palette.objective.light.allspice};
  `
);

export default ButtonLoadingIcon;
