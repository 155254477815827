export enum ApplicationValidationResultRuleType {
  FormIORule = "FormioValidationRule",
  DocumentContainer = "DocumentContainer",
  Other = "Other",
}

export const ApplicationValidationResultRuleTypeUtil = {
  parse: (str: string): ApplicationValidationResultRuleType => {
    switch (str) {
      case "FormioValidationRule":
        return ApplicationValidationResultRuleType.FormIORule;
      case "DocumentContainer":
        return ApplicationValidationResultRuleType.DocumentContainer;
      default:
        return ApplicationValidationResultRuleType.Other;
    }
  },
};
