export type IRegisteredApplicantInvitationEntity = {
  timestamp: string;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  status: RegisteredApplicantInvitationStatus;
  sendingDate: string;
  expiryDate: string;
  userId: number;
  registeredApplicantId: string;
  bcaCode: string;
};

export enum RegisteredApplicantInvitationStatus {
  Accepted = "Accepted",
  Pending = "Pending",
  Rejected = "Rejected",
}

export const RegisteredApplicantInvitationStatusUtil = {
  parse: (str: string): RegisteredApplicantInvitationStatus => {
    for (const value of Object.values(RegisteredApplicantInvitationStatus)) {
      if (str === value.toString()) {
        return value;
      }
    }

    return RegisteredApplicantInvitationStatus.Pending;
  },
};
