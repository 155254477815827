import React from "react";
import { MenuProps, Menu as MuiMenu } from "@material-ui/core";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const Menu: React.FC<MenuProps> = React.forwardRef(
  ({ anchorOrigin = defaultAnchorOrigin, getContentAnchorEl = null, ...props }, ref) => {
    return <MuiMenu ref={ref} anchorOrigin={anchorOrigin} getContentAnchorEl={getContentAnchorEl} {...props} />;
  }
);

export default Menu;
