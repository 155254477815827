import React from "react";
import styled, { css } from "styled-components/macro";
import TextButton from "components/odl-v2/Button/TextButton";
import { disabledMixin } from "components/odl-v2/Button/mixins/disabledMixin";
import { buttonTextColor } from "components/odl-v2/Alert/mixins/buttonTextColor";
import { Color } from "@material-ui/lab/Alert";

type Props = React.ComponentProps<typeof TextButton> & {
  severity: Color;
};

const AlertAction: React.FC<Props> = React.forwardRef(
  ({ children, severity, disabled = false, ...otherProps }, ref) => {
    return (
      <StyledTextButton disabled={disabled} $severity={severity} ref={ref} data-testid={"AlertAction"} {...otherProps}>
        {children}
      </StyledTextButton>
    );
  }
);

const StyledTextButton = styled(TextButton)<{ $severity: Color }>(
  ({ theme, disabled, $severity }) => css`
    ${buttonTextColor($severity)};
    ${disabledMixin(disabled)}
    background: none;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    &:focus-visible {
      background: rgba(0, 0, 0, 0.08);
      outline: none;
      border: ${theme.palette.objective.blue.main} solid 1px;
    }
  `
);

export default AlertAction;
