// language=ejs
const label = `<!-- Label -->
{% const isReadOnly = ctx.options.readOnly %}

<!-- Begin: Readonly mode -->
{% if (isReadOnly) { %}

<label
        class="col-form-label {{ctx.label.className}}"
        for="{{ctx.instance.id}}-{{ctx.component.key}}"
>
    {{ ctx.t(ctx.component.label) }}:
    {% if (ctx.component.tooltip) { %}
    <i ref="tooltip" class="{{ctx.iconClass('question-sign')}} text-muted" data-tooltip="{{ctx.component.tooltip}}"></i>
    {% } %}
</label>

{% } %}
<!-- End: Readonly mode -->

<!-- Begin: Form mode -->
{% if (!isReadOnly) { %}

<label
        class="col-form-label {{ctx.label.className}}"
        for="{{ctx.instance.id}}-{{ctx.component.key}}"
>
    {{ ctx.t(ctx.component.label) }}
    {% if (ctx.component.tooltip) { %}
    <i ref="tooltip" class="{{ctx.iconClass('question-sign')}} text-muted" data-tooltip="{{ctx.component.tooltip}}"></i>
    {% } %}
</label>

{% } %}
<!-- End: Form mode -->
`;
export default label;
