export enum PaymentStatus {
  AwaitingPayment = "AwaitingPayment",
  DepositPending = "DepositPending",
  Paid = "Paid",
}

export enum PaymentMethod {
  Gateway = "Gateway",
  Deposit = "Deposit",
  Council = "Council",
}

export const PaymentStatusUtil = {
  parse: (status: string): PaymentStatus => {
    switch (status) {
      case "AwaitingPayment":
      case "AWAITING_PAYMENT":
        return PaymentStatus.AwaitingPayment;
      case "DepositPending":
      case "DEPOSIT_PENDING":
        return PaymentStatus.DepositPending;
      case "Paid":
      case "PAID":
        return PaymentStatus.Paid;
      default:
        return PaymentStatus.AwaitingPayment;
    }
  },
};

export const PaymentMethodUtil = {
  parse: (method: string): PaymentMethod => {
    return PaymentMethodUtil.parseWithNull(method) || PaymentMethod.Gateway;
  },
  parseWithNull: (method: string): PaymentMethod | null => {
    switch (method) {
      case "Gateway":
      case "GATEWAY":
        return PaymentMethod.Gateway;
      case "Deposit":
      case "DEPOSIT":
        return PaymentMethod.Deposit;
      case "Council":
      case "COUNCIL":
        return PaymentMethod.Council;
      default:
        return null;
    }
  },
};

export type IPaymentEntity = {
  id: number;
  applicationId: number;
  documentContainerId: number;
  description: string;
  displayOrder: number;
  guid: string;
  paymentDate: string;
  reference: string;
  status: PaymentStatus;
  baseAmount: number;
  surchargeAmount: number;
  issuedDate: string;
  invoiceDocumentName: string;
  transactions: IPaymentTransaction[];
  paymentMethod: PaymentMethod | null;
  currency: string;
};

export type IPaymentTransaction = {
  transactionId: number;
  transactionReference: string;
  transactionDate: string;
  paymentMethod: PaymentMethod;
  proofDocumentName?: string;
  surchargePaid: number;
};

export enum TransactionResult {
  Approved = "Approved",
  Declined = "Declined",
  Error = "Error",
}

export const TransactionResultUtil = {
  parse: (str: string): TransactionResult => {
    switch (str) {
      case "Approved":
        return TransactionResult.Approved;
      case "Declined":
        return TransactionResult.Declined;
      case "Error":
        return TransactionResult.Error;
      default:
        return TransactionResult.Declined;
    }
  },
};
