import React from "react";
import FlexBox from "../FlexBox/FlexBox";
import { Formik } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import QualificationFormContent from "components/ParticipantEditDialog/QualificationFormContent";
import { getQualificationFormSchema } from "./functions/getQualificationFormSchema";
import { useStoreSelector } from "store/hooks";
import { selectAllQualificationTypeEntities } from "store/domain-data/qualification-type/qualificationType";
import { QualificationViewContext } from "components/ParticipantEditDialog/contexts/QualificationViewContext";

type AddQualificationFormikFormProps = {};

const AddQualificationFormikForm: React.FC<AddQualificationFormikFormProps> = () => {
  const qualificationTypes = useStoreSelector(selectAllQualificationTypeEntities);
  const { qualificationName } = React.useContext(QualificationViewContext);

  const initialValues = React.useMemo(() => {
    return {
      qualificationName: qualificationName,
      licensingClasses: [],
      otherQualificationType: "",
      qualificationNumber: "",
      warnings: [],
    };
  }, [qualificationName]);

  const formikSchema = React.useMemo(() => {
    const qualificationType = qualificationTypes.find(
      (qualificationType) => qualificationType.name === qualificationName
    );
    return getQualificationFormSchema(qualificationType);
  }, [qualificationName, qualificationTypes]);

  return (
    <FlexBox flexGrow={1}>
      <Formik<IQualificationEntity>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isInitialValid={false}
        enableReinitialize={true}
        validateOnChange={true}
        validationSchema={formikSchema}
      >
        {() => <QualificationFormContent />}
      </Formik>
    </FlexBox>
  );
};

const handleSubmit = () => {
  // No form submission should happen
};

export default AddQualificationFormikForm;
