import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import {
  IJurisdictionFormDocumentTypeRelation,
  JurisdictionFormDocumentTypeDataUtil,
} from "models/IJurisdictionFormDocumentTypeRelation";

// Entity Adapter

const jurisdictionFormDocumentTypeRelationAdapter = createEntityAdapter<IJurisdictionFormDocumentTypeRelation>({
  selectId: (documentFormTypes) => JurisdictionFormDocumentTypeDataUtil.generateEntityKey(documentFormTypes),
  sortComparer: (a, b) => {
    if (a.jurisdictionFormId !== b.jurisdictionFormId) {
      return a.jurisdictionFormId - b.jurisdictionFormId;
    }

    return a.documentTypeId - b.documentTypeId;
  },
});

// Action & Thunks

export const upsertFormDocumentTypeRelations = createAction<IJurisdictionFormDocumentTypeRelation[]>(
  "domainData/formDocumentTypes/updateFormDocumentTypeRelation"
);

// Reducer

export const jurisdictionFormDocumentTypeRelationReducer = createReducer(
  jurisdictionFormDocumentTypeRelationAdapter.getInitialState(),
  (builder) =>
    builder.addCase(upsertFormDocumentTypeRelations, (draft, action) => {
      jurisdictionFormDocumentTypeRelationAdapter.upsertMany(draft, action.payload);
    })
);

// Selectors

export const {
  selectAll: selectAllJurisdictionFormDocumentTypeRelations,
} = jurisdictionFormDocumentTypeRelationAdapter.getSelectors(
  (state: RootState) => state.domainData.jurisdictionFormDocumentTypeRelation
);

export const selectJurisdictionFormDocumentTypeRelationsByJurisdictionFormId = createDeepEqualSelector(
  [
    selectAllJurisdictionFormDocumentTypeRelations,
    (state: RootState, jurisdictionFormId: number) => jurisdictionFormId,
  ],
  (allRelations, jurisdictionFormId) => {
    return allRelations.filter((rel) => rel.jurisdictionFormId === jurisdictionFormId);
  }
);
