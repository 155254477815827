import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { DocumentCategoryIdentifier, IDocumentCategoryEntity } from "models/DocumentCategory.model";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { selectApplicationEntityById } from "store/domain-data/application/application";

// Entity Adapter

const documentCategoriesAdapter = createEntityAdapter<IDocumentCategoryEntity>({
  selectId: (category) => category.id,
  sortComparer: (a, b) => a.displayOrder - b.displayOrder,
});

// Action & Thunks

export const loadDocumentCategories = createAction<IDocumentCategoryEntity[]>(
  "domainData/documentCategory/loadDocumentCategories"
);

// Reducer

export const defaultDocumentCategoryState = documentCategoriesAdapter.getInitialState();

export const documentCategoryReducer = createReducer<typeof defaultDocumentCategoryState>(
  defaultDocumentCategoryState,
  (builder) => builder.addCase(loadDocumentCategories, documentCategoriesAdapter.upsertMany)
);

// Selectors

export const {
  selectAll: selectAllDocumentCategoryEntities,
  selectById: selectDocumentCategoryById,
} = documentCategoriesAdapter.getSelectors((state: RootState) => state.domainData.documentCategory);

export const selectDocumentCategoriesByApplicationId = createDeepEqualSelector(
  [
    selectAllDocumentCategoryEntities,
    (state: RootState, { applicationId }: { applicationId: number }) =>
      selectApplicationEntityById(state, applicationId),
  ],
  (entities, application) => {
    return entities.filter((entity) => entity.jurisdictionId === application?.jurisdictionId);
  }
);

export const selectDocumentCategoryForApplicationByIdentifier = createDeepEqualSelector(
  [
    selectAllDocumentCategoryEntities,
    (state: RootState, { applicationId }: { applicationId: number }) =>
      selectApplicationEntityById(state, applicationId),
    (state: RootState, { identifier }: { identifier: DocumentCategoryIdentifier }) => identifier,
  ],
  (entities, application, identifier) => {
    return entities.find(
      (entity) => entity.jurisdictionId === application?.jurisdictionId && entity.identifier === identifier
    );
  }
);
