import React from "react";
import Box from "components/Box/Box";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { serviceContainer } from "services/serviceContainer";
import Guard from "components/Guard/Guard";
import MessageSection from "pages/authentication/MessageSection";
import LinkAction from "pages/authentication/LinkAction";

type Props = {
  verificationCode: string;
  email: string;
};

const RegistrationConfirmationSection: React.FC<Props> = ({ verificationCode, email }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isConfirming, setIsConfirming] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    if (!verificationCode || !email) {
      history.replace("/");
      return;
    }

    const confirmRegistration = async () => {
      setIsConfirming(true);

      try {
        await serviceContainer.cradle.authenticationService.confirmRegistration(email, verificationCode);
        setIsConfirmed(true);
      } catch (e) {
        setError(e);
      } finally {
        setIsConfirming(false);
      }
    };

    confirmRegistration();
  }, [verificationCode, email, history]);

  return (
    <Box data-testid="RegistrationConfirmationSection" paddingTop={4}>
      <Guard condition={isConfirming}>
        <MessageSection title={t(`Confirming Registration`)}>
          {t(`Your account registration is currently being verified.`)}
        </MessageSection>
      </Guard>

      <Guard condition={isConfirmed}>
        <MessageSection title={t(`Registration Confirmed`)}>
          {t(`Your account has been successfully confirmed.`)}
          <LinkAction to={"/"} hint={t(`You can now`)} action={t(`login here`)} />
        </MessageSection>
      </Guard>

      <Guard condition={Boolean(error)}>
        <MessageSection title={t(`Confirmation Failed`)}>
          {t(`We are not able to confirm your registration.`)}
        </MessageSection>
      </Guard>
    </Box>
  );
};

export default RegistrationConfirmationSection;
