import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import TextButton from "components/odl-v2/Button/TextButton";
import styled, { css } from "styled-components/macro";
import ContainedButton from "components/odl-v2/Button/ContainedButton";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import { QualificationViewContext } from "components/ParticipantEditDialog/contexts/QualificationViewContext";
import Guard from "components/Guard/Guard";

type LicenseFormButtonsProps = {};

const LicenseFormButtons: React.FC<LicenseFormButtonsProps> = () => {
  const { isValid, values } = useFormikContext<IQualificationEntity>();
  const { applyNewQualification, cancelNewQualification } = React.useContext(QualificationViewContext);

  const handleClick = React.useCallback(() => {
    applyNewQualification(values);
  }, [applyNewQualification, values]);

  return (
    <FlexBox justifyContent={"space-between"}>
      <StyledTextButton onClick={cancelNewQualification}>Cancel</StyledTextButton>
      <Guard condition={values.qualificationName}>
        <StyledContainedButton onClick={handleClick} disabled={!isValid} aria-disabled={!isValid}>
          Apply license
        </StyledContainedButton>
      </Guard>
    </FlexBox>
  );
};

const StyledTextButton = styled(TextButton)(
  ({ theme }) => css`
    justify-content: start;
    color: ${theme.palette.objective.blue.main};
    width: 200px;
    height: 32px;
    padding: 6px 12px 6px 0;
  `
);

const StyledContainedButton = styled(ContainedButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.blue.tonal};
    color: ${theme.palette.objective.dark.black};
    &:hover {
      color: ${theme.palette.objective.light.white};
      background-color: ${theme.palette.objective.blue.mid};
    }
  `
);

export default LicenseFormButtons;
