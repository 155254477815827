import { serviceContainer } from "services/serviceContainer";

const logger = serviceContainer.cradle.logger;
const LOG_PREFIX = "objectiveBuildDevTools::";

const getTokens = () => {
  const tokens = Object.keys(localStorage)
    .filter((key) => key.toLowerCase().startsWith("cognito"))
    .map((key) => ({ key, value: localStorage.getItem(key) }));
  return tokens;
};

const forceRefreshTokens = async () => {
  const cognitoService = serviceContainer.cradle.cognitoService;

  logger.debug(LOG_PREFIX, "tokens before refresh");
  logger.debug(getTokens());

  await cognitoService.forceTokenRefresh();

  logger.debug(LOG_PREFIX, "tokens after refresh");
  logger.debug(getTokens());
};

export const objectiveBuildDevTools = {
  getTokens,
  forceRefreshTokens,
};
