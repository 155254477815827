import React from "react";
import FontIcon from "components/FontIcon/FontIcon";
import FlexBox from "components/FlexBox/FlexBox";
import styled, { css } from "styled-components/macro";
import { ListItem } from "@material-ui/core";

export type INotificationSubscriptionLevelItem = {
  icon: string;
  title: string;
  description: string;
  selected?: boolean;
  onSelect?: () => void;
  disabled?: boolean;
  dataTestId?: string;
};

type Props = INotificationSubscriptionLevelItem & {
  button?: boolean;
};

const NotificationSubscriptionLevelListItem: React.FC<Props> = ({
  icon,
  title,
  description,
  selected = false,
  onSelect,
  button = true,
  disabled = false,
  dataTestId,
}) => {
  const handleClickListItem = React.useCallback(() => {
    if (!button || !onSelect || disabled) {
      return;
    }
    onSelect();
  }, [button, onSelect, disabled]);

  return (
    <StyledItemContainer
      data-testid={dataTestId || "NotificationSubscriptionLevelListItem"}
      onClick={handleClickListItem}
      selected={selected}
      //@ts-ignore
      button={button}
      component={"li"}
      role={"listitem"}
    >
      <FontIcon name={icon} fontSize={18} width={24} display={"flex"} justifyContent={"center"} />
      <FlexBox direction={"column"} spacing={3}>
        <StyledItemTitle>{title}</StyledItemTitle>
        <StyledItemDescription>{description}</StyledItemDescription>
      </FlexBox>
    </StyledItemContainer>
  );
};

const StyledItemContainer = styled(ListItem)(
  ({ theme, selected, button }) => css`
    display: flex;    
    padding: 16px 20px;
    align-items: flex-start;
    ${theme.mixins.flexGap("16px")}
    
    ${
      !button &&
      css`
        background: none !important;
      `
    }

    ${
      button &&
      selected &&
      css`
        color: ${theme.palette.objective.light.white};
        background-color: ${theme.palette.objective.blue.main} !important;
      `
    }

    ${
      button &&
      !selected &&
      css`
        &:hover {
          background: ${theme.palette.objective.blue.light};
        }
      `
    }
  `
);

const StyledItemTitle = styled.span(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
  `
);

const StyledItemDescription = styled.span(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
  `
);
export default NotificationSubscriptionLevelListItem;
