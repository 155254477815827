import { createAsyncThunk, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { INotificationEntity, NotificationRecipientType, NotificationSortOrder } from "models/Notification.model";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import toString from "lodash/toString";
import { NOTIFICATIONS_LIST_PAGE_SIZE, NOTIFICATIONS_POPUP_PAGE_SIZE } from "constants/configs";

// Entity Adapter

const notificationAdapter = createEntityAdapter<INotificationEntity>({
  selectId: (entity) => entity.id,
});

// Action & Thunks

export const fetchNotifications = createAsyncThunk(
  "domainData/notification/fetchNotifications",
  async (args: {
    pageNumber: number;
    pageSize: number;
    orderBy: NotificationSortOrder;
    recipientType: NotificationRecipientType;
    recipientId: string;
    lastKey?: string;
  }) => {
    const notifications = await serviceContainer.cradle.notificationService.fetchNotifications({ ...args });
    return notifications;
  }
);

export const fetchNotificationById = createAsyncThunk(
  "domainData/notification/fetchNotification",
  async (notificationId: string) => {
    const notification = await serviceContainer.cradle.notificationService.fetchNotificationById(notificationId);
    return notification;
  }
);

export const fetchLatestNotificationsForCurrentUser = createAsyncThunk(
  "domainData/notification/fetchUserNotification",
  async (_, thunkAPI) => {
    const user = selectUserInfoEntityForAuthenticatedUser(thunkAPI.getState() as RootState);

    const recipientId: number = user?.id || 0;
    if (!recipientId) {
      throw new ServiceError(ServiceErrorCode.ClientError, "User info not available");
    }

    const latestNotificationsForCurrentUser = await serviceContainer.cradle.notificationService.fetchNotifications({
      pageSize: NOTIFICATIONS_POPUP_PAGE_SIZE,
      recipientType: NotificationRecipientType.User,
      orderBy: NotificationSortOrder.Descending,
      recipientId: toString(recipientId),
    });
    return latestNotificationsForCurrentUser;
  }
);

export const fetchPaginatedUserNotifications = createAsyncThunk(
  "domainData/notification/fetchMoreUserNotifications",
  async (lastKey: string, thunkAPI) => {
    const user = selectUserInfoEntityForAuthenticatedUser(thunkAPI.getState() as RootState);

    const recipientId: number = user?.id || 0;
    if (!recipientId) {
      throw new ServiceError(ServiceErrorCode.ClientError, "User info not available");
    }

    const result = await serviceContainer.cradle.notificationService.fetchNotifications({
      pageSize: NOTIFICATIONS_LIST_PAGE_SIZE,
      recipientType: NotificationRecipientType.User,
      orderBy: NotificationSortOrder.Descending,
      recipientId: toString(recipientId),
      lastKey: toString(lastKey),
    });

    return result;
  }
);

export const fetchPaginatedApplicationNotifications = createAsyncThunk(
  "domainData/notification/fetchApplicationNotifications",
  async (args: { applicationId: number; lastKey: string }, thunkAPI) => {
    const result = await serviceContainer.cradle.notificationService.fetchNotifications({
      pageSize: NOTIFICATIONS_LIST_PAGE_SIZE,
      recipientType: NotificationRecipientType.Application,
      orderBy: NotificationSortOrder.Descending,
      recipientId: toString(args.applicationId),
      lastKey: toString(args.lastKey),
    });
    return result;
  }
);

// Reducer

export const defaultNotificationState = notificationAdapter.getInitialState();

export const notificationReducer = createReducer<EntityState<INotificationEntity>>(
  defaultNotificationState,
  (builder) => {
    builder
      .addCase(fetchLatestNotificationsForCurrentUser.fulfilled, (state, action) => {
        notificationAdapter.upsertMany(state, action.payload.notifications);
      })
      .addCase(fetchPaginatedUserNotifications.fulfilled, (state, action) => {
        notificationAdapter.upsertMany(state, action.payload.notifications);
      })
      .addCase(fetchNotificationById.fulfilled, notificationAdapter.upsertOne)
      .addCase(fetchPaginatedApplicationNotifications.fulfilled, (state, action) =>
        notificationAdapter.upsertMany(state, action.payload.notifications)
      );
  }
);

// Selectors

export const {
  selectById: selectNotificationEntityById,
  selectIds: selectNotificationEntityIds,
  selectEntities: selectNotificationEntities,
  selectAll: selectAllNotificationEntities,
  selectTotal: selectTotalNotificationEntities,
} = notificationAdapter.getSelectors((state: RootState) => state.domainData.notifications);
