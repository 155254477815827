import { IRFIPointService } from "services/rfi-point/RFIPointService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IRFIPointEntity, RFIPointType, RFIPointTypeUtil, RFIRoundUtil } from "models/RFIPoint.model";
import toInteger from "lodash/toInteger";
import { IDocumentEntity } from "models/Document.model";
import { IApplicationDocumentRelation } from "models/ApplicationDocumentRelation.model";
import { UploadStatusUtil } from "models/DocumentStatus.model";
import { DocumentErrorCodeUtil } from "models/DocumentErrorCode.model";
import toArray from "lodash/toArray";

export class RFIPointService implements IRFIPointService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchRFIPointsByApplicationId(applicationId: number) {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/rfi-points-v2`);
    const jsonArr = get(response.data, "rfiPoints");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch RFI points`));
    }
    const rfiPoints = jsonArr.map((rfiJson) => this.parseRFIPoint(rfiJson));
    return rfiPoints;
  }

  public async fetchRFIPoint(args: { applicationId: number; rfiPointId: number }) {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${args.applicationId}/rfi-points-v2/${args.rfiPointId}`
    );
    const json = response.data;
    if (!json) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch RFI point`));
    }
    const rfiPoint = this.parseRFIPoint(json);

    // RFI Point may have attached document
    let documents: IDocumentEntity[] = [];
    let applicationDocumentRelations: IApplicationDocumentRelation[] = [];

    // TODO: BE needs renaming
    const rfiDocumentsJson = get(json, "rfiPointDocuments");
    if (rfiDocumentsJson) {
      const rfiPointDocuments = this.parseRFIPointDocuments(args.applicationId, args.rfiPointId, rfiDocumentsJson);
      documents = rfiPointDocuments.map((parseRFIDocumentResult) => parseRFIDocumentResult.document);
      rfiPoint.documentNames = documents.map((document) => document.name);
      applicationDocumentRelations = rfiPointDocuments.map(
        (parseRFIDocumentResult) => parseRFIDocumentResult.applicationDocumentRelation
      );
    }

    return {
      rfiPoint,
      documents,
      applicationDocumentRelations,
    };
  }

  public async saveRFIPointResponse(args: {
    applicationId: number;
    rfiPointId: number;
    responseDetails: string;
    responseDocumentNames: string[];
  }): Promise<{
    rfiPoint: IRFIPointEntity;
    documents: IDocumentEntity[];
    applicationDocumentRelations: IApplicationDocumentRelation[];
  }> {
    const response = await this.apiClient.protectedApi.patch(
      `/user/applications/${args.applicationId}/rfi-points-v2/${args.rfiPointId}`,
      {
        responseDetails: args.responseDetails,
        responseDocumentNames: args.responseDocumentNames || [],
      }
    );
    const json = response.data;
    if (!json) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to update RFI point`));
    }
    const rfiPoint = this.parseRFIPoint(json);

    // RFI Point may have attached document
    let documents: IDocumentEntity[] = [];
    let applicationDocumentRelations: IApplicationDocumentRelation[] = [];

    // TODO: BE needs renaming
    const rfiDocumentsJson = get(json, "rfiPointDocuments");
    if (rfiDocumentsJson) {
      const parseRFIDocumentsResult = this.parseRFIPointDocuments(
        args.applicationId,
        args.rfiPointId,
        rfiDocumentsJson
      );
      documents = parseRFIDocumentsResult.map((parseRFIDocumentResult) => parseRFIDocumentResult.document);
      rfiPoint.documentNames = documents.map((document) => document.name);
      applicationDocumentRelations = parseRFIDocumentsResult.map(
        (parseRFIDocumentResult) => parseRFIDocumentResult.applicationDocumentRelation
      );
    }

    return {
      rfiPoint,
      documents,
      applicationDocumentRelations,
    };
  }

  public async submitRFIRound(args: {
    applicationId: number;
    rfiLocation: string;
    rfiRound: string;
    rfiPointType: RFIPointType;
    encodedTokens: string;
  }): Promise<void> {
    await this.apiClient.protectedApi.post(`/user/applications/${args.applicationId}/rfi-round-submissions`, {
      rfiLocation: args.rfiLocation,
      rfiRound: args.rfiRound,
      rfiType: args.rfiPointType.toUpperCase(),
    });
  }
  public async submitRFIRoundV3(args: {
    applicationId: number;
    rfiTeam: string;
    rfiRound: string;
    rfiPointType: RFIPointType;
    encodedTokens: string;
  }): Promise<void> {
    await this.apiClient.protectedApi.post(`/user/applications/${args.applicationId}/rfi-round-submissions-v3`, {
      rfiTeam: args.rfiTeam,
      rfiRound: args.rfiRound,
      rfiType: args.rfiPointType.toUpperCase(),
    });
  }

  private parseRFIPoint(json: any): IRFIPointEntity {
    const rfiPoint: IRFIPointEntity = {
      id: toInteger(get(json, "id")),
      applicationId: toInteger(get(json, "applicationId")),
      councilCreatedDate: toString(get(json, "councilCreatedDate")),
      councilSentDate: toString(get(json, "councilSentDate")),
      councilSignedOffDate: toString(get(json, "councilSignedOffDate")),
      customerSubmittedDate: toString(get(json, "customerSubmittedDate")),
      roundSubmittedByName: toString(get(json, "roundSubmittedByName")),
      roundSubmittedById: toInteger(get(json, "roundSubmittedById")),
      displayOrderInRound: toInteger(get(json, "displayOrderInRound")),
      requestDetails: toString(get(json, "requestDetails")),
      requestPersonEmail: toString(get(json, "requestPersonEmail")),
      requestPersonName: toString(get(json, "requestPersonName")),
      requestPersonPhone: toString(get(json, "requestPersonPhone")),
      responsePersonName: toString(get(json, "responsePersonName")),
      responseLastModifiedDate: toString(get(json, "responseLastModifiedDate")),
      responseDetails: toString(get(json, "responseDetails")),
      rfiPointGuid: toString(get(json, "rfiPointGuid")),
      rfiPointStatus: RFIPointTypeUtil.parseRFIPointStatus(get(json, "status")),
      rfiTeam: toString(get(json, "team")),
      rfiTitle: toString(get(json, "title")),
      rfiBuilding: toString(get(json, "building")),
      rfiLocation: toString(get(json, "rfiPointLocation")),
      rfiPointType:
        RFIPointTypeUtil.parse(toString(get(json, "rfiPointType") || get(json, "rfiType"))) || RFIPointType.Vetting,
      rfiRound: RFIRoundUtil.parse(get(json, "rfiRound")),
      rfiRoundLegacy: toString(get(json, "rfiRound")),
      documentContainerId: toInteger(get(json, "documentContainerId")),
      documentCategoryId: toInteger(get(json, "documentCategoryId")),
      previousRfiPointGuid: toString(get(json, "previousRfiPointGuid")),
      nextRfiPointGuid: toString(get(json, "nextRfiPointGuid")),
      documentNames: toArray(get(json, "documentNames")).map((item) => toString(item)),
    };
    return rfiPoint;
  }

  private parseRFIPointDocuments(applicationId: number, rfiPointId: number, jsonRFIDocumentArr: any) {
    if (!Array.isArray(jsonRFIDocumentArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch RFI point documents`));
    }
    const rfiDocuments = jsonRFIDocumentArr.map((rfiDocumentsJson) =>
      this.parseRFIPointDocument(applicationId, rfiPointId, rfiDocumentsJson)
    );
    return rfiDocuments;
  }

  private parseRFIPointDocument(applicationId: number, rfiPointId: number, json: any) {
    const document: IDocumentEntity = {
      fileName: toString(get(json, "originalName")),
      name: toString(get(json, "name")),
      fileType: toString(get(json, "mimeType")),
      fileSize: toInteger(get(json, "size")),
      fileVersion: toInteger(get(json, "version")),
      fileModifiedDate: toString(get(json, "modifiedDate")),
      referenceUrl: toString(get(json, "reference")),
      presignedUrl: toString(get(json, "presignedUrl")),
      uploadStatus: UploadStatusUtil.parse(toString(get(json, "status"))),
      errorCode: DocumentErrorCodeUtil.parse(toString(get(json, "errorCode"))),
      createdDate: toString(get(json, "createdDate")),
      isMergedFile: Boolean(get(json, "isMergedFile")),
    };

    const applicationDocumentRelation: IApplicationDocumentRelation = {
      applicationId,
      documentCategoryId: toInteger(get(json, "documentCategoryId")),
      documentName: toString(get(json, "name")),
      documentContainerId: toInteger(get(json, "documentContainerId")),
      order: 0,
    };

    return { document, applicationDocumentRelation };
  }
}
