import React from "react";
import MuiAccordionDetails, { AccordionDetailsProps } from "@material-ui/core/AccordionDetails";
import styled, { css } from "styled-components/macro";

type Props = AccordionDetailsProps;

const AccordionDetails: React.FC<Props> = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledMuiAccordionDetails ref={ref} {...props}>
      {children}
    </StyledMuiAccordionDetails>
  );
});

const StyledMuiAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => css`
    margin: 0;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
  `
);

export default AccordionDetails;
