import React from "react";
import Dialog from "components/odl-v2/Dialog/Dialog";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  fetchApplicationById,
  fetchChildApplications,
  selectApplicationEntityById,
} from "store/domain-data/application/application";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import styled, { css } from "styled-components/macro";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import { useTranslation } from "react-i18next";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import FontIcon from "components/FontIcon/FontIcon";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import { useApplicationFormNavigation } from "pages/application-form/functions/useApplicationFormNavigation";
import { fetchApplicationStepRelations } from "store/domain-data/application-step-relation/applicationStepRelation";
import { BreadcrumbStepType } from "models/BreadcrumbStepType";
import BreadcrumbSection from "components/ApplicationFormReviewDialog/BreadcrumbSection";
import { ApplicationUtil } from "models/Application.model";
import { useApplicationBuildingConsentDetails } from "pages/application-form/functions/useApplicationBuildingConsentDetails";
import { Divider } from "@material-ui/core";
import ApplicationReviewParentConsentInfo from "components/ApplicationReviewParentConsentInfo/ApplicationReviewParentConsentInfo";
import { fetchApplicationDocuments } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { fetchParticipantTypes } from "store/domain-data/participant-type/participantType";
import { fetchPaymentsByApplicationId } from "store/domain-data/payment/payment";
import { fetchRFIPointsByApplicationId } from "store/domain-data/rfi-point/rfiPoint";
import { useEventStopPropCallback } from "hooks/useEventStopPropCallback";

type Props = {
  applicationId: number;
  onClose: () => void;
};

const ApplicationFormReviewDialog: React.FC<Props> = ({ applicationId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    const initData = async () => {
      if (!applicationId) {
        return;
      }
      await dispatch(fetchApplicationById(applicationId));
      await dispatch(fetchChildApplications(applicationId));
      await dispatch(fetchApplicationDocuments(applicationId));
      await dispatch(fetchParticipantTypes(applicationId));
      await dispatch(fetchPaymentsByApplicationId(applicationId));
      await dispatch(fetchRFIPointsByApplicationId(applicationId));
    };
    initData();
  }, [dispatch, applicationId]);

  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const { breadcrumbs } = useApplicationFormNavigation(applicationId, false);

  const { isConsentFound: isParentConsentFound, consentNumber, issuedBy } = useApplicationBuildingConsentDetails(
    application?.parentApplicationId || 0
  );

  const eventStopPropCallback = useEventStopPropCallback();

  const applicationDisplayName = React.useMemo(() => ApplicationUtil.getDisplayId(application), [application]);

  const displayBreadcrumbs = React.useMemo(() => {
    const displayStepTypes = [BreadcrumbStepType.Formio, BreadcrumbStepType.Contact, BreadcrumbStepType.DocumentUpload];
    const filteredBreadcrumbs = breadcrumbs.map((breadcrumb) => {
      const steps = breadcrumb.steps.filter((step) => displayStepTypes.includes(step.type));
      return {
        ...breadcrumb,
        steps,
      };
    });
    return filteredBreadcrumbs.filter((breadcrumb) => breadcrumb.steps.length > 0);
  }, [breadcrumbs]);

  // Effect: Fetch application data required to determine enabled steps
  React.useEffect(() => {
    dispatch(fetchApplicationStepRelations(applicationId));
  }, [dispatch, applicationId]);

  if (!application) {
    return null;
  }

  return (
    <Dialog
      open={true}
      title={`View application ${applicationDisplayName}`}
      onClose={onClose}
      data-testid={`ApplicationFormDialog-${application.id}`}
      scroll={"paper"}
      onClick={eventStopPropCallback}
    >
      {isParentConsentFound && (
        <React.Fragment>
          <StyledBuildingConsentInfo>
            <ApplicationReviewParentConsentInfo
              addressLine={ApplicationUtil.getAddressWithLegalDescription(application)}
              consentNumber={consentNumber}
              issuedBy={issuedBy}
            />
          </StyledBuildingConsentInfo>
          <Divider />
        </React.Fragment>
      )}

      <StyledDialogContent>
        {displayBreadcrumbs.map((breadcrumb) => (
          <BreadcrumbSection key={breadcrumb.id} applicationId={applicationId} breadcrumb={breadcrumb} />
        ))}
      </StyledDialogContent>

      <DialogActions>
        <DialogActionCancel onClick={onClose}>{t(`Cancel`)}</DialogActionCancel>
        <HoverTooltip title={t(`Application is currently locked from further changes`)}>
          <DialogActionPrimary startIcon={<FontIcon name={"save"} />} disabled={true}>
            {t(`Editing locked`)}
          </DialogActionPrimary>
        </HoverTooltip>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    // 1084px is the ideal width when user viewing form content in review page under 1920px screen
    max-width: min(1084px, calc(100vw - 64px));
    width: min(1084px, calc(100vw - 64px));
    background-color: ${theme.palette.objective.light.day};
  `
);

const StyledBuildingConsentInfo = styled(DialogContent)(
  ({ theme }) => css`
    padding-left: 24px;
    flex: 0 0 auto;
  `
);

export default ApplicationFormReviewDialog;
