import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";

// Type
export type ApplicationFormioStepBuffer = {
  [key: string]: {
    isInited: boolean;
  };
};

// Actions & Thunks

export const startInitFormioData = createAction<{ applicationId: number; stepId: number }>(
  "appState/applicationFormioStepBufferReducer/startInitFormioData"
);
export const finishInitFormioData = createAction<{ applicationId: number; stepId: number }>(
  "appState/applicationFormioStepBufferReducer/finishInitFormioData"
);

// Reducer

const defaultState: ApplicationFormioStepBuffer = {};

export const applicationFormioStepBufferReducer = createReducer<ApplicationFormioStepBuffer>(defaultState, (builder) =>
  builder
    .addCase(startInitFormioData, (state, action) => {
      const key = getKeyForApplicationFormioStepBuffer(action.payload);
      state[key] = {
        isInited: false,
      };
    })
    .addCase(finishInitFormioData, (state, action) => {
      const key = getKeyForApplicationFormioStepBuffer(action.payload);
      state[key] = {
        isInited: true,
      };
    })
);

// Selectors

export const selectApplicationFormioStepBufferState = (state: RootState) => state.appState.applicationFormioStepBuffer;

export const selectApplicationFormioStepBuffer = createDeepEqualSelector(
  [
    selectApplicationFormioStepBufferState,
    (state: RootState, props: { applicationId: number; stepId: number }) => getKeyForApplicationFormioStepBuffer(props),
  ],
  (state, key) => state[key]
);

// Helpers

export const getKeyForApplicationFormioStepBuffer = (args: { applicationId: number; stepId: number }) => {
  const { applicationId, stepId } = args;
  return `Application#${applicationId}|Step#${stepId}`;
};
