import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel } from "@material-ui/core";
import { useFormikContext } from "formik";
import Guard from "components/Guard/Guard";
import OutlinedInput from "components/OutlinedInput/OutlinedInput";
import trim from "lodash/trim";
import styled, { css } from "styled-components/macro";

const nanoid = require("nanoid");

type Props = {
  label: string;
  fieldName: string;
  rows: number;
  noScrollWhenDisabled?: boolean;
  disabled?: boolean;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
};

const FormikTextArea: React.FC<Props> = ({
  label,
  fieldName,
  rows,
  disabled = false,
  required = false,
  noScrollWhenDisabled = false,
  onBlur,
}) => {
  const { touched, errors, handleChange, handleBlur: formikHandleBlur, setFieldValue, values } = useFormikContext<
    any
  >();

  const inputId = React.useMemo(() => fieldName + "-" + nanoid(5), [fieldName]);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);

  const handleBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLTextAreaElement>) => {
      // Trim text input
      setFieldValue(fieldName, trim(evt.target.value));

      // Custom blur handler
      if (onBlur) {
        onBlur(evt);
      }

      // General blur handler
      formikHandleBlur(evt);
    },
    [formikHandleBlur, setFieldValue, fieldName, onBlur]
  );

  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <StyledInputLabel htmlFor={inputId} shrink={false}>
        {label}
      </StyledInputLabel>

      <Guard condition={!disabled}>
        <OutlinedInput
          id={inputId}
          name={fieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[fieldName]}
          error={hasError}
          rows={rows}
          multiline={true}
        />
      </Guard>

      <Guard condition={disabled && !noScrollWhenDisabled}>
        <OutlinedInput
          id={inputId}
          name={fieldName}
          value={values[fieldName]}
          error={hasError}
          rows={rows}
          multiline={true}
          disabled={true}
        />
      </Guard>

      <Guard condition={disabled && noScrollWhenDisabled}>
        <StyledReadOnlyText aria-hidden={true} $rows={rows}>
          {values[fieldName]}
        </StyledReadOnlyText>
        <StyledOutlinedInput
          id={inputId}
          name={fieldName}
          value={values[fieldName]}
          disabled={true}
          rows={rows}
          multiline={true}
          $isVisible={false}
        />
      </Guard>

      {hasError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => css`
    font-weight: 400;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledReadOnlyText = styled("div")<{ $rows: number }>(
  ({ theme, $rows }) => css`
    padding: 16px 10px;
    color: ${theme.palette.objective.dark.night};
    background: ${theme.palette.objective.light.day};
    border: ${theme.palette.objective.light.allspice} solid 1px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-wrap;
    min-height: ${$rows * 14}px;
  `
);

// Note: workaround to display text for disabled textarea
const StyledOutlinedInput = styled(OutlinedInput)<{ $isVisible?: boolean }>(
  ({ theme, $isVisible }) => css`
    textarea {
      z-index: 1;
    }

    ${!$isVisible &&
    css`
      // Screen-reader only
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    `}
  `
);
export default FormikTextArea;
