import React from "react";
import styled, { css } from "styled-components/macro";
import { Checkbox, Link, Theme } from "@material-ui/core";
import FlexBox from "components/FlexBox/FlexBox";
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from "constants/configs";
import { Trans, useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

type Props = {
  isAcknowledged: boolean;
  onToggle: () => void;
};

const AcknowledgementCheckbox: React.FC<Props> = ({ isAcknowledged, onToggle }) => {
  const { t } = useTranslation();

  const htmlId = React.useMemo(() => `AcknowledgementCheckbox-${nanoid(5)}`, []);

  return (
    <FlexBox alignItems={"flex-start"} spacing={2} data-testid="AcknowledgementCheckbox">
      <StyledCheckbox
        id={htmlId}
        name={"acknowledgeLegal"}
        checked={isAcknowledged}
        size={"medium"}
        color="primary"
        onChange={onToggle}
      />

      <StyledLabel htmlFor={htmlId}>
        <span>
          {t(
            "I acknowledge the Consenting Authority has selected Objective Build as its platform to process building consent applications."
          )}
        </span>
        &nbsp;
        <span>
          <Trans
            defaults="I have read and accepted Objective build <0>Terms of Use</0> and Objective <1>Privacy Policy</1>."
            components={[
              <Link href={TERMS_OF_SERVICE_LINK} target="_blank" underline="none" rel="noopener noreferrer" />,
              <Link href={PRIVACY_POLICY_LINK} target="_blank" underline="none" rel="noopener noreferrer" />,
            ]}
          />
        </span>
      </StyledLabel>
    </FlexBox>
  );
};

const StyledCheckbox = styled(Checkbox)(
  ({ theme }: { theme: Theme }) => css`
    color: ${theme.palette.text.primary};
    margin: 0;
    padding: 0;

    &.MuiSvgIcon-root {
      font-size: 18px;
    }
  `
);

const StyledLabel = styled.label(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    line-height: 150%;
  `
);

export default AcknowledgementCheckbox;
