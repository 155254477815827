import React from "react";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import { useStoreDispatch } from "store/hooks";
import { setIsSideNavigationCollapsed } from "store/app-state/ui/ui";

const ScreenWidthListener: React.FC = () => {
  const { lg } = useScreenWidthMatch();
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    dispatch(setIsSideNavigationCollapsed(!lg));
  }, [dispatch, lg]);

  return null;
};

export default ScreenWidthListener;
