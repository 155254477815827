import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
// @ts-ignore
import WebViewer from "@pdftron/pdfjs-express-viewer";
import styled, { css } from "styled-components/macro";
import { useToast } from "hooks/useToast";
import { PDFTRON_LICENSE_KEY } from "constants/configs";
import Guard from "components/Guard/Guard";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import { useEventStopPropCallback } from "hooks/useEventStopPropCallback";

type Props = {
  title: string;
  getDownloadLink: () => Promise<string>;
  onClose: () => void;
};

const DocumentPreviewDialog: React.FC<Props> = ({ title, getDownloadLink, onClose, ...otherProps }) => {
  const { toastError } = useToast();

  const viewer = React.useRef<HTMLDivElement | null>(null);

  const [downloadLink, setDownloadLink] = React.useState("");
  const [isPDFRendered, setIsPDFRendered] = React.useState(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = React.useState(false);

  React.useEffect(() => {
    getDownloadLink()
      .then(setDownloadLink)
      .catch((e) => {
        toastError(e);
        // If this fails, there will be no document to show, so close the modal
        onClose();
      });
  }, [getDownloadLink, onClose, toastError]);

  React.useEffect(() => {
    const viewerEl = viewer.current;
    if (!viewerEl || !downloadLink) {
      return;
    }

    // Get PDF contents from download link
    const downloadPDFAsBlob = (link: string): Promise<Blob> => {
      return fetch(link).then((res) => res.blob());
    };
    const pdfDownloadedPromise = downloadPDFAsBlob(downloadLink);
    pdfDownloadedPromise.then(() => {
      setIsPDFDownloaded(true);
    });

    // Load Web viewer
    const webViewerPromise = WebViewer(
      {
        licenseKey: PDFTRON_LICENSE_KEY,
        path: "/pdftron/webviewer",
        isReadOnly: true,
        loadAsPDF: true,
      },
      viewerEl
    );

    // Preview PDF
    // https://pdfjs.express/documentation
    // https://pdfjs.express/api/UI.html
    const pdfViewerReadyPromise = Promise.all([pdfDownloadedPromise, webViewerPromise]);
    pdfViewerReadyPromise.then(([blob, instance]) => {
      const FitMode = instance.UI.FitMode;
      const { documentViewer } = instance.Core;
      const {
        loadDocument,
        setFitMode,
        disableFeatures,
        disableElements,
        setToolMode,
        setHeaderItems,
        downloadPdf,
      } = instance.UI;

      loadDocument(blob, { filename: title, extension: "pdf" });
      documentViewer.addEventListener("documentLoaded", () => {
        setIsPDFRendered(true);
      });

      setFitMode(FitMode.FitWidth);
      setToolMode(`Pan`);
      setHeaderItems((header: any) => {
        header.getHeader("default").push({
          img: "icon-header-download",
          index: -1,
          type: "actionButton",
          element: "downloadButton",
          onClick: downloadPdf,
        });
      });

      // Modify the controls to hide all document modification items
      disableFeatures([`TextSelection`, `ThumbnailReordering`, `ThumbnailMerging`]);
      disableElements([
        `selectToolButton`,
        `toggleNotesButton`,
        `documentControl`,
        `downloadButton`,
        `thumbnailControl`,
        `layoutHeader`,
        `singleLayoutButton`,
        `doubleLayoutButton`,
        `coverLayoutButton`,
        `viewControlsDivider1`,
        `viewControlsDivider2`,
        `rotateHeader`,
        `rotateClockwiseButton`,
        `rotateCounterClockwiseButton`,
      ]);
    });
  }, [downloadLink, title]);

  const eventStopPropCallback = useEventStopPropCallback();

  if (!document) {
    return null;
  }

  return (
    <Dialog
      open={true}
      data-testid={"DocumentPreviewDialog"}
      data-ispdfrendered={isPDFRendered}
      data-is-pdf-downloaded={isPDFDownloaded}
      onClose={onClose}
      title={title}
      {...otherProps}
      onClick={eventStopPropCallback}
    >
      <StyledDialogContent>
        <StyledViewer className="webViewer" ref={viewer} />
        <Guard condition={!isPDFRendered}>
          <StyledCentre>
            <CircularProgress size={60} />
          </StyledCentre>
        </Guard>
      </StyledDialogContent>
    </Dialog>
  );
};

const StyledViewer = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
  `
);

const StyledCentre = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    padding: 0;
    width: 90vh;
    height: 85vh;
  `
);

export default DocumentPreviewDialog;
export type DocumentPreviewDialogProps = Props;
