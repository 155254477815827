import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { IContactEntity, IContactPersonalInfoFormValues } from "models/Contact.model";
import { serviceContainer } from "services/serviceContainer";
import { createDeepEqualSelector } from "store/utils";
import { IQualificationEntity } from "models/Qualification.model";

// Entity Adapter

const contactAdapter = createEntityAdapter<IContactEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => b.id - a.id,
});

// Action & Thunks

export const loadContacts = createAction<IContactEntity[]>("domainData/contact/loadContacts");

export const fetchContactsForCurrentUser = createAsyncThunk(
  "domainData/contact/fetchContactsForCurrentUser",
  async () => {
    const contacts = await serviceContainer.cradle.contactService.fetchContactsForCurrentUserOrganisation();
    return contacts;
  }
);

export const createContact = createAsyncThunk(
  "domainData/contact/createContact",
  async (args: {
    formValues: Partial<IContactPersonalInfoFormValues>;
    qualificationValues: IQualificationEntity[];
  }) => {
    const contact = await serviceContainer.cradle.contactService.createContact(args);
    return contact;
  }
);

export const deleteContactById = createAsyncThunk("domainData/contact/deleteContactById", async (contactId: number) => {
  await serviceContainer.cradle.contactService.deleteOrganisationContactById(contactId);
  return contactId;
});

export const updateContact = createAsyncThunk(
  "domainData/contact/updateContact",
  async (args: {
    contactId: number;
    formValues: Partial<IContactPersonalInfoFormValues>;
    qualificationValues: IQualificationEntity[];
  }) => {
    return await serviceContainer.cradle.contactService.updateContact(args);
  }
);

// Reducer

export const defaultContactState = contactAdapter.getInitialState();

export const contactReducer = createReducer<typeof defaultContactState>(defaultContactState, (builder) =>
  builder
    .addCase(loadContacts, contactAdapter.upsertMany)
    .addCase(fetchContactsForCurrentUser.fulfilled, contactAdapter.upsertMany)
    .addCase(createContact.fulfilled, contactAdapter.addOne)
    .addCase(deleteContactById.fulfilled, contactAdapter.removeOne)
    .addCase(updateContact.fulfilled, contactAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectContactEntityById,
  selectIds: selectContactEntityIds,
  selectEntities: selectContactEntities,
  selectAll: selectAllContactEntities,
  selectTotal: selectTotalContactEntities,
} = contactAdapter.getSelectors((state: RootState) => state.domainData.contact);

export const selectEmailFoundOnExistingContact = createDeepEqualSelector(
  [selectAllContactEntities, (_: RootState, emailAddress: string) => emailAddress],
  (contacts, emailAddress) => {
    return contacts.some((c) => emailAddress && emailAddress.trim().toLowerCase() === c.email.toLowerCase());
  }
);
