// @ts-nocheck
// https://raw.githubusercontent.com/formio/formio.js/v4.12.1/src/components/radio/Radio.js

import set from "lodash/set";
import get from "lodash/get";
import head from "lodash/head";
import FormioRadioComponent from "@objective_build/formiojs/components/radio/Radio";
import FormioComponent from "@objective_build/formiojs/components/_classes/component/Component";

export default class RadioComponent extends FormioRadioComponent {
  /**
   * @override
   * @param component
   * @param options
   * @param data
   */
  constructor(component, options, data) {
    super(component, options, data);
    set(this, "component.getImageUrl", this.getImageUrl.bind(this));
    set(this, "component.getImageOpacity", this.getImageOpacity.bind(this));
  }

  /**
   * Get image url by option value and select state
   * @param value
   * @param selected
   */
  getImageUrl(value: string, selected: boolean): string {
    const propKey = ["option", "image", selected ? "selected" : "unselected", value].join("_");
    const url = get(this, `component.properties.${propKey}`);
    return url;
  }

  /**
   * Get image opacity based on selection state
   * @param selected
   */
  getImageOpacity(selected: boolean): number {
    return selected ? 1 : 0.4;
  }

  /**
   * Detect if the radio is an image-based radio
   */
  isImageRadio() {
    return get(this, "component.properties.display_type") === "image";
  }

  /**
   * @override
   * @param value
   * @param flags
   */
  updateValue(value, flags) {
    // Copied this code from Component.js
    const changed = FormioComponent.prototype.updateValue.call(this, value, flags);
    if (changed) {
      this.setSelectedClasses();
    }

    if (changed && this.refs.wrapper) {
      const dataValue = this.dataValue;

      this.refs.wrapper.forEach((wrapper, index) => {
        const input = this.refs.input[index];
        if (input && input.value.toString() === dataValue.toString()) {
          // Change "checked" attr
          input.setAttribute("checked", "true");

          // Update image
          if (this.isImageRadio()) {
            const img = head(wrapper.getElementsByTagName("img"));
            if (img) {
              img.style.display = "block";
              img.src = this.getImageUrl(input.value.toString(), true);
              img.style.opacity = this.getImageOpacity(true);
            }
          }
        } else {
          // Change "checked" attr
          input.removeAttribute("checked");

          // Update image
          if (this.isImageRadio()) {
            const img = head(wrapper.getElementsByTagName("img"));
            if (img) {
              img.style.display = "block";
              img.src = this.getImageUrl(input.value.toString(), false);
              img.style.opacity = this.getImageOpacity(false);
            }
          }
        }
      });
    }

    return changed;
  }
}
