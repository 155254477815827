import React from "react";
import MuiTab, { TabProps } from "@material-ui/core/Tab";
import styled, { css } from "styled-components/macro";

type Props = TabProps & {
  // Extended props, not exist in MUI
  badge?: number | string | null;
};

const Tab: React.FC<Props> = React.forwardRef(({ badge, ...tabProps }, ref) => {
  const badgeNum = badge || 0;
  return (
    <StyledMuiTab
      ref={ref}
      data-badge-label={badgeNum}
      disableRipple={true}
      disableTouchRipple={true}
      disableFocusRipple={true}
      tabIndex={tabProps.disabled ? -1 : 0}
      {...tabProps}
      $badge={badge}
    />
  );
});

const StyledMuiTab = styled(MuiTab)<{ $badge?: Props["badge"] }>(
  ({ theme, selected, disabled, $badge }) => css`
    // Normal
    position: relative;
    padding: 12px;
    min-width: 88px;
    opacity: 1 !important;
    background-color: transparent;
    border: 1px solid transparent;

    .MuiTab-wrapper {
      text-transform: none;
      color: ${theme.palette.objective.blue.main};
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      
      // Badge
      ${
        $badge &&
        css`
          &:after {
            display: inline-block;
            content: "${$badge}";
            margin-left: 8px;
            color: ${theme.palette.objective.light.white};
            background-color: ${theme.palette.objective.red.main};
            border-radius: 4px;
            padding: 0 4px;
            font-size: 14px;
            line-height: 150%;
            font-weight: 500;
          }
        `
      }

    }

    // Hover
    &:hover {
      background-color: ${theme.palette.objective.light.day};
    }
    
    // Active
    &:active {
      background-color: ${theme.palette.objective.blue.light};
    }

    // Focus
    &:focus-visible {
      border: 1px solid ${theme.palette.objective.blue.main};
    }
    
    
    // Disabled
    ${
      disabled &&
      css`
        &:after {
          background-color: ${theme.palette.objective.light.allspice} !important;
        }

        .MuiTab-wrapper {
          color: ${theme.palette.objective.light.allspice} !important;
        }
      `
    }

    // Selected
    ${
      selected &&
      css`
        &:after {
          position: absolute;
          content: "";
          left: 12px;
          right: 12px;
          bottom: 0;
          height: 4px;
          background-color: ${theme.palette.objective.blue.main};
          border-radius: 4px;
        }

        .MuiTab-wrapper {
          color: ${theme.palette.text.primary};
        }
      `
    }
  `
);

export default Tab;
