import React from "react";
import { IQualificationEntity } from "models/Qualification.model";

export enum QualificationView {
  QualificationCards = "QualificationCards",
  NewQualification = "NewQualification",
}

export type QualificationViewContextValue = {
  qualificationView: QualificationView;
  setQualificationView: React.Dispatch<React.SetStateAction<QualificationView>>;
  qualificationName: string;
  setQualificationName: React.Dispatch<React.SetStateAction<string>>;
  cancelNewQualification: () => void;
  applyNewQualification: (values: IQualificationEntity) => void;
  removeQualification: (index: number) => void;
  currentQualifications: IQualificationEntity[];
};

export const QualificationViewContext = React.createContext<QualificationViewContextValue>({
  qualificationView: QualificationView.QualificationCards,
  setQualificationView: () => {},
  qualificationName: "",
  setQualificationName: () => {},
  cancelNewQualification: () => {},
  applyNewQualification: () => {},
  removeQualification: () => {},
  currentQualifications: [],
});

type Props = {
  currentQualifications: IQualificationEntity[];
  onAddQualification: (qualificationToAdd: IQualificationEntity) => void;
  onRemoveQualification: (index: number) => void;
};

const QualificationViewContextProvider: React.FC<Props> = ({
  currentQualifications,
  onAddQualification,
  onRemoveQualification,
  children,
}) => {
  const [qualificationView, setQualificationView] = React.useState<QualificationView>(
    QualificationView.QualificationCards
  );
  const [qualificationName, setQualificationName] = React.useState("");

  const cancelNewQualification = React.useCallback(() => {
    setQualificationView(QualificationView.QualificationCards);
    setQualificationName("");
  }, [setQualificationView]);

  const applyNewQualification = React.useCallback(
    (values: IQualificationEntity) => {
      onAddQualification(values);
      setQualificationView(QualificationView.QualificationCards);
      setQualificationName("");
    },
    [onAddQualification, setQualificationView]
  );

  const removeQualification = React.useCallback(
    (index: number) => {
      onRemoveQualification(index);
    },
    [onRemoveQualification]
  );

  const contextValue = React.useMemo(() => {
    return {
      qualificationView,
      setQualificationView,
      qualificationName,
      setQualificationName,
      cancelNewQualification,
      applyNewQualification,
      removeQualification,
      currentQualifications,
    };
  }, [
    applyNewQualification,
    cancelNewQualification,
    qualificationName,
    qualificationView,
    removeQualification,
    currentQualifications,
  ]);

  return <QualificationViewContext.Provider value={contextValue}>{children}</QualificationViewContext.Provider>;
};

export default QualificationViewContextProvider;
