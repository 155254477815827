import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tabs } from "@objective_build/odl-components";
import { AuthenticationType } from "pages/authentication/AuthenticationPage.types";

type Props = {
  activeAuthenticationType: AuthenticationType;
};

const AuthenticationNavigationTabs: React.FC<Props> = ({ activeAuthenticationType }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleTabChange = React.useCallback(
    (evt, value: AuthenticationType) => {
      switch (value) {
        case AuthenticationType.Registration:
          history.replace("/registration");
          break;
        case AuthenticationType.ForgotPassword:
          history.replace("/forgot-password");
          break;
        case AuthenticationType.ResetPassword:
          history.replace("/reset-password");
          break;
        case AuthenticationType.Login:
          history.replace("/");
          break;
        default:
          history.replace("/");
          break;
      }
    },
    [history]
  );

  const tabItems = React.useMemo(() => {
    return [
      {
        disabled: false,
        label: t(`Create Account`),
        value: AuthenticationType.Registration,
      },
      {
        disabled: false,
        label: t(`Sign In`),
        value: AuthenticationType.Login,
      },
    ];
  }, [t]);

  return (
    <Tabs
      a11yId="AuthenticationNavigationTabs"
      ariaLabel="Authentication Navigation Tabs"
      items={tabItems}
      value={activeAuthenticationType}
      centered={true}
      onChangeHandler={handleTabChange}
    />
  );
};

export default AuthenticationNavigationTabs;
