import React from "react";
import { useFormikContext } from "formik";
import isEqual from "lodash/isEqual";
import Button from "components/odl-v2/Button/Button";
import { useTranslation } from "react-i18next";
import FontIcon from "components/FontIcon/FontIcon";

type Props = {
  title?: React.ReactNode;
  disabled?: boolean;
};

const FormikSubmitButton: React.FC<Props> = ({ title, disabled }) => {
  const { t } = useTranslation();
  const { initialValues, values, isValid } = useFormikContext<any>();

  const isSubmitDisabled = React.useMemo(() => {
    const hasNoChanges = isEqual(initialValues, values);
    return disabled || hasNoChanges || !isValid;
  }, [disabled, initialValues, values, isValid]);

  return (
    <Button
      variant={"contained"}
      type="submit"
      size={"large"}
      disabled={isSubmitDisabled}
      endIcon={<FontIcon name={"arrow-right"} />}
    >
      {title || t(`Next`)}
    </Button>
  );
};

export default FormikSubmitButton;
