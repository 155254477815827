import { createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { OrganisationInvitationStatus } from "models/OrganisationMemberInvitation.model";
import { RootState } from "store/types";
import { serviceContainer } from "services/serviceContainer";
import { createDeepEqualSelector } from "store/utils";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { IOrganisationMergeInvitationEntity } from "models/OrganisationMergeInvitation.model";
import { OrganisationRole } from "models/OrganisationMember.model";

const organisationMergeInvitationAdapter = createEntityAdapter<IOrganisationMergeInvitationEntity>({
  selectId: (invitation) => invitation.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchOrganisationMergeInvitationsSentFromCurrentOrganisation = createAsyncThunk(
  "domainData/organisationMergeInvitation/fetchOrganisationMergeInvitations",
  async () => {
    const invitations = await serviceContainer.cradle.organisationMergeInvitationService.fetchOrganisationMergeInvitationsSentFromCurrentOrganisation();
    return invitations;
  }
);

export const fetchOrganisationMergeInvitationsSentToCurrentUser = createAsyncThunk(
  "domainData/organisationMergeInvitation/fetchOrganisationMergeInvitationsForCurrentUser",
  async (_, thunkAPI) => {
    const invitations = await serviceContainer.cradle.organisationMergeInvitationService.fetchOrganisationMergeInvitationsSentToCurrentUser();

    // TODO: Workaround when backend didn't return inviteeOrganisationId
    const rootState = thunkAPI.getState() as RootState;
    const userInfo = selectUserInfoEntityForAuthenticatedUser(rootState);
    const result = invitations.map(
      (item): IOrganisationMergeInvitationEntity => {
        if (item.inviteeOrganisationId > 0) {
          return item;
        }

        return {
          ...item,
          inviteeOrganisationId: userInfo?.organisationId || 0,
        };
      }
    );

    return result;
  }
);

export const sendOrganisationMergeInvitation = createAsyncThunk(
  "domainData/organisationMergeInvitation/sendOrganisationMergeInvitation",
  async (args: { organisationId: number; inviteeEmail: string }) => {
    const invitation = await serviceContainer.cradle.organisationMergeInvitationService.sendOrganisationMergeInvitation(
      args
    );
    return invitation;
  }
);

export const resendOrganisationMergeInvitation = createAsyncThunk(
  "domainData/organisationMergeInvitation/resendOrganisationMergeInvitation",
  async (args: { invitationId: number }, thunkAPI) => {
    await serviceContainer.cradle.organisationMergeInvitationService.resendOrganisationMergeInvitation(
      args.invitationId
    );

    // TODO: Do we need to reload all?
    await thunkAPI.dispatch(fetchOrganisationMergeInvitationsSentFromCurrentOrganisation());
  }
);

export const revokeOrganisationMergeInvitation = createAsyncThunk(
  "domainData/organisationMergeInvitation/revokeOrganisationMergeInvitation",
  async (args: { invitationId: number }, thunkAPI) => {
    await serviceContainer.cradle.organisationMergeInvitationService.revokeOrganisationMergeInvitation(
      args.invitationId
    );

    // TODO: Do we need to reload all?
    await thunkAPI.dispatch(fetchOrganisationMergeInvitationsSentFromCurrentOrganisation());
  }
);

export const acceptOrganisationMergeInvitation = createAsyncThunk(
  "domainData/organisationMergeInvitation/acceptOrganisationMergeInvitation",
  async (invitationId: number) => {
    const response = await serviceContainer.cradle.organisationMergeInvitationService.respondToOrganisationMergeInvitation(
      {
        invitationId,
        status: OrganisationInvitationStatus.Accepted,
      }
    );
    return response;
  }
);

export const rejectOrganisationMergeInvitation = createAsyncThunk(
  "domainData/organisationMergeInvitation/rejectOrganisationMergeInvitation",
  async (invitationId: number) => {
    const response = await serviceContainer.cradle.organisationMergeInvitationService.respondToOrganisationMergeInvitation(
      {
        invitationId,
        status: OrganisationInvitationStatus.Rejected,
      }
    );
    return response;
  }
);

// Reducer

export const defaultOrganisationMergeInvitationState = organisationMergeInvitationAdapter.getInitialState();

export const organisationMergeInvitationReducer = createReducer<typeof defaultOrganisationMergeInvitationState>(
  defaultOrganisationMergeInvitationState,
  (builder) =>
    builder
      .addCase(
        fetchOrganisationMergeInvitationsSentFromCurrentOrganisation.fulfilled,
        organisationMergeInvitationAdapter.upsertMany
      )
      .addCase(
        fetchOrganisationMergeInvitationsSentToCurrentUser.fulfilled,
        organisationMergeInvitationAdapter.upsertMany
      )
      .addCase(sendOrganisationMergeInvitation.fulfilled, organisationMergeInvitationAdapter.upsertOne)
      .addCase(acceptOrganisationMergeInvitation.fulfilled, organisationMergeInvitationAdapter.upsertOne)
      .addCase(rejectOrganisationMergeInvitation.fulfilled, organisationMergeInvitationAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectOrganisationMergeInvitationById,
  selectAll: selectAllOrganisationMergeInvitations,
} = organisationMergeInvitationAdapter.getSelectors((state: RootState) => state.domainData.organisationMergeInvitation);

export const selectIncompleteOrganisationMergeInvitationsSentFromCurrentOrganisation = createDeepEqualSelector(
  [selectAllOrganisationMergeInvitations, (state: RootState) => selectUserInfoEntityForAuthenticatedUser(state)],
  (invitations, userInfo) => {
    return invitations.filter((invitation) => {
      const isIncomplete = [
        OrganisationInvitationStatus.Pending,
        OrganisationInvitationStatus.Expired,
        OrganisationInvitationStatus.Rejected,
      ].includes(invitation.status);
      const isSentFromCurrentOrganisation = invitation.organisationId === userInfo?.organisationId;
      return isIncomplete && isSentFromCurrentOrganisation;
    });
  }
);

export const selectPendingOrganisationMergeInvitationsSentToCurrentUser = createDeepEqualSelector(
  [selectAllOrganisationMergeInvitations, (state: RootState) => selectUserInfoEntityForAuthenticatedUser(state)],
  (invitations, userInfo) => {
    return invitations.filter(
      (invitation) =>
        invitation.status === OrganisationInvitationStatus.Pending &&
        invitation.inviteeOrganisationId === userInfo?.organisationId &&
        userInfo?.organisationRole === OrganisationRole.Admin
    );
  }
);
