import React from "react";
import styled, { css } from "styled-components/macro";
import QualificationCard from "./QualificationCard";
import { useTranslation } from "react-i18next";
import FlexBox from "../FlexBox/FlexBox";
import Guard from "../Guard/Guard";
import AddQualificationFormikForm from "./AddQualificationFormikForm";
import ContainedButton from "../odl-v2/Button/ContainedButton";
import {
  QualificationView,
  QualificationViewContext,
} from "components/ParticipantEditDialog/contexts/QualificationViewContext";

type Props = {};

const ParticipantQualificationList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { setQualificationView, qualificationView, currentQualifications } = React.useContext(QualificationViewContext);

  const handleClickAddQualification = React.useCallback(() => {
    setQualificationView(QualificationView.NewQualification);
  }, [setQualificationView]);

  return (
    <StyledListContainer data-testid="ParticipantQualificationList">
      <HeaderContainer>{t("Trade licenses, qualifications and registration")}</HeaderContainer>
      <Guard condition={qualificationView === QualificationView.QualificationCards}>
        <FlexBox direction="column" spacing={3}>
          {currentQualifications.map((qualification, index) => (
            <QualificationCard
              qualification={qualification}
              index={index}
              key={`${qualification.qualificationName}-${index}`}
            />
          ))}
        </FlexBox>
        <StyledContainedButton onClick={handleClickAddQualification}>
          {t("Add a license, qualification or registration")}
        </StyledContainedButton>
      </Guard>
      <Guard condition={qualificationView === QualificationView.NewQualification}>
        <AddQualificationFormikForm />
      </Guard>
    </StyledListContainer>
  );
};

const StyledListContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 12px 48px;
    ${theme.mixins.flexGap("12px")};
  `
);

const StyledContainedButton = styled(ContainedButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.blue.tonal};
    color: ${theme.palette.objective.dark.black};
    font-weight: 600;
    font-size: 16px;
    padding: 14px 10px;

    &:hover {
      color: ${theme.palette.objective.light.white};
      background-color: ${theme.palette.objective.blue.mid};
    }
  `
);

const HeaderContainer = styled.span(
  () => css`
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
  `
);

export default ParticipantQualificationList;
