import React from "react";
import { useTranslation } from "react-i18next";
import { IDocumentEntity } from "models/Document.model";
import MenuTriggerIconButton from "components/odl-v2/Menu/MenuTriggerIconButton";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import DocumentCard from "components/DocumentCard/DocumentCard";
import Guard from "components/Guard/Guard";
import { DocumentStatus } from "models/DocumentStatus.model";
import RemoveIconButton from "components/RemoveIconButton/RemoveIconButton";

type Props = {
  document: IDocumentEntity;
  isDraggable?: boolean;
  onPreviewApplicationDocument: (documentName: string) => void;
  onDownloadApplicationDocument: (documentName: string) => Promise<boolean>;
  onDeleteApplicationDocument?: (documentName: string) => void;
};

const DocumentContainerDocumentCard = ({
  document,
  onPreviewApplicationDocument,
  onDownloadApplicationDocument,
  onDeleteApplicationDocument,
  isDraggable,
}: Props) => {
  // Common
  const { t } = useTranslation();
  const { Menu, openMenu, closeMenu } = useMenu();
  const [isDownloaded, setIsDownloaded] = React.useState(false);

  // Different action button according to status
  const actionButton = React.useMemo(() => {
    if (document.uploadStatus === DocumentStatus.Completed) {
      return <MenuTriggerIconButton onClick={openMenu} />;
    }
    return (
      <RemoveIconButton
        onClick={() => {
          if (onDeleteApplicationDocument) {
            onDeleteApplicationDocument(document.name);
          }
        }}
      />
    );
  }, [document.name, document.uploadStatus, onDeleteApplicationDocument, openMenu]);

  return (
    <React.Fragment>
      <DocumentCard
        fileName={document.fileName}
        fileSize={document.fileSize}
        uploadStatus={document.uploadStatus}
        fileModifiedDate={document.fileModifiedDate}
        errorCode={document.errorCode}
        isDraggable={isDraggable}
        actionButton={actionButton}
        data-is-downloaded={isDownloaded}
      />
      <Menu>
        <MenuItem
          icon={"file"}
          data-testid={"PopupMenuItemPreview"}
          onClick={() => {
            onPreviewApplicationDocument(document.name);
            closeMenu();
          }}
        >
          {t("Preview")}
        </MenuItem>
        <MenuItem
          icon={"download"}
          data-testid={"PopupMenuItemDownload"}
          onClick={async () => {
            closeMenu();
            setIsDownloaded(false);
            const downloadResult = await onDownloadApplicationDocument(document.name);
            setIsDownloaded(downloadResult);
          }}
        >
          {t(`Download`)}
        </MenuItem>
        <Guard condition={onDeleteApplicationDocument}>
          <MenuItem
            icon={"delete"}
            data-testid={"PopupMenuItemDelete"}
            onClick={async () => {
              if (onDeleteApplicationDocument) {
                onDeleteApplicationDocument(document.name);
                closeMenu();
              }
            }}
          >
            {t(`Remove`)}
          </MenuItem>
        </Guard>
      </Menu>
    </React.Fragment>
  );
};

export default DocumentContainerDocumentCard;
