import { IOrganisationMemberInvitationService } from "services/organisation-member-invitation/OrganisationMemberInvitationService.types";
import { Cradle } from "services/serviceContainer.types";
import {
  IOrganisationMemberInvitationEntity,
  OrganisationInvitationStatusUtil,
} from "models/OrganisationMemberInvitation.model";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { ServiceError } from "services/ServiceError";

export class OrganisationMemberInvitationService implements IOrganisationMemberInvitationService {
  private readonly apiClient: Cradle["apiClient"];

  constructor(args: Pick<Cradle, "apiClient">) {
    this.apiClient = args.apiClient;
  }

  public async fetchOrganisationInvitationsSentToCurrentUser(): Promise<IOrganisationMemberInvitationEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/organisation-invitations`);
    const json = response.data;
    const invitationsJSON = get(json, "invitations");

    if (!Array.isArray(invitationsJSON)) {
      throw new ServiceError();
    }
    const invitations = invitationsJSON.map((invite) => {
      return this.parseInvitationFromJSON(invite);
    });
    return invitations;
  }

  public async fetchOrganisationInvitationsSentFromCurrentOrganisation(): Promise<
    IOrganisationMemberInvitationEntity[]
  > {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/invitations`);
    const json = response.data;
    const invitationsJSON = get(json, "invitations");

    if (!Array.isArray(invitationsJSON)) {
      throw new ServiceError();
    }
    const invitations = invitationsJSON.map((invite) => {
      return this.parseInvitationFromJSON(invite);
    });
    return invitations;
  }

  public async resendOrganisationInvitation(invitationId: number): Promise<void> {
    await this.apiClient.protectedApi.post(`/user/organisation/invitations/${invitationId}/notifications`);
  }

  public async revokeOrganisationInvitation(invitationId: number): Promise<IOrganisationMemberInvitationEntity> {
    const response = await this.apiClient.protectedApi.patch(`/user/organisation/invitations/${invitationId}`, {
      status: "Revoked",
    });

    const invitation = this.parseInvitationFromJSON(response.data);
    return invitation;
  }

  public async createOrganisationInvitation(args: {
    email: string;
    firstName: string;
    lastName: string;
    captchaToken: string;
  }): Promise<void> {
    try {
      await this.apiClient.protectedApi.post(`/user/organisation/invitations`, {
        email: args.email,
        firstName: args.firstName,
        lastName: args.lastName,
        captchaToken: args.captchaToken,
      });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async validateOrganisationInvitation(invitationCode: string): Promise<{ email: string }> {
    try {
      const response = await this.apiClient.publicApi.get(`/public/organisation/invitations?uuid=${invitationCode}`);
      const json = response.data;
      const email = toString(get(json, "email"));
      return { email: email };
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async sendOrganisationMergeInvitation(organisationId: number): Promise<void> {
    try {
      await this.apiClient.protectedApi.post(`/user/organisation/merge`, {
        organisationId: organisationId,
      });
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  private parseInvitationFromJSON(json: any): IOrganisationMemberInvitationEntity {
    const invitation: IOrganisationMemberInvitationEntity = {
      id: toInteger(get(json, "id")),
      organisationId: toInteger(get(json, "organisationId")),
      organisationName: toString(get(json, "organisationDisplayName")),
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      email: toString(get(json, "email")),
      status: OrganisationInvitationStatusUtil.parse(toString(get(json, "status"))),
      expiryDate: toString(get(json, "expiryDate")),
      sentDate: toString(get(json, "sendingDate")),
    };
    return invitation;
  }
}
