import React from "react";
import MuiDialogActions, { DialogActionsProps } from "@material-ui/core/DialogActions";
import styled, { css } from "styled-components/macro";

type Props = DialogActionsProps;

const DialogActions: React.FC<Props> = React.forwardRef((props, ref) => {
  return <StyledMuiDialogActions ref={ref} {...props} />;
});

const StyledMuiDialogActions = styled(MuiDialogActions)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
  `
);

export default DialogActions;
