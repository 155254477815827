import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Guard from "components/Guard/Guard";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import { useEventStopPropCallback } from "hooks/useEventStopPropCallback";

type Props = {
  title: string;
  content?: React.ReactNode;
  isOpen?: boolean;
  confirmTitle?: React.ReactNode;
  cancelTitle?: React.ReactNode;
  displayConfirmButton?: boolean;
  displayCancelButton?: boolean;
  isConfirmButtonDisabled?: boolean;
  className?: string;
  "data-testid"?: string;
  onClose: (confirmed: boolean) => void;
};

const ConfirmationDialog: React.FC<Props> = ({
  title,
  content,
  isOpen = false,
  confirmTitle = "Continue",
  cancelTitle,
  displayConfirmButton = true,
  displayCancelButton = true,
  isConfirmButtonDisabled,
  className,
  "data-testid": dataTestId = "",
  onClose,
}) => {
  const { t } = useTranslation();

  const eventStopPropCallback = useEventStopPropCallback();

  const handleOnClose = React.useCallback(
    (event: SyntheticEvent, isConfirmed: boolean) => {
      onClose(isConfirmed);
    },
    [onClose]
  );

  return (
    <Dialog
      open={isOpen}
      className={className}
      title={title}
      onClose={(event) => handleOnClose(event, false)}
      data-testid={dataTestId}
      onClick={eventStopPropCallback}
    >
      <Guard condition={content}>
        <DialogContent>{content}</DialogContent>
      </Guard>
      <DialogActions>
        <Guard condition={displayCancelButton}>
          <DialogActionCancel onClick={(event) => handleOnClose(event, false)} data-testid={`CancelButton`}>
            {cancelTitle || t(`Cancel`)}
          </DialogActionCancel>
        </Guard>

        <Guard condition={displayConfirmButton}>
          <DialogActionPrimary
            onClick={(event) => handleOnClose(event, true)}
            disabled={isConfirmButtonDisabled}
            data-testid={`ConfirmButton`}
          >
            {confirmTitle || t(`Continue`)}
          </DialogActionPrimary>
        </Guard>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
export type ConfirmationDialogProps = Props;
