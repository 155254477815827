import { ApplicationValidationResultSeverity } from "models/ApplicationValidationSeverity.model";
import objectHash from "object-hash";
import { ApplicationValidationResultRuleType } from "models/ApplicationValidationResultRuleType";

export type IApplicationValidationResultEntity = {
  message: string;
  messageParams: string;
  severity: ApplicationValidationResultSeverity;
  ruleId: number;
  ruleType: ApplicationValidationResultRuleType;
  applicationId: number;
  stepId: number;
  applicationFieldName: string;
};

export const ApplicationValidationResultUtil = {
  generateKey: (entity: IApplicationValidationResultEntity) => {
    return objectHash(entity);
  },
};
