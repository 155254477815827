import { createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import {
  IOrganisationMemberInvitationEntity,
  OrganisationInvitationStatus,
} from "models/OrganisationMemberInvitation.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { IOrganisationMemberInvitationService } from "services/organisation-member-invitation/OrganisationMemberInvitationService.types";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";

// Entity adapter

const organisationMemberInvitationAdapter = createEntityAdapter<IOrganisationMemberInvitationEntity>({
  selectId: (invitation) => invitation.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Action and thunks

export const fetchOrganisationMemberInvitationsSentToCurrentUser = createAsyncThunk(
  "domainData/organisationMemberInvitation/fetchOrganisationInvitationsForCurrentUser",
  async () => {
    const invitations = await serviceContainer.cradle.organisationMemberInvitationService.fetchOrganisationInvitationsSentToCurrentUser();
    return invitations;
  }
);

export const fetchOrganisationMemberInvitationsSentFromCurrentOrganisation = createAsyncThunk(
  "domainData/organisationMemberInvitation/fetchOrganisationMemberInvitations",
  async () => {
    const invitations = await serviceContainer.cradle.organisationMemberInvitationService.fetchOrganisationInvitationsSentFromCurrentOrganisation();
    return invitations;
  }
);

export const resendOrganisationMemberInvitation = createAsyncThunk(
  "domainData/organisationMemberInvitation/resendOrganisationMemberInvitation",
  async (invitationId: number, thunkAPI) => {
    await serviceContainer.cradle.organisationMemberInvitationService.resendOrganisationInvitation(invitationId);

    // TODO: BLD-1740 Backend bug - POST returns 204 with no content when success, so we need re-fetch
    await thunkAPI.dispatch(fetchOrganisationMemberInvitationsSentFromCurrentOrganisation());
  }
);

export const revokeOrganisationMemberInvitation = createAsyncThunk(
  "domainData/organisationMemberInvitation/revokeOrganisationMemberInvitation",
  async (invitationId: number) => {
    const invitation = await serviceContainer.cradle.organisationMemberInvitationService.revokeOrganisationInvitation(
      invitationId
    );
    return invitation;
  }
);

export const inviteUserToOrganisation = createAsyncThunk(
  "domainData/organisationMemberInvitation/inviteUserToOrganisation",
  async (args: Parameters<IOrganisationMemberInvitationService["createOrganisationInvitation"]>[0], thunkAPI) => {
    await serviceContainer.cradle.organisationMemberInvitationService.createOrganisationInvitation(args);

    // TODO: BLD-1740 Backend bug - POST returns 204 with no content when success, so we need re-fetch
    await thunkAPI.dispatch(fetchOrganisationMemberInvitationsSentFromCurrentOrganisation());
  }
);

// Reducer

export const defaultOrganisationMemberInvitationState = organisationMemberInvitationAdapter.getInitialState();

export const organisationMemberInvitationReducer = createReducer<typeof defaultOrganisationMemberInvitationState>(
  defaultOrganisationMemberInvitationState,
  (builder) =>
    builder
      .addCase(
        fetchOrganisationMemberInvitationsSentToCurrentUser.fulfilled,
        organisationMemberInvitationAdapter.upsertMany
      )
      .addCase(
        fetchOrganisationMemberInvitationsSentFromCurrentOrganisation.fulfilled,
        organisationMemberInvitationAdapter.upsertMany
      )
      .addCase(revokeOrganisationMemberInvitation.fulfilled, organisationMemberInvitationAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectOrganisationMemberInvitationById,
  selectAll: selectAllOrganisationMemberInvitations,
} = organisationMemberInvitationAdapter.getSelectors(
  (state: RootState) => state.domainData.organisationMemberInvitation
);

export const selectIncompleteOrganisationMemberInvitationsSentFromCurrentOrganisation = createDeepEqualSelector(
  [selectAllOrganisationMemberInvitations, (state: RootState) => selectUserInfoEntityForAuthenticatedUser(state)],
  (invitations, userInfo) => {
    return invitations.filter(
      (invitation) =>
        (invitation.status === OrganisationInvitationStatus.Pending ||
          invitation.status === OrganisationInvitationStatus.Expired) &&
        invitation.organisationId === userInfo?.organisationId
    );
  }
);
