import React from "react";
import { serviceContainer } from "services/serviceContainer";

export const useIsInMaintenance = () => {
  const [isInMaintenance, setIsInMaintenance] = React.useState(false);
  const [isLoadingMaintenanceFlag, setIsLoadingMaintenanceFlag] = React.useState(true);

  React.useEffect(() => {
    const logger = serviceContainer.cradle.logger;
    const fetchData = async () => {
      const result = await serviceContainer.cradle.maintenanceService.fetchIsInMaintenance();
      setIsInMaintenance(result);
    };

    fetchData()
      .then(() => setIsLoadingMaintenanceFlag(false))
      .catch(logger.error);
  }, []);

  return {
    isInMaintenance,
    isLoadingMaintenanceFlag,
  };
};
