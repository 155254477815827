import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { loginWithCachedState, selectAuthenticationState } from "store/app-state/authentication/authentication";
import React from "react";
import { fetchUserInfoForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { fetchIndependentAuthoritiesForAuthenticatedUser } from "store/domain-data/authority/authority";
import { fetchUserAuthoritiesRelationForAuthenticatedUser } from "store/domain-data/user-authority-relation/userAuthorityRelation";

export const useAuthenticationRoutine = () => {
  const dispatch = useStoreDispatch();

  const { authenticated } = useStoreSelector(selectAuthenticationState);

  // Automatically login if user has cached authentication state
  React.useEffect(() => {
    dispatch(loginWithCachedState());
  }, [dispatch]);

  // When authentication state changes, fetch & update user info
  React.useEffect(() => {
    if (!authenticated) {
      return;
    }
    const fetchUserData = async () => {
      await dispatch(fetchUserInfoForAuthenticatedUser());
      await dispatch(fetchUserAuthoritiesRelationForAuthenticatedUser());
      await dispatch(fetchIndependentAuthoritiesForAuthenticatedUser());
    };

    fetchUserData();
  }, [authenticated, dispatch]);
};
