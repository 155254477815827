import { ActionItemVariant, IActionItem } from "components/ActionSection/ActionItem";
import newApplicationIcon from "assets/images/note-plus-outline-icon.svg";
import React from "react";

import styled, { css } from "styled-components/macro";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export const useNewApplicationIntentActionItem = (): IActionItem => {
  const history = useHistory();
  const { t } = useTranslation();
  const actionItem: IActionItem = React.useMemo(() => {
    return {
      icon: <StyledActionItemIcon src={newApplicationIcon} alt={"New application intent action icon"} />,
      title: t(`Start a new application`),
      variant: ActionItemVariant.Button,
      onClick: () => {
        history.push("/new-application");
      },
    };
  }, [t, history]);

  return actionItem;
};

const StyledActionItemIcon = styled.img(
  ({ theme }) => css`
    width: 14px;
  `
);
