import { createAction, createAsyncThunk, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { INotificationUserProfileEntity } from "models/NotificationUserProfile.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { createDeepEqualSelector } from "store/utils";

// Entity Adapter

const notificationUserProfileAdapter = createEntityAdapter<INotificationUserProfileEntity>({
  selectId: (entity) => entity.id,
});

// Action & Thunks

export const loadNotificationUserProfileForCurrentUser = createAction<INotificationUserProfileEntity>(
  "domainData/notificationUserProfile/loadNotificationUserProfileForCurrentUser"
);

export const fetchNotificationUserProfileForCurrentUser = createAsyncThunk(
  "domainData/notificationUserProfile/fetchNotificationUserProfile",
  async () => {
    const notificationUserProfile = await serviceContainer.cradle.notificationUserProfileService.fetchNotificationUserProfile();
    return notificationUserProfile;
  }
);

export const markAllNotificationsAsSeenForCurrentUser = createAsyncThunk(
  "domainData/notificationUserProfile/updateNotificationUserProfile",
  async (_, thunkAPI) => {
    const notificationUserProfile = await serviceContainer.cradle.notificationUserProfileService.markAllNotificationsAsSeen();
    thunkAPI.dispatch(loadNotificationUserProfileForCurrentUser(notificationUserProfile));
  }
);

// Reducer

export const defaultNotificationUserProfileState = notificationUserProfileAdapter.getInitialState();

export const notificationUserProfileReducer = createReducer<EntityState<INotificationUserProfileEntity>>(
  defaultNotificationUserProfileState,
  (builder) =>
    builder
      .addCase(fetchNotificationUserProfileForCurrentUser.fulfilled, notificationUserProfileAdapter.upsertOne)
      .addCase(loadNotificationUserProfileForCurrentUser, notificationUserProfileAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectNotificationUserProfileEntityById,
  selectIds: selectNotificationUserProfileEntityIds,
  selectEntities: selectNotificationUserProfileEntities,
  selectAll: selectAllNotificationUserProfileEntities,
  selectTotal: selectTotalNotificationUserProfileEntities,
} = notificationUserProfileAdapter.getSelectors((state: RootState) => state.domainData.notificationUserProfile);

export const selectNotificationUserProfileForCurrentUser = createDeepEqualSelector(
  [selectAllNotificationUserProfileEntities, selectUserInfoEntityForAuthenticatedUser],
  (entities, userInfo) => {
    return entities.find((entity) => entity.username === userInfo?.username);
  }
);
