import React from "react";
import { saveApplicationStepData } from "store/domain-data/application-step-data/applicationStepData";
import { useStoreDispatch } from "store/hooks";
import { ApplicationFormContext, ISaveCurrentStepDataCommand } from "pages/application-form/ApplicationFormContext";

type Props = {
  applicationId: number;
  breadcrumbStepId: number;
};

const SavingStepDataWhenMovingAway: React.FC<Props> = ({ children, applicationId, breadcrumbStepId }) => {
  const dispatch = useStoreDispatch();

  const { runSaveCurrentStepDataCommand } = React.useContext(ApplicationFormContext);
  const runSaveCurrentStepDataCommandRef = React.useRef<ISaveCurrentStepDataCommand | null>(null);

  // Use Refs to capture the latest ids
  const refApplicationId = React.useRef(applicationId);
  const refBreadcrumbStepId = React.useRef(breadcrumbStepId);
  React.useEffect(() => {
    refApplicationId.current = applicationId;
    refBreadcrumbStepId.current = breadcrumbStepId;
  }, [applicationId, breadcrumbStepId]);

  React.useEffect(() => {
    runSaveCurrentStepDataCommandRef.current = runSaveCurrentStepDataCommand;
  }, [runSaveCurrentStepDataCommand]);

  // Automatically save data when moving away
  React.useEffect(() => {
    return () => {
      // Special case: Don't save after submitting application
      if (window.location.pathname.startsWith("/application-submitted")) {
        return;
      }

      const runSaveCurrentStepDataCommand = runSaveCurrentStepDataCommandRef.current;
      if (runSaveCurrentStepDataCommand) {
        runSaveCurrentStepDataCommand();
      }

      dispatch(
        saveApplicationStepData({
          applicationId: refApplicationId.current,
          breadcrumbStepId: refBreadcrumbStepId.current,
        })
      );
    };
  }, [dispatch]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default SavingStepDataWhenMovingAway;
