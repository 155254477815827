import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IApplicationActionItemService } from "services/application-action-item/ApplicationActionItemService.types";
import {
  ApplicationActionItemType,
  ApplicationActionItemTypeUtil,
  IApplicationActionItemData,
  IBasicApplicationActionItem,
  IBookInspectionActionItem,
  ICreateChildActionItem,
} from "models/ApplicationActionItem.model";
import toInteger from "lodash/toInteger";
import sortBy from "lodash/sortBy";

export class ApplicationActionItemService implements IApplicationActionItemService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchApplicationActionItems(applicationId: number): Promise<IApplicationActionItemData[]> {
    const response = await this.apiClient.protectedApi.get(`/user/applicationsV3/${applicationId}/action-items`);
    const jsonArr = get(response.data, "actionItems");

    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t("Failed to fetch application action items"));
    }

    const actionItemsData: IApplicationActionItemData[] = [];
    for (const json of jsonArr) {
      const itemType = ApplicationActionItemTypeUtil.parse(toString(get(json, "type")));

      switch (itemType) {
        case ApplicationActionItemType.Unknown: {
          break;
        }
        case ApplicationActionItemType.CreateChildApplication: {
          const actionItemData = ApplicationActionItemService.parseCreateChildApplicationActionItem(json);
          actionItemsData.push(actionItemData);
          break;
        }
        case ApplicationActionItemType.BookInspection: {
          const actionItemData = ApplicationActionItemService.parseBookInspectionActionItem(json);
          actionItemsData.push(actionItemData);
          break;
        }
        default: {
          const actionItemData = ApplicationActionItemService.parseBasicApplicationActionItem(json);
          actionItemsData.push(actionItemData);
        }
      }
    }

    const sortedActionItemsData = sortBy(actionItemsData, (data) => data.displayOrder);
    return sortedActionItemsData;
  }

  static parseBasicApplicationActionItem(json: any) {
    const actionItemData: IBasicApplicationActionItem = {
      displayName: toString(get(json, "displayName")),
      itemType: ApplicationActionItemTypeUtil.parse(toString(get(json, "type"))),
      icon: toString(get(json, "icon")),
      displayOrder: toInteger(get(json, "displayOrder")),
    };
    return actionItemData;
  }

  static parseCreateChildApplicationActionItem(json: any) {
    const actionItemData: ICreateChildActionItem = {
      displayName: toString(get(json, "displayName")),
      itemType: ApplicationActionItemType.CreateChildApplication,
      icon: toString(get(json, "icon")),
      formId: toInteger(get(json, "formId")),
      displayOrder: toInteger(get(json, "displayOrder")),
    };
    return actionItemData;
  }

  static parseBookInspectionActionItem(json: any) {
    const actionItemData: IBookInspectionActionItem = {
      displayName: toString(get(json, "displayName")),
      itemType: ApplicationActionItemType.BookInspection,
      to: toString(get(json, "url")),
      target: toString(get(json, "target")),
      displayOrder: toInteger(get(json, "displayOrder")),
    };
    return actionItemData;
  }
}
