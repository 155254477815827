import { css } from "styled-components/macro";

export const iconMixin = () => {
  return css`
    .MuiButton-startIcon {
      .icon {
        font-size: 14px;
      }
    }

    .MuiButton-endIcon {
      .icon {
        font-size: 14px;
      }
    }
  `;
};
