import { IAnnouncementService } from "services/announcement/AnnouncementService.types";
import { Cradle } from "services/serviceContainer.types";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import get from "lodash/get";
import toString from "lodash/toString";
import axios from "axios";
import { AnnouncementLocationUtil, AnnouncementSeverityUtil } from "models/Announcement.model";

export class AnnouncementService implements IAnnouncementService {
  private readonly i18n: Cradle["i18n"];

  constructor(args: { i18n: Cradle["i18n"] }) {
    this.i18n = args.i18n;
  }

  public async fetchAnnouncements() {
    const response = await axios.get("/announcements/announcements.json");
    const jsonArr = get(response, "data");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch announcements`));
    }

    return jsonArr.map((json) => this.parseAnnouncement(json));
  }

  private parseAnnouncement(json: any) {
    return {
      id: toString(get(json, "id")),
      location: AnnouncementLocationUtil.parse(toString(get(json, "location"))),
      severity: AnnouncementSeverityUtil.parse(toString(get(json, "severity"))),
      startTime: toString(get(json, "startTime")),
      endTime: toString(get(json, "endTime")),
      persistent: Boolean(get(json, "persistent")),
      header: toString(get(json, "header")),
      subTitle: toString(get(json, "subTitle")),
      description: toString(get(json, "description")),
    };
  }
}
