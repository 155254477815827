export enum ToastVariant {
  Error = "Error",
  Success = "Success",
  Warning = "Warning",
  Info = "Info",
}

export type ToastDataType = {
  message: string;
  variant: ToastVariant;
};
