import React from "react";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { Trans, useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import styled, { css } from "styled-components/macro";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";

type Props = {
  documentContainerId: number;
  onClose: (confirmed: boolean) => void;
};

const RemoveAllApplicationDocumentsInContainerConfirmationDialog: React.FC<Props> = ({
  documentContainerId,
  onClose,
}) => {
  const { t } = useTranslation();
  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));

  if (!documentContainer) {
    return null;
  }

  return (
    <ConfirmationDialog
      data-testid={"RemoveAllApplicationDocumentsInContainerConfirmationDialog"}
      title={t(`Remove all documents in container`)}
      isOpen={true}
      onClose={onClose}
      confirmTitle={t(`Remove`)}
      cancelTitle={t(`Cancel`)}
      content={
        <StyledDialogContent>
          <Trans
            defaults={`Remove all documents from <strong>{{containerName}}</strong>?`}
            values={{
              containerName: documentContainer.displayName,
            }}
            components={{
              strong: <StyledStrong />,
            }}
            parent={StyledTransParent}
          />
          <div>{t(`This operation can't be undone.`)}</div>
        </StyledDialogContent>
      }
    />
  );
};

const StyledTransParent = styled.div(
  ({ theme }) => css`
    display: inline-block;
  `
);

const StyledStrong = styled.span(
  ({ theme }) => css`
    font-weight: 600;
  `
);

const StyledDialogContent = styled.div(
  ({ theme }) => css`
    line-height: 1.5em;
    padding: 4px;
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("16px")}
  `
);

export default RemoveAllApplicationDocumentsInContainerConfirmationDialog;
