import React from "react";
import { IconButton } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import Alert from "components/odl-v2/Alert/Alert";
import AlertAction from "components/odl-v2/Alert/AlertAction";
import FontIcon from "components/FontIcon/FontIcon";

type Props = {
  onClickMute: () => void;
  onClickGotoProfile: () => void;
};

const ProfileIncompleteAlert: React.FC<Props> = ({ onClickMute, onClickGotoProfile }) => {
  const { t } = useTranslation();

  return (
    <Alert
      severity={"warning"}
      data-testid={"ProfileIncompleteAlert"}
      action={
        <React.Fragment>
          <AlertAction severity={"warning"} onClick={onClickGotoProfile}>
            {t(`Go to Profile`)}
          </AlertAction>
          <StyledIconButton onClick={onClickMute} aria-label={t(`Close`)}>
            <StyledCloseIcon $disabled={false} />
          </StyledIconButton>
        </React.Fragment>
      }
    >
      {t(`Your personal information is incomplete.`)}
      &nbsp;
      {t(`Please add the missing details to your profile.`)}
    </Alert>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
  `
);

const StyledCloseIcon = styled(FontIcon).attrs({ name: "close" })<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    font-size: 14px;
    color: ${theme.palette.objective.dark.night};
    ${$disabled &&
    css`
      color: ${theme.palette.objective.light.allspice};
    `}
  `
);

export default ProfileIncompleteAlert;
