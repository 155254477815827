import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import Guard from "components/Guard/Guard";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import Dialog from "components/odl-v2/Dialog/Dialog";

type Props = {
  title: string;
  content?: React.ReactNode;
  isOpen: boolean;
  onClose: (confirmed: boolean) => void;
  confirmTitle?: React.ReactNode;
  cancelTitle?: React.ReactNode;
  displayConfirmButton?: boolean;
  displayCancelButton?: boolean;
  className?: string;
  "data-testid"?: string;
};

const CriticalConfirmationDialog: React.FC<Props> = ({
  title,
  content,
  isOpen,
  onClose,
  confirmTitle,
  cancelTitle,
  displayConfirmButton = true,
  displayCancelButton = true,
  children,
  className,
  "data-testid": dataTestId = "",
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      className={className}
      title={t(`Alert: {{title}}`, { title })}
      open={isOpen}
      onClose={() => onClose(false)}
      data-testid={dataTestId}
    >
      <Guard condition={content}>
        <StyledDialogContent>{content}</StyledDialogContent>
      </Guard>
      <Guard condition={children}>{children}</Guard>
      <DialogActions>
        <Guard condition={displayCancelButton}>
          <DialogActionCancel onClick={() => onClose(false)} data-testid={"CriticalCancelButton"}>
            {cancelTitle || t(`Cancel`)}
          </DialogActionCancel>
        </Guard>
        <Guard condition={displayConfirmButton}>
          <DialogActionGroup>
            <DialogActionPrimary onClick={() => onClose(true)} data-testid={"CriticalConfirmButton"}>
              {confirmTitle || t(`Continue`)}
            </DialogActionPrimary>
          </DialogActionGroup>
        </Guard>
      </DialogActions>
    </StyledDialog>
  );
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    min-width: 390px;
    padding-left: 0;
    padding-right: 0;
  `
);

const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiDialog-paper {
      border: 2px dashed ${theme.palette.objective.red.main};

      & > .MuiDialogTitle-root {
        background: ${theme.palette.objective.red.main};

        h2 {
          color: ${theme.palette.objective.light.white};
        }

        .icon {
          color: ${theme.palette.objective.light.white};
        }
      }
    }
  `
);

export default CriticalConfirmationDialog;
