import { IJurisdictionService, INormalizedJurisdiction } from "services/jurisdiction/JurisdictionService.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toArray from "lodash/toArray";
import toString from "lodash/toString";
import { Cradle } from "services/serviceContainer.types";
import { IJurisdictionEntity } from "models/Jurisdiction.model";
import { IBreadcrumbEntity } from "models/Breadcrumb.model";
import { IJurisdictionFormEntity, JurisdictionFormCategoryUtil } from "models/JurisdictionForm.model";
import { IBreadcrumbStepEntity } from "models/BreadcrumbStep.model";
import { IAuthorityEntity } from "models/Authority.model";
import { BreadcrumbStepType } from "models/BreadcrumbStepType";
import { parseParticipantAccessLevelString } from "models/Participant.model";
import { DocumentCategoryIdentifierUtil, IDocumentCategoryEntity } from "models/DocumentCategory.model";

export class JurisdictionService implements IJurisdictionService {
  private readonly i18n: Cradle["i18n"];
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "i18n" | "apiClient">) {
    this.i18n = args.i18n;
    this.apiClient = args.apiClient;
  }

  public fetchJurisdictionById(id: number): Promise<INormalizedJurisdiction> {
    return this.fetchJurisdiction(id);
  }

  public fetchJurisdictionByName(name: string): Promise<INormalizedJurisdiction> {
    return this.fetchJurisdiction(name);
  }

  private async fetchJurisdiction(idOrName: string | number): Promise<INormalizedJurisdiction> {
    const response = await this.apiClient.publicApi.get(`/public/jurisdictions/${idOrName}`);
    const json = response.data;

    const jurisdictionEntity = JurisdictionService.parseJurisdictionEntity(json);

    const jurisdictionFormEntities: IJurisdictionFormEntity[] = [];
    const breadcrumbEntities: IBreadcrumbEntity[] = [];
    const breadcrumbStepEntities: IBreadcrumbStepEntity[] = [];
    const authorityEntities: IAuthorityEntity[] = [];

    jurisdictionEntity.formIds.forEach((_, formIdx) => {
      // Parse jurisdiction form
      const formJSON = get(json, `forms.${formIdx}`);
      const formEntity = JurisdictionService.parseJurisdictionFormEntity(formJSON, jurisdictionEntity.id);
      jurisdictionFormEntities.push(formEntity);

      formEntity.breadcrumbIds.forEach((_, breadcrumbIdx) => {
        // Parse breadcrumb
        const breadcrumbJSON = get(json, `forms.${formIdx}.breadcrumbs.${breadcrumbIdx}`);
        const breadcrumbEntity = JurisdictionService.parseBreadcrumbEntity(breadcrumbJSON, formEntity.id);
        breadcrumbEntities.push(breadcrumbEntity);

        // Parse breadcrumb step
        breadcrumbEntity.stepIds.forEach((_, stepIdx) => {
          const stepJSON = get(json, `forms.${formIdx}.breadcrumbs.${breadcrumbIdx}.steps.${stepIdx}`);
          const stepEntity = JurisdictionService.parseBreadcrumbStepEntity(stepJSON, breadcrumbEntity.id);
          breadcrumbStepEntities.push(stepEntity);
        });
      });
    });

    jurisdictionEntity.authorityIds.forEach((_, authorityIdx) => {
      // Parse authority
      const authorityJSON = get(json, `authorities.${authorityIdx}`);
      const authorityEntity = JurisdictionService.parseAuthorityEntity(authorityJSON, jurisdictionEntity.id);
      authorityEntities.push(authorityEntity);
    });

    const documentCategoriesJson = get(json, "documentCategories");
    let documentCategories: IDocumentCategoryEntity[] = [];
    if (documentCategoriesJson && Array.isArray(documentCategoriesJson)) {
      documentCategories = documentCategoriesJson.map((json) =>
        JurisdictionService.parseDocumentCategoryEntity(json, jurisdictionEntity.id)
      );
    }

    const orderById = (a: { id: number }, b: { id: number }) => a.id - b.id;
    jurisdictionFormEntities.sort(orderById);
    breadcrumbEntities.sort(orderById);
    breadcrumbStepEntities.sort(orderById);
    authorityEntities.sort(orderById);

    return {
      jurisdictionEntity,
      jurisdictionFormEntities,
      breadcrumbEntities,
      breadcrumbStepEntities,
      authorityEntities,
      documentCategories,
    };
  }

  private static parseJurisdictionEntity(json: any): IJurisdictionEntity {
    return {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      etag: toString(get(json, "etag")),
      formIds: toArray(get(json, "forms")).map((item) => toInteger(get(item, "id"))),
      authorityIds: toArray(get(json, "authorities")).map((item) => toInteger(get(item, "id"))),
    };
  }

  private static parseJurisdictionFormEntity(json: any, jurisdictionId: number): IJurisdictionFormEntity {
    return {
      id: toInteger(get(json, "id")),
      jurisdictionId: jurisdictionId,
      name: toString(get(json, "name")),
      description: toString(get(json, "description")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder")),
      imageId: toInteger(get(json, "imageId")),
      breadcrumbIds: toArray(get(json, "breadcrumbs")).map((item) => toInteger(get(item, "id"))),
      category: JurisdictionFormCategoryUtil.parse(toString(get(json, "category"))),
      isHidden: Boolean(get(json, "isHidden")),
      declarationText: toString(get(json, "declaration")) || toString(get(json, "declarationText")),
      series: toString(get(json, "formSeries")),
    };
  }

  private static parseBreadcrumbEntity(json: any, jurisdictionFormId: number): IBreadcrumbEntity {
    return {
      id: toInteger(get(json, "id")),
      jurisdictionFormId: jurisdictionFormId,
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder")),
      stepIds: toArray(get(json, "steps")).map((item) => toInteger(get(item, "id"))),
    };
  }

  private static parseBreadcrumbStepEntity(json: any, breadcrumbId: number): IBreadcrumbStepEntity {
    // TODO: BLD-1744, BLD-1987 Better enum parsing
    let type: BreadcrumbStepType = BreadcrumbStepType.Formio;
    if (toString(get(json, "stepType")).toUpperCase() === "UPLOAD") {
      type = BreadcrumbStepType.DocumentUpload;
    }
    if (toString(get(json, "stepType")).toUpperCase() === "APPLICATION_REVIEW") {
      type = BreadcrumbStepType.ApplicationReview;
    }
    if (toString(get(json, "stepType")).toUpperCase() === "CONTACT") {
      type = BreadcrumbStepType.Contact;
    }
    if (toString(get(json, "stepType")).toUpperCase() === "TEMPLATE_DETAILS") {
      type = BreadcrumbStepType.TemplateDetails;
    }

    return {
      id: toInteger(get(json, "id")),
      displayOrder: toInteger(get(json, "displayOrder")),
      breadcrumbId: breadcrumbId,
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      formioName: toString(get(json, "formioName")),
      minimumAccessLevel: parseParticipantAccessLevelString(toString(get(json, "minimumAccessLevel"))),
      type,
    };
  }

  private static parseAuthorityEntity(json: any, jurisdictionId: number): IAuthorityEntity {
    return {
      id: toInteger(get(json, "id")),
      imageId: toInteger(get(json, "imageId")),
      jurisdictionId: jurisdictionId,
      name: toString(get(json, "name")),
      abbr: toString(get(json, "abbr")),
      // TODO: BLD-3498 Backend bug - API does not return displayName
      displayName: toString(get(json, "name")),
      phone: toString(get(json, "phone")),
      email: toString(get(json, "email")),
      website: toString(get(json, "website")),
      postalAddress: toString(get(json, "postalAddress")),
      address: toString(get(json, "address")),
      serviceable: Boolean(get(json, "serviceable")),
      enabledFormSeries: toArray(get(json, "enabledFormSeries")),
      isIndependent: Boolean(get(json, "isIndependent")),
      defaultIndependent: Boolean(get(json, "defaultIndependent")),
      propertyLookupEnabled: Boolean(get(json, "propertyLookupEnabled")),
    };
  }
  private static parseDocumentCategoryEntity(json: any, jurisdictionId: number): IDocumentCategoryEntity {
    return {
      id: toInteger(get(json, "id")),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      jurisdictionId: jurisdictionId,
      displayOrder: toInteger(get(json, "displayOrder")),
      identifier: DocumentCategoryIdentifierUtil.parse(get(json, "identifier")),
    };
  }
}
