export enum CreateApplicationIntention {
  FromScratch = "FromScratch",
  FromTemplate = "FromTemplate",
  FromExistingApplication = "FromExistingApplication",
}

export const CreateApplicationIntentionUtil = {
  parse: (str: string): CreateApplicationIntention => {
    for (const value of Object.values(CreateApplicationIntention)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        return value;
      }
    }
    return CreateApplicationIntention.FromScratch;
  },
};
