import { IRequiredDocumentUploadEntity } from "models/RequiredDocument.model";

export enum RequiredDocumentGroupStatus {
  Pending = "Pending",
  NA = "NA",
  Accepted = "Accepted",
  Optional = "Optional",
  Unknown = "Unknown",
}

export enum RequiredDocumentGroupType {
  Mandatory = "Mandatory",
  Optional = "Optional",
}

export type IRequiredDocumentGroupEntity = {
  applicationId: number;
  id: number;
  documentContainerId: number;
  documentCategoryId: number;
  guid: string;
  name: string;
  status: RequiredDocumentGroupStatus;
  type: RequiredDocumentGroupType;
  building: string;
  subcategory: string;
  description: string;
  acceptedDate: string;
  requestedDate: string;
  displayOrder: number;

  // TODO: Normalise it
  requiredDocumentUploads: IRequiredDocumentUploadEntity[];
};
export const RequiredDocumentGroupUtil = {
  isGroupEditable: (requiredDocumentGroup: IRequiredDocumentGroupEntity): boolean => {
    return requiredDocumentGroup.status === RequiredDocumentGroupStatus.Pending;
  },
  isGroupAccepted: (requiredDocumentGroup: IRequiredDocumentGroupEntity): boolean => {
    return requiredDocumentGroup.status === RequiredDocumentGroupStatus.Accepted;
  },
  groupComparator: (a: IRequiredDocumentGroupEntity, b: IRequiredDocumentGroupEntity) => {
    if (a.applicationId !== b.applicationId) {
      return b.applicationId - a.applicationId;
    }
    const buildingA = a.building.toLowerCase();
    const buildingB = b.building.toLowerCase();
    if (buildingB === "" || buildingA < buildingB) {
      return -1;
    }
    if (buildingA > buildingB) {
      return 1;
    }

    // If building same then sort by display order
    return a.displayOrder - b.displayOrder;
  },
};
export const RequiredDocumentGroupStatusUtil = {
  parse: (str: string): RequiredDocumentGroupStatus => {
    const status = Object.values(RequiredDocumentGroupStatus).find(
      (value) => value.toLowerCase() === str.toLowerCase()
    );

    if (status) {
      return status;
    }

    return RequiredDocumentGroupStatus.Unknown;
  },
};
export const RequiredDocumentGroupTypeUtil = {
  parse: (str: string): RequiredDocumentGroupType => {
    const groupType = Object.values(RequiredDocumentGroupType).find(
      (value) => value.toLowerCase() === str.toLowerCase()
    );

    if (groupType) {
      return groupType;
    }

    return RequiredDocumentGroupType.Optional;
  },
};
