import { css } from "styled-components/macro";

export const layoutTransition = (prop: string | string[]) => {
  if (typeof prop === "string") {
    return css`
      ${prop} ease-in-out 0.25s
    `;
  }

  return css`
    ${prop.map((prop) => `${prop} ease-in-out 0.25s`).join(",")}
  `;
};
