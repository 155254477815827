import { IDocumentService } from "services/document/DocumentService.types";
import { IDocumentEntity } from "models/Document.model";
import { DocumentStatus, UploadStatusUtil } from "models/DocumentStatus.model";
import axios, { CancelToken } from "axios";
import toString from "lodash/toString";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import { Cradle } from "services/serviceContainer.types";
import { DocumentErrorCodeUtil } from "models/DocumentErrorCode.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class DocumentService implements IDocumentService {
  private apiClient: Cradle["apiClient"];

  constructor(args: { apiClient: Cradle["apiClient"] }) {
    this.apiClient = args.apiClient;
  }

  public async createDocument(args: { file: File; isBuffer: boolean; fileName?: string }): Promise<IDocumentEntity> {
    const { file, fileName: _fileName, isBuffer } = args;

    const fileName = _fileName || file.name;
    const fileType = file.type;
    const fileSize = file.size;

    const response = await this.apiClient.protectedApi.post(`/user/documents`, {
      name: fileName,
      mimeType: fileType,
      size: fileSize,
      isBuffer,
    });
    const json = response.data;
    const presignedUrl = toString(get(json, "presignedUrl"));
    const name = toString(get(json, "name"));
    const fileVersion = toInteger(get(json, "version"));
    const fileModifiedDate = toString(get(json, "modifiedDate"));

    const document: IDocumentEntity = {
      name,
      fileName,
      fileType,
      fileSize,
      fileVersion,
      fileModifiedDate,
      referenceUrl: "",
      presignedUrl,
      uploadStatus: presignedUrl ? DocumentStatus.Pending : DocumentStatus.Error,
      createdDate: new Date().toISOString(),
      errorCode: null,
    };

    return document;
  }

  public async fetchDocument(documentName: string): Promise<IDocumentEntity> {
    const response = await this.apiClient.protectedApi.get(`/user/documents/${documentName}`);
    const json = response.data;
    const document = DocumentService.parseDocumentEntity(json);
    return document;
  }

  public async fetchDocumentDownloadLink(documentName: string): Promise<string> {
    const response = await this.apiClient.protectedApi.get(`/user/documents/${documentName}/download-link`);
    const downloadLink = toString(get(response.data, "downloadLink"));
    return downloadLink;
  }

  public async updateDocumentStatus(args: {
    documentName: string;
    status: DocumentStatus.Uploading | DocumentStatus.Error;
  }): Promise<IDocumentEntity> {
    const urlPath = `/user/documents/${args.documentName}`;
    const requestPayload = {
      status: args.status.toUpperCase(),
    };
    const response = await this.apiClient.protectedApi.patch(urlPath, requestPayload);
    const document = DocumentService.parseDocumentEntity(response.data);
    return document;
  }

  public async uploadDocument(file: File, presignedUrl: string, options?: { cancelToken: CancelToken }): Promise<void> {
    if (!file || !presignedUrl) {
      throw new ServiceError(ServiceErrorCode.ClientError, "Upload file failed");
    }

    const fileContent = await this.readFile(file);
    const blob = this.dataURItoBlob(fileContent);
    await axios.put(presignedUrl, blob, {
      cancelToken: options?.cancelToken,
    });
  }

  public static parseDocumentEntity(json: any): IDocumentEntity {
    // Document entities and relations
    const documentEntity: IDocumentEntity = {
      name: toString(get(json, "name")),
      fileName: toString(get(json, "originalName")),
      fileType: toString(get(json, "mimeType")),
      fileSize: toInteger(get(json, "size")),
      fileVersion: toInteger(get(json, "version")),
      fileModifiedDate: toString(get(json, "modifiedDate")),
      presignedUrl: toString(get(json, "presignedUrl")),
      referenceUrl: toString(get(json, "reference")),
      createdDate: toString(get(json, "createdDate")),
      uploadStatus: UploadStatusUtil.parse(toString(get(json, "status"))),
      errorCode: DocumentErrorCodeUtil.parse(toString(get(json, "errorCode"))),
      isMergedFile: Boolean(get(json, "isMergedFile")),
    };

    return documentEntity;
  }

  private async readFile(file: File) {
    const reader = new FileReader();
    const promise = new Promise<string>((resolve) => {
      reader.onloadend = () => {
        const result = reader.result as string;
        resolve(result);
      };
      reader.readAsDataURL(file);
    });
    return promise;
  }

  // Refer: https://stackoverflow.com/questions/12168909/blob-from-dataurl
  private dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }
}
