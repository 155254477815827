import { INotificationService } from "services/notifications/NotificationService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import {
  INotificationEntity,
  NotificationMessageTypeUtil,
  NotificationRecipientType,
  NotificationRecipientTypeUtil,
  NotificationSortOrder,
} from "models/Notification.model";
import toString from "lodash/toString";
import {
  BNS_FETCH_APPLICATION_NOTIFICATIONS_ENABLED,
  BNS_FETCH_NOTIFICATION_DETAILS_ENABLED,
  BNS_FETCH_USER_NOTIFICATIONS_ENABLED,
} from "constants/configs";
import { base64Decode } from "utils/base64Decode";
import isEmpty from "lodash/isEmpty";

export class NotificationService implements INotificationService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchNotifications(args: {
    pageSize: number;
    orderBy: NotificationSortOrder;
    recipientType: NotificationRecipientType;
    recipientId: string;
    lastKey?: string;
  }) {
    if (
      (!BNS_FETCH_USER_NOTIFICATIONS_ENABLED && args.recipientType === NotificationRecipientType.User) ||
      (!BNS_FETCH_APPLICATION_NOTIFICATIONS_ENABLED && args.recipientType === NotificationRecipientType.Application)
    ) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Not implemented");
    }

    const notificationSearchParams = new URLSearchParams();
    notificationSearchParams.set("pageSize", toString(args.pageSize));
    notificationSearchParams.set("order", args.orderBy);
    notificationSearchParams.set("recipientType", args.recipientType);
    notificationSearchParams.set("recipientId", toString(args.recipientId));

    if (!isEmpty(args.lastKey)) {
      notificationSearchParams.set("lastKey", toString(args.lastKey));
    }

    const response = await this.apiClient.protectedApi.get(`/v1/service/notifications`, {
      params: notificationSearchParams,
    });
    const jsonArr = get(response.data, "notifications");
    const lastKey = toString(get(response.data, "lastKey"));

    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch notifications`));
    }

    const notifications = jsonArr.map((json) => this.parseNotification(json));
    return {
      notifications,
      lastKey,
    };
  }

  public async fetchNotificationById(notificationId: string) {
    if (!BNS_FETCH_NOTIFICATION_DETAILS_ENABLED) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Not implemented");
    }

    try {
      const response = await this.apiClient.protectedApi.get(`/v1/service/notifications/${notificationId}`);
      return this.parseNotificationDetails(response.data);
    } catch (error) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch notification`));
    }
  }

  private parseNotification(json: any): INotificationEntity {
    const encodedShortText = toString(get(json, "message.shortText"));
    return {
      id: toString(get(json, "id")),
      groupId: toString(get(json, "groupId")),
      recipientId: toString(get(json, "recipientId")),
      recipientType: NotificationRecipientTypeUtil.parse(toString(get(json, "recipientType"))),

      createdDate: toString(get(json, "createdDate")),
      updatedDate: toString(get(json, "updatedDate")),

      message: {
        subtype: NotificationMessageTypeUtil.parse(toString(get(json, "message.subtype"))),
        shortText: base64Decode(encodedShortText),
      },

      recipients: {
        users: this.parseNotificationRecipientUsers(get(json, "recipients.users")),
        organisation: {
          id: toString(get(json, "recipients.organisation.id")),
          email: toString(get(json, "recipients.organisation.email")),
        },
        application: {
          id: toString(get(json, "recipients.application.id")),
        },
        authority: {
          id: toString(get(json, "recipients.authority.id")),
        },
      },
      apiVersion: toString(get(json, "apiVersion")),
    };
  }

  private parseNotificationDetails(json: any): INotificationEntity {
    const notificationDetails = {
      ...this.parseNotification(json),
    };

    const encodedFullText = toString(get(json, "message.fullText"));
    notificationDetails.message.fullText = base64Decode(encodedFullText);
    const encodedHtmlText = toString(get(json, "message.htmlText"));
    notificationDetails.message.htmlText = base64Decode(encodedHtmlText);

    return notificationDetails;
  }

  private parseNotificationRecipientUsers(jsonArr: any): INotificationEntity["recipients"]["users"] {
    if (!jsonArr || !Array.isArray(jsonArr)) {
      return [];
    }

    const users = jsonArr
      .map((json) => {
        const user: INotificationEntity["recipients"]["users"][0] = {
          id: toString(get(json, "id")),
          email: toString(get(json, "email")),
          fullName: toString(get(json, "fullName")),
        };
        return user;
      })
      .filter((user) => user.id);

    return users;
  }
}
