import { useStoreSelector } from "store/hooks";
import { useApplicationById } from "./useApplicationById";
import { selectAuthorityEntityById } from "store/domain-data/authority/authority";

export const useApplicationBuildingConsentDetails = (applicationId: number) => {
  const application = useApplicationById(applicationId);
  const authorityId = application?.authorityId || 0;
  const authority = useStoreSelector((state) => selectAuthorityEntityById(state, authorityId));
  const isConsentFound = Boolean(application?.consentNumber);
  const consentNumber = application?.consentNumber || "";
  const issuedBy = authority?.displayName || "";
  return { isConsentFound, consentNumber, issuedBy };
};
