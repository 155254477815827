export type IDocumentTagRelation = {
  documentName: string;
  documentTagId: number;
};

export const DocumentTagRelationUtil = {
  generateKey: (relation: IDocumentTagRelation) => {
    return `documentName:${relation.documentName}|documentTagId:${relation.documentTagId}`;
  },
};
