import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import styled, { css } from "styled-components/macro";

type Props = {
  title: React.ReactNode;
};

const MessageSection: React.FC<Props> = ({ title, children }) => {
  return (
    <FlexBox direction="column" alignItems="center" spacing={4}>
      <StyledTitle>{title}</StyledTitle>
      <StyledContent spacing={2}>{children}</StyledContent>
    </FlexBox>
  );
};

const StyledTitle = styled.h3(
  () => css`
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
  `
);

const StyledContent = styled.div<{ spacing: number }>(
  ({ spacing }) => css`
    flex-direction: column;
    align-items: center;
    gap: ${spacing * 4}px;

    font-weight: 300;
    font-size: 16px;
    line-height: 1.2;
  `
);

export default MessageSection;
