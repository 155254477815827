import { ButtonProps as MUIButtonProps } from "@material-ui/core";
import React from "react";
import TextButton from "components/odl-v2/Button/TextButton";
import ContainedButton from "components/odl-v2/Button/ContainedButton";
import OutlinedButton from "components/odl-v2/Button/OutlinedButton";

// Omit props that we don't support to avoid them being used
type Props = Omit<MUIButtonProps, "color" | "disableRipple" | "disableElevation" | "disableFocusRipple" | "size"> & {
  size?: "regular" | "large";
};

const Button: React.FC<Props> = React.forwardRef(({ variant, ...props }, ref) => {
  switch (variant) {
    case "text":
      return <TextButton ref={ref} {...props} />;
    case "contained":
      return <ContainedButton ref={ref} {...props} />;
    case "outlined":
      return <OutlinedButton ref={ref} {...props} />;
    default:
      return <TextButton ref={ref} {...props} />;
  }
});

export default Button;
export type ButtonProps = Props;
