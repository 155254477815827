export enum ApplicationSortCriteria {
  ApplicationNumber = "applicationNumber",
  ConsentNumber = "consentNumber",
  StartedDate = "startedDate",
  ModifiedDate = "modifiedDate",
  SubmittedDate = "submissionDate",
}

export const ApplicationSortCriteriaUtil = {
  parse: (criteria: string): ApplicationSortCriteria => {
    switch (criteria) {
      case "applicationNumber":
        return ApplicationSortCriteria.ApplicationNumber;
      case "consentNumber":
        return ApplicationSortCriteria.ConsentNumber;
      case "startedDate":
        return ApplicationSortCriteria.StartedDate;
      case "modifiedDate":
        return ApplicationSortCriteria.ModifiedDate;
      case "submissionDate":
        return ApplicationSortCriteria.SubmittedDate;
      default:
        return ApplicationSortCriteria.ModifiedDate;
    }
  },
};
