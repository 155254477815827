import { createAction, createAsyncThunk, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { IPaymentEntity, PaymentMethod, PaymentStatus } from "models/Payment.model";
import { loadPaymentOptions } from "store/domain-data/payment-option/paymentOption";
import { batch } from "react-redux";

// Entity Adapter
const paymentAdapter = createEntityAdapter<IPaymentEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    if (a.applicationId !== b.applicationId) {
      return a.applicationId - b.applicationId;
    }

    return a.displayOrder - b.displayOrder;
  },
});

// Actions
export const loadPayment = createAction<IPaymentEntity>("domainData/payment/loadPayment");
export const loadPayments = createAction<IPaymentEntity[]>("domainData/payment/loadPayments");

// Thunks
export const fetchPaymentsByApplicationId = createAsyncThunk(
  "domainData/payment/fetchPaymentsByApplicationId",
  async (applicationId: number, thunkAPI) => {
    const payments = await serviceContainer.cradle.paymentService.fetchPaymentsByApplicationId(applicationId);
    thunkAPI.dispatch(loadPayments(payments));
    return payments;
  }
);

export const fetchPayment = createAsyncThunk(
  "domainData/payment/fetchPayment",
  async (args: { applicationId: number; paymentId: number }, thunkAPI) => {
    const { payment, paymentOptions } = await serviceContainer.cradle.paymentService.fetchPayment(args);
    batch(() => {
      thunkAPI.dispatch(loadPayment(payment));
      thunkAPI.dispatch(loadPaymentOptions(paymentOptions));
    });
  }
);

export const claimPaymentBeingMade = createAsyncThunk(
  "domainData/payment/directDeposit",
  async (
    args: {
      applicationId: number;
      paymentId: number;
      paymentMethod: PaymentMethod;
      transactionDate: string;
      proofDocumentName?: string;
    },
    thunkAPI
  ) => {
    const { payment, paymentOptions } = await serviceContainer.cradle.paymentService.claimPaymentBeingMade(args);
    batch(() => {
      thunkAPI.dispatch(loadPayment(payment));
      thunkAPI.dispatch(loadPaymentOptions(paymentOptions));
    });
  }
);

export const claimOnlinePaymentBeingMade = createAsyncThunk(
  "domainData/payment/onlinePayment",
  async (args: { applicationId: number; paymentId: number; token: string }, thunkAPI) => {
    const result = await serviceContainer.cradle.paymentService.claimOnlinePaymentBeingMade(args);
    return result;
  }
);

// Reducer
export const defaultPaymentState = paymentAdapter.getInitialState();
export const paymentReducer = createReducer<EntityState<IPaymentEntity>>(defaultPaymentState, (builder) =>
  builder
    // @prettier-ignore
    .addCase(loadPayments, paymentAdapter.upsertMany)
    .addCase(loadPayment, paymentAdapter.upsertOne)
);

// Selectors
export const {
  selectById: selectPaymentEntityById,
  selectIds: selectPaymentsEntityIds,
  selectEntities: selectPaymentEntities,
  selectAll: selectAllPaymentEntities,
} = paymentAdapter.getSelectors((state: RootState) => state.domainData.payment);

export const selectPaymentsByApplicationId = createDeepEqualSelector(
  [selectAllPaymentEntities, (state: RootState, applicationId: number) => applicationId],
  (entities, applicationId) => {
    return entities.filter((entity) => entity.applicationId === applicationId);
  }
);

export const selectOutstandingPaymentsByApplicationId = createDeepEqualSelector(
  [selectPaymentsByApplicationId, (state: RootState, applicationId: number) => applicationId],
  (entities) => {
    return entities.filter((payment) => payment.status === PaymentStatus.AwaitingPayment);
  }
);
