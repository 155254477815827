import { ApplicationDetailsSections } from "models/ApplicationDetailsSections";
import { ApplicationListTabType } from "components/ApplicationListTab/ApplicationListTab.types";
import trimEnd from "lodash/trimEnd";
import { RFIPointType } from "models/RFIPoint.model";
import { CreateApplicationIntention } from "models/CreateApplicationIntention.model";
import { JurisdictionFormCategory } from "models/JurisdictionForm.model";
import { PROJECTS_ENABLED } from "constants/configs";

const getUrlForApplicationDetailsPage = (args: {
  applicationId: number;
  section?: ApplicationDetailsSections;
  subSection?: string;
}) => {
  const searchParams = new URLSearchParams();
  if (args.section) {
    searchParams.set("section", args.section);
  }
  if (args.subSection) {
    searchParams.set("subSection", args.subSection);
  }
  return trimEnd(`/application-details/${args.applicationId}?${searchParams.toString()}`, "?");
};

const getUrlForApplicationFormPage = (args: { applicationId: number }) => {
  return `/application-form/${args.applicationId}`;
};

const gerUrlForApplicationsPage = (
  args: {
    tab?: ApplicationListTabType.Draft | ApplicationListTabType.Submitted | ApplicationListTabType.Archived;
    encodedFilters?: string;
  } = {}
) => {
  const searchParams = new URLSearchParams();
  if (args.tab) {
    searchParams.set("tab", args.tab);
  }
  if (args.encodedFilters) {
    searchParams.set("filters", args.encodedFilters);
  }
  if (PROJECTS_ENABLED) {
    return trimEnd(`/projects?${searchParams.toString()}`, "?");
  }
  return trimEnd(`/applications?${searchParams.toString()}`, "?");
};

const gerUrlForConsentsPage = (
  args: {
    tab?: ApplicationListTabType.Current | ApplicationListTabType.Archived;
    encodedFilters?: string;
  } = {}
) => {
  const searchParams = new URLSearchParams();
  if (args.tab) {
    searchParams.set("tab", args.tab);
  }
  if (args.encodedFilters) {
    searchParams.set("filters", args.encodedFilters);
  }
  if (PROJECTS_ENABLED) {
    return trimEnd(`/projects?${searchParams.toString()}`, "?");
  }
  return trimEnd(`/consents?${searchParams.toString()}`, "?");
};

const getUrlForUserProfilePage = () => "/user-profile";

const getUrlForApplicationDetailsPageBasedOnRFIType = (args: { applicationId: number; rfiPointType: RFIPointType }) => {
  return trimEnd(
    `/application-details/${args.applicationId}?section=${ApplicationDetailsSections.RFIs}&subSection=${args.rfiPointType}`
  );
};

const getUrlForApplicationPropertyRecordsPage = (args: {
  address: string;
  authorityId: string;
  intention: CreateApplicationIntention;
  refApplicationId: string;
}) => {
  const { address, authorityId, intention, refApplicationId } = args;
  const searchParams = new URLSearchParams();
  searchParams.set("address", address);
  searchParams.set("authority-id", authorityId);
  searchParams.set("intention", intention);
  searchParams.set("refApplicationId", refApplicationId);
  return trimEnd(`/new-application/authority-property-records?${searchParams.toString()}`, "?");
};

const getUrlForApplicationFormSelectionPageForStandAloneApplication = (args: {
  address: string;
  authorityId: string;
  category: JurisdictionFormCategory;
  parcel: string;
}) => {
  const { address, authorityId, category, parcel } = args;
  const searchParams = new URLSearchParams();
  searchParams.set("address", address);
  searchParams.set("authority-id", authorityId);
  searchParams.set("parcel", parcel);
  searchParams.set("category", category);
  return trimEnd(`/new-application/form-selection?${searchParams.toString()}`, "?");
};

const getUrlForApplicationFormSelectionPageForNewApplication = (newApplicationId: number) => {
  return trimEnd(`/application-form/${newApplicationId}`);
};

export const urlHelper = {
  getUrlForApplicationDetailsPage,
  getUrlForApplicationFormPage,
  gerUrlForApplicationsPage,
  gerUrlForConsentsPage,
  getUrlForUserProfilePage,
  getUrlForApplicationDetailsPageBasedOnRFIType,
  getUrlForApplicationPropertyRecordsPage,
  getUrlForApplicationFormSelectionPageForStandAloneApplication,
  getUrlForApplicationFormSelectionPageForNewApplication,
};
