import React from "react";
import { useStoreDispatch } from "store/hooks";
import { fetchApplicationDocuments } from "store/domain-data/application-document-relation/applicationDocumentRelation";

/**
 * Poll the documents endpoint to update document status
 * @param applicationId
 * @param shouldScheduleInterval
 */
export const usePollApplicationDocuments = (applicationId: number, shouldScheduleInterval: boolean) => {
  const dispatch = useStoreDispatch();
  const pollIntervalIdRef = React.useRef<any>();

  React.useEffect(() => {
    // If no incomplete document but interval task is still running, cancel it
    if (!shouldScheduleInterval) {
      clearInterval(pollIntervalIdRef.current);
      return;
    }

    // Schedule interval task - fetch application documents in every 5 seconds
    pollIntervalIdRef.current = setInterval(async () => {
      await dispatch(fetchApplicationDocuments(applicationId));
    }, 5000);

    // Cancel interval task when clearing effect (e.g., unmount, dependency array changes)
    return () => {
      clearInterval(pollIntervalIdRef.current);
    };
  }, [applicationId, dispatch, shouldScheduleInterval]);
};
