import { selectDocumentEntitiesByApplicationIdForContainer } from "store/domain-data/document/document";
import { DOCUMENT_CONTAINER_UPLOAD_CONFIG } from "constants/configs";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { DocumentContainerType } from "models/DocumentContainer.model";

export const useCheckContainerFileNumberLimit = (applicationId: number, documentContainerId: number) => {
  const { t } = useTranslation();
  const store = useStore<RootState>();

  const checkContainerFileNumberLimit = React.useCallback(
    (numOfFiles: number) => {
      // Get latest documents in container when drop
      const documentsInContainer = selectDocumentEntitiesByApplicationIdForContainer(store.getState(), {
        applicationId,
        documentContainerId,
      });

      const documentContainer = selectDocumentContainersEntityById(store.getState(), documentContainerId);
      if (documentContainer?.containerType === DocumentContainerType.RFIDocument) {
        return;
      }

      // Handle file number limitation upfront
      if (documentsInContainer.length + numOfFiles > DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MAX_FILE_NUMBERS) {
        throw new Error(
          t(`Max file numbers is {{max_number}} per container`, {
            max_number: DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MAX_FILE_NUMBERS,
          })
        );
      }
    },
    [applicationId, documentContainerId, store, t]
  );

  return checkContainerFileNumberLimit;
};
