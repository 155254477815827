import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IRequiredDocumentUploadEntity, RequiredDocumentStatusUtil } from "models/RequiredDocument.model";
import toNumber from "lodash/toNumber";
import { UploadStatusUtil } from "models/DocumentStatus.model";
import { IRequiredDocumentsService } from "services/required-documents/RequiredDocumentsService.types";
import toInteger from "lodash/toInteger";
import {
  IRequiredDocumentGroupEntity,
  RequiredDocumentGroupStatusUtil,
  RequiredDocumentGroupTypeUtil,
  RequiredDocumentGroupUtil,
} from "models/RequiredDocumentGroup.model";
import toArray from "lodash/toArray";

export class RequiredDocumentsService implements IRequiredDocumentsService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchRequiredDocumentGroups(applicationId: number): Promise<IRequiredDocumentGroupEntity[]> {
    const response = await this.apiClient.protectedApi.get(
      `user/applications/${applicationId}/required-document-groups`
    );
    const requiredDocumentGroupsAsJson = get(response, "data.requiredDocumentGroups");
    if (!Array.isArray(requiredDocumentGroupsAsJson)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch required document groups`));
    }

    return this.parseRequiredDocumentGroups(applicationId, requiredDocumentGroupsAsJson);
  }

  private parseRequiredDocumentGroups(
    applicationId: number,
    documentGroupsJson: any[]
  ): IRequiredDocumentGroupEntity[] {
    if (!documentGroupsJson || !Array.isArray(documentGroupsJson)) {
      return [];
    }

    const requiredDocumentGroups = documentGroupsJson.map((documentGroupJson) =>
      this.parseRequiredDocumentGroup(applicationId, documentGroupJson)
    );

    return requiredDocumentGroups.sort(RequiredDocumentGroupUtil.groupComparator);
  }

  private parseRequiredDocumentGroup(applicationId: number, documentGroupJson: string): IRequiredDocumentGroupEntity {
    const guid = toString(get(documentGroupJson, "guid"));
    return {
      applicationId,
      id: toNumber(get(documentGroupJson, "id")),
      documentContainerId: toNumber(get(documentGroupJson, "documentContainerId")),
      documentCategoryId: toNumber(get(documentGroupJson, "documentCategoryId")),
      guid,
      name: toString(get(documentGroupJson, "name")),
      status: RequiredDocumentGroupStatusUtil.parse(toString(get(documentGroupJson, "status"))),
      type: RequiredDocumentGroupTypeUtil.parse(toString(get(documentGroupJson, "type"))),
      building: toString(get(documentGroupJson, "building")).trim(),
      subcategory: toString(get(documentGroupJson, "subcategory")),
      description: toString(get(documentGroupJson, "description")),
      acceptedDate: toString(get(documentGroupJson, "acceptedDate")),
      requestedDate: toString(get(documentGroupJson, "requestedDate")),
      displayOrder: toInteger(get(documentGroupJson, "displayOrder")),
      requiredDocumentUploads: this.parseRequiredDocumentUploads(
        guid,
        toArray(get(documentGroupJson, "requiredDocumentUploads"))
      ),
    };
  }

  private parseRequiredDocumentUploads(
    requiredDocumentGroupGuid: string,
    documentUploadsJson: any[]
  ): IRequiredDocumentUploadEntity[] {
    if (!documentUploadsJson || !Array.isArray(documentUploadsJson)) {
      return [];
    }

    const requiredDocumentUploads = documentUploadsJson.map((documentUploadJson) =>
      this.parseRequiredDocumentUpload(requiredDocumentGroupGuid, documentUploadJson)
    );

    const requiredDocumentUploadComparator = (a: IRequiredDocumentUploadEntity, b: IRequiredDocumentUploadEntity) => {
      const fileNameA = a.fileName.toLowerCase();
      const fileNameB = b.fileName.toLowerCase();
      if (fileNameA < fileNameB) {
        return -1;
      }
      if (fileNameA > fileNameB) {
        return 1;
      }

      // If building same then sort by display order
      return 0;
    };
    return requiredDocumentUploads.sort(requiredDocumentUploadComparator);
  }

  private parseRequiredDocumentUpload(
    requiredDocumentGroupGuid: string,
    documentUploadJson: string
  ): IRequiredDocumentUploadEntity {
    return {
      requiredDocumentGroupGuid: requiredDocumentGroupGuid,
      applicationDocumentId: toNumber(get(documentUploadJson, "applicationDocumentId")),
      name: toString(get(documentUploadJson, "name")),
      documentStatus: UploadStatusUtil.parse(toString(get(documentUploadJson, "documentStatus"))),
      councilStatus: RequiredDocumentStatusUtil.parse(toString(get(documentUploadJson, "councilStatus"))),
      reason: toString(get(documentUploadJson, "reason")),
      receivedDate: toString(get(documentUploadJson, "receivedDate")),
      processingDate: toString(get(documentUploadJson, "processingDate")),
      processingDocumentGuid: toString(get(documentUploadJson, "processingDocumentGuid")),
      fileName: toString(get(documentUploadJson, "fileName")),
      fileSize: toInteger(get(documentUploadJson, "fileSize")),
      mimeType: toString(get(documentUploadJson, "mimeType")),
    };
  }
}
