import React from "react";
import NotificationSubscriptionLevelDialog from "components/NotificationSubscriptionLevelDialog/NotificationSubscriptionLevelDialog";

export const useNotificationSubscriptionLevelDialog = (applicationId: number) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const openNotificationSubscriptionLevelDialog = React.useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleUpdateNotificationLevelDialogClose = React.useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  const notificationSubscriptionLevelDialog = React.useMemo(() => {
    if (!isDialogOpen) {
      return null;
    }
    return (
      <NotificationSubscriptionLevelDialog
        applicationId={applicationId}
        onClose={handleUpdateNotificationLevelDialogClose}
      />
    );
  }, [applicationId, handleUpdateNotificationLevelDialogClose, isDialogOpen]);

  return {
    notificationSubscriptionLevelDialog,
    openNotificationSubscriptionLevelDialog,
  };
};
