import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import toInteger from "lodash/toInteger";
import { IDocumentTagService } from "services/document-tag/DocumentTagService.types";
import { IDocumentTagEntity } from "models/DocumentTag.model";
import { IDocumentTagRelation } from "models/DocumentTagRelation.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class DocumentTagService implements IDocumentTagService {
  private apiClient: Cradle["apiClient"];

  constructor(args: { apiClient: Cradle["apiClient"] }) {
    this.apiClient = args.apiClient;
  }

  public async fetchDocumentTagsByApplicationDocument(args: {
    applicationId: number;
    documentName: string;
  }): Promise<{
    documentTagEntities: IDocumentTagEntity[];
    documentTagRelations: IDocumentTagRelation[];
  }> {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${args.applicationId}/documents/${args.documentName}/tags`
    );
    const jsonArr = get(response.data, "documentTags");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Failed to fetch document tags");
    }
    const documentTagEntities = jsonArr.map(DocumentTagService.parseDocumentTag);
    const documentTagRelations = documentTagEntities.map(
      (documentTag): IDocumentTagRelation => {
        return {
          documentName: args.documentName,
          documentTagId: documentTag.id,
        };
      }
    );

    return {
      documentTagEntities,
      documentTagRelations,
    };
  }

  public async fetchUserDocumentTagsForAuthenticatedUser(): Promise<IDocumentTagEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/documenttags`);
    const jsonArr = get(response.data, "documentTags");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Failed to fetch document tags");
    }
    const documentTagEntities = jsonArr.map(DocumentTagService.parseDocumentTag);
    return documentTagEntities;
  }

  public static parseDocumentTag(json: any): IDocumentTagEntity {
    const documentTag: IDocumentTagEntity = {
      id: toInteger(get(json, "id", 0)),
      name: toString(get(json, "name")),
      displayName: toString(get(json, "displayName")),
      displayOrder: toInteger(get(json, "displayOrder", 0)),
      // TODO: "userId" is not always coming back with the backend payload, need to negotiate / passing it as an arg
      userId: toInteger(get(json, "userId", 0)),
      description: toString(get(json, "description")),
    };
    return documentTag;
  }

  public async addDocumentTagToApplicationDocument(args: {
    applicationId: number;
    documentName: string;
    documentTagName: string;
  }): Promise<{
    documentTagEntity: IDocumentTagEntity;
    documentTagRelation: IDocumentTagRelation;
  }> {
    const response = await this.apiClient.protectedApi.post(
      `/user/applications/${args.applicationId}/documents/${args.documentName}/tags`,
      {
        name: args.documentTagName,
      }
    );
    const documentTagEntity = DocumentTagService.parseDocumentTag(response.data);
    const documentTagRelation: IDocumentTagRelation = {
      documentName: args.documentName,
      documentTagId: documentTagEntity.id,
    };
    return {
      documentTagEntity,
      documentTagRelation,
    };
  }

  public async removeDocumentTagFromApplicationDocument(args: {
    applicationId: number;
    documentName: string;
    documentTagId: number;
  }): Promise<void> {
    return await this.apiClient.protectedApi.delete(
      `/user/applications/${args.applicationId}/documents/${args.documentName}/tags/${args.documentTagId}`
    );
  }

  public async setDocumentTagForApplicationDocument(args: {
    applicationId: number;
    documentName: string;
    documentTagNames: string[];
  }): Promise<{
    documentTagEntities: IDocumentTagEntity[];
    documentTagRelations: IDocumentTagRelation[];
  }> {
    const response = await this.apiClient.protectedApi.put(
      `/user/applications/${args.applicationId}/documents/${args.documentName}/tags`,
      {
        tags: args.documentTagNames,
      }
    );
    const jsonArr = get(response.data, "documentTags");
    if (!jsonArr || !Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, "Failed to fetch document tags");
    }
    const documentTagEntities = jsonArr.map(DocumentTagService.parseDocumentTag);
    const documentTagRelations = documentTagEntities.map(
      (documentTag): IDocumentTagRelation => {
        return {
          documentName: args.documentName,
          documentTagId: documentTag.id,
        };
      }
    );

    return {
      documentTagEntities,
      documentTagRelations,
    };
  }
}
