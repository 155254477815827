import React from "react";
import styled, { css } from "styled-components/macro";
import FlexBox from "components/FlexBox/FlexBox";

type Props = {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
  onClick: () => void;
};

const StartApplicationIntentCard: React.FC<Props> = ({ icon, title, subTitle, onClick }) => {
  return (
    <StyledApplicationIntentCardContainer
      onClick={onClick}
      data-testid={"ApplicationIntentCardMinimal"}
      aria-label={title}
    >
      <div>{icon}</div>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <FlexBox spacing={2} direction={"column"}>
          <StyledDescription>{subTitle}</StyledDescription>
        </FlexBox>
      </StyledContent>
    </StyledApplicationIntentCardContainer>
  );
};

const StyledApplicationIntentCardContainer = styled.article(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    padding: 16px 24px 16px 24px;

    ${theme.mixins.flexGap("16px")};
    cursor: pointer;

    border: 1px solid ${theme.palette.objective.light.allspice};
    border-radius: 4px;

    &:hover {
      background-color: ${theme.palette.objective.light.day};
    }
  `
);

const StyledContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${theme.mixins.flexGap("4px")};
  `
);

const StyledTitle = styled.h4(
  ({ theme }) => css`
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledDescription = styled.p(
  ({ theme }) => css`
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

export default StartApplicationIntentCard;
