import { createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { IDocumentContainerEntity } from "models/DocumentContainer.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { loadApplicationDocumentContainerRelation } from "store/domain-data/application-document-containers-relation/applicationDocumentContainersRelation";

// Adapter

const documentContainersAdapter = createEntityAdapter<IDocumentContainerEntity>({
  selectId: (container) => container.id,
  sortComparer: (a, b) => a.displayOrder - b.displayOrder,
});

// Action and Thunks

export const fetchApplicationDocumentContainers = createAsyncThunk(
  "domainData/document-containers/fetchApplicationDocumentContainers",
  async (applicationId: number, thunkAPI) => {
    const documentContainers = await serviceContainer.cradle.applicationDocumentService.fetchDocumentContainersByApplication(
      applicationId
    );
    const documentContainerIds = documentContainers.map((documentContainer) => documentContainer.id);
    thunkAPI.dispatch(
      loadApplicationDocumentContainerRelation({
        applicationId,
        documentContainerIds,
      })
    );
    return documentContainers;
  }
);

// Reducer

export const defaultDocumentContainersState = documentContainersAdapter.getInitialState();

export const documentContainerReducer = createReducer<typeof defaultDocumentContainersState>(
  defaultDocumentContainersState,
  (builder) => {
    builder.addCase(fetchApplicationDocumentContainers.fulfilled, documentContainersAdapter.upsertMany);
  }
);

// Selectors

export const {
  selectById: selectDocumentContainersEntityById,
  selectIds: selectDocumentContainersEntityIds,
  selectAll: selectAllDocumentContainers,
} = documentContainersAdapter.getSelectors((state: RootState) => state.domainData.documentContainers);
