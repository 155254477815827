import first from "lodash/first";
import { IAddressEntity } from "models/Address.model";
import { OrganisationRole } from "models/OrganisationMember.model";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import { IEncodedImageFile } from "models/EncodedImageFile.model";

export enum UserStatus {
  Active = "Active",
  Disabled = "Disabled",
}

export const UserStatusUtil = {
  parse: (str: string): UserStatus => {
    for (const value of Object.values(UserStatus)) {
      if (str === value.toString()) {
        return value;
      }
    }
    return UserStatus.Active;
  },
};

export type IUserInfoEntity = {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  contactCountryCode: string;
  organisationId: number;
  organisationRole: OrganisationRole;
  organisationColour: string | null;
  organisationImage: IEncodedImageFile | null;
  isOrganisationAccount: boolean;
  address: IAddressEntity;
  defaultApplicationNotificationLevel: NotificationLevel;
  emailNotificationEnabled: boolean;
  smsNotificationEnabled: boolean;
  pushNotificationEnabled: boolean;
};

export const UserInfoUtil = {
  getDisplayName: (userInfo?: IUserInfoEntity): string => {
    if (!userInfo) {
      return "";
    }

    const displayName = `${userInfo.firstName} ${userInfo.lastName}`.trim();
    if (!displayName) {
      return userInfo.username;
    }

    return displayName;
  },
  getFirstCharacter: (userInfo?: IUserInfoEntity) => {
    const displayName = UserInfoUtil.getDisplayName(userInfo);
    if (displayName.length === 0) {
      return "";
    }

    return displayName[0].toUpperCase();
  },
  getInitials: (userInfo?: IUserInfoEntity) => {
    if (!userInfo) {
      return "";
    }

    // If no first name or last name exist, use the username to get the avatar content
    if (!userInfo.firstName && !userInfo.lastName) {
      return first(userInfo.username)?.toUpperCase() || "";
    }

    return [userInfo.firstName, userInfo.lastName]
      .filter((str) => str && str.length > 0)
      .map((str) => str[0].toUpperCase())
      .join("");
  },
  isProfileComplete: (userInfo: IUserInfoEntity) => {
    if (!userInfo.firstName || !userInfo.lastName) {
      return false;
    }

    if (!userInfo.contactNumber) {
      return false;
    }

    if (!userInfo.address || !userInfo.address.fullAddress) {
      return false;
    }

    return true;
  },
};
