import toString from "lodash/toString";
import get from "lodash/get";
import { base64Encode } from "utils/base64Encode";
import { base64Decode } from "utils/base64Decode";

export type IParcelEntity = {
  parcelId: string;
  ratingAddress: string;
  legalDescription: string;
  lotNumber: string;
  dpNumber: string;
  valuationNumber: string;
};

export const ParcelUtil = {
  base64Encode: (parcel?: IParcelEntity): string => {
    if (!parcel) {
      return "";
    }
    const jsonString = JSON.stringify(parcel);
    const encodedString = base64Encode(encodeURIComponent(jsonString));
    return encodedString;
  },

  base64Decode: (encodedString: string): IParcelEntity | undefined => {
    if (!encodedString) {
      return;
    }

    const decodedString = decodeURIComponent(base64Decode(encodedString));
    const json = JSON.parse(decodedString);
    const parcel = {
      parcelId: toString(get(json, "parcelId")),
      ratingAddress: toString(get(json, "ratingAddress")),
      legalDescription: toString(get(json, "legalDescription")),
      lotNumber: toString(get(json, "lotNumber")),
      dpNumber: toString(get(json, "dpNumber")),
      valuationNumber: toString(get(json, "valuationNumber")),
    };

    return parcel;
  },
};
