import React from "react";
import { useStoreDispatch } from "store/hooks";
import { useToast } from "hooks/useToast";
import { FileRejection } from "react-dropzone";
import { addFilesToQueue } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHandleFileRejections } from "components/DocumentContainer/functions/useHandleFileRejections";
import { useCheckContainerFileNumberLimit } from "components/DocumentContainer/functions/useCheckContainerFileNumberLimit";
import { prettyBytes } from "utils/prettyBytes";
import { CONVERT_IMAGE_TO_PDF_ENABLED, DOCUMENT_CONTAINER_UPLOAD_CONFIG } from "constants/configs";

type Props = {
  applicationId: number;
  documentContainerId: number;
  documentCategoryId: number;
};

export const useHandleDropFiles = ({ applicationId, documentContainerId, documentCategoryId }: Props) => {
  const { toastError } = useToast();
  const dispatch = useStoreDispatch();

  const acceptFileTypeDescription = CONVERT_IMAGE_TO_PDF_ENABLED ? "PDF, JPEG, and PNG" : "PDF";
  const maxSizeDescription = prettyBytes(DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MAX_FILE_SIZE);
  const handleFileRejections = useHandleFileRejections({ acceptFileTypeDescription, maxSizeDescription });
  const checkContainerFileNumberLimit = useCheckContainerFileNumberLimit(applicationId, documentContainerId);

  const handleDropFiles = React.useCallback(
    async (files: File[], fileRejections: FileRejection[]) => {
      // Handle rejections but does not block the flow
      handleFileRejections(fileRejections);
      if (files.length === 0) {
        return;
      }

      try {
        checkContainerFileNumberLimit(files.length);
      } catch (error) {
        toastError(error);
        return;
      }

      // Add files to queue
      try {
        const { newDocuments, duplicatedDocuments } = await dispatch(
          addFilesToQueue({ files, applicationId, documentContainerId, documentCategoryId })
        ).then(unwrapResult);
        return { newDocuments, duplicatedDocuments };
      } catch (e) {
        toastError(e);
      }
    },
    [
      handleFileRejections,
      checkContainerFileNumberLimit,
      toastError,
      dispatch,
      applicationId,
      documentContainerId,
      documentCategoryId,
    ]
  );

  return handleDropFiles;
};
