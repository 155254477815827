export enum ChildApplicationType {
  Amendment = "Amendment",
  Supporting = "Supporting",
}

export const ChildApplicationTypeUtil = {
  parse: (str: string): ChildApplicationType => {
    for (const value of Object.values(ChildApplicationType)) {
      if (str.toLowerCase() === value.toLowerCase().toString()) {
        return value;
      }
    }
    return ChildApplicationType.Amendment;
  },
};
