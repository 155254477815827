import axios, { AxiosInstance } from "axios";
import { BUILD_API_ENDPOINT } from "constants/configs";
import { Cradle } from "services/serviceContainer.types";
import { IApiClient } from "services/api-client/ApiClient.types";
import merge from "lodash/merge";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

// All api requests to backend will be sent with this client
export class ApiClient implements IApiClient {
  private readonly cognitoService: Cradle["cognitoService"];

  private publicInstance: AxiosInstance | null = null;
  private protectedInstance: AxiosInstance | null = null;

  constructor(args: Pick<Cradle, "cognitoService">) {
    this.cognitoService = args.cognitoService;
  }

  get publicApi(): AxiosInstance {
    if (this.publicInstance) {
      return this.publicInstance;
    }

    const instance = axios.create({
      baseURL: `${BUILD_API_ENDPOINT}`,
    });

    instance.interceptors.response.use(
      (response) => response,
      async (e) => {
        // Always throw ServiceError
        const error = ServiceError.createFromResponseError(e);
        // TODO: We should be able to get rid of try...catch code around apiClient.get()/post()/put()/patch()/etc
        return Promise.reject(error);
      }
    );

    this.publicInstance = instance;
    return instance;
  }

  get protectedApi(): AxiosInstance {
    if (this.protectedInstance) {
      return this.protectedInstance;
    }

    const instance = axios.create({
      baseURL: `${BUILD_API_ENDPOINT}`,
    });

    instance.interceptors.request.use(async (config) => {
      const idToken = await this.cognitoService.getIdToken();

      const newConfig = merge({}, config, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return newConfig;
    });

    instance.interceptors.response.use(
      (response) => response,
      async (e) => {
        // Always throw ServiceError
        const error = ServiceError.createFromResponseError(e);
        // TODO: We should be able to get rid of try...catch code around apiClient.get()/post()/put()/patch()/etc

        // Log user out if user is not active but still with a valid token
        if (error.code === ServiceErrorCode.UserNotActive) {
          // Clear the stored token and refresh the page
          try {
            await this.cognitoService.logout();
          } catch (e) {
          } finally {
            window.location.href = "/";
          }
        }

        return Promise.reject(error);
      }
    );

    this.protectedInstance = instance;
    return instance;
  }
}
