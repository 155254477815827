import React from "react";
import { useTheme } from "@material-ui/core";
import Box from "components/Box/Box";
import planIcon from "assets/images/plan-icon.svg";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import { Trans, useTranslation } from "react-i18next";
import Spacer from "components/Spacer/Spacer";
import FlexBox from "components/FlexBox/FlexBox";

type Props = {};

// TODO:
//  Replace all Box with styled-components
//  There will be a carousel when designs are ready
const ApplicantGuide: React.FC<Props> = () => {
  const theme = useTheme();
  const { xl } = useScreenWidthMatch();
  const { t } = useTranslation();

  return (
    <FlexBox flexDirection="column" justifyContent="center" alignItems="center" position="relative" flexBasis={"100%"}>
      <Box
        component="h2"
        fontSize={theme.spacing(9)}
        fontWeight="400"
        data-testid="guide.title"
        color={theme.palette.objective.dark.neutral}
      >
        <Trans
          i18nKey="<strong>Got everything</strong> you need?"
          defaults="<strong>Got everything</strong> you need?"
          components={{
            strong: <Box color={theme.palette.common.black} component="strong" />,
          }}
        />
      </Box>

      <Spacer y={6} />

      <Box
        maxWidth={xl ? "640px" : "80%"}
        textAlign="center"
        component="p"
        fontSize={theme.spacing(6)}
        lineHeight={1.2}
      >
        {t(`Sign in to see all the files, documents and information you will need for each step.`)}
      </Box>

      <Spacer y={15} />

      <img alt={t(`Plans`)} src={planIcon} />

      <Spacer y={6} />
    </FlexBox>
  );
};

export default ApplicantGuide;
