// language=ejs
const input = `<!-- Input -->
{% const isReadOnly = ctx.options.readOnly %}

<!-- Begin: Readonly Input -->
{% if (isReadOnly) { %}

<div class="wrapper">
    {% if (ctx.prefix && typeof ctx.prefix === "string") { %}
    <span>{{ ctx.t(ctx.prefix) }}</span>
    {% } %}

    <{{ctx.input.type}}
    ref="{{ctx.input.ref ? ctx.input.ref : 'input'}}"
    {% for (var attr in ctx.input.attr) { %}
    {{attr}}="{{ctx.input.attr[attr]}}"
    {% } %}
    id="{{ctx.instance.id}}-{{ctx.component.key}}"
    >{{ctx.input.content}}
</{{ctx.input.type}}>

<!-- Extra slot for dynamic content -->
<span></span>

{% if (ctx.suffix && typeof ctx.suffix === "string") { %}
{{ ctx.t(ctx.suffix) }}
{% } %}
</div>

{% } %}
<!-- End: Readonly Input -->

<!-- Begin: Form Input -->
{% if (!isReadOnly) { %}

{% if (ctx.prefix || ctx.suffix) { %}
<div class="input-group">
    {% } %}
    {% if (ctx.prefix) { %}
    <div class="input-group-prepend" ref="prefix">
  <span class="input-group-text">
    {% if(ctx.prefix instanceof HTMLElement){ %}
      {{ ctx.t(ctx.prefix.outerHTML) }}
    {% } else{ %}
      {{ ctx.t(ctx.prefix) }}
    {% } %}
  </span>
    </div>
    {% } %}
    {% if (!ctx.component.editor && !ctx.component.wysiwyg) { %}
    <{{ctx.input.type}}
    ref="{{ctx.input.ref ? ctx.input.ref : 'input'}}"
    {% for (var attr in ctx.input.attr) { %}
    {{attr}}="{{ctx.input.attr[attr]}}"
    {% } %}
    id="{{ctx.instance.id}}-{{ctx.component.key}}"
    >{{ctx.input.content}}
</{{ctx.input.type}}>
{% } %}
{% if (ctx.component.editor || ctx.component.wysiwyg) { %}
<div ref="input"></div>
{% } %}
{% if (ctx.component.showCharCount) { %}
<span class="text-muted pull-right" ref="charcount"></span>
{% } %}
{% if (ctx.component.showWordCount) { %}
<span class="text-muted pull-right" ref="wordcount"></span>
{% } %}
{% if (ctx.suffix) { %}
<div class="input-group-append" ref="suffix">
  <span class="input-group-text">
    {% if(ctx.suffix instanceof HTMLElement){ %}
      {{ ctx.t(ctx.suffix.outerHTML) }}
    {% } else{ %}
      {{ ctx.t(ctx.suffix) }}
    {% } %}
  </span>
</div>
{% } %}
{% if (ctx.prefix || ctx.suffix) { %}
</div>
{% } %}


{% } %}
<!-- End: Form Input -->
`;
export default input;
