import React from "react";
import { useStoreSelector } from "store/hooks";
import {
  accessLevelMetaData,
  getGroupName,
  getParticipantInitial,
  getParticipantName,
  InvitationStatus,
  IParticipantEntity,
  ParticipantAccessLevel,
} from "models/Participant.model";
import FlexBox from "components/FlexBox/FlexBox";
import styled, { css } from "styled-components/macro";
import { Fade, IconButton, Tooltip, useTheme } from "@material-ui/core";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { UserAvatarType } from "components/UserAvatar/UserAvatar.types";
import { selectVisibleSavedParticipantTypes } from "store/domain-data/participant-type/participantType";
import { getAddressWithoutCountry } from "utils/addressUtils";
import FontIcon from "components/FontIcon/FontIcon";
import { useTranslation } from "react-i18next";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import Guard from "components/Guard/Guard";
import primaryContactIcon from "assets/images/primary-contact-icon.svg";
import { selectParticipantEnableGroups } from "store/domain-data/participant/participant";
import UserGroupAvatar from "components/UserAvatar/UserGroupAvatar";
import { initialsFromName } from "utils/initialsFromName";
import ParticipantCardParticipantTypeTag from "components/ParticipantCard/ParticipantCardParticipantTypeTag";
import isEmpty from "lodash/isEmpty";
import AlertIcon from "components/AlertIcon/AlertIcon";

const GroupParticipants: React.FC<Props> = ({ participant }) => {
  const groupNames = !!participant.groupList ? participant.groupList.split(", ") : [];
  if (participant.groupName) {
    groupNames.unshift(getParticipantName(participant));
  }

  return (
    <FlexBox
      marginTop={1}
      marginLeft={17}
      alignSelf={"flex-start"}
      direction={"column"}
      data-testid={`ParticipantGroupList-${participant.id}`}
    >
      {groupNames.map((groupName, index) => (
        <StyledCardDetailChildSection key={groupName + index}>
          <FlexBox alignSelf={"flex-start"}>
            <UserAvatar initials={initialsFromName(groupName)} rectSize={55} fontSize={24} />
          </FlexBox>
          <FlexBox flexShrink={1} spacing={2} direction={"column"}>
            <StyledParticipantHeader>{groupName}</StyledParticipantHeader>
          </FlexBox>
        </StyledCardDetailChildSection>
      ))}
    </FlexBox>
  );
};

type Props = {
  participant: IParticipantEntity;
  readOnly?: boolean;
};

const ParticipantCardDetail: React.FC<Props> = ({ participant, readOnly }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [isGroupMembersOpen, setIsGroupMembersOpen] = React.useState<boolean>(true);

  const showHideGroupMemberIcon = React.useMemo(() => (isGroupMembersOpen ? "chevron-up" : "chevron-down"), [
    isGroupMembersOpen,
  ]);

  const isParticipantCertified = React.useMemo(() => {
    return !isEmpty(participant.qualifications);
  }, [participant]);

  const isParticipantEnableGroups = useStoreSelector((state) => selectParticipantEnableGroups(state, participant.id));
  const isAnyGroupNames = React.useMemo(() => participant.groupList || participant.groupName, [
    participant.groupList,
    participant.groupName,
  ]);

  const toggleGroupMembersHandler = React.useCallback(() => {
    setIsGroupMembersOpen(!isGroupMembersOpen);
  }, [isGroupMembersOpen]);

  const visibleParticipantTypes = useStoreSelector((state) =>
    selectVisibleSavedParticipantTypes(state, participant.participantTypes)
  );

  const initial = React.useMemo(() => getParticipantInitial(participant), [participant]);
  const isGroupNameTitle = React.useMemo(() => !!participant.groupName, [participant.groupName]);

  const headerText = React.useMemo(() => {
    const displayName = isParticipantEnableGroups ? getGroupName(participant) : getParticipantName(participant);
    return [displayName, participant.organisation].filter((str) => Boolean(str)).join(" - ");
  }, [participant, isParticipantEnableGroups]);

  const secondaryInformation = React.useMemo(() => {
    const address = getAddressWithoutCountry(participant.address.fullAddress);
    return [address, participant.phone, participant.email].filter((str) => Boolean(str)).join(" • ");
  }, [participant]);

  const participantAccessMetaData = React.useMemo(
    () => accessLevelMetaData[participant.accessLevel] ?? accessLevelMetaData[""],
    [participant]
  );

  const isInvalidUserInfoAlertVisible = React.useMemo(() => {
    const isHavingRequiredRole = participant.participantTypes.some((participantType) => participantType.required);
    const isMissingRequiredInfo =
      isEmpty(participant.email) ||
      isEmpty(participant.address) ||
      isEmpty(participant.phone) ||
      isEmpty(participant.firstName) ||
      isEmpty(participant.lastName);
    return isHavingRequiredRole && isMissingRequiredInfo;
  }, [participant]);

  const isParticipantPending = React.useMemo(() => {
    if (!!participant.loginUserId) {
      return false;
    }
    if (participant.accessLevel === ParticipantAccessLevel.None) {
      return false;
    }
    const invitation = participant.invitation;
    return invitation === InvitationStatus.Queued || invitation === InvitationStatus.Unregistered;
  }, [participant]);

  const isInviteNonSent = React.useMemo(() => participant.invitation === InvitationStatus.Error, [participant]);

  const avatarType = React.useMemo(
    () => (isParticipantPending || isInviteNonSent ? UserAvatarType.Outlined : UserAvatarType.Solid),
    [isParticipantPending, isInviteNonSent]
  );

  const individualUserAvtar = React.useMemo(() => {
    if (isEmpty(initial)) {
      return null;
    }

    return <UserAvatar initials={initial} rectSize={55} fontSize={24} type={avatarType} />;
  }, [avatarType, initial]);

  return (
    <StyledCardDetail>
      <StyledCardDetailSection>
        <FlexBox alignSelf={"flex-start"}>
          <Guard condition={isParticipantEnableGroups && isGroupNameTitle} fallback={individualUserAvtar}>
            <UserGroupAvatar rectSize={55} fontSize={30} type={avatarType} />
          </Guard>
        </FlexBox>
        <FlexBox flexShrink={1} spacing={2} direction={"column"}>
          <StyledParticipantHeader>
            <Guard condition={participant.primaryContact}>
              <Tooltip aria-label={"Primary contact"} title={"Primary contact"} arrow={true} TransitionComponent={Fade}>
                <StyledLineButton>
                  <img
                    width={"16px"}
                    alt={t(`Primary contact`)}
                    data-testid={`PrimaryContact`}
                    src={primaryContactIcon}
                  />
                </StyledLineButton>
              </Tooltip>
            </Guard>
            <span data-testid={`ParticipantHeaderLine-${participant.id}`}>{headerText}</span>
          </StyledParticipantHeader>
          <StyledParticipantSubheader data-testid={`ParticipantContact-${participant.id}`}>
            {secondaryInformation}
          </StyledParticipantSubheader>
          <Guard condition={isInvalidUserInfoAlertVisible}>
            <StyledAlertInfo role={"alert"} aria-label={"User info invalid"}>
              <AlertIcon />
              {t(`Participant's user details are missing for mandatory role`)}
            </StyledAlertInfo>
          </Guard>
          <StyledTagsContainer>
            {visibleParticipantTypes.map((participantType) => (
              <ParticipantCardParticipantTypeTag
                key={participantType.name}
                participantId={participant.id}
                participantTypeId={participantType.id}
                readOnly={readOnly}
              />
            ))}
          </StyledTagsContainer>
        </FlexBox>
        <FlexBox paddingTop={1} flexGrow={1} alignSelf={"auto"}>
          <StyledIconsContainer>
            <Guard condition={!readOnly && isParticipantPending}>
              <StyledStatusTag data-testid={`PendingParticipant-${participant.id}`}>
                <span>{t(`Pending`)}</span>
              </StyledStatusTag>
            </Guard>
            <Guard condition={!readOnly && isInviteNonSent}>
              <HoverTooltip title={t("Invitation initially failed, please re-send")}>
                <StyledStatusFailedTag data-testid={`InviteNotSentParticipant-${participant.id}`}>
                  <span>{t(`Please invite`)}</span>
                </StyledStatusFailedTag>
              </HoverTooltip>
            </Guard>
            <Guard condition={isParticipantEnableGroups && isAnyGroupNames}>
              <StyledButton
                data-testid={`ParticipantGroupMember-${participant.id}`}
                aria-label={t(`Participant group members`)}
                onClick={toggleGroupMembersHandler}
              >
                <FontIcon name={showHideGroupMemberIcon} color={theme.palette.objective.blue.main} fontSize={18} />
              </StyledButton>
            </Guard>

            <Guard condition={isParticipantCertified}>
              <HoverTooltip title={t("Certified")}>
                <StyledButton data-testid={`QualifiedParticipant-${participant.id}`} aria-label={t(`Certified`)}>
                  <FontIcon name={"certificate"} fontSize={18} padding={2} color={theme.palette.objective.dark.night} />
                </StyledButton>
              </HoverTooltip>
            </Guard>
            <HoverTooltip title={t(participantAccessMetaData.description)}>
              <StyledButton aria-label={t(participantAccessMetaData.description)}>
                <FontIcon
                  name={participantAccessMetaData.icon}
                  fontSize={18}
                  padding={2}
                  color={theme.palette.objective.dark.night}
                />
              </StyledButton>
            </HoverTooltip>
          </StyledIconsContainer>
        </FlexBox>
      </StyledCardDetailSection>
      <Guard condition={isParticipantEnableGroups && isAnyGroupNames && isGroupMembersOpen}>
        <GroupParticipants participant={participant} />
      </Guard>
    </StyledCardDetail>
  );
};

const StyledCardDetail = styled.div(
  ({ theme }) => css`
    width: 100%;
  `
);

const StyledCardDetailSection = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex: 1;
    ${theme.mixins.flexGap("16px")}
    flex-direction: row;
    align-items: flex-start;
  `
);
const StyledCardDetailChildSection = styled(StyledCardDetailSection)(
  ({ theme }) => css`
    margin-top: 20px;
    align-items: center;
  `
);

const StyledParticipantHeader = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette.text.primary};
  `
);

const StyledParticipantSubheader = styled.span(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.palette.text.secondary};
  `
);

const StyledTagsContainer = styled.div(
  ({ theme }) => css`
    margin-top: 5px;
    display: flex;
    ${theme.mixins.flexGap("8px")}
    flex-wrap: wrap;
  `
);

const StyledStatusTag = styled.div(
  ({ theme }) => css`
    padding: 0px 0px;
    margin: 5px 5px 6px 5px;
    height: 32px;
    width: 79px;
    background: ${theme.palette.objective.light.day};
    border-radius: 23px;
    line-height: 30px;
    font-size: 14px;
    font-weight: normal;
    font-family: Noto Sans;
    text-align: center;
    border: 1px solid ${theme.palette.objective.light.allspice};
    color: ${theme.palette.objective.dark.neutral};
    cursor: default;
  `
);
const StyledStatusFailedTag = styled(StyledStatusTag)(
  ({ theme }) => css`
    background: ${theme.palette.objective.red.light};
    border: 1px solid ${theme.palette.objective.red.main};
    width: 104px;
    color: ${theme.palette.objective.red.main};
    cursor: default;
  `
);

const StyledIconsContainer = styled.span(
  ({ theme }) => css`
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    ${theme.mixins.flexGap("8px")}
    margin-left: auto;
  `
);

const StyledButton = styled(IconButton)(
  ({ theme }) => css`
    width: 40px;
    height: 40px;
    padding: 0px;
  `
);

const StyledLineButton = styled(IconButton)(
  ({ theme }) => css`
    margin-right: 8px;
  `
);

const StyledAlertInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${theme.mixins.flexGap("8px")}
    align-items: center;
    font-size: 16px;
    color: ${theme.palette.objective.red.main};
  `
);

export default ParticipantCardDetail;
