import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import startCase from "lodash/startCase";

type Props = {
  onClickCancel: () => void;
  onClickKeep: () => void;
  expiredFileNames: string[];
  fileExpiryInMonths: number;
  documentContainerName: string;
};

const ApplicationDocumentExpiredDialog: React.FC<Props> = ({
  onClickCancel,
  onClickKeep,
  expiredFileNames,
  documentContainerName,
  fileExpiryInMonths,
}) => {
  const { t } = useTranslation();
  const fileCount = expiredFileNames.length;
  const fileLabel = t(`{{count}} files`, { count: fileCount });
  const thisTheseLabel = t(`{{count}} these`, { count: fileCount });
  const isAreLabel = t(`{{count}} are`, { count: fileCount });

  const title = t(`{{fileLabel}} more than {{fileExpiryInMonths}} months old`, {
    fileLabel: startCase(fileLabel),
    fileExpiryInMonths,
  });

  const subTitle = t(`{{documentContainerName}} files must be less than {{fileExpiryInMonths}} months old.`, {
    documentContainerName,
    fileExpiryInMonths,
  });

  const desc1 = t(
    `If you submit a file that is older than {{fileExpiryInMonths}} months, your BCA is likely to issue an RFI for you to submit a newer file.`,
    {
      fileExpiryInMonths,
    }
  );
  const desc2 = t(`RFIs slow down the acceptance of your application.`);

  const fileTitle = t(`{{thisTheseLabel}} {{fileLabel}} {{isAreLabel}} older than {{fileExpiryInMonths}}  months:`, {
    thisTheseLabel: startCase(thisTheseLabel),
    fileLabel,
    isAreLabel,
    fileExpiryInMonths,
  });

  const question = t(`Do you want to keep {{thisTheseLabel}} {{fileLabel}}? `, {
    fileLabel,
    thisTheseLabel,
  });

  return (
    <Dialog
      open={true}
      data-testid={"ApplicationDocumentExpiredDialog"}
      title={title}
      onClose={onClickCancel}
      maxWidth={false}
      scroll="body"
    >
      <DialogContent>
        <StyledDialogContent>
          <FlexBox direction={"column"}>
            <div>{subTitle}</div>
            <br />
            <div>
              {desc1} {desc2}
            </div>
            <br />
            <div>{fileTitle}</div>
            <br />
            {expiredFileNames.map((fileName) => (
              <div key={fileName}>
                <strong>{fileName}</strong>
              </div>
            ))}
            <br />
            <div>{question}</div>
          </FlexBox>
        </StyledDialogContent>
      </DialogContent>

      <DialogActions>
        <DialogActionCancel data-testid="CancelButton" onClick={onClickCancel}>
          {t(`Cancel`)}
        </DialogActionCancel>
        <DialogActionGroup>
          <DialogActionPrimary data-testid="KeepButton" onClick={onClickKeep}>
            {t(`Keep`)}
          </DialogActionPrimary>
        </DialogActionGroup>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogContent = styled.div(
  ({ theme }) => css`
    line-height: 1.2em;
    padding: 4px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    ${theme.mixins.flexGap("8px")}
  `
);

export default ApplicationDocumentExpiredDialog;
