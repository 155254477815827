import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import {
  ApplicationDocumentContainerDataUtil,
  IApplicationDocumentContainerRelation,
} from "models/ApplicationDocumentContainerRelation";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";
import { selectAllDocumentContainers } from "store/domain-data/document-containers/documentContainers";
import { DocumentContainerType } from "models/DocumentContainer.model";

// Entity Adapter

const applicationDocumentContainersRelationAdapter = createEntityAdapter<IApplicationDocumentContainerRelation>({
  selectId: (applicationDocumentContainer) =>
    ApplicationDocumentContainerDataUtil.generateEntityKey(applicationDocumentContainer),
  sortComparer: (a, b) => {
    if (a.applicationId !== b.applicationId) {
      return a.applicationId - b.applicationId;
    }
    return a.documentContainerId - b.documentContainerId;
  },
});

// Action and Thunks

export const loadApplicationDocumentContainerRelation = createAction<{
  applicationId: number;
  documentContainerIds: number[];
}>("domainData/applicationDocumentContainers/loadApplicationDocumentContainerRelation");

export const removeApplicationDocumentContainerRelationsByApplicationId = createAction<number>(
  "domainData/applicationDocumentContainers/removeApplicationDocumentContainerRelationsByApplicationId"
);

// Reducer

export const applicationDocumentContainerReducer = createReducer(
  applicationDocumentContainersRelationAdapter.getInitialState(),
  (builder) =>
    builder
      .addCase(loadApplicationDocumentContainerRelation, (draft, action) => {
        // Clear existing relations for an application
        const relationKeys = Object.keys(draft.entities);
        for (const relationKey of relationKeys) {
          const relation = draft.entities[relationKey];
          if (relation?.applicationId === action.payload.applicationId) {
            applicationDocumentContainersRelationAdapter.removeOne(draft, relationKey);
          }
        }

        const relations = action.payload.documentContainerIds.map((documentContainerId) => {
          const relation: IApplicationDocumentContainerRelation = {
            applicationId: action.payload.applicationId,
            documentContainerId,
          };
          return relation;
        });

        applicationDocumentContainersRelationAdapter.upsertMany(draft, relations);
      })
      .addCase(removeApplicationDocumentContainerRelationsByApplicationId, (draft, action) => {
        const applicationId = action.payload;
        const keys = Object.keys(draft.entities);
        for (const key of keys) {
          const relation = draft.entities[key];
          if (relation?.applicationId === applicationId) {
            applicationDocumentContainersRelationAdapter.removeOne(draft, key);
          }
        }
      })
);

// Selectors
export const {
  selectAll: selectAllApplicationDocumentContainerRelations,
} = applicationDocumentContainersRelationAdapter.getSelectors(
  (state: RootState) => state.domainData.applicationDocumentContainerRelation
);

export const selectApplicationDocumentContainerRelationsByCriteria = createDeepEqualSelector(
  [
    selectAllApplicationDocumentContainerRelations,
    (state: RootState) => selectAllDocumentContainers(state),
    (state: RootState, { applicationId }: { applicationId: number }) => applicationId,
    (state: RootState, { documentContainerType }: { documentContainerType: DocumentContainerType }) =>
      documentContainerType,
  ],
  (allRelations, documentContainers, applicationId, documentContainerType) => {
    // Get container ids for given container type (they will be in displayOrder)
    const documentContainerIds = documentContainers
      .filter((container) => container.containerType === documentContainerType)
      .sort((containerA, containerB) => containerA.displayOrder - containerB.displayOrder)
      .map((container) => container.id);

    // Get relations only for given application id
    const relations = allRelations
      .filter((relation) => {
        return relation.applicationId === applicationId && documentContainerIds.includes(relation.documentContainerId);
      })
      .sort((relationA, relationB) => {
        const indexA = documentContainerIds.findIndex((id) => relationA.documentContainerId === id);
        const indexB = documentContainerIds.findIndex((id) => relationB.documentContainerId === id);
        return indexA - indexB;
      });

    return relations;
  }
);
export const selectApplicationDocumentContainerRelationsByTypeAndName = createDeepEqualSelector(
  [
    selectAllApplicationDocumentContainerRelations,
    (state: RootState) => selectAllDocumentContainers(state),
    (state: RootState, { applicationId }: { applicationId: number }) => applicationId,
    (state: RootState, { documentContainerType }: { documentContainerType: DocumentContainerType }) =>
      documentContainerType,
    (state: RootState, { documentContainerName }: { documentContainerName?: string }) => documentContainerName,
  ],
  (allRelations, documentContainers, applicationId, documentContainerType, documentContainerName) => {
    // Get container ids for given container type (they will be in displayOrder)
    const documentContainerIds = documentContainers
      .filter(
        (container) => container.containerType === documentContainerType && container.name === documentContainerName
      )
      .sort((containerA, containerB) => containerA.displayOrder - containerB.displayOrder)
      .map((container) => container.id);

    // Get relations only for given application id
    return allRelations.find((relation) => {
      return relation.applicationId === applicationId && documentContainerIds.includes(relation.documentContainerId);
    });
  }
);
