export type IUserAuthorityRelation = {
  userId: number;
  authorityId: number;
  defaultIndependent: boolean;
};

export const UserAuthorityRelationUtil = {
  generateEntityKey: (relation: Pick<IUserAuthorityRelation, "userId" | "authorityId">) => {
    return `userId:${relation.userId}|authorityId:${relation.authorityId}}`;
  },
};
