import React from "react";
import ApplicationFormReviewDialog from "components/ApplicationFormReviewDialog/ApplicationFormReviewDialog";

export const useApplicationFormReviewDialog = (applicationId: number) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const applicationFormReviewDialog = React.useMemo(() => {
    if (!applicationId || !isDialogOpen) {
      return null;
    }

    return <ApplicationFormReviewDialog applicationId={applicationId} onClose={() => setIsDialogOpen(false)} />;
  }, [applicationId, isDialogOpen, setIsDialogOpen]);

  const openApplicationFormReviewDialog = React.useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);

  return {
    applicationFormReviewDialog,
    openApplicationFormReviewDialog,
  };
};
