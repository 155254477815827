import React from "react";
import { useFormikContext } from "formik";
import { capitalize, FormControl, FormHelperText, InputAdornment, InputLabel } from "@material-ui/core";
import OutlinedInput from "components/OutlinedInput/OutlinedInput";
import Guard from "components/Guard/Guard";
import { fetchCountryInfo, selectAllCountryEntities } from "store/domain-data/country/country";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { ICountryEntity } from "models/Country.model";
import FontIcon from "components/FontIcon/FontIcon";
import styled, { css } from "styled-components/macro";
import { useToast } from "hooks/useToast";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import FlexBox from "components/FlexBox/FlexBox";
import Button from "components/odl-v2/Button/Button";

const nanoid = require("nanoid");

type Props = {
  label: string;
  contactNumberFieldName: string;
  countryCodeFieldName: string;
  disabled?: boolean;
  required?: boolean;
};

const FormikContactNumberInput: React.FC<Props> = ({
  label,
  contactNumberFieldName,
  countryCodeFieldName,
  disabled = false,
  required = false,
}) => {
  const dispatch = useStoreDispatch();

  const { touched, errors, handleChange, handleBlur, values, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const { toastError } = useToast();

  const { Menu, openMenu, closeMenu } = useMenu();

  const hasError = touched[contactNumberFieldName] && Boolean(errors[contactNumberFieldName]);
  const countries = useStoreSelector((state) => selectAllCountryEntities(state));

  const handleClickCountryListItem = React.useCallback(
    (country: ICountryEntity) => {
      setFieldValue(countryCodeFieldName, country.phoneCountryCode);
      setTimeout(() => {
        setFieldTouched(contactNumberFieldName);
      }, 0);
    },
    [countryCodeFieldName, setFieldValue, setFieldTouched, contactNumberFieldName]
  );

  const inputId = React.useMemo(() => {
    return contactNumberFieldName + "-" + nanoid(5);
  }, [contactNumberFieldName]);

  React.useEffect(() => {
    // Already got country list, no need to re-fetch
    if (countries.length > 0) {
      return;
    }

    dispatch(fetchCountryInfo()).catch(toastError);
  }, [dispatch, countries, toastError]);

  if (!countries) {
    return null;
  }
  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <InputLabel htmlFor={inputId} shrink={false}>
        {label}
      </InputLabel>

      <Guard
        condition={!disabled}
        fallback={
          <OutlinedInput
            id={inputId}
            name={contactNumberFieldName}
            value={values[contactNumberFieldName]}
            disabled={disabled}
            data-testid={"FormikContactNumberInput-" + contactNumberFieldName}
          />
        }
      >
        <OutlinedInput
          id={inputId}
          data-testid={"FormikContactNumberInput-" + contactNumberFieldName}
          name={contactNumberFieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError}
          value={values[contactNumberFieldName]}
          startAdornment={
            <InputAdornment position="start">
              <StyledPopupTrigger>
                <StyledButton
                  onClick={openMenu}
                  data-testid={"UpdateContactCountryCodeButton"}
                  disabled={countries.length === 0}
                >
                  <span>{values[countryCodeFieldName]}</span>
                  <FontIcon name={"menu-down"} />
                </StyledButton>
              </StyledPopupTrigger>

              <Menu data-testid={"UpdateContactCountryCodePopup"}>
                {countries.map((country) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleClickCountryListItem(country);
                        closeMenu();
                      }}
                      key={country.id}
                      data-testid={`ContactCountryCodeItem-${country.phoneCountryCode.replace("+", "")}`}
                      selected={values[countryCodeFieldName] === country.phoneCountryCode}
                    >
                      <FlexBox spacing={2}>
                        <span>{country.phoneCountryCode}</span>
                        <span>{country.name}</span>
                      </FlexBox>
                    </MenuItem>
                  );
                })}
              </Menu>
            </InputAdornment>
          }
        />
      </Guard>
      <Guard condition={hasError}>
        <FormHelperText error={true} data-testid={`InputError${capitalize(contactNumberFieldName)}`}>
          {errors[contactNumberFieldName]}
        </FormHelperText>
      </Guard>
    </FormControl>
  );
};

const StyledPopupTrigger = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    text-transform: none;
    min-width: 0;
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.objective.blue.main};
    padding: 8px;
    margin: 0;

    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("8px")}
  `
);

export default FormikContactNumberInput;
