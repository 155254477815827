import React from "react";
import { useFormikContext } from "formik";

type FieldChangeDetectionProps = {
  onFormValidated?: (invalid: boolean) => void;
};

// Auto trigger form submission on 'values' change. Context only available within a formik form
const FormikChangeDetection: React.FC<FieldChangeDetectionProps> = ({ onFormValidated }) => {
  const { isValid, values, submitForm } = useFormikContext<any>();
  React.useEffect(() => {
    // Report form is invalid
    if (onFormValidated) {
      onFormValidated(isValid);
    }
    // Trigger submit
    submitForm();
  }, [isValid, values, onFormValidated, submitForm]);
  return null;
};

export default FormikChangeDetection;
