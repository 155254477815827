import { serviceContainer } from "services/serviceContainer";
import loglevel from "loglevel";

const interceptRequest = async (requestArgs: any) => {
  try {
    // Detect call to Build API, intercept the network request
    if (requestArgs.url && requestArgs.url.startsWith("{OBAPI}/public")) {
      const url = requestArgs.url.replace("{OBAPI}/public", "/public");
      const response = await serviceContainer.cradle.apiClient.publicApi.get(url);
      return response.data;
    }

    if (requestArgs.url && requestArgs.url.startsWith("{OBAPI}/user")) {
      const url = requestArgs.url.replace("{OBAPI}/user", "/user");
      const response = await serviceContainer.cradle.apiClient.protectedApi.get(url);
      return response.data;
    }

    if (requestArgs.url && requestArgs.url.startsWith("{SERVICE_API}")) {
      const url = requestArgs.url.replace("{SERVICE_API}", "/v1/service");
      const response = await serviceContainer.cradle.apiClient.protectedApi.get(url);
      return response.data;
    }
  } catch (e) {
    loglevel.error(e);
  }

  // Let FormIO to take over
  return null;
};

export const OBAPIRequestPlugin = {
  priority: Number.MAX_SAFE_INTEGER,
  // This is called in https://github.com/formio/formio.js/blob/c31da9f6f3fe286a13ade65ff00ae5243b666524/src/Formio.js#L773
  staticRequest: interceptRequest,
  // This is called in https://github.com/formio/formio.js/blob/c31da9f6f3fe286a13ade65ff00ae5243b666524/src/Formio.js#L802
  request: interceptRequest,
};
