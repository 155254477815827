import React from "react";
import MissingJurisdictionListener from "components/JurisdictionDataProvider/MissingJurisdictionListener";
import { fetchJurisdictionByName } from "store/domain-data/jurisdiction/jurisdiction";
import { useStoreDispatch } from "store/hooks";
import { updateCurrentJurisdictionIdByName } from "store/app-state/application-navigation/applicationNavigation";
import { useToast } from "hooks/useToast";

type Props = {
  name: string;
};

const JurisdictionDataProvider: React.FC<Props> = ({ name, children }) => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  // Load jurisdiction by name
  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchJurisdictionByName(name));
      await dispatch(updateCurrentJurisdictionIdByName(name));
    };
    fetchData().catch(toastError);
  }, [dispatch, name, toastError]);

  return (
    <React.Fragment>
      <MissingJurisdictionListener />
      {children}
    </React.Fragment>
  );
};

export default JurisdictionDataProvider;
