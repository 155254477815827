import React from "react";
import { OnWebFormChangeCallback, WebForm } from "@objective_build/formiojs";

type Props = {
  form: WebForm;
};

const FormIOReviewStylesHandler: React.FC<Props> = ({ form }) => {
  React.useEffect(() => {
    if (!form) {
      return;
    }

    // Callback logic when form data gets changed
    const onChange: OnWebFormChangeCallback = () => {
      adaptInputSize(form);
    };

    // Register "change" event listener
    form.on("change", onChange, false);

    // Apply fixes immediately
    adaptInputSize(form);

    return () => {
      // @ts-ignore
      form.off("change", onChange);
    };
  }, [form]);

  return null;
};

/**
 * Adaptive width for inputs
 * @param form
 */
const adaptInputSize = (form: WebForm) => {
  const formId = form.id;
  const elements = document.querySelectorAll<HTMLInputElement>(`#${formId} input`);
  elements.forEach((el) => {
    el.size = el.value.length + 1 || 1;
  });
};

export default FormIOReviewStylesHandler;
