import { IBreadcrumbEntity } from "models/Breadcrumb.model";
import { IBreadcrumbStepEntity } from "models/BreadcrumbStep.model";
import { generateApplicationFormPageURL } from "pages/application-form/functions/generateApplicationFormPageURL";

export const assembleApplicationFormNavigation = ({
  breadcrumbEntities,
  breadcrumbStepEntities,
  applicationId,
  enabledStepIds,
  accessibleStepIds,
  forEdit,
}: {
  breadcrumbEntities: IBreadcrumbEntity[];
  breadcrumbStepEntities: IBreadcrumbStepEntity[];
  applicationId: number;
  enabledStepIds: number[];
  accessibleStepIds: number[];
  forEdit: boolean;
}) => {
  const breadcrumbs = breadcrumbEntities
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((breadcrumbEntity) => {
      const stepEntities = breadcrumbStepEntities.filter((step) => step.breadcrumbId === breadcrumbEntity.id);
      return {
        id: breadcrumbEntity.id,
        name: breadcrumbEntity.name,
        displayName: breadcrumbEntity.displayName,
        link: generateApplicationFormPageURL({
          applicationId,
          breadcrumbId: breadcrumbEntity.id,
        }),
        steps: stepEntities
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .filter((step) => {
            return enabledStepIds.includes(step.id);
          })
          .filter((step) => {
            return forEdit ? accessibleStepIds.includes(step.id) : true;
          })
          .map((stepEntity) => {
            return {
              id: stepEntity.id,
              name: stepEntity.name,
              displayName: stepEntity.displayName,
              type: stepEntity.type,
              minimumAccessLevel: stepEntity.minimumAccessLevel,
              link: generateApplicationFormPageURL({
                applicationId,
                breadcrumbId: breadcrumbEntity.id,
                breadcrumbStepId: stepEntity.id,
              }),
            };
          }),
      };
    })
    .filter((breadcrumb) => {
      return breadcrumb.steps.length > 0;
    })
    .map((breadcrumb) => {
      // Use the link of first step as the link of breadcrumb
      return {
        ...breadcrumb,
        link: breadcrumb.steps[0].link,
      };
    });
  return breadcrumbs;
};
