import React from "react";
import prettyBytes from "pretty-bytes";
import styled, { css } from "styled-components/macro";
import { formatDateStr } from "utils/formatDateStr";
import FlexBox from "components/FlexBox/FlexBox";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import FontIcon from "components/FontIcon/FontIcon";
import { useTranslation } from "react-i18next";
import { getFileNameComponents } from "utils/getFileNameComponents";
import { DATE_FORMATS } from "constants/configs";
import { IEncodedImageFile } from "models/EncodedImageFile.model";
import Guard from "components/Guard/Guard";
import { IconButton } from "@material-ui/core";

type Props = {
  image: IEncodedImageFile;
  onClickRemove?: () => void | Promise<void>;
  readonly?: boolean;
};

const ImagePreviewCard: React.FC<Props> = ({ image, onClickRemove, readonly = false }) => {
  // Common
  const { t } = useTranslation();

  const { fileExt, fileNameWithoutExt } = getFileNameComponents(image.name);
  const displayModifiedDate = React.useMemo(
    () => formatDateStr(new Date(image.modifiedDate).toISOString(), DATE_FORMATS.FULL_DATE),
    [image.modifiedDate]
  );

  const imageDataUri = React.useMemo(() => `data:${image.type};base64,${image.base64Image}`, [image]);
  const fileSize = React.useMemo(() => prettyBytes(image.size || 0), [image]);

  return (
    <StyledCardContainer data-testid={"ImagePreviewCard"}>
      <StyledImage src={imageDataUri} />

      <FlexBox justifyContent={"space-between"}>
        <FlexBox direction={"column"} spacing={1} flexGrow={1} flexShrink={1} flexBasis={0}>
          <StyledFilename>
            <strong>{fileNameWithoutExt}</strong>
            <span>.{fileExt}</span>
          </StyledFilename>

          <StyledFileMetaData>{displayModifiedDate}</StyledFileMetaData>
          <StyledHidden data-testid={`FileSize`}>{fileSize}</StyledHidden>
        </FlexBox>

        <Guard condition={!readonly}>
          <FlexBox flexGrow={0} flexShrink={0}>
            <HoverTooltip title={t("Remove")}>
              <StyledIconButton data-testid={"DeleteImageButton"} aria-label={t("Remove")} onClick={onClickRemove}>
                <StyledRemoveIcon />
              </StyledIconButton>
            </HoverTooltip>
          </FlexBox>
        </Guard>
      </FlexBox>
    </StyledCardContainer>
  );
};

const StyledCardContainer = styled(FlexBox).attrs({
  direction: "column",
  spacing: 6,
})(
  ({ theme }) => css`
    background: ${theme.palette.objective.light.white};
    border: 1px solid ${theme.palette.objective.light.allspice};
    border-radius: 4px;
    padding: 12px 24px 18px 24px;
    overflow: hidden;
  `
);

const StyledRemoveIcon = styled(FontIcon).attrs({ name: "close-circle" })(
  ({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledImage = styled.img(
  ({ theme }) => css`
    object-fit: contain;
    width: 200px;
    height: 120px;
    border-radius: 4px;
  `
);

const StyledFilename = styled.div(
  ({ theme }) => css`
    line-height: 14px;
    font-size: 12px;
    color: ${theme.palette.objective.dark.neutral};
    width: 156px;

    /* ellipsis
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    */

    overflow-wrap: break-word;

    strong {
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.text.primary};
    }
  `
);

const StyledFileMetaData = styled.div(
  ({ theme }) => css`
    line-height: 14px;
    font-size: 12px;
    color: ${theme.palette.objective.dark.neutral};
  `
);

const StyledHidden = styled.span(
  ({ theme }) => css`
    text-indent: -9999px;
    line-height: 0px;
    height: 0px;
    width: 0px;
  `
);

const StyledIconButton = styled(IconButton).attrs({
  disableFocusRipple: true,
  disableRipple: true,
  disableTouchRipple: true,
})(
  ({ theme }) => css`
    text-transform: none;
    width: 44px;
    height: 44px;
    color: ${theme.palette.text.secondary};
  `
);

export default ImagePreviewCard;
