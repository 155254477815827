import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import {
  IRegisteredApplicantInvitationEntity,
  RegisteredApplicantInvitationStatus,
} from "models/RegisteredApplicantInvitation.model";
import { createDeepEqualSelector } from "store/utils";
import { fetchUserInfoForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { fetchUserAuthoritiesRelationForAuthenticatedUser } from "store/domain-data/user-authority-relation/userAuthorityRelation";
import { fetchIndependentAuthoritiesForAuthenticatedUser } from "store/domain-data/authority/authority";

// Entity Adapter
const registeredApplicantInvitationAdapter = createEntityAdapter<IRegisteredApplicantInvitationEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    return b.id - a.id;
  },
});

// Action & Thunks

export const loadRegisteredApplicantPendingInvitations = createAction<IRegisteredApplicantInvitationEntity[]>(
  "domainData/registered-applicant-invitation/loadRegisteredApplicantPendingInvitations"
);

export const loadRegisteredApplicantPendingInvitation = createAction<IRegisteredApplicantInvitationEntity>(
  "domainData/registered-applicant-invitation/loadRegisteredApplicantPendingInvitation"
);

export const fetchRegisteredApplicantPendingInvitations = createAsyncThunk(
  "domainData/registered-applicant-invitation/fetchRegisteredApplicantPendingInvitations",
  async (_: void, thunkAPI) => {
    const registeredApplicantPendingInvitations = await serviceContainer.cradle.registeredApplicantInvitationService.fetchRegisteredApplicantPendingInvitations();
    thunkAPI.dispatch(loadRegisteredApplicantPendingInvitations(registeredApplicantPendingInvitations));
    return registeredApplicantPendingInvitations;
  }
);

export const fetchRegisteredApplicantPendingInvitation = createAsyncThunk(
  "domainData/registered-applicant-invitation/fetchRegisteredApplicantPendingInvitation",
  async (invitationId: number, thunkAPI) => {
    const registeredApplicantPendingInvitation = await serviceContainer.cradle.registeredApplicantInvitationService.fetchRegisteredApplicantPendingInvitation(
      invitationId
    );
    thunkAPI.dispatch(loadRegisteredApplicantPendingInvitation(registeredApplicantPendingInvitation));
    return registeredApplicantPendingInvitation;
  }
);

export const updateRegisteredApplicantInvitation = createAsyncThunk(
  "domainData/registered-applicant-invitation/fetchRegisteredApplicantPendingInvitation",
  async (args: { invitationId: number; status: RegisteredApplicantInvitationStatus }, thunkAPI) => {
    const registeredApplicantPendingInvitation = await serviceContainer.cradle.registeredApplicantInvitationService.updateRegisteredApplicantInvitation(
      args
    );
    thunkAPI.dispatch(fetchRegisteredApplicantPendingInvitations());
    thunkAPI.dispatch(fetchUserInfoForAuthenticatedUser());
    thunkAPI.dispatch(fetchUserAuthoritiesRelationForAuthenticatedUser());
    thunkAPI.dispatch(fetchIndependentAuthoritiesForAuthenticatedUser());
    return registeredApplicantPendingInvitation;
  }
);

// Reducer
export const defaultState = registeredApplicantInvitationAdapter.getInitialState();

export const registeredApplicantInvitationReducer = createReducer<typeof defaultState>(defaultState, (builder) =>
  builder
    .addCase(loadRegisteredApplicantPendingInvitations, registeredApplicantInvitationAdapter.setAll)
    .addCase(loadRegisteredApplicantPendingInvitation, registeredApplicantInvitationAdapter.upsertOne)
);

// Selectors
export const {
  selectById: selectRegisteredApplicantInvitationEntityById,
  selectIds: selectRegisteredApplicantInvitationEntityIds,
  selectEntities: selectRegisteredApplicantInvitationEntities,
  selectAll: selectAllRegisteredApplicantInvitationEntities,
  selectTotal: selectTotalRegisteredApplicantInvitationEntities,
} = registeredApplicantInvitationAdapter.getSelectors(
  (state: RootState) => state.domainData.registeredApplicantInvitations
);

export const selectAllPendingRegisteredApplicationInvitations = createDeepEqualSelector(
  [selectAllRegisteredApplicantInvitationEntities],
  (allRegisteredApplicantInvitations) => {
    return allRegisteredApplicantInvitations.filter(
      (invitation) => invitation.status === RegisteredApplicantInvitationStatus.Pending
    );
  }
);
