export enum NotificationLevel {
  Watching = "Watching",
  Normal = "Normal",
  Muted = "Muted",
}

export const NotificationSubscriptionLevelUtil = {
  parseSubscriptionLevel: (str: string): NotificationLevel => {
    switch (str) {
      case "Watching":
        return NotificationLevel.Watching;
      case "Normal":
        return NotificationLevel.Normal;
      case "Muted":
        return NotificationLevel.Muted;
      default:
        return NotificationLevel.Normal;
    }
  },
};
