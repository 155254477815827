import React from "react";
import ReactDOM from "react-dom";
import "fontsource-roboto/400.css"; // Regular
import "fontsource-roboto/500.css"; // Medium
import "fontsource-roboto/700.css"; // Bold
import "styles/objective-icons.css"; // Objective Icons
import "styles/objective-spreedly.css"; // Spreedly Custom css
import "styles/print.css"; // Print css
import App from "components/App/App";
import { serviceContainer } from "services/serviceContainer";
import { Provider } from "react-redux";
import { store } from "store/store";
import { USE_MOCK_BACKEND } from "constants/configs";
import { objectiveBuildDevTools } from "utils/objectiveBuildDevTools";
import { initializeSentry } from "utils/initializeSentry";
import { pageRefreshUtil } from "components/RouteController/functions/pageRefreshUtil";

let initMockServer: () => Promise<void>;

initializeSentry();

// Tree shaking only works with process.env.NODE_ENV because it requires system env to be always defined at build time
// https://github.com/facebook/create-react-app/issues/9674
if (process.env.NODE_ENV === "development") {
  initMockServer = async () => {
    const { worker } = require("mock-server/env/browser");
    await worker.start();
  };
}

const prepare = async () => {
  // Mock backend server in browser while development
  if (USE_MOCK_BACKEND && initMockServer) {
    await initMockServer();
  }

  // Initialize global i18n instance
  serviceContainer.cradle.i18nInitializer.initI18n();
};

prepare()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <React.Suspense fallback={<div />}>
          <Provider store={store}>
            <App />
          </Provider>
        </React.Suspense>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch((error) => {
    // Unexpected crash, re-throw
    if (error.name !== "ChunkLoadError") {
      throw error;
    }

    // Crash because of ChunkLoadError, we can recover by refresh
    if (pageRefreshUtil.getIsPageRefreshed()) {
      return;
    }

    pageRefreshUtil.markPageRefreshed();
    window.location.reload();
  });

if (process.env.NODE_ENV === "development") {
  Object.defineProperty(window, "objectiveBuildDevTools", {
    value: objectiveBuildDevTools,
    writable: false,
  });
}
