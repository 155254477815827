import React from "react";
import styled, { css } from "styled-components/macro";
import { formatNotificationDateStr } from "utils/formatNotificationDateStr";
import { DATE_FORMATS } from "constants/configs";

type Prop = {
  icon: React.ReactNode;
  subject: string;
  datetime: string;
  accessory: React.ReactNode;
  onCardClick?: () => void;
};

const NotificationCard: React.FC<Prop> = ({ icon, subject, datetime, accessory, onCardClick, ...otherProps }) => {
  const notificationCardIsClickable = React.useMemo(() => {
    if (!onCardClick) {
      return false;
    }
    return true;
  }, [onCardClick]);

  return (
    <StyledNotificationCard
      $isClickable={notificationCardIsClickable}
      onClick={onCardClick}
      data-testid={"NotificationCard"}
      {...otherProps}
    >
      <StyledNotificationIcon>{icon}</StyledNotificationIcon>
      <StyledNotificationInfo>
        <StyledSubject>{subject}</StyledSubject>
        <StyledDateTime>{formatNotificationDateStr(datetime, DATE_FORMATS.FULL_DATE)}</StyledDateTime>
      </StyledNotificationInfo>
      <StyledAccessory>{accessory}</StyledAccessory>
    </StyledNotificationCard>
  );
};

const StyledNotificationCard = styled.div<{ $isClickable?: boolean }>(
  ({ theme, $isClickable }) => css`
    display: flex;
    ${theme.mixins.flexGap("12px")}
    padding: 8px 12px;

    &:hover {
      background: ${theme.palette.objective.light.day};
    }

    ${$isClickable &&
    css`
      cursor: pointer;
    `}
  `
);

const StyledNotificationIcon = styled.div(
  ({ theme }) => css`
    max-width: 44px;
  `
);

const StyledNotificationInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledSubject = styled.p(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.objective.dark.night};
    line-height: 150%;
  `
);

const StyledDateTime = styled.p(
  ({ theme }) => css`
    font-size: 10px;
    color: ${theme.palette.objective.dark.neutral};
  `
);

const StyledAccessory = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-shrink: 0;
    width: 32px;
  `
);

export default NotificationCard;
