import React from "react";
import { fetchAnnouncements } from "store/domain-data/announcement/announcement";
import { useStoreDispatch } from "store/hooks";
import { serviceContainer } from "services/serviceContainer";

export const useAnnouncementsRoutine = () => {
  const dispatch = useStoreDispatch();

  React.useEffect(() => {
    const logger = serviceContainer.cradle.logger;
    dispatch(fetchAnnouncements()).catch(logger.error);
  }, [dispatch]);
};
