import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectAuthenticationState } from "store/app-state/authentication/authentication";
import MainLayout from "components/MainLayout/MainLayout";
import JurisdictionDataProvider from "components/JurisdictionDataProvider/JurisdictionDataProvider";
import { useStoreDispatch, useStoreSelector } from "store/hooks";

import SavingStepDataWhenMovingAway from "pages/application-form/SavingStepDataWhenMovingAway";
import {
  setCurrentApplicationId,
  setCurrentBreadcrumbId,
  setCurrentBreadcrumbStepId,
  setCurrentPaymentId,
} from "store/app-state/application-navigation/applicationNavigation";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { batch } from "react-redux";
import get from "lodash/get";
import trim from "lodash/trim";

// Models and types
import { ApplicationDetailsSectionsUtil } from "models/ApplicationDetailsSections";
import { AddressUtil, IExtendedAddressEntity } from "models/Address.model";
import { CreateApplicationIntentionUtil } from "models/CreateApplicationIntention.model";
import { JurisdictionFormCategoryUtil } from "models/JurisdictionForm.model";
import { ChildApplicationTypeUtil } from "pages/new-application/child-application/ChildApplicationType.types";

// Authentication page and its sections (the root page for unauthenticated users), no lazy load required
import AuthenticationPage from "pages/authentication/AuthenticationPage";
import LoginSection from "pages/authentication/LoginSection";
import RegistrationSection from "pages/authentication/RegistrationSection";
import RegistrationConfirmationSection from "pages/authentication/RegistrationConfirmationSection";
import ForgotPasswordSection from "pages/authentication/ForgotPasswordSection";
import ResetPasswordSection from "pages/authentication/ResetPasswordSection";
import InvitationRegistrationSection from "pages/authentication/InvitationRegistrationSection";

// Dashboard page (the root page for authenticated users), no lazy load required
import DashboardPage from "pages/dashboard/DashboardPage";
import {
  ApplicationListTabType,
  ApplicationListTabTypeUtil,
} from "components/ApplicationListTab/ApplicationListTab.types";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { lazyLoadComponent } from "components/RouteController/functions/lazyLoadComponent";
import { ParcelUtil } from "models/Parcel.model";

// Route-based code splitting, with immediate preloading (won't block the initial render)
const LibrariesPageFactory = import("pages/libraries/LibrariesPage");
const LibrariesPage = lazyLoadComponent(() => LibrariesPageFactory);

const OrganisationInvitationsPageFactory = import("pages/organisation-invitations/OrganisationInvitationsPage");
const OrganisationInvitationsPage = lazyLoadComponent(() => OrganisationInvitationsPageFactory);

const OrganisationDisabledMembersPageFactory = import(
  "pages/organisation-disabled-members/OrganisationDisabledMembersPage"
);
const OrganisationDisabledMembersPage = lazyLoadComponent(() => OrganisationDisabledMembersPageFactory);

const PaymentDetailsPageFactory = import("pages/payment-details/PaymentDetailsPage");
const PaymentDetailsPage = lazyLoadComponent(() => PaymentDetailsPageFactory);

const ApplicationSubmittedPageFactory = import("pages/application-submitted/ApplicationSubmittedPage");
const ApplicationSubmittedPage = lazyLoadComponent(() => ApplicationSubmittedPageFactory);

const ApplicationsPageFactory = import("pages/applications/ApplicationsPage");
const ApplicationsPage = lazyLoadComponent(() => ApplicationsPageFactory);

const ProjectsPageFactory = import("pages/projects/ProjectsPage");
const ProjectsPage = lazyLoadComponent(() => ProjectsPageFactory);

const IntentSelectionPageFactory = import("pages/new-application/intent-selection/IntentSelectionPage");
const IntentSelectionPage = lazyLoadComponent(() => IntentSelectionPageFactory);

const ApplicationFormContextProviderFactory = import("pages/application-form/ApplicationFormContext");
const ApplicationFormContextProvider = lazyLoadComponent(() => ApplicationFormContextProviderFactory);

const ApplicationFormPageFactory = import("pages/application-form/ApplicationFormPage");
const ApplicationFormPage = lazyLoadComponent(() => ApplicationFormPageFactory);

const ApplicationDetailsPageFactory = import("pages/application-details/ApplicationDetailsPage");
const ApplicationDetailsPage = lazyLoadComponent(() => ApplicationDetailsPageFactory);

const RFIPointDetailsPageFactory = import("pages/rfi-point-details/RFIPointDetailsPage");
const RFIPointDetailsPage = lazyLoadComponent(() => RFIPointDetailsPageFactory);

const RFIPointDetailsPageV3Factory = import("pages/rfi-point-details/RFIPointDetailsPageV3");
const RFIPointDetailsPageV3 = lazyLoadComponent(() => RFIPointDetailsPageV3Factory);

const UserProfilePageFactory = import("pages/user-profile/UserProfilePage");
const UserProfilePage = lazyLoadComponent(() => UserProfilePageFactory);

const ContactsPageFactory = import("pages/contacts/ContactsPage");
const ContactsPage = lazyLoadComponent(() => ContactsPageFactory);

const OrganisationMembersPageFactory = import("pages/organisation-members/OrganisationMembersPage");
const OrganisationMembersPage = lazyLoadComponent(() => OrganisationMembersPageFactory);

const ApplicationTemplatesPageFactory = import("pages/application-template/ApplicationTemplatesPage");
const ApplicationTemplatesPage = lazyLoadComponent(() => ApplicationTemplatesPageFactory);

const ConsentsPageFactory = import("pages/consents/ConsentsPage");
const ConsentsPage = lazyLoadComponent(() => ConsentsPageFactory);

const ApplicationAddressPageFactory = import("pages/new-application/address/ApplicationAddressPage");
const ApplicationAddressPage = lazyLoadComponent(() => ApplicationAddressPageFactory);

const FormSelectionPageFactory = import("pages/new-application/form-selection/FormSelectionPage");
const FormSelectionPage = lazyLoadComponent(() => FormSelectionPageFactory);

const AuthorityPropertyRecordPageFactory = import("pages/new-application/address/AuthorityPropertyRecordPage");
const AuthorityPropertyRecordPage = lazyLoadComponent(() => AuthorityPropertyRecordPageFactory);

const ChildApplicationPageFactory = import("pages/new-application/child-application/ChildApplicationPage");
const ChildApplicationPage = lazyLoadComponent(() => ChildApplicationPageFactory);

const UserNotificationsPageFactory = import("pages/user-notifications/UserNotificationsPage");
const UserNotificationsPage = lazyLoadComponent(() => UserNotificationsPageFactory);

const ApplicationFormPDFPageFactory = import("pages/application-form-pdf/ApplicationFormPDFPage");
const ApplicationFormPDFPage = lazyLoadComponent(() => ApplicationFormPDFPageFactory);

const ApplicationRFIsPDFPageFactory = import("pages/application-rfis-pdf/ApplicationRFIsPDFPage");
const ApplicationRFIsPDFPage = lazyLoadComponent(() => ApplicationRFIsPDFPageFactory);

const RouteController: React.FC = () => {
  const { t } = useTranslation();
  const { authenticated, authenticating } = useStoreSelector(selectAuthenticationState);
  const userInfo = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);
  const dispatch = useStoreDispatch();

  return (
    <Router>
      <React.Suspense fallback={<div>{t("Loading...")}</div>}>
        {!authenticated && (
          <Switch>
            {/* Page: Login */}
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <AuthenticationPage>
                    <LoginSection />
                  </AuthenticationPage>
                );
              }}
            />

            {/* Page: Register */}
            <Route
              exact
              path="/registration"
              render={() => {
                return (
                  <AuthenticationPage>
                    <RegistrationSection />
                  </AuthenticationPage>
                );
              }}
            />

            {/* Page: Registration Confirmation */}
            <Route
              exact
              path="/confirm-registration"
              render={(routerProps) => {
                /*
                  Sample email link format
                  http://builddev.objectivebuild.com/confirm-registration?email=xxx&verification_code=yyy
                */
                const searchParams = new URLSearchParams(routerProps.location.search);
                const verificationCode = trim(searchParams.get("verification_code") || "");
                const email = trim(searchParams.get("email") || "");

                return (
                  <AuthenticationPage>
                    <RegistrationConfirmationSection verificationCode={verificationCode!} email={email} />
                  </AuthenticationPage>
                );
              }}
            />

            {/* Page: Organisation Invitation */}
            <Route
              exact
              path="/registration-via-invitation"
              render={(routerProps) => {
                const searchParams = new URLSearchParams(routerProps.location.search);
                const invitationCode = toString(searchParams.get("code"));

                if (!invitationCode) {
                  return <Redirect to={"/"} />;
                }

                return (
                  <AuthenticationPage>
                    <InvitationRegistrationSection invitationCode={invitationCode} />
                  </AuthenticationPage>
                );
              }}
            />

            {/* Page: Forgot Password */}
            <Route
              exact
              path="/forgot-password"
              render={() => {
                return (
                  <AuthenticationPage>
                    <ForgotPasswordSection />
                  </AuthenticationPage>
                );
              }}
            />

            {/* Page: Reset Password */}
            <Route
              exact
              path="/reset-password"
              render={(routerProps) => {
                const email = get(routerProps.location.state, "email") || "";
                if (!email) {
                  return <Redirect to="/forgot-password" push={false} />;
                }
                return (
                  <AuthenticationPage>
                    <ResetPasswordSection email={email} />
                  </AuthenticationPage>
                );
              }}
            />

            {!authenticating && (
              <Route path="*">
                <Redirect to={"/"} />
              </Route>
            )}
          </Switch>
        )}

        {authenticated && !authenticating && userInfo && (
          <JurisdictionDataProvider name="NZ">
            <MainLayout>
              <Switch>
                {/* Page: Dashboard */}
                <Route exact path="/" component={DashboardPage} />

                {/* Page: Projects */}
                <Route
                  exact
                  path="/projects"
                  render={(routeProps) => {
                    const searchParams = new URLSearchParams(routeProps.location.search);
                    const activeTabType = ApplicationListTabTypeUtil.parseForProjectsList(
                      toString(searchParams.get("tab"))
                    );

                    const encodedSelectedFilters = trim(toString(searchParams.get("filters")));

                    if (!activeTabType) {
                      return (
                        <Redirect
                          to={`/projects?tab=${ApplicationListTabType.All}&filters=${encodedSelectedFilters}`}
                          push={false}
                        />
                      );
                    }

                    return (
                      <ProjectsPage activeTabType={activeTabType} encodedSelectedFilters={encodedSelectedFilters} />
                    );
                  }}
                />

                {/* Page: Applications */}
                <Route
                  exact
                  path="/applications"
                  render={(routeProps) => {
                    const searchParams = new URLSearchParams(routeProps.location.search);
                    const activeTabType = ApplicationListTabTypeUtil.parseForApplicationList(
                      toString(searchParams.get("tab"))
                    );
                    const encodedSelectedFilters = trim(toString(searchParams.get("filters")));

                    if (!activeTabType) {
                      return (
                        <Redirect
                          to={`/applications?tab=${ApplicationListTabType.Draft}&filters=${encodedSelectedFilters}`}
                          push={false}
                        />
                      );
                    }

                    return (
                      <ApplicationsPage activeTabType={activeTabType} encodedSelectedFilters={encodedSelectedFilters} />
                    );
                  }}
                />

                {/* Page: Application Consents */}
                <Route
                  exact
                  path="/consents"
                  render={(routeProps) => {
                    const searchParams = new URLSearchParams(routeProps.location.search);
                    const activeTabType = ApplicationListTabTypeUtil.parseForConsentList(
                      toString(searchParams.get("tab"))
                    );
                    const encodedSelectedFilters = toString(searchParams.get("filters"));

                    if (!activeTabType) {
                      return (
                        <Redirect
                          to={`/consents?tab=${ApplicationListTabType.Current}&filters=${encodedSelectedFilters}`}
                          push={false}
                        />
                      );
                    }
                    return (
                      <ConsentsPage activeTabType={activeTabType} encodedSelectedFilters={encodedSelectedFilters} />
                    );
                  }}
                />

                {/* Page: New Application */}
                <Route path="/new-application">
                  <Switch>
                    <Route path="/new-application/intent-selection" exact={true} component={IntentSelectionPage} />

                    {/* Page: Child application */}
                    <Route
                      path="/new-application/child-application"
                      exact={true}
                      render={(routeProps) => {
                        const queryParams = new URLSearchParams(routeProps.location.search);
                        try {
                          const applicationType = ChildApplicationTypeUtil.parse(toString(queryParams.get("type")));
                          return <ChildApplicationPage applicationType={applicationType} />;
                        } catch (e) {
                          return <Redirect to={"/"} />;
                        }
                      }}
                    />

                    <Route
                      path={"/new-application/address"}
                      exact={true}
                      render={(routeProps) => {
                        const queryParams = new URLSearchParams(routeProps.location.search);
                        let intention = CreateApplicationIntentionUtil.parse(toString(queryParams.get("intention")));
                        const refApplicationId = toInteger(queryParams.get("refApplicationId"));

                        const addressParam = queryParams.get("address");
                        const authorityIdParam = queryParams.get("authority-id");

                        let address: IExtendedAddressEntity | undefined;
                        if (addressParam) {
                          address = AddressUtil.base64Decode(addressParam);
                        }

                        let authorityId: number | undefined;
                        if (authorityIdParam) {
                          authorityId = toInteger(authorityIdParam);
                        }

                        return (
                          <ApplicationAddressPage
                            intention={intention}
                            refApplicationId={refApplicationId}
                            address={address}
                            authorityId={authorityId}
                          />
                        );
                      }}
                    />

                    <Route
                      path={"/new-application/authority-property-records"}
                      exact={true}
                      render={(routeProps) => {
                        const queryParams = new URLSearchParams(routeProps.location.search);

                        try {
                          let intention = CreateApplicationIntentionUtil.parse(toString(queryParams.get("intention")));
                          const refApplicationId = toInteger(queryParams.get("refApplicationId"));
                          const address = AddressUtil.base64Decode(queryParams.get("address") || "");
                          const authorityId = toInteger(queryParams.get("authority-id"));

                          return (
                            <AuthorityPropertyRecordPage
                              intention={intention}
                              refApplicationId={refApplicationId}
                              address={address}
                              authorityId={authorityId}
                            />
                          );
                        } catch (e) {
                          return <Redirect to={"/"} />;
                        }
                      }}
                    />

                    <Route
                      path={"/new-application/form-selection"}
                      exact={true}
                      render={(routeProps) => {
                        const queryParams = new URLSearchParams(routeProps.location.search);

                        try {
                          const address = AddressUtil.base64Decode(queryParams.get("address") || "");
                          const authorityId = toInteger(queryParams.get("authority-id"));
                          const formCategory = JurisdictionFormCategoryUtil.parse(queryParams.get("category") || "");
                          const parentApplicationId = toInteger(queryParams.get("parent-application-id") || 0);
                          const parcel = ParcelUtil.base64Decode(queryParams.get("parcel") || "");

                          return (
                            <FormSelectionPage
                              address={address}
                              authorityId={authorityId}
                              formCategory={formCategory}
                              parentApplicationId={parentApplicationId}
                              parcel={parcel}
                            />
                          );
                        } catch (e) {
                          return <Redirect to={"/"} />;
                        }
                      }}
                    />

                    {/* Default fallback to Intent Selection page if no sub-page given */}
                    <Route path={"*"}>
                      <Redirect to={"/new-application/intent-selection"} />
                    </Route>
                  </Switch>
                </Route>

                {/* Page: Printable application form */}
                <Route
                  exact
                  path="/application-form-pdf/:applicationId"
                  render={(routeProps) => {
                    const applicationId = toInteger(get(routeProps.match.params, "applicationId"));
                    return <ApplicationFormPDFPage applicationId={applicationId} />;
                  }}
                />

                {/* Page: Printable Application RFIs */}
                <Route
                  exact
                  path="/application-rfis-pdf/:applicationId"
                  render={(routeProps) => {
                    const applicationId = toInteger(get(routeProps.match.params, "applicationId"));
                    return <ApplicationRFIsPDFPage applicationId={applicationId} />;
                  }}
                />

                {/* Page: Application Form */}
                <Route path="/application-form">
                  <Switch>
                    <Route
                      exact
                      path="/application-form/:applicationId/(breadcrumb)?/:breadcrumbId(\d+)?/(step)?/:stepId(\d+)?"
                      render={(routeProps) => {
                        const applicationId = toInteger(get(routeProps.match.params, "applicationId"));
                        const breadcrumbId = toInteger(get(routeProps.match.params, "breadcrumbId"));
                        const breadcrumbStepId = toInteger(get(routeProps.match.params, "stepId"));

                        batch(() => {
                          dispatch(setCurrentApplicationId(applicationId));
                          dispatch(setCurrentBreadcrumbId(breadcrumbId));
                          dispatch(setCurrentBreadcrumbStepId(breadcrumbStepId));
                        });

                        return (
                          <ApplicationFormContextProvider>
                            <SavingStepDataWhenMovingAway
                              applicationId={applicationId}
                              breadcrumbStepId={breadcrumbStepId}
                            >
                              <ApplicationFormPage />
                            </SavingStepDataWhenMovingAway>
                          </ApplicationFormContextProvider>
                        );
                      }}
                    />
                  </Switch>
                </Route>

                {/* Page: Application Details */}
                <Route path="/application-details">
                  <Switch>
                    <Route
                      exact
                      path="/application-details/:applicationId"
                      render={(routeProps) => {
                        const applicationId = toInteger(routeProps.match.params.applicationId);
                        const searchParams = new URLSearchParams(routeProps.location.search);
                        const section = ApplicationDetailsSectionsUtil.parse(toString(searchParams.get("section")));
                        const subSection = toString(searchParams.get("subSection"));

                        // TODO: BLD-3104 do not use `setCurrentApplicationId` out of form editing
                        batch(() => {
                          dispatch(setCurrentApplicationId(applicationId));
                        });

                        return <ApplicationDetailsPage section={section} subSection={subSection} />;
                      }}
                    />

                    <Route
                      exact
                      path="/application-details/:applicationId/rfi-point-details/:rfiPointId"
                      render={(routeProps) => {
                        const applicationId = toInteger(routeProps.match.params.applicationId);
                        const rfiPointId = toInteger(routeProps.match.params.rfiPointId);

                        // TODO: BLD-3104 do not use `setCurrentApplicationId` out of form editing
                        batch(() => {
                          dispatch(setCurrentApplicationId(applicationId));
                        });

                        return <RFIPointDetailsPage applicationId={applicationId} rfiPointId={rfiPointId} />;
                      }}
                    />

                    <Route
                      exact
                      path="/application-details/:applicationId/rfi-point-details-v3/:rfiPointId"
                      render={(routeProps) => {
                        const applicationId = toInteger(routeProps.match.params.applicationId);
                        const rfiPointId = toInteger(routeProps.match.params.rfiPointId);
                        return <RFIPointDetailsPageV3 applicationId={applicationId} rfiPointId={rfiPointId} />;
                      }}
                    />

                    <Route
                      exact
                      path="/application-details/:applicationId/payment-details/:paymentId"
                      render={(routeProps) => {
                        const applicationId = toInteger(routeProps.match.params.applicationId);
                        const paymentId = toInteger(routeProps.match.params.paymentId);

                        // TODO: BLD-3104 do not use `setCurrentApplicationId` and `setCurrentPaymentId` out of form editing
                        batch(() => {
                          dispatch(setCurrentApplicationId(applicationId));
                          dispatch(setCurrentPaymentId(paymentId));
                        });
                        return <PaymentDetailsPage />;
                      }}
                    />
                  </Switch>
                </Route>

                {/* Page: Application Submitted */}
                <Route path="/application-submitted">
                  <Switch>
                    <Route
                      exact
                      path="/application-submitted/:applicationId(\d+)"
                      render={(routeProps) => {
                        // TODO: BLD-3104 do not use `setCurrentApplicationId` out of form editing
                        batch(() => {
                          dispatch(setCurrentApplicationId(toInteger(routeProps.match.params.applicationId)));
                        });

                        return <ApplicationSubmittedPage />;
                      }}
                    />
                  </Switch>
                </Route>

                {/* Page: User Profile */}
                <Route path="/user-profile">
                  <UserProfilePage />
                </Route>

                {/* Page: User Notifications */}
                <Route path="/user-notifications">
                  <UserNotificationsPage />
                </Route>

                {/* Page: Libraries Page */}
                <Route path="/libraries">
                  <LibrariesPage />
                </Route>

                {/* Page: Organisation Members */}
                <Route path="/organisation-members">
                  <OrganisationMembersPage />
                </Route>

                {/* Page: Organisation Invitations */}
                <Route path="/organisation-invitations">
                  <OrganisationInvitationsPage />
                </Route>

                {/* Page: Application templates */}
                <Route path="/application-templates">
                  <ApplicationTemplatesPage />
                </Route>

                {/* Page: Organisation Disabled members */}
                <Route path="/organisation-disabled-members">
                  <OrganisationDisabledMembersPage />
                </Route>

                {/* Page: Contact Library */}
                <Route path="/contacts">
                  <ContactsPage />
                </Route>

                <Route path="*">
                  <Redirect to={"/"} />
                </Route>
              </Switch>
            </MainLayout>
          </JurisdictionDataProvider>
        )}
      </React.Suspense>
    </Router>
  );
};
export default RouteController;
