import { ICountryEntity } from "models/Country.model";
import { createAsyncThunk, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { createDeepEqualSelector } from "store/utils";

// Entity Adapter

const countryAdapter = createEntityAdapter<ICountryEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    return a.id - b.id;
  },
});

// Action & Thunks

export const fetchCountryInfo = createAsyncThunk("domainData/country/fetchCountryInfo", async () => {
  const countries = await serviceContainer.cradle.countryService.fetchCountries();
  return countries;
});

// Reducer

export const defaultCountryState = countryAdapter.getInitialState();

export const countryReducer = createReducer<EntityState<ICountryEntity>>(defaultCountryState, (builder) =>
  builder.addCase(fetchCountryInfo.fulfilled, countryAdapter.upsertMany)
);

// Selectors

export const {
  selectById: selectCountryEntityById,
  selectIds: selectCountryEntityIds,
  selectEntities: selectCountryEntities,
  selectAll: selectAllCountryEntities,
  selectTotal: selectTotalCountryEntities,
} = countryAdapter.getSelectors((state: RootState) => state.domainData.country);

export const selectCountryEntityByName = createDeepEqualSelector(
  [selectAllCountryEntities, (state: RootState, name: string) => name],
  (entities, name) => entities.find((entity) => entity.name === name)
);

export const selectCountryEntityByAbbreviatedName = createDeepEqualSelector(
  [selectAllCountryEntities, (state: RootState, abbrName: string) => abbrName],
  (entities, abbrName) => entities.find((entity) => entity.abbreviatedName === abbrName)
);
