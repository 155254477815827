import { ApplicationStatus } from "models/ApplicationStatus.model";
import { ParticipantAccessLevel } from "models/Participant.model";
import { IAddressEntity } from "models/Address.model";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import { IWorkflowStage } from "models/ApplicationWorkflow.model";

export type IApplicationEntity = {
  id: number;
  userId: number; // The user who created the application, link to a IUserEntity
  jurisdictionId: number;
  name: string;
  description: string;
  recordType: ApplicationRecordType;
  status: ApplicationStatus;
  formId: number; // Link to a IJurisdictionFormEntity
  consentNumber: string;
  parentApplicationId: number;
  authorityId: number; // Link to a IAuthorityEntity
  participantIds: number[]; // Link to many IParticipantEntity
  primaryPropertyId: number; // Link to a IPropertyEntity
  propertyIds: number[]; // Link to many IPropertyEntity
  legalDescription: string;
  trackingNumber: string;
  applicationNumber: string;
  applicationFormPdf: string;
  applicationStatusLabel: string;
  complexity: string; // R1, R2, etc
  complexityDescription: string;
  startedDate: string;
  modifiedDate: string;
  submissionDate: string;
  address: IAddressEntity; // Display address
  primaryContactId: number;
  etag: string;
  formDisplayName: string;
  qualificationTypes: string[];
  workflowStages?: IWorkflowStage[];
  userSignature: string;
  closedReason: string;
  migrationSource: string;

  // Viewer-specific fields
  accessLevel: ParticipantAccessLevel;
  notificationLevel: NotificationLevel;
  isParticipant: boolean;
  buildingPurposeDescription: string;
  hasRFI: boolean;
  hasOutstandingRFIs: boolean;
  isHidden: boolean;
  hasOutstandingPayments: boolean;
  developmentContributionRequired: boolean;
  resourceConsentRequired: boolean;
  useRequiredDocumentGroups: boolean;
  useMultipleRfiDocuments: boolean;
};

export type IApplicationUserPreference = {
  isHidden: boolean;
};

export enum ApplicationRecordType {
  Draft = "Draft",
  Consent = "Consent",
  Template = "Template",
}

export enum ApplicationOperationType {
  Create = "Create",
  Duplicate = "Duplicate",
  CreateFromTemplate = "CreateFromTemplate",
  CreateTemplate = "CreateTemplate",
}

export const ApplicationUtil = {
  getDisplayId: (application?: IApplicationEntity): string => {
    if (!application) {
      return "";
    }
    return application.recordType === ApplicationRecordType.Consent
      ? application.consentNumber
      : application.applicationNumber;
  },
  isAttentionRequired: (application?: IApplicationEntity): boolean => {
    if (!application) {
      return false;
    }

    return Boolean(application.hasOutstandingPayments || application.hasOutstandingRFIs);
  },
  getAddressWithLegalDescription: (application?: IApplicationEntity): string => {
    if (!application) {
      return "";
    }
    const address = application.address.fullAddress;
    const legalDescription = application.legalDescription;
    return legalDescription ? `${address} - ${legalDescription}` : address;
  },
};

export const ApplicationRecordTypeUtil = {
  parse: (str: string): ApplicationRecordType => {
    for (const value of Object.values(ApplicationRecordType)) {
      if (str.toLowerCase() === value.toString().toLowerCase()) {
        return value;
      }
    }
    return ApplicationRecordType.Draft;
  },
};
