import React from "react";
import Section from "components/Layout/Section";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  fetchOrganisationMergeInvitationsSentToCurrentUser,
  selectPendingOrganisationMergeInvitationsSentToCurrentUser,
} from "store/domain-data/organisation-merge-invitation/organisationMergeInvitation";
import OrganisationMergeInvitationAlertItem from "pages/dashboard/OrganisationMergeInvitationAlertItem";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { OrganisationRole } from "models/OrganisationMember.model";
import { useProfileIncompleteAlert } from "pages/dashboard/functions/useProfileIncompleteAlert";
import styled, { css } from "styled-components/macro";
import Announcements from "pages/authentication/Announcements";
import { AnnouncementLocation } from "models/Announcement.model";
import { selectAnnouncementBasedOnLocation } from "store/domain-data/announcement/announcement";
import { useRespondToRegisterApplicantInvitationAlerts } from "pages/dashboard/functions/useRespondToRegisterApplicantInvitationAlerts";

type Props = {};

const AlertsSection: React.FC<Props> = () => {
  const dispatch = useStoreDispatch();
  const organisationMergeInvitations = useStoreSelector(selectPendingOrganisationMergeInvitationsSentToCurrentUser);
  const userInfo = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);

  const announcements = useStoreSelector((state) =>
    selectAnnouncementBasedOnLocation(state, AnnouncementLocation.Dashboard)
  );

  const hasAnnouncements = announcements.length > 0;

  React.useEffect(() => {
    // Invitations to merge organisations
    if (userInfo && userInfo.organisationRole === OrganisationRole.Admin) {
      dispatch(fetchOrganisationMergeInvitationsSentToCurrentUser());
    }
  }, [dispatch, userInfo]);

  const profileIncompleteAlert = useProfileIncompleteAlert();
  const respondToRegisterApplicantInvitationAlert = useRespondToRegisterApplicantInvitationAlerts();
  const hasAnyOrganisationMergeInvitations = organisationMergeInvitations.length > 0;

  // No alerts, hide the whole section
  if (
    !profileIncompleteAlert &&
    !hasAnyOrganisationMergeInvitations &&
    !hasAnnouncements &&
    !respondToRegisterApplicantInvitationAlert
  ) {
    return null;
  }

  return (
    <Section>
      <StyledAlerts>
        <Announcements location={AnnouncementLocation.Dashboard} />
        {profileIncompleteAlert}
        {respondToRegisterApplicantInvitationAlert}
        {hasAnyOrganisationMergeInvitations &&
          organisationMergeInvitations.map((invitation) => (
            <OrganisationMergeInvitationAlertItem mergeInvitationId={invitation.id} key={invitation.id} />
          ))}
      </StyledAlerts>
    </Section>
  );
};

const StyledAlerts = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("12px")};
  `
);

export default AlertsSection;
