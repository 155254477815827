import {
  FetchApplicationsBadgeCountArgs,
  FetchPaginatedApplicationsArgs,
  IApplicationService,
  IUpdateApplicationTemplatePayload,
} from "services/application/ApplicationService.types";
import {
  ApplicationOperationType,
  ApplicationRecordType,
  ApplicationRecordTypeUtil,
  IApplicationEntity,
  IApplicationUserPreference,
} from "models/Application.model";
import { ApplicationStatusUtil } from "models/ApplicationStatus.model";
import { Cradle } from "services/serviceContainer.types";
import toInteger from "lodash/toInteger";
import get from "lodash/get";
import toString from "lodash/toString";
import { IApplicationStepDataEntity } from "models/ApplicationStepData.model";
import toArray from "lodash/toArray";
import { IParticipantEntity, parseParticipantAccessLevelString } from "models/Participant.model";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IAddressEntity } from "models/Address.model";
import { NotificationSubscriptionLevelUtil } from "models/NotificationSubscriptionLevel.model";
import { parseWorkflowStages } from "models/ApplicationWorkflow.model";
import { IApplicationTemplateEntity } from "models/ApplicationTemplate.model";
import { IEncodedImageFile } from "models/EncodedImageFile.model";
import { IRequiredDocumentEntity, MergeStatusUtil, RequiredDocumentStatusUtil } from "models/RequiredDocument.model";
import {
  InspectionStatusUtil,
  InspectionTypeStatusUtil,
  IRequiredInspectionHistoryEntity,
  IRequiredInspectionsEntity,
  IRequiredLocationInspectionTypeEntity,
} from "models/RequiredInspections.model";
import trim from "lodash/trim";
import nth from "lodash/nth";
import toNumber from "lodash/toNumber";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { ApplicationSortCriteria } from "models/ApplicationSortCriteria.model";
import { SortDirection } from "models/SortDirection.model";
import { IParcelEntity } from "models/Parcel.model";
import { sanitize } from "utils/sanitize";

export class ApplicationService implements IApplicationService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];
  private readonly participantService: Cradle["participantService"];

  constructor(args: {
    apiClient: Cradle["apiClient"];
    i18n: Cradle["i18n"];
    participantService: Cradle["participantService"];
  }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
    this.participantService = args.participantService;
  }

  public async fetchPaginatedApplicationsForCurrentUser(args: FetchPaginatedApplicationsArgs) {
    const {
      pageIndex,
      pageSize,
      recordType,
      isHidden,
      sortBy = ApplicationSortCriteria.ModifiedDate,
      sortDirection = SortDirection.Descending,
      accessLevel,
      searchText,
      statuses = [],
      forms = [],
      authorities = [],
      complexities = [],
    } = args;

    const queryParams = new URLSearchParams();

    queryParams.set("pageIndex", toString(pageIndex));
    queryParams.set("pageSize", toString(pageSize));
    queryParams.set("recordType", recordType);
    queryParams.set("sortBy", sortBy);
    queryParams.set("sortDirection", sortDirection);

    if (!isUndefined(isHidden)) {
      queryParams.set("isHidden", toString(isHidden));
    }

    if (!isEmpty(forms)) {
      queryParams.set("form", toString(forms.join(",")));
    }

    if (!isEmpty(authorities)) {
      queryParams.set("authority", toString(authorities.join(",")));
    }

    if (!isEmpty(complexities)) {
      queryParams.set("complexity", toString(complexities.join(",")));
    }

    if (!isEmpty(statuses)) {
      queryParams.set("status", statuses.join(","));
    }

    if (accessLevel) {
      queryParams.set("accessLevel", accessLevel);
    }

    if (searchText) {
      queryParams.set("searchText", toString(searchText));
    }

    const response = await this.apiClient.protectedApi.get(`/user/applicationsV2?${queryParams.toString()}`);

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }

    return this.parseApplications(jsonArr);
  }

  public async fetchApplicationsBadgeCount(args: FetchApplicationsBadgeCountArgs) {
    const { recordType, isHidden, status = [] } = args;

    const queryParams = new URLSearchParams();

    if (!isUndefined(recordType)) {
      queryParams.set("recordType", recordType);
    }
    if (!isUndefined(isHidden)) {
      queryParams.set("isHidden", toString(isHidden));
    }
    if (!isEmpty(status)) {
      queryParams.set("status", status.join(","));
    }

    const response = await this.apiClient.protectedApi.get(`/user/applicationsV2/badges?${queryParams.toString()}`);
    const badgeCount = get(response, "data");
    return toInteger(badgeCount);
  }

  public async fetchApplicationConsentsForCurrentUser() {
    const response = await this.apiClient.protectedApi.get("/user/applications?recordType=Consent");

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }

    return this.parseApplications(jsonArr);
  }

  public async fetchApplicationsForCurrentUser() {
    const response = await this.apiClient.protectedApi.get("/user/applications?recordType=Draft");

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch applications`));
    }

    return this.parseApplications(jsonArr);
  }

  public async fetchApplicationTemplatesForCurrentUser() {
    const response = await this.apiClient.protectedApi.get(
      "/user/applicationsV2?recordType=Template&pageSize=1000&pageIndex=1"
    );

    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch application templates`));
    }

    const { applications, participants, templates } = this.parseApplications(jsonArr);

    return {
      templates,
      applications,
      participants,
    };
  }

  public async createApplication(args: {
    jurisdictionId: number;
    formId: number;
    authorityId: number;
    address: IAddressEntity;
    parcel?: IParcelEntity;
  }) {
    const { address, parcel, ...others } = args;
    const payload = {
      address: {
        ...address,
        legalDescription: parcel?.legalDescription,
        lotNumber: parcel?.lotNumber,
        dpNumber: parcel?.dpNumber,
        parcelId: parcel?.parcelId,
        valuationNumber: parcel?.valuationNumber,
      },
      operationName: ApplicationOperationType.Create,
      recordType: ApplicationRecordType.Draft,
      ...others,
    };

    const response = await this.apiClient.protectedApi.post("/user/applications", payload);
    const json = response.data;
    return this.parseApplication(json);
  }

  public async createDuplicateApplication(args: {
    applicationId: number;
    address: IAddressEntity;
    authorityId: number;
    parcel?: IParcelEntity;
  }) {
    const { address, parcel } = args;
    const payload = {
      applicationId: args.applicationId,
      address: {
        ...address,
        legalDescription: parcel?.legalDescription,
        lotNumber: parcel?.lotNumber,
        dpNumber: parcel?.dpNumber,
        parcelId: parcel?.parcelId,
        valuationNumber: parcel?.valuationNumber,
      },
      authorityId: args.authorityId,
      parcel: args.parcel,
      recordType: ApplicationRecordType.Draft,
      operationName: ApplicationOperationType.Duplicate,
    };
    const response = await this.apiClient.protectedApi.post(`/user/applications`, payload);
    const json = response.data;
    return this.parseApplication(json);
  }

  public async createApplicationFromTemplate(args: {
    applicationId: number;
    address: IAddressEntity;
    authorityId: number;
    parcel?: IParcelEntity;
  }) {
    const { address, parcel } = args;
    const payload = {
      applicationId: args.applicationId,
      address: {
        ...address,
        legalDescription: parcel?.legalDescription,
        lotNumber: parcel?.lotNumber,
        dpNumber: parcel?.dpNumber,
        parcelId: parcel?.parcelId,
        valuationNumber: parcel?.valuationNumber,
      },
      authorityId: args.authorityId,
      parcel: args.parcel,
      recordType: ApplicationRecordType.Draft,
      operationName: ApplicationOperationType.CreateFromTemplate,
    };
    const response = await this.apiClient.protectedApi.post(`/user/applications`, payload);
    const json = response.data;
    return this.parseApplication(json);
  }

  public async createChildApplication(args: { formId: number; parentApplicationId: number }) {
    const payload = {
      formId: args.formId,
    };

    const response = await this.apiClient.protectedApi.post(
      `/user/applications/${args.parentApplicationId}/child-applications`,
      payload
    );
    const json = response.data;
    return this.parseApplication(json);
  }

  public async fetchApplicationById(applicationId: number) {
    try {
      const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}`);
      return this.parseApplication(response.data);
    } catch (error) {
      // Handle application not found / access denied
      if (error.response && error.response.status === 404) {
        const errorMessage = toString(get(error.response.data, "description")) || error.message;
        throw new ServiceError(ServiceErrorCode.ResourceNotFound, errorMessage);
      }

      return Promise.reject(error);
    }
  }

  public async fetchChildApplications(parentApplicationId: number) {
    const response = await this.apiClient.protectedApi.get(
      `user/applications/${parentApplicationId}/child-applications`
    );
    const jsonArr = get(response.data, "applications");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch child applications`));
    }

    return this.parseApplications(jsonArr);
  }

  public async createTemplateFromApplication(payload: {
    applicationId: number;
    templateName: string;
    templateDescription?: string;
    templateThumbnail?: string;
    templateThumbnailName?: string;
  }): Promise<{
    template: IApplicationTemplateEntity;
    application: IApplicationEntity;
    participants: IParticipantEntity[];
  }> {
    const response = await this.apiClient.protectedApi.post("/user/applications", {
      recordType: "Template",
      applicationId: payload.applicationId,
      templateName: payload.templateName,
      templateDescription: payload.templateDescription,
      templateThumbnail: payload.templateThumbnail,
      templateThumbnailFileName: payload.templateThumbnailName,
      operationName: ApplicationOperationType.CreateTemplate,
    });
    const json = response.data;
    const { application, participants, template } = this.parseApplication(json);

    if (!template) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Fail to create template`));
    }

    return {
      template,
      application,
      participants,
    };
  }

  public async updateApplicationTemplate(args: {
    applicationId: number;
    payload: IUpdateApplicationTemplatePayload;
  }): Promise<IApplicationTemplateEntity> {
    const response = await this.apiClient.protectedApi.patch(`/user/applications/${args.applicationId}`, args.payload);
    const json = response.data;
    const template = this.parseApplicationTemplate(json);
    if (!template) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Fail to update template`));
    }

    return template;
  }

  public async deleteApplicationById(applicationId: number): Promise<void> {
    await this.apiClient.protectedApi.delete(`/user/applications/${applicationId}`);
  }

  public async submitApplication(payload: { applicationId: number; signature: string }) {
    const { applicationId, signature } = payload;

    const response = await this.apiClient.protectedApi.post(`/user/applications/${applicationId}/submission`, {
      userSignature: signature,
    });
    return this.parseApplication(response.data);
  }

  public async submitRequiredDocument(applicationId: number, requiredDocumentId: number) {
    const response = await this.apiClient.protectedApi.patch(
      `/user/applications/${applicationId}/required-documents/${requiredDocumentId}`,
      {
        containerMergeStatus: "InProgress",
      }
    );
    return this.parseRequiredDocument(applicationId, response.data);
  }

  public async saveStepData(
    args: Pick<IApplicationStepDataEntity, "applicationId" | "breadcrumbStepId" | "data">
  ): Promise<void> {
    const sanitizedData = sanitize(args.data);

    const payload = {
      data: sanitizedData,
    };

    await this.apiClient.protectedApi.post(
      `/user/applications/${args.applicationId}/steps/${args.breadcrumbStepId}`,
      payload
    );
  }

  public async fetchStepData(
    args: Pick<IApplicationStepDataEntity, "applicationId" | "breadcrumbStepId">
  ): Promise<IApplicationStepDataEntity> {
    const response = await this.apiClient.protectedApi.get(
      `/user/applications/${args.applicationId}/steps/${args.breadcrumbStepId}`
    );
    const json = response.data;
    const sanitizedData = sanitize(get(json, "data"));

    const stepData: IApplicationStepDataEntity = {
      applicationId: args.applicationId,
      breadcrumbStepId: args.breadcrumbStepId,
      data: sanitizedData,
      isValid: Boolean(get(json, "valid")),
      exists: Boolean(get(json, "exists")),
      validationErrors: get(json, "validationErrors") || {},
    };

    return stepData;
  }

  public async fetchEnabledSteps(applicationId: number): Promise<Array<{ id: number; name: string }>> {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/enabled-steps`);
    const json = get(response.data, "steps");
    if (!json || !Array.isArray(json)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t("Failed to fetch enabled steps"));
    }

    const enabledSteps = json.map((item) => {
      return {
        id: toInteger(get(item, "id")),
        name: toString(get(item, "name")),
      };
    });
    return enabledSteps;
  }

  public async updateApplicationUserPreferences(args: {
    applicationId: number;
    isHidden: boolean;
  }): Promise<IApplicationUserPreference> {
    const response = await this.apiClient.protectedApi.patch(`/user/applications/${args.applicationId}/preferences`, {
      isHidden: args.isHidden,
    });
    return this.parseApplicationUserPreferences(response.data);
  }

  public async fetchRejectReason(applicationId: number): Promise<{ rejectedReason: string }> {
    const response = await this.apiClient.protectedApi.get(`/user/applications/${applicationId}/rejected-reason`);
    const rejectedReason = toString(get(response.data, "rejectedReason"));
    return { rejectedReason };
  }

  private parseApplicationUserPreferences(json: any): IApplicationUserPreference {
    return { isHidden: Boolean(get(json, "isHidden")) };
  }

  private parseApplicationTemplate(json: any): IApplicationTemplateEntity | undefined {
    const applicationId = toInteger(get(json, "id"));

    const templateName = toString(get(json, "templateName"));
    if (!templateName) {
      return;
    }

    const thumbnailJson = get(json, "templateThumbnail") || get(json, "encodedImageFileEmbedDTO");
    let templateThumbnail: IEncodedImageFile | null = null;

    const base64Image = toString(get(thumbnailJson, "base64Image"));
    if (base64Image) {
      const nameInDB = toString(get(thumbnailJson, "name"));
      const name = nameInDB || "thumbnail.jpg";

      const typeInDB = toString(get(thumbnailJson, "type"));
      const type = typeInDB.startsWith("image/") ? typeInDB : `image/${typeInDB}`;

      const sizeInDB = toInteger(get(thumbnailJson, "size"));
      const size = sizeInDB || base64Image.length;

      templateThumbnail = {
        name,
        type,
        size,
        base64Image,
        modifiedDate: toString(get(thumbnailJson, "modifiedDate")),
      };
    }

    const template: IApplicationTemplateEntity = {
      applicationId: applicationId,
      displayName: templateName,
      description: toString(get(json, "templateDescription")),
      thumbnail: templateThumbnail,
      numOfDocuments: toInteger(get(json, "templateDocumentCount")),
    };
    return template;
  }

  private parseApplications(jsonArr: any[]) {
    const applications: IApplicationEntity[] = [];
    const participants: IParticipantEntity[] = [];
    const templates: IApplicationTemplateEntity[] = [];

    for (const json of jsonArr) {
      const result = this.parseApplication(json);
      applications.push(result.application);
      participants.push(...result.participants);
      if (result.template) {
        templates.push(result.template);
      }
    }

    return {
      applications,
      participants,
      templates,
    };
  }

  private parseApplication(
    json: any
  ): {
    application: IApplicationEntity;
    participants: IParticipantEntity[];
    requiredDocuments: IRequiredDocumentEntity[];
    requiredInspections: IRequiredInspectionsEntity[];
    template?: IApplicationTemplateEntity;
  } {
    const applicationId = toInteger(get(json, "id"));

    // Parse property ids
    const propertyIds = [];
    let primaryPropertyId = 0;
    const propertyJSONArr = toArray(get(json, "properties"));
    for (const item of propertyJSONArr) {
      const propertyId = toInteger(get(item, "id"));
      propertyIds.push(propertyId);
      if (Boolean(get(item, "primaryProperty"))) {
        primaryPropertyId = propertyId;
      }
    }

    // Parse participants
    const participantsJSON = get(json, "participants");
    const primaryContactId = get(json, "primaryContactId");
    const participants: IParticipantEntity[] = [];
    if (Array.isArray(participantsJSON)) {
      participantsJSON.forEach((participantJSON) => {
        const participant = this.participantService.parseParticipantFromJSON(
          applicationId,
          participantJSON,
          primaryContactId
        );
        participants.push(participant);
      });
    }

    //TODO: Change Application address in B/E to address object. Remove when Application address uses address object
    // Note: application.address is not returning as a complete form, so here we just make the best guess
    const fullAddress =
      typeof json.address === "string" ? toString(get(json, "address")) : toString(get(json, "address.fullAddress"));

    const addressComponents = fullAddress.split(",").map(trim);
    const address: IAddressEntity = {
      address1: toString(get(json, "address.address1")) || toString(nth(addressComponents, 0)),
      address2: toString(get(json, "address.address2")) || toString(nth(addressComponents, 1)),
      city: toString(get(json, "address.city")) || toString(nth(addressComponents, -2)),
      state: toString(get(json, "address.state")),
      country: toString(get(json, "address.country")) || "NZ",
      zipCode: toString(get(json, "address.zipCode")) || toString(nth(addressComponents, -1)),
      fullAddress: fullAddress,
      isManualAddress: Boolean(get(json, "address.isManualAddress")),
    };

    const getRecordType = () => {
      const recordType = ApplicationRecordTypeUtil.parse(toString(get(json, "recordType")));
      if (recordType === ApplicationRecordType.Draft) {
        const isConsent = Boolean(get(json, "isConsent"));
        if (isConsent) {
          return ApplicationRecordType.Consent;
        }
      }

      return recordType;
    };

    const application: IApplicationEntity = {
      id: applicationId,
      name: toString(get(json, "name")),
      consentNumber: toString(get(json, "consentNumber")),
      parentApplicationId: toInteger(get(json, "parentApplicationId")),
      recordType: getRecordType(),
      trackingNumber: toString(get(json, "trackingNumber")),
      propertyIds: propertyIds,
      primaryPropertyId: primaryPropertyId,
      authorityId: toInteger(get(json, "authorityId")),
      jurisdictionId: toInteger(get(json, "jurisdictionId")),
      complexity: toString(get(json, "applicationComplexity")),
      complexityDescription: toString(get(json, "complexityDescription")),
      startedDate: toString(get(json, "startedDate")),
      submissionDate: toString(get(json, "submissionDate")),
      modifiedDate: toString(get(json, "modifiedDate")),
      applicationNumber: toString(get(json, "applicationNumber")),
      applicationFormPdf: toString(get(json, "applicationFormPdf")),
      applicationStatusLabel: toString(get(json, "applicationStatusLabel")),
      userId: toInteger(get(json, "userId")),
      status: ApplicationStatusUtil.parse(toString(get(json, "status"))),
      participantIds: participants.map((participant) => participant.id),
      formId: toInteger(get(json, "formId")),
      description: toString(get(json, "description")),
      address: address,
      legalDescription: toString(get(json, "legalDescription")),
      etag: toString(get(json, "etag")),
      formDisplayName: toString(get(json, "formDisplayName")),
      accessLevel: parseParticipantAccessLevelString(toString(get(json, "accessLevel"))),
      notificationLevel: NotificationSubscriptionLevelUtil.parseSubscriptionLevel(
        toString(get(json, "notificationLevel"))
      ),
      primaryContactId: toInteger(get(json, "primaryContactId")),
      isParticipant: Boolean(get(json, "isParticipant")),
      qualificationTypes: toArray(get(json, "qualificationTypes")),
      buildingPurposeDescription: toString(get(json, "buildingPurposeDescription")),
      workflowStages: parseWorkflowStages(get(json, "displayStages")),
      hasRFI: Boolean(get(json, "hasRFI")),
      hasOutstandingRFIs: Boolean(get(json, "hasOutstandingRFIs")),
      isHidden: Boolean(get(json, "isHidden")),
      hasOutstandingPayments: Boolean(get(json, "hasOutstandingPayments")),
      userSignature: toString(get(json, "userSignature")),
      closedReason: toString(get(json, "closedReason")),
      migrationSource: toString(get(json, "migrationSource")),
      developmentContributionRequired: Boolean(get(json, "developmentContributionRequired")),
      resourceConsentRequired: Boolean(get(json, "resourceConsentRequired")),
      useRequiredDocumentGroups: Boolean(get(json, "useRequiredDocumentGroups")),
      useMultipleRfiDocuments: Boolean(get(json, "useMultipleRfiDocuments")),
    };

    const requiredDocuments = this.parseRequiredDocuments(applicationId, get(json, "requiredDocuments"));
    const requiredInspections = this.parseRequiredInspections(applicationId, get(json, "inspectionTypes"));
    const template = this.parseApplicationTemplate(json);

    return { application, participants, requiredDocuments, requiredInspections, template };
  }

  private parseRequiredDocuments(applicationId: number, requiredDocumentsJson?: string): IRequiredDocumentEntity[] {
    if (!requiredDocumentsJson || !Array.isArray(requiredDocumentsJson)) {
      return [];
    }

    return requiredDocumentsJson.map((documentJson) => this.parseRequiredDocument(applicationId, documentJson));
  }

  private parseRequiredDocument(applicationId: number, documentJson: string): IRequiredDocumentEntity {
    return {
      applicationId,
      id: toNumber(get(documentJson, "id")),
      documentGuid: toString(get(documentJson, "documentGuid")),
      category: toString(get(documentJson, "category")),
      name: toString(get(documentJson, "name")),
      description: toString(get(documentJson, "description")),
      status: RequiredDocumentStatusUtil.parse(toString(get(documentJson, "status"))),
      documentContainerId: toNumber(get(documentJson, "documentContainerId")),
      receivedDate: toString(get(documentJson, "receivedDate")),
      acceptedDate: toString(get(documentJson, "acceptedDate")),
      fileName: toString(get(documentJson, "fileName")),
      fileSize: toNumber(get(documentJson, "fileSize")),
      containerMergeStatus: MergeStatusUtil.parse(toString(get(documentJson, "containerMergeStatus"))),
    };
  }

  private parseRequiredInspectionHistory(inspectionHistoryJson: any): IRequiredInspectionHistoryEntity {
    return {
      inspectionDate: toString(get(inspectionHistoryJson, "inspectionDate")),
      inspectionName: toString(get(inspectionHistoryJson, "inspectionName")),
      inspectionResult: InspectionStatusUtil.parse(toString(get(inspectionHistoryJson, "inspectionResult"))),
      inspectionNotice: toString(get(inspectionHistoryJson, "inspectionNotice")),
    };
  }

  private parseRequiredInspectionType(inspectionTypeJson: string): IRequiredLocationInspectionTypeEntity {
    return {
      location: toString(get(inspectionTypeJson, "location")),
      inspectionTypeId: toString(get(inspectionTypeJson, "inspectionTypeId")),
      inspectionStatus: InspectionTypeStatusUtil.parse(toString(get(inspectionTypeJson, "inspectionStatus"))),
      inspectionTypeName: toString(get(inspectionTypeJson, "inspectionTypeName")),
      inspectionsRequired: toNumber(get(inspectionTypeJson, "inspectionsRequired")),
      nextBookingDate: toString(get(inspectionTypeJson, "nextBookingDate")),
      inspectionHistory: toArray(get(inspectionTypeJson, "inspectionHistory")).map((inspectionHistoryJson) =>
        this.parseRequiredInspectionHistory(inspectionHistoryJson)
      ),
      sequence: toInteger(get(inspectionTypeJson, "sequence")),
    };
  }

  private parseRequiredInspections(applicationId: number, inspectionTypesJson?: string): IRequiredInspectionsEntity[] {
    if (!inspectionTypesJson || !Array.isArray(inspectionTypesJson)) {
      return [];
    }

    const locationInspectionTypes = toArray(inspectionTypesJson).map((inspectionTypeJson) =>
      this.parseRequiredInspectionType(inspectionTypeJson)
    );

    const locations = Array.from(
      new Set(locationInspectionTypes.map((locationInspectionType) => locationInspectionType.location))
    );
    locations.sort((a, b) => a.localeCompare(b));
    const inspectionTypesByLocation = locations.map((location) => {
      return {
        applicationId,
        location,
        inspectionTypes: locationInspectionTypes
          .filter((locationInspectionType) => locationInspectionType.location === location)
          .map((locationInspectionType) => {
            const { location, ...inspectionType } = locationInspectionType;
            return inspectionType;
          }),
      };
    });

    return inspectionTypesByLocation;
  }
}
