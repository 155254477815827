import React from "react";

type Props = {
  scrollElement?: HTMLElement;
  children: (args: { scrollPosition: number }) => React.ReactNode;
};

// This component will listen to the scroll event of a given element, and provide scroll position to its children
const ScrollPositionListener: React.FC<Props> = ({ scrollElement, children }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = (e: any) => {
      const position = e.target.scrollTop;
      setScrollPosition(position);
    };

    scrollElement?.addEventListener("scroll", handleScroll);
    return () => {
      scrollElement?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollElement]);

  return <React.Fragment>{children({ scrollPosition })}</React.Fragment>;
};

export default ScrollPositionListener;
