import { IApplicationFilterService } from "services/application-filter/ApplicationFilterService.type";
import {
  ApplicationFilterGroup,
  IApplicationFilters,
  IApplicationFilterItem,
} from "components/ApplicationFilter/ApplicationFilter.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";

export class ApplicationFilterService implements IApplicationFilterService {
  private readonly apiClient: Cradle["apiClient"];
  private readonly i18n: Cradle["i18n"];

  constructor(args: Pick<Cradle, "apiClient" | "i18n">) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }
  public async fetchApplicationFilters(): Promise<IApplicationFilters> {
    const response = await this.apiClient.protectedApi.get(`/user/applicationsV2/filters`);
    return this.parseApplicationFilters(response.data);
  }

  private parseApplicationFilters(json: any): IApplicationFilters {
    const formFiltersJSON = get(json, "forms");
    const authorityFiltersJSON = get(json, "authorities");
    const statusFiltersJSON = get(json, "statuses");
    const complexityFiltersJSON = get(json, "complexities");
    const outstandingActionFiltersJSON = get(json, "outstandingActions");

    const isJSONInvalid = [
      formFiltersJSON,
      authorityFiltersJSON,
      statusFiltersJSON,
      complexityFiltersJSON,
      outstandingActionFiltersJSON,
    ].some((arr) => !Array.isArray(arr));

    if (isJSONInvalid) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch application filters`));
    }

    const getSortedApplicationFilterGroups = (items: any[], applicationFilterGroup: ApplicationFilterGroup) => {
      const filterItems = items.map(
        (item: any): IApplicationFilterItem => {
          return {
            id: toString(get(item, "id")),
            name: toString(get(item, "name")),
            group: applicationFilterGroup,
          };
        }
      );
      return sortBy(filterItems, (item) => item.name);
    };

    const sortedFormFilters = getSortedApplicationFilterGroups(formFiltersJSON, ApplicationFilterGroup.Form);
    const sortedAuthorityFilters = getSortedApplicationFilterGroups(authorityFiltersJSON, ApplicationFilterGroup.BCA);
    const sortedStatusFilters = getSortedApplicationFilterGroups(statusFiltersJSON, ApplicationFilterGroup.Status);
    const sortedComplexityFilters = getSortedApplicationFilterGroups(
      complexityFiltersJSON,
      ApplicationFilterGroup.Complexity
    );
    const sortedOutstandingActionFilters = getSortedApplicationFilterGroups(
      outstandingActionFiltersJSON,
      ApplicationFilterGroup.OutstandingAction
    );

    return {
      forms: sortedFormFilters,
      authorities: sortedAuthorityFilters,
      statuses: sortedStatusFilters,
      complexities: sortedComplexityFilters,
      outstandingActions: sortedOutstandingActionFilters,
    };
  }
}
