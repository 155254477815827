const markPageRefreshed = () => {
  window.sessionStorage.setItem(STORAGE_KEY_IS_PAGE_REFRESHED, "true");
};

const getIsPageRefreshed = () => {
  const storedValue = window.sessionStorage.getItem(STORAGE_KEY_IS_PAGE_REFRESHED);
  if (!storedValue) {
    return false;
  }

  return storedValue === "true";
};

const STORAGE_KEY_IS_PAGE_REFRESHED = "objective.build.is_page_refreshed";

export const pageRefreshUtil = {
  markPageRefreshed,
  getIsPageRefreshed,
};
