import { IAddressCheckerService } from "services/address-checker/AddressCheckerService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toString from "lodash/toString";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { IAddressSuggestionEntity, IExtendedAddressEntity } from "models/Address.model";
import toNumber from "lodash/toNumber";

export class NZPostAddressCheckerService implements IAddressCheckerService {
  private apiClient: Cradle["apiClient"];
  private i18n: Cradle["i18n"];

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  async search(keywords: string): Promise<IAddressSuggestionEntity[]> {
    try {
      const response = await this.apiClient.protectedApi.get(
        `/user/nzpost/addresschecker/suggest?type=Physical&q=${keywords}`
      );
      this.validateResponseJson(response.data);

      const items = get(response.data, "data.addresses");
      const suggestions = items.map((item: any) => {
        return {
          identifier: toString(get(item, "DPID")),
          fullAddress: toString(get(item, "FullAddress")),
        } as IAddressSuggestionEntity;
      });
      return suggestions;
    } catch (e) {
      throw new ServiceError(
        ServiceErrorCode.ServerError,
        this.i18n.t(`Fail to provide suggestions based on your search`)
      );
    }
  }

  async lookup(identifier: string): Promise<IExtendedAddressEntity> {
    try {
      const response = await this.apiClient.protectedApi.get(
        `/user/nzpost/addresschecker/details?type=Physical&dpid=${identifier}`
      );
      this.validateResponseJson(response.data);

      const item = get(response.data, "data.details.0");

      const fullAddress = [
        toString(get(item, "AddressLine1")),
        toString(get(item, "AddressLine2")),
        toString(get(item, "AddressLine3")),
        toString(get(item, "AddressLine4")),
        toString(get(item, "AddressLine5")),
      ]
        .filter((str) => str.length > 0)
        .join(", ");

      const coordinates = get(item, "NZGD2kCoord.coordinates");
      const longitude = toNumber(get(coordinates, "0")) || 0;
      const latitude = toNumber(get(coordinates, "1")) || 0;

      return {
        address1: toString(get(item, "AddressLine1")),
        address2: toString(get(item, "AddressLine2")),
        city: toString(get(item, "CityTown")),
        country: "NZ",
        state: "",
        zipCode: toString(get(item, "Postcode")),
        isManualAddress: false,
        fullAddress,
        longitude,
        latitude,
      };
    } catch (e) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Fail to retrieve the address information`));
    }
  }

  private validateResponseJson(json: any) {
    if (!Boolean(get(json, "data.success"))) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Fail to connect to NZ Post service`));
    }
  }
}
