import React from "react";
import styled, { css } from "styled-components/macro";

type Props = {};

const PanelInner = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(({ children }, ref) => {
  return <StyledPanelContainerInner ref={ref}>{children}</StyledPanelContainerInner>;
});

const StyledPanelContainerInner = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.light.white};
    border: 1px solid ${theme.palette.objective.light.allspice};
    border-radius: 4px;
    overflow: hidden;
  `
);

export default PanelInner;
