import React from "react";
import { BoxProps } from "@material-ui/core";
import Box from "components/Box/Box";
import clsx from "clsx";

type Props = BoxProps & {
  name: string;
};

// This component renders a Span with icon class names by default
//   All props can be overwritten by passing props (BoxProps)
const FontIcon: React.FC<Props> = ({ name, className, ...restProps }) => {
  // Allow using both full icon name (icon-view-dashboard) and short name (view-dashboard)
  const iconName = name.startsWith("icon") ? name : `icon-${name}`;

  return <Box className={clsx("icon", iconName, className)} component="span" {...restProps} />;
};

export default FontIcon;
