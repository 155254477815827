import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { createDeepEqualSelector } from "store/utils";
import { RootState } from "store/types";
import { IBreadcrumbEntity } from "models/Breadcrumb.model";

// Entity Adapter

const breadcrumbAdapter = createEntityAdapter<IBreadcrumbEntity>({
  selectId: (breadcrumb) => breadcrumb.id,
  sortComparer: (a, b) => {
    if (a.jurisdictionFormId !== b.jurisdictionFormId) {
      return a.jurisdictionFormId - b.jurisdictionFormId;
    }

    return a.displayOrder - b.displayOrder;
  },
});

// Action & Thunks

export const loadBreadcrumbs = createAction<IBreadcrumbEntity[]>("domainData/breadcrumb/loadBreadcrumbs");

// Reducer

export const defaultBreadcrumbState = breadcrumbAdapter.getInitialState();

export const breadcrumbReducer = createReducer<typeof defaultBreadcrumbState>(defaultBreadcrumbState, (builder) =>
  builder.addCase(loadBreadcrumbs, (draft, action) => {
    breadcrumbAdapter.upsertMany(draft, action.payload);
  })
);

// Selectors

export const {
  selectById: selectBreadcrumbEntityById,
  selectIds: selectBreadcrumbEntityIds,
  selectEntities: selectBreadcrumbEntities,
  selectAll: selectAllBreadcrumbEntities,
  selectTotal: selectTotalBreadcrumbEntities,
} = breadcrumbAdapter.getSelectors((state: RootState) => state.domainData.breadcrumb);

export const selectBreadcrumbEntitiesByJurisdictionFormId = createDeepEqualSelector(
  [selectAllBreadcrumbEntities, (state: RootState, jurisdictionFormId: number) => jurisdictionFormId],
  (entities, jurisdictionFormId) => {
    return entities.filter((entity) => entity.jurisdictionFormId === jurisdictionFormId);
  }
);
