import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { IParticipantEntity } from "models/Participant.model";
import { createDeepEqualSelector } from "store/utils";
import { serviceContainer } from "services/serviceContainer";
import {
  selectAllParticipantTypeEntities,
  selectParticipantTypesBeingManagedByCurrentUser,
} from "store/domain-data/participant-type/participantType";
import isEmpty from "lodash/isEmpty";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import { fetchApplicationById, selectApplicationEntityById } from "store/domain-data/application/application";

// Entity Adapter

const participantAdapter = createEntityAdapter<IParticipantEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Action & Thunks

export const fetchParticipants = createAsyncThunk(
  "domainData/participants/fetchParticipants",
  async ({ applicationId }: { applicationId: number }) => {
    const participants = await serviceContainer.cradle.participantService.fetchParticipantsByApplicationId(
      applicationId
    );
    return participants;
  }
);

export const fetchParticipant = createAsyncThunk(
  "domainData/participants/fetchParticipant",
  async ({ applicationId, participantId }: { applicationId: number; participantId: number }) => {
    const participant = await serviceContainer.cradle.participantService.fetchParticipant(applicationId, participantId);
    return participant;
  }
);

export const loadParticipants = createAction<IParticipantEntity[]>("domainData/participants/loadParticipants");

export const removeParticipantFromApplication = createAsyncThunk(
  "domainData/participants/removeParticipantFromApplication",
  async ({ applicationId, participantId }: { applicationId: number; participantId: number }) => {
    await serviceContainer.cradle.participantService.removeParticipantFromApplication(applicationId, participantId);
    return participantId;
  }
);

export const removeParticipantsByApplicationId = createAction<number>(
  "domainData/participants/removeParticipantsByApplicationId"
);

export const createParticipant = createAsyncThunk(
  "domainData/participants/createParticipant",
  async ({ applicationId, payload }: { applicationId: number; payload: Object }) => {
    const participant = await serviceContainer.cradle.participantService.createParticipant(applicationId, payload);
    return participant;
  }
);

export const updateParticipant = createAsyncThunk(
  "domainData/participants/updateParticipant",
  async ({
    applicationId,
    participantId,
    payload,
  }: {
    applicationId: number;
    participantId: number;
    payload: Object;
  }) => {
    const participant = await serviceContainer.cradle.participantService.updateParticipant(
      applicationId,
      participantId,
      payload
    );
    return participant;
  }
);

export const sendParticipantInvite = createAsyncThunk(
  "domainData/participants/sendParticipantInvite",
  async ({ applicationId, participantId }: { applicationId: number; participantId: number }) => {
    const participant = await serviceContainer.cradle.participantService.sendParticipantInvite(
      applicationId,
      participantId
    );
    return participant;
  }
);

export const updateApplicationNotificationSubscriptionLevel = createAsyncThunk(
  "domainData/userInfo/updateDefaultApplicationNotificationSubscriptionLevel",
  async (
    {
      level,
      applicationId,
      participantId,
    }: {
      level: NotificationLevel;
      applicationId: number;
      participantId: number;
    },
    thunkAPI
  ) => {
    await serviceContainer.cradle.participantService.updateParticipantNotificationLevel(
      applicationId,
      participantId,
      level
    );
    await thunkAPI.dispatch(fetchApplicationById(applicationId));
  }
);

// Reducer

export const defaultParticipantState = participantAdapter.getInitialState();

export const participantReducer = createReducer<typeof defaultParticipantState>(defaultParticipantState, (builder) =>
  builder
    .addCase(fetchParticipants.fulfilled, participantAdapter.upsertMany)
    .addCase(fetchParticipant.fulfilled, participantAdapter.upsertOne)
    .addCase(loadParticipants, participantAdapter.upsertMany)
    .addCase(removeParticipantFromApplication.fulfilled, participantAdapter.removeOne)
    .addCase(removeParticipantsByApplicationId, (draft, action) => {
      const applicationId = action.payload;
      const keys = Object.keys(draft.entities);
      for (const key of keys) {
        const relation = draft.entities[key];
        if (relation?.applicationId === applicationId) {
          participantAdapter.removeOne(draft, key);
        }
      }
    })
    .addCase(createParticipant.fulfilled, participantAdapter.upsertOne)
    .addCase(updateParticipant.fulfilled, participantAdapter.upsertOne)
    .addCase(sendParticipantInvite.fulfilled, participantAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectParticipantEntityById,
  selectIds: selectParticipantEntityIds,
  selectEntities: selectParticipantEntities,
  selectAll: selectAllParticipantEntities,
  selectTotal: selectTotalContactEntities,
} = participantAdapter.getSelectors((state: RootState) => state.domainData.participant);

export const selectParticipantEntitiesByApplicationId = createDeepEqualSelector(
  [(_: RootState, applicationId: number) => applicationId, selectAllParticipantEntities],
  (applicationId, allEntities) => {
    return Object.values(allEntities).filter((participant) => participant.applicationId === applicationId);
  }
);

export const selectParticipantUserByApplicationId = createDeepEqualSelector(
  [selectUserInfoEntityForAuthenticatedUser, selectParticipantEntitiesByApplicationId],
  (userInfo, participants) => {
    return participants.find((participant) => participant.loginUserId === userInfo?.id);
  }
);

type SelectEmailExistsOnOtherApplicationParticipantProps = {
  applicationId: number;
  participantId: number;
  participantEmail: string;
};

export const selectEmailExistsOnOtherApplicationParticipant = createDeepEqualSelector(
  [
    (state: RootState, props: SelectEmailExistsOnOtherApplicationParticipantProps) =>
      selectParticipantEntitiesByApplicationId(state, props.applicationId),
    (state: RootState, props: SelectEmailExistsOnOtherApplicationParticipantProps) => props.participantId,
    (state: RootState, props: SelectEmailExistsOnOtherApplicationParticipantProps) => props.participantEmail,
  ],
  (allParticipants, participantId, participantEmail): boolean => {
    const participantEmailAlreadyExists = allParticipants.some((participant) => {
      if (participantId === participant.id) {
        return false;
      }

      return participantEmail && participantEmail.trim().toLowerCase() === participant.email.toLowerCase();
    });

    return participantEmailAlreadyExists;
  }
);

export const selectCurrentUserManagesOtherParticipants = createDeepEqualSelector(
  [(state: RootState, applicationId: number) => selectParticipantTypesBeingManagedByCurrentUser(state, applicationId)],
  (participantTypes) => {
    return !isEmpty(participantTypes);
  }
);

export const selectParticipantsWithRequiredTypes = createDeepEqualSelector(
  [(state: RootState, applicationId: number) => selectParticipantEntitiesByApplicationId(state, applicationId)],
  (participants) => {
    return participants.filter((participant) => {
      return participant.participantTypes.some((type) => type.required);
    });
  }
);

export const selectParticipantsWithNoRequiredTypes = createDeepEqualSelector(
  [(state: RootState, applicationId: number) => selectParticipantEntitiesByApplicationId(state, applicationId)],
  (participants) => {
    return participants.filter((participant) => {
      return !participant.participantTypes.some((type) => type.required);
    });
  }
);

export const selectPrimaryContactParticipantByApplicationId = createDeepEqualSelector(
  [
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
    (state: RootState, applicationId: number) => selectParticipantEntitiesByApplicationId(state, applicationId),
  ],
  (application, participants) => {
    if (!application) {
      return;
    }

    const participant = participants.find((participant) => participant.id === application.primaryContactId);
    return participant;
  }
);

export const selectCanManageParticipantNotificationPreferenceForApplicationId = createDeepEqualSelector(
  [
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
    (state: RootState, applicationId: number) => selectPrimaryContactParticipantByApplicationId(state, applicationId),
    (state: RootState) => selectUserInfoEntityForAuthenticatedUser(state),
  ],
  (application, primaryContactParticipant, userInfo) => {
    // If not a participant, can't manage notification preference
    if (!application?.isParticipant) {
      return false;
    }

    // If is primary contact, can't manage notification preference
    if (primaryContactParticipant?.loginUserId === userInfo?.id) {
      return false;
    }

    return true;
  }
);

export const selectParticipantEnableGroups = createDeepEqualSelector(
  [selectParticipantEntityById, (state: RootState) => selectAllParticipantTypeEntities(state)],
  (participant, applicationParticipantTypes) => {
    if (!participant) {
      return false;
    }

    const participantTypes = participant.participantTypes;

    const isGroupEnabled = participantTypes.some((participantType) =>
      applicationParticipantTypes.some(
        (appParticipantType) => appParticipantType.enableGroups && appParticipantType.name === participantType.name
      )
    );
    return isGroupEnabled;
  }
);

export const selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId = createDeepEqualSelector(
  [
    selectUserInfoEntityForAuthenticatedUser,
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
    selectAllParticipantEntities,
  ],
  (userInfo, application, participants) => {
    if (!application || !userInfo || !participants) {
      return null;
    }

    const userParticipantTypes = participants
      .find((participant) => participant.loginUserId === userInfo.id)
      ?.participantTypes.map((participantType) => participantType.name)
      .join(",");

    return userParticipantTypes;
  }
);
