import React from "react";
import { useStoreSelector } from "store/hooks";
import { selectAnnouncementBasedOnLocation } from "store/domain-data/announcement/announcement";
import AnnouncementAlert from "components/AnnouncementAlert/AnnouncementAlert";
import isEmpty from "lodash/isEmpty";
import FlexBox from "components/FlexBox/FlexBox";
import { AnnouncementLocation } from "models/Announcement.model";

type Props = {
  location: AnnouncementLocation;
};
const Announcements: React.FC<Props> = ({ location }) => {
  const announcements = useStoreSelector((state) => selectAnnouncementBasedOnLocation(state, location));

  if (isEmpty(announcements)) {
    return null;
  }
  return (
    <FlexBox spacing={3} direction={"column"}>
      {announcements && announcements.map((alert) => <AnnouncementAlert key={alert.id} id={alert.id} />)}
    </FlexBox>
  );
};

export default Announcements;
