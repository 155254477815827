import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectApplicationDocumentContainerRelationsByCriteria } from "store/domain-data/application-document-containers-relation/applicationDocumentContainersRelation";
import { fetchApplicationDocumentContainers } from "store/domain-data/document-containers/documentContainers";
import styled, { css } from "styled-components/macro";
import { DocumentContainerType } from "models/DocumentContainer.model";
import {
  fetchApplicationDocuments,
  selectApplicationDocumentRelationsByApplicationId,
} from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { selectDocumentEntitiesByApplicationId } from "store/domain-data/document/document";
import isEmpty from "lodash/isEmpty";
import EmptyText from "components/odl-v2/EmptyText/Emptytext";
import { useTranslation } from "react-i18next";
import DocumentCard from "components/DocumentCard/DocumentCard";
import Cards from "components/odl-v2/Card/Cards";

type Props = {
  applicationId: number;
};

const DocumentUploadStepContent: React.FC<Props> = ({ applicationId }) => {
  // Common
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();

  // Data
  const applicationDocumentContainerRelations = useStoreSelector((state) =>
    selectApplicationDocumentContainerRelationsByCriteria(state, {
      applicationId,
      documentContainerType: DocumentContainerType.DocumentUploadStep,
    })
  );
  const applicationDocumentRelations = useStoreSelector((state) =>
    selectApplicationDocumentRelationsByApplicationId(state, applicationId)
  );
  const documents = useStoreSelector((state) => selectDocumentEntitiesByApplicationId(state, applicationId));

  const filteredDocuments = React.useMemo(() => {
    const validDocumentContainerIds = applicationDocumentContainerRelations.map(
      (relation) => relation.documentContainerId
    );
    const getDocumentContainerIdForDocument = (documentName: string) => {
      const applicationDocumentRelation = applicationDocumentRelations.find(
        (relation) => relation.documentName === documentName
      );
      const documentContainerId = applicationDocumentRelation?.documentContainerId || 0;
      return documentContainerId;
    };

    return documents
      .filter((document) => {
        const documentContainerId = getDocumentContainerIdForDocument(document.name);
        return validDocumentContainerIds.includes(documentContainerId);
      })
      .sort((documentA, documentB) => {
        const containerIdA = getDocumentContainerIdForDocument(documentA.name);
        const containerIdB = getDocumentContainerIdForDocument(documentB.name);
        return validDocumentContainerIds.indexOf(containerIdA) - validDocumentContainerIds.indexOf(containerIdB);
      });
  }, [documents, applicationDocumentContainerRelations, applicationDocumentRelations]);

  // Effects
  React.useEffect(() => {
    dispatch(fetchApplicationDocumentContainers(applicationId));
    dispatch(fetchApplicationDocuments(applicationId));
  }, [dispatch, applicationId]);

  if (isEmpty(filteredDocuments)) {
    return <EmptyText>{t(`No documents`)}</EmptyText>;
  }

  return (
    <Cards>
      {filteredDocuments.map((document) => (
        <StyledCardContainer key={document.name}>
          <DocumentCard
            fileName={document.fileName}
            fileSize={document.fileSize}
            uploadStatus={document.uploadStatus}
            fileModifiedDate={document.fileModifiedDate}
          />
        </StyledCardContainer>
      ))}
    </Cards>
  );
};

const StyledCardContainer = styled.div(
  ({ theme }) => css`
    // Flexbox does not support "break-inside", but we need to avoid page break when print
    display: block;
    break-inside: avoid;
  `
);

export default DocumentUploadStepContent;
