import React from "react";
import Box from "components/Box/Box";
import AppHeader from "components/AppHeader/AppHeader";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";

type Props = {};

const MainLayout: React.FC<Props> = ({ children }) => {
  const screenWidthMatch = useScreenWidthMatch();

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" minHeight="100vh" maxHeight="100vh">
      <Box flexGrow={0} flexShrink={0}>
        <AppHeader />
      </Box>
      <Box flexGrow={1} display="flex" overflow="hidden">
        <Box component="main" minWidth={screenWidthMatch.sm ? 720 : 0} flexGrow={1} overflow="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
