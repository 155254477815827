import { DocumentTypeRelationUtil, IDocumentTypeRelation } from "models/DocumentTypeRelation.model";
import { createAction, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { createDeepEqualSelector } from "store/utils";
import { RootState } from "store/types";

// This is the join table between Document and Document Type
const documentTypeRelationAdapter = createEntityAdapter<IDocumentTypeRelation>({
  selectId: (relation) => DocumentTypeRelationUtil.generateKey(relation),
  sortComparer: (a, b) => {
    if (a.documentName.localeCompare(b.documentName) !== 0) {
      return a.documentName.localeCompare(b.documentName);
    }
    return a.documentTypeId - b.documentTypeId;
  },
});

// Action & Thunks

export const upsertDocumentTypeRelation = createAction<IDocumentTypeRelation[]>(
  "domainData/documentTypeRelation/upsertDocumentTypeRelation"
);
export const upsertDocumentTypeRelations = createAction<IDocumentTypeRelation[]>(
  "domainData/documentTypeRelation/upsertDocumentTypeRelations"
);

export const removeDocumentTypeRelation = createAction<IDocumentTypeRelation>(
  "domainData/documentTypeRelation/removeDocumentTypeRelation"
);

export const removeDocumentTypeRelations = createAction<IDocumentTypeRelation[]>(
  "domainData/documentTypeRelation/removeDocumentTypeRelations"
);

// Reducer
export const documentTypeRelationReducer = createReducer(documentTypeRelationAdapter.getInitialState(), (builder) =>
  builder
    .addCase(upsertDocumentTypeRelation, (draft, action) => {
      documentTypeRelationAdapter.upsertMany(draft, action.payload);
    })
    .addCase(upsertDocumentTypeRelations, (draft, action) => {
      documentTypeRelationAdapter.upsertMany(draft, action.payload);
    })
    .addCase(removeDocumentTypeRelation, (draft, action) => {
      documentTypeRelationAdapter.removeOne(draft, DocumentTypeRelationUtil.generateKey(action.payload));
    })
    .addCase(removeDocumentTypeRelations, (draft, action) => {
      documentTypeRelationAdapter.removeMany(draft, action.payload.map(DocumentTypeRelationUtil.generateKey));
    })
);

// Selectors
export const {
  selectById: selectDocumentTypeRelationByKey,
  selectAll: selectAllDocumentTypeRelations,
} = documentTypeRelationAdapter.getSelectors((state: RootState) => state.domainData.documentTypeRelation);

export const selectDocumentTypeRelationsByDocumentName = createDeepEqualSelector(
  [selectAllDocumentTypeRelations, (state: RootState, documentName: string) => documentName],
  (relations, documentName) => relations.filter((relation) => relation.documentName === documentName)
);

export const selectDocumentTypeRelationsByTypeId = createDeepEqualSelector(
  [selectAllDocumentTypeRelations, (state: RootState, documentTypeId: number) => documentTypeId],
  (relations, documentTypeId) => relations.filter((relation) => relation.documentTypeId === documentTypeId)
);
