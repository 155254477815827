import React from "react";
import applicationIconAttention from "assets/images/application-icon-attention.svg";
import applicationIconConsent from "assets/images/application-icon-consent.svg";
import applicationIconComplete from "assets/images/application-icon-complete.svg";
import applicationIconDefault from "assets/images/application-icon-default.svg";
import Guard from "components/Guard/Guard";
import FlexBox from "components/FlexBox/FlexBox";
import { ApplicationRecordType } from "models/Application.model";
import { ApplicationStatus } from "models/ApplicationStatus.model";

type Props = {
  showAttentionIcon?: boolean;
  recordType: ApplicationRecordType;
  status: ApplicationStatus;
};

const ApplicationCardIcon: React.FC<Props> = ({ showAttentionIcon, recordType, status }) => {
  if (showAttentionIcon) {
    return (
      <FlexBox data-testid={"ApplicationCardIcon"}>
        <img
          src={applicationIconAttention}
          alt={"Icon - Application requires attention"}
          data-testid={"ApplicationIconAttention"}
        />
      </FlexBox>
    );
  }

  if (status === ApplicationStatus.Completed) {
    return (
      <FlexBox data-testid={"ApplicationCardIcon"}>
        <img
          src={applicationIconComplete}
          alt={"Icon - Application requires attention"}
          data-testid={"ApplicationIconComplete"}
        />
      </FlexBox>
    );
  }

  return (
    <FlexBox data-testid={"ApplicationCardIcon"}>
      <Guard condition={recordType === ApplicationRecordType.Consent}>
        <img src={applicationIconConsent} alt={"Icon - Application"} data-testid={"ApplicationIconConsent"} />
      </Guard>
      <Guard condition={recordType === ApplicationRecordType.Draft}>
        <img src={applicationIconDefault} alt={"Icon - Application"} data-testid={"ApplicationIconDefault"} />
      </Guard>
    </FlexBox>
  );
};

export default ApplicationCardIcon;
