import React from "react";
import { Button } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import { focusMixin } from "components/odl-v2/Button/mixins/focusMixin";
import { disabledMixin } from "components/odl-v2/Button/mixins/disabledMixin";
import { commonMixin } from "components/odl-v2/Button/mixins/commonMixin";
import { ButtonProps } from "components/odl-v2/Button/Button";
import { iconMixin } from "components/odl-v2/Button/mixins/iconMixin";
import { hideInPrintMixin } from "components/odl-v2/Button/mixins/hideInPrintMixin";

// Omit props that we don't support to avoid them being used
type Props = Omit<ButtonProps, "variant">;

const ContainedButton: React.FC<Props> = React.forwardRef(({ size, ...props }, ref) => {
  return (
    <StyledButton
      ref={ref}
      variant={"contained"}
      disableRipple={true}
      disableElevation={true}
      disableFocusRipple={true}
      $size={size}
      {...props}
    />
  );
});

const StyledButton = styled(Button)<{ $size: ButtonProps["size"] }>(
  ({ theme, disabled, $size = "regular" }) => css`
    ${commonMixin($size)}
    ${focusMixin("contained", $size)}
    ${disabledMixin(disabled)}
    ${iconMixin()}
    ${hideInPrintMixin()}
    
    color: ${theme.palette.objective.light.white};
    background-color: ${theme.palette.objective.blue.main};

    &:hover {
      color: ${theme.palette.objective.light.white};
      background-color: ${theme.palette.objective.blue.dark};
    }

    &:active {
      color: ${theme.palette.objective.light.white};
      background-color: ${theme.palette.objective.blue.darker};
    }
  `
);

export default ContainedButton;
