import { IPersonSearchService, PersonSearchLibraries } from "./PersonSearchService.types";
import { Cradle } from "../serviceContainer.types";
import get from "lodash/get";
import { ServiceError, ServiceErrorCode } from "../ServiceError";
import { AddressUtil, IAddressEntity } from "models/Address.model";
import toString from "lodash/toString";
import {
  IParticipantLookupResponse,
  IParticipantPersonalInfoFormData,
  IParticipantSearchData,
} from "models/Participant.model";
import uniq from "lodash/uniq";
import { IQualificationEntity, IQualificationWarning } from "models/Qualification.model";
import isEmpty from "lodash/isEmpty";

export class PersonSearchService implements IPersonSearchService {
  private apiClient: Cradle["apiClient"];
  private i18n: Cradle["i18n"];

  private static INACTIVE_STATUS_REASONS = new Map<string, string>([
    ["Approved", "Approved"],
    ["Cancelled", "Cancelled"],
    ["Cancelled_Disciplinary", "Cancelled (Disciplinary)"],
    ["Cancelled_Voluntary", "Cancelled (Voluntary)"],
    ["Cancelled_Relicensing", "Cancelled (Relicensing)"],
    ["Createdinerror", "Created in Error"],
    ["Declined", "Declined"],
    ["Duplicate", "Duplicate"],
    ["Ineligible", "Ineligible"],
    ["Lapsed", "Lapsed"],
    ["Other", "Other"],
    ["Pending", "Pending"],
    ["Removed", "Removed"],
    ["Superseded", "Superseded"],
    ["Suspended_Disciplinary", "Suspended (Disciplinary)"],
    ["Suspended_Relicensing", "Suspended (Relicensing)"],
    ["Suspended_Voluntary", "Suspended (Voluntary)"],
    ["Upgraded", "Upgraded"],
    ["Withdrawn", "Withdrawn"],
  ]);

  constructor(args: { apiClient: Cradle["apiClient"]; i18n: Cradle["i18n"] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  async search(args: { library: PersonSearchLibraries; keywords: string }): Promise<IParticipantSearchData[]> {
    switch (args.library) {
      case PersonSearchLibraries.ContactLibrary:
        return this.searchContacts(args.keywords);
      case PersonSearchLibraries.LBP:
        return this.searchLbp(args.keywords);
    }
  }

  async find(args: { library: PersonSearchLibraries; identifier: string }): Promise<IParticipantLookupResponse> {
    switch (args.library) {
      case PersonSearchLibraries.ContactLibrary:
        return this.findContact(args.identifier);
      case PersonSearchLibraries.LBP:
        return this.findLbp(args.identifier);
    }
  }

  async updateQualification(args: {
    library: PersonSearchLibraries;
    identifier: string;
  }): Promise<IQualificationEntity[]> {
    switch (args.library) {
      case PersonSearchLibraries.ContactLibrary:
        return this.updateQualificationContact(args.identifier);
      case PersonSearchLibraries.LBP:
        return this.updateQualificationLbp(args.identifier);
    }
  }

  private async searchContacts(keywords: string): Promise<IParticipantSearchData[]> {
    const response = await this.apiClient.protectedApi.get(
      `/user/organisation/contacts?page_size=10&search_text=${keywords}`
    );
    const jsonArr = get(response.data, "contacts");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch contacts`));
    }

    const searchData: IParticipantSearchData[] = jsonArr
      .map((json) => this.parseParticipantSearch(json))
      .sort((a, b) => a.name.localeCompare(b.name));
    return searchData;
  }

  private async findContact(id: string): Promise<IParticipantLookupResponse> {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/contacts/${id}`);
    const json = response.data;
    const contact = this.parseParticipant(json);
    const qualificationArr = get(json, "qualifications");
    if (!Array.isArray(qualificationArr)) {
      return {
        ...contact,
        qualifications: [],
      };
    }
    const qualifications = qualificationArr.map((qualification) => {
      return {
        qualificationName: toString(get(qualification, "qualificationName")),
        licensingClasses: get(qualification, "licensingClasses"),
        otherQualificationType: toString(get(qualification, "otherQualificationType")),
        qualificationNumber: toString(get(qualification, "qualificationNumber")),
        warnings: [],
      };
    });
    return {
      ...contact,
      qualifications,
    };
  }

  private async searchLbp(keywords: string): Promise<IParticipantSearchData[]> {
    const response = await this.apiClient.protectedApi.get(
      `/v1/service/licensed-building-practitioners?searchText=${keywords}&limit=10`
    );
    const jsonArr = get(response.data, "items");
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to get registered lbps`));
    }

    const foundLBPs: IParticipantSearchData[] = jsonArr
      .map((lbp) => {
        return {
          name: `${toString(get(lbp, "firstName"))} ${toString(get(lbp, "lastName"))}`,
          identifier: toString(get(lbp, "lbpNumber")),
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    return foundLBPs;
  }

  private async findLbp(lbpNumber: string): Promise<IParticipantLookupResponse> {
    const response = await this.apiClient.protectedApi.get(`/v1/service/licensed-building-practitioners/${lbpNumber}`);
    const json = response.data;
    const addressArray = get(response.data, "addressDetails");
    const firstAddress = get(addressArray, "0");
    const address: IAddressEntity = {
      address1: "",
      address2: "",
      city: toString(get(firstAddress, "townOrCity")),
      state: "",
      country: "New Zealand",
      zipCode: toString(get(firstAddress, "postCode")),
      isManualAddress: false,
      fullAddress: "",
    };
    address.fullAddress = AddressUtil.generateFullAddressForManualAddress(address);

    // Filter qualifications.
    const licenseClassesArray = get(response.data, "licensedClasses");
    if (!Array.isArray(licenseClassesArray)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch contacts`));
    }
    const mappedLicenseClasses = licenseClassesArray.map((license) => {
      return {
        status: toString(get(license, "status")),
        statusReason: toString(get(license, "statusReason")), // Dont ignore suspended
        className: toString(get(license, "className")),
        grantedDate: toString(get(license, "grantedDate") || ""),
      };
    });
    const classNames = uniq(
      mappedLicenseClasses
        .filter((license) => license.grantedDate || !license.statusReason.toLowerCase().includes("withdrawn"))
        .map((license) => license.className)
    );
    const inactiveClassWarnings: IQualificationWarning[] = uniq(
      mappedLicenseClasses
        .filter((license) => license.status.toLowerCase().includes("inactive"))
        .map((license) => {
          let inactiveReason = PersonSearchService.INACTIVE_STATUS_REASONS.get(license.statusReason);
          if (!inactiveReason) {
            inactiveReason = license.statusReason;
          }
          return {
            label: license.className,
            warning: `${license.className} license status: ${inactiveReason}`,
          };
        })
    );

    return {
      email: toString(get(json, "contactDetails.emailAddress")),
      firstName: toString(get(json, "names.firstName")),
      lastName: toString(get(json, "names.lastName")),
      phoneCountryCode: "+64",
      phone: toString(get(json, "contactDetails.phoneNumber")),
      organisation: "",
      groupList: "",
      groupName: "",
      ...address,
      qualifications: [
        {
          qualificationName: "LBP",
          licensingClasses: classNames,
          otherQualificationType: "",
          qualificationNumber: toString(get(json, "lbpNumber")),
          warnings: inactiveClassWarnings,
        },
      ],
    };
  }

  private async updateQualificationContact(id: string): Promise<IQualificationEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/user/organisation/contacts/${id}`);
    const json = response.data;
    const qualificationArr = get(json, "qualifications");
    if (!Array.isArray(qualificationArr)) {
      return [];
    }
    const qualifications = qualificationArr.map((qualification) => {
      return {
        qualificationName: toString(get(qualification, "qualificationName")),
        licensingClasses: get(qualification, "licensingClasses"),
        otherQualificationType: toString(get(qualification, "otherQualificationType")),
        qualificationNumber: toString(get(qualification, "qualificationNumber")),
        warnings: [],
      };
    });
    return qualifications;
  }

  private async updateQualificationLbp(lbpNumber: string): Promise<IQualificationEntity[]> {
    const response = await this.apiClient.protectedApi.get(`/v1/service/licensed-building-practitioners/${lbpNumber}`);
    const json = response.data;
    const responseLbpNumber = toString(get(json, "lbpNumber"));
    if (isEmpty(responseLbpNumber)) {
      return [];
    }
    const licenseClassesArray = get(json, "licensedClasses");
    if (!Array.isArray(licenseClassesArray)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch contacts`));
    }
    const mappedLicenseClasses = licenseClassesArray.map((license) => {
      return {
        status: toString(get(license, "status")),
        statusReason: toString(get(license, "statusReason")), // Dont ignore suspended
        className: toString(get(license, "className")),
        grantedDate: toString(get(license, "grantedDate") || ""),
      };
    });
    const classNames = uniq(
      mappedLicenseClasses
        .filter((license) => license.grantedDate || !license.statusReason.toLowerCase().includes("withdrawn"))
        .map((license) => license.className)
    );
    const inactiveClassWarnings: IQualificationWarning[] = uniq(
      mappedLicenseClasses
        .filter((license) => license.status.toLowerCase().includes("inactive"))
        .map((license) => {
          let inactiveReason = PersonSearchService.INACTIVE_STATUS_REASONS.get(license.statusReason);
          if (!inactiveReason) {
            inactiveReason = license.statusReason;
          }
          return {
            label: license.className,
            warning: `${license.className} license status: ${inactiveReason}`,
          };
        })
    );
    return [
      {
        qualificationName: "LBP",
        licensingClasses: classNames,
        otherQualificationType: "",
        qualificationNumber: toString(get(json, "lbpNumber")),
        warnings: inactiveClassWarnings,
      },
    ];
  }

  private parseParticipantSearch(json: any): IParticipantSearchData {
    return {
      name: `${toString(get(json, "firstName"))} ${toString(get(json, "lastName"))}`,
      identifier: toString(get(json, "id")),
    };
  }

  private parseParticipant(json: any): IParticipantPersonalInfoFormData {
    const address: IAddressEntity = {
      address1: toString(get(json, "address.address1")),
      address2: toString(get(json, "address.address2")),
      city: toString(get(json, "address.city")),
      state: toString(get(json, "address.state")),
      country: toString(get(json, "address.country")),
      zipCode: toString(get(json, "address.zipCode")),
      fullAddress: toString(get(json, "address.fullAddress")),
      isManualAddress: Boolean(get(json, "address.isManualAddress")),
    };

    return {
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      organisation: toString(get(json, "organisation")),
      phoneCountryCode: toString(get(json, "phoneCountryCode")),
      phone: toString(get(json, "phone")),
      email: toString(get(json, "email")),
      ...address,
    };
  }
}
