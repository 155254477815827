import NavigationItems from "components/odl-v2/Navigation/NavigationItems";
import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectIsSideNavigationCollapsed, toggleIsSideNavigationCollapsed } from "store/app-state/ui/ui";

export const useAppNavigationItemsProps = (): React.ComponentProps<typeof NavigationItems> => {
  const dispatch = useStoreDispatch();
  const isCollapsed = useStoreSelector(selectIsSideNavigationCollapsed);
  const onClickToggleCollapse = React.useCallback(() => {
    dispatch(toggleIsSideNavigationCollapsed());
  }, [dispatch]);

  return {
    isCollapsed,
    onClickToggleCollapse,
  };
};
