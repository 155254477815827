import React from "react";
import DuplicateApplicationDialog from "components/DuplicateApplicationDialog/DuplicateApplicationDialog";

export const useDuplicateApplicationDialog = (applicationId: number) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handleOnDialogClose = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const duplicateApplicationDialog = React.useMemo(() => {
    if (!isDialogOpen) {
      return null;
    }

    return <DuplicateApplicationDialog applicationId={applicationId} onClose={handleOnDialogClose} />;
  }, [applicationId, isDialogOpen, handleOnDialogClose]);

  const openDuplicateApplicationDialog = React.useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  return {
    duplicateApplicationDialog,
    openDuplicateApplicationDialog,
  };
};
