import { IUserInfoEntity } from "models/UserInfo.model";
import { IUserInfoService, IUserInfoServiceUpdateProfilePayload } from "services/user-info/UserInfoService.types";
import { Cradle } from "services/serviceContainer.types";
import get from "lodash/get";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import { IOrganisationEntity, parseOrganisationImage } from "models/Organisation.model";
import { OrganisationRoleUtil } from "models/OrganisationMember.model";
import { NotificationLevel, NotificationSubscriptionLevelUtil } from "models/NotificationSubscriptionLevel.model";
import { ServiceError } from "services/ServiceError";
import { IAuthorityEntity } from "models/Authority.model";
import toArray from "lodash/toArray";
import { IUserAuthorityRelation } from "models/UserAuthorityRelation.model";
import toNumber from "lodash/toNumber";

export class UserInfoService implements IUserInfoService {
  private readonly logger: Cradle["logger"];
  private readonly i18n: Cradle["i18n"];
  private readonly apiClient: Cradle["apiClient"];

  /**
   * Dependencies will be injected
   * @param args
   */
  constructor(args: Pick<Cradle, "logger" | "i18n" | "apiClient">) {
    this.logger = args.logger;
    this.i18n = args.i18n;
    this.apiClient = args.apiClient;
  }

  public async fetchUserInfoForAuthenticatedUser(): Promise<{
    userInfo: IUserInfoEntity;
    organisation: IOrganisationEntity;
  }> {
    const response = await this.apiClient.protectedApi.get("/user");
    const userInfo = this.parseUserInfo(response.data);
    const organisation = this.parseOrganisation(response.data);
    return { userInfo, organisation };
  }

  public async fetchIndependentAuthoritiesForAuthenticatedUser(): Promise<{
    independentAuthorities: IAuthorityEntity[];
  }> {
    const response = await this.apiClient.protectedApi.get("/user");
    const independentAuthorities = this.parseIndependentAuthorities(get(response.data, "independentAuthorities"));
    return { independentAuthorities };
  }

  public async fetchUserAuthoritiesRelationForAuthenticatedUser(): Promise<{
    userAuthorityRelations: IUserAuthorityRelation[];
  }> {
    const response = await this.apiClient.protectedApi.get("/user");
    const userAuthorityRelations = this.parseUserAuthoritiesRelations(response.data);
    return { userAuthorityRelations };
  }

  public async updateUserProfileForAuthenticatedUser(
    payload: Partial<IUserInfoServiceUpdateProfilePayload>
  ): Promise<{
    userInfo: IUserInfoEntity;
  }> {
    try {
      const response = await this.apiClient.protectedApi.patch("/user", {
        firstName: payload.firstName,
        lastName: payload.lastName,
        contactNumber: payload.contactNumber,
        phoneCountryCode: payload.contactCountryCode,
        address: payload.address,
      });
      const userInfo = this.parseUserInfo(response.data);
      return { userInfo };
    } catch (e) {
      throw ServiceError.createFromResponseError(e);
    }
  }

  public async updateDefaultApplicationNotificationSubscriptionLevelForAuthenticatedUser(args: {
    level: NotificationLevel;
  }): Promise<{ userInfo: IUserInfoEntity }> {
    const response = await this.apiClient.protectedApi.patch("/user", {
      defaultApplicationNotificationLevel: args.level.toString(),
    });
    const userInfo = this.parseUserInfo(response.data);
    return { userInfo };
  }

  public async updateNotificationMethodForAuthenticatedUser(args: {
    emailNotificationEnabled?: boolean;
    smsNotificationEnabled?: boolean;
    pushNotificationEnabled?: boolean;
  }): Promise<{ userInfo: IUserInfoEntity }> {
    const payload: Record<string, boolean> = {};
    if (typeof args.emailNotificationEnabled !== "undefined") {
      payload.emailNotificationPreference = args.emailNotificationEnabled;
    }
    if (typeof args.smsNotificationEnabled !== "undefined") {
      payload.smsNotificationPreference = args.smsNotificationEnabled;
    }
    if (typeof args.pushNotificationEnabled !== "undefined") {
      payload.pushNotificationPreference = args.pushNotificationEnabled;
    }

    const response = await this.apiClient.protectedApi.patch("/user", payload);
    const userInfo = this.parseUserInfo(response.data);
    return { userInfo };
  }

  private parseUserInfo(json: any): IUserInfoEntity {
    const updatedUserInfo: IUserInfoEntity = {
      id: toInteger(get(json, "id")),
      username: toString(get(json, "username")),
      email: toString(get(json, "email")),
      firstName: toString(get(json, "firstName")),
      lastName: toString(get(json, "lastName")),
      contactCountryCode: toString(get(json, "userProfile.phoneCountryCode")),
      contactNumber: toString(get(json, "userProfile.contactNumber")),
      organisationId: toInteger(get(json, "organisationId")),
      organisationRole: OrganisationRoleUtil.parse(toString(get(json, "organisationRole"))),
      organisationColour: toString(get(json, "organisationColour")),
      organisationImage: parseOrganisationImage(get(json, "organisationImage")),
      // TODO: If backend does not return this field, we default to true
      isOrganisationAccount: Boolean(get(json, "isOrganisationAccount") ?? true),
      defaultApplicationNotificationLevel: NotificationSubscriptionLevelUtil.parseSubscriptionLevel(
        toString(get(json, "userProfile.defaultApplicationNotificationLevel"))
      ),
      emailNotificationEnabled: Boolean(get(json, "userProfile.emailNotificationPreference") || true),
      smsNotificationEnabled: Boolean(get(json, "userProfile.smsNotificationPreference")),
      pushNotificationEnabled: Boolean(get(json, "userProfile.pushNotificationPreference")),
      address: {
        address1: toString(get(json, "address.address1")),
        address2: toString(get(json, "address.address2")),
        city: toString(get(json, "address.city")),
        state: toString(get(json, "address.state")),
        country: toString(get(json, "address.country")),
        zipCode: toString(get(json, "address.zipCode")),
        fullAddress: toString(get(json, "address.fullAddress")),
        isManualAddress: Boolean(get(json, "address.isManualAddress")),
      },
    };
    return updatedUserInfo;
  }

  private parseIndependentAuthorities(json: any): IAuthorityEntity[] {
    return json.map((authority: any) => {
      return {
        id: toInteger(get(authority, "id")),
        jurisdictionId: toInteger(get(authority, "jurisdictionId")),
        abbr: toString(get(authority, "abbr")),
        name: toString(get(authority, "name")),
        displayName: toString(get(authority, "displayName")),
        phone: toString(get(authority, "phone")),
        email: toString(get(authority, "email")),
        website: toString(get(authority, "website")),
        postalAddress: toString(get(authority, "postalAddress")),
        address: toString(get(authority, "address")),
        imageId: toInteger(get(authority, "imageId")),
        serviceable: Boolean(get(authority, "serviceable")),
        isIndependent: Boolean(get(authority, "isIndependent")),
        defaultIndependent: Boolean(get(authority, "defaultIndependent")),
        enabledFormSeries: toArray(get(authority, "enabledFormSeries")),
        propertyLookupEnabled: Boolean(get(json, "propertyLookupEnabled")),
      };
    });
  }

  private parseUserAuthoritiesRelations(json: any): IUserAuthorityRelation[] {
    const userId = toNumber(get(json, "id"));

    const authorities = get(json, "independentAuthorities");

    return authorities.map((authority: IAuthorityEntity) => {
      return {
        userId: userId,
        authorityId: toInteger(get(authority, "id")),
        defaultIndependent: Boolean(get(authority, "defaultIndependent")),
      };
    });
  }

  private parseOrganisation(json: any): IOrganisationEntity {
    let organisationName = toString(get(json, "organisationName"));
    // TODO: This is a fallback before backend switch to use "organisationName"
    if (!organisationName) {
      organisationName = toString(get(json, "userProfile.businessName"));
    }

    const jsonOrganisationImage = get(json, "organisationImage");

    const organisation: IOrganisationEntity = {
      id: toInteger(get(json, "organisationId")),
      displayName: organisationName,
      colour: toString(get(json, "organisationColour")),
      image: jsonOrganisationImage ? parseOrganisationImage(jsonOrganisationImage) : undefined,
    };
    return organisation;
  }
}
