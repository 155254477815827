import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { IPropertyEntity } from "models/Property.model";
import { serviceContainer } from "services/serviceContainer";

// Entity Adapter

const propertyAdapter = createEntityAdapter<IPropertyEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Action & Thunks

export const loadProperties = createAction<IPropertyEntity[]>("domainData/property/loadProperties");

export const fetchPropertiesForCurrentUser = createAsyncThunk(
  "domainData/property/fetchPropertiesForCurrentUser",
  async () => {
    const properties = await serviceContainer.cradle.propertyService.fetchPropertiesForCurrentUser();
    return properties;
  }
);

// Reducer

export const defaultPropertyState = propertyAdapter.getInitialState();

export const propertyReducer = createReducer<typeof defaultPropertyState>(defaultPropertyState, (builder) =>
  builder
    .addCase(loadProperties, propertyAdapter.upsertMany)
    .addCase(fetchPropertiesForCurrentUser.fulfilled, propertyAdapter.upsertMany)
);

// Selectors

export const {
  selectById: selectPropertyEntityById,
  selectIds: selectPropertyEntityIds,
  selectEntities: selectPropertyEntities,
  selectAll: selectAllPropertyEntities,
  selectTotal: selectTotalPropertyEntities,
} = propertyAdapter.getSelectors((state: RootState) => state.domainData.property);
