import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { IAuthorityEntity } from "models/Authority.model";
import { createDeepEqualSelector } from "store/utils";
import sortBy from "lodash/sortBy";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { serviceContainer } from "services/serviceContainer";
import { selectAllUserAuthorityRelations } from "store/domain-data/user-authority-relation/userAuthorityRelation";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";

// Entity Adapter

const authorityEntityAdapter = createEntityAdapter<IAuthorityEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => a.id - b.id,
});

// Action & Thunks

export const loadAuthorities = createAction<IAuthorityEntity[]>("domainData/authority/loadAuthorities");

export const fetchIndependentAuthoritiesForAuthenticatedUser = createAsyncThunk(
  "domainData/authorities/fetchIndependentAuthoritiesForAuthenticatedUser",
  async (_: void, thunkAPI) => {
    const {
      independentAuthorities,
    } = await serviceContainer.cradle.userInfoService.fetchIndependentAuthoritiesForAuthenticatedUser();
    thunkAPI.dispatch(loadAuthorities(independentAuthorities));
  }
);

// Reducer

export const initialState = authorityEntityAdapter.getInitialState();

export const authorityReducer = createReducer<typeof initialState>(initialState, (builder) =>
  builder.addCase(loadAuthorities, authorityEntityAdapter.upsertMany)
);

// Selectors

export const {
  selectById: selectAuthorityEntityById,
  selectAll: selectAllAuthorityEntities,
} = authorityEntityAdapter.getSelectors((state: RootState) => state.domainData.authority);

export const selectPublicAuthoritiesByJurisdiction = createDeepEqualSelector(
  [selectAllAuthorityEntities, (state: RootState, jurisdictionId: number) => jurisdictionId],
  (authorities, jurisdictionId) => {
    const filteredAuthorities = authorities
      .filter((authority) => authority.jurisdictionId === jurisdictionId)
      .filter((authority) => !authority.isIndependent);
    const sortedAuthorities = sortBy(filteredAuthorities, (authority) => authority.displayName);
    return sortedAuthorities;
  }
);

export const selectAuthoritiesByJurisdiction = createDeepEqualSelector(
  [selectAllAuthorityEntities, (state: RootState, jurisdictionId: number) => jurisdictionId],
  (authorities, jurisdictionId) => {
    const filteredAuthorities = authorities.filter((authority) => authority.jurisdictionId === jurisdictionId);
    const sortedAuthorities = sortBy(filteredAuthorities, (authority) => authority.displayName);
    return sortedAuthorities;
  }
);

export const selectAuthorityByApplicationId = createDeepEqualSelector(
  [
    selectAllAuthorityEntities,
    (state: RootState, applicationId: number) => selectApplicationEntityById(state, applicationId),
  ],
  (entities, application) => {
    return entities.find((entity) => entity.id === application?.authorityId);
  }
);

export const selectAuthorityNameByAuthorityId = createDeepEqualSelector(
  [selectAllAuthorityEntities, (_: RootState, authorityId: number) => authorityId],
  (entities, authorityId) => {
    const authority = entities.find((entity) => entity.id === authorityId);
    if (!authority) {
      return "";
    }
    return authority.displayName;
  }
);

export const selectAuthorityEntityByAbbr = createDeepEqualSelector(
  [selectAllAuthorityEntities, (_: RootState, abbr: string) => abbr],
  (authorities, abbr) => {
    return authorities.find((authority) => authority.abbr === abbr);
  }
);

export const selectHasIndependentAuthorities = createDeepEqualSelector([selectAllAuthorityEntities], (authorities) => {
  return authorities.filter((authority) => authority.isIndependent).length > 0;
});

export const selectIndependentAuthorities = createDeepEqualSelector([selectAllAuthorityEntities], (authorities) => {
  return authorities.filter((authority) => authority.isIndependent);
});

export const selectIndependentAuthoritiesForAuthenticatedUser = createDeepEqualSelector(
  [selectIndependentAuthorities, selectAllUserAuthorityRelations, selectUserInfoEntityForAuthenticatedUser],
  (independentAuthorities, allUserAuthorityRelations, userInfo) => {
    if (!userInfo || !allUserAuthorityRelations) {
      return [];
    }
    const userAuthorityRelationsForCurrentUser = allUserAuthorityRelations.filter((rel) => rel.userId === userInfo.id);

    const filteredAuthorities = independentAuthorities.filter((independentAuthority) =>
      userAuthorityRelationsForCurrentUser.some((a) => a.authorityId === independentAuthority.id)
    );
    return filteredAuthorities || [];
  }
);
