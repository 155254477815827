// Updated from backend code
export enum ApplicationStatus {
  Archived = "Archived",
  Completed = "Completed",
  Draft = "Draft",
  InProgress = "InProgress",
  Refused = "Refused",
  Rejected = "Rejected",
  Lapsed = "Lapsed",
  Submitted = "Submitted",
  Submitting = "Submitting",
  VRFISuspended = "VRFISuspended",
  Withdrawn = "Withdrawn",
  Unknown = "Unknown",
}

export const ApplicationStatusUtil = {
  parse: (str: string): ApplicationStatus => {
    for (const value of Object.values(ApplicationStatus)) {
      if (str === value.toString()) {
        return value;
      }
    }

    return ApplicationStatus.Unknown;
  },
  getVisibleApplicationStatuses: () => {
    return [
      ApplicationStatus.Draft,
      ApplicationStatus.Submitting,
      ApplicationStatus.Submitted,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
      ApplicationStatus.Completed,
      ApplicationStatus.Rejected,
      ApplicationStatus.Lapsed,
      ApplicationStatus.Refused,
      ApplicationStatus.Withdrawn,
      ApplicationStatus.Unknown,
    ];
  },
};
