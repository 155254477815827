import { serviceContainer } from "services/serviceContainer";
import * as yup from "yup";
import { IQualificationTypeEntity, QualificationUtil } from "models/Qualification.model";
import isEmpty from "lodash/isEmpty";

export const getQualificationFormSchema = (qualificationType: IQualificationTypeEntity | undefined) => {
  const t = serviceContainer.cradle.i18n.t;
  if (!qualificationType) {
    return;
  }

  let validationMessage = "";

  if (isEmpty(qualificationType.validationErrorMessage)) {
    validationMessage = t("Qualification number is invalid");
  } else {
    validationMessage = qualificationType.validationErrorMessage;
  }

  return yup.object({
    qualificationName: yup.string().required(),
    qualificationNumber: yup.string().when("qualificationName", {
      is: () => !!qualificationType.regex,
      then: yup.string().matches(new RegExp(qualificationType.regex), { message: validationMessage }).required(),
      otherwise: yup.string().notRequired(),
    }),
    otherQualificationType: yup.string().when("qualificationName", {
      is: (qualificationName: string) => QualificationUtil.isOtherQualification(qualificationName),
      then: yup.string().required(t(`Other qualification type is required`)),
      otherwise: yup.string().notRequired(),
    }),
    licensingClasses: yup
      .array()
      .test("min-one-license-class", t("At least one license class must be selected"), (licenceClasses, context) => {
        // Other qualification should not have licensing classes
        if (QualificationUtil.isOtherQualification(context.parent.qualificationName)) {
          return isEmpty(licenceClasses);
        }

        return !isEmpty(licenceClasses);
      }),
  });
};
