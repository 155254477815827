export enum QualificationType {
  LicensedBuildingPractitioner = "Licenced Building Practitioner",
}

export enum QualificationEntitySource {
  Manual = "Manual",
  Lookup = "Lookup",
}

export type IQualificationTypeLicenceClass = {
  id: number;
  name: string;
  displayName: string;
  displayOrder: number;
};

export type IQualificationTypeEntity = {
  id: number;
  name: string;
  displayName: string;
  displayOrder: number;
  regex: string;
  validationErrorMessage: string;
  classes: IQualificationTypeLicenceClass[];
};

export type IQualificationWarning = {
  label: string;
  warning: string;
};

export type IQualificationEntity = {
  qualificationName: string;
  licensingClasses: string[];
  otherQualificationType: string;
  qualificationNumber: string;
  warnings: IQualificationWarning[];
};

export const QualificationUtil = {
  isOtherQualification: (qualificationName: string) => {
    return qualificationName === "Other";
  },
};
