import React from "react";
import { FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";

type HandleFileRejectionsOptions = {
  maxSizeDescription: string;
  acceptFileTypeDescription: string;
};

export const useHandleFileRejections = (options: HandleFileRejectionsOptions) => {
  const { t } = useTranslation();
  const { toastError } = useToast();
  const { maxSizeDescription, acceptFileTypeDescription } = options;

  const handleFileRejections = React.useCallback(
    (fileRejections: FileRejection[]) => {
      // Handle the bad files.
      if (fileRejections.length === 0) {
        return;
      }

      for (const fileRejection of fileRejections) {
        // code: "file-too-large" | "file-too-small"|"too-many-files"|"file-invalid-type";
        const errorCodes = fileRejection.errors.map((error) => error.code);
        if (errorCodes.includes("file-too-large")) {
          toastError(
            t(`File "{{filename}}" is too large, we only accept file size less than {{maxSize}}.`, {
              filename: fileRejection.file.name,
              maxSize: maxSizeDescription,
            })
          );
          continue;
        }
        if (errorCodes.includes("file-invalid-type")) {
          toastError(
            t(`File "{{filename}}" is not acceptable, we only accept {{fileType}} files.`, {
              filename: fileRejection.file.name,
              fileType: acceptFileTypeDescription,
            })
          );
          continue;
        }

        toastError(
          t(`File "{{filename}}" is not acceptable`, {
            filename: fileRejection.file.name,
          })
        );
      }
    },
    [toastError, t, maxSizeDescription, acceptFileTypeDescription]
  );

  return handleFileRejections;
};
