import { createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { IFormIOSchemaEntity } from "models/FormIOSchema.model";

// Entity Adapter

const formSchemaAdapter = createEntityAdapter<IFormIOSchemaEntity>({
  // The "name" field could be considered as the key
  selectId: (formSchema) => formSchema.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

// Action & Thunks

export const fetchFormSchemaByName = createAsyncThunk(
  "domainData/formSchema/fetchFormSchemaByName",
  async (name: string, thunkAPI) => {
    const formSchema = await serviceContainer.cradle.formIOService.fetchFormSchemaByName(name);
    return formSchema;
  }
);

// Reducer

export const defaultFormSchemaState = formSchemaAdapter.getInitialState();

export const formSchemaReducer = createReducer<typeof defaultFormSchemaState>(defaultFormSchemaState, (builder) =>
  builder.addCase(fetchFormSchemaByName.fulfilled, (draft, action) => {
    formSchemaAdapter.upsertOne(draft, action.payload);
  })
);

// Selectors

// Select entities
export const {
  selectById: selectFormSchemaEntityByName,
  selectIds: selectFormSchemaEntityNames,
  selectEntities: selectFormSchemaEntities,
  selectAll: selectAllFormSchemaEntities,
  selectTotal: selectTotalFormSchemaEntities,
} = formSchemaAdapter.getSelectors((state: RootState) => state.domainData.formSchema);
