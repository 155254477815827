import React from "react";
import NewApplicationTemplateDialog from "components/NewApplicationTemplateDialog/NewApplicationTemplateDialog";

export const useNewApplicationTemplateDialog = (applicationId: number) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const newApplicationTemplateDialog = React.useMemo(() => {
    if (!isDialogOpen || !applicationId) {
      return null;
    }

    return <NewApplicationTemplateDialog applicationId={applicationId} onClose={() => setIsDialogOpen(false)} />;
  }, [applicationId, isDialogOpen]);

  const openNewApplicationTemplateDialog = React.useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);

  return {
    newApplicationTemplateDialog,
    openNewApplicationTemplateDialog,
  };
};
