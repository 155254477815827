import React from "react";
import { Link, LinkProps, Theme } from "@material-ui/core";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import FontIcon from "components/FontIcon/FontIcon";

export enum ActionItemVariant {
  Link = "Link",
  Button = "Button",
}

export type IActionItem = {
  icon: string | JSX.Element;
  title: string;
  variant: ActionItemVariant;
  to?: string;
  onClick?: () => void;
  dialog?: React.ReactNode;
  testId?: string;
  disabled?: Boolean;
};

type Props = {
  item: IActionItem;
};

const ActionItem: React.FC<Props> = ({ item }) => {
  const { icon, title, variant, to, onClick, testId, disabled, dialog } = item;

  if (variant === ActionItemVariant.Button) {
    return (
      <React.Fragment>
        <StyledActionButton
          data-testid={testId || "ActionItem"}
          onClick={onClick}
          role="button"
          disabled={disabled || false}
        >
          {typeof icon === "string" && <FontIcon name={icon} fontSize={14} />}
          {typeof icon === "object" && icon}
          <StyledActionTitle>{title}</StyledActionTitle>
        </StyledActionButton>
        {dialog}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StyledActionLink
        data-testid={"ActionItem"}
        component={RouterLink}
        underline="none"
        to={to || "#"}
        disabled={disabled || false}
      >
        {typeof icon === "string" && <FontIcon name={icon} fontSize={14} />}
        {typeof icon === "object" && icon}
        <StyledActionTitle>{title}</StyledActionTitle>
      </StyledActionLink>
      {dialog}
    </React.Fragment>
  );
};

const StyledActionLink = styled(Link)(
  ({ theme, disabled }: { theme: Theme; disabled: Boolean }) => css`
    display: flex;
    align-items: center;
    padding: 8px 0;
    ${theme.mixins.flexGap("8px")}

    ${disabled &&
    css`
      pointer-events: none;
      color: ${theme.palette.objective.dark.neutral2};
    `}
  `
) as React.FC<LinkProps & RouterLinkProps & { disabled: Boolean }>;

const StyledActionButton = styled.div(
  ({ theme, disabled }: { theme: Theme; disabled: Boolean }) => css`
    display: flex;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
    color: ${theme.palette.primary.main};
    ${theme.mixins.flexGap("8px")}

    ${disabled &&
    css`
      pointer-events: none;
      color: ${theme.palette.objective.dark.neutral2};
    `}
  `
);

const StyledActionTitle = styled.span(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 400;
  `
);

export default ActionItem;
