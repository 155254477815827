// TODO: Infer from yup schema
export type RegistrationFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

// TODO: Infer from yup schema
export type LoginFormValues = {
  account: string;
  password: string;
};

// TODO: Infer from yup schema
export type ForgotPasswordFormValues = {
  email: string;
};

// TODO: Infer from yup schema
export type ResetPasswordFormValues = {
  email: string;
  code: string;
  password: string;
};

export enum AuthenticationType {
  Login = "Login",
  Registration = "Registration",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
}

// TODO: Infer from yup schema
export type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
};

// TODO: Infer from yup schema
export type InvitationRegistrationFormValues = {
  email: string;
  password: string;
};
